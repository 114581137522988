<template>
<v-container class="pa-1">
    <DashNavi v-bind:title="'Create Embed'" v-bind:route="'/dashboard'" id="DashNavi"/>
          <v-alert v-if="isLoaded && !$store.state.chosenDiscord.hasPerms" prominent type="error" class="mt-n5 mt-sm-n7">
              <v-row align="center">
                <v-col class="grow">You don't have permission to create embeds in this server.</v-col>
                <v-col class="shrink">
                  <v-btn elevation="0" to="/dashboard">Go back</v-btn>
                </v-col>
              </v-row>
            </v-alert>

    <v-card v-if="$store.state.chosenDiscord.hasPerms" class="cardFont pa-0 pt-3 mt-n3 mt-sm-n8 pb-5" dark color="#1f2025" elevation="2">
        <v-hover v-slot="{ hover }">
     <div :style="hover === true ? 'cursor: pointer; width: 6px; height: 100%; opacity: 1; position: absolute; border-top-right-radius: 0px;  border-top-left-radius: 4px; border-bottom-left-radius: 4px; background-color: ' + color + ';' : 'opacity: 0.95; width: 6px; height: 100%; position: absolute; border-top-right-radius: 0px; border-top-left-radius: 4px; border-bottom-left-radius: 4px; background-color: ' + color + ';'" @click="colorOverlay = true" class="mt-n3"></div>
            </v-hover>
      <v-container>
        <v-row class="px-3 mt-n1 mb-n5">
          <v-col>
            <v-row class="mx-n1 mb-n1" :style="'background-color: #1a1a1e; border-radius: 5px'">
                <v-col class="mx-2" @click="mentionsFieldOpen = !mentionsFieldOpen">Mentions <v-icon v-if="mentionsFieldOpen">mdi-chevron-up</v-icon><v-icon v-else>mdi-chevron-down</v-icon>
                </v-col>
                <v-col v-if="mentionsFieldOpen" cols="12">
                  <v-row justify="space-between" style="padding-top: 1px; padding-bottom: 18px;" v-if="$store.state.chosenDiscordRoles">
                <v-col class="pa-0 mb-n2">
                  <v-col>
                  
                    <v-select hide-details item-value="id" item-text="name" dense solo background-color="#25262c" flat label="Select roles." @click="checkRoles()" multiple :items="chosenDiscordRoles" v-model="embedObject.mentions">
                      <template v-slot:selection="{ attrs, item, selected }">
                        <v-chip v-bind="attrs" :input-value="selected" small close close-icon="mdi-close-circle" :text-color="'white'" style="font-size: 0.7em; border-radius: 6px;" @click:close="embedObject.mentions.splice(embedObject.mentions.indexOf(item.id), 1);embedObject.mentions = [...embedObject.mentions]" ><v-icon small class="mr-1 ml-n1" :color="'rgb(' + item.color + ')'">mdi-circle</v-icon>
                        <strong>{{ item.name }}</strong>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-icon v-if="embedObject.mentions.includes(item.id)" :color="'rgb(' + item.color + ')'" class="mr-5">mdi-checkbox-marked</v-icon>
                        <v-icon v-else class="mr-5">mdi-checkbox-blank-outline</v-icon>
                        <span :style="'color: rgb(' + item.color + '); font-size: 14px;'">{{ item.name }}</span>
                      </template>
                    </v-select>
                  </v-col>

                </v-col>
              </v-row>
                </v-col>
            </v-row>

            <v-row class="mx-n1 mb-n1" :style="'background-color: #1a1a1e; border-radius: 5px'">
                <v-col class="mx-2" @click="authorFieldOpen = !authorFieldOpen">Author <v-icon v-if="authorFieldOpen">mdi-chevron-up</v-icon><v-icon v-else>mdi-chevron-down</v-icon>
                </v-col>
                <v-col v-if="authorFieldOpen" cols="12">
                  <v-text-field flat maxlength="256" background-color="#25262c" solo hide-details :placeholder="'Name'" v-model="embedObject.author.name" class="mb-2" full-width single-line>
                  </v-text-field>
                  <v-text-field flat background-color="#25262c" solo hide-details :placeholder="'URL'" v-model="embedObject.author.url" class="my-2" full-width single-line>
                  </v-text-field>
                  <Images :key="key" @imageUrl="setAuthorIcon" v-bind:formLabel="'Icon URL'" v-bind:formColor="'#25262c'" v-bind:url="embedObject.author.icon_url" id="ImageSelection"  class="mt-6 mb-n4 mx-n4" /> 
                </v-col>
            </v-row>

            <v-row class="mx-n1 mb-n1" :style="'background-color: #1a1a1e; border-radius: 5px'">
                <v-col class="mx-2" @click="thumbnailFieldOpen = !thumbnailFieldOpen">Thumbnail <v-icon v-if="thumbnailFieldOpen">mdi-chevron-up</v-icon><v-icon v-else>mdi-chevron-down</v-icon>
                </v-col>
                <v-col v-if="thumbnailFieldOpen" cols="12">
                    <Images :key="key" @imageUrl="setThumbnail" v-bind:formColor="'#25262c'" v-bind:url="embedObject.thumbnail" id="ImageSelection"  class="mt-2 mb-n4 mx-n4" /> 
                </v-col>
            </v-row>

            <v-row class="mx-n1 mb-n1" :style="'background-color: #1a1a1e; border-radius: 5px'">
                <v-col class="mx-2" @click="titleFieldOpen = !titleFieldOpen">Title <v-icon v-if="titleFieldOpen">mdi-chevron-up</v-icon><v-icon v-else>mdi-chevron-down</v-icon>
                </v-col>
                <v-col v-if="titleFieldOpen" cols="12">
                  <v-text-field flat maxlength="256" background-color="#25262c" solo hide-details :placeholder="'Enter the title here'" v-model="embedObject.title" class="my-2" full-width single-line>
                  </v-text-field>
                </v-col>
            </v-row>

            <v-row class="mx-n1 mb-n1" :style="'background-color: #1a1a1e; border-radius: 5px'">
                <v-col class="mx-2" @click="descriptionFieldOpen = !descriptionFieldOpen">Description <v-icon v-if="descriptionFieldOpen">mdi-chevron-up</v-icon><v-icon v-else>mdi-chevron-down</v-icon>
                </v-col>
                <v-col v-if="descriptionFieldOpen" cols="12">
                <v-textarea maxlength="4096" flat background-color="#25262c" solo hide-details :placeholder="'Enter the description here'" v-model="embedObject.description" class="my-2" full-width>
                </v-textarea>
                </v-col>
            </v-row>

            <v-row class="mx-n1 mb-n1" :style="'background-color: #1a1a1e; border-radius: 5px'">
                <v-col class="mx-2" @click="FieldsFieldOpen = !FieldsFieldOpen">Fields ({{embedObject.fields.length}})<v-icon v-if="FieldsFieldOpen">mdi-chevron-up</v-icon><v-icon v-else>mdi-chevron-down</v-icon>
                </v-col>
            <v-tooltip v-if="FieldsFieldOpen" transition="v-scale-transition" color="#0f0f0f" top nudge-bottom="30px">
							<template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" elevation="0" plain tile color="grey" :ripple="false" class="pt-11 pr-13" absolute right style="margin-top: -20px !important;" @click="removeField()"><v-icon>mdi-minus</v-icon></v-btn>
              </template><span>Remove Field</span>
						</v-tooltip>
            <v-tooltip v-if="FieldsFieldOpen" transition="v-scale-transition" color="#0f0f0f" top nudge-bottom="30px">
							<template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" elevation="0" plain tile color="grey" :ripple="false" class="pt-11" absolute right style="margin-top: -20px !important;" @click="addField()"><v-icon>mdi-plus</v-icon></v-btn>
              </template><span>Add Field</span>
						</v-tooltip>
                  
                <v-col v-if="FieldsFieldOpen" cols="12">

            <v-row>
          <v-col v-for="(field, b) in embedObject.fields" :key="b" :cols="field.inline && !$vuetify.breakpoint.xs ? 4 : 12">
                <v-text-field flat maxlength="256" background-color="#25262c" solo hide-details :placeholder="'Name'" v-model="field.name" class="px-0 py-1" full-width single-line>
                </v-text-field>
                <v-textarea maxlength="1024" flat background-color="#25262c" solo hide-details :placeholder="'Value'" v-model="field.value" class="px-0 py-1" full-width>
                </v-textarea>
                    <v-checkbox flat item-text="name" item-value="id" item-color="white" solo v-model="field.inline" :label="'Inline: ' + field.inline" class="px-0 mt-0" color="green" background-color="#25262c">
                    </v-checkbox>
          </v-col>
          </v-row>

                </v-col>
            </v-row>

            <v-row class="mx-n1 mb-n1" :style="'background-color: #1a1a1e; border-radius: 5px'">
                <v-col class="mx-2" @click="imageFieldOpen = !imageFieldOpen">Image <v-icon v-if="imageFieldOpen">mdi-chevron-up</v-icon><v-icon v-else>mdi-chevron-down</v-icon>
                </v-col>
                <v-col v-if="imageFieldOpen" cols="12">
                    <Images :key="key" @imageUrl="setImage" v-bind:formColor="'#25262c'" v-bind:url="embedObject.image" id="ImageSelection"  class="mt-2 mb-n4 mx-n4" /> 
                </v-col>
            </v-row>

            <v-row class="mx-n1 mb-n1" :style="'background-color: #1a1a1e; border-radius: 5px'">
                <v-col class="mx-2" @click="footerFieldOpen = !footerFieldOpen">Footer <v-icon v-if="footerFieldOpen">mdi-chevron-up</v-icon><v-icon v-else>mdi-chevron-down</v-icon>
                </v-col>
                <v-col v-if="footerFieldOpen" cols="12">
                  <v-text-field flat maxlength="2048" background-color="#25262c" solo hide-details :placeholder="'Text'" v-model="embedObject.footer.text" class="mb-2" full-width single-line>
                  </v-text-field>
                    <Images :key="key" @imageUrl="setFooterIcon" v-bind:formLabel="'Icon URL'" v-bind:formColor="'#25262c'" v-bind:url="embedObject.footer.icon_url" id="ImageSelection"  class="mt-6 mb-n4 mx-n4" /> 
                </v-col>
            </v-row>

            <v-row class="mx-n1 mb-n1" :style="'background-color: #1a1a1e; border-radius: 5px'">
                <v-col class="mx-2" @click="colorFieldOpen = !colorFieldOpen">Color <v-icon v-if="colorFieldOpen">mdi-chevron-up</v-icon><v-icon v-else>mdi-chevron-down</v-icon>
                </v-col>
                <v-col v-if="colorFieldOpen" cols="12">
          
          <v-color-picker class="pa-2" show-swatches swatches-max-height="230" hide-canvas width="100%" :style="'background-color: transparent;'" v-model="embedObject.color"></v-color-picker>
                </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card v-if="$store.state.chosenDiscord.hasPerms" class="cardFont pa-6 pt-5 pb-8 mt-2" dark color="primary" elevation="2">
          <v-row class="mt-0">
                        <v-select class="mx-2" item-text="id" hide-details dense return-object style="z-index: 7" item-color="green" solo v-model="channel" :items="getEventChannels()" label="Select a channel." background-color="#313338" flat>
                          <template v-slot:selection="{ item }">
                            <v-avatar tile width="20" height="20">
                              <img :src="'https://cdn.discordapp.com/emojis/' + getChannelIconByType(item.type) + '.png?v=1'" width="20" height="20" class="ml-n7" />
                            </v-avatar>
                             <span class="ml-n5" style="font-size: 14px;">{{ item.name }}</span>
                          </template>
                          <template v-slot:item="{ item }"> 
                            <v-avatar tile width="20" height="20">
                              <img :src="'https://cdn.discordapp.com/emojis/' + getChannelIconByType(item.type) + '.png?v=1'" width="20" height="20" class="ml-n7" />
                            </v-avatar>
                            <span class="ml-n5" style="font-size: 14px;">{{ item.name }}</span>
                          </template>
                        </v-select>
          </v-row>


            <v-row>
              <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-btn elevation="0" v-on="on" left x-small fab color="red" @click="deleteAllInput()" class="mt-3 mx-2" style="margin-top: 14px;"><v-icon>mdi-trash-can-outline</v-icon></v-btn></template><span>Reset all input</span></v-tooltip>
              <v-tooltip right max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-btn elevation="0" v-on="on" left x-small fab color="blue" @click="overlayOptions = true; copyEmbedToStore()" class="mt-3 " style="margin-top: 14px;"><v-icon >mdi-folder-open-outline</v-icon></v-btn></template><span>Embed Profiles</span></v-tooltip>
              <v-col>
                <v-btn elevation="0" color="purple" :ripple="false" class="mx-1 mt-0" :disabled="channel == undefined || embedIsEmpty() || disableCreation" absolute right @click="sendEmbed()">{{getEmbedButtonText()}}</v-btn>
              </v-col>
            </v-row>

    </v-card>
                    <v-dialog transition="slide-y-transition" v-model="colorOverlay" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
                    <v-card color="#3b3e45" style="border: 1px solid #2c2f33;">
                      <v-card-title>Color</v-card-title>
                      <v-color-picker class="pa-2" show-swatches swatches-max-height="230" hide-canvas width="100%" :style="'background-color: transparent;'" v-model="color"></v-color-picker>
                        <div style="background-color: #2f3136; text-align: right;" class="pa-2">
                            <v-btn elevation="0" @click="colorOverlay = false;" right color="green" class="ma-2">OK</v-btn>
                        </div>
                    </v-card>
                </v-dialog>

      <v-dialog transition="slide-y-transition" v-model="overlayOptions" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
                  <EmbedProfiles id="EmbedProfiles" @closeOverlay="closeOverlayNow" />
                        <v-container>
                          <v-row style="background-color: #2f3136;">
                              <v-col align="end">
                            <v-btn elevation="0" @click="overlayOptions = false;" class="mr-2 ma-1" :ripple="false" color="green">OK</v-btn>
                              </v-col>
                          </v-row>
                        </v-container>
                </v-dialog>

		<v-snackbar elevation="0" :color="snackbarColor" v-model="snackbar" timeout="3500" ><span style="font-size: 15px">{{ snackbarResultText }}</span>
		</v-snackbar>
  </v-container>
</template>
<script>
import axios from "axios";
import Store from "../../store/index";
import DashNavi from "../../components/DashNavi";
import Images from "../../components/events/ImageSelection";
import EmbedProfiles from "../embeds/EmbedsProfiles.vue";

export default {
    components: {
      DashNavi,
      Images,
      EmbedProfiles
  },

  data() {
    return {
      embedObject: {
        mentions: "",
        title: "",
        description: "",
        author: {name: ""},
        fields: [],
        footer: {text: ""},
        image: "",
        thumbnail: "",
        color: "#1a1a1e",
      },
      chosenDiscordRoles: undefined,
      overlayOptions: false,
      mentionsFieldOpen: false,
      authorFieldOpen: false,
      thumbnailFieldOpen: false,
      titleFieldOpen: false,
      descriptionFieldOpen: false,
      FieldsFieldOpen: false,
      imageFieldOpen: false,
      footerFieldOpen: false,
      colorFieldOpen: false,
      channel: undefined,
      snackbar: false,
			snackbarResultText: "",
			snackbarColor: "green darken-1",
      disableCreation: false,
      colorOverlay: false,
      color: "#1a1a1e",
      key: 0,
      isLoaded: false,
    };
  },

        
  methods: {

    	closeOverlayNow(variable) {
      this.overlayOptions = variable;
    },

    addField() {
      if (this.embedObject.fields.length < 25) {
        let field = {name: "", value: "", inline: true}
        this.embedObject.fields.push(field);
      }
    },

    deleteAllInput() {
      this.embedObject = {
        mentions: "",
        title: "",
        description: "",
        author: {name: "", url: "", icon_url: ""},
        fields: [],
        footer: {text: ""},
        image: "",
        thumbnail: "",
        color: "#1a1a1e",
      };
      this.channel = undefined,
      this.key++;
    },

    getChannelIconByType(type) {
      if (type === "text") {
        return "1025776950096171148";
      } else if (type === "forum") {
        return "1025776945146904629";
      } else if (type === "thread") {
        return "1025779885127516170";
      } else if (type === "post") {
        return "1025779860838301810";
      } else if (type === "news") {
        return "1025776946698784861";
      } else if (type === "stage") {
        return "1025776948288426068";
      } else if (type === "dm") {
        return "1026094422015877171";
      } else {
        return "1025776951627100210";
      }
    },

    getEventChannels() {
      return this.$store.state.discordChannels;
    },

    removeField() {
      this.embedObject.fields.pop();
    },

    disableButtonTemporarily() {
        this.disableCreation = true;
        setTimeout(() => this.disableCreation = false, 5000);
    },

    setImage(variable) {
      this.embedObject.image = variable;
    },

    setThumbnail(variable) {
      this.embedObject.thumbnail = variable;
    },

    setAuthorIcon(variable) {
      this.embedObject.author.icon_url = variable;
    },

    setFooterIcon(variable) {
      this.embedObject.footer.icon_url = variable;
    },

    checkRoles() {
      this.chosenDiscordRoles = JSON.parse(JSON.stringify(this.$store.state.chosenDiscordRoles));
      if (this.$store.state.formAnswers.channel && this.$store.state.formAnswers.channel.mention_permission) {
        this.chosenDiscordRoles.push({color: "255,255,255", id: "@here", name: "@here", position: -1})
        this.chosenDiscordRoles.push({color: "255,255,255", id: "@everyone", name: "@everyone", position: -2})
      }
    },

    sendEmbed() {
      this.disableButtonTemporarily();
      let req = {
        serverid: Store.state.chosenDiscord.id,
        channelid: this.channel.id,
        accessToken: localStorage.getItem('accessToken').slice(1,-1),
        embed: this.embedObject,
      };
     
      axios.post(Store.state.url + "/api/embed/create", req)      
      .then(response => {
        if (response.data.status == 'success') {
          this.snackbarColor = "green darken-1"
          this.snackbarResultText = 'Embed created!';
          this.snackbar = true;
        }

        if (response.data.status == 'failed') {
            this.snackbarColor = "red"
            this.snackbarResultText = response.data.reason;
            this.snackbar = true;
        }

      }).catch(error => {
          if (error.response.data.status === 'failed') {
            this.snackbarColor = "red"
            this.snackbarResultText = error.response.data.reason;
            this.snackbar = true;
          }
      });
    },

    getEmbedButtonText() {
      if (this.channel === undefined) {
        return "No channel selected"
      }
      if (this.embedIsEmpty()) {
        return "Embed is empty"
      }
      if (this.disableCreation) {
        return "On cooldown"
      }

      return "Send Embed";
    },

    copyEmbedToStore(){

	    this.$store.state.embed= this.embedObject
			
		},

    copyEmbedFromStore(){
			
		this.embedObject =  this.$store.state.embed
    this.key++;
		},

    embedIsEmpty() {
      try {
      if (this.embedObject.title.length > 0 || this.embedObject.description.length > 0 || this.embedObject.author.name.length > 0 || this.embedObject.footer.text.length > 0 || this.embedObject.image.length > 0 || this.embedObject.thumbnail.length > 0) {
        return false;
      }
      for (let i = 0; i < this.embedObject.fields.length; i++) {
        if (this.embedObject.fields[i].name.length > 0 || this.embedObject.fields[i].value.length > 0) {
          return false;
        }
      }
      } catch (error) { }


      return true;
    },

    async loadServerData() {
        this.req = {
          serverid: Store.state.chosenDiscord.id,
          accessToken: localStorage.getItem('accessToken').slice(1,-1),
        };

        let channelNameList = [];
        let rolesList = [];

        axios.post(Store.state.url + "/api/server/", this.req)
          .then(response => {
            Store.state.permissionLevel = response.data.permission;
            try {
            Store.state.permissionLevel = response.data.permission;
            Store.state.chosenDiscord.premium = response.data.premium;
            this.isLoaded = true;
          if (response.data.reason === "missing permission") {
            Store.state.chosenDiscord.hasPerms = false;

          } else {
            Store.state.chosenDiscord.hasPerms = true;
          }
          } catch (error) { }

            for (let index = 0; index < response.data.channels.length; index++) {
              channelNameList.push(response.data.channels[index]);
            }
            for (let index = 0; index < response.data.roles.length; index++) {
              rolesList.push(response.data.roles[index]);
            }

            Store.state.chosenDiscordRoles = rolesList;
            Store.state.discordChannels = channelNameList;

          }).catch(error => {
            if (error.response.status === 429) {
            this.ratelimitText = error.response.data.reason;
            this.ratelimitSnackbar = true;
          } else if (error.response.status === 401) {
            window.location.href = this.$store.state.url + "/api/auth/discord";
          }
          
      });
    },
    },

    created() {
      this.loadServerData();
      this.$root.$refs.EmbedProfiles = this;
      document.title = "Dashboard - Create Embed"
  },
}



</script>
<style>
.fixedColPadding {
  padding: 0px;
}


.noMarginP {
  margin-bottom: 0px !important;
}

.noMarginP2 {
  margin-bottom: 4px !important;
}

.noMarginIcon {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  margin-right: 0px !important;
}

.cardFont {
  font-family: Whitney, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

li {
  display: inline-block;
}

  /* width */
  ::-webkit-scrollbar {
    width: 3px;

  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #202225;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #4b5158;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
</style>