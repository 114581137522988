<template>
<v-container class="pa-1">
              <DashNavi v-bind:title="'Settings'" v-bind:route="'/dashboard'" id="DashNavi"/>
        <v-row class="mt-n9 mt-sm-n3">
          <v-col>

            <v-alert v-if="isLoaded && $store.state.currentGuild && $store.state.currentGuild.permission !== 'admin'" prominent type="error" class="mt-n5 mt-sm-n7">
              <v-row align="center">
                <v-col class="grow">You don't have permission to edit settings in this server.</v-col>
                <v-col class="shrink">
                  <v-btn elevation="0" to="/dashboard">Go back</v-btn>
                </v-col>
              </v-row>
            </v-alert>

    <v-card dark color="primary" elevation="2" class="cardFont px-1" v-else-if="isLoaded && $store.state.currentGuild && $store.state.currentGuild.permission === 'admin'">
      
      <v-row justify="space-between" no-gutters class="mt-3 mt-sm-n8 pt-sm-3">
            
    
        <v-col cols="12">
          <v-container>
            
            <v-form>
              <v-row>
              <v-col cols="12" md="6" class="pr-1 pl-1 pl-sm-3 pr-sm-3" >

              <v-row justify="space-between">
                <v-col class="pa-0 mt-2">
                      <v-row class="mx-0">
                        <v-col cols="12">
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Language</div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;"><v-btn elevation="0" x-small plain :ripple="false" class="mr-n2" @click="tooltipTitle = 'Language'; tooltipText = 'The language of the bot. This is still a work in progress, this website is not translated yet for example.'; tooltipOverlay = true;"><v-icon small color="grey darken-2" class="mt-n1">mdi-help-rhombus-outline</v-icon></v-btn></div>
                        </div>
                    <v-select hide-details dense solo background-color="#313338" flat label="Select a language." item-color="yellow" v-model="settings.language" :items="languages">
                      <template v-slot:item="{ item }">
                        <span>{{ item.charAt(0).toUpperCase() + item.slice(1) }}</span>
                      </template>
                      <template v-slot:selection="{ item }">
                        <span>{{ item.charAt(0).toUpperCase() + item.slice(1) }}</span>
                      </template>
                    </v-select>
                    </v-col>
                      </v-row>
                </v-col>
              </v-row>

              <v-row class="" justify="space-between">
                <v-col class="pa-0 mb-n5">
                      <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Timezone</div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;"><v-btn elevation="0" x-small plain :ripple="false" class="mr-n2" @click="tooltipTitle = 'Timezone'; tooltipText = 'The timezone is important for the bot to correctly understand time inputs. The bot will handle DST automatically, unless you choose a GMT offset. In which case you have to adjust the timezone for DST changes yourself.'; tooltipOverlay = true;"><v-icon small color="grey darken-2" class="mt-n1">mdi-help-rhombus-outline</v-icon></v-btn></div>

                        </div>
                    <v-select hide-details item-text="name" item-value="abbr" dense solo background-color="#313338" item-color="yellow" flat label="Select a timezone." v-model="settings.timezone" :items="timezones">
                      <template v-slot:item="{ item }">
                                 <span>{{ item.name }} <span style="color: grey;" class="ml-2">({{getCurrentZoneTime(item.abbr)}})</span></span>
                      </template>
                      <template v-slot:selection="{ item }">
                        <span>{{ item.name }} <span style="color: grey;" class="ml-2">({{getCurrentZoneTime(item.abbr)}})</span></span>
                      </template>
                    </v-select>
                      </v-col>
                </v-col>
              </v-row>

              <v-row justify="space-between" style="padding-top: 23px;">
                <v-col class="pa-0 mb-n2">
                      <v-row class="mx-0">
                        <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Manager</div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;">
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n4" @click="settings.manager_role = ''"><v-icon small color="grey darken-2" class="mt-n1">mdi-trash-can-outline</v-icon></v-btn>
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n2" @click="tooltipTitle = 'Manager'; tooltipText = 'Members with a manager role can create and edit events.'; tooltipOverlay = true;"><v-icon small color="grey darken-2" class="mt-n1">mdi-help-rhombus-outline</v-icon></v-btn></div>
                        </div>

                    <v-select multiple hide-details item-value="id" item-text="name" dense solo v-model="settings.manager_role" label="Select roles." background-color="#313338" flat :items="$store.state.chosenDiscordRoles">
                      <template v-slot:selection="{ attrs, item, selected }">
                        <v-chip v-bind="attrs" :input-value="selected" small close close-icon="mdi-close-circle" :text-color="'white'" style="font-size: 0.7em; border-radius: 6px;" @click:close="settings.manager_role.splice(settings.manager_role.indexOf(item.id), 1);settings.manager_role = [...settings.manager_role]" ><v-icon small class="mr-1 ml-n1" :color="'rgb(' + item.color + ')'">mdi-circle</v-icon>
                        <strong>{{ item.name }}</strong>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-icon v-if="settings.manager_role.includes(item.id)" :color="'rgb(' + item.color + ')'" class="mr-5">mdi-checkbox-marked</v-icon>
                        <v-icon v-else class="mr-5">mdi-checkbox-blank-outline</v-icon>
                        <span :style="'color: rgb(' + item.color + ');'">{{ item.name }}</span>
                      </template>
                    </v-select>
                    </v-col>

                      </v-row>
                </v-col>
              </v-row>

                            <v-row justify="space-between" style="padding-top: 23px;">
                <v-col class="pa-0 mb-n2">
                      <v-row class="mx-0">
                        <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Manager whitelist</div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;">
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n4" @click="settings.manager_whitelist = ''"><v-icon small color="grey darken-2" class="mt-n1">mdi-trash-can-outline</v-icon></v-btn>
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n2" @click="tooltipTitle = 'Manager whitelist'; tooltipText = 'You can add channels to the whitelist to restrict the managers to only those channels. Leave the whitelist empty if you don\'t want to restrict them.'; tooltipOverlay = true;"><v-icon small color="grey darken-2" class="mt-n1">mdi-help-rhombus-outline</v-icon></v-btn></div>
                        </div>

                    <v-select multiple hide-details item-value="id" item-text="name" item-color="yellow" dense solo v-model="settings.manager_whitelist" label="Select channels." background-color="#313338" flat :items="$store.state.discordChannels">
                      <template v-slot:selection="{ attrs, item, selected }">
                        <v-chip v-bind="attrs" :input-value="selected" small close close-icon="mdi-close-circle" :text-color="'white'" style="font-size: 0.7em; border-radius: 6px;" @click:close="settings.manager_whitelist.splice(settings.manager_whitelist.indexOf(item.id), 1);settings.manager_whitelist = [...settings.manager_whitelist]" ><v-icon small class="mr-1 ml-n1">#</v-icon>
                        <strong>{{ item.name }}</strong>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-icon v-if="settings.manager_whitelist.includes(item.id)" class="mr-5">mdi-checkbox-marked</v-icon>
                        <v-icon v-else class="mr-5">mdi-checkbox-blank-outline</v-icon>
                        <span># {{ item.name }}</span>
                      </template>
                    </v-select>
                    </v-col>

                      </v-row>
                </v-col>
              </v-row>

                            <v-row justify="space-between" style="padding-top: 23px;">
                <v-col class="pa-0 mb-n2">
                      <v-row class="mx-0">
                        <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Assistant</div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;">
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n4" @click="settings.assistant_role = ''"><v-icon small color="grey darken-2" class="mt-n1">mdi-trash-can-outline</v-icon></v-btn>
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n2" @click="tooltipTitle = 'Assistant'; tooltipText = 'Members with an assistant role can create and edit their own events.'; tooltipOverlay = true;"><v-icon small color="grey darken-2" class="mt-n1">mdi-help-rhombus-outline</v-icon></v-btn></div>
                        </div>

                    <v-select multiple hide-details item-value="id" item-text="name" dense solo v-model="settings.assistant_role" label="Select roles." background-color="#313338" flat :items="$store.state.chosenDiscordRoles">
                      <template v-slot:selection="{ attrs, item, selected }">
                        <v-chip v-bind="attrs" :input-value="selected" small close close-icon="mdi-close-circle" :text-color="'white'" style="font-size: 0.7em; border-radius: 6px;" @click:close="settings.assistant_role.splice(settings.assistant_role.indexOf(item.id), 1);settings.assistant_role = [...settings.assistant_role]" ><v-icon small class="mr-1 ml-n1" :color="'rgb(' + item.color + ')'">mdi-circle</v-icon>
                        <strong>{{ item.name }}</strong>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-icon v-if="settings.assistant_role.includes(item.id)" :color="'rgb(' + item.color + ')'" class="mr-5">mdi-checkbox-marked</v-icon>
                        <v-icon v-else class="mr-5">mdi-checkbox-blank-outline</v-icon>
                        <span :style="'color: rgb(' + item.color + ');'">{{ item.name }}</span>
                      </template>
                    </v-select>
                    </v-col>

                      </v-row>
                </v-col>
              </v-row>

                                          <v-row justify="space-between" style="padding-top: 23px;">
                <v-col class="pa-0 mb-n2">
                      <v-row class="mx-0">
                        <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Assistant whitelist</div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;">
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n4" @click="settings.assistant_whitelist = ''"><v-icon small color="grey darken-2" class="mt-n1">mdi-trash-can-outline</v-icon></v-btn>
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n2" @click="tooltipTitle = 'Assistant whitelist'; tooltipText = 'You can add channels to the whitelist to restrict the assistants to only those channels. Leave the whitelist empty if you don\'t want to restrict them.'; tooltipOverlay = true;"><v-icon small color="grey darken-2" class="mt-n1">mdi-help-rhombus-outline</v-icon></v-btn></div>
                        </div>

                    <v-select multiple hide-details item-value="id" item-text="name" item-color="yellow" dense solo v-model="settings.assistant_whitelist" label="Select channels." background-color="#313338" flat :items="$store.state.discordChannels">
                      <template v-slot:selection="{ attrs, item, selected }">
                        <v-chip v-bind="attrs" :input-value="selected" small close close-icon="mdi-close-circle" :text-color="'white'" style="font-size: 0.7em; border-radius: 6px;" @click:close="settings.assistant_whitelist.splice(settings.assistant_whitelist.indexOf(item.id), 1);settings.assistant_whitelist = [...settings.assistant_whitelist]" ><v-icon small class="mr-1 ml-n1">#</v-icon>
                        <strong>{{ item.name }}</strong>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-icon v-if="settings.assistant_whitelist.includes(item.id)" class="mr-5">mdi-checkbox-marked</v-icon>
                        <v-icon v-else class="mr-5">mdi-checkbox-blank-outline</v-icon>
                        <span># {{ item.name }}</span>
                      </template>
                    </v-select>
                    </v-col>

                      </v-row>
                </v-col>
              </v-row>

                   <v-row justify="space-between" style="padding-top: 23px;">
                <v-col class="pa-0 mb-n2">
                      <v-row class="mx-0">
                        <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Raider roles</div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;">
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n4" @click="settings.raider_roles = ''"><v-icon small color="grey darken-2" class="mt-n1">mdi-trash-can-outline</v-icon></v-btn>
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n2" @click="tooltipTitle = 'Raider roles'; tooltipText = 'The raider roles define the pool of players for the \'Unsigned\' command and keyword. All members with a raider role will be in the pool so that you can query the unsigned members to an event.'; tooltipOverlay = true;"><v-icon small color="grey darken-2" class="mt-n1">mdi-help-rhombus-outline</v-icon></v-btn></div>
                        </div>

                    <v-select multiple hide-details item-value="id" item-text="name" dense solo v-model="settings.raider_roles" label="Select roles." background-color="#313338" flat :items="$store.state.chosenDiscordRoles">
                      <template v-slot:selection="{ attrs, item, selected }">
                        <v-chip v-bind="attrs" :input-value="selected" small close close-icon="mdi-close-circle" :text-color="'white'" style="font-size: 0.7em; border-radius: 6px;" @click:close="settings.raider_roles.splice(settings.raider_roles.indexOf(item.id), 1);settings.raider_roles = [...settings.raider_roles]" ><v-icon small class="mr-1 ml-n1" :color="'rgb(' + item.color + ')'">mdi-circle</v-icon>
                        <strong>{{ item.name }}</strong>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-icon v-if="settings.raider_roles.includes(item.id)" :color="'rgb(' + item.color + ')'" class="mr-5">mdi-checkbox-marked</v-icon>
                        <v-icon v-else class="mr-5">mdi-checkbox-blank-outline</v-icon>
                        <span :style="'color: rgb(' + item.color + ');'">{{ item.name }}</span>
                      </template>
                    </v-select>
                    </v-col>

                      </v-row>
                </v-col>
              </v-row>

              </v-col>

              <v-col cols="12" md="6" class="pl-1 pr-1 pr-sm-3 pb-2 pl-sm-2 mb-10 mb-sm-6">


    <v-row class="mb-2 mt-4 mt-sm-n2 ml-0 mr-0">
        <v-btn-toggle borderless tile background-color="transparent">
          <v-btn elevation="0" style="border-radius: 4px 4px 0px 0px;" plain small @click="radio = 0" :style="radio === 0 ? 'background-color: #27272b;' : ''" :class="$vuetify.breakpoint.xs ? 'btn px-2 mr-n2' : 'btn px-4'" tile :ripple="false" text><v-icon small>mdi-pound</v-icon></v-btn>
          <v-btn elevation="0" style="border-radius: 4px 4px 0px 0px;" plain small @click="radio = 1" :style="radio === 1 ? 'background-color: #27272b;' : ''" :class="$vuetify.breakpoint.xs ? 'btn px-2 mr-n2' : 'btn px-4'" tile :ripple="false" text><v-icon small>mdi-tune</v-icon></v-btn>
        </v-btn-toggle>
    </v-row>

<div v-if="radio === 0" style="background-color: #27272b; height: 100%; border-radius: 0px 4px 4px 4px;" :style="$vuetify.breakpoint.xs ? 'margin-bottom: -32px;' : 'margin-bottom: 27px;'" class="px-3 pb-n15 pb-sm-6">
              <v-row class="px-1 pl-3 pt-2 pb-n2" style="font-weight: 700;" justify="space-between">
                <div>Channels</div>
              </v-row>
              <v-row justify="space-between" style="padding-top: 11px;">
                <v-col class="pa-0 mb-n2">
                      <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Leader channel</div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;">
                          <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n4" @click="settings.leader_channel = ''"><v-icon small color="grey darken-2" class="mt-n1">mdi-trash-can-outline</v-icon></v-btn>
                          <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n2" @click="tooltipTitle = 'Leader channel'; tooltipText = 'The leader channel will receive notifications about created events.'; tooltipOverlay = true;"><v-icon small color="grey darken-2" class="mt-n1">mdi-help-rhombus-outline</v-icon></v-btn></div>
                        </div>
                    <v-select hide-details="" item-value="id" item-text="name" dense solo background-color="#313338" flat label="Select a channel." item-color="yellow" :items="$store.state.discordChannels" v-model="settings.leader_channel">
                      <template v-slot:selection="{ attrs, item, selected }">
                        <v-chip v-bind="attrs" :input-value="selected" small close close-icon="mdi-close-circle" :text-color="'white'" style="font-size: 0.7em; border-radius: 6px;" @click:close="settings.leader_channel = ''" ><v-icon small class="mr-1 ml-n1">#</v-icon>
                        <strong>{{ item.name }}</strong>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item }">
                        <span># {{ item.name }}</span>
                      </template>
                    </v-select>
                      </v-col>

                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col class="pa-0 mb-n2">
                      <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Notification channel</div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;">
                          <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n4" @click="settings.reasons_channel = ''"><v-icon small color="grey darken-2" class="mt-n1">mdi-trash-can-outline</v-icon></v-btn>
                          <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n2" @click="tooltipTitle = 'Notification channel'; tooltipText = 'If members sign up with a default role (Bench, Late, Tentative, Absence) or cancel on a raid comp, the bot will inquire for a reason. If the member provides a reason it will be sent to the notification channel.'; tooltipOverlay = true;"><v-icon small color="grey darken-2" class="mt-n1">mdi-help-rhombus-outline</v-icon></v-btn></div>
                        </div>
                    <v-select hide-details="" item-value="id" item-text="name" dense solo background-color="#313338" flat label="Select a channel." item-color="yellow" :items="$store.state.discordChannels" v-model="settings.reasons_channel">
                      <template v-slot:selection="{ attrs, item, selected }">
                        <v-chip v-bind="attrs" :input-value="selected" small close close-icon="mdi-close-circle" :text-color="'white'" style="font-size: 0.7em; border-radius: 6px;" @click:close="settings.reasons_channel = ''" ><v-icon small class="mr-1 ml-n1">#</v-icon>
                        <strong>{{ item.name }}</strong>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item }">
                        <span># {{ item.name }}</span>
                      </template>
                    </v-select>
                      </v-col>

                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col class="pa-0 mb-n2">
                      <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Dkp channel</div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;">
                          <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n4" @click="settings.dkp_channel = ''"><v-icon small color="grey darken-2" class="mt-n1">mdi-trash-can-outline</v-icon></v-btn>
                          <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n2" @click="tooltipTitle = 'Dkp channel'; tooltipText = 'The bot will send dkp change notifications to the dkp channel.'; tooltipOverlay = true;"><v-icon small color="grey darken-2" class="mt-n1">mdi-help-rhombus-outline</v-icon></v-btn></div>
                        </div>
                    <v-select hide-details="" item-value="id" item-text="name" dense solo background-color="#313338" flat label="Select a channel." item-color="yellow" :items="$store.state.discordChannels" v-model="settings.dkp_channel">
                      <template v-slot:selection="{ attrs, item, selected }">
                        <v-chip v-bind="attrs" :input-value="selected" small close close-icon="mdi-close-circle" :text-color="'white'" style="font-size: 0.7em; border-radius: 6px;" @click:close="settings.dkp_channel = ''" ><v-icon small class="mr-1 ml-n1">#</v-icon>
                        <strong>{{ item.name }}</strong>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item }">
                        <span># {{ item.name }}</span>
                      </template>
                    </v-select>
                      </v-col>
                </v-col>
              </v-row>
                <v-row justify="space-between">
                <v-col class="pa-0 mb-n5">
                      <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Logging channel<v-btn elevation="0" style="margin-bottom: -2px;" v-if="!$store.state.currentGuild.premium" x-small plain :ripple="false" class="mr-n4" @click="$router.push('/premium/', () => {})"><v-icon @click="$router.push('/premium/', () => {})" small color="purple lighten-1" class="mt-n1">mdi-crown</v-icon></v-btn></div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3;">
                          <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n4" @click="settings.logging_channel = ''"><v-icon small color="grey darken-2" class="mt-n1">mdi-trash-can-outline</v-icon></v-btn>
                          <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n2" @click="tooltipTitle = 'Logging channel'; tooltipText = 'The bot will send notifications about event changes to the logging channel. This is a premium feature.'; tooltipOverlay = true;"><v-icon small color="grey darken-2" class="mt-n1">mdi-help-rhombus-outline</v-icon></v-btn></div>
                        </div>
                    <v-select :disabled="!$store.state.currentGuild.premium" hide-details="" item-value="id" item-text="name" dense solo background-color="#313338" flat label="Select a channel." item-color="yellow" :items="$store.state.discordChannels" v-model="settings.logging_channel">
                      <template v-slot:selection="{ attrs, item, selected }">
                        <v-chip v-bind="attrs" :input-value="selected" small close close-icon="mdi-close-circle" :text-color="'white'" style="font-size: 0.7em; border-radius: 6px;" @click:close="settings.logging_channel = ''" ><v-icon small class="mr-1 ml-n1">#</v-icon>
                        <strong>{{ item.name }}</strong>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item }">
                        <span># {{ item.name }}</span>
                      </template>
                    </v-select>
                      </v-col>
                </v-col>
              </v-row>

                <v-row justify="space-between" style="padding-top: 23px;">
                <v-col class="pa-0 mb-n5 mb-sm-n3">
                      <v-row class="mx-0">
                        <v-col cols="12" sm="7">
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Archive channel<v-btn elevation="0" style="margin-bottom: -2px;" v-if="!$store.state.currentGuild.premium" x-small plain :ripple="false" class="mr-n4" @click="$router.push('/premium/', () => {})"><v-icon @click="$router.push('/premium/', () => {})" small color="purple lighten-1" class="mt-n1">mdi-crown</v-icon></v-btn></div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3;">
                          <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n4" @click="settings.archive_channel = ''" ><v-icon small color="grey darken-2" class="mt-n1">mdi-trash-can-outline</v-icon></v-btn>
                          <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n2" @click="tooltipTitle = 'Archive channel'; tooltipText = 'If the archive channel is set, the bot will move events to the archive channel x hours after the event concluded. Where x is the archive time in hours. This setting will not apply to events created before the archive channel was set. This is a premium feature.'; tooltipOverlay = true;"><v-icon small color="grey darken-2" class="mt-n1">mdi-help-rhombus-outline</v-icon></v-btn></div>
                        </div>
                    <v-select :disabled="!$store.state.currentGuild.premium" hide-details="" item-value="id" item-text="name" dense solo background-color="#313338" flat label="Select a channel." item-color="yellow" :items="$store.state.discordChannels" v-model="settings.archive_channel">
                      <template v-slot:selection="{ attrs, item, selected }">
                        <v-chip v-bind="attrs" :input-value="selected" small close close-icon="mdi-close-circle" :text-color="'white'" style="font-size: 0.7em; border-radius: 6px;" @click:close="settings.archive_channel = ''" ><v-icon small class="mr-1 ml-n1">#</v-icon>
                        <strong>{{ item.name }}</strong>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item }" >
                        <span># {{ item.name }}</span>
                      </template>
                    </v-select>
                    </v-col>
                        <v-col cols="12" sm="5" class="mt-n3 mt-sm-1">
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Time</div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;">
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n4" @click="settings.archive_time = '0'" ><v-icon small color="grey darken-2" class="mt-n1">mdi-trash-can-outline</v-icon></v-btn>
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n2" @click="tooltipTitle = 'Archive time'; tooltipText = 'Determines when an event will be archived. The input is in hours after an events closure.'; tooltipOverlay = true;"><v-icon small color="grey darken-2" class="mt-n1">mdi-help-rhombus-outline</v-icon></v-btn></div>
                        </div>
                    <v-text-field :disabled="!$store.state.currentGuild.premium" dense solo v-model="settings.archive_time" type="number" label="Enter a number." background-color="#313338" flat>
                        </v-text-field>                    
                        </v-col>
                      </v-row>
                </v-col>
              </v-row>

            </div>
          
            <div v-if="radio === 1 && isLoaded" style="background-color: #27272b; height: 100%; border-radius: 0px 4px 4px 4px;" class="px-3 pb-3">
              <v-row class="px-1 pl-3 pt-2 pb-n3" style="font-weight: 700;" justify="space-between">
                <div>Advanced Settings</div><v-tooltip bottom max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-btn elevation="0" :ripple="false" v-on="on" x-small plain @click="advanced = $store.state.systemDefaultAdvancedSettings; key++;"><v-icon color="grey darken-2" small>mdi-trash-can-outline</v-icon></v-btn></template><span>Reset the input</span></v-tooltip>

              </v-row>
                <v-row style="padding-top: 16px;" :style="$vuetify.breakpoint.xs ? 'margin-bottom: -28px;' : 'margin-bottom: 34px;'" class="pl-1">
                     <Advanced :key="key" @advancedSettings="setAdvanced" v-bind:adv="advanced" v-bind:isRec="'full'" id="AdvancedSelection" /> 
                </v-row>
            </div>
          </v-col>
        </v-row>
            <v-row class="mt-0">
                <v-col class="mb-8 pl-2 pl-sm-4">
    <v-btn elevation="0" :disabled="disableButton || settings.prefix.length < 1 " class="mt-sm-n1 mx-n2 mx-sm-0" style="background-color: #4caf4f;" :style="disableButton || settings.prefix.length < 1 ? '' : 'box-shadow: 0px 0px 25px green;'" id="custom-disabled" absolute right @click="sendUpdate();">Save
    </v-btn>
                </v-col>
        </v-row>
      </v-form>

          </v-container>
        </v-col>
      </v-row>
          <v-dialog transition="slide-y-transition" v-model="tooltipOverlay" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="400" dark>

            <v-card color="#3b3e45" style="border: 1px solid #2c2f33;">
                        <v-card-title>{{tooltipTitle}}</v-card-title>
              <v-card-text class="px-3 pb-2">
                  <v-col>
                   {{tooltipText}}
                  </v-col> 
              </v-card-text>
                        <v-container>
                          <v-row style="background-color: #2f3136;">
                              <v-col align="end">
                            <v-btn elevation="0" @click="tooltipOverlay = false;" plain class="mr-2 ma-1 mt-n2 mb-n2" :ripple="false">Close</v-btn>
                              </v-col>
                          </v-row>
                        </v-container>
              </v-card>
          </v-dialog>
  </v-card></v-col></v-row>

            <v-snackbar elevation="0" color="red" v-model="ratelimitSnackbar" timeout="4500" ><span style="font-size:15px;">{{ ratelimitText }}</span>
          </v-snackbar>

          <v-snackbar class="my-2" color="green" v-model="snackbar" :timeout="snackbarTimeout" ><v-icon class="mx-3">mdi-check-circle</v-icon>Settings updated!
          </v-snackbar>

</v-container>
</template>
<script>

import Advanced from "../components/events/AdvancedSelection";
import DashNavi from "../components/DashNavi";
import axios from "axios";

export default {
  components: {
    Advanced,
    DashNavi
  },
  data() {
    return {
      radio: 0,
      key: 0,
      overlayShadow: false,
      tooltipOverlay: false,
      tooltipTitle: '',
      tooltipText: '',
      isLoaded: true,
      disableCreation: false,
      disableButton: false,
      ratelimitSnackbar: false,
      ratelimitText: undefined,
      snackbar: false,
      snackbarTimeout: 3000,
      currentTime: undefined,
      interval: undefined,
      advanced: {
            deadline: "",
            limit: "",
            lock_at_limit: "",
            limit_per_user: "",
            lower_limit: "",
            horizontal_mode: "",
            bench_overflow: "",
            queue_bench: "",
            vacuum: "",
            force_reminders: "",
            create_thread: "",
            forum_tags: "",
            delete_thread: "",
            use_nicknames: "",
            create_discordevent: "",
            only_discordevent: "",
            pin_message: "",
            deletion: "",
            mention_mode: "",
            preserve_order: "",
            event_type: "",
            reminder: "",
            apply_unregister: "",
            apply_specreset: "",
            show_on_overview: "",
            mention_leader: "",
            spec_saving: "",
            font_style: "",
            alt_names: "",
            attendance: "",
            show_title: "",
            show_info: "",
            show_leader: "",
            show_counter: "",
            show_roles: "",
            show_content: "",
            show_classes: "",
            show_emotes: "",
            show_numbering: "",
            show_allowed: "",
            show_banned: "",
            show_footer: "",
            defaults_pre_req: "",
            disable_archiving: "",
            bold_all: "",
            allow_duplicate: "",
            info_variant: "",
            date_variant: "",
            localtime_as_link: "",
            show_countdown: "",
            bench_emote: "",
            late_emote: "",
            tentative_emote: "",
            absence_emote: "",
            leader_emote: "",
            signups1_emote: "",
            signups2_emote: "",
            date1_emote: "",
            date2_emote: "",
            time1_emote: "",
            time2_emote: "",
            countdown1_emote: "",
            countdown2_emote: "",
            thumbnail: "",
        },

        settings: {
          prefix: "",
          timezone: "",
          language: "",
          manager_role: [],
          assistant_role: [],
          manager_whitelist: [],
          assistant_whitelist: [],
          raider_roles: [],
          reasons_channel: {},
          logging_channel: {},
          leader_channel: {},
          dkp_channel: {},
          archive_channel: {},
          archive_time: "",
        },

        languages: ["english", "german", "czech", "dutch", "finnish", "french", "hungarian", "italian", "norwegian", "polish", "portuguese", "russian", "spanish", "swedish", "chinese", "korean", "bulgarian", "danish", "japanese", "greek", "turkish", "romanian", "indonesian"
        ],

      timezones: [
        {name: "Pacific Time - Los Angeles", abbr: "America/Los_Angeles"},
        {name: "Mountain Time - Denver", abbr: "America/Denver"},
        {name: "Central Time - Chicago", abbr: "America/Chicago"},
        {name: "Eastern Time - New York", abbr: "America/New_York"},
        {name: "Atlantic Time - Halifax", abbr: "America/Halifax"},
        {name: "Brazil Time - São Paulo", abbr: "America/Sao_Paulo"},
        {name: "Western Europe - London", abbr: "Europe/London"},
        {name: "Central Europe - Berlin", abbr: "Europe/Berlin"},
        {name: "Eastern Europe - Bucharest", abbr: "Europe/Bucharest"},
        {name: "Russia - Moscow", abbr: "Europe/Moscow"},
        {name: "Turkey - Ankara", abbr: "Turkey"},
        {name: "India - Kolkata", abbr: "Asia/Kolkata"},
        {name: "Bangladesh - Dhaka", abbr: "Asia/Dhaka"},
        {name: "Asia - Hong Kong", abbr: "Asia/Hong_Kong"},
        {name: "Korea - Seoul", abbr: "Asia/Seoul"},
        {name: "Japan - Tokyo", abbr: "Asia/Tokyo"},
        {name: "Asia - Bangkok", abbr: "Asia/Bangkok"},
        {name: "Western Australia - Perth", abbr: "Australia/Perth"},
        {name: "Northern Australia - Darwin", abbr: "Australia/Darwin"},
        {name: "Eastern Australia - Queensland", abbr: "Australia/Queensland"},
        {name: "Southern Australia - Adelaide", abbr: "Australia/Adelaide"},
        {name: "Eastern Australia - Sydney", abbr: "Australia/Sydney"},
        {name: "New Zealand - Auckland", abbr: "Pacific/Auckland"},
        {name: "GMT -12", abbr: "-12"},
        {name: "GMT -11", abbr: "-11"},
        {name: "GMT -10", abbr: "-10"},
        {name: "GMT -9", abbr: "-9"},
        {name: "GMT -8", abbr: "-8"},
        {name: "GMT -7", abbr: "-7"},
        {name: "GMT -6", abbr: "-6"},
        {name: "GMT -5", abbr: "-5"},
        {name: "GMT -4", abbr: "-4"},
        {name: "GMT -3", abbr: "-3"},
        {name: "GMT -2", abbr: "-2"},
        {name: "GMT -1", abbr: "-1"},
        {name: "GMT +0", abbr: "+0"},
        {name: "GMT +1", abbr: "+1"},
        {name: "GMT +2", abbr: "+2"},
        {name: "GMT +3", abbr: "+3"},
        {name: "GMT +4", abbr: "+4"},
        {name: "GMT +5", abbr: "+5"},
        {name: "GMT +6", abbr: "+6"},
        {name: "GMT +7", abbr: "+7"},
        {name: "GMT +8", abbr: "+8"},
        {name: "GMT +9", abbr: "+9"},
        {name: "GMT +10", abbr: "+10"},
        {name: "GMT +11", abbr: "+11"},
        {name: "GMT +12", abbr: "+12"},
      ]
    };
  },
  methods: {

    setAdvanced(variable) {
      this.advanced = variable;
    },

    async initialiseServer() {
        this.req = {
          serverid: this.$store.state.chosenDiscord.id,
          accessToken: localStorage.getItem('accessToken').slice(1,-1),
        };

        axios.post(this.$store.state.url + "/api/server/",this.req)
          .then(response => {
            this.$store.state.currentGuild = response.data;
            this.parseServer();
          })
          .catch(error => {
            if (error.response.data.status === 429) {
              this.isLoaded = false;
              this.ratelimitText = error.response.data.reason;
              this.ratelimitSnackbar = true;
            } else if (error.response.status === 401) {
              window.location.href = this.$store.state.url + "/api/auth/discord";
            }
          });
    },

    async sendUpdate() {
      let settings = {};
      let advanced = JSON.parse(JSON.stringify(this.advanced));
      this.disableButton = true;
      setTimeout(() => this.disableButton = false, 5000);
      settings.leader_channel = this.settings.leader_channel;
      settings.reasons_channel = this.settings.reasons_channel;
      settings.dkp_channel = this.settings.dkp_channel;
      settings.logging_channel = this.settings.logging_channel;
      settings.archive_channel = this.settings.archive_channel;

      settings.timezone = this.settings.timezone;
      settings.prefix = this.settings.prefix;
      settings.language = this.settings.language;
      settings.archive_time = this.settings.archive_time;

      settings.manager_role = "";
      for (let i = 0; i < this.settings.manager_role.length; i++) {
        settings.manager_role += this.settings.manager_role[i] + " ";
      }

      settings.assistant_role = "";
      for (let i = 0; i < this.settings.assistant_role.length; i++) {
        settings.assistant_role += this.settings.assistant_role[i] + " ";
      }

      settings.manager_whitelist = "";
      for (let i = 0; i < this.settings.manager_whitelist.length; i++) {
        settings.manager_whitelist += this.settings.manager_whitelist[i] + " ";
      }

      settings.assistant_whitelist = "";
      for (let i = 0; i < this.settings.assistant_whitelist.length; i++) {
        settings.assistant_whitelist += this.settings.assistant_whitelist[i] + " ";
      }

      settings.raider_roles = "";
      for (let i = 0; i < this.settings.raider_roles.length; i++) {
        settings.raider_roles += this.settings.raider_roles[i] + " ";
      }

        let req = {
          serverid: this.$store.state.chosenDiscord.id,
          accessToken: localStorage.getItem('accessToken').slice(1,-1),
          edit: {
            settings,
            advanced
          }
        };

        axios.post(this.$store.state.url + "/api/server/", req)        
          .then(response => {
            this.$store.state.currentGuild = response.data;
            this.parseServer();
            this.snackbar = true;
          })
          .catch(error => {
            if (error.response.data.status === 'failed') {
              this.ratelimitText = error.response.data.reason;
              this.ratelimitSnackbar = true;
            }
          });
    },

    parseServer() {
      try {

        let channelNameList = [];
        let voiceChannelNameList = [];
        let rolesList = [];
        this.isLoaded = true;

        if (this.$store.state.currentGuild.permission === "admin") {
          for (let index = 0; index < this.$store.state.currentGuild.channels.length; index++) {
            channelNameList.push(this.$store.state.currentGuild.channels[index]);
          }
          for (let index = 0; index < this.$store.state.currentGuild.voicechannels.length; index++) {
            voiceChannelNameList.push(this.$store.state.currentGuild.voicechannels[index]);
          }
          for (let index = 0; index < this.$store.state.currentGuild.roles.length; index++) {
            rolesList.push(this.$store.state.currentGuild.roles[index]);
          }

          this.$store.state.discordVoiceChannels = voiceChannelNameList;
          this.$store.state.discordChannels = channelNameList;
          this.$store.state.chosenDiscordRoles = rolesList;


          this.advanced = this.$store.state.currentGuild.advanced1;
          this.settings = this.$store.state.currentGuild.settings;
          
          let managerArray = this.settings.manager_role.split(" ");
          let managerTemp = [];
          for (let i = 0; i < managerArray.length; i++) {
            for (let k = 0; k < rolesList.length; k++) {
              if (rolesList[k].id === managerArray[i] || rolesList[k].name === managerArray[i]) {
                managerTemp.push(rolesList[k].id);
              }
            }
          }
          this.settings.manager_role = managerTemp;
          this.settings.language = this.settings.language.toLowerCase();

          let assistantArray = this.settings.assistant_role.split(" ");
          let assistantTemp = [];
          for (let i = 0; i < assistantArray.length; i++) {
            for (let k = 0; k < rolesList.length; k++) {
              if (rolesList[k].id === assistantArray[i] || rolesList[k].name === assistantArray[i]) {
                assistantTemp.push(rolesList[k].id);
              }
            }
          }
          this.settings.assistant_role = assistantTemp;

     
          let managerWhitelistArray = this.settings.manager_whitelist.split(" ");
          let managerList = [];
          for (let i = 0; i < managerWhitelistArray.length; i++) {
            for (let k = 0; k < channelNameList.length; k++) {
              if (channelNameList[k].id === managerWhitelistArray[i]) {
                managerList.push(channelNameList[k].id);
              }
            }
          }
          this.settings.manager_whitelist = managerList;

          let assistantWhitelistArray = this.settings.assistant_whitelist.split(" ");
          let assistantList = [];
          for (let i = 0; i < assistantWhitelistArray.length; i++) {
            for (let k = 0; k < channelNameList.length; k++) {
              if (channelNameList[k].id === assistantWhitelistArray[i]) {
                assistantList.push(channelNameList[k].id);
              }
            }
          }
          this.settings.assistant_whitelist = assistantList;

          let raiderRolesArray = this.settings.raider_roles.split(" ");
          let raiderRolesTemp = [];
          for (let i = 0; i < raiderRolesArray.length; i++) {
            for (let k = 0; k < rolesList.length; k++) {
              if (rolesList[k].id === raiderRolesArray[i]) {
                raiderRolesTemp.push(rolesList[k].id);
              }
            }
          }
          this.settings.raider_roles = raiderRolesTemp;


          let timezone = this.settings.timezone;
          for (let i = 0; i < this.timezones.length; i++) {
            if (timezone.includes(this.timezones[i].abbr)) {
              this.settings.timezone = this.timezones[i].abbr;
            }
          }

          for (let i = 0; i < channelNameList.length; i++) {
            if (channelNameList[i].id === this.settings.leader_channel) {
              this.settings.leader_channel = channelNameList[i]
            }
            if (channelNameList[i].id === this.settings.reasons_channel) {
              this.settings.reasons_channel = channelNameList[i]
            }
            if (channelNameList[i].id === this.settings.dkp_channel) {
              this.settings.dkp_channel = channelNameList[i]
            }
            if (channelNameList[i].id === this.settings.logging_channel) {
              this.settings.logging_channel = channelNameList[i]
            }
            if (channelNameList[i].id === this.settings.archive_channel) {
              this.settings.archive_channel = channelNameList[i]
            }
          }
        } else {

        }

      } catch (error) {
      }
    },

    forceUpdate() {
      this.key++;
    },

    isMobile() {
      return window.innerWidth < 800;
    },

    updateCurrentTime() {
      this.currentTime = new Date().getTime();
    },

    getCurrentZoneTime(abbr) {
      try {
      return new Date(this.currentTime).toLocaleTimeString("en-GB", {timeZone: abbr, hour: '2-digit', minute:'2-digit'});
      } catch (error) {
      return new Date(new Date().getTime() + parseInt(abbr)*1000*60*60).toLocaleTimeString("en-GB", {timeZone: 'UTC', hour: '2-digit', minute:'2-digit'});
      }
    }
  },
    

  created() {
    this.initialiseServer();
    this.updateCurrentTime()
    this.interval = setInterval(() => this.updateCurrentTime(), 5000);
          if (this.firstClick === true) {
        try {
          this.roles = [];
          this.firstClick = false;
          let data = this.$store.state.chosenDiscordRoles;

          for (let index = 0; index < data.length; index++) {
            this.roles.push(data[index].name);
          }
        } catch (error) {}
      }
      document.title = "Dashboard - Settings" 
  },

  destroyed() {
    clearInterval(this.interval);
  }
  
};
</script>
<style>
.v-input__slot .v-label{
color: grey!important
}

.v-input {
  min-height: 1px !important;
}

.tooltip-arrow-top {
  opacity: 0.7; 
  position: absolute; 
  height: 10px; 
  width: 10px; 
  margin-left: 40%; 
  margin-top: -15px; 
  border-left: 10px solid transparent; 
  border-right: 10px solid transparent; 
  border-bottom: 10px solid rgb(5, 5, 5);
}

</style>
