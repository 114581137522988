<template>
    <v-container>
      <span class="bg"></span>

  <v-card height="100%" color="primary" class="mt-n8 mx-3">
    <v-card-text style="color: white;">
testing
  </v-card-text>
  </v-card>


        		<v-snackbar elevation="0" color="green" v-model="snackbar" timeout="1000" ><span style="font-size:15px;">Text copied!</span></v-snackbar>
</v-container>
</template>
<script>


export default {
  data() {
    return {
      snackbar: false,
    }
  },
  methods: {
  
  },
  mounted() {
  }

  
};
</script>
<style>

</style>
