<template>
	<v-container  class="pa-1">
		   <v-col cols="12" class="mt-sm-n10 mt-n1 mb-sm-9 mb-4" style="background-color: #1d1d21; border-radius: 3px;">
          <v-row justify="space-between">
            <v-col>
              <div style="height: 20px;">
              
              <v-tooltip transition="v-scale-transition" color="transparent" right nudge-left="30px">
							<template v-slot:activator="{ on, attrs }">
                <v-btn elevation="0" :ripple="false" v-bind="attrs" v-on="on" small left @click="$router.push(getBackRoute())" plain class="mt-n1 ml-n3" color="#484850"><v-icon size="30">mdi-arrow-left-circle</v-icon></v-btn>
              </template><span style="color: grey;">Go back</span>
						</v-tooltip>
              </div></v-col>
                        <v-spacer></v-spacer>
            <v-col :style="hasEditPermission ? 'cursor: pointer;' : ''" @click="hasEditPermission ? [editedTitle = '', overlay4 = true] : ''" v-bind:route="getBackRoute()">
          <div v-if="$vuetify.breakpoint.xs" style="white-space:nowrap; color: white; font-size: 0.8em; text-align: center; height: 20px;">{{truncate(title)}}</div>
          <div v-else-if="$vuetify.breakpoint.sm" class="mt-n1" style="white-space:nowrap; color: white; font-size: 1.3em; text-align: center; height: 20px;">{{truncate1(title)}}</div>
          <div v-else class="mt-n1" style="white-space:nowrap; color: white; font-size: 1.3em; text-align: center; height: 20px;">{{truncate2(title)}}</div>
                  </v-col>
            <v-spacer></v-spacer>
            <v-col>
              <div style="text-align: right; height: 20px;">
          						<v-tooltip transition="v-scale-transition" color="transparent" left nudge-right="30px">
							<template v-slot:activator="{ on, attrs }">
					<v-btn elevation="0" v-bind="attrs" v-on="on" class="mr-n3 mt-n2" :ripple="false" style="cursor: default;" plain>
          <v-avatar class="" width="30" height="30" min-width="30" min-height="30">
            <img :src="getServerIcon() ? getServerIcon() : 'https://cdn.discordapp.com/icons/817542418420596766/f8ec64ca464b50d9f4a80e1256cabd7f.png'" alt="DiscordAvatar" />
          </v-avatar>
          </v-btn>
              </template><span v-if="eventObject" style="color: grey;">{{(eventObject.servername ? eventObject.servername : '')}}</span>
						</v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-col>
		
		<v-overlay opacity="0" :value="!isLoaded ">
			<v-progress-circular
				indeterminate
				color="white"
				size="64"
			></v-progress-circular>
		</v-overlay>

		<v-card v-if="isLoaded" class="cardFont px-1 mt-n3 mt-sm-n8" dark color="primary" elevation="2">

			<v-col class="mb-n10" >
				<v-row justify="center" >

					<v-col cols="12" :align="'center'" class="mb-6">	
				
						<v-tooltip v-if="hasEditPermission && $store.state.authenticated && $store.state.discordUser !== undefined" transition="v-scale-transition" color="#0f0f0f" bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn v-if="eventObject" elevation="0" v-bind="attrs" v-on="on" x-small class="mt-1 mb-sm-6 mr-2"  right  @click="overlayOptions = true; copyToStore(); loadServerData()" color="green" ><v-icon style="margin-top: 1px;" small >mdi-folder-open-outline</v-icon></v-btn>
							</template><span>Comp Profiles</span>
						</v-tooltip>
						<v-tooltip v-if="!isFirefox" transition="v-scale-transition" color="#0f0f0f" bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn elevation="0" v-bind="attrs" v-on="on" x-small class="mt-1 mb-sm-6 mr-2" right color="green" @click="copyAsImage(); snackbarColor = 'green'; snackbarResultText = 'Screenshot copied to clipboard!'; snackbar = true;"><v-icon style="margin-top: 1px;" small>mdi-camera</v-icon></v-btn>
							</template><span>Take Screenshot</span>
						</v-tooltip>
						<v-tooltip v-else transition="v-scale-transition" color="#0f0f0f" bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn elevation="0" v-bind="attrs" v-on="on" x-small class="mt-1 mb-sm-6 mr-2" right color="green" @click="clearImageFirefox() ;copyAsImage();"><v-icon style="margin-top: 1px;" small>mdi-camera</v-icon></v-btn>
							</template><span>Take Screenshot</span>
						</v-tooltip>
						<v-tooltip transition="v-scale-transition" color="#0f0f0f" bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn elevation="0" v-bind="attrs" v-on="on" x-small class="mt-1 mb-sm-6 mr-2" right color="green" @click="overlay6 = true;"><v-icon style="margin-top: 1px;" small>mdi-file-export</v-icon></v-btn>
							</template><span>Export Data</span>
						</v-tooltip>
						<v-tooltip transition="v-scale-transition" color="#0f0f0f" bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn elevation="0" v-bind="attrs" v-on="on" x-small class="mt-1 mb-sm-6 mr-2" right color="green" @click="shareButton(); snackbarColor = 'green'; snackbarResultText = 'URL copied to clipboard!'; snackbar = true;"><v-icon style="margin-top: 1px;" small>mdi-share-variant</v-icon></v-btn>
							</template><span>Copy URL</span>
						</v-tooltip>
						<v-tooltip v-if="hasEditPermission" transition="v-scale-transition" color="#0f0f0f" bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn elevation="0" v-bind="attrs" v-on="on" x-small class="mt-1 mb-sm-6 mr-2" right color="red" @click="overlay5 = true"><v-icon style="margin-top: 1px;" small>mdi-trash-can-outline</v-icon></v-btn>
							</template><span>Reset Composition</span>
						</v-tooltip>
						
						<v-tooltip v-if="$store.state.authenticated && $store.state.discordUser !== undefined" transition="v-scale-transition" color="#0f0f0f" top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn elevation="0" v-bind="attrs" v-on="on" x-small class="mt-1 mb-sm-6 mr-2" right color="blue" @click="showLogs()"><v-icon small>mdi-post-outline</v-icon></v-btn>
							</template><span>Show logs</span>
						</v-tooltip> 
						
						
								<v-btn elevation="0" v-if="hasManagerPermission" class="mt-1 mb-sm-6" color="purple" right x-small @click=" settingsOverlay = true;">Settings</v-btn>
								<v-btn elevation="0" v-else-if="eventObject && !hasEditPermission" class="mt-1 mb-sm-6 ml-3" color="purple" right x-small @click="editPermsOverlay = true;">Edit</v-btn>

					</v-col>

					
				</v-row>
				<v-col cols="12" md="8">
					<v-row class="ml-n3 mt-n12 mb-5" justify="start" v-if="eventObject && !eventObject.template.match(/^(?:[2-5]|38)$/) && hasEditPermission">
						<v-card class="my-1 mr-1 " elevation="0" v-for="(uniqueClasses, k) in getEventClasses()" :key="k" style=" background-color: #292b2f; border-top-right-radius: 4px; border-bottom-right-radius: 4px;">
							<div v-for="(specs, i) in getCustomSpecs(uniqueClasses)" :key="i" style="float: left; display: inline">
								<v-tooltip transition="v-scale-transition" color="#0f0f0f" top>
									<template v-slot:activator="{ on, attrs }">
										<span v-bind="attrs" v-on="on">
											<v-hover v-slot="{ hover }">
												<v-img class="mx-1 my-1" draggable @dragstart="startDrag($event, specs)" @click="addOnClick(specs)" max-width="22" max-height="22" color="secondary" v-if="specs.spec_emote.match('^(\\d{17,20})$')" :src="iconize(specs.spec_emote)" style="cursor: pointer;" :style="hover ? 'filter: brightness(1.3);' : ''"></v-img>
												<div class="mx-1 my-1" draggable @dragstart="startDrag($event, specs)" @click="addOnClick(specs)" max-width="22" max-height="22" color="secondary" v-else style="cursor: pointer;" :style="hover ? 'filter: brightness(1.3);' : ''">{{specs.spec_emote}}</div>
											
											</v-hover>
										</span>
									</template>
								<span :style="'color:' + getClassColor(specs, true)">{{ getTooltip(specs.spec, specs.class, false) }}</span></v-tooltip>
							</div>
						</v-card>
					</v-row>
					<v-row class="ml-n3 mt-md-n12 mb-5" justify="start" v-else-if="hasEditPermission">
						<v-card class="my-1 mr-1" elevation="0" v-for="uniqueClasses in getClasses()" :key="uniqueClasses" style=" background-color: #292b2f; border-top-right-radius: 4px; border-bottom-right-radius: 4px;">
							<div v-for="(specs, i) in getSpecs(uniqueClasses)" :key="i" style="float: left; display: inline">
								<v-tooltip transition="v-scale-transition" color="#0f0f0f" top>
									<template v-slot:activator="{ on, attrs }">
										<span v-bind="attrs" v-on="on">
											<v-hover v-slot="{ hover }">
												<v-img class="mx-1 my-1" draggable @dragstart="startDrag($event, specs)" @click="addOnClick(specs)" max-width="22" max-height="22" color="secondary" v-if="specs.spec_emote.match('^(\\d{17,20})$')" :src="iconize(specs.spec_emote)" style="cursor: pointer;" :style="hover ? 'filter: brightness(1.3);' : ''"></v-img>
												<div class="mx-1 my-1" draggable @dragstart="startDrag($event, specs)" @click="addOnClick(specs)" max-width="22" max-height="22" color="secondary" v-else style="cursor: pointer;" :style="hover ? 'filter: brightness(1.3);' : ''">{{specs.spec_emote}}</div>
											
											</v-hover>
										</span>
									</template>
								<span :style="'color: red' + getClassColor(specs, true)">{{ getTooltip(specs.spec, specs.class, false) }}</span></v-tooltip>
							</div>
						</v-card>
					</v-row>
				</v-col>
				</v-col>
				
					<v-row v-if="eventObject && eventObject.roles.length > 0 && displayRoles" class="mb-n6 pa-6 pr-3 pt-3 mt-3" style="margin-left: -13px; margin-right: -13px;">
							<v-col cols="6" sm="3" class=" pr-1 mr-3 ml-n3" v-for="(roles, b) in eventObject.roles" :key="b" align="start">
							
									 		<span :style="hoveredRole == roles.emote ? 'filter: brightness(1.3);' : ''" style="white-space: nowrap; font-size: 0.8em; display: block; background-color: #292b2f; color: #adb1b6; border: 2px solid #212327; border-radius: 4px; width: 100%; padding-bottom: 3px !important;  padding-top: 3px !important; padding: 5px !important;" class="mt-n3">
									 	<v-tooltip transition="v-scale-transition" color="#0f0f0f" top nudge-right="12">
									 	<template v-slot:activator="{ on, attrs }">
									 		<span v-bind="attrs" v-on="on" class="mr-7 ml-n1"><v-img :src="iconize(roles.emote)" max-height="25" max-width="25" style="display:inline-block; position: absolute; margin-top: -3px !important; margin-left: 5px !important;"></v-img></span>
									 	</template>
									 	<span>{{roles.name}}</span>
									 	</v-tooltip>
									 	<span style="color: white; position: absolute; font-size: 1.1em; font-weight: 300; margin-top: -1px; margin-left: 8px;">{{roles.name }} <span style="font-weight: 700;">{{ getRoleCountRaidDrop(roles.emote, raidDrop)}} </span></span></span>
								
							</v-col>
						</v-row>

						           <v-row class="ml-4 mr-1 mb-7" v-if="eventObject && eventObject.roles.length > 0 && displayRoles">
             <v-col class="pr-6">
               <v-row>
             <v-divider style="background-color: #292b2f;"></v-divider>
               </v-row>
             </v-col>
           </v-row>
			<v-col class="mt-n12 mb-n2">
					<v-row id="capture" v-if="componentLoaded" class="mt-2">
						<v-col cols="12" :sm="Number.isInteger(party) ? 6 : 12" :md="Number.isInteger(party) ? 4 : 12" :lg="Number.isInteger(party) ? 4 : 12" v-for="(party, i) in divideArray" :key="i" :class="Number.isInteger(party) ? 'mt-n4 mb-n10' : 'mt-1 mb-0'">
							
							<v-row class="mx-0" v-if="!Number.isInteger(party)">
						<v-divider style="background-color: #292b2f;"></v-divider>
							<v-btn elevation="0" plain tile x-small color="white" :ripple="false" class="mx-2" style="margin-top: -10px !important;" :style="!hasEditPermission ? 'cursor: default;' : ''" @click="hasEditPermission ? [overlay7 = true, selectedDivider = i, dividerName = ''] : ''">{{party}}</v-btn>
						<v-divider style="background-color: #292b2f;"></v-divider>
							</v-row>
							
							<div v-else>
							<span @click="hasEditPermission ? [overlay3 = true, partyNameTarget = party-1] : ''" class="ml-0" style="white-space: nowrap;font-size: 0.8em;font-weight: 700;display: block;color: #5e5e5e;border-top-right-radius: 4px;border-bottom-right-radius: 4px;width: -webkit-calc(100% - 25px);padding-bottom: 3px !important;padding-top: 3px !important;" :style="hasEditPermission ? 'cursor: pointer;' : ''"> {{ getPartyName(partyNames[party-1]) }}</span>
							
								<v-tooltip transition="v-scale-transition" color="#0f0f0f" top v-if="hasEditPermission">
									<template v-slot:activator="{ on, attrs }">
										<v-btn elevation="0" data-html2canvas-ignore="true" v-bind="attrs" v-on="on" plain x-small style="float: right; margin-top: -20px;" color="grey darken-2" icon class="mr-4" @click="setDividerCurrent(party);" ><v-icon x-small>mdi-slash-forward-box</v-icon></v-btn>
									</template><span>Add Divider</span>
								</v-tooltip>

								<v-tooltip transition="v-scale-transition" color="#0f0f0f" top v-if="hasEditPermission">
									<template v-slot:activator="{ on, attrs }">
										<v-btn elevation="0" data-html2canvas-ignore="true" v-bind="attrs" v-on="on" plain x-small style="float: right; margin-top: -20px;" color="grey darken-2" icon class="mr-n1" @click="emptyGroup(party) " ><v-icon x-small>mdi-trash-can-outline</v-icon></v-btn>
									</template><span>Empty group</span>
								</v-tooltip>
							<span>


								<div v-for="(slot, i) in getParty(party, raidDrop)" :key="i" @dragenter.prevent @dragover.prevent @drop="hasEditPermission ? onDrop($event, slot, raidDrop) : ''" :draggable="hasEditPermission && slot.name !== null ? 'true' : 'false'" @dragstart="hasEditPermission ? startDrag($event, slot) : ''">
									<v-hover v-slot="{ hover }">
										<span @mouseenter="hoveredSlot = slot" @mouseleave="hoveredSlot = undefined" @click="hasEditPermission ? (isOutsideClick = false, selectedSlot === undefined && slot.name !== null ? selectedSlot = slot : swap(slot)) : ''" style=" white-space: nowrap; font-size: 0.8em; font-weight: 700; display: block; color: #adb1b6; border-top-right-radius: 2px; border-bottom-right-radius: 2px; padding-bottom: 3px !important; padding-top: 3px !important; width: 100%;" :style="{backgroundColor: slot.signuptime !== null ? '#2f3136' : '#27272e', filter: selectedSlot === slot ? 'brightness(1.5)' : hover ? 'brightness(1.3)' : '' ? 'brightness(1.3)' : ''}" :class="(hoveredSlot != undefined && slot != undefined && hoveredSlot != slot && hoveredSlot.userid != undefined && slot.userid != undefined && (hoveredSlot.name == slot.name || hoveredSlot.userid == slot.userid)) ? 'pulse-element mt-1' : 'mt-1'">
											
											<v-tooltip v-if="(slot.spec_emote !== null && displayClasses)" transition="v-scale-transition" color="#0f0f0f" top nudge-right="10px">
												<template v-slot:activator="{ on, attrs }">
													<span v-bind="attrs" v-on="on" class="mr-6">
												<v-img v-if="slot.class_emote.match('^(\\d{17,20})$')" :src="iconize(slot.class_emote)" max-height="25" max-width="25" style="display:inline-block; position: absolute; margin-top: -3px !important;"></v-img>
												<div v-else max-height="25" max-width="25" style="display:inline-block; position: absolute; margin-top: -3px !important; margin-left: 3px; font-size: 18px;">{{slot.spec_emote}}</div>
													</span></template>
												<span :style="'color: ' + slot.color + ';'">{{ getTooltip(slot.spec, slot.class, true)}}</span></v-tooltip>

												<v-tooltip v-if="slot.spec_emote !== null" transition="v-scale-transition" color="#0f0f0f" top nudge-right="10px">
												<template v-slot:activator="{ on, attrs }">
													<span v-bind="attrs" v-on="on" class="mr-6">
												<v-img v-if="slot.spec_emote.match('^(\\d{17,20})$')" :src="iconize(slot.spec_emote)" max-height="25" max-width="25" style="display:inline-block; position: absolute; margin-top: -3px !important; margin-left: 2px !important;"></v-img>
												<div v-else max-height="25" max-width="25" style="display:inline-block; position: absolute; margin-top: -3px !important; margin-left: 3px; font-size: 18px;">{{slot.spec_emote}}</div>
													</span></template>
												<span :style="'color: ' + slot.color + ';'">{{ getTooltip(slot.spec, slot.class, false)}}</span></v-tooltip>

											<v-tooltip transition="v-scale-transition" open-delay="500" color="#0f0f0f" top v-if="hasEditPermission && selectedSlot === slot && hover">
												<template v-slot:activator="{ on, attrs }">
											<span v-bind="attrs" v-on="on" style="padding-left: 10px !important; width: -webkit-calc(100% - 92px); display: inline-block;">
												<span :style="'color: ' + slot.color + ';'">
													<span :style="{filter: hover ? 'brightness(1.3)' : '', fontWeight: isInEvent(slot) ? '700' : '400'}">{{slot.name}}
													</span>
												</span>
											</span>
												</template><span>Click again to delete, or somewhere else to move.</span>
											</v-tooltip>

											
											<span v-else style="padding-left: 10px !important; width: -webkit-calc(100% - 92px); display: inline-block;">
												<span :style="'color: ' + slot.color + ';'">

													<span :class="(hoveredSlot != undefined && slot != undefined && hoveredSlot != slot && hoveredSlot.userid != undefined && slot.userid != undefined && (hoveredSlot.name == slot.name || hoveredSlot.userid == slot.userid)) ? 'text-shadow' : ''" :style="{filter:hover ? 'brightness(1.3)' : '', fontWeight: isInEvent(slot) ? '700' : '400'}">{{slot.name}}
													</span>
												</span>
											</span>
											
											<v-tooltip v-if="slot.name !== null && slot.isConfirmed == 'confirmed'" transition="v-scale-transition" color="#0f0f0f" top>
												<template v-slot:activator="{ on, attrs }">
													<v-btn elevation="0" :ripple="false" v-bind="attrs" v-on="on" plain x-small :style="hasEditPermission ? 'cursor: pointer; float: right;' : 'cursor: default; float: right;'" color="green lighten-2" icon class="mr-1 mb-n1" @click="hasEditPermission ? [slot.isConfirmed = 'cancelled', selectedSlot = undefined, updateRaidDB()] : '';createlogAction('isConfirmed',slot);" ><v-icon small>mdi-check-bold</v-icon></v-btn>
												</template>
												<span>Confirmed</span></v-tooltip>
												<v-tooltip v-else-if="slot.name !== null && slot.isConfirmed == 'cancelled'" transition="v-scale-transition" color="#0f0f0f" top>
												<template v-slot:activator="{ on, attrs }">
													<v-btn elevation="0" :ripple="false" v-bind="attrs" v-on="on" plain x-small :style="hasEditPermission ? 'cursor: pointer; float: right;' : 'cursor: default; float: right;'" color="red" icon class="mr-1 mb-n1" @click="hasEditPermission ? [slot.isConfirmed = 'false', selectedSlot = undefined, updateRaidDB()] : '';createlogAction('isConfirmed',slot);" ><v-icon small>mdi-cancel</v-icon></v-btn>
												</template>
												<span>Cancelled</span></v-tooltip>
												<v-tooltip v-else-if="slot.name !== null" transition="v-scale-transition" color="#0f0f0f" top>
												<template v-slot:activator="{ on, attrs }">
													<v-btn elevation="0" :ripple="false" v-bind="attrs" v-on="on" plain x-small :style="hasEditPermission ? 'cursor: pointer; float: right;' : 'cursor: default; float: right;'" color="#212527" icon class="mr-1 mb-n1" @click="hasEditPermission ? [slot.isConfirmed = 'confirmed', selectedSlot = undefined, updateRaidDB()] : '';createlogAction('isConfirmed',slot)" ><v-icon small>mdi-check-bold</v-icon></v-btn>
												</template>
												<span>Unconfirmed</span></v-tooltip>

												<v-tooltip v-if="hasEditPermission && slot.name !== null" transition="v-scale-transition" color="#0f0f0f" top>
												<template v-slot:activator="{ on, attrs }">
													<v-btn elevation="0" :ripple="false" v-bind="attrs" v-on="on" v-show="hover" plain x-small style="float: right" color="orange" icon class="" @click="selectedSlot = undefined; isOutsideClick = false; overlay = !overlay; editName(slot, raidDrop);" ><v-icon small>mdi-pencil</v-icon></v-btn>
												</template>
												<span>Edit</span></v-tooltip>
										</span>
									</v-hover>
								</div>

									
								<div class="mt-2">
									<span v-if="$store.state.showBuffs" style="background-color: transparent; font-size: 0.8em; font-weight: 700; display: inline-block; color: #adb1b6; border-radius: 2px; width: 100%;" class="mb-10">
										<div v-for="(buff, idx) in getGroupBuffs(party)" :key="idx" style="display: inline-block;">
											<a style="color: purple; position: relative;" :href="'https://' + getExpShortcut() + '.wowhead.com/spell=' + buff.name">
												<v-img :src="require('@/assets/emotes/' + buff.name + '.jpg')" width="17" height="17" style="display: inline-block; position: relative;" class="mr-1"></v-img>
												<div v-if="buff.value > 1" style="font-size: 0.7em; text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000; margin-left: -21px; margin-top: 6px; position: absolute; display: inline-block; color: white;">{{buff.value}}</div>
											</a>
										</div>

										<div v-for="(buff, ctx) in getGroupBuffsCustom(party)" :key="ctx" style="display: inline-block;">
											<v-tooltip transition="v-scale-transition" color="#0f0f0f" top>
												<template v-slot:activator="{ on, attrs }">
												<v-img v-bind="attrs" v-on="on" :src="require('@/assets/emotes/' + buff.name + '.jpg')" width="17" height="17" style="display: inline-block; position: relative;" class="mr-1"></v-img>
												</template><span>{{buff.name}}</span>
											</v-tooltip>
											<div v-if="buff.value > 1" style="font-size: 0.7em; text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000; margin-left: -21px; margin-top: 6px; position: absolute; display: inline-block; color: white;">{{buff.value}}</div>
										</div>
									</span>
								</div>

							</span>
							</div>
						</v-col>
					</v-row>



				<v-row class="mt-10 mb-n1" v-if="true === false && templates[templateId].buffs.length > 0 && $store.state.showBuffs">
				<v-col cols="12" sm="6">
					<v-card elevation="0" tile color="#282b2f" class="pl-3 pb-2 pt-2">
						<v-card-text style="font-size: 1.1em; font-weight: 700;" class="ml-n4 mt-n4 mb-n2">Buffs</v-card-text>
				<span style="background-color: transparent; font-size: 0.8em; font-weight: 700; display: inline-block; color: #adb1b6; border-radius: 2px; width: 100%;">
					<div v-for="buff in templates[templateId].buffs" :key="buff" class="mr-2" style="display: inline-block;">
						<a style="color: purple; position: relative;" :href="'https://tbc.wowhead.com/spell=' + buff">
							<v-img :src="require('@/assets/emotes/' + buff + '.jpg')" width="25" height="25" style="display: inline-block; position: relative;" :style="raidHasBuff(buff) > 0 ? 'filter: grayscale(0%);' : 'filter: grayscale(100%); opacity: 0.2;'"></v-img>
							<div v-if="raidHasBuff(buff) > 1" style="font-size: 0.9em; text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000; margin-left: -25px; margin-top: 12px; position: absolute; display: inline-block; color: white;">{{raidHasBuff(buff)}}</div>
						</a>
					</div>
				</span>
					</v-card>
				</v-col>
				<v-col cols="12" sm="6">
					<v-card elevation="0" tile color="#282b2f" class="pl-3 pb-2 pt-2">
						<v-card-text style="font-size: 1.1em; font-weight: 700;" class="ml-n4 mt-n4 mb-n2">Debuffs</v-card-text>
				<span style="background-color: transparent; font-size: 0.8em; font-weight: 700; display: inline-block; color: #adb1b6; border-radius: 2px; width: 100%;">
					<div v-for="buff in templates[templateId].debuffs" :key="buff" class="mr-2" style="display: inline-block;">
						<a style="color: purple; position: relative;" :href="'https://tbc.wowhead.com/spell=' + buff">
							<v-img :src="require('@/assets/emotes/' + buff + '.jpg')" width="25" height="25" style="display: inline-block; position: relative;" :style="raidHasBuff(buff) > 0 ? 'filter: grayscale(0%);' : 'filter: grayscale(100%); opacity: 0.2;'"></v-img>
							<div v-if="raidHasBuff(buff) > 1" style="font-size: 0.9em; text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000; margin-left: -25px; margin-top: 12px; position: absolute; display: inline-block; color: white;">{{raidHasBuff(buff)}}</div>
						</a>
					</div>
				</span>
					</v-card>
				</v-col>
				</v-row>
				<v-row justify="center" v-if="hasEditPermission && eventObject">
					<v-btn elevation="0" class="mb-6" color="purple" right x-small @click="loadServerData(); wantsToScheduleAnnounce = false; wantsToCreateUpdatingAnnounce = false; wantsToCreateUpdatingAnnounceNoConfirmation = false;  overlay8 = true;" >Announce</v-btn>
				</v-row>




				<v-row v-if="isWoWCata() || isWoWWotlk() || isWoWTBC()" class="mx-1 mb-1" :style="'background-color: #1d1d21; border-radius: 5px'">
                <v-col class="mx-2" @click="displayRaidbuffs = !displayRaidbuffs; setTemplateBuffs(); loadBuffCount(); " style="white-space:nowrap; color: white; font-size: 1.1em;">Raidbuffs <v-icon v-if="displayRaidbuffs  ">mdi-chevron-up</v-icon><v-icon v-else>mdi-chevron-down</v-icon>
                </v-col>



                <v-col v-if="displayRaidbuffs" cols="12">
					<v-col  style="font-size: 0.8em">
				<v-row  >
					<v-col v-for="group in currentTemplateBuffs" :key="group[0].caption" max-height="100"  class="pa-1 d-flex flex-column "  >
						<v-card v-for="playerBuffList in group" :key="playerBuffList.caption" class="my-1" height="100%" style="background-color: #27272e; display: inline-block;" elevation="0">
							<v-col>
								<v-row>
									<v-col>
										<span class="font-weight-bold" style="font-size: 1.1em;">
											{{playerBuffList.caption}}
										</span>
									</v-col>
								</v-row>

								<v-row >	
									<v-col >

										<span v-for="playerBuff in playerBuffList.spellList" :key="playerBuff.id">
											<v-tooltip transition="v-scale-transition" color="#0f0f0f" top>
													<template v-slot:activator="{ on, attrs }">
											<span style="cursor: default;" class="buffhover" v-bind="attrs" v-on="on" :style="{ color: displayBuffCountWOTLK(playerBuff.id) ? 'yellow' : 'grey'}" >{{displayBuffCountWOTLK(playerBuff.id)}} {{playerBuff.name}}</span> 
													</template>
													<span v-for="buffTooltip in playerBuff.tooltip" :key="buffTooltip.icon">
														<v-img :src="require('@/assets/' + buffImagePath + '/' + buffTooltip.icon + '.jpg')" width="20" height="20" style="margin-bottom: -5px;display: inline-block; position: relative;border-style: solid; border-color: grey; border-width: 1px"></v-img> <span style="font-size: 14px" :style="'color: ' + buffTooltip.color + ';'" >  {{buffTooltip.spell}} </span>
														<br/>
													</span>

											</v-tooltip>
											<br/>
										</span>

									</v-col>
								</v-row>
							</v-col>
						</v-card>
					</v-col>

					
			
					
				</v-row>
			</v-col>
                </v-col>
            </v-row>

			</v-col>

		</v-card>
		<br/>


		<v-card class="cardFont pa-1 pt-0 mt-4" style="margin-bottom: 300px;" dark color="primary" elevation="2" v-if="isLoaded && eventObject">
					<v-col cols="12" :align="($vuetify.breakpoint.xl || $vuetify.breakpoint.lg || $vuetify.breakpoint.md) ? 'right' : 'center'" :class="($vuetify.breakpoint.xl || $vuetify.breakpoint.lg || $vuetify.breakpoint.md) ? 'mb-n15' : 'mb-n3'">
						<v-row >
						 <v-col>
						
						<v-tooltip v-if="hasEditPermission" transition="v-scale-transition" color="#0f0f0f" top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn elevation="0" v-bind="attrs" v-on="on" x-small class="mt-3 mb-sm-6 mr-1" right color="green" @click="importAll()"><v-icon small>mdi-chevron-double-up</v-icon></v-btn>
							</template><span>Import all</span>
						</v-tooltip>
						<v-tooltip v-if="hasEditPermission" transition="v-scale-transition" color="#0f0f0f" top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn elevation="0" v-bind="attrs" v-on="on" x-small class="mt-3 mb-sm-6 mr-1" right color="red" @click="retrieveAll()"><v-icon small>mdi-chevron-double-down</v-icon></v-btn>
							</template><span>Retrieve all</span>
						</v-tooltip>
						<v-tooltip v-if="hasEditPermission" transition="v-scale-transition" color="#0f0f0f" top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn elevation="0" v-bind="attrs" v-on="on" class="mt-3 mb-sm-6 mr-3" color="green" right x-small @click="sortImportedRaid()"><v-icon small>mdi-sort-alphabetical-ascending</v-icon></v-btn>
							</template><span>Sort</span>
						</v-tooltip>
						
						<v-btn elevation="0" class="mt-3 mb-sm-6" color="purple" right x-small @click="$router.push('/event/' + eventObject.raidid, () => {})" >Go to Event</v-btn>
						
						</v-col>
						
						
						
						</v-row>

						
					</v-col>
		


<v-col class="mx-3 mt-3">
                <v-row id="title">
          <v-col>
            <v-row class="mb-n1 ml-n2">
              <span class="pt-0" style="font-size: 1.5em;">
                {{ eventObject.displayTitle }}
              </span>
            </v-row>
          </v-col>
        </v-row>
              <v-container>
                  <v-row class="pt-2 ml-n6">

					<v-tooltip transition="v-scale-transition" color="#0f0f0f" top>
						<template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" style="white-space: nowrap; font-size: 0.8em;" class="mt-2">
                    <span style='background-color: #181a1c; color: #dcd0d0; border: 2px solid #212327; border-right: 0px; border-top-left-radius: 4px; border-bottom-left-radius: 4px;' class='pl-1 pr-6 py-1'><v-img src="https://cdn.discordapp.com/emojis/799879945123135508.png" max-height="19" max-width="20" style="display:inline-block; position: absolute;"></v-img></span>
                    <span style='background-color: #292b2f; color: #adb1b6; border: 2px solid #212327; border-left: 0px; border-top-right-radius: 4px; border-bottom-right-radius: 4px;' class='px-2 py-1 mr-3'>{{ eventObject.leadername}}</span>
                    </span>               
					</template><span>Leader</span></v-tooltip>

					<v-tooltip transition="v-scale-transition" color="#0f0f0f" top>
						<template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" style="white-space: nowrap; font-size: 0.8em;" class="mt-2">
                    <span style='background-color: #181a1c; color: #dcd0d0; border: 2px solid #212327; border-right: 0px; border-top-left-radius: 4px; border-bottom-left-radius: 4px;' class='pl-1 pr-6 py-1'><v-img src="https://cdn.discordapp.com/emojis/592462264670617641.png" max-height="19" max-width="20" style="display:inline-block; position: absolute;"></v-img></span>
                    <span style='background-color: #292b2f; color: #adb1b6; border: 2px solid #212327; border-left: 0px; border-top-right-radius: 4px; border-bottom-right-radius: 4px;' class='px-2 py-1 mr-3'>{{ new Date(eventObject.unixtime*1000).toLocaleDateString([],{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }}</span>
                    </span>                
					</template><span>Starts in: {{days}} days {{hours}} hours {{minutes}} minutes</span></v-tooltip>

					<v-tooltip transition="v-scale-transition" color="#0f0f0f" top>
					<template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" style="white-space: nowrap; font-size: 0.8em;" class="mt-2">
                    <span style='background-color: #181a1c; color: #dcd0d0; border: 2px solid #212327; border-right: 0px; border-top-left-radius: 4px; border-bottom-left-radius: 4px;' class='pl-1 pr-6 py-1'><v-img src="https://cdn.discordapp.com/emojis/592463749865275452.png" max-height="19" max-width="20" style="display:inline-block; position: absolute;"></v-img></span>
                    <span style='background-color: #292b2f; color: #adb1b6; border: 2px solid #212327; border-left: 0px; border-top-right-radius: 4px; border-bottom-right-radius: 4px;' class='px-2 py-1 mr-3'>{{ new Date(eventObject.unixtime*1000).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) }}</span>
                    </span>            
					</template><span>Starts in: {{days}} days {{hours}} hours {{minutes}} minutes</span></v-tooltip>

					<v-tooltip transition="v-scale-transition" color="#0f0f0f" top>
					<template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" style="white-space: nowrap; font-size: 0.8em;" class="mt-2">
                    <span style='background-color: #181a1c; color: #dcd0d0; border: 2px solid #212327; border-right: 0px; border-top-left-radius: 4px; border-bottom-left-radius: 4px;' class='pl-1 pr-6 py-1'><v-img src="https://cdn.discordapp.com/emojis/799893852814049310.png" max-height="19" max-width="20" style="display:inline-block; position: absolute;"></v-img></span>
					<span style='background-color: #292b2f; color: #adb1b6; border: 2px solid #212327; border-left: 0px; border-top-right-radius: 4px; border-bottom-right-radius: 4px;' class='px-2 py-1 mr-3'><img :src="'https://cdn.discordapp.com/emojis/' + getChannelIconByType(eventObject.channelType) + '.png?v=1'" width="20" height="20" style="margin-bottom: -6px; margin-left: -5px; margin-right: 5px;" />{{ eventObject.channelName }}</span>
                    </span>              
					</template><span>Channel</span></v-tooltip>

                  </v-row>
              </v-container>
              
              </v-col>
				
					

			<v-col>
				<v-row class="mt-n5">
					<v-col cols="12" sm="6" md="4" lg="4" v-for="party in 3" :key="party">
						<span v-for="(slot, i) in getParty(party, importedSignUps)" :key="i">
							<div v-if="!isInComp(slot)" @dragenter.prevent @dragover.prevent :draggable="hasEditPermission && slot.name !== null ? 'true' : 'false'" @dragstart="hasEditPermission ? startDrag($event, slot): ''" @click="hasEditPermission ? addOnClick(slot) : ''">
								<v-hover v-slot="{ hover }">
									<span @mouseenter="hoveredSlot = slot" @mouseleave="hoveredSlot = undefined" style="white-space: nowrap;font-size: 0.8em;font-weight: 700;display: block;background-color: #292b2f;color: #adb1b6;border-top-right-radius: 2px;border-bottom-right-radius: 2px;padding-bottom: 3px !important;padding-top: 3px !important;width: 100%;" :style="{ backgroundColor: slot.signuptime !== null ? '#2f3136' : '#292b2f', opacity: isUserInCompAtAll(slot) ? '0.4' : '1.0', filter: hover ? 'brightness(1.3)' : ''}" :class="(hoveredSlot != undefined && hoveredSlot != slot && (hoveredSlot.name == slot.name || hoveredSlot.userid == slot.userid)) ? 'pulse-element mt-1' : 'mt-1'">
										<v-tooltip transition="v-scale-transition" color="#0f0f0f" top nudge-right="10">
											<template v-slot:activator="{ on, attrs }">
												<span v-bind="attrs" v-on="on" class="mr-6">

													<v-tooltip v-if="(slot.spec_emote.match('^(\\d{17,20})$') && !['Bench', 'Late', 'Tentative'].includes(slot.class) && displayClasses)" transition="v-scale-transition" color="#0f0f0f" top nudge-right="10px">
												<template v-slot:activator="{ on, attrs }">
													<span v-bind="attrs" v-on="on" class="mr-6">
												<v-img v-if="slot.class_emote.match('^(\\d{17,20})$')" :src="iconize(slot.class_emote)" max-height="25" max-width="25" style="display:inline-block; position: absolute; margin-top: -3px !important;"></v-img>
												<div v-else max-height="25" max-width="25" style="display:inline-block; position: absolute; margin-top: -3px !important; margin-left: 3px; font-size: 18px;">{{slot.spec_emote}}</div>
													</span></template>
												<span :style="'color: white'">{{ getTooltip(slot.spec, slot.class, true)}}</span></v-tooltip>
												
												<v-img v-if="slot.spec_emote.match('^(\\d{17,20})$') && !['Bench', 'Late', 'Tentative'].includes(slot.class)" :src="iconize(slot.spec_emote)" max-height="25" max-width="25" style="display:inline-block; position: absolute; margin-top: -3px !important; margin-left: 2px !important;"></v-img>
												<v-img v-else-if="['Bench', 'Late', 'Tentative'].includes(slot.class) && iconize2(slot.class_emote).match('^(\\d{17,20})$')" :src="'https://cdn.discordapp.com/emojis/' + iconize2(slot.class_emote) + '.png?v=1'" max-height="25" max-width="25" style="display:inline-block; position: absolute; margin-top: -3px !important;" :style="hover ? 'filter: brightness(1.3)' : ''"></v-img>
												<div v-else-if="['Bench', 'Late', 'Tentative'].includes(slot.class)" max-height="25" max-width="25" style="display:inline-block; position: absolute; margin-top: -3px !important; margin-left: 3px; font-size: 18px;">{{iconize2(slot.class_emote)}}</div>
												<div v-else max-height="25" max-width="25" style="display:inline-block; position: absolute; margin-top: -3px !important; margin-left: 3px; font-size: 18px;">{{slot.spec_emote}}</div>
												</span> 
												</template>
												<span :style="'color: white' ">{{ getTooltip(slot.spec, slot.class, false)}}</span></v-tooltip>
													<v-tooltip transition="v-scale-transition" color="#0f0f0f" top open-on-hover>
                										<template v-slot:activator="{ on, attrs }">
                    										<span v-bind="attrs" v-on="on" class="mx-1" style="background-color: #202225; font-size: 1em; position: absolute; margin-top: -2px !important; border-radius: 4px; padding: 2px; padding-left: 3px; padding-right: 3px;">{{ getPosition(slot.position) }}</span>
                    									</template>
                										<span>{{new Date(slot.signuptime*1000).toLocaleDateString([],{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }} {{ new Date(slot.signuptime*1000).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}}</span>
              							</v-tooltip>
										<span style="padding-left: 29px !important">
	
											<span :class="(hoveredSlot != undefined && hoveredSlot != slot && (hoveredSlot.name == slot.name || hoveredSlot.userid == slot.userid)) ? 'text-shadow' : ''" :style="'font-weight: 700; color: ' + getClassColor(slot, true) + ';'"><span :style="hover ? 'filter: brightness(1.3)' : ''">
												{{slot.name}}</span></span></span>

									</span>
								</v-hover>
							</div>
						</span>
					</v-col>
				</v-row>
			</v-col>
			<v-col class="px-6 pt-5 mb-n10">
               <v-row>
             <v-divider style="background-color: #292b2f;"></v-divider>
               </v-row>
             </v-col>
			<v-col>
				<v-row class="mt-2">
					<v-col cols="12" sm="6" md="4" lg="4" v-for="party in 3" :key="party">
						<span v-for="(slot, i) in getParty(party, importedSignUps)" :key="i">
							<div v-if="isInComp(slot)" @dragenter.prevent @dragover.prevent :draggable="hasEditPermission && slot.name !== null ? 'true' : 'false'" @dragstart="hasEditPermission ? startDrag($event, slot): ''" @click="hasEditPermission ? addOnClick(slot) : ''">
								
								<v-hover v-slot="{ hover }">
									<span @mouseenter="hoveredSlot = slot" @mouseleave="hoveredSlot = undefined" style="white-space: nowrap;font-size: 0.8em;font-weight: 700;display: block;background-color: #292b2f;color: #adb1b6;border-top-right-radius: 2px;border-bottom-right-radius: 2px;padding-bottom: 3px !important;padding-top: 3px !important;width: 100%;" :style="{ backgroundColor: slot.signuptime !== null ? '#2f3136' : '#292b2f', opacity: isInComp(slot) ? '0.3' : '1.0', filter: hover ? 'brightness(1.3)' : ''}" class="mt-1">
										<v-tooltip transition="v-scale-transition" color="#0f0f0f" top nudge-right="10">
											<template v-slot:activator="{ on, attrs }">
												<span v-bind="attrs" v-on="on" class="mr-6">

													<v-tooltip v-if="(slot.spec_emote.match('^(\\d{17,20})$') && !['Bench', 'Late', 'Tentative'].includes(slot.class) && displayClasses)" transition="v-scale-transition" color="#0f0f0f" top nudge-right="10px">
												<template v-slot:activator="{ on, attrs }">
													<span v-bind="attrs" v-on="on" class="mr-6">
												<v-img v-if="slot.class_emote.match('^(\\d{17,20})$')" :src="iconize(slot.class_emote)" max-height="25" max-width="25" style="display:inline-block; position: absolute; margin-top: -3px !important;"></v-img>
												<div v-else max-height="25" max-width="25" style="display:inline-block; position: absolute; margin-top: -3px !important; margin-left: 3px; font-size: 18px;">{{slot.spec_emote}}</div>
													</span></template>
												<span :style="'color: white'">{{ getTooltip(slot.spec, slot.class, true)}}</span></v-tooltip>
												
												<v-img v-if="slot.spec_emote.match('^(\\d{17,20})$') && !['Bench', 'Late', 'Tentative'].includes(slot.class)" :src="iconize(slot.spec_emote)" max-height="25" max-width="25" style="display:inline-block; position: absolute; margin-top: -3px !important; margin-left: 2px !important;"></v-img>
												<v-img v-else-if="['Bench', 'Late', 'Tentative'].includes(slot.class) && iconize2(slot.class_emote).match('^(\\d{17,20})$')" :src="'https://cdn.discordapp.com/emojis/' + iconize2(slot.class_emote) + '.png?v=1'" max-height="25" max-width="25" style="display:inline-block; position: absolute; margin-top: -3px !important;" :style="hover ? 'filter: brightness(1.3)' : ''"></v-img>
												<div v-else-if="['Bench', 'Late', 'Tentative'].includes(slot.class)" max-height="25" max-width="25" style="display:inline-block; position: absolute; margin-top: -3px !important; margin-left: 3px; font-size: 18px;">{{iconize2(slot.class_emote)}}</div>
												<div v-else max-height="25" max-width="25" style="display:inline-block; position: absolute; margin-top: -3px !important; margin-left: 3px; font-size: 18px;">{{slot.spec_emote}}</div>
												</span> 
												</template>
												<span :style="'color: white' ">{{ getTooltip(slot.spec, slot.class, false)}}</span></v-tooltip>
													<v-tooltip transition="v-scale-transition" color="#0f0f0f" top open-on-hover>
                										<template v-slot:activator="{ on, attrs }">
                    										<span v-bind="attrs" v-on="on" class="mx-1" style="background-color: #202225; font-size: 1em; position: absolute; margin-top: -2px !important; border-radius: 4px; padding: 2px; padding-left: 3px; padding-right: 3px;">{{ getPosition(slot.position) }}</span>
                    									</template>
                										<span>{{new Date(slot.signuptime*1000).toLocaleDateString([],{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }} {{ new Date(slot.signuptime*1000).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}}</span>
              							</v-tooltip>
										<span style="padding-left: 29px !important">
	
											<span :class="(hoveredSlot != undefined && hoveredSlot != slot && (hoveredSlot.name == slot.name || hoveredSlot.userid == slot.userid)) ? 'text-shadow' : ''" :style="'font-weight: 700; color: ' + getClassColor(slot, true) + ';'"><span :style="hover ? 'filter: brightness(1.3)' : ''">
												{{slot.name}}</span></span></span>

												
												<v-tooltip v-if="hasEditPermission && slot.name !== null" transition="v-scale-transition" color="#0f0f0f" top>
        											<template v-slot:activator="{ on, attrs }">
            											<v-btn elevation="0" :ripple="false" v-bind="attrs" v-on="on" v-show="hover" plain x-small style="float: right" color="white" icon class="mr-1" @click.stop="retrieveSlot(slot);" >
                											<v-icon small>mdi-keyboard-return</v-icon>
            											</v-btn>
        											</template>
        											<span>Retrieve</span>
    											</v-tooltip>
							
									</span>
								</v-hover>
							</div>
						</span>
					</v-col>
				</v-row>
			</v-col>
		</v-card>

<v-dialog transition="slide-y-transition" v-model="overlay" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
	<v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
		<v-card-text class="mt-2 pa-4" style="font-size: 1.3em; color: white">Edit Character</v-card-text>
		<v-card-text class="mt-n5 pa-4" style="font-size: 0.8em">Enter a new name for this character or change its spec.</v-card-text>
		<v-text-field dense @keydown.esc="selectedSlot = undefined" flat background-color="#4a4e57" solo @keydown.enter=" overlay = false; overlayNameEntered.name = overlayName; overlayName = ''; updateRaidDB();" hide-details :placeholder="overlayNameEntered.name" v-model="overlayName" class="pa-4 " full-width single-line>
		</v-text-field>
			<v-select v-if="isLoaded" dense item-text="index" item-value="index" item-color="white" return-object solo placeholder="Select a spec." flat v-model="overlayDrop" :items="getEventClassesAndSpecs()" class="pa-4 pt-0 mb-n5" background-color="#4a4e57">
				  <template v-slot:selection="{ item }">
					<v-avatar tile width="20" height="20">
					  <img v-if="item.spec_emote.match('^(\\d{17,20})$')" :src="'https://cdn.discordapp.com/emojis/' + item.spec_emote + '.png?v=1'" width="20" height="20" class="" />
					  <div v-else>{{item.spec_emote}}</div>
					</v-avatar>
					{{item.spec}}
				  </template>
				  <template v-slot:item="{ item }">
					<v-avatar tile width="20" height="20">
					  <img v-if="item.spec_emote.match('^(\\d{17,20})$')" :src="'https://cdn.discordapp.com/emojis/' + item.spec_emote + '.png?v=1'" width="20" height="20" class="" />
					  <div v-else>{{item.spec_emote}}</div>
					</v-avatar>
					{{item.spec}}
				  </template>
				</v-select>

		<div style="background-color: #2f3136; text-align: right" class="pa-2 mb-0">
				<v-btn elevation="0" @click="overlay = false;" right plain class="mx-1 my-2" :ripple="false" >Cancel</v-btn>
				<v-btn elevation="0" @click="overlay = false;  updateColorsOnEditClass(overlayDrop); createlogAction('editSlot', { oldName: overlayNameEntered.name, 
					oldClass: overlayNameEntered.class, 
					oldSpec: overlayNameEntered.spec, 
					oldClass_emote: overlayNameEntered.class_emote, 
					oldSpec_emote: overlayNameEntered.spec_emote, 
					oldColor: overlayNameEntered.color,
					newName: overlayName, 
					newColor: overlayDropColor,
					newClass_emote: overlayDrop.class_emote, 
					newSpec_emote: overlayDrop.spec_emote, 
					newClass: overlayDrop.class, 
					newSpec: overlayDrop.spec, 
					partyId: overlayNameEntered.partyId, 
					slotId: overlayNameEntered.slotId});overlayNameEntered.name = overlayName; overlayName = ''; overlayNameEntered.spec = overlayDrop.spec; overlayNameEntered.class = overlayDrop.class; overlayNameEntered.spec_emote = overlayDrop.spec_emote; overlayNameEntered.class_emote = overlayDrop.class_emote; overlayNameEntered.role_emote = overlayDrop.role_emote; overlayNameEntered.color = overlayDropColor; updateRaidDB();" right color="green" class="mx-2 my-2" >Save</v-btn>
			</div>
	</v-card>
</v-dialog>

<v-dialog transition="slide-y-transition" v-model="settingsOverlay" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
	<v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
		<v-card-text class="mt-2 pa-4" style="font-size: 1.3em; color: white">Settings</v-card-text>
		
				<v-row class="ml-n1 mb-0 mr-3" justify="space-between">
					<v-col cols="12" sm="6">

				<v-card elevation="0" class="ma-2" width="100%" height="90px" color="#2f3136">
				<v-card-text align="center" class="py-2" style="background-color: #4a4e57; font-weight: 500; font-size: 1.3em;">Template</v-card-text>
				<v-select :disabled="eventObject !== undefined" @click="overlayShadow = false;" @blur="updateRaidDB()" class="mt-2" hide-details item-value="id" dense item-color="yellow" solo v-model="templateId" :items="templates" label="Select a template." background-color="#2f3136" flat>
				  <template v-slot:selection="{ item }">
					<v-avatar tile width="20" height="20">
					  <img :src="'https://cdn.discordapp.com/emojis/' + item.image + '.png?v=1'" width="20" height="20" class="ml-n7" />
					</v-avatar>
					<span class="ml-n5">{{ item.name }}</span>
				  </template>
				  <template v-slot:item="{ item }">
					<v-avatar tile width="20" height="20">
					  <img :src="'https://cdn.discordapp.com/emojis/' + item.image + '.png?v=1'" width="20" height="20" class="ml-n7" />
					</v-avatar>
				   <span class="ml-n5">{{ item.name }}</span>
				  </template>
				</v-select></v-card>

				<v-card elevation="0" class="ma-2 mt-5" width="100%" height="90px" color="#2f3136">
				<v-card-text align="center" class="py-2" style="background-color: #4a4e57; font-weight: 500; font-size: 1.3em;">Edit Permissions</v-card-text>
				<v-select @blur="createlogAction('editPermissions',permissionLevel); updateRaidDB()" v-if="eventObject" @click="overlayShadow = false;" class="mt-2" hide-details dense item-color="yellow" solo v-model="permissionLevel" :items="['managers', 'everyone']" label="Select a permission level." background-color="#2f3136" flat>
				  
				</v-select></v-card>

				<v-card elevation="0" class="ma-2 mt-5" width="100%" height="90px" color="#2f3136">
				<v-card-text align="center" class="py-2" style="background-color: #4a4e57; font-weight: 500; font-size: 1.3em;">Show Roles</v-card-text>
				<v-select @blur="createlogAction('showRoles',displayRoles);updateRaidDB()" v-if="eventObject" @click="overlayShadow = false;" class="mt-2" hide-details dense item-color="yellow" solo v-model="displayRoles" :items="[true, false]" label="Select a permission level." background-color="#2f3136" flat>
				  
				</v-select></v-card>

				<v-card elevation="0" class="ma-2 mt-5" width="100%" height="90px" color="#2f3136">
				<v-card-text align="center" class="py-2" style="background-color: #4a4e57; font-weight: 500; font-size: 1.3em;">Show Classes</v-card-text>
				<v-select @blur="createlogAction('showClasses',displayClasses);updateRaidDB()" v-if="eventObject" @click="overlayShadow = false;" class="mt-2" hide-details dense item-color="yellow" solo v-model="displayClasses" :items="[true, false]" label="Select a permission level." background-color="#2f3136" flat>
				  
				</v-select></v-card>
				
				
				</v-col>

		<v-col cols="12" sm="6">
					<v-card elevation="0" class="ma-2 mt-n3 mt-sm-2" width="100%" height="90px" color="#2f3136">
						<v-card-text align="center" class="py-2" style="background-color: #4a4e57; font-weight: 500; font-size: 1.3em;">Groups</v-card-text>
						<v-row justify="space-between" class="mx-1 pb-2">
							<v-btn elevation="0" :ripple="false" text left color="red" @click="removeParty()" x-small class="mt-6 py-4 mr-n5 ml-1"><v-icon>mdi-minus</v-icon></v-btn>
							<v-col align="center"><v-card-text style="font-size: 1.5em; font-weight: 700;" class="px-0 py-4 mb-n5">{{partyPerRaid}}</v-card-text></v-col>
							<v-btn elevation="0" :ripple="false" text right color="green" @click="addParty()" class="mt-6 py-4 ml-n5 mr-1" x-small><v-icon>mdi-plus</v-icon></v-btn>
						</v-row>
					</v-card>
					<v-card elevation="0" class="ma-2 mt-5" width="100%" height="90px" color="#2f3136">
						<v-card-text align="center" class="py-2" style="background-color: #4a4e57; font-weight: 500; font-size: 1.3em;">Slots</v-card-text>
						<v-row justify="space-between" class="mx-1 pb-2">
							<v-btn elevation="0" :ripple="false" text left color="red" @click="removeSlot()" x-small class="mt-6 py-4 mr-n5 ml-1"><v-icon>mdi-minus</v-icon></v-btn>
							<v-col align="center"><v-card-text style="font-size: 1.5em; font-weight: 700;" class="px-0 py-4 mb-n5">{{slotPerParty}}</v-card-text></v-col>
							<v-btn elevation="0" :ripple="false" text right color="green" @click="addSlot()" class="mt-6 py-4 ml-n5 mr-1" x-small><v-icon>mdi-plus</v-icon></v-btn>
						</v-row>
					</v-card>

					<v-card elevation="0" class="ma-2 mt-5" width="100%" height="90px" color="#2f3136">
				<v-card-text align="center" class="py-2" style="background-color: #4a4e57; font-weight: 500; font-size: 1.3em;">Spec Colors</v-card-text>
				<v-select  @blur="updateRaidDB();" @change="checkIfColorReset(overlayDrop)" v-if="eventObject" item-color="white"  return-object @click="overlayShadow = false;" class="mt-2" id="test" hide-details dense solo v-model="overlayDrop" :items="getEventClassesAndSpecsColors()" placeholder="Select a spec" background-color="#2f3136" flat>
					<template v-slot:selection="{ item }">
					<v-avatar tile width="20" height="20">
					  <img v-if="item.spec_emote.match('^(\\d{17,20})$')" :src="'https://cdn.discordapp.com/emojis/' + item.spec_emote + '.png?v=1'" width="20" height="20" class="" />
					  <div v-else>{{item.spec_emote}}</div>
					</v-avatar>
					<span :style="'color:' + getClassColor(item, true)">{{item.spec}}</span>
				  </template>
				  <template v-slot:item="{ item }">
					<v-avatar tile width="20" height="20">
					  <img v-if="item.spec_emote.match('^(\\d{17,20})$')" :src="'https://cdn.discordapp.com/emojis/' + item.spec_emote + '.png?v=1'" width="20" height="20" class="" />
					  <div v-else>{{item.spec_emote}}</div>
					</v-avatar>
					<span :style="'color:' + getClassColor(item, true)">{{item.spec}}</span>
				  </template>
				</v-select></v-card>
				
					</v-col>
				</v-row>
		
		
			<div style="background-color: #2f3136; text-align: right" class="pa-2 mb-0">
				<v-btn elevation="0" @click="settingsOverlay = false;" color="green" right class="mx-2 my-2">OK</v-btn>
			</div>
	</v-card>
</v-dialog>

<v-dialog transition="slide-y-transition" v-model="overlay3" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
	<v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
		<v-card-text class="mt-2 pa-4" style="font-size: 1.3em; color: white">Edit name</v-card-text>
		<v-card-text class="mt-n5 pa-4" style="font-size: 0.8em">Enter a new name for this group.</v-card-text>
		<v-text-field flat autofocus background-color="#4a4e57" solo @keydown.enter="createlogAction('partyNameEntered',{partyNameTarget: partyNameTarget, partyNameEntered: partyNameEntered}); partyNames[partyNameTarget] = partyNameEntered;  partyNameEntered = '';   updateRaidDB(); overlay3 = false;" hide-details :placeholder="partyNames[partyNameTarget]" v-model="partyNameEntered" class="pa-4 mb-2" full-width single-line>
		</v-text-field>
		<div style="background-color: #2f3136; text-align: right" class="pa-2 mb-0">
			<v-btn elevation="0" @click="partyNameEntered = '';overlay3 = false;" right plain class="mx-1 my-2" :ripple="false" >Cancel</v-btn>
			<v-btn elevation="0" @click="createlogAction('partyNameEntered',{partyNameTarget: partyNameTarget, partyNameEntered: partyNameEntered}); partyNames[partyNameTarget] = partyNameEntered;  partyNameEntered = '';   updateRaidDB(); overlay3 = false;" right color="green" class="mx-2 my-2" >Save</v-btn>
		</div>
	</v-card>
</v-dialog>

<v-dialog transition="slide-y-transition" v-model="overlay4" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
	<v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
		<v-card-text class="mt-2 pa-4" style="font-size: 1.3em; color: white">Edit title</v-card-text>
		<v-card-text class="mt-n5 pa-4" style="font-size: 0.8em">Enter a new title.</v-card-text>
		<v-text-field flat autofocus background-color="#4a4e57" solo @keydown.enter="title = editedTitle; createlogAction('editTitle',editedTitle); updateRaidDB(); overlay4 = false;" hide-details :placeholder="title" v-model="editedTitle" class="pa-4 mb-2" full-width single-line>
		</v-text-field>
		<div style="background-color: #2f3136; text-align: right" class="pa-2 mb-0">
			<v-btn elevation="0" @click="overlay4 = false;" right plain class="mx-1 my-2" :ripple="false" >Cancel</v-btn>
			<v-btn elevation="0" @click="title = editedTitle; createlogAction('editTitle',editedTitle); updateRaidDB(); overlay4 = false;" right color="green" class="mx-2 my-2" >Save</v-btn>
		</div>
	</v-card>
</v-dialog>

<v-dialog transition="slide-y-transition" v-model="overlay7" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
	<v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
		<v-card-text class="mt-2 pa-4" style="font-size: 1.3em; color: white">Edit divider name</v-card-text>
		<v-card-text class="mt-n5 pa-4" style="font-size: 0.8em">Enter a name for the divider.</v-card-text>
		<v-text-field flat autofocus background-color="#4a4e57" solo @keydown.enter="createlogAction('dividerName',{selectedDivider:selectedDivider, dividerName:dividerName}); divideArray[selectedDivider] = dividerName; updateRaidDB(); overlay7 = false;" hide-details :placeholder="divideArray[selectedDivider] + ''" v-model="dividerName" class="pa-4 mb-2" full-width single-line>
		</v-text-field>
		<v-col style="background-color: #2f3136;">
			<v-row justify="end">
		<v-col cols="6">
			<v-btn elevation="0" @click="dividerDelete(); updateRaidDB(); overlay7 = false;" left color="red" class="mx-2 my-2 mr-10" >Delete</v-btn>
		</v-col>
		<v-col cols="6" style=" text-align: right">
			
			<v-btn elevation="0" @click="overlay7 = false;" right plain class="mx-1 my-2" :ripple="false" >Cancel</v-btn>
			<v-btn elevation="0" @click="createlogAction('dividerName',{selectedDivider:selectedDivider, dividerName:dividerName}); divideArray[selectedDivider] = dividerName; updateRaidDB(); overlay7 = false;" right color="green" class="mx-2 my-2" >Save</v-btn>
		</v-col>
		</v-row>
		</v-col>
	</v-card>
</v-dialog>

<v-dialog transition="slide-y-transition" v-model="overlay8" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
	<v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
		<v-card-text class="mt-2 pa-4" style="font-size: 1.3em; color: white">Announce Composition</v-card-text>
		<v-card-text class="mt-n5 pa-4" style="font-size: 0.8em">Sends a message to the selected discord channel.<br>The message supports Raid-Helper <a href="https://raid-helper.dev/documentation/reference#shortcodes" style="text-decoration: none; color: grey;" target="_blank">shortcodes</a> and <a href="https://raid-helper.dev/documentation/reference#markdown" style="text-decoration: none; color: grey;" target="_blank">markdown</a>.</v-card-text>
				<v-row class="ma-0 mt-n4">
				<v-col cols="12">
			<v-select v-if="$store.state.discordChannels" height="40" hide-details item-text="id" item-value="id" solo background-color="#4a4e57" flat dense placeholder="Select a channel." item-color="yellow" v-model="announceChannel" :items="getChannels()" class="py-1 pl-1 pr-1 mb-n5">
				 <template v-slot:selection="{ item }">
					<v-avatar tile width="20" height="20">
					  <img :src="'https://cdn.discordapp.com/emojis/' + getChannelIconByType(item.type) + '.png?v=1'" width="20" height="20" class="ml-n7" />
					</v-avatar>
					 <span class="ml-n5" style="font-size: 14px;">{{ item.name }}</span>
				  </template>
				  <template v-slot:item="{ item }"> 
					<v-avatar tile width="20" height="20">
					  <img :src="'https://cdn.discordapp.com/emojis/' + getChannelIconByType(item.type) + '.png?v=1'" width="20" height="20" class="ml-n7" />
					</v-avatar>
					<span class="ml-n5" style="font-size: 14px;">{{ item.name }}</span>
				  </template>

			</v-select>
				  </v-col>
				  <v-col cols="12">
					<v-textarea rows="4" flat dense background-color="#4a4e57" solo hide-details placeholder="Enter a message (optional)" v-model="announceMessage" class="pa-1" full-width>
					</v-textarea>
				  </v-col>
				<v-col cols="12" class="mt-n5">
				   <v-switch style="float: left; margin-top: 7px;" label="Ping all included members" color="green lighten-1" v-model="mentionUsers" hide-details class="ml-2"></v-switch>    
				  </v-col>
					
					<v-hover v-slot="{ hover }">

				

						<v-card width="100%" color="transparent" elevation="0" class="mb-5">
							<v-container >
    <v-row class="ml-n3 mt-n4">
        <v-col cols="12" class="mt-n5">
            <v-switch :disabled="hasPremium === false" style="float: left; margin-top: 7px;" :label="hasPremium === true ? 'Create syncing announcement' : 'Create syncing announcement (Requires Premium)'" color="purple" v-model="wantsToCreateUpdatingAnnounceNoConfirmation" @change="wantsToCreateUpdatingAnnounceNoConfirmation ? wantsToCreateUpdatingAnnounce = false : ''" hide-details class="ml-2"></v-switch>    
        </v-col>
    </v-row>
    <v-row class="ml-n3  mt-2">
        <v-col cols="12" class="mt-n5">
            <v-switch :disabled="hasPremium === false" style="float: left; margin-top: 7px;" :label="hasPremium === true ? 'Create syncing announcement with confirmation buttons' : 'Create syncing announcement with confirmation buttons (Requires Premium)'" color="purple" v-model="wantsToCreateUpdatingAnnounce" @change="wantsToCreateUpdatingAnnounce ? wantsToCreateUpdatingAnnounceNoConfirmation = false : ''" hide-details class="ml-2"></v-switch>    
        </v-col>
    </v-row>
	<v-row class="ml-n3  mt-2">
        <v-col cols="12" class="mt-n5">
            <v-switch :disabled="hasPremium === false" style="float: left; margin-top: 7px;" :label="hasPremium === true ? 'Schedule' : 'Schedule (Requires Premium)'" color="purple" v-model="wantsToScheduleAnnounce" hide-details class="ml-2"></v-switch>    
        </v-col>
		<v-col cols="12" v-if="wantsToScheduleAnnounce">
            <v-text-field flat dense background-color="#4a4e57" type="number" solo hide-details placeholder="Enter a time in minutes before the event." v-model="announcementTime" class="pa-1 mt-n3 mb-n3" full-width>
             </v-text-field>
        </v-col>
	</v-row>
    <v-row class="ml-n3 mt-n1">
        <v-col cols="12" class="mt-n5">
            <v-overlay :z-index="0" v-if="hover && hasPremium === false" absolute opacity="0.2" color="primary" class="mx-4">
                <v-btn elevation="0" color="purple" @click="$router.push('/premium/', () => {})"> <span><v-icon small color="yellow" class="pb-1">mdi-crown-outline</v-icon>  Get Premium!</span></v-btn>
            </v-overlay>
        </v-col>
    </v-row>

	<v-col v-if="eventObject.compAnnouncement != undefined" class="mt-10 px-5">
               <v-row>
             <v-divider style="background-color: #41444b;"></v-divider>
               </v-row>
             </v-col>

			 <v-row v-if="eventObject.compAnnouncement != undefined" justify="space-between" class="pt-1">
                <v-col class="pa-0 mb-n1">
                      <v-col>
                        <div style="overflow: hidden; text-align: center;">
                        <div style="display: inline-block; font-size: .8em; color: #e3e3e3;"><span v-html="getAnnouncementTime()"></span></div>
                        </div>
                      </v-col>
                </v-col>
              </v-row>

			 <v-row v-if="eventObject.compAnnouncement != undefined" justify="end" class="pt-n3">
			  	<v-col cols="12" style="text-align: center;">
                    <v-btn elevation="0" @click="removeAnnouncement();" left color="red" style="font-weight: 700;" >Delete</v-btn>
                </v-col>
			</v-row>
</v-container>
</v-card>

				</v-hover>


			   </v-row>
		<v-col style="background-color: #2f3136;">
			<v-row justify="end">

		<v-col cols="6" style=" text-align: right">
			<v-btn elevation="0" @click="overlay8 = false;" right plain class="mx-1 my-2" :ripple="false" >Cancel</v-btn>
			<v-btn elevation="0" @click="overlay8 = false; sendAnnouncement()" right color="green" class="mx-2 my-2" >Send</v-btn>
		</v-col>
		</v-row>
		</v-col>
	</v-card>
</v-dialog>

<v-dialog transition="slide-y-transition" v-model="editPermsOverlay" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
	<v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
		<v-card-text class="mt-2 pa-4" style="font-size: 1.3em; color: white">Edit Permissions</v-card-text>
		<v-card-text class="mt-n3 pt-4 pa-2 px-4" style="font-size: 0.9em">This raidplan can be edited by members that:</v-card-text>
		<v-card-text>· have the admin or manage server permission<br>· have a manager role<br>· have an assistant role and are the leader of the event.</v-card-text>
		<v-card-text v-if="!$store.state.authenticated" class="mt-10" style="text-align: center; font-size: 1.2em;">You also need to be logged in. Click below to login!</v-card-text>
		<div v-if="!$store.state.authenticated" class="mb-10" style="text-align: center;"><v-btn elevation="0" color="purple" @click="login()">LOGIN</v-btn></div>
		<div style="background-color: #2f3136; text-align: right" class="pa-2 mb-0">
			<v-btn elevation="0" @click="editPermsOverlay = false;" right plain class="mx-1 my-2" :ripple="false" >Close</v-btn>
		</div>
	</v-card>
</v-dialog>

<v-dialog transition="slide-y-transition" v-model="overlay5" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="300" dark>
	<v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
		<v-card-text class="mt-2 pa-4" style="font-size: 1.3em; color: white">Are you sure ?</v-card-text>
		<v-card-text class="mt-n5 pa-4" style="font-size: 0.8em">The Raidplan will be reset if you confirm.</v-card-text>
		<div style="background-color: #2f3136; text-align: right" class="pa-2 mb-0">
			<v-btn elevation="0" @click="overlay5 = false;" right plain class="mx-1 my-21" :ripple="false" >Cancel</v-btn>
			<v-btn elevation="0" @click="resetRaidplan(); overlay5 = false;" right color="green" class="mx-2 my-1" >Confirm</v-btn>
		</div>
	</v-card>
</v-dialog>

<v-dialog transition="slide-y-transition" v-model="firefoxOverlay" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="1000" dark>
	<v-card  color="#2c2f33" style="border: 1px solid #2c2f33; overflow-y: hidden">
		<v-card-text class="mt-2 pa-4" style="font-size: 1.3em; color: white">Save Image from here (Firefox)</v-card-text>
		<div id="captureDisplay" style="text-align: center; background-color: #202025;">

	</div >
		<div style="background-color: #2f3136; text-align: right" class="pa-2 mb-0">
			<v-btn elevation="0" @click="firefoxOverlay = false; " right plain class="mx-1 my-2" :ripple="false" >Close</v-btn>
		</div>
	</v-card>
</v-dialog>

<v-dialog transition="slide-y-transition" v-model="overlay6" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
	<v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
		<v-card-text class="mt-2 pa-4" style="font-size: 1.3em; color: white">Export</v-card-text>
		<v-card-text class="mt-n5 pa-4" style="font-size: 0.8em">View the JSON data of this composition by clicking the JSON button or export the data by clicking the EXPORT button. The default export format is set to vertically aligned groups with horizontally aligned players separated by a space. Click on the expansion panel for different formatting options.</v-card-text>
							
<v-row class="ml-n1 mb-0 mr-3 pb-2" justify="space-around">
					<v-btn elevation="0" class="ma-3 mt-5" color="purple" :href="'https://raid-helper.dev/api/raidplan/' + currentHash" target="_blank">JSON</v-btn>
					</v-row>
					<v-divider class="mx-5 mb-6" style="background-color: white;"></v-divider>
			<v-row class="ml-n1 mb-0 mr-3" justify="space-around">
					<v-btn elevation="0" class="ma-3 mt-5" color="purple" @click="delimiter = 'space'; exportData(3); overlay6 = false; snackbarColor = 'green darken-1'; snackbarResultText = 'Data copied to clipboard!'; snackbar = true;">Export</v-btn>
<v-expansion-panels class="pl-5 pr-1 py-5">
<v-expansion-panel style="background-color: #2e3136 !important;">
<v-expansion-panel-header>
Additional formats
</v-expansion-panel-header>
<v-expansion-panel-content class="ml-n5">


				<v-col cols="12" sm="6" class="mb-n4">

				<v-card elevation="0" width="100%" height="80px" color="transparent" class="mx-2">
				<v-card-text align="center" class="py-0" style="background-color: #25282c; font-weight: 500;">Delimiter</v-card-text>
				<v-select class="mt-1" hide-details dense item-color="yellow" solo v-model="delimiter" :items="['space', ',', ';']" background-color="#25282c" flat>

				</v-select></v-card>


					  </v-col>
				<v-col cols="12">
					<v-hover v-slot="{ hover }">
					<v-card :ripple="false" :style="hover ? 'background-color: #26262c;' : 'background-color: #202025;'" @click="exportData(1); overlay6 = false; snackbarColor = 'green darken-1'; snackbarResultText = 'Data copied to clipboard!'; snackbar = true;" elevation="0" class="mx-2 mb-n4" width="100%" color="#2f3136">
						<v-card-text>Players sorted horizontally</v-card-text>
					</v-card>
					</v-hover>
				</v-col>
				<v-col cols="12">
					<v-hover v-slot="{ hover }">
					<v-card :ripple="false" :style="hover ? 'background-color: #26262c;' : 'background-color: #202025;'" @click="exportData(2); overlay6 = false; snackbarColor = 'green darken-1'; snackbarResultText = 'Data copied to clipboard!'; snackbar = true;" elevation="0" class="mx-2 mb-n4" width="100%" color="#2f3136">
						<v-card-text>Players sorted vertically</v-card-text>
					</v-card></v-hover>
				</v-col>
				<v-col cols="12">
					<v-hover v-slot="{ hover }">
					<v-card :ripple="false" :style="hover ? 'background-color: #26262c;' : 'background-color: #202025;'" @click="exportData(4); overlay6 = false; snackbarColor = 'green darken-1'; snackbarResultText = 'Data copied to clipboard!'; snackbar = true;" elevation="0" class="mx-2 mb-n4" width="100%" color="#2f3136">
						<v-card-text>Groups sorted horizontally</v-card-text>
					</v-card></v-hover>
				</v-col>
				<v-col cols="12">
					<v-hover v-slot="{ hover }">
					<v-card :ripple="false" :style="hover ? 'background-color: #26262c;' : 'background-color: #202025;'" @click="exportData(3); overlay6 = false; snackbarColor = 'green darken-1'; snackbarResultText = 'Data copied to clipboard!'; snackbar = true;" elevation="0" class="mx-2" width="100%" color="#2f3136">
						<v-card-text>Groups sorted vertically</v-card-text>
					</v-card>
					</v-hover>
				</v-col>
					  </v-expansion-panel-content>
</v-expansion-panel>
</v-expansion-panels>
			</v-row>

				<v-container>
				  <v-row style="background-color: #2f3136;" class="px-1 pt-1">
					  <v-col align="end">
					  <v-btn elevation="0" @click="overlay6 = false;" :ripple="false" plain class="ma-1">Close</v-btn>
					  </v-col>
				  </v-row>
				</v-container>
				
	</v-card>
</v-dialog>

<v-dialog transition="slide-y-transition" v-model="overlayOptions" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
		  <RaidplanProfiles id="RaidplanProfiles" @closeOverlay="closeOverlayNow" />
				<v-container>
				  <v-row style="background-color: #2f3136;">
					  <v-col align="end">
					<v-btn elevation="0" @click="overlayOptions = false;" class="mr-2 ma-1" :ripple="false" color="green">OK</v-btn>
					  </v-col>
				  </v-row>
				</v-container>
		</v-dialog>

		<v-dialog transition="slide-y-transition" v-model="overlay2" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
			<v-card color="#3b3e45" style="border: 1px solid #2c2f33;">
			  <v-card-title >Color</v-card-title>
			  <v-color-picker class="pa-2" show-swatches swatches-max-height="230" hide-canvas width="100%" :style="'background-color: transparent;'" v-model="overlayDropColor"></v-color-picker>
				<div style="background-color: #2f3136; text-align: right;" class="pa-2">
					<v-btn elevation="0" @click="overlay2 = false; updateColors();" right color="green" class="ma-2">OK</v-btn>
				</div>
			</v-card>
		</v-dialog>		

		<v-dialog transition="slide-y-transition" v-model="overlayShowLogs" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="800" max-height="600" scrollable dark>
			<v-card color="#3b3e45" style="border: 1px solid #2c2f33;" max-height="600" >
			  <v-card-title style="background-color: #2f3136;">Log</v-card-title>
			  <v-card-text class="mt-2 pa-6" style="font-size: 0.8em; color: white" >
				<div v-for="(log,i) in logging" :key="i" class="my-0" >
					<span v-if="log.type">	

						<span v-if="log.type == 'newDayLine'"> 
							<br/>
							<v-row class="mx-0">
						<v-divider style="background-color: #b4b6b8;"></v-divider>
							<v-btn elevation="0" plain tile x-small color="white" :ripple="false" class="mx-2" style="margin-top: -10px !important; cursor: default;">{{ new Date(log.timestamp).toLocaleDateString([], { weekday: 'long', month: 'long', day: 'numeric' }) }}</v-btn>
						<v-divider style="background-color: #b4b6b8;"></v-divider>
							</v-row>
							<br/>
						</span>

						<v-tooltip name="timestamp" transition="v-scale-transition" color="#0f0f0f" top>
							<template v-slot:activator="{ on, attrs }">
									<span v-if="log.type != 'newDayLine'" v-bind="attrs" v-on="on" style="color: rgb(255, 255, 255);">{{ new Date(log.timestamp).toLocaleTimeString(['en-GB'], { hour: '2-digit', minute: '2-digit' }) }} </span> 
	             			</template><span>{{ new Date(log.timestamp).toLocaleDateString([], { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }} {{ new Date(log.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }}</span>
						</v-tooltip>

						<span style="color: rgb(255, 255, 255);" v-if="log.username">| {{ (log.username.split('#')[0].charAt(0).toUpperCase() + log.username.split('#')[0].slice(1)) }} </span>


						<span style="color: rgb(173, 173, 173);" v-if="log.type == 'editTitle' || log.type == 'loadedRaidplan' || log.type == 'savedRaidplan' || log.type == 'deletedRaidplan'"> {{ log.string }} <span :style="'color: rgb(' + getEventObjectColor() + ') ;font-weight:bold;'"> {{ log.title }}</span></span>

						<span style="color: rgb(173, 173, 173);" v-if="log.type == 'editPermissions' || log.type == 'showRoles' || log.type == 'showClasses'"> {{ log.string }} <span :style="'color: rgb(' + getEventObjectColor() + ') ;font-weight:bold;'"> {{ log.value }}</span></span>

						<span style="color: rgb(173, 173, 173);" v-if="log.type == 'resetRaidplan' || log.type == 'retrieveAll' || log.type == 'importAll'"> {{ log.string }}.</span>

						<span style="color: rgb(173, 173, 173);" v-if="log.type == 'addParty' || log.type == 'removeParty' || log.type == 'addSlot' || log.type == 'removeSlot'"> {{ log.string }} <span :style="'color:rgb(' + getEventObjectColor() + ');font-weight:bold;'">{{ log.size }}</span></span>

						<span style="color: rgb(173, 173, 173);" v-if="log.type == 'partyNameEntered'"> {{ log.string }} <span :style="'color:rgb(' + getEventObjectColor() + ');font-weight:bold;'">{{ log.partyNameTarget }} </span> to <span :style="'color:rgb(' + getEventObjectColor() + ');font-weight:bold;'">{{ log.partyNameEntered }}</span></span>


						<span  v-if="log.type == 'editSlot'">
								<span style="color: rgb(173, 173, 173);"> {{ log.string }} </span>

							
							
							
							<span style="vertical-align: middle;" > 
								<v-tooltip  transition="v-scale-transition" color="#0f0f0f" top>
									<template v-slot:activator="{ on, attrs }">
										<v-img class="mr-1" v-bind="attrs" v-on="on" style="display:inline-block;" :src="iconize(log.oldClass_emote)" max-height="17" max-width="17" v-if="log.oldClass_emote"></v-img>
									</template><span>{{ log.oldClass }} </span>
								</v-tooltip>

								<v-tooltip  transition="v-scale-transition" color="#0f0f0f" top>
									<template v-slot:activator="{ on, attrs }">
										<v-img v-bind="attrs" v-on="on" style="display:inline-block;" :src="iconize(log.oldSpec_emote)" max-height="17" max-width="17" v-if="log.oldSpec_emote"></v-img> 
									</template><span>{{ log.oldSpec }}</span>
								</v-tooltip>	
							</span>  
								<span class="ml-1" v-if="log.oldName" :style="'color:' + log.oldColor">{{ log.oldName }} </span>

								<span style="color: rgb(173, 173, 173);"> to </span>

							<span style="vertical-align: middle;" >
								<v-tooltip  transition="v-scale-transition" color="#0f0f0f" top>
									<template v-slot:activator="{ on, attrs }">
										<v-img class="mr-1" v-bind="attrs" v-on="on" style="display:inline-block;" :src="iconize(log.newClass_emote)" max-height="17" max-width="17" v-if="log.newClass_emote"></v-img>
									</template><span>{{ log.newClass }}</span>
								</v-tooltip>

								<v-tooltip  transition="v-scale-transition" color="#0f0f0f" top>
									<template v-slot:activator="{ on, attrs }">
										<v-img v-bind="attrs" v-on="on" style="display:inline-block;" :src="iconize(log.newSpec_emote)" max-height="17" max-width="17" v-if="log.newSpec_emote"></v-img> 
									</template><span>{{ log.newSpec }}</span>
								</v-tooltip>	
							</span> 
								<span class="ml-1" v-if="log.newName" :style="'color:' + log.newColor">{{ log.newName }} </span>


								<span  style="color: rgb(173, 173, 173);">at position <span :style="'color:rgb(' + getEventObjectColor() + ');font-weight:bold;'">{{ log.slotId }} </span> in <span :style="'color:rgb(' + getEventObjectColor() + ');font-weight:bold;'">{{ log.partyId }}</span></span> 
							</span>

							<span  v-if="log.type == 'addOnClick' || log.type == 'swapTarget' || log.type == 'isConfirmed'">
								<span style="color: rgb(173, 173, 173);"> {{ log.string }} </span>
							<span style="vertical-align: middle;">
								<v-tooltip  transition="v-scale-transition" color="#0f0f0f" top>
									<template v-slot:activator="{ on, attrs }">
										<v-img class="mr-1" v-bind="attrs" v-on="on" style="display:inline-block;" :src="iconize(log.class_emote)" max-height="17" max-width="17" v-if="log.class_emote"></v-img>
									</template><span>{{ log.class }}</span>
								</v-tooltip>

								<v-tooltip  transition="v-scale-transition" color="#0f0f0f" top>
									<template v-slot:activator="{ on, attrs }">
										<v-img v-bind="attrs" v-on="on" style="display:inline-block;" :src="iconize(log.spec_emote)" max-height="17" max-width="17" v-if="log.spec_emote"></v-img> 
									</template><span>{{ log.spec }}</span>
								</v-tooltip>	
							</span>  
								<span class="ml-1" v-if="log.name" :style="'color:' + log.color">{{ log.name }} </span>
								<span v-if=" log.type == 'isConfirmed'" style="color: rgb(173, 173, 173);">at position <span :style="'color:rgb(' + getEventObjectColor() + ');font-weight:bold;'">{{ log.slotId }} </span> in <span :style="'color:rgb(' + getEventObjectColor() + ');font-weight:bold;'">{{ log.partyId }}</span> to  <span :style="'color:rgb(' + getEventObjectColor() + ');font-weight:bold;'">{{ log.isConfirmed }}</span></span> 
								<span v-if=" log.type != 'isConfirmed'" style="color: rgb(173, 173, 173);"> to position <span :style="'color:rgb(' + getEventObjectColor() + ');font-weight:bold;'">{{ log.slotId }} </span> in <span :style="'color:rgb(' + getEventObjectColor() + ');font-weight:bold;'">{{ log.partyId }}</span></span> 
								<span v-if="log.hasOwnProperty('oldSlotId') && log.hasOwnProperty('oldPartyId')" style="color: rgb(173, 173, 173);"> from position <span :style="'color:rgb(' + getEventObjectColor() + ');font-weight:bold;'">{{ log.oldSlotId }} </span> in <span :style="'color:rgb(' + getEventObjectColor() + ');font-weight:bold;'">{{ log.oldPartyId }}</span></span> 
							</span>

							<span  v-if="log.type == 'delete' || log.type == 'deleteDrag' || log.type == 'swapStart'">
								<span style="color: rgb(173, 173, 173);"> {{ log.string }} position <span :style="'color:rgb(' + getEventObjectColor() + ');font-weight:bold;'">{{ log.slotId }} </span> in <span :style="'color:rgb(' + getEventObjectColor() + ');font-weight:bold;'">{{ log.partyId }}</span></span>
							</span>

							<span v-if="log.type == 'emptyGroup'"> 
								<span style="color: rgb(173, 173, 173);"> {{ log.string }} <span :style="'color: rgb(' + getEventObjectColor() + ') ;font-weight:bold;'"> {{ log.partyId }}</span></span>
							</span>

							<span v-if="log.type == 'setDividerCurrent' || log.type == 'dividerDelete'"> 
								<span style="color: rgb(173, 173, 173);">  {{ log.string }} <span :style="'color: rgb(' + getEventObjectColor() + ') ;font-weight:bold;'"> {{ log.partyId }}</span></span>
							</span>

							<span v-if="log.type == 'dividerName'"> 
								<span style="color: rgb(173, 173, 173);">  {{ log.string }} <span :style="'color: rgb(' + getEventObjectColor() + ') ;font-weight:bold;'"> {{ log.selectedDivider }} </span>to<span :style="'color: rgb(' + getEventObjectColor() + ') ;font-weight:bold;'"> {{ log.dividerName }}</span></span>
							</span>

					
					</span>

					<div class="mt-n5" v-if="log.type == 'swapTarget' && log.hasOwnProperty('oldName')">	
					<br/>


					<v-tooltip name="timestamp" transition="v-scale-transition" color="#0f0f0f" top>
						<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on" style="color: rgb(255, 255, 255);">{{ new Date(log.timestamp).toLocaleTimeString(['en-GB'], { hour: '2-digit', minute: '2-digit' }) }} </span> 
						</template><span>{{ new Date(log.timestamp).toLocaleDateString([], { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }} {{ new Date(log.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }}</span>
					</v-tooltip>

					<span style="color: rgb(255, 255, 255);" v-if="log.username">| {{ (log.username.split('#')[0].charAt(0).toUpperCase() + log.username.split('#')[0].slice(1)) }}</span>
						<span>
							<span style="color: rgb(173, 173, 173);"> {{ log.string }} </span>
						<span style="vertical-align: middle;" >
							<v-tooltip  transition="v-scale-transition" color="#0f0f0f" top>
								<template v-slot:activator="{ on, attrs }">
									<v-img class="mr-1" v-bind="attrs" v-on="on" style="display:inline-block;" :src="iconize(log.oldClass_emote)" max-height="17" max-width="17" v-if="log.oldClass_emote"></v-img>
								</template><span>{{ log.oldClass }}</span>
							</v-tooltip>

							<v-tooltip  transition="v-scale-transition" color="#0f0f0f" top>
								<template v-slot:activator="{ on, attrs }">
									<v-img v-bind="attrs" v-on="on" style="display:inline-block;" :src="iconize(log.oldSpec_emote)" max-height="17" max-width="17" v-if="log.oldSpec_emote"></v-img> 
								</template><span>{{ log.oldSpec }}</span>
							</v-tooltip>	
						</span>  
							<span class="ml-1" v-if="log.oldName" :style="'color:' + log.oldColor">{{ log.oldName }} </span>
							<span style="color: rgb(173, 173, 173);"> to position <span :style="'color:rgb(' + getEventObjectColor() + ');font-weight:bold;'">{{ log.oldSlotId }} </span> in <span :style="'color:rgb(' + getEventObjectColor() + ');font-weight:bold;'">{{ log.oldPartyId }}</span></span> 
							<span style="color: rgb(173, 173, 173);"> from position <span :style="'color:rgb(' + getEventObjectColor() + ');font-weight:bold;'">{{ log.slotId }} </span> in <span :style="'color:rgb(' + getEventObjectColor() + ');font-weight:bold;'">{{ log.partyId }}</span></span> 
						</span>


						

					</div>
				</div>
				</v-card-text>
			  	
				<div style="background-color: #2f3136; text-align: right;" class="pa-2">
					<v-btn elevation="0" @click="overlayShowLogs = false;" right color="green" class="ma-2">OK</v-btn>
				</div>
			</v-card>
		</v-dialog>	

		<v-snackbar v-if="snackbarResultText != undefined" v-model="snackbar" :color="snackbarColor" :timeout="4000" >
              <div v-for="(text, index) in snackbarResultText.split('\n')" :key="index">
              {{ text }}
              </div>
            
            </v-snackbar>
</v-container>

</template>
<script>
import { customAlphabet } from "nanoid/non-secure";
import axios from "axios";
import html2canvas from "html2canvas";
import DashNavi from "../../components/DashNavi";
import RaidplanProfiles from "../raidplan/RaidplanProfiles";
import { diff, addedDiff, deletedDiff, updatedDiff, detailedDiff } from 'deep-object-diff';
export default {
	components: {
		DashNavi,
		RaidplanProfiles
	},
	data() {
		return {
			logging: [],
			currentReq: undefined,
			newReq:undefined,
			overlayShowLogs: false,
			isFirefox: undefined,
			days: undefined,
      		hours: undefined,
      		minutes: undefined,
			displayRaidbuffs: false,
			resetColorsObject: { "class": "null", "spec": "Reset Colors", "class_emote": "612343589070045200", "role_emote": "612343589070045200", "spec_emote": "612343589070045200", "isConfirmed": false, "color": "#FFFFFF" },
			hasManagerPermission: false,
			hasEditPermission: false,
			overlayOptions: false,
			displayRoles: true,
			displayClasses: false,
			display_roles_working_for_template: [2,3,4,5,6,10,26,27],
			sortMode: 2,
			eventObject: undefined,
			eventExists: undefined,
			templateId: 3,
			partyPerRaid: 5,
			slotPerParty: 5,
			importedPartyPerRaid: 3,
			importedslotPerParty: 0,
			overlay: false,
			overlayName: "",
			countDownInterval: undefined,
			overlayNameEntered: "",
			overlayDrop: "",
			overlayDropColor: "",
			overlayDropColorObject: [],
			showBench: false,
			currentHash: undefined,
			isLoaded: false,
			importedEventId: undefined,
			overlay2: false,
			overlay3: false,
			overlay4: false,
			overlay5: false,
			overlay6: false,
			overlay7: false,
			overlay8: false,
			firefoxOverlay: false,
			firefoxOverlayImage: undefined,
			settingsOverlay: false,
			editPermsOverlay: false,
			componentLoaded: false,
			title: "Composition Tool",
			editedTitle: "",
			partyNames: ["Group 1", "Group 2", "Group 3", "Group 4", "Group 5"],
			partyNameEntered: "",
			partyNameTarget: null,
			sortClass: [],
			snackbar: false,
			snackbarResultText: "test",
			snackbarColor: "green darken-1",
			startDragObject: undefined,
			loadedEvent: undefined,
			timestamp: 0,
			last_updated: 0,
			firstLoad: true,
			delimiter: "space",
			notInDB: false,
			selectedSlot: undefined,
			isOutsideClick: true,
			permissionLevel: "managers",
			visibilityLevel: "everyone",
			dividerCount: 1,
			dividerCurrent: 5,
			dividerName: "",
			divideArray: [],
			selectedDivider: 0,
			announceChannel: undefined,
			announceMessage: "",
			mentionUsers: true,
			hasUpdatingAnnounce: false,
			wantsToCreateUpdatingAnnounce: false,
			wantsToCreateUpdatingAnnounceNoConfirmation: false,
			wantsToScheduleAnnounce: false,
			announcementTime: undefined,
			hasPremium: false,
			hoveredSlot: undefined,
			hoveredRole: undefined,
			allBuffSpells: undefined,
			currentTemplateBuffs: undefined,
			buffImagePath: undefined,
			hasUsersInComp: false,
			savedProfiles: [],
			loadedProfiles: [],

			templates: [
				{
					id: 0,
					name: "Other",
					image: "592384590375550976",
					logo: "",
					buffs: [],
					debuffs: []
				},
				{
					id: 1,
					name: "WoW Classic",
					image: "599020379125841954",
					logo: "classic",
					buffs: [],
					debuffs: []
				},
				{
					id: 37,
					name: "WoW Classic: SoD",
					image: "599020379125841954",
					logo: "classic",
					buffs: [],
					debuffs: []
				},
				{
					id: 38,
					name: "WoW Cataclysm",
					image: "599020379125841954",
					logo: "cata",
					buffs: [],
					debuffs: []
				},
				{
					id: 3,
					name: "WoW TBC",
					image: "599020379125841954",
					logo: "tbc",
					buffs: ["26991", "27127", "25392", "32999", "39374", "25898", "27145", "27141", "27169", "27143", "25895"],
					debuffs: ["19425"]
				},
				{
					id: 4,
					name: "WoW WotLK",
					image: "732179749480300546",
					logo: "wotlk",
					buffs: [],
					debuffs: []
				},
				{
					id: 5,
					name: "WoW Retail",
					image: "599020379125841954",
					logo: "",
					buffs: [],
					debuffs: []
				},
			],

			specDrag: [
				{
					class: "Death Knight",
					spec: "Blood_Tank",
					class_emote: "599012538935410701",
					spec_emote: "637564101274632192",
					role_emote: "598989638098747403",
					templates: [4, 38],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color:'#C41E3A',
				},
				{
					class: "Death Knight",
					spec: "Frost_Tank",
					class_emote: "599012538935410701",
					spec_emote: "637564101262049280",
					role_emote: "598989638098747403",
					templates: [4, 38],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color:'#C41E3A',
				},
				{
					class: "Death Knight",
					spec: "Unholy_Tank",
					class_emote: "599012538935410701",
					spec_emote: "637564101329027082",
					role_emote: "598989638098747403",
					templates: [4, 38],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color:'#C41E3A',
				},
				{
					class: "Death Knight",
					spec: "Blood_DPS",
					class_emote: "599012538935410701",
					spec_emote: "637564101274632192",
					role_emote: "734439523328720913",
					templates: [4, 38],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color:'#C41E3A',
				},
				{
					class: "Death Knight",
					spec: "Frost_DPS",
					class_emote: "599012538935410701",
					spec_emote: "637564101262049280",
					role_emote: "734439523328720913",
					templates: [4, 38],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color:'#C41E3A',
				},
				{
					class: "Death Knight",
					spec: "Unholy_DPS",
					class_emote: "599012538935410701",
					spec_emote: "637564101329027082",
					role_emote: "734439523328720913",
					templates: [4, 38],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color:'#C41E3A',
				},
				{
					class: "Death Knight",
					spec: "Blood",
					class_emote: "599012538935410701",
					spec_emote: "637564101274632192",
					role_emote: "598989638098747403",
					templates: [5],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color:'#C41E3A',
				},
				{
					class: "Death Knight",
					spec: "Frost1",
					class_emote: "599012538935410701",
					spec_emote: "637564101262049280",
					role_emote: "734439523328720913",
					templates: [5],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color:'#C41E3A',
				},
				{
					class: "Death Knight",
					spec: "Unholy",
					class_emote: "599012538935410701",
					spec_emote: "637564101329027082",
					role_emote: "734439523328720913",
					templates: [5],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color:'#C41E3A',
				},
				{
					class: "Demon Hunter",
					spec: "Vengeance",
					class_emote: "599012539015364633",
					spec_emote: "637564139371233290",
					role_emote: "598989638098747403",
					templates: [5],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color:'#A330C9',
				},
				{
					class: "Demon Hunter",
					spec: "Havoc",
					class_emote: "599012539015364633",
					spec_emote: "637564138964516867",
					role_emote: "734439523328720913",
					templates: [5],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color:'#A330C9',
				},
				{
					class: "Monk",
					spec: "Brewmaster",
					class_emote: "599012539556167691",
					spec_emote: "637564262167871489",
					role_emote: "598989638098747403",
					templates: [5],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color:'#00FF98',
				},
				{
					class: "Monk",
					spec: "Mistweaver",
					class_emote: "599012539556167691",
					spec_emote: "637564262289637433",
					role_emote: "592438128057253898",
					templates: [5],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color:'#00FF98',
				},
				{
					class: "Monk",
					spec: "Windwalker",
					class_emote: "599012539556167691",
					spec_emote: "637564262054625281",
					role_emote: "734439523328720913",
					templates: [5],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color:'#00FF98',
				},
				{
					class: "Warrior",
					spec: "Arms",
					class_emote: "579532030153588739",
					spec_emote: "637564445031399474",
					role_emote: "734439523328720913",
					templates: [1, 2, 3, 4, 5, 38],
					groupbuffs: [[], ["25289"], ["25289"], ["2048"], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color:'#C69B6D',
				},
				{
					class: "Warrior",
					spec: "Fury",
					class_emote: "579532030153588739",
					spec_emote: "637564445215948810",
					role_emote: "734439523328720913",
					templates: [1, 2, 3, 4, 5, 38],
					groupbuffs: [[], ["25289"], ["25289"], ["2048"], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color:'#C69B6D',
				},
				{
					class: "Warrior",
					spec: "Protection",
					class_emote: "579532030153588739",
					spec_emote: "637564444834136065",
					role_emote: "598989638098747403",
					templates: [1, 2, 3, 4, 5, 38],
					groupbuffs: [[], ["25289"], ["25289"], ["469"], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color:'#C69B6D',
				},
				{
					class: "Druid",
					spec: "Balance",
					class_emote: "579532029675438081",
					spec_emote: "637564171994529798",
					role_emote: "592446395596931072",
					templates: [1, 2, 3, 4, 5, 38],
					groupbuffs: [[], ["24858"], ["24858"], ["24858"], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: ["26991"],
					isConfirmed: false,
					color:'#FF7C0A',
				},
				{
					class: "Druid",
					spec: "Dreamstate",
					class_emote: "579532029675438081",
					spec_emote: "982381290663866468",
					role_emote: "592438128057253898",
					templates: [3],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: ["26991"],
					isConfirmed: false,
					color:'#FF7C0A',
				},
				{
					class: "Druid",
					spec: "Feral",
					class_emote: "579532029675438081",
					spec_emote: "637564172061900820",
					role_emote: "734439523328720913",
					templates: [1, 2, 3, 4, 5, 38],
					groupbuffs: [[], ["17007"], ["17007"], ["17007"], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: ["26991"],
					isConfirmed: false,
					color:'#FF7C0A',
				},
				{
					class: "Druid",
					spec: "Restoration",
					class_emote: "579532029675438081",
					spec_emote: "637564172007112723",
					role_emote: "592438128057253898",
					templates: [1, 2, 3, 4, 5, 38],
					groupbuffs: [[], [], [], ["33891"], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: ["26991"],
					isConfirmed: false,
					color:'#FF7C0A',
				},
				{
					class: "Druid",
					spec: "Guardian",
					class_emote: "579532029675438081",
					spec_emote: "637564171696734209",
					role_emote: "598989638098747403",
					templates: [1, 2, 3, 4, 5, 38],
					groupbuffs: [[], ["17007"], ["17007"], ["17007"], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: ["26991"],
					isConfirmed: false,
					color:'#FF7C0A',
				},
				{
					class: "Paladin",
					spec: "Holy1",
					class_emote: "579532029906124840",
					spec_emote: "637564297622454272",
					role_emote: "592438128057253898",
					templates: [1, 2, 3, 4, 5, 38],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], ["Paladin Aura"], ["Paladin Aura"], ["Paladin Aura"], [], []],
					tbcbuffs_raid: ["25898", "27145", "27141", "27169", "27143", "25895"],
					isConfirmed: false,
					color:'#F48CBA',
				},
				{
					class: "Paladin",
					spec: "Protection1",
					class_emote: "579532029906124840",
					spec_emote: "637564297647489034",
					role_emote: "598989638098747403",
					templates: [1, 2, 3, 4, 5, 38],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], ["Paladin Aura"], ["Paladin Aura"], ["Paladin Aura"], [], []],
					tbcbuffs_raid: ["25898", "27145", "27141", "27169", "27143", "25895"],
					isConfirmed: false,
					color:'#F48CBA',
				},
				{
					class: "Paladin",
					spec: "Retribution",
					class_emote: "579532029906124840",
					spec_emote: "637564297953673216",
					role_emote: "734439523328720913",
					templates: [1, 2, 3, 4, 5, 38],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], ["Paladin Aura"], ["Paladin Aura"], ["Paladin Aura"], [], []],
					tbcbuffs_raid: ["25898", "27145", "27141", "27169", "27143", "25895"],
					isConfirmed: false,
					color:'#F48CBA',
				},
				{
					class: "Rogue",
					spec: "Assassination",
					class_emote: "579532030086217748",
					spec_emote: "637564351707873324",
					role_emote: "734439523328720913",
					templates: [1, 2, 3, 4, 5, 38],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color:'#FFF468',
				},
				{
					class: "Rogue",
					spec: "Combat",
					class_emote: "579532030086217748",
					spec_emote: "637564352333086720",
					role_emote: "734439523328720913",
					templates: [1, 2, 3, 4, 38],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color:'#FFF468',
				},
				{
					class: "Rogue",
					spec: "Outlaw",
					class_emote: "579532030086217748",
					spec_emote: "794267634497486858",
					role_emote: "734439523328720913",
					templates: [5],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color:'#FFF468',
				},
				{
					class: "Rogue",
					spec: "Subtlety",
					class_emote: "579532030086217748",
					spec_emote: "637564352169508892",
					role_emote: "734439523328720913",
					templates: [1, 2, 3, 4, 5, 38],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color:'#FFF468',
				},
				{
					class: "Hunter",
					spec: "Beast Mastery",
					class_emote: "579532029880827924",
					spec_emote: "637564202021814277",
					role_emote: "592446395596931072",
					templates: [1, 2, 3, 4, 5, 38],
					groupbuffs: [[], ["24597"], ["24597"], ["34460"], [], []],
					groupbuffs_custom: [[], ["Hunter Aspect"], ["Hunter Aspect"], ["Hunter Aspect"], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color:'#AAD372',
				},
				{
					class: "Hunter",
					spec: "Marksmanship",
					class_emote: "579532029880827924",
					spec_emote: "637564202084466708",
					role_emote: "592446395596931072",
					templates: [1, 2, 3, 4, 5, 38],
					groupbuffs: [[], ["20906", "24597"], ["20906", "24597"], ["27066"], [], []],
					groupbuffs_custom: [[], ["Hunter Aspect"], ["Hunter Aspect"], ["Hunter Aspect"], [], []],
					tbcbuffs_raid: ["19425"],
					isConfirmed: false,
					color:'#AAD372',
				},
				{
					class: "Hunter",
					spec: "Survival",
					class_emote: "579532029880827924",
					spec_emote: "637564202130866186",
					role_emote: "592446395596931072",
					templates: [1, 2, 3, 4, 5, 38],
					groupbuffs: [[], ["24597"], ["24597"], [], [], []],
					groupbuffs_custom: [[], ["Hunter Aspect"], ["Hunter Aspect"], ["Hunter Aspect"], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color:'#AAD372',
				},
				{
					class: "Mage",
					spec: "Arcane",
					class_emote: "579532030161977355",
					spec_emote: "637564231545389056",
					role_emote: "592446395596931072",
					templates: [1, 2, 3, 4, 5, 38],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: ["27127"],
					isConfirmed: false,
					color:'#3FC7EB',
				},
				{
					class: "Mage",
					spec: "Fire",
					class_emote: "579532030161977355",
					spec_emote: "637564231239073802",
					role_emote: "592446395596931072",
					templates: [1, 2, 3, 4, 5, 38],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: ["27127"],
					isConfirmed: false,
					color:'#3FC7EB',
				},
				{
					class: "Mage",
					spec: "Frost",
					class_emote: "579532030161977355",
					spec_emote: "637564231469891594",
					role_emote: "592446395596931072",
					templates: [1, 2, 3, 4, 5, 38],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: ["27127"],
					isConfirmed: false,
					color:'#3FC7EB',
				},
				{
					class: "Warlock",
					spec: "Affliction",
					class_emote: "579532029851336716",
					spec_emote: "637564406984867861",
					role_emote: "592446395596931072",
					templates: [1, 2, 3, 4, 5, 38],
					groupbuffs: [[], ["11767"], ["11767"], ["11767"], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color:'#8788EE',
				},
				{
					class: "Warlock",
					spec: "Demonology",
					class_emote: "579532029851336716",
					spec_emote: "637564407001513984",
					role_emote: "592446395596931072",
					templates: [1, 2, 3, 4, 5, 38],
					groupbuffs: [[], ["11767"], ["11767"], ["11767"], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color:'#8788EE',
				},
				{
					class: "Warlock",
					spec: "Destruction",
					class_emote: "579532029851336716",
					spec_emote: "637564406682877964",
					role_emote: "592446395596931072",
					templates: [1, 2, 3, 4, 5, 38],
					groupbuffs: [[], ["11767"], ["11767"], ["11767"], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color:'#8788EE',
				},
				{
					class: "Priest",
					spec: "Discipline",
					class_emote: "579532029901799437",
					spec_emote: "637564323442720768",
					role_emote: "592438128057253898",
					templates: [1, 2, 3, 4, 5, 38],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: ["25392", "32999", "39374"],
					isConfirmed: false,
					color:'#FFFFFF',
				},
				{
					class: "Priest",
					spec: "Holy",
					class_emote: "579532029901799437",
					spec_emote: "637564323530539019",
					role_emote: "592438128057253898",
					templates: [1, 2, 3, 4, 5, 38],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: ["25392", "39374"],
					isConfirmed: false,
					color:'#FFFFFF',
				},
				{
					class: "Priest",
					spec: "Shadow",
					class_emote: "579532029901799437",
					spec_emote: "637564323291725825",
					role_emote: "592446395596931072",
					templates: [1, 2, 3, 4, 5, 38],
					groupbuffs: [[], ["15286"], ["15286"], ["34917", "15286"], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: ["25392", "39374"],
					isConfirmed: false,
					color:'#FFFFFF',
				},
				{
					class: "Priest",
					spec: "Smite",
					class_emote: "579532029901799437",
					spec_emote: "887257034066653184",
					role_emote: "592438128057253898",
					templates: [1, 2, 3, 4, 38],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: ["25392", "32999", "39374"],
					isConfirmed: false,
					color:'#FFFFFF',
				},
				{
					class: "Shaman",
					spec: "Elemental",
					class_emote: "579532030056857600",
					spec_emote: "637564379595931649",
					role_emote: "592446395596931072",
					templates: [1, 2, 3, 4, 5, 38],
					groupbuffs: [[], [], [], ["2825", "30706"], [], []],
					groupbuffs_custom: [[], ["Shaman Totems"], ["Shaman Totems"], ["Shaman Totems"], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color:'#0070DD',
				},
				{
					class: "Shaman",
					spec: "Enhancement",
					class_emote: "579532030056857600",
					spec_emote: "637564379772223489",
					role_emote: "734439523328720913",
					templates: [1, 2, 3, 4, 5, 38],
					groupbuffs: [[], ["30811#"], ["30811#"], ["2825", "30811"], [], []],
					groupbuffs_custom: [[], ["Shaman Totems"], ["Shaman Totems"], ["Shaman Totems"], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color:'#0070DD',
				},
				{
					class: "Shaman",
					spec: "Restoration1",
					class_emote: "579532030056857600",
					spec_emote: "637564379847458846",
					role_emote: "592438128057253898",
					templates: [1, 2, 3, 4, 5, 38],
					groupbuffs: [[], ["17359"], ["17359"], ["2825", "16190"], [], []],
					groupbuffs_custom: [[], ["Shaman Totems"], ["Shaman Totems"], ["Shaman Totems"], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color:'#0070DD',
				},
				{
					class: "Evoker",
					spec: "Devastation",
					class_emote: "1013161650184003744",
					spec_emote: "1013161648728592444",
					role_emote: "592446395596931072",
					templates: [5],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color:'#33937F',
				},
				{
					class: "Evoker",
					spec: "Preservation",
					class_emote: "1013161650184003744",
					spec_emote: "1013161651706544158",
					role_emote: "592438128057253898",
					templates: [5],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color: '#33937F',
				},
							{
					class: "Evoker",
					spec: "Augmentation",
					class_emote: "592446395596931072",
					spec_emote: "1127164727328510054",
					role_emote: "592446395596931072",
					templates: [5],
					groupbuffs: [[], [], [], [], [], []],
					groupbuffs_custom: [[], [], [], [], [], []],
					tbcbuffs_raid: [],
					isConfirmed: false,
					color: '#33937F',
				},
			],


			
			
			raidDrop: [],
			raidDropBench: [],
			importedSignUps: [],
		};
	},

	sockets: {
    	connect() {
    	  this.isConnected = true;
    	},
	
    	disconnect() {
    	  this.isConnected = false;
    	},
	
    	liveUpdateRaidPlan(last_updated) {
      		if (this.timestamp < last_updated) {
				this.timestamp = last_updated;
        		this.getRaidPlan();
      		}
    	},

		liveUpdateEvent(last_updated) {
			if (this.last_updated < last_updated) {
				this.last_updated = last_updated
        		this.getEvent();
      		}
		}
  },

	methods: {

		showLogs(){
			this.overlayShowLogs = true;
		},

		loadBuffCount(){
			let array = this.currentTemplateBuffs.flat(1)
			let spells = []
			for (let a = 0; a < array.length; a++) {
				spells.push(array[a].spellList)
			}
			this.allBuffSpells = spells.flat(1)
			
		},

		setTemplateBuffs(){
			if (this.isWoWCata()) {
				this.currentTemplateBuffs = this.$store.state.cataBuffArray
				this.buffImagePath = 'cataBuffs'
			}
			if (this.isWoWWotlk()) {
				this.currentTemplateBuffs = this.$store.state.wotlkBuffArray
				this.buffImagePath = 'wotlkBuffs'
			}
			if (this.isWoWTBC()) {
				this.currentTemplateBuffs = this.$store.state.tbcBuffArray
				this.buffImagePath = 'tbcBuffs'
			}

			
		},

		getAnnouncementTime() {
			try {
      			let date = new Date(this.eventObject.unixtime*1000)
      			date = new Date(this.eventObject.unixtime*1000).setMinutes(date.getMinutes() - parseInt(this.eventObject.compAnnouncement.time));
      			return "An announcement is scheduled for<br><b>" + new Date(date).toLocaleDateString([],{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) + " @ " + new Date(date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) + "</b> ";
			} catch (error) {
				return "";
			}
		},
		

		displayBuffCountWOTLK(buffSlot){
			let out = this.allBuffSpells.filter((item) => item.id == buffSlot)
			return this.raidDrop.filter((item) => (out[0].specs).includes(item.spec)).length
		},

		applyWoWColors(slot){
				if (slot.class === 'Death Knight' || slot.class === 'DK' || ['Blood', 'Unholy', 'Frost1','Blood_Tank', 'Unholy_Tank', 'Frost1_Tank'].includes(slot.spec)) {
					return '#C41E3A';
				} else if (slot.class === 'Demon Hunter' || slot.class === 'DH' || ['Havoc', 'Vengeance'].includes(slot.spec)) {
					return '#A330C9';
				} else if (slot.class === 'Evoker' || ['Devastation', 'Preservation'].includes(slot.spec)) {
					return '#33937F';
				} else if (slot.class === 'Druid' || ['Guardian', 'Feral', 'Balance', 'Restoration'].includes(slot.spec)) {
					return '#FF7C0A';
				} else if (slot.class === 'Hunter' || ['Beast Mastery', "Beastmastery", 'Survival', 'Marksmanship'].includes(slot.spec)) {
					return '#AAD372';
				} else if (slot.class === 'Mage' || ['Frost', 'Fire', 'Arcane'].includes(slot.spec)) {
					return '#3FC7EB';
				} else if (slot.class === 'Monk' || ['Brewmaster', 'Windwalker', 'Mistweaver'].includes(slot.spec)) {
					return '#00FF98';
				} else if (slot.class === 'Paladin' || ['Holy1', 'Retribution', 'Protection1'].includes(slot.spec)) {
					return '#F48CBA';
				} else if (slot.class === 'Priest' || ['Holy', 'Discipline', 'Shadow', 'Smite'].includes(slot.spec)) {
					return '#FFFFFF';
				} else if (slot.class === 'Rogue' || ['Assassination', 'Combat', 'Subtlety'].includes(slot.spec)) {
					return '#FFF468';
				} else if (slot.class === 'Shaman' || ['Enhancement', 'Elemental', 'Restoration1'].includes(slot.spec)) {
					return '#0070DD';
				} else if (slot.class === 'Warlock' || ['Demonology', 'Destruction', 'Affliction'].includes(slot.spec)) {
					return '#8788EE';
				} else if (slot.class === 'Warrior' || ['Arms', 'Fury', 'Protection'].includes(slot.spec)) {
					return '#C69B6D';
				} else if ( slot.class === 'Bench' || slot.class === 'Late' || slot.class === 'Tentative' || slot.class === 'Absence' ){
					return '#FFFFFF'
			}
			
		},

		checkIfColorReset(overlayDrop){
			if (overlayDrop.spec == "Reset Colors" || overlayDrop == "Init") {
				this.overlayDropColorObject = this.getEventClassesAndSpecs()
				if (this.isWoW()) {
					this.overlayDropColorObject.map((item) => {item.color = this.applyWoWColors(item)})
					this.raidDrop.map((item) => {item.color = this.applyWoWColors(item)})
					this.importedSignUps.map((item) => {item.color = this.applyWoWColors(item)})
				} else {
					this.overlayDropColorObject.map( item => { item.color = '#FFFFFF'})
					this.raidDrop.map(item => { item.color = '#FFFFFF'})
					this.importedSignUps.map(item => { item.color = '#FFFFFF'})
				}


			} else {
				this.overlay2=!this.overlay2;
			}
			
		},

		updateCountdown() {
      if (this.eventObject) {
        if ((this.eventObject.unixtime*1000 - new Date().getTime()) < 0) {
          this.days = 0;
          this.hours = 0;
          this.minutes = 0;
        } else {
          let delta = Math.abs(this.eventObject.unixtime*1000 - new Date().getTime()) / 1000;
          this.days = Math.floor(delta / 86400);
          delta -= this.days * 86400;
          this.hours = Math.floor(delta / 3600) % 24;
          delta -= this.hours * 3600;
          this.minutes = Math.floor(delta / 60) % 60;
          delta -= this.minutes * 60;
        }
      }
    },

		getEventClassesAndSpecsColors(){
			let list = this.getEventClassesAndSpecs()
			list.unshift(this.resetColorsObject)
			return list
		},

		updateColorsOnEditClass(overlayDrop){
			if (!overlayDrop.color) {
				let colorResult = this.overlayDropColorObject.find((item) => item.spec == overlayDrop.spec)
				if (colorResult != undefined) {
					this.overlayDropColor = colorResult.color
				}
			}
			
		},

		updateColors(){
			if (this.settingsOverlay) {

				this.overlayDrop.color = this.overlayDropColor

				let classesColorToChange = this.raidDrop.filter((item) => item.spec == this.overlayDrop.spec);
				for (let i = 0; i < classesColorToChange.length; i++) {
					classesColorToChange[i].color = this.overlayDropColor
				}

				this.overlayDropColorObject = this.overlayDropColorObject.filter((item) => item.spec != this.overlayDrop.spec)
				this.overlayDropColorObject.push(this.overlayDrop)

				this.$store.state.raidplan.overlayDropColorObject = this.overlayDropColorObject
			}	
			
		},

		closeOverlayNow(variable) {
      this.overlayOptions = variable;
    },

	getEventObjectColor() {
		if (this.eventObject != undefined) {
			if (this.eventObject.color != undefined) {
				return this.eventObject.color;
			}
		}
		return "0,0,0"
	},

		getUniqueClasses() {
			
      let list = [];
        for (let i = 0; i < this.eventObject.classes.length; i++) {
          for (let k = 0; k < this.eventObject.signups.length; k++) {
             if (this.eventObject.signups[k].class_emote === this.eventObject.classes[i].emote) {
                  list.push(this.eventObject.classes[i]);
                  break;
             }
          }
        }
        return list;
    },

		getSignupsOfClass(key) {
      let signupsOfClass = [];
      let sorted = [];
      if (!this.eventObject.interval) {
        for (let i = 0; i < this.eventObject.signups.length; i++) {
          if (this.eventObject.signups[i].class_emote === key) {
            signupsOfClass.push(this.eventObject.signups[i]);
          }
        }
      }
      let specs = new Set();
      for (let i = 0; i < signupsOfClass.length; i++) {
          specs.add(signupsOfClass[i].spec);
      }

      signupsOfClass.sort(function (a, b) {
        return a.signuptime - b.signuptime;
      });

      let preSortedSpecs = [];
      specs.forEach(key => {
        let count = 0;
        let positionsCombined = 0;
        for (let i = 0; i < signupsOfClass.length; i++) {
          if (signupsOfClass[i].spec == key) {
            count++;
            positionsCombined += signupsOfClass[i].position;
          }
        }
        let average = positionsCombined / count;
        preSortedSpecs.push({spec: key, position: average});
      })

      preSortedSpecs.sort(function (a, b) {
        return a.position - b.position;
      });

      preSortedSpecs.forEach(key => {
        for (let i = 0; i < signupsOfClass.length; i++) {
          if (signupsOfClass[i].spec == key.spec) {
            sorted.push(signupsOfClass[i]);
          }
        }
      })
      return sorted;
    },	
		getRoleCountEvent(emote) {
      let counter = 0;
      if (this.eventObject.signups) {
      for (let i = 0; i < this.eventObject.signups.length; i++) {
        if ( this.eventObject.signups[i].class !== "Tentative" && this.eventObject.signups[i].class !== "Absence" && this.eventObject.signups[i].class !== "Bench" && this.eventObject.signups[i].class !== "Late") {
          if (this.eventObject.signups[i].status === "primary" && this.eventObject.signups[i].role_emote === emote) {
            counter += 1;
          }
        }
      }
      }
      return counter;
    },

	getRoleCountRaidDrop(emote, input) {
      let counter = input.filter((item) => item.role_emote && item.role_emote == emote);
	  if (this.eventObject.template == "38" && emote == "592440132129521664") {
		  counter = input.filter((item) => item.role_emote && (item.role_emote == "734439523328720913" || item.role_emote == "592446395596931072"));
	  }
      return counter.length;
    },

		iconize(input) {
			if (input === "-") {
				return " ";
			} else {
				return "https://cdn.discordapp.com/emojis/" + input + ".png?v=1";
			}
			},

		sortImportedRaid(){
			if (this.sortMode == 0) {
				let uniqueClasses = this.getUniqueClasses()
				
			let sortedClasses = []

			for (let i = 0; i < uniqueClasses.length; i++) {
				sortedClasses = sortedClasses.concat(this.getSignupsOfClass(uniqueClasses[i].emote))
			}
			this.importedSignUps = []
			let a = 1;
			let b = 1;
			for (let i = 0; i < sortedClasses.length; i++) {
						this.importedSignUps.push({
							partyId: a,
							slotId: b,
							class: sortedClasses[i].class,
							spec: sortedClasses[i].spec,
							name: sortedClasses[i].name,
							color: this.getClassColor(sortedClasses[i], false),
							spec_emote: sortedClasses[i].spec_emote,
							class_emote: sortedClasses[i].class_emote,
							role_emote: sortedClasses[i].role_emote,
							signuptime: sortedClasses[i].signuptime,
							position: sortedClasses[i].position,
							userid: sortedClasses[i].userid,
							isConfirmed: sortedClasses[i].isConfirmed,
						});

						if (b == Math.ceil(sortedClasses.length / 3)) {
							a++;
							b = 1;
						} else {
							b++;
						}
				}
				this.sortMode = 1


			} else if (this.sortMode == 1) {
				let a = 1;
				let b = 1;
				this.importedSignUps = []
				let signups = this.eventObject.signups
				signups.sort((a, b) => a.position - b.position);
				for (let i = 0; i < signups.length; i++) {
						this.importedSignUps.push({
							partyId: a,
							slotId: b,
							class: signups[i].class,
							spec: signups[i].spec,
							name: signups[i].name,
							color: this.getClassColor(signups[i], false),
							spec_emote: signups[i].spec_emote,
							class_emote: signups[i].class_emote,
							role_emote: signups[i].role_emote,
							signuptime: signups[i].signuptime,
							position: signups[i].position,
							userid: signups[i].userid,
							isConfirmed: signups[i].isConfirmed,
						});

						if (b == Math.ceil(signups.length / 3)) {
							a++;
							b = 1;
						} else {
							b++;
						}
				}
			this.sortMode = 2
			} else if (this.sortMode == 2) {
				let a = 1;
				let b = 1;
				this.importedSignUps = []
				let signups = this.eventObject.signups
	
				signups.sort((a, b) => a.name.localeCompare(b.name));
				for (let i = 0; i < signups.length; i++) {
						this.importedSignUps.push({
							partyId: a,
							slotId: b,
							class: signups[i].class,
							spec: signups[i].spec,
							name: signups[i].name,
							color: this.getClassColor(signups[i], false),
							spec_emote: signups[i].spec_emote,
							class_emote: signups[i].class_emote,
							role_emote: signups[i].role_emote,
							signuptime: signups[i].signuptime,
							position: signups[i].position,
							userid: signups[i].userid,
							isConfirmed: signups[i].isConfirmed,
						});

						if (b == Math.ceil(signups.length / 3)) {
							a++;
							b = 1;
						} else {
							b++;
						}
				}
			this.sortMode = 0
			}
		},

		range(startAt, size) {
    		let output =  [...Array(size).keys()].map(i => i + startAt);
			return output
		},
		
		setDividerCurrent(i){
			this.divideArray.splice(this.divideArray.indexOf(i),0, 'Edit Name')
			this.createlogAction("setDividerCurrent",i)
			this.updateRaidDB()
		},

		dividerDelete(){
			this.divideArray.splice(this.selectedDivider,1)
			this.createlogAction("dividerDelete",this.selectedDivider)
			this.updateRaidDB()
		},

		getBackRoute() {
			if (this.eventExists) {
				return '/event/' + this.eventObject.raidid;
			} else {
				return '/dashboard/';
			}
		},

    getChannelIconByType(type) {
      if (type === "text") {
        return "1025776950096171148";
      } else if (type === "forum") {
        return "1025776945146904629";
      } else if (type === "thread") {
        return "1025779885127516170";
      } else if (type === "post") {
        return "1025779860838301810";
      } else if (type === "news") {
        return "1025776946698784861";
      } else if (type === "stage") {
        return "1025776948288426068";
      } else if (type === "dm") {
        return "1026094422015877171";
      } else {
        return "1025776951627100210";
      }
    },

	getChannels() {
      let channelNameList = [];

      try {
      for (let index = 0; index < this.$store.state.discordChannels.length; index++) {
        let signedChannel = {
          id: this.$store.state.discordChannels[index].id,
          name: this.$store.state.discordChannels[index].name,
          position: this.$store.state.discordChannels[index].position,
		  type: this.$store.state.discordChannels[index].type,
        };
		if (this.$store.state.discordChannels[index].type !== "forum") {
        	channelNameList.push(signedChannel);
		}
      }
      } catch { }

      return channelNameList;
    },	

	truncate(input) {
      if (input.length > 19) {
        return input.substring(0, 20) + "..";
      }
      return input;
    },

    truncate1(input) {
      if (input.length > 39) {
        return input.substring(0, 40) + "..";
      }
      return input;
    },

    truncate2(input) {
      if (input.length > 69) {
        return input.substring(0, 70) + "..";
      }
      return input;
    },

		copyAsImage() {

			let source = document.querySelector('#capture')
			let options = {
				allowTaint: true,
				useCORS: true,
				logging: false,
				scale: 3,
				y: -5,
				windowWidth: 1200,
				backgroundColor: '#202025'
			}

			if (navigator.userAgent.includes('Firefox')) {
				this.firefoxOverlay = true

				html2canvas(source, options).then(canvas => {
					document.querySelector('#captureDisplay').appendChild(canvas)
				});
			} else {
				if (navigator.userAgent.includes('Firefox')) {
					html2canvas(source, options).then(function (canvas) {
						test = canvas.toDataURL('image/png').toString()
					})

				} else {
					const img = html2canvas(source, options).then(canvas => new Promise(resolve => canvas.toBlob(resolve)));
					navigator.clipboard.write([new ClipboardItem({["image/png"]: img})]);
				}
			}
		},

		clearImageFirefox(){
			if (navigator.userAgent.includes('Firefox') && document.getElementById("captureDisplay")) {
			let elem = document.getElementById("captureDisplay")
			elem.removeChild(elem.lastChild)
			}
		},

    	login() {
			localStorage.setItem('lastPage', this.$router.currentRoute.path)
			window.location.href = this.$store.state.url + "/api/auth/discord";
        return;
      	},
		getPosition(pos) {
    	  if (pos < 10) {
    	    pos = "0" + pos;
    	  }
	
    	  return pos;
    	},

		getTooltip(one, two, isClassOnly) {

			if (this.displayClasses) {

				if (isClassOnly) {
					return two
				} else {
					return one
				}
			}

			if (one === undefined || two === undefined) {
				return "error";
			}

			if (two === 'Bench' || two === 'Late' || two === 'Tentative' || two === 'Absence') {
     		  two = this.eventObject.classes.filter(obj => {
  				return obj.name === two
				})[0].effective_name;
     		}

			if (one !== two) {
				return one.replace("1", "") + " " + two;
			} else {
				return one.replace("1", "");
			}
		},

		getExpShortcut() {
			if (this.templateId === 1 || this.templateId === 2) {
				return "classic";
			} else {
				return "tbc";
			}
		},

		getEndTime() {
      if (this.eventObject.advanced.duration === '' || this.eventObject.advanced.duration === 0 || this.eventObject.advanced.duration === "0" || this.eventObject.advanced.duration === undefined) {
        return ""
      } else {
        if (this.eventObject.advanced.duration < 1440) {
          return " - " + new Date(this.eventObject.unixtime*1000 + 1000*60*parseInt(this.eventObject.advanced.duration)).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
        } else {
          return " - " + new Date(this.eventObject.unixtime*1000 + 1000*60*parseInt(this.eventObject.advanced.duration)).toLocaleDateString([],{ month: 'long', day: 'numeric', hour: '2-digit', minute:'2-digit' })
        }
      }
    },


	iconize(input) {
      if (input === "-") {
        return " ";
      } else if (input === '612373441051361353') {
        return "https://cdn.discordapp.com/emojis/" + this.eventObject.advanced.bench_emote + ".png?v=1";
      } else if (input === '612373443551297689') {
        return "https://cdn.discordapp.com/emojis/" + this.eventObject.advanced.late_emote + ".png?v=1";
      } else if (input === '676284492754976788') {
        return "https://cdn.discordapp.com/emojis/" + this.eventObject.advanced.tentative_emote + ".png?v=1";
      } else if (input === '612343589070045200') {
        return "https://cdn.discordapp.com/emojis/" + this.eventObject.advanced.absence_emote + ".png?v=1";
      } else if (input.match("^(\\d{17,20})$")) {
        return "https://cdn.discordapp.com/emojis/" + input + ".png?v=1";
      } else {
        return input;
      }
    },

	iconize2(input) {
		let value = input;
		      if (input === "-") {
        return " ";
      } else if (input === '612373441051361353') {
        value = this.eventObject.advanced.bench_emote;
      } else if (input === '612373443551297689') {
        value = this.eventObject.advanced.late_emote;
      } else if (input === '676284492754976788') {
        value = this.eventObject.advanced.tentative_emote;
      } else if (input === '612343589070045200') {
        value = this.eventObject.advanced.absence_emote;
      }
		
	  return value;
	},

		getClassColor(slot, imported) {
		
			try {
			if (slot.class === 'Bench' || slot.class === 'Late' || slot.class === 'Tentative' || slot.class === 'Absence' ){
				return '#FFFFFF'
			}
			
			if (slot.color || imported) {
				if (slot.color != null && slot.color != undefined) {	
					return slot.color
				} else {
					let colorResult = this.overlayDropColorObject.find((item) => item.spec == slot.spec);
					if (colorResult == undefined) {
						this.checkIfColorReset("Init")
						let colorResult = this.overlayDropColorObject.find((item) => item.class == slot.class)
						return colorResult.color
					} else {
						return colorResult.color
					}
				}
			}

		} catch (error) {
				console.log(error)
			}
		},

		

		isWoW() {
    	  if (this.templateId === 1 || this.templateId === 2 || this.templateId === 3  || this.templateId === 4  || this.templateId === 5  || this.templateId === 31 || this.templateId === 37 || this.templateId === 38) {
    	      return true;
    	  } else {
    	      return false;
    	  }
    	},

		isWoWWotlk() {
    	  if (this.templateId === 4) {
    	      return true;
    	  } else {
    	      return false;
    	  }
    	},

		isWoWCata() {
    	  if (this.templateId === 38) {
    	      return true;
    	  } else {
    	      return false;
    	  }
    	},

		isWoWTBC() {
    	  if (this.templateId === 3) {
    	      return true;
    	  } else {
    	      return false;
    	  }
    	},

		getHasEditPermission() {
			return ((this.eventObject && this.eventObject.permission)) || !this.eventExists || this.permissionLevel === 'everyone';
		},
		getHasManagerPermission() {
			return ((this.eventObject && this.eventObject.permission)) || !this.eventExists;
		},

		raidHasBuff(buff) {
			let amount = 0;
			for (let i = 0; i < this.raidDrop.length; i++) {
				if (this.specDrag.find(buffs => this.raidDrop[i].spec_emote === buffs.spec_emote && buffs.tbcbuffs_raid.includes(buff))) {
					amount++;
				}
			}

			return amount;
		},

loadServerData() {
        this.req = {
          	serverid: this.eventObject.serverid,
        	accessToken: localStorage.getItem('accessToken').slice(1,-1),
        };

        let channelNameList = [];
        axios.post(this.$store.state.url + "/api/server/", this.req)
          .then(response => {

            this.$store.state.permissionLevel = response.data.permission;
            this.hasPremium = response.data.premium;

            for (let index = 0; index < response.data.channels.length; index++) {
              channelNameList.push(response.data.channels[index]);
            }

            this.$store.state.discordChannels = channelNameList;
			let icon = "https://cdn.discordapp.com/icons/817542418420596766/f8ec64ca464b50d9f4a80e1256cabd7f.png";
			if (response.data.icon != undefined) {
				icon = response.data.icon;
			}
			let chosenDiscord = {id: this.eventObject.serverid, name: response.data.name, premium: this.hasPremium, icon: icon}	
				this.updateGuild(chosenDiscord)
          }).catch(error => {
			this.hasEditPermission = false;
            if (error.response.status === 429) { 
            	this.ratelimitText = error.response.data.reason;
            	this.ratelimitSnackbar = true;
				this.hasEditPermission = false;
          }
      });
    },

		getGroupBuffs(party) {
			let buffs = [];
			for (let i = 0; i < this.raidDrop.length; i++) {
				if (this.raidDrop[i].partyId === party) {
					for (let k = 0; k < this.specDrag.length; k++) {
						if (this.raidDrop[i].spec_emote === this.specDrag[k].spec_emote) {
							if (this.specDrag[k].groupbuffs[this.templateId] !== undefined) {
								for (let u = 0; u < this.specDrag[k].groupbuffs[this.templateId].length; u++) {
									buffs.push(this.specDrag[k].groupbuffs[this.templateId][u].toLowerCase());
								}
							}
						}
					}
				}
			}
			let list = {};

			for (let i = 0; i < buffs.length; i++) {
				if (list[buffs[i]] === undefined) {
					list[buffs[i]] = {name: buffs[i], value: 1};
				} else {
					list[buffs[i]] = {name: buffs[i], value: list[buffs[i]].value+1}
				}
			}

			return list;
		},

		getGroupBuffsCustom(party) {
			let buffs = [];
			for (let i = 0; i < this.raidDrop.length; i++) {
				if (this.raidDrop[i].partyId === party) {
					for (let k = 0; k < this.specDrag.length; k++) {
						if (this.raidDrop[i].spec_emote === this.specDrag[k].spec_emote) {
							if (this.specDrag[k].groupbuffs_custom[this.templateId] !== undefined) {
								for (let u = 0; u < this.specDrag[k].groupbuffs_custom[this.templateId].length; u++) {
									buffs.push(this.specDrag[k].groupbuffs_custom[this.templateId][u]);									
								}
							}
						}
					}
				}
			}
			let list = {};

			for (let i = 0; i < buffs.length; i++) {
				if (list[buffs[i]] === undefined) {
					list[buffs[i]] = {name: buffs[i], value: 1};
				} else {
					list[buffs[i]] = {name: buffs[i], value: list[buffs[i]].value+1}
				}
			}

			return list;
		},

		getPartyName(name) {
			try {
				if (name === undefined || name.length === 0) {
					return "-"
				} else {
					return name;
				}
			} catch (error) {
				return "-"
			 }
		},

		isInComp(slot) {
			if (this.importedSignUps) {
				if (
					this.raidDrop.find(
						(item) => item.name == slot.name && item.spec == slot.spec)
				) {
					return true;
				} else {
					return false;
				}
			}
		},

		isUserInCompAtAll(slot) {
			if (this.importedSignUps) {
				if (
					this.raidDrop.find(
						(item) => item.userid == slot.userid)
				) {
					return true;
				} else {
					return false;
				}
			}
		},

		isInEvent(slot) {
			if (this.importedSignUps && slot.spec != undefined) {
				if (
					this.importedSignUps.find(
						(item) => item.name == slot.name || item.userid == slot.userid)
				) {
					return true;
				} else {
					return false;
				}
			}
		},

		emptyGroup(party) {
			for (let i = 0; i < this.raidDrop.length; i++) {
				if (this.raidDrop[i].partyId === party) {
					this.raidDrop[i].class = null;
					this.raidDrop[i].spec = null;
					this.raidDrop[i].name = null;
					this.raidDrop[i].color = null;
					this.raidDrop[i].spec_emote = null;
					this.raidDrop[i].class_emote = null;
					this.raidDrop[i].role_emote = null;
					this.raidDrop[i].signuptime = null;
					this.raidDrop[i].userid = null;
					this.raidDrop[i].isConfirmed = false;
				}
			}
			this.createlogAction("emptyGroup", party)
			this.updateRaidDB();
		},

		deleteDrag(slot, drop) {
			if (this.hasEditPermission) {
			let target = drop.find(
				(item) => item.partyId == slot.partyId && item.slotId == slot.slotId
			);
			target.class = null;
			target.spec = null;
			target.name = null;
			target.color = null;
			target.spec_emote = null;
			target.class_emote = null;
			target.role_emote = null;
			target.signuptime = null;
			target.userid = null;
			target.isConfirmed = false;
			
			this.createlogAction("deleteDrag",{slot:slot, drop:drop})
			this.updateRaidDB();
			}
		},
		retrieveSlot(slot) {
			if (this.hasEditPermission) {
			let target = this.raidDrop.find(
				(item) => item.userid == slot.userid && item.spec == slot.spec
			);
			this.createlogAction("deleteDrag", target)

			target.class = null;
			target.spec = null;
			target.name = null;
			target.color = null;
			target.spec_emote = null;
			target.class_emote = null;
			target.role_emote = null;
			target.signuptime = null;
			target.userid = null;
			target.isConfirmed = false;
			
			this.updateRaidDB();
			}
		},
		getParty(partyId, drop) {
			return drop.filter((party) => party.partyId == partyId);
		},
		startDrag(event, slot) {
			this.startDragObject = slot;

			if (!slot.color) {
				let colorResult = this.overlayDropColorObject.find((item) => item.spec == slot.spec)
				if (colorResult != undefined) {
					slot.color  = colorResult.color
				}
			}

			if (slot.name === undefined) {
				slot.name = slot.spec;
			}
			if (slot.position !== undefined) {
			event.dataTransfer.setData("imported", true);
			}
			event.dataTransfer.dropEffect = "move";
			event.dataTransfer.effectAllowed = "move";
			event.dataTransfer.setData("slot_spec", slot.spec);
			event.dataTransfer.setData("slot_name", slot.name);
			event.dataTransfer.setData("slot_class", slot.class);
			event.dataTransfer.setData("slot_color", slot.color);
			event.dataTransfer.setData("slot_spec_emote", slot.spec_emote);
			event.dataTransfer.setData("slot_class_emote", slot.class_emote);
			event.dataTransfer.setData("slot_role_emote", slot.role_emote);
			event.dataTransfer.setData("slot_timestamp", slot.signuptime);
			event.dataTransfer.setData("slot_userid", slot.userid);
			event.dataTransfer.setData("slot_isConfirmed", slot.isConfirmed);
		},
		onDrop(event, slot, drop) {
			let temp = undefined
			this.selectedSlot = undefined;
			let target = drop.find(
				(item) => item.partyId == slot.partyId && item.slotId == slot.slotId
			);

			try {
			if (!event.dataTransfer.getData("imported")) {
			let deleteTarget = drop.find(
				(item) => item.partyId == this.startDragObject.partyId && item.slotId == this.startDragObject.slotId
			);
			deleteTarget.class = target.class;
			deleteTarget.spec = target.spec;
			deleteTarget.name = target.name;
			deleteTarget.color = target.color;
			deleteTarget.spec_emote = target.spec_emote;
			deleteTarget.class_emote = target.class_emote;
			deleteTarget.role_emote = target.role_emote;
			deleteTarget.signuptime = target.signuptime;
			deleteTarget.userid = target.userid;
			deleteTarget.isConfirmed = target.isConfirmed;
			temp = deleteTarget
			}
			} catch (error) { }
			target.class = event.dataTransfer.getData("slot_class");
			target.spec = event.dataTransfer.getData("slot_spec");
			target.name = event.dataTransfer.getData("slot_name");
			target.color = event.dataTransfer.getData("slot_color");
			target.spec_emote = event.dataTransfer.getData("slot_spec_emote");
			target.class_emote = event.dataTransfer.getData("slot_class_emote");
			target.role_emote = event.dataTransfer.getData("slot_role_emote");
			target.signuptime = event.dataTransfer.getData("slot_timestamp");
			target.userid = event.dataTransfer.getData("slot_userid");
			target.isConfirmed = event.dataTransfer.getData("slot_isConfirmed");
			this.createlogAction("swapTarget",{target: target, temp:temp})
			this.updateRaidDB();
			
		},

		getServerIcon() {
      		try {
      		  if (this.eventObject.servericon === undefined) {
      		    return false;
      		  } else {
      		    return this.eventObject.servericon
      		  }
      		} catch (error) {
      		  return false;
      		}
    	},

		swap(slot) {
			try {
			if (this.selectedSlot === slot) {
			this.createlogAction("delete",slot)
			
			slot.class = null;
			slot.spec = null;
			slot.name = null;
			slot.color = null;
			slot.spec_emote = null;
			slot.class_emote = null;
			slot.role_emote = null;
			slot.signuptime = null;
			slot.userid = null;
			slot.isConfirmed = false;
			this.selectedSlot = undefined;
			
			this.updateRaidDB();
			return;
			}
			let temp = JSON.parse(JSON.stringify(this.selectedSlot));
			let deleteTarget = this.raidDrop.find(
				(item) => item.partyId == this.selectedSlot.partyId && item.slotId == this.selectedSlot.slotId
			);

			deleteTarget.class = slot.class;
			deleteTarget.spec = slot.spec;
			deleteTarget.name = slot.name;
			deleteTarget.color = slot.color;
			deleteTarget.spec_emote = slot.spec_emote;
			deleteTarget.class_emote = slot.class_emote;
			deleteTarget.role_emote = slot.role_emote;
			deleteTarget.signuptime = slot.signuptime;
			deleteTarget.userid = slot.userid;
			deleteTarget.isConfirmed = slot.isConfirmed;
			let temp2 = deleteTarget
			

			slot.class = temp.class;
			slot.spec = temp.spec;
			slot.name = temp.name;
			slot.color = temp.color;
			slot.spec_emote = temp.spec_emote;
			slot.class_emote = temp.class_emote;
			slot.role_emote = temp.role_emote;
			slot.signuptime = temp.signuptime;
			slot.userid = temp.userid;
			slot.isConfirmed = temp.isConfirmed;
			this.selectedSlot = undefined;
			this.createlogAction("swapTarget",{target: slot, temp2: temp2})
			this.updateRaidDB();
			} catch (error) { }
		},

		addOnClick(spec) {
			this.selectedSlot = undefined;
			let target;

			try {
			let colorResult = this.overlayDropColorObject.find((item) => item.spec == spec.spec)
				if (colorResult != undefined) {
					spec.color  = colorResult.color
				}
			} catch (error) { }

			if (spec.name === undefined) {
				spec.name = spec.spec;
			}
			for (let i = 1; i <= this.partyPerRaid; i++) {
				for (let k = 1; k <= this.slotPerParty; k++) {
					let slot = this.raidDrop.find((item) => item.partyId == i && item.slotId == k);
					if (slot.name === null) {
					target = slot;
					target.class = spec.class;
					target.spec = spec.spec;
					target.name = spec.name.replace("Protection1", "Protection").replace("Restoration1", "Restoration").replace("Holy1", "Holy").replace("WarriorFF", "Warrior").replace("PaladinFF", "Paladin");
					target.color = spec.color;
					target.spec_emote = spec.spec_emote;
					target.class_emote = spec.class_emote;
					target.role_emote = spec.role_emote;
					target.signuptime = spec.signuptime;
					target.userid = spec.userid;
					target.isConfirmed = spec.isConfirmed;
					this.createlogAction("addOnClick",slot)
					this.updateRaidDB();
					return;
					}
				}
			}
		},

		importAll() {
		for (let k = 0; k < this.importedSignUps.length; k++) {
			let spec = this.importedSignUps[k]
			if (!this.raidDrop.find((item) => item.name === spec.name && item.spec_emote == spec.spec_emote)) {
			loop1: for (let i = 1; i <= this.partyPerRaid; i++) {
				for (let k = 1; k <= this.slotPerParty; k++) {
					let slot = this.raidDrop.find((item) => item.partyId == i && item.slotId == k);
					if (slot.name === null) {
					let target;	
					target = slot;
					target.class = spec.class;
					target.spec = spec.spec;
					target.name = spec.name;
					if (!spec.color) {
						let colorResult = this.overlayDropColorObject.find((item) => item.spec == spec.spec)
						if (colorResult != undefined) {
							spec.color  = colorResult.color
						}
					}
					target.color = spec.color;
					target.spec_emote = spec.spec_emote;
					target.class_emote = spec.class_emote;
					target.role_emote = spec.role_emote;
					target.signuptime = spec.signuptime;
					target.userid = spec.userid;
					target.isConfirmed = spec.isConfirmed;
					break loop1;
					}
				}
			}
			}
		}
		this.createlogAction("importAll",0)
		this.updateRaidDB();
		},

		retrieveAll() {
		for (let k = 0; k < this.raidDrop.length; k++) {
			if (this.importedSignUps.find((item) => item.name === this.raidDrop[k].name && item.spec_emote === this.raidDrop[k].spec_emote)) {
				let target = this.raidDrop[k];
				target.class = null;
				target.spec = null;
				target.name = null;
				target.color = null;
				target.spec_emote = null;
				target.class_emote = null;
				target.role_emote = null;
				target.signuptime = null;
				target.userid = null;
				target.isConfirmed = false;
			}
		}
		this.createlogAction("retrieveAll",0)
		this.updateRaidDB();
		},

		editName(slot, drop) {

			if (slot) {
				if (slot.color != null) {
					this.overlayDropColor = slot.color
				}  else {
					this.overlayDropColor = this.getClassColor(slot)
				}
			}

			let target = drop.find(
				(item) => item.partyId == slot.partyId && item.slotId == slot.slotId
			);
			this.overlayNameEntered = target;
			this.overlayDrop = JSON.parse(JSON.stringify(target));
			this.overlayDrop.index = this.overlayDrop.class + this.overlayDrop.spec;
			this.overlayName = target.name;

			this.createlogAction("editName",{slot: slot,target: this.overlayName})
			this.updateRaidDB();
		},
		removeSlot() {
			if  (this.slotPerParty > 0) {
			let filtered = this.raidDrop.filter(
				(item) => item.slotId < this.slotPerParty
			);
			this.slotPerParty--;
			this.raidDrop = filtered;
			this.createlogAction("removeSlot",this.slotPerParty)
			this.updateRaidDB();
			}
		},

		addSlot() {
			
			if (this.slotPerParty < 100) {
			this.slotPerParty++;
			for (let i = 1; i <= this.partyPerRaid; i++) {
				this.raidDrop.push({
					partyId: i,
					slotId: this.slotPerParty,
					class: null,
					spec: null,
					name: null,
					color: null,
					spec_emote: null,
					class_emote: null,
					role_emote: null,
					signuptime: null,
					userid: null,
					isConfirmed: false,
				});
			}
			this.createlogAction("addSlot",this.slotPerParty)
			this.updateRaidDB();
			}
		},
		removeParty() {
			if (this.partyPerRaid > 0) {
			let filtered = this.raidDrop.filter(
				(item) => item.partyId < this.partyPerRaid
			);
			
			let n = -1;
			while (!Number.isInteger(this.divideArray.at(n))) {
				n = n -1;
				if (Math.abs(n) > this.divideArray.length) {
					break;
				}
			}
			this.divideArray.splice(n, 1)

			this.partyPerRaid--;
			this.raidDrop = filtered;
			this.partyNames.pop();
			this.createlogAction("removeParty",this.partyPerRaid)
			this.updateRaidDB();
			}
		},
		addParty() {
			if (this.partyPerRaid < 100) {
				this.partyPerRaid++;
				this.divideArray.push(this.partyPerRaid)
				
			for (let i = 1; i <= this.slotPerParty; i++) {
				this.raidDrop.push({
					partyId: this.partyPerRaid,
					slotId: i,
					class: null,
					spec: null,
					name: null,
					color: null,
					spec_emote: null,
					class_emote: null,
					role_emote: null,
					signuptime: null,
					userid: null,
					isConfirmed: false,
				});
			}

			this.partyNames.push("Group " + this.partyPerRaid);
			this.createlogAction("addParty",this.partyPerRaid)
			this.updateRaidDB();
			}
		},
		resetRaidplan() {
			this.divideArray = [];
			this.raidDrop = [];
			this.partyNames = [];
			this.partyPerRaid = 5;
			this.slotPerParty = 5;
			for (let i = 1; i <= this.partyPerRaid; i++) {
				this.partyNames.push("Group " + i);
				this.divideArray.push(i);
				for (let n = 1; n <= this.slotPerParty; n++) {
					this.raidDrop.push({
						partyId: i,
						slotId: n,
						class: null,
						spec: null,
						name: null,
						color: null,
						spec_emote: null,
						class_emote: null,
						role_emote: null,
						signuptime: null,
						userid: null,
						isConfirmed: false,
					});
				}
			}

			this.createlogAction("resetRaidplan",0)
			this.updateRaidDB();
		},
		getClasses() {

			let list = [];
			for (let i = 0; i < this.specDrag.length; i++) {
				if (this.specDrag[i].templates.includes(this.templateId)) {
					
					list.push(this.specDrag[i]);
				}
			}

			return list.map((item) => item.class).filter((value, index, self) => self.indexOf(value) === index);
		},

		getEventClassesAndSpecs() {
			let list = [];
			if (this.eventObject) {
				for (let i = 0; i < this.eventObject.classes.length; i++) {
					if ((this.eventObject.template !== "3" || this.eventObject.classes[i].name !== "Tank") && this.eventObject.classes[i].name !== "Late" && this.eventObject.classes[i].name !== "Bench" && this.eventObject.classes[i].name !== "Tentative" && this.eventObject.classes[i].name !== "Absence") {
						let list1 = (this.getCustomSpecs(this.eventObject.classes[i]));
						for (let k = 0; k < list1.length; k++) {
							list1[k].index = list1[k].class + list1[k].spec;
							list.push(list1[k]);
						}
					}
				}
			}
			return list;
		},

		getEventClasses() {
			let list = [];
			for (let i = 0; i < this.eventObject.classes.length; i++) {
				if ((this.eventObject.template !== "3" || this.eventObject.classes[i].name !== "Tank") && this.eventObject.classes[i].name !== "Late" && this.eventObject.classes[i].name !== "Bench" && this.eventObject.classes[i].name !== "Tentative" && this.eventObject.classes[i].name !== "Absence") {
					list.push(this.eventObject.classes[i]);
				}
			}
			return list;
		},

		getCustomSpecs(uniqueClass) {
			let list = [];
			if (this.eventObject.template !== "3") {
				let firstObj = {};
				firstObj.class = uniqueClass.name;
				firstObj.spec = uniqueClass.name;
				firstObj.class_emote = uniqueClass.emote;
				firstObj.spec_emote = uniqueClass.emote;
				firstObj.role_emote = uniqueClass.emote;
				firstObj.isConfirmed = false;
				list.push(firstObj);
			}

			for (let i = 0; i < uniqueClass.specs.length; i++) {
				let obj = {};
				obj.class = uniqueClass.name;
				obj.spec = uniqueClass.specs[i].name;
				obj.class_emote = uniqueClass.emote;
				obj.role_emote = uniqueClass.specs[i].role_emote;
				obj.spec_emote = uniqueClass.specs[i].emote;
				obj.isConfirmed = false;
				list.push(obj);
			}
			return list;
		},

		getSpecs(input) {
			let list = [];
			for (let i = 0; i < this.specDrag.length; i++) {
				if (this.specDrag[i].templates.includes(this.templateId)) {
					list.push(this.specDrag[i]);
				}
			}
			return list.filter((item) => item.class == input);
		},
		shareButton() {
			navigator.clipboard.writeText(window.location.href);
		},

		exportData (variant) {
			let text = "";
			
			if (variant === 1) {
				for (let i = 0; i < this.raidDrop.length; i++) {
					if (this.raidDrop[i].name === null) {
						text = text + "-";	
					} else {
						text = text + this.raidDrop[i].name;
					}
					if (i+1 < this.raidDrop.length) {
						if (this.delimiter === ",") {
							text = text + ", ";
						} else if (this.delimiter === ";") {
							text = text + "; ";
						} else {
							text = text + " ";
						}
					}
				}
			} else if (variant === 2) {
				for (let i = 0; i < this.raidDrop.length; i++) {
					if (this.raidDrop[i].name === null) {
						text = text + "-";	
					} else {
						text = text + this.raidDrop[i].name;
					}
					if (i+1 < this.raidDrop.length) {
						if (this.delimiter === ",") {
							text = text + ",\n";
						} else if (this.delimiter === ";") {
							text = text + ";\n";
						} else {
							text = text + "\n";
						}
					}
				}
			} else if (variant === 3) {
				for (let i = 1; i < this.partyPerRaid+1; i++) {
					let drop = this.raidDrop.filter((party) => party.partyId == i);
					for (let k = 0; k < drop.length; k++) {
						if (drop[k].name === null) {
							text = text + "-";	
						} else {
							text = text + drop[k].name;
						}
						if (k+1 < drop.length) {
							if (this.delimiter === ",") {
								text = text + ", ";
							} else if (this.delimiter === ";") {
								text = text + "; ";
							} else {
								text = text + " ";
							}
						}
					}
					text = text + "\n";
				}
			} else if (variant === 4) {
				for (let i = 1; i < this.partyPerRaid+1; i++) {
					let drop = this.raidDrop.filter((party) => party.slotId == i);
					for (let k = 0; k < drop.length; k++) {
						if (drop[k].name === null) {
							text = text + "-";	
						} else {
							text = text + drop[k].name;
						}
						if (k+1 < drop.length) {
							if (this.delimiter === ",") {
								text = text + ", ";
							} else if (this.delimiter === ";") {
								text = text + "; ";
							} else {
								text = text + " ";
							}
						}
					}
					text = text + "\n";
				}
			}

			navigator.clipboard.writeText(text);
		},

		copyToStore(){

			this.$store.state.raidplan.hash= this.currentHash
			this.$store.state.raidplan.accessToken = localStorage.getItem('accessToken').slice(1,-1)
			this.$store.state.raidplan.title= this.title
			this.$store.state.raidplan.permissionLevel= this.permissionLevel
			this.$store.state.permissionLevel= this.permissionLevel
			this.$store.state.raidplan.visibilityLevel= this.visibilityLevel
			this.$store.state.raidplan.templateId= this.templateId
			this.$store.state.raidplan.importedEventId= this.importedEventId
			this.$store.state.raidplan.partyPerRaid= this.partyPerRaid
			this.$store.state.raidplan.slotPerParty= this.slotPerParty
			this.$store.state.raidplan.raidDrop= this.raidDrop
			this.$store.state.raidplan.raidDropBench= this.raidDropBench
			this.$store.state.raidplan.partyNames= this.partyNames
			this.$store.state.raidplan.last_updated= this.timestamp
			this.$store.state.raidplan.divideArray= this.divideArray
			this.$store.state.raidplan.displayRoles= this.displayRoles
			this.$store.state.raidplan.displayClasses= this.displayClasses
			
			this.$store.state.raidplan.overlayDropColorObject= this.overlayDropColorObject
			
		},

		copyRaidplanFromStore(){
			
			 this.currentHash = this.$store.state.raidplan.hash
			 this.title = this.$store.state.raidplan.title
			 this.permissionLevel = this.$store.state.raidplan.permissionLevel
			 this.permissionLevel = this.$store.state.raidplan.permissionLevel
			 this.visibilityLevel = this.$store.state.raidplan.visibilityLevel
			 this.templateId = this.$store.state.raidplan.templateId
			 this.importedEventId = this.$store.state.raidplan.importedEventId
			 this.partyPerRaid = this.$store.state.raidplan.partyPerRaid
			 this.slotPerParty = this.$store.state.raidplan.slotPerParty
			 this.raidDrop = this.$store.state.raidplan.raidDrop
			 this.raidDropBench = this.$store.state.raidplan.raidDropBench
			 this.partyNames = this.$store.state.raidplan.partyNames
			 this.timestamp = this.$store.state.raidplan.timestamp
			 this.divideArray = this.$store.state.raidplan.divideArray
			 this.displayRoles = JSON.parse(this.$store.state.raidplan.displayRoles)
			 this.displayClasses = JSON.parse(this.$store.state.raidplan.displayClasses)
			 this.overlayDropColorObject = this.$store.state.raidplan.overlayDropColorObject
			 
			 try {
			 if (this.overlayDropColorObject.length < 2 || this.overlayDropColorObject == null || this.overlayDropColorObject == undefined) {
				this.checkIfColorReset("Init")
			}
		} catch (error) { }

			this.updateRaidDB();

		},

		createlogAction(type, action) {
			let timestamp = new Date().getTime()
			let username = this.$store.state.discordUser.tag
			let log = {}
			switch (type) {
				case "editSlot":
					log = {
						type: type,
						string: "changed",
						oldName: action.oldName,
						oldClass: action.oldClass,
						oldClass_emote: action.oldClass_emote,
						oldSpec: action.oldSpec,
						oldSpec_emote: action.oldSpec_emote,
						oldColor: action.oldColor,
						newName: action.newName,
						newClass_emote: action.newClass_emote,
						newSpec_emote: action.newSpec_emote,
						newClass: action.newClass,
						newSpec: action.newSpec,
						newColor: action.newColor,
						partyId: this.partyNames[action.partyId - 1],
						slotId: action.slotId,

					}
					break;
				case "isConfirmed":
					if (action.isConfirmed == 'false') {
						action.isConfirmed = 'unconfirmed'
					}
					log = {
						type: type,
						string: "changed confirmation of ",
						isConfirmed: action.isConfirmed,
						class: action.class,
						class_emote: action.class_emote,
						spec: action.spec,
						spec_emote: action.spec_emote,
						color: action.color,
						name: action.name,
						partyId: this.partyNames[action.partyId - 1],
						slotId: action.slotId,
					}
				break;
				case "deletedRaidplan":
					log = {
						type: type,
						string: "deleted Raidplan Profile",
						title: action.profileName,
					}
					break;
				case "savedRaidplan":
					log = {
						type: type,
						string: "saved Raidplan Profile",
						title: action.profileName,
					}
					break;
				case "loadedRaidplan":
					log = {
						type: type,
						string: "loaded Raidplan Profile",
						title: action.profileName,
					}
					break;

				case "dividerName":
					log = {
						type: type,
						string: "changed divider name",
						selectedDivider:  this.divideArray[action.selectedDivider],
						dividerName: action.dividerName,
					}
					break;
				case "partyNameEntered":
					let actionPartyNameTarget = action.partyNameTarget
					let actionPartyNameEntered = action.partyNameEntered
					log = {
						type: type,
						string: "changed group name",
						partyNameTarget: this.partyNames[actionPartyNameTarget],
						partyNameEntered: actionPartyNameEntered,
					}
					break;
				case "editTitle":
					log = {
						type: type,
						string: "changed Raidplan title to",
						title: action,
					}
					break;
				case "resetRaidplan":
					log = {
						type: type,
						string: "has reset the Raidplan",
					}
					break;

				case "addParty":
					log = {
						type: type,
						string: "increased amount of groups to",
						size: action,
					}
					break;

				case "removeParty":
					log = {
						type: type,
						string: "decreased amount of groups to",
						size: action,
					}

					break;

				case "addSlot":
					log = {
						type: type,
						string: "increased group size to",
						size: action,
					}
					break;

				case "removeSlot":
					log = {
						type: type,
						string: "decreased group size to",
						size: action,
					}

					break;

				case "retrieveAll":
					log = {
						type: type,
						string: "retrieved all imported signups",
					}
					break;

				case "importAll":
					log = {
						type: type,
						string: "imported all signups",
					}

					break;

				case "addOnClick":
					log = {
						type: type,
						string: "added",
						class: action.class,
						class_emote: action.class_emote,
						spec: action.spec,
						spec_emote: action.spec_emote,
						color: action.color,
						name: action.name,
						partyId: this.partyNames[action.partyId - 1],
						slotId: action.slotId,
					}
					break;

				case "delete":
					log = {
						type: type,
						string: "cleared",
						partyId: this.partyNames[action.partyId - 1],
						slotId: action.slotId,
					}

					break;

				case "swapStart":
					log = {
						type: type,
						string: "cleared",
						partyId: this.partyNames[action.partyId - 1],
						slotId: action.slotId,
					}

					break;

				case "swapTarget":
					let action1 = action.target
					let action2 = action.temp
					
					log = {
						type: type,
						string: "moved",
						class: action1.class,
						class_emote: action1.class_emote,
						spec: action1.spec,
						spec_emote: action1.spec_emote,
						color: action1.color,
						name: action1.name,
						partyId: this.partyNames[action1.partyId - 1],
						slotId: action1.slotId,
					}

					if (action2 != undefined) {


						log.oldPartyId = this.partyNames[action2.partyId - 1]
						log.oldSlotId = action2.slotId
						if (action2.name != null) {
							log.oldClass = action2.class
							log.oldClass_emote = action2.class_emote
							log.oldSpec = action2.spec
							log.oldSpec_emote = action2.spec_emote
							log.oldColor = action2.color
							log.oldName = action2.name
						}
					}
					break;

				case "deleteDrag":
					log = {
						type: type,
						string: "cleared",
						partyId: this.partyNames[action.partyId - 1],
						slotId: action.slotId,
					}
					break;

				case "emptyGroup":
					log = {
						type: type,
						string: "has emptied",
						partyId: this.partyNames[action - 1],
					}
					break;

				case "setDividerCurrent":
					log = {
						type: type,
						string: "added a divider above ",
						partyId: this.partyNames[action - 1],
					}
					break;

				case "dividerDelete":
					log = {
						type: type,
						string: "deleted a divider above ",
						partyId: this.partyNames[action - 1],
					}
					break;
				case "editPermissions":
					log = {
						type: type,
						string: "changed to setting 'Edit Permissions' to",
						value: action,
					}
					break;
				case "showRoles":
					log = {
						type: type,
						string: "changed the setting 'Show Roles' to",
						value: action,
					}
					break;
				case "showClasses":
					log = {
						type: type,
						string: "changed the setting 'Show Classes' to",
						value: action,
					}
					break;
				default:
					break;
			}

			log.timestamp = timestamp;
			log.username = username;
			
			if (this.logging.length  > 1) {
				if (new Date((this.logging[this.logging.length-1]).timestamp).getDay() != new Date(log.timestamp).getDay()) {
				let newDayLog = {}
				newDayLog.timestamp = timestamp
				newDayLog.type = "newDayLine"
				this.logging.push(newDayLog)

			}
			}
			

			this.logging.push(log)			
		},

		createLog(req){
			if (this.currentReq == undefined) {
				this.currentReq = JSON.parse(JSON.stringify(this.eventObject));
			}

			this.newReq = JSON.parse(JSON.stringify(req));

			req.logging = this.logging
			this.currentReq = JSON.parse(JSON.stringify(this.newReq));
		},
	

		async updateRaidDB() {
		
			let req = {
				hash: this.currentHash,
				accessToken: localStorage.getItem('accessToken') ? localStorage.getItem('accessToken').slice(1,-1) : undefined,
				title: this.title,
				permissionLevel: this.permissionLevel,
				visibilityLevel: this.visibilityLevel,
				templateId: this.templateId,
				importedEventId: this.importedEventId,
				partyPerRaid: this.partyPerRaid,
				slotPerParty: this.slotPerParty,
				raidDrop: this.raidDrop,
				raidDropBench: this.raidDropBench,
				partyNames: this.partyNames,
				last_updated: this.timestamp,
				divideArray: this.divideArray,
				displayRoles: this.displayRoles,
				displayClasses: this.displayClasses,
				overlayDropColorObject: this.overlayDropColorObject,
				logging: undefined,
			};

			this.createLog(req);

			axios
				.post(this.$store.state.url + "/api/raidplan/save/", req)
				.catch((error) => {
					console.log("Something went wrong!");
				});
		},

		async getRaidPlan() {
	
			if (this.$route.params.hash == "new" || this.$route.params.hash === undefined) {
				const nanoid = customAlphabet("1234567890abcdef", 6);
				this.currentHash = nanoid();
				if (this.$route.path === "/raidplan") {
				this.$router.push("/raidplan/" + this.currentHash);
				} else {
				this.$router.push(this.currentHash);
				}
			}
		
			this.currentHash = this.$route.params.hash;
			let response = await axios.post(
				this.$store.state.url + "/api/raidplan/" + this.$route.params.hash
			);
		
			if (JSON.stringify(response.data).length > 2) {
				this.currentReq = JSON.parse(JSON.stringify(response.data));
				try { this.title = response.data.title; } catch (e) { console.error("Error setting title", e); }
				try { this.templateId = JSON.parse(response.data.templateId); } catch (e) { console.error("Error setting templateId", e); }
				try { this.importedEventId = response.data.importedEventId; } catch (e) { console.error("Error setting importedEventId", e); }
				try { this.partyPerRaid = response.data.partyPerRaid; } catch (e) { console.error("Error setting partyPerRaid", e); }
				try { this.slotPerParty = response.data.slotPerParty; } catch (e) { console.error("Error setting slotPerParty", e); }
				try { this.raidDrop = response.data.raidDrop; } catch (e) { console.error("Error setting raidDrop", e); }
				try { this.raidDropBench = response.data.raidDropBench; } catch (e) { console.error("Error setting raidDropBench", e); }
				try { this.partyNames = response.data.partyNames; } catch (e) { console.error("Error setting partyNames", e); }
				try { this.permissionLevel = response.data.permissionLevel; } catch (e) { console.error("Error setting permissionLevel", e); }
				try { this.visibilityLevel = response.data.visibilityLevel; } catch (e) { console.error("Error setting visibilityLevel", e); }
				try { this.divideArray = response.data.divideArray; } catch (e) { console.error("Error setting divideArray", e); }
				try { this.overlayDropColorObject = response.data.overlayDropColorObject; } catch (e) { console.error("Error setting overlayDropColorObject", e); }
				try { this.logging = response.data.logging; } catch (e) { console.error("Error setting logging", e); }

				try {
					this.displayRoles = response.data.displayRoles ? JSON.parse(response.data.displayRoles) : true
				} catch (error) { }
				try {
					this.displayClasses = response.data.displayClasses ? JSON.parse(response.data.displayClasses) : true
				} catch (error) { }
				this.hasUpdatingAnnounce = response.data.hasUpdatingAnnounce;
				if (this.divideArray.length === 0 && this.partyPerRaid > 0) {
					this.divideArray = this.range(1,this.partyPerRaid);
				}
			} else {
				if (this.templateId == '37') {
					this.displayClasses = true;
				}
				this.notInDB = true;
				this.divideArray = this.range(1,this.partyPerRaid);
			}
				
				this.componentLoaded = true;
		},

    async sendAnnouncement() {
		let mentions = "";
		let scheduleTime = "false"
		if (this.mentionUsers) {
			mentions = "signed";
		}
		let confirmation = "false";
		if (this.wantsToCreateUpdatingAnnounce) {
			confirmation = "updatingAndConfirming"
		} else if (this.wantsToCreateUpdatingAnnounceNoConfirmation) {
			confirmation = "updating"
		}
		if (this.wantsToScheduleAnnounce) {
			scheduleTime = this.announcementTime
		}

      let req = {
        serverId: this.eventObject.serverid,
        accessToken: localStorage.getItem('accessToken').slice(1,-1),
        raidId: this.eventObject.raidid,
		channelId: this.announceChannel,
		templateId: this.templateId,
		title: this.title,
		description: this.announceMessage,
		partyPerRaid: this.partyPerRaid,
		slotPerParty: this.slotPerParty,
		raidDrop: this.raidDrop,
		partyNames: this.partyNames,
		divideArray: this.divideArray,
		permissionLevel: this.permissionLevel,
		visibilityLevel: this.visibilityLevel,
		displayRoles: this.displayRoles,
		displayClasses: this.displayClasses,
		mentions: mentions,
		confirmation: confirmation,
		scheduleTime: scheduleTime,

      }
      axios.post(this.$store.state.url + "/api/announce/", req)      
      .then(response => {
		  	this.announceMessage = "";
			if (this.wantsToScheduleAnnounce) {
				this.snackbarResultText = "Announcement scheduled.";
			} else {
				this.snackbarResultText = "Announcement sent.";
			}
			this.snackbarColor = 'green';
			this.snackbar = true;
			if (response.data.event != undefined) {
				this.eventObject = response.data.event;
				this.parseEvent(this.eventObject.signups);
			}
			this.updateCountdown();
			this.announceChannel = this.eventObject.channelid;
    		if (this.eventObject.channelType == "forum") {
				this.announceChannel = this.eventObject.raidid;
			}

      }).catch(error => {
          if (error.response.data.status === 'failed') {
			this.snackbarResultText = error.response.data.reason;
			this.snackbarColor = 'red';
			this.snackbar = true;
          }
      });
    },

	async removeAnnouncement() {
      var compAnnouncement = {
        delete: true,
      }
      var edit = {
        compAnnouncement: compAnnouncement
      };

      let req = {
        serverid: this.eventObject.serverid,
        accessToken: localStorage.getItem('accessToken').slice(1,-1),
        eventid: this.eventObject.raidid,
        edit: edit
      }

      axios.post(this.$store.state.url + "/api/edit/", req)      
      .then(response => {
		this.eventObject = response.data;
		this.parseEvent(this.eventObject.signups);
		this.updateCountdown();
		this.announceChannel = this.eventObject.channelid;
    	if (this.eventObject.channelType == "forum") {
			this.announceChannel = this.eventObject.raidid;
		}
		this.snackbarResultText = "Announcement deleted.";
		this.snackbarColor = 'green';
        this.snackbar = true;
      }).catch(error => {
		  console.log(error)
          if (error.response.data.status=== 'failed') {
            this.ratelimitText = error.response.data.reason;
            this.ratelimitSnackbar = true;
          }
      });
    },

		initRaidDrop(target) {
			let a = 1;
			let b = 1;
			for (let i = 0; i < this.partyPerRaid * this.slotPerParty; i++) {
				target.push({
					partyId: a,
					slotId: b,
					class: null,
					spec: null,
					name: null,
					color: null,
					spec_emote: null,
					class_emote: null,
					role_emote: null,
					signuptime: null,
					userid: null,
					isConfirmed: false,
				});

				if (b == this.slotPerParty) {
					a++;
					b = 1;
				} else {
					b++;
				}
			}
		},

		parseEvent(signups) {
			let a = 1;
			let b = 1;
			let order = ['Tank', 'Melee', 'Ranged', 'Healer', 'DK', 'DH', 'Monk', 'Warrior', 'Druid', 'Paladin', 'Rogue', 'Hunter', 'Mage', 'Warlock', 'Priest', 'Shaman', 'Evoker', 'Late', 'Bench', 'Tentative']
			signups.sort((a, b) => b.spec.localeCompare(a.spec));
			this.importedSignUps = [];
			if (this.templateId !== 0) {
			for (let k = 0; k < order.length; k++) {
				for (let i = 0; i < signups.length; i++) {
					if (signups[i].class === order[k]) {
						this.importedSignUps.push({
							partyId: a,
							slotId: b,
							class: signups[i].class,
							spec: signups[i].spec[0].toUpperCase() + signups[i].spec.substring(1),
							name: signups[i].name,
							color: null,
							spec_emote: signups[i].spec_emote,
							class_emote: signups[i].class_emote,
							role_emote: signups[i].role_emote,
							signuptime: signups[i].signuptime,
							position: signups[i].position,
							userid: signups[i].userid,
							isConfirmed: false,
						});

						if (b == Math.ceil(signups.length / 3)) {
							a++;
							b = 1;
						} else {
							b++;
						}
					}
				}
			}
			} else {
				signups.sort((a, b) => a.position - b.position);
				for (let i = 0; i < signups.length; i++) {
						this.importedSignUps.push({
							partyId: a,
							slotId: b,
							class: signups[i].class,
							spec: signups[i].spec[0].toUpperCase() + signups[i].spec.substring(1),
							name: signups[i].name,
							color: null,
							spec_emote: signups[i].spec_emote,
							class_emote: signups[i].class_emote,
							role_emote: signups[i].role_emote,
							signuptime: signups[i].signuptime,
							position: signups[i].position,
							userid: signups[i].userid,
							isConfirmed: false,
						});

						if (b == Math.ceil(signups.length / 3)) {
							a++;
							b = 1;
						} else {
							b++;
						}
				}
			}
			this.importedPartyPerRaid = a;
		},

		async getEvent() {
			let response = undefined;
			if (this.$store.state.authenticated && this.$store.state.discordUser !== undefined) {
				
				let req = {
					accessToken: localStorage.getItem('accessToken').slice(1,-1),
				};
				response = await axios.post(
					this.$store.state.url + "/api/event/" + this.$route.params.hash, req
				).catch(error => {
          			if (error.response.status === 401) {
                 			this.$store.commit("setAuthenticated", false);
      					axios
							.get(this.$store.state.url + "/api/discord/logout", {withCredentials: true})
        					.then((res) => {
          						this.$store.commit("setChosenDiscord", null);
          						this.$store.commit("setDiscordUser", null);
        					})
        				.catch((error) => {
          					console.log(error);
        				})
        				.finally(() => {
							this.$store.state.discordUser = undefined;
							this.$store.state.discordChannels = undefined;
          					localStorage.removeItem("discordUser");
          					localStorage.removeItem("chosenDiscord");
         	 				localStorage.removeItem("accessToken");
          					location.reload();
		  					this.updateCountdown();
		
        				});
            		return;
          			}
        		}
        	);
				} else {
					response = await axios.get(
						this.$store.state.url + "/api/event/" + this.$route.params.hash
					);
				}

				if (response.data.status === 'failed' && response.data.reason !== 'unknown event') {
					this.eventExists = false;
					this.snackbarResultText = response.data.reason;
					this.snackbarColor = 'red';
					this.snackbar = true;
					return;
				} else if (response.data.status === 'failed') {
					return;
				} else if (!this.eventExists) {
					if (response.data.template.match(/^(?:[2-5]|37|38)$/)) {
						if (response.data.template === "2") {
							this.templateId = 1;
							if (this.notInDB) {
								while (this.partyPerRaid < 8) {
									this.partyPerRaid++;
									for (let i = 1; i <= this.slotPerParty; i++) {
										this.raidDrop.push({
											partyId: this.partyPerRaid,
											slotId: i,
											class: null,
											spec: null,
											name: null,
											color: null,
											spec_emote: null,
											class_emote: null,
											role_emote: null,
											signuptime: null,
											userid: null,
											isConfirmed: false,
										});
									}
									this.divideArray.push(this.partyPerRaid);
									this.partyNames.push("Group " + this.partyPerRaid);

								}
								this.updateRaidDB();
							}
						} else {
					
							this.templateId = parseInt(response.data.template);
						}
					} else {
						this.templateId = 0;
					}
					this.eventExists = true;
				}
	
				this.eventObject = response.data;

				document.title = "Comp Tool - " + this.eventObject.displayTitle
			
				this.parseEvent(this.eventObject.signups);
				if (this.firstLoad) {
					this.loadEventSource();
					this.firstLoad = false;
				}
				this.updateCountdown();
				this.announceChannel = this.eventObject.channelid;
			
    			if (this.eventObject.channelType == "forum") {
					this.announceChannel = this.eventObject.raidid;
				}
				
		},

		loadEventSource() {
			this.$socket.emit('liveUpdateEventRegister', this.$route.params.hash)
		},

		async initiate() {
			this.initRaidDrop(this.raidDrop);
			if (this.$route.params.hash.match('^(\\d{17,20})$')) {
				await this.getEvent();
			}
			this.getRaidPlan();
			

			this.$socket.emit('liveUpdateRaidPlanRegister', this.$route.params.hash)
			this.timestamp = new Date().getTime();
			this.last_updated = new Date().getTime();
			
			if (this.overlayDropColorObject == null || this.overlayDropColorObject == undefined || this.overlayDropColorObject.length < 2) {
				this.checkIfColorReset("Init")
			}
			this.isLoaded = true;
			this.hasEditPermission = this.getHasEditPermission()
			this.hasManagerPermission = this.getHasManagerPermission()
			this.updateCountdown();
		},

		removeSelectedSlot() {
			this.isOutsideClick ? this.selectedSlot = undefined : this.isOutsideClick = true;
		},
		getProfileDefault() {

      let server = this.$store.state.chosenDiscord.id;
      axios
        .post(this.$store.state.url + "/api/profilesRaidplan/get/", { server })
        .then((res) => {
          this.loadedProfiles = res.data;
        })
        .catch((error) => {
          console.log("Something went wrong!");
        })
        .finally(() => {
          this.savedProfiles = this.loadedProfiles;
		  	let profileToFetch = localStorage.getItem('defaultProfile')
          	let fetchedProfile = this.savedProfiles.find((item) => item.profileName == profileToFetch)
			
			this.$store.state.raidplan.hash= fetchedProfile.hash
			this.$store.state.raidplan.accessToken = localStorage.getItem('accessToken').slice(1,-1)
			this.$store.state.raidplan.title= fetchedProfile.title
			this.$store.state.raidplan.permissionLevel= fetchedProfile.permissionLevel
			this.$store.state.permissionLevel= fetchedProfile.permissionLevel
			this.$store.state.raidplan.visibilityLevel= fetchedProfile.visibilityLevel
			this.$store.state.raidplan.templateId= fetchedProfile.templateId
			this.$store.state.raidplan.importedEventId= fetchedProfile.importedEventId
			this.$store.state.raidplan.partyPerRaid= fetchedProfile.partyPerRaid
			this.$store.state.raidplan.slotPerParty= fetchedProfile.slotPerParty
			this.$store.state.raidplan.raidDrop= fetchedProfile.raidDrop
			this.$store.state.raidplan.raidDropBench= fetchedProfile.raidDropBench
			this.$store.state.raidplan.partyNames= fetchedProfile.partyNames
			this.$store.state.raidplan.last_updated= fetchedProfile.timestamp
			this.$store.state.raidplan.divideArray= fetchedProfile.divideArray
			this.$store.state.raidplan.displayRoles= fetchedProfile.displayRoles
			this.$store.state.raidplan.displayClasses= fetchedProfile.displayClasses
			this.$store.state.raidplan.overlayDropColorObject = fetchedProfile.overlayDropColorObject
			this.$store.state.raidplan.profileName = fetchedProfile.profileName
			this.$store.state.savedProfiles = this.savedProfiles
			
			this.copyRaidplanFromStore()
        });
    },
	async updateGuild(chosenDiscord) {

      this.$store.commit("setChosenDiscord", chosenDiscord);
    },
	},

	created() {
		this.initiate();
		this.$root.$refs.Raidplan = this;

		this.isFirefox = navigator.userAgent.includes('Firefox')
		document.title = "Comp Tool" 
	},

	 mounted() {
		
		 document.addEventListener('click', this.removeSelectedSlot)
    	var scripts = [
    	  "https://wow.zamimg.com/widgets/power.js"
    	];

    	scripts.forEach(script => {
    	  let tag = document.createElement("script");
    	  tag.setAttribute("src", script);
    	  document.head.appendChild(tag);
    	});

		this.countDownInterval = setInterval(() => this.updateCountdown(), 5000);
		
  	},
  beforeDestroy() {
	clearInterval(this.countDownInterval);
    window.removeEventListener("click", this.removeSelectedSlot);
  },
  destroyed() {
	try {
		let head = document.getElementsByTagName('head')[0];	  
		var script = head.getElementsByTagName('script');
     	if (script.length > 0){
        	head.removeChild(script[0]);
     	}
		} catch (error) {
	}
  }
};
</script>
<style >

.pulse-element {
  animation: pulse 0.8s infinite 0.4s; /* Adjust the duration as needed */
}

@keyframes pulse {
  0% {
    filter: brightness(100%);
  }
  50% {
    filter: brightness(130%); /* Adjust the brightness value for pulsing effect */
  }
  100% {
    filter: brightness(100%);
  }
}

.text-shadow {
	transition: all 0.4s ease-in-out;
	animation: flicker 0.4s ease-in-out infinite alternate;
}


@keyframes flicker {
	0% {
		opacity: 1;
		text-shadow: 0px 0px 5px black;
	}
	100% {
		opacity: 0.5;
		text-shadow: 0px 0px 5px black;
	}
}


.cardFont {
	font-family: Whitney, "Helvetica Neue", Helvetica, Arial, sans-serif;
}


a{
	-webkit-user-drag: none;
}

.buffhover:hover {
    opacity: 0.7;
}

.format {
	background-color: #202225; 
	padding: 1px;
	padding-left: 3px;
	padding-right: 3px;

}


</style>