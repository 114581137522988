<template>
<v-container class="my-5" style="max-width: 900px;">
      <v-card dark elevation="0">
        <v-card-title class="text-h4 justify-center py-10">
          <b>Terms of Service</b>
        </v-card-title>

        <v-card-text>
<br>

<h4>1. Terms</h4>
By accessing this Website, accessible from raid-helper.dev, you are agreeing to be bound by these Terms of Service and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site.
<br><br><h4>2. Use License</h4>
Permission is granted to temporarily download one copy of the materials on the Raid-Helper Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title.
<br><br><h4>3. Disclaimer</h4>
All the materials on the Raid-Helper Website are provided "as is". Raid-Helper makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, Raid-Helper does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.
<br><br><h4>4. Limitations</h4>
Raid-Helper will not be hold accountable for any damages that will arise with the use or inability to use the materials on the Raid-Helper Website, even if Raid-Helper or an authorized representative of this Website has been notified, orally or written, of the possibility of such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.
<br><br><h4>5. Revisions and Errata</h4>
The materials appearing on the Raid-Helper Website may include technical, typographical, or photographic errors. Raid-Helper will not promise that any of the materials in this Website are accurate, complete, or current. Raid-Helper may change the materials contained on its Website at any time without notice. Raid-Helper does not make any commitment to update the materials.
<br><br><h4>6. Links</h4>
Raid-Helper has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by Raid-Helper of the site. The use of any linked website is at the user’s own risk.
<br><br><h4>7. Site Terms of Use Modifications</h4>
Raid-Helper may revise these Terms of Use for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms of Service.
<br><br><h4>8. Your Privacy</h4>
Please read our Privacy Policy.
<br><br><h4>9. Governing Law</h4>
Any claim related to the Raid-Helper Website shall be governed by the laws of Germany without regards to its conflict of law provisions.<br><br>
<br>
<h2>Copyright Policy</h2><br>
Raid-Helper respects the intellectual property rights of others and expects its users to do the same.<br>
Raid-Helper has adopted the following policy concerning copyright infringement in accordance with the Digital Millennium Copyright Act (“DMCA”), as codified in 17 U.S.C §512. The contact information for our designated agent to receive notification of claimed copyright or intellectual property infringement (“Copyright Agent”) is listed at the end of this policy.
If you believe in good faith your work has been copied in a way that constitutes copyright infringement, or that your intellectual property rights have been otherwise violated, please provide the following DMCA requirements to Raid-Helper’s Copyright Agent:<br><br>
	•	Information reasonably sufficient for Raid-Helper to contact you: name, address, phone and e-mail address (if available);<br>
	•	A description of the copyrighted work or intellectual property that you claim has been infringed, or if multiple works, a listing of such works;<br>
	•	Information reasonably sufficient to permit Raid-Helper to locate your work on the site;<br>
	•	A statement, made by you, that you have a good faith belief that the disputed use of the material is not authorized by the copyright owner, its agent or the law;<br>
	•	A statement by you, made under penalty of perjury, that the information in your notice is accurate and that you are the copyright owner, or authorized to act on the copyright owner’s behalf;<br>
	•	A physical or electronic signature of the copyright owner, or a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.<br><br>
Upon receiving a proper notification of alleged copyright infringement as described above, we will remove or disable access to the allegedly infringing content and promptly notify the alleged infringer of your claim. Please submit your statement to Raid-Helper by email as set forth below:<br>
<br><b>E-Mail:</b> raidhelperapp@gmail.com
</v-card-text>

      </v-card>
</v-container>
</template>
<script>
export default {
    		created() {
			 this.fetchDiscordInfo;
			 window.scrollTo({
                top: 0,
                left: 0,
                });
		},
}
</script>
<style>
    
</style>