import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import vuetify from "./plugins/vuetify"
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import CookieLaw from 'vue-cookie-law';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import VueSocketIO from 'vue-socket.io';

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);
Vue.use(CookieLaw)
Vue.config.productionTip = false;
Vue.use(new VueSocketIO({
    //connection: "http://localhost:3003",
    connection: store.state.url,
}))
new Vue({
    router,
    store,
    vuetify,
    beforeCreate() { this.$store.commit('initialiseStore'); },
    render: (h) => h(App),
}).$mount("#app");