<template>
  	<v-container justify="center">
		<v-layout row fluid>
			<v-flex xs0 sm0 md0 lg1 xl2> </v-flex>
			<v-flex xs12 sm12 md12 lg10 xl8 class="my-5 rounded-lg" :class="$vuetify.breakpoint.xs ? 'mt-1' : 'mt-10'">
		    	<SelectedEvent />
			</v-flex>
			<v-flex xs0 sm0 md0 lg1 xl2> 
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import SelectedEvent from "../components/events/SelectedEvent.vue";

export default {
  	name: "DashboardEvent",
  	components: { SelectedEvent },
  	methods: {
  	},
	data() {
    	return {
      		opacityValue: "0",
		}
	},
	mounted() {
		setTimeout(this.showAd, 300);
	}
};
</script>
<style>

</style>