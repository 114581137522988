import Vue from "vue"
import Vuetify from "vuetify/lib/framework"
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#202025',
                secondary: '#43434E',
                accent: '#222227',
                error: colors.red.accent3,
                background: '#2E2E33', // Not automatically applied
            },
            dark: {
                primary: '#202025',
                secondary: colors.grey.darken1,
                accent: colors.shades.black,
                error: colors.red.accent3,
                background: '#2E2E33', // If not using lighten/darken, use base to return hex
            },
        },
    }
})