<template>
	<v-container justify="center">
		<v-layout row fluid>
			<v-flex xs0 sm0 md0 lg0 xl2> </v-flex>
			<v-flex xs12 sm12 md12 lg12 xl8 class="my-12" :class="`rounded-lg`">
				<Landing />
			</v-flex>
			<v-flex xs0 sm0 md0 lg0 xl2> </v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import Landing from '../components/Landingpage.vue'

	export default {
		name: "Landingpage",
		components: {Landing},
	};
</script>
