<template>
            <div style="white-space: nowrap;">
              <ul style="display:block; padding: 0px;">
                <li align="center">
            <span style="background-color: #292b2f; color: lightgreen; font-size: 1.1em; display:block; width: 50px; font-weight: 600;" class="pa-1 mx-1 mb-n1 pb-0">{{days}}</span><span style="font-size: 0.6em; color: darkgrey;">Days</span>
        </li>
           <li align="center">
            <span style="background-color: #292b2f; color: lightgreen; font-size: 1.1em; display:block; width: 50px; font-weight: 600;" class="pa-1 mx-1 mb-n1 pb-0">{{hours}}</span><span style="font-size: 0.6em; color: darkgrey;">Hours</span>
           </li>
           <li align="center">
            <span style="background-color: #292b2f; color: lightgreen; font-size: 1.1em; display:block; width: 50px; font-weight: 600;" class="pa-1 mx-1 mb-n1 pb-0">{{minutes}}</span><span style="font-size: 0.6em; color: darkgrey;">Minutes</span>
           </li>
              </ul>
            </div>
      
</template>
<script>

export default {
    props: {
  	    unixtime: {
            type: Number,
  	    },
    },

    data() {
        return {
            timer: undefined,
            interval: undefined,
            timerCount: undefined,
            days: undefined,
            hours: undefined,
            minutes: undefined,
        }
    },

    mounted() {
        this.timer = this.unixtime;
        this.updateCountdown();
        this.interval = setInterval(() => this.updateCountdown(), 5000);
    },

  destroyed() {
    clearInterval(this.interval);
  },

    methods:{
      isMobile() {
        return window.innerWidth < 800;
      },

      updateCountdown() {
        if ((this.unixtime*1000 - new Date().getTime()) < 0) {
          this.days = 0;
          this.hours = 0;
          this.minutes = 0;
        } else {
          let delta = Math.abs(this.unixtime*1000 - new Date().getTime()) / 1000;
          this.days = Math.floor(delta / 86400);
          delta -= this.days * 86400;
          this.hours = Math.floor(delta / 3600) % 24;
          delta -= this.hours * 3600;
          this.minutes = Math.floor(delta / 60) % 60;
          delta -= this.minutes * 60;
        }
    },
    }
}
</script>
<style>
    
</style>