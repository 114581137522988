<template>

<v-container fill-height fluid class="mt-sm-n5">
<span class="bg"></span>


<v-col cols="12" v-if="isMobile2 < 701" class="mt-n15 ml-n3">
  <v-card color="transparent" elevation="0" class="">
   <v-spacer></v-spacer>
   <v-card-text class="white--text" style="font-size: 18px; font-weight: 100;">Raid-Helper is the ultimate tool to create and organize your events within Discord. Save valuable time and game more!</v-card-text>
            </v-card>
</v-col>
<v-col cols="12" v-if="isMobile2 < 701" class="mt-n10">
  <v-card color="transparent" elevation="0" class="py-5">
    <v-row >
      <v-col>
          <v-btn elevation="0" block class="purple white--text shadow" to="/documentation/intro">Get started!</v-btn>
      </v-col>
      
      
    </v-row>
    
    
  </v-card>
</v-col>

<v-col cols="6">
<v-card :height="getHeight()" elevation="0" :max-width="getHeight()" :min-width="getHeight()" color="#2f3136">
  <v-carousel hide-delimiters :show-arrows="false" cycle interval="8000" :height="getHeight()" class="shadow">
    <v-carousel-item
      v-for="(item,i) in carousel"
      :key="i"
      :src="item"
    ></v-carousel-item>
  </v-carousel>
    </v-card>
</v-col>

<v-col cols="6" v-if="isMobile2 > 700" class="ml-lg-n15 ml-0">
  <v-card color="transparent" :height="getHeight()" elevation="0" class="pl-xl-0 pl-lg-0 pl-md-15 pl-sm-15">
   <v-img v-if="isMobile2 > 900" src="@/assets/test.png" class="ml-md-5 ml-sm-3"></v-img>
   <v-spacer></v-spacer>
   <v-card-text class="ml-4 ml-md-6 pl-lg-6 white--text" style="font-size: 20px; font-weight: 100;">Raid-Helper is the ultimate tool to create and organize your events within Discord. Save valuable time and game more!</v-card-text>
            <v-btn elevation="0" min-width="200" class="purple white--text ml-8 ml-md-10 ml-lg-12 mt-2 shadow" to="/documentation/intro">Get started!</v-btn>
            </v-card>
</v-col>

<v-col cols="12" class="mt-15 bgfeatures">

      <v-row justify="center">
      
          <v-col cols="12" sm="4" xl="4">
    <v-card color="#38383e" style="" elevation="5" class="text-center py-5 vcard-premium" min-height="260">
      <v-card-title class="justify-center">
      <v-icon size="60" color="#bab5b5">mdi-calendar-check</v-icon>
      </v-card-title>
      <v-card-subtitle class="my-3" style="color: #e1dada;"><h2>Easy</h2></v-card-subtitle>
      <v-card-text style="color: #a09c9c;">It's easy to get started. Create and edit events without learning complex commands!</v-card-text>
                
    </v-card></v-col>

              <v-col cols="12" sm="4" xl="4">
    <v-card color="#38383e" style="" elevation="5" class="text-center py-5 vcard-premium" min-height="260">
      <v-card-title class="justify-center">
      <v-icon size="60" color="#bab5b5">mdi-drag-variant</v-icon>
      </v-card-title>
      <v-card-subtitle class="my-3" style="color: #e1dada;"><h2>Customizable</h2></v-card-subtitle>
      <v-card-text style="color: #a09c9c;">Plenty of settings and features to customize your events functionally and visually.</v-card-text>   
    </v-card></v-col>

                  <v-col cols="12" sm="4" xl="4">
    <v-card color="#38383e" style="" elevation="5" class="text-center py-5 vcard-premium" min-height="260">
      <v-card-title class="justify-center">
      <v-icon size="60" color="#bab5b5">mdi-blur</v-icon>
      </v-card-title>
      <v-card-subtitle class="my-3" style="color: #e1dada;"><h2>Versatile</h2></v-card-subtitle>
      <v-card-text style="color: #a09c9c;">Raid-Helper can be used for scheduling and polling, reaction roles, embed creation and more.</v-card-text> 
    </v-card></v-col>

                  <v-col cols="12" sm="4" xl="4">
    <v-card color="#38383e" style="" elevation="5" class="text-center py-5 vcard-premium" min-height="260">
      <v-card-title class="justify-center">
      <v-icon size="60" color="#bab5b5">mdi-translate</v-icon>
      </v-card-title>
      <v-card-subtitle class="my-3" style="color: #e1dada;"><h2>Multilingual</h2></v-card-subtitle>
      <v-card-text style="color: #a09c9c;">The bot is translated into multiple languages by the community!</v-card-text>
    </v-card></v-col>

                      <v-col cols="12" sm="4" xl="4">
    <v-card color="#38383e" style="" elevation="5" class="text-center py-5 vcard-premium" min-height="260">
      <v-card-title class="justify-center">
      <v-icon size="60" color="#bab5b5">mdi-connection</v-icon>
      </v-card-title>
      <v-card-subtitle class="my-3" style="color: #e1dada;"><h2>Powerful</h2></v-card-subtitle>
      <v-card-text style="color: #a09c9c;">Raid-Helper gives you the power you need to organize and communicate with your team effectively!</v-card-text>
    </v-card></v-col>

                          <v-col cols="12" sm="4" xl="4">
    <v-card color="#38383e" style="" elevation="5" class="text-center py-5 vcard-premium" min-height="260">
      <v-card-title class="justify-center">
      <v-icon size="60" color="#bab5b5">mdi-cog-clockwise</v-icon>
      </v-card-title>
      <v-card-subtitle class="my-3" style="color: #e1dada;"><h2>Evolving</h2></v-card-subtitle>
      <v-card-text style="color: #a09c9c;">The project is under active and continuous development. New features are being added and old ones improved regularly.</v-card-text>
    </v-card></v-col>

                              <v-col cols="12" sm="4" xl="4">
    <v-card color="#38383e" style="" elevation="5" class="text-center py-5 vcard-premium" min-height="260">
      <v-card-title class="justify-center">
      <v-icon size="60" color="#bab5b5">mdi-current-ac</v-icon>
      </v-card-title>
      <v-card-subtitle class="my-3" style="color: #e1dada;"><h2>Stable</h2></v-card-subtitle>
      <v-card-text style="color: #a09c9c;">24/7 online with 99,8% uptime. Running since 2019 and server costs covered for many years upfront.</v-card-text>
    </v-card></v-col>

                                  <v-col cols="12" sm="4" xl="4">
    <v-card color="#38383e" style="" elevation="5" class="text-center py-5 vcard-premium" min-height="260">
      <v-card-title class="justify-center">
      <v-icon size="60" color="#bab5b5">mdi-monitor-dashboard</v-icon>
      </v-card-title>
      <v-card-subtitle class="my-3" style="color: #e1dada;"><h2>Dashboard</h2></v-card-subtitle>
      <v-card-text style="color: #a09c9c;">Raid-Helper has a dashboard for those that prefer a web ui when creating events!</v-card-text>
    </v-card></v-col>

                                  <v-col cols="12" sm="4" xl="4">
    <v-card color="#38383e" style="" elevation="5" class="text-center py-5 vcard-premium" min-height="260">
      <v-card-title class="justify-center">
      <v-icon size="60" color="#bab5b5">mdi-face-agent</v-icon>
      </v-card-title>
      <v-card-subtitle class="my-3" style="color: #e1dada;"><h2>Support</h2></v-card-subtitle>
      <v-card-text style="color: #a09c9c;">Customer support on our discord server. Your question will be answered in a timely manner!</v-card-text>
    </v-card></v-col>

    </v-row>
</v-col>
</v-container>
</template>
<script>

export default {
      data() {
    return {
                isMobile: false,
                isMobile2: false,
                carousel: [
                require("../assets/banner.gif"),
                require("../assets/banner0.gif"),
                require("../assets/banner1.png"),
                require("../assets/banner2.png"),
                require("../assets/banner3.png"),
                require("../assets/banner4.png"),
                require("../assets/banner5.png"),
        ],
    }
  },

    beforeDestroy() {
    window.removeEventListener("resize", this.onResize, { passive: true });
  },

        methods: {
        goToDiscordRedirect() {
            window.location.href = this.$store.state.url + "/api/auth/discord";
     
			},
                
        onResize() {
      this.isMobile2 = window.innerWidth;
    },

        getHeight() {
       
            if (this.isMobile2 < 700) {
                return this.isMobile2-50 + "px"
            }
            if (this.isMobile2 < 800) {
            return "400px"
            }
            if (this.isMobile2 < 900) {
            return "450px"
            } else {
                return "500px"
            }
        }
    },
      mounted() {
   
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  created(){
    document.title = "Raid-Helper"
  },
}
</script>
<style >
.shadow {
box-shadow: rgba(0, 0, 0, 0.35) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
border: 1px solid #101012;
}

.bg {
    width: 100%;
    height: 400px;
    position: fixed;
    top: 0;
    border-bottom: 2px solid black;
    left: 0;
    opacity: 0.30;
    background: url("../assets/kt1.jpg") no-repeat center bottom;
    background-size: cover;
    background-color: transparent;
  }

</style>