<template>
	<v-container justify="center">
		<v-layout row fluid>
			<v-flex xs0 sm1 md1 lg1 xl2> </v-flex>
			<v-flex xs12 sm10 md10 lg10 xl8 class="my-12" :class="`rounded-lg`">

                <GuidesPage />
			</v-flex>
			<v-flex xs0 sm1 md1 lg1 xl2> </v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import GuidesPage from '../components/Guides.vue'

export default {
    name: 'Guides',
    components: {GuidesPage,},

}
</script>
<style>
    
</style>