<template>

<v-container class="pa-1">
  
        
              <DashNavi v-bind:title="'Past Events'" v-bind:route="'/dashboard'" id="DashNavi"/>

          <v-row v-if="apiCalled && !$store.state.chosenDiscord.hasPerms" class="justify-center mt-n5 mt-sm-n10">
    <v-col cols="12">

    <v-alert v-if="!$store.state.chosenDiscord.hasPerms" prominent type="error">
      <v-row align="center">
        <v-col class="grow"
          >You don't have permission to view events in this server.</v-col
        >
        <v-col class="shrink">
          
          <v-btn elevation="0" to="/dashboard">Go back</v-btn>
        </v-col>
      </v-row>
    </v-alert>
    </v-col>
        </v-row>
        
    

    <v-row v-if="!loadingEvents && eventsEven" class="justify-center mt-sm-n11 mt-n6">
      <v-col cols="12">
        <v-card id="profile" :ripple="false" dark color="primary" class="mb-1" elevation="0" v-for="(item, i) in displayPage(eventsEven)" :key="i" @click="selectToEdit(item.raidId)" :style="'border-left: 5px solid rgb(' + item.color + ') !important;'">
          <v-card-actions>
         
              <v-col cols="3" sm="2" md="2" lg="2" xl="1" class="d-flex justify-center ml-n1">
                <v-img class="my-0" :src="'https://cdn.discordapp.com/emojis/' + item.emote + '.png'" max-height="30" max-width="30"></v-img>
              </v-col>
              <v-col>
                <v-row id="title">
          <v-col>
            <v-row class="mb-n1 ml-n2">
              <span class="pt-0" style="font-size: 1.5em;">
                {{ (item.displayTitle != undefined ? item.displayTitle : item.title) }}
              </span>
            </v-row>
          </v-col>
        </v-row>
              <v-container>
                  <v-row class="pt-2 ml-n6">


                    <span style="white-space: nowrap; font-size: 0.8em;" class="mt-2">
                    <span style='background-color: #181a1c; color: #dcd0d0; border: 2px solid #212327; border-right: 0px; border-top-left-radius: 4px; border-bottom-left-radius: 4px;' class='pl-1 pr-6 py-1'><v-img src="https://cdn.discordapp.com/emojis/799879945123135508.png" max-height="19" max-width="20" style="display:inline-block; position: absolute;"></v-img></span>
                    <span style='background-color: #292b2f; color: #adb1b6; border: 2px solid #212327; border-left: 0px; border-top-right-radius: 4px; border-bottom-right-radius: 4px;' class='px-2 py-1 mr-3'>{{ item.leader }}</span>
                    </span>

                    <span style="white-space: nowrap; font-size: 0.8em;" class="mt-2">
                    <span style='background-color: #181a1c; color: #dcd0d0; border: 2px solid #212327; border-right: 0px; border-top-left-radius: 4px; border-bottom-left-radius: 4px;' class='pl-1 pr-6 py-1'><v-img src="https://cdn.discordapp.com/emojis/592462264670617641.png" max-height="19" max-width="20" style="display:inline-block; position: absolute;"></v-img></span>
                    <span style='background-color: #292b2f; color: #adb1b6; border: 2px solid #212327; border-left: 0px; border-top-right-radius: 4px; border-bottom-right-radius: 4px;' class='px-2 py-1 mr-3'>{{ new Date(item.unixtime*1000).toLocaleDateString([],{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }}</span>
                    </span>

                    <span style="white-space: nowrap; font-size: 0.8em;" class="mt-2">
                    <span style='background-color: #181a1c; color: #dcd0d0; border: 2px solid #212327; border-right: 0px; border-top-left-radius: 4px; border-bottom-left-radius: 4px;' class='pl-1 pr-6 py-1'><v-img src="https://cdn.discordapp.com/emojis/592463749865275452.png" max-height="19" max-width="20" style="display:inline-block; position: absolute;"></v-img></span>
                    <span style='background-color: #292b2f; color: #adb1b6; border: 2px solid #212327; border-left: 0px; border-top-right-radius: 4px; border-bottom-right-radius: 4px;' class='px-2 py-1 mr-3'>{{ new Date(item.unixtime*1000).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) }}</span>
                    </span>

                    <span style="white-space: nowrap; font-size: 0.8em;" class="mt-2">
                    <span style='background-color: #181a1c; color: #dcd0d0; border: 2px solid #212327; border-right: 0px; border-top-left-radius: 4px; border-bottom-left-radius: 4px;' class='pl-1 pr-6 py-1'><v-img src="https://cdn.discordapp.com/emojis/799893852814049310.png" max-height="19" max-width="20" style="display:inline-block; position: absolute;"></v-img></span>
                    <span style='background-color: #292b2f; color: #adb1b6; border: 2px solid #212327; border-left: 0px; border-top-right-radius: 4px; border-bottom-right-radius: 4px;' :style="checkChannelDeleted(item.channelName) === 'deleted channel' ? 'color: red;' : ''" class='px-2 py-1 mr-3'><img :src="'https://cdn.discordapp.com/emojis/' + getChannelIconByType(item.channelType) + '.png?v=1'" width="20" height="20" style="margin-bottom: -6px; margin-left: -5px; margin-right: 5px;" />{{ checkChannelDeleted(item.channelName) }}</span>
                    </span>


                  </v-row>
              </v-container>
              
              </v-col>
           
          </v-card-actions>
        </v-card>

        
      </v-col>
    </v-row>

    <v-row v-if="!loadingEvents && eventsEven.length === 0 && $store.state.chosenDiscord.hasPerms" class="justify-center" style="color: grey;">no events found</v-row>
          <v-snackbar elevation="0" color="red" v-model="ratelimitSnackbar" timeout="4500" ><span style="font-size:15px;">{{ ratelimitText }}</span>
          </v-snackbar>
    <v-overlay opacity="0" :value="loadingEvents && $store.state.chosenDiscord.hasPerms">
      <v-progress-circular
        indeterminate
        color="white"
        size="64"
      ></v-progress-circular>
    </v-overlay>

          <div v-if="Math.ceil(eventsEven.length/10)>1 && !loadingEvents && $store.state.chosenDiscord.hasPerms" class="text-center mt-7" >
          <v-pagination
          dark
            active="white"
            v-model="page"
            :length="Math.ceil(eventsEven.length/10)"
            :total-visible="7"
          ></v-pagination>
        </div>
      
  </v-container>
</template>
<script>
import axios from "axios";
import Store from "../../store/index";
import DashNavi from "../../components/DashNavi";

export default {
  components: {
    DashNavi
  },
  data() {
    return {
      eventsEven: [],
      page: 1,
      loadingEvents: true,
      apiCalled: false,
      ratelimitSnackbar: false,
      ratelimitText: "",
      color: undefined,
    };
  },
  methods: {
    displayPage(events){
      let pageStart = this.page *10
      return events.slice(pageStart -10, pageStart)
    },  

   

    async getEvents() {
      if (!Store.state.chosenDiscord) {
        this.$router.push("/dashboard");
      } else {
        this.req = {
          serverid: Store.state.chosenDiscord.id,
          accessToken: localStorage.getItem('accessToken').slice(1,-1),
        };

        axios.post(Store.state.url + "/api/server/",this.req)
        .then(response => {

          Store.state.permissionLevel = response.data.permission;
          if (response.data.reason === "missing permission") {
            Store.state.chosenDiscord.hasPerms = false
            this.loadingEvents = false;
            this.apiCalled = true;
            return;
          } else {
            Store.state.chosenDiscord.hasPerms = true
            this.apiCalled = true;
            axios.post(Store.state.url + "/api/events/", this.req)
            .then(response => {
              const events = response.data.events;
              events.sort(function (a, b) {
                return b.unixtime - a.unixtime;
              });

              this.eventsEven = [];
              for (let index = 0; index < events.length; index++) {
                if (events[index].unixtime*1000 < new Date().getTime()) {
                  this.eventsEven.push(events[index]);
                }
              }
              Store.state.closedCache = this.eventsEven;
              this.loadingEvents = false;

            }).catch(error => {
              if (error.response.status === 429) {
                this.ratelimitSnackbar = true;
                this.ratelimitText = error.response.data.reason;
                this.loadingEvents = false;
              }
            });
          } 
          }).catch(error => {
              if (error.response.status === 429) {
                this.ratelimitSnackbar = true;
                this.ratelimitText = error.response.data.reason;
                this.loadingEvents = false;
              } else if (error.response.status === 401) {
                window.location.href = this.$store.state.url + "/api/auth/discord";
            }
          }
        );
      }
    },

                getChannelIconByType(type) {
      if (type === "text") {
        return "1025776950096171148";
      } else if (type === "forum") {
        return "1025776945146904629";
      } else if (type === "thread") {
        return "1025779885127516170";
      } else if (type === "post") {
        return "1025779860838301810";
      } else if (type === "news") {
        return "1025776946698784861";
      } else if (type === "stage") {
        return "1025776948288426068";
      } else if (type === "dm") {
        return "1026094422015877171";
      } else {
        return "1025776951627100210";
      }
    },
    
    getTitleAsEmotes(input, font=3) {
      if (input === 'unnamed') {
        this.titleEmote = false
      }
      let textEmotes = [];
      for (let i = 0; i < input.length; i++) {
        
        if (this.$store.state.textEmotes[0].includes(input[i].toUpperCase())) {
          
          textEmotes.push(
            this.$store.state.textEmotes[parseInt(font)].find(
              (x) => x.name === input[i].toUpperCase()
            ).id
          );
        } else {
          this.titleEmote = false
        }
      }
      this.titleEmote = true
      return textEmotes;
    },
    iconize(input) {
      if (input === "-") {
        return " ";
      } else {
        return "https://cdn.discordapp.com/emojis/" + input + ".png?v=1";
      }
    },

    async selectToEdit(raidId) {
      localStorage.setItem("selectedEventId", raidId);
      this.$store.state.selectedEventId = raidId;
      this.$router.push(
        "/event/" + this.$store.state.selectedEventId,
        () => {}
      );
    },
    
    truncate(input) {
      if (input.length > 15 && this.$vuetify.breakpoint.xs) {
        return input.substring(0, 15) + "..";
      }
      return input;
    },

    checkDateColor(input, item, i) {
      let now = new Date();
      let eventDate = new Date(item.date.split("-").reverse().join("-"));

      if (now - eventDate > 0) {
        return "#ff0000";
      }
      if (input && input !== "none") {
        return input;
      }
    },
    checkChannelDeleted(input) {
      if (input === "") {
        return "deleted channel";
      } else {
        return input;
      }
    },
  },
  created(){
    document.title = "Dashboard - Past Events" 
  },
  mounted() {
    this.$store.state.selectedEvent = undefined
    this.$store.state.selectedEventId = undefined

    if (this.$store.state.closedCache !== undefined) {
      this.eventsEven = this.$store.state.closedCache;
      this.loadingEvents = false;
    }

    this.getEvents();
  },
};
</script>
<style >
.fixedPadding {
  padding-bottom: 6px !important;
  padding-top: 6px !important;
  padding-right: 6px !important;
  padding-left: 6px !important;
}

#profile {
  border: 1px solid transparent !important;
}

#profile:hover {
  background-color: #25252b !important;
}

.theme--dark.v-pagination .v-pagination__navigation {
    background: #202025 !important;
    color: white !important;
}


.theme--dark.v-pagination .v-pagination__item {
    background: #202025 !important;
    color: white !important;
}

.theme--dark.v-pagination .v-pagination__item--active {
    background: #3d4046 !important;
}

.theme--light.v-application{
  color: white;
}
</style>