<template>

        <v-form class="mb-0" ref="form">
                <v-text-field height="50px" solo clearable dense v-model="imageUrl" :background-color="color" flat :label="label" class="mx-4 mt-n4"> 
                    <v-btn elevation="0" :ripple="false" color="accent" text plain small @click="onButtonClick" :loading="isSelecting" slot="append"><v-icon color="#2196F3" style="margin-top: -2px;">mdi-cloud-upload</v-icon></v-btn>
                    <v-btn elevation="0" :ripple="false" @click="loadServerImages(); overlay1 = true;" small color="green lighten-2" text plain slot="append" class="mx-n2" style="margin-top: -2px;"><v-icon>mdi-folder-multiple-image</v-icon></v-btn>
                </v-text-field>
            <form enctype="multipart/form-data"><input ref="uploader" class="d-none" type="file" accept="image/*" @change="onFileChanged"></form>


            <v-dialog transition="slide-y-transition" overlay-color="black" overlay-opacity="0" content-class="elevation-0" v-model="overlay1" width="800" dark>

            <v-card color="#3b3e45">
              <v-card-title class="text-h5">
                Preset images
              </v-card-title>

              <v-card-text>

<v-expansion-panels focusable>
    <v-expansion-panel v-if="serverImages.length > 0" class="mt-3">
      <v-expansion-panel-header color="#2f3136">
          <v-avatar max-width="25" max-height="25">
        <v-img max-width="25" height="25" :src="$store.state.chosenDiscord.icon"></v-img>
          </v-avatar>
          {{ $store.state.chosenDiscord.name }}
      </v-expansion-panel-header>
      <v-expansion-panel-content color="accent">
  <v-row class="my-2">
       <v-col cols="12" sm="12" md="6" lg="4" v-for="n in serverImages.length" :key="n">
                  <v-card v-if="$store.state.permissionLevel === 'admin'" class="mb-5" style="background-color: transparent; font-size: 0.7em;" height="15px" elevation="0">
                    <v-col align="right">
                    <v-icon class="mr-n3" color="red" small @click="deleteImage(serverImages[n-1].split('/')[5])">mdi-trash-can-outline</v-icon>
                    </v-col>
                    </v-card>
                    <v-card color="#27272d" elevation="5" class="vcard-templates" @click="overlay1 = false; imageUrl = serverImages[n-1]">
                      <v-img contain max-height="150" :src="serverImages[n-1]"></v-img>
                    </v-card>
                  </v-col> 
    </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>


    <v-expansion-panel v-for="k in $store.state.gameBanners.length" :key="k">
      <v-expansion-panel-header color="#2f3136">
          <v-avatar max-width="25" max-height="25">
        <v-img max-width="25" height="25" :src="$store.state.gameBanners[k-1].icon"></v-img>
          </v-avatar>
          {{ $store.state.gameBanners[k-1].name }}
      </v-expansion-panel-header>
      <v-expansion-panel-content color="accent">
  <v-row class="my-5">
       <v-col cols="12" sm="12" md="6" lg="4" v-for="n in $store.state.gameBanners[k-1].images.length" :key="n">
         
    <v-tooltip transition="v-scale-transition" color="#0f0f0f" bottom>
      <template v-slot:activator="{ on, attrs }">
    
                    <v-card v-bind="attrs" v-on="on" color="#27272d" elevation="5" class="vcard-templates" @click="overlay1 = false; imageUrl = $store.state.gameBanners[k-1].images[n-1].url">
  <v-img :src="$store.state.gameBanners[k-1].images[n-1].url"></v-img>
                    </v-card>
           
      </template>
      <span>Credit: <b>{{ $store.state.gameBanners[k-1].images[n-1].credit }}</b></span>
    </v-tooltip>
                  </v-col> 
    </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>

              </v-card-text>
                        <v-container>
                          <v-row style="background-color: #2f3136;">
                              <v-col align="end">
                            <v-btn elevation="0" @click="overlay1 = false;" plain class="mr-2 ma-1" :ripple="false">Close</v-btn>
                              </v-col>
                          </v-row>
                        </v-container>
            </v-card>
          </v-dialog>
            <v-img max-height="300" style="border-radius: 5px;" contain v-if="checkURL(imageUrl)" :src="imageUrl" class="mx-4 mb-6"></v-img>
        </v-form>
</template>
<script>

import axios from 'axios'

export default {
    props: {
  	    url: {
            type: String,
  	    },
        formColor: {
          type: String,
        },
        formLabel: {
          type: String,
        }
    },

    data() {
        return {
            overlay: false,
            overlay1: false,
            selectedFile: null,
            isSelecting: false,
            imageUrl: undefined,
            serverImages: [],
            color: "#4a4e57",
            label: "URL"

        }
    },

    mounted() {
        this.imageUrl = this.url;
        if (this.imageUrl === "none") {
            this.imageUrl = "";
        }
        this.color = this.formColor;
        if (this.formColor == undefined) {
          this.color = "#4a4e57";
        }
        this.label = this.formLabel;
        if (this.formLabel == undefined) {
          this.label = "URL";
        }

        
    },

    methods:{
          isMobile() {
            return window.innerWidth < 800;
        },

        checkURL(url) {
            try {
                this.$emit('imageUrl', url);       
                return(url.match(/\.(jpeg|jpg|gif|png|JPEG|JPG|GIF|PNG|webp)$/) != null); 
            } catch {
                return false;
            }
        },
        onButtonClick() {
            this.isSelecting = true
            window.addEventListener('focus', () => {
              this.isSelecting = false
            }, { once: true })
            this.$refs.uploader.click()
        },
        onFileChanged(e) {
          const config = {
            headers: { 'content-type': 'multipart/form-data', 'serverid' : this.$store.state.chosenDiscord.id }
          }
          this.selectedFile = e.target.files[0]

          let fd = new FormData();
          fd.append('file', this.selectedFile)
          
          axios
          .post(this.$store.state.url + "/api/upload", fd, config)
          .then((res) => {this.selectedFile = res.data})
          .catch((error) => {})
          .finally(() => {this.imageUrl = 'https://raid-helper.dev/uploads/' + this.$store.state.chosenDiscord.id + '/' + this.selectedFile});
        },

        deleteImage(fileName) {
          let req = {
            serverid: this.$store.state.chosenDiscord.id,
            file: fileName,
          };
          axios.post(this.$store.state.url + "/api/image/delete", req)

          this.serverImages.splice(this.serverImages.findIndex(e => e.includes(fileName)),1);
        },

        loadServerImages() {
          axios
          .get(this.$store.state.url + "/api/uploads/" + this.$store.state.chosenDiscord.id)
          .then((res) => {this.serverImages = res.data.urls})
        }
    }
}
</script>
<style>
    
</style>