<template>
<v-container class="my-5" style="max-width: 900px;">
      <v-card dark elevation="0">
        <v-card-title class="text-h4 justify-center py-10">
          <b>Privacy Policy</b>
        </v-card-title>

        <v-card-text>
<br>
This privacy policy will explain how we use the data we collect from you while you use our bot or website.<br><br>
<b>What data do we collect?</b><br>
Raid-Helper collects the following data:<br>
• Your discord id and username<br>
• The emote and time of the reaction you clicked on the bots messages<br><br>
<b>How do we collect your data?</b><br>
We collect and process data when you:<br>
• Create an event or poll<br>
• Click a reaction on an event posted by the bot<br>
• A user in your server, with the necessary permissions, manually adds you to an event made by the bot<br><br>
<b>How do we use your data?</b><br>
• Raid-Helper uses the collected data to show you the event and the users that have signed up to it<br><br>
<b>How do we store your data?</b><br>

Raid-Helper stores all data in an encrypted and password protected database.<br>
The security of your data is important to us but we would like to point out that no method of transmission over the Internet or method of electronic storage is fully secure. While we use commercially acceptable means to protect your personal data, we cannot guarantee its absolute security.<br><br>
<b>What are your data protection rights?</b><br>
Every user is entitled to the following:<br><br>


<u>The right to access</u><br>You have the right to request copies of your personal data.<br><br>
<u>The right of rectification</u><br>You have the right to have your information rectified if it’s inaccurate or incomplete.<br><br>
<u>The right to erasure</u><br>You have the right to request that we erase your personal data, under certain conditions.<br><br>
<u>The right of restriction</u><br>You have the right to request that we restrict the processing of your personal information.<br><br>
<u>The right to object</u><br>You have the right to object to our processing of your Personal Data.<br><br>
<u>The right to data portability</u><br>You have the right to be provided with a copy of the information we have on you in a structured, machine-readable and commonly used format.<br><br>

Please note that we may ask you to verify your identity before responding to such requests.<br>
You have the right to complain to a Data Protection Authority about our collection and use of your personal data. For more information, please contact your local data protection authority.<br><br>
<b>Changes to our privacy policy</b><br>
We keep this privacy policy under regular review and place any updates on this web page.<br>
This privacy policy was last updated on 01 August 2021.
</v-card-text>

      </v-card>
</v-container>
</template>
<script>
export default {
    		created() {
			 window.scrollTo({
                top: 0,
                left: 0,
                });
		},
}
</script>
<style>
    
</style>