<template>
  <div>
    <v-col>
	  <v-card color="transparent" elevation="0" class="text-center pb-10 mt-n5">
			<span style="color: #e1dada;"><h1 style="font-size: 2em;" class="mb-5">Thank You!</h1>Your purchase was successfully completed.<br>Your contribution will help keep this project going and we appreciate your trust in Raid-Helper!<br><br>You can see your premium code below, which you can activate on your Discord server by using the /verify command.<br>The code was also sent to your email address!</span>
	 <v-divider dark style="color:yellow;" class="my-5"></v-divider>

<v-row justify="center">
<v-col cols="12" sm="8" md="6" class="pr-3 pl-3">
	  <v-card @click="copyText()" color="#3b3b7f" elevation="0" class="text-center py-5">
      <span style="color: white;">{{this.clientReferenceId}}</span>
    </v-card>
</v-col>
</v-row>
	  </v-card>
    </v-col>
            		<v-snackbar elevation="0" color="green" v-model="snackbar" timeout="1000" ><span style="font-size:15px;">Copied to clipboard!</span></v-snackbar>

  </div>
  
</template>

<script>

import axios from "axios";

export default {
		created() {
			this.fetchDiscordInfo;
      this.getCode();
			window.scrollTo({
                top: 0,
                left: 0,
                });

                document.title = "Raid-Helper - Thank You!"
		},
    data() {
      return {
		    clientReferenceId: "-",
        snackbar: false,
      }
    },

  methods: {
    async getCode() {
        axios.get(this.$store.state.url + "/api/thankyou/" + this.$route.params.clientReferenceId)
        .then(response => {
          console.log(response)
            this.clientReferenceId = response.data.code;
        }).catch(error => {
        });
    },
    copyText() {
      navigator.clipboard.writeText(this.clientReferenceId);
      this.snackbar = true;
    },
  }
}

</script>

<style>

</style>
