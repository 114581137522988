<template >

  <v-card color="#3b3e45" elevation="0" style="border-radius: 0px;">
    <v-card-title class="text-h5">
      Embed Profiles
    </v-card-title>
    <v-card-text class="pt-1 px-6">
      You can save your current Embed into a profile and load it later. The profiles are saved, so that other
      managers of this server can use them as well.
    </v-card-text>
    <v-card-actions class="pa-0 pl-3 pr-1 pb-3">
      <v-col class="mx-n1">
        <v-card color="#2f3136" class="mt-n3" elevation="0">
          <v-card-title class="mx-1">
            Save Profile
          </v-card-title>
          <v-row>
            <v-col class="">
              <v-text-field class="px-5" v-model="profileName" outlined dense placeholder="Enter a profile name."
                background-color="secondary" flat>
              </v-text-field>
            </v-col>

          </v-row>
          <v-btn elevation="0" class="mb-5 mx-5 green" :disabled="profileNameIsEntered() === false" color="green"
            @click="saveProfile()">Save</v-btn>
        </v-card>


            <v-card color="#2f3136" class="mt-5" elevation="0">

              <v-card-title class="mx-1">
                Load Profile
              </v-card-title>
              <v-row>
                <v-col>
                  <v-select class="px-5" dense item-color="white" outlined v-model="selectedProfile"
                    :items="loadedProfiles" placeholder="Select a profile." background-color="secondary" flat
                    @click="getProfiles()">
                    <template v-slot:selection="{ item }">
                      {{ item.profileName }}
                    </template>
                    <template v-slot:item="{ item }">
                      {{ item.profileName }}
                    </template>
                  </v-select>
                </v-col>

              </v-row>
              <v-btn elevation="0" class="mb-5 mx-5" :disabled="selectedProfile === ''" @click="applyProfile()"
                color="green">Load</v-btn>

              <v-tooltip bottom
                :disabled="$store.state.permissionLevel === 'admin' || $store.state.permissionLevel === 'manager'">
                <template v-slot:activator="{ on }">
                  <div v-on="on" class="d-inline-block">
                    <v-btn elevation="0" class="mb-5" text color="red"
                      :disabled="($store.state.permissionLevel !== 'admin' && $store.state.permissionLevel !== 'manager')"
                      v-if="selectedProfile !== ''" @click="deleteProfile()" :ripple="false">Delete</v-btn>
                  </div>
                </template>
                <span>Requires manager role or higher.</span>
              </v-tooltip>

            </v-card>

      </v-col>

      
    </v-card-actions>
    <v-snackbar color="green" elevation="0" v-model="snackbar1" timeout="4000"><span style="font-size:15px;">Profile
        saved!</span></v-snackbar>
    <v-snackbar color="red" elevation="0" v-model="snackbar2" timeout="4000"><span style="font-size:15px;">Profile
        deleted!</span></v-snackbar>

  </v-card>
  

    

</template>
<script>
import axios from "axios";

export default {
  props: {
    closeOverlay: {
      type: Boolean,
    },
  },
  data() {
    return {
      snackbar1: false,
      snackbar2: false,
      dialog: false,
      profileName: "",
      selectedProfile: "",
      savedProfiles: [],
      loadedProfiles: [],
      profileSnapshot: {
        mentions: "",
        title: "",
        description: "",
        author: "",
        fields: [],
        footer: "",
        image: "",
        thumbnail: "",
      },
    };
  },
  watch: {
    dialog: function (newValue) {
      if (newValue === false) {
        this.selectedProfile = "";
        this.profileName = "";
      }
    },
  },
  methods: {

    isMobile() {
      return window.innerWidth < 800;
    },

    profileNameIsEntered() {
      if (this.profileName.length > 0) {
        return true;
      } else {
        return false;
      }
    },

    applyProfile() {
          this.$emit('closeOverlay', false);
          this.$store.state.embed.mentions = this.selectedProfile.mentions
          this.$store.state.embed.title = this.selectedProfile.title
      		this.$store.state.embed.description = this.selectedProfile.description
      		this.$store.state.embed.author = this.selectedProfile.author
          this.$store.state.embed.fields = this.selectedProfile.fields
          this.$store.state.embed.footer = this.selectedProfile.footer
          this.$store.state.embed.image = this.selectedProfile.image
          this.$store.state.embed.thumbnail = this.selectedProfile.thumbnail
          this.$store.state.embed.color = this.selectedProfile.color
          this.$root.$refs.EmbedProfiles.copyEmbedFromStore();
          this.dialog = false;
          this.profileName = "";
          this.selectedProfile = "";
    },

    deleteProfile() {
        let body = {};
        body.server = this.$store.state.chosenDiscord.id;
        body.profileName = this.selectedProfile.profileName;
        body.accessToken = localStorage.getItem('accessToken').slice(1,-1);
        axios
          .post(this.$store.state.url + "/api/profilesEmbed/delete/", body)
          .then((res) => { })
          .catch((error) => {
            console.log("Something went wrong!");
          })
          .finally(() => {
            this.snackbar2 = true;
            this.selectedProfile = ''
          });
    },
    saveProfile() {
        this.profileSnapshot.mentions = this.$store.state.embed.mentions
        this.profileSnapshot.title = this.$store.state.embed.title
			  this.profileSnapshot.description = this.$store.state.embed.description
			  this.profileSnapshot.author = this.$store.state.embed.author
			  this.profileSnapshot.fields = this.$store.state.embed.fields
			  this.profileSnapshot.footer = this.$store.state.embed.footer
			  this.profileSnapshot.image = this.$store.state.embed.image
			  this.profileSnapshot.thumbnail = this.$store.state.embed.thumbnail
        this.profileSnapshot.color = this.$store.state.embed.color
        this.profileSnapshot.profileName = this.profileName;
        this.profileSnapshot.server = this.$store.state.chosenDiscord.id
        this.profileSnapshot.accessToken = localStorage.getItem('accessToken').slice(1,-1);


            if (this.profileName !== undefined && this.profileName.length > 0) {
            axios
            .post(this.$store.state.url + "/api/profilesEmbed/create/", this.profileSnapshot)
            .then((res) => { })
            .catch((error) => {
              console.log("Something went wrong!");
            })
            .finally(() => {
              this.profileName = "";
              this.snackbar1 = true;
            });
        }
    },
    getProfiles() {
      let body = {};
      body.server = this.$store.state.chosenDiscord.id;
      body.accessToken = localStorage.getItem('accessToken').slice(1,-1);
        axios
          .post(this.$store.state.url + "/api/profilesEmbed/get/", body)
          .then((res) => {
            if (res.data.embeds) {
              this.loadedProfiles = res.data.embeds.sort(function(a, b) {
              return a.profileName.localeCompare(b.profileName);
            });
            } else {
            this.loadedProfiles = res.data.sort(function(a, b) {
              return a.profileName.localeCompare(b.profileName);
            });
            }
          })
          .catch((error) => {
            console.log(error)
            console.log("Something went wrong!");
          })
          .finally(() => {
            for (let index = 0; index < this.loadedProfiles.length; index++) {
              this.savedProfiles.push(this.loadedProfiles[index]);
            }
          });
    },

    cancelBtn() {
      this.dialog = false;
    },
  },
};
</script>
<style >
#form-profile.v-btn--absolute.v-btn--bottom,
#form-profile.v-btn--fixed.v-btn--bottom {
  right: 1px;
  margin-right: 350px;
}

#profile-card-title {
  background-color: rgb(67 67 78);
}



.settingsbuttons {
  opacity: 0.7;

}

.settingsbuttons:hover {
  opacity: 1;
}
</style>