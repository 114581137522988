<template>
  <v-container v-if="$store.state.chosenDiscord !== undefined" >
      		<v-layout row fluid @click="disableOverlay()">
      			<v-flex xs0 sm0 md0 lg1 xl2> </v-flex>
			<v-flex xs12 sm12 md12 lg10 xl8 class="my-5 rounded-lg" :class="$vuetify.breakpoint.xs ? 'mt-1' : 'mt-10'">
          <div v-if="this.$route.name === 'Settings'">
         
            <Sett id="Sett" />
            <div v-if="$store.state.chosenDiscord.hasPerms">

              <v-fade-transition>
                <v-overlay
                  :z-index="2"
                  v-if="overlay === true"
                  opacity="0.8"
                  color="primary"
                >
                </v-overlay>
              </v-fade-transition>
            </div>
          </div>

			</v-flex>
			<v-flex xs0 sm0 md0 lg1 xl2> </v-flex>
      		</v-layout>


  </v-container>
</template>

<script>
import Sett from "../components/Settings";

export default {
  name: "Settings",
  components: {
    Sett
  },
  data() {
    return {
      disableCreation: false,
      fab: false,
      overlay: false,
      snackbar2: false,
      snackbarInvalidForm: false,
      snackbarInvalidFormTimeout: 4000,
      opacityValue: "0",
    };
  },

	mounted() {
		setTimeout(this.showAd, 300);
	},
  	methods: {

    disableButtonTemporarily() {
      if (this.requirementsMet()) {
        this.disableCreation = true;
        setTimeout(() => this.disableCreation = false, 3000);
      }
    },

    requirementsMet() {
      return (
        this.$store.state.chosenDiscord.hasPerms &&
        this.$store.state.formAnswers.channel !== "" && this.$store.state.formAnswers.channel !== undefined &&
        this.$store.state.formAnswers.date !== "" && this.$store.state.formAnswers.date !== undefined
      );
    },

    disableOverlay() {
      if (this.overlay === true) {
        this.overlay = false;
      }
    },

    postFormAnswer() {
      this.$vuetify.goTo("#Form", {
        duration: 250,
        offset: 0,
        easing: "easeInOutQuad",
      });
      if (this.requirementsMet()) {
        this.$root.$refs.Form.postFormAnswer();
      } else {
        this.overlay = true;
        this.snackbarInvalidForm = true;
      }
    },

    getButtonColor() {
      if (
        this.$store.state.chosenDiscord.hasPerms &&
        this.$store.state.formAnswers.channel !== "" &&
        this.$store.state.formAnswers.date !== ""
      ) {
        return "green";
      } else {
        return "grey";
      }
    },
        getButtonStyle() {
        
      if (
        this.$store.state.chosenDiscord.hasPerms &&
        this.$store.state.formAnswers.channel !== "" &&
        this.$store.state.formAnswers.date !== ""
      ) {
        return "z-index: 10; box-shadow: 0px 0px 25px green;";
      } else {
        return "z-index: 10;";
      }
    },
  },
    beforeCreate() {
    if (this.$store.state.chosenDiscord === undefined) {
			this.$router.push('/dashboard/', () => {});
      return;
    }
  },
}
</script>

<style >
.v-list .v-list-item--link:before {
  background-color: #fff !important;
}
#custom-disabled.v-btn--disabled.theme--light {
  z-index: 10 !important; 
  box-shadow: 0px 0px 0px rgb(83, 83, 85) !important;
  opacity: 1;
}

</style>
