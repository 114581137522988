<template>
  <v-container class="pa-1">

    <v-col cols="12" class="mt-sm-n10 mt-n1 mb-sm-9 mb-4" style="background-color: #1d1d21; border-radius: 3px;">
      <v-row justify="space-between">
        <v-col>
          <div style="height: 20px;">

            <v-tooltip transition="v-scale-transition" color="transparent" right nudge-left="30px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn elevation="0" v-bind="attrs" v-on="on" small left
                  @click="$router.push('/dashboard/').catch(() => { })" :ripple="false" plain class="mt-n1 ml-n3"
                  color="#484850"><v-icon size="30">mdi-arrow-left-circle</v-icon></v-btn>
              </template><span style="color: grey;">Go back</span>
            </v-tooltip>
          </div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col>
          <div class="mt-n1"
            style="white-space:nowrap; color: white; font-size: 1.3em; text-align: center; height: 20px;">Calendar</div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col>
          <div style="text-align: right; height: 20px;">
            <v-tooltip transition="v-scale-transition" color="transparent" left nudge-right="30px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn elevation="0" v-bind="attrs" v-on="on" class="mr-n3 mt-n2" :ripple="false" style="cursor: default;"
                  plain>
                  <v-avatar class="" width="30" height="30" min-width="30" min-height="30">
                    <img
                      :src="serverIcon ? serverIcon : 'https://cdn.discordapp.com/icons/817542418420596766/f8ec64ca464b50d9f4a80e1256cabd7f.png'"
                      alt="DiscordAvatar" />

                  </v-avatar>
                </v-btn>
              </template><span style="color: grey;">{{ serverName }}</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" class="mt-n3 mt-sm-n8 mb-3" style="background-color: #1d1d21; border-style: solid;
            border-color: #2e2e33;
            border-width: 1px;
            border-top: none;
            border-bottom: none">
      <v-row justify="space-between">

        <v-spacer></v-spacer>


        <v-col class="mb-3 my-2">
          <div class="mt-n2" style="
              white-space: nowrap;
              color: white;
              font-size: 1.3em;
              text-align: center;
              height: 20px;
              
            ">

            <v-btn elevation="0" small left @click="changeMonth('minus')" :ripple="false" plain class="mb-1"
              color="#484850">
              <v-icon size="30">mdi-arrow-left-circle</v-icon>
            </v-btn>
            {{ months[currentMonth] }} {{ currentYear }}

            <v-btn elevation="0" small left @click="changeMonth('plus')" :ripple="false" plain class="mb-1"
              color="#484850">
              <v-icon size="30">mdi-arrow-right-circle</v-icon>
            </v-btn>
          </div>
        </v-col>

        <v-spacer></v-spacer>
      </v-row>
    </v-col>

    <div style="background-color: #1d1d21; margin-left: 1px; margin-right: 1px; border-left: 1px solid black;">
      <v-row class="mx-0" v-for="weekdayNumber in [0, 1, 2, 3, 4, 5, 6]" :key="weekdayNumber">

        <v-col :style="'margin-left: 0px;' +
          'width: 120%;' +
          'border-style: solid;' +
          'border-color: black;' +
          'border-width: 1px;' +
          'border-left: none;' +
          'border-bottom: none;' +
          'min-height: 120px;' +
          'min-width: 120px; '

          " v-for="(day, n) in monthList[weekdayNumber]" :key="n">
          <v-hover v-slot="{ hover }">
            <v-row style="min-height: 10px !important;">
              <div v-if="(ifCurrentDay(day) && !hover)" style="width: 100%; max-height: 24px;" class="ml-2 mt-1">
                <b class="mr-3" style="float: left; color: yellow;">
                  {{ weekdays[n] }}
                </b>



                <b class="mr-3" style="float: right; color: yellow;">
                  {{ day }}
                </b>
              </div>

              <v-card v-else-if="ifCurrentDay(day)" @click="goToEventCreation(day);" id="profile"
                style="width: 100%; max-height: 24px;" :ripple="false" dark color="primary" class="mt-1 mx-1"
                elevation="0">
                <v-row>

                  <v-col cols="6">
                    <v-icon v-if="hover" class="mb-1" medium style="color:yellow; float: left"> mdi-plus</v-icon>
                    <b v-else class="ml-1" style="color:yellow; float: left">
                      {{ weekdays[n] }}
                    </b>


                  </v-col>
                  <v-col cols="6">
                    <b class="mr-2" style="color:yellow; float: right">
                      {{ day }}
                    </b>

                  </v-col>

                </v-row>
              </v-card>

              <v-card v-else-if="hover" @click="goToEventCreation(day);" id="profile"
                style="width: 100%; max-height: 24px;" :ripple="false" dark color="primary" class="mt-1 mx-1"
                elevation="0">

                <v-row>

                  <v-col cols="6">

                    <v-icon v-if="hover" class="mb-1" medium style="color:white; float: left"> mdi-plus</v-icon>
                    <span v-else class="ml-1" style="color:white; float: left">
                      {{ weekdays[n] }}
                    </span>

                  </v-col>

                  <v-col cols="6">
                    <span class="mr-3" style="color:white; float: right">
                      {{ day }}
                    </span>
                  </v-col>

                </v-row>
              </v-card>


              <span v-else style="width: 100%" class="ml-2 mt-1 mx-1">
                <span class="mr-3" style="color:grey; float: left">
                  {{ weekdays[n] }}
                </span>

                <span class="mr-3" style="color:grey; float: right">
                  {{ day }}
                </span>

              </span>



              <br />


              <span v-if="returnEvent(day)[0]" style="width: 100%">
                <v-card @click="selectToEdit(event.raidId)" id="profile" v-for="(event, i) in returnEvent(day)"
                  :key="event + i" :ripple="false" dark color="primary" class="ma-2" elevation="0" :style="'border-left: 5px solid rgb(' + event.color + ') !important; border-right: 1px solid grey; '
                    ">
                  <v-card-actions>
                    <v-col class="mb-n2">
                      <v-row id="title">
                        <v-col>
                          <v-row class="mb-n1 ml-n2">
                            <v-img class="my-0" :src="'https://cdn.discordapp.com/emojis/' +
                              event.emote +
                              '.png'
                              " max-height="36" max-width="36"></v-img>
                            <span class="ml-2 mt-2" style="font-size: 0.8em">
                              {{ truncate((event.displayTitle != undefined ? event.displayTitle : event.title)) }}
                            </span>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-container>
                        <v-row class="pt-2 ml-n6">

                          <span style="white-space: nowrap; font-size: 1em" class="mt-2">
                            <span style="
                              background-color: #181a1c;
                              color: #dcd0d0;
                              border: 2px solid #212327;
                              border-right: 0px;
                              border-top-left-radius: 4px;
                              border-bottom-left-radius: 4px;
                            " class="pl-1 pr-6 py-1">
                              <v-img src="https://cdn.discordapp.com/emojis/592463749865275452.png" max-height="20"
                                max-width="20" style="display: inline-block; position: absolute"></v-img>
                            </span>
                            <span style="
                              background-color: #292b2f;
                              color: #adb1b6;
                              border: 2px solid #212327;
                              border-left: 0px;
                              border-top-right-radius: 4px;
                              border-bottom-right-radius: 4px;
                            " class="px-2 py-1 mr-3">{{
                              new Date(
                                event.unixtime * 1000
                              ).toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit",
                              })
                            }}</span>
                          </span>

                          <span style="white-space: nowrap; font-size: 1em" class="mt-2">
                            <span style="
                              background-color: #181a1c;
                              color: #dcd0d0;
                              border: 2px solid #212327;
                              border-right: 0px;
                              border-top-left-radius: 4px;
                              border-bottom-left-radius: 4px;
                            " class="pl-1 pr-6 py-1">
                              <v-img src="https://cdn.discordapp.com/emojis/592460751055224842.png" max-height="20"
                                max-width="20" style="display: inline-block; position: absolute"></v-img>
                            </span>
                            <span
                              style="background-color: #292b2f; color: #adb1b6; border: 2px solid #212327; border-left: 0px; border-top-right-radius: 4px; border-bottom-right-radius: 4px;"
                              class="px-2 py-1 mr-3">{{ event.signupcount }}</span>
                          </span>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-card-actions>
                </v-card>
              </span>
            </v-row>
          </v-hover>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import axios from "axios";
import Store from "../../store/index";
import DashNavi from "../../components/DashNavi";
import { mapActions } from "vuex";

export default {
  components: {
    DashNavi,
  },
  data() {
    return {
      allEvents: undefined,
      serverName: undefined,
      serverIcon: undefined,
      eventList: [],
      monthList: [],
      weekdays: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      currentDate: undefined,
      currentWeekday: undefined,
      currentDay: undefined,
      currentMonth: undefined,
      currentYear: undefined,
      serverIcon: undefined,
      serverName: undefined,
      loadingEvents: true,
      apiCalled: false,
      ratelimitSnackbar: false,
      ratelimitText: "",
      color: undefined,
      testObject: {
        template: "26",
        date: "5-4-2022",
        unixtime: 1649181600,
        leader: "Benni",
        image: "",
        color: "255,0,0",
        description: "1",
        title: "1",
        serverId: "586582443360911528",
        raidId: "961215346075918366",
        emote: "831430831809626142",
        channelName: "#all",
        time: "20:00",
        channelId: "890932499692257291",
      },
    };
  },
  methods: {

    goToEventCreation(date) {
      if (date != null) {
        this.$store.state.formAnswers.date = this.currentYear + '-' + (this.currentMonth + 1 < 10 ? "0" + (this.currentMonth + 1) : this.currentMonth + 1) + '-' + (date < 10 ? "0" + date : date) + " 00:00"
        this.$router.push("/create/",
          () => { }
        );
      }
    },

    ifCurrentDay(day) {
      if (this.currentDate.getDate() == day && this.currentDate.getMonth() == this.currentMonth && this.currentDate.getFullYear() == this.currentYear) {
        return true
      } else {
        return false
      }
    },

    truncate(input) {
      if (input.length > 18) {
        return input.substring(0, 18) + "..";
      }
      return input;
    },

    returnEvent(day) {
      if (day < 10) {
        day = "0" + day;
      }

      let outputList = [];
      var day1 = 24 * 60 * 60 * 1000;
      var todayTimestamp = new Date(this.currentYear + "-" + ((this.currentMonth + 1) < 10 ? "0" + (this.currentMonth + 1) : (this.currentMonth + 1)) + "-" + day + "T00:00:00");
      outputList = this.eventList.filter((key) => (key.unixtime * 1000 - todayTimestamp.getTime() >= 0 && (key.unixtime * 1000 - todayTimestamp.getTime()) <= day1));
      outputList.sort(function (a, b) {
        return a.unixtime - b.unixtime;
      });

      return outputList;
    },

    changeMonth(change) {
      this.loadingEvents = true;
      let currentMonthString = ""

      if (change == "plus") {
        this.currentMonth += 1;
        if (this.currentMonth > 11) {
          this.currentYear += 1;
          this.currentMonth = 0;
        }
      } else if (change == "minus") {
        this.currentMonth -= 1;
        if (this.currentMonth < 0) {
          this.currentYear -= 1;
          this.currentMonth = 11;
        }
      }

      if (this.currentMonth + 1 < 10) {
        currentMonthString = "0" + String(this.currentMonth + 1)
      } else {
        currentMonthString = String(this.currentMonth + 1)
      }

      let firstDateOfCurrentMonth = new Date(
        String(this.currentYear) + "-" + currentMonthString + "-01T00:00:00"
      );

      this.monthList = this.splitDaysToList(
        firstDateOfCurrentMonth.getDay(),
        this.getAllDaysInMonth(this.currentYear, this.currentMonth).length
      );

    },

    getAllDaysInMonth(year, month) {
      const date = new Date(year, month, 1);

      const dates = [];

      while (date.getMonth() === month) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }

      return dates;
    },

    async selectToEdit(raidId) {
      localStorage.setItem("selectedEventId", raidId);
      this.$store.state.selectedEventId = raidId;
      localStorage.setItem("calendar", "true")
      this.$router.push(
        "/event/" + this.$store.state.selectedEventId,
        () => { }
      );
    },

    splitDaysToList(firstDayWeekday, totalDays) {
      let monthOutput = [];
      let weekOutput = [];

      firstDayWeekday = (firstDayWeekday + 6) % 7

      if (firstDayWeekday !== 0) {
        for (let i = 0; i < firstDayWeekday; i++) {
          weekOutput.push(null);
        }
      }

      for (let i = 1; i < totalDays + 1; i++) {
        if (firstDayWeekday > 6) {
          firstDayWeekday = 0;
          monthOutput.push(weekOutput);
          weekOutput = [];
        }
        weekOutput.push(i);

        firstDayWeekday++;
      }
      firstDayWeekday--;
      if (firstDayWeekday !== 6) {
        for (let i = 0; firstDayWeekday < 6; firstDayWeekday++) {
          weekOutput.push(null);
        }
      }

      monthOutput.push(weekOutput);
      return monthOutput;
    },


    async getAllEvents() {
      if (!Store.state.authenticated) {
        window.location.href = this.$store.state.url + "/api/auth/discord";
      } else {
        this.req = {
          serverid: this.$route.params.serverId,
          accessToken: localStorage.getItem("accessToken").slice(1, -1),
        };

        try {
          const response = await axios.post(Store.state.url + "/api/events/", this.req);


          this.serverIcon = response.data.servericon;
          this.serverName = response.data.servername;
          this.allEvents = response.data.events;


          Store.state.calendarCache = this.allEvents;
          this.loadingEvents = false;
          this.apiCalled = true;


          this.eventList = this.allEvents;
          this.changeMonth('init')
        } catch (error) {
          console.log(error)
        }

      }
    },

  },

  mounted() {


    this.$store.state.selectedEvent = undefined;
    this.$store.state.selectedEventId = undefined;

    if (this.$store.state.calendarCache !== undefined) {
      this.eventsEven = this.$store.state.calendarCache;
      this.loadingEvents = false;
    }
  },
  created() {
    this.currentDate = new Date();
    this.currentYear = this.currentDate.getFullYear();
    this.currentMonth = this.currentDate.getMonth();
    this.currentWeekday = this.currentDate.getDay();
    this.currentDay = this.currentDate.getDate();
    this.getAllEvents()
    document.title = "Dashboard - Calendar"

  },
};
</script>

<style></style>
