import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        //url: "http://localhost:3001",
        url: "https://raid-helper.dev",
        //url: "https://raid-helper.xyz",
        //url: "http://149.56.133.14",

        discordGuilds: undefined,
        discordUser: undefined,
        authenticated: false,
        discordChannels: undefined,
        discordVoiceChannels: [],
        currentGuild: undefined,
        chosenDiscord: undefined,
        chosenDiscordRoles: [],
        accessToken: undefined,
        permissionLevel: undefined,
        loadedTemplates: undefined,
        settings: {},
        showBuffs: true,

        closedCache: undefined,
        upcomingCache: undefined,
        scheduledCache: undefined,
        calendarCache: undefined,

        selectedEventId: undefined,
        selectedEvent: undefined,
        selectedEventObject: {
            color: undefined,
            roles: undefined,
            information: {
                selectedType: undefined,
                selectedInput: undefined,
            }
        },

        formAnswers: {
            template: {},
            channel: {},
            title: "",
            description: "",
            date: "",
            time: "",
            imageUrl: "",
            color: "#71368A",
            mentions: [],
            optOut: "",
            announcementChannel: "",
            announcementTime: "",
            announcementMessage: "",
            allowed_roles: [],
            banned_roles: [],
            temporaryRoles: "",
            response: "",
            pollQuestion: "",
            pollAnswers: [],
            softresFaction: "Horde",
            softresInstance: "",
            softresMaxres: "1",
            softresBehaviour: "true",
            voice_channel: "",
        },

        premiumFeatures: {
            recurring: "",
            recurringUnit: "days",
            scheduleDate: "",
            interval2Ordinal: "",
            interval2Weekday: "",
        },

        systemDefaultAdvancedSettings: {
            auto_mirror: "none",
            limit_per_user: 1,
            lower_limit: 0,
            apply_unregister: false,
            apply_specreset: true,
            show_on_overview: true,
            mention_leader: false,
            show_content: true,
            event_type: "reaction",
            deletion: "false",
            limit: 9999,
            mention_mode: false,
            bench_overflow: true,
            show_emotes: true,
            image: "none",
            thumbnail: "none",
            show_footer: true,
            allow_duplicate: false,
            show_classes: false,
            alt_names: false,
            strawpoll_type: "selection",
            preserve_order: "normal",
            force_reminders: "false",
            create_thread: "false",
            forum_tags: "Event",
            delete_thread: false,
            use_nicknames: true,
            create_discordevent: true,
            only_discordevent: false,
            show_title: true,
            mentions: "none",
            show_info: true,
            show_leader: true,
            show_counter: true,
            opt_out: "none",
            color: "none",
            poll_type: "button",
            pin_message: false,
            spec_saving: true,
            vacuum: false,
            defaults_pre_req: true,
            deadline: "0",
            show_localtime: true,
            horizontal_mode: false,
            show_countdown: false,
            bold_all: true,
            font_style: "3",
            temp_role: "false",
            allowed_roles: "all",
            banned_roles: "none",
            queue_bench: false,
            show_roles: true,
            response: "none",
            lock_at_limit: true,
            disable_archiving: false,
            leader_emote: "1124529969926779050",
            signups1_emote: "1124529971428339752",
            signups2_emote: "593930418932285440",
            date1_emote: "1124529967611523272",
            date2_emote: "593930359985405983",
            time1_emote: "1124529972883767398",
            time2_emote: "593930235658108939",
            countdown1_emote: "1124530049329139772",
            countdown2_emote: "858794526176444467",
            show_numbering: true,
            show_allowed: false,
            show_banned: false,
            attendance: "true",
            info_variant: "short",
            date_variant: "local",
            duration: "0",
            event_type: "interaction",
            reminder: "false",
            bench_emote: "612373441051361353",
            late_emote: "612373443551297689",
            tentative_emote: "676284492754976788",
            absence_emote: "612343589070045200",
            thumbnail: "none",
        },

        defaultAdvancedSettings: {
            deadline: "",
            limit: "",
            lock_at_limit: "",
            limit_per_user: "",
            lower_limit: "",
            horizontal_mode: "",
            bench_overflow: "",
            queue_bench: "",
            vacuum: "",
            force_reminders: "",
            create_thread: "",
            forum_tags: "",
            delete_thread: "",
            use_nicknames: "",
            create_discordevent: "",
            only_discordevent: "",
            pin_message: "",
            deletion: "",
            mention_mode: "",
            preserve_order: "",
            apply_unregister: "",
            apply_specreset: "",
            show_on_overview: "",
            mention_leader: "",
            spec_saving: "",
            font_style: "",
            alt_names: "",
            attendance: "",
            show_title: "",
            show_info: "",
            show_leader: "",
            show_counter: "",
            show_roles: "",
            show_content: "",
            show_classes: "",
            show_emotes: "",
            show_numbering: "",
            show_allowed: "",
            show_banned: "",
            show_footer: "",
            defaults_pre_req: "",
            disable_archiving: "",
            allow_duplicate: "",
            bold_all: "",
            info_variant: "",
            date_variant: "",
            localtime_as_link: "",
            show_countdown: "",
            duration: "",
            opt_out: "",
            event_type: "",
            reminder: "",
            response: "",
            bench_emote: "",
            late_emote: "",
            tentative_emote: "",
            absence_emote: "",
            leader_emote: "",
            signups1_emote: "",
            signups2_emote: "",
            date1_emote: "",
            date2_emote: "",
            time1_emote: "",
            time2_emote: "",
            countdown1_emote: "",
            countdown2_emote: "",
            thumbnail: "",
        },

        advancedSettings: {
            deadline: "",
            limit: "",
            lock_at_limit: "",
            limit_per_user: "",
            lower_limit: "",
            horizontal_mode: "",
            bench_overflow: "",
            queue_bench: "",
            vacuum: "",
            force_reminders: "",
            create_thread: "",
            forum_tags: "",
            delete_thread: "",
            use_nicknames: "",
            create_discordevent: "",
            only_discordevent: "",
            pin_message: "",
            deletion: "",
            mention_mode: "",
            preserve_order: "",
            apply_unregister: "",
            apply_specreset: "",
            show_on_overview: "",
            mention_leader: "",
            spec_saving: "",
            font_style: "",
            alt_names: "",
            attendance: "",
            show_title: "",
            show_info: "",
            show_roles: "",
            show_content: "",
            show_classes: "",
            show_emotes: "",
            show_numbering: "",
            show_allowed: "",
            show_banned: "",
            show_footer: "",
            defaults_pre_req: "",
            disable_archiving: "",
            bold_all: "",
            allow_duplicate: "",
            info_variant: "",
            date_variant: "",
            localtime_as_link: "",
            show_countdown: "",
            duration: "",
            opt_out: "",
            event_type: "",
            reminder: "",
            response: "",
            bench_emote: "",
            late_emote: "",
            tentative_emote: "",
            absence_emote: "",
            leader_emote: "",
            signups1_emote: "",
            signups2_emote: "",
            date1_emote: "",
            date2_emote: "",
            time1_emote: "",
            time2_emote: "",
            countdown1_emote: "",
            countdown2_emote: "",
            thumbnail: "",
        },

        embed: {
            mentions: "",
            title: "",
            description: "",
            author: { name: "", url: "", icon_url: "" },
            fields: [],
            footer: { text: "", icon_url: "" },
            image: "",
            thumbnail: "",
            color: "",
        },
        savedProfiles: "",

        raidplan: {
            profileName: "",
            hash: "",
            accessToken: "",
            title: "",
            permissionLevel: "",
            visibilityLevel: "",
            templateId: "",
            importedEventId: "",
            partyPerRaid: "",
            slotPerParty: "",
            raidDrop: "",
            raidDropBench: "",
            partyNames: "",
            last_updated: "",
            divideArray: "",
            displayRoles: "",
            displayClasses: "",
            overlayDropColorObject: "",
        },


        templates: [{
                id: 23,
                name: "Poll",
                shortName: "basepoll",
                image: "830737466532429855",
                classes: [],
                roles: []
            },

            {
                id: 24,
                name: "Sorted Strawpoll",
                shortName: "sortedstrawpoll",
                image: "830736955053703199",
                classes: [],
                roles: []
            },

            {
                id: 25,
                name: "Unsorted Strawpoll",
                shortName: "unsortedstrawpoll",
                image: "747844490705895454",
                classes: [],
                roles: []
            },

            {
                id: 0,
                name: "Standard",
                shortName: "standard",
                image: "831430831809626142",
                classes: [{ name: "Accepted", value: 999, id: "831430831809626142" }, { name: "Maybe", value: 999, id: "831430900113866784" }, { name: "Declined", value: 999, id: "831430856405024798" }],
                roles: []
            },

            {
                id: 31,
                name: "WoW Classic",
                shortName: "wowclassic",
                image: "599020379125841954",
                classes: [{ name: "Tank", value: 999, id: "580801859221192714" }, { name: "Hunter", value: 999, id: "579532029880827924" }, { name: "Druid", value: 999, id: "579532029675438081" }, { name: "Warrior", value: 999, id: "579532030153588739" }, { name: "Mage", value: 999, id: "579532030161977355" }, { name: "Rogue", value: 999, id: "579532030086217748" }, { name: "Warlock", value: 999, id: "579532029851336716" }, { name: "Priest", value: 999, id: "579532029901799437" }, { name: "Paladin", value: 999, id: "579532029906124840" }, { name: "Shaman", value: 999, id: "579532030056857600" }],
                roles: [{ name: "Tanks", value: 999, id: "598989638098747403" }, { name: "Melee", value: 999, id: "734439523328720913" }, { name: "Ranged", value: 999, id: "592446395596931072" }, { name: "Healers", value: 999, id: "592438128057253898" }]
            },

            {
                id: 40,
                name: "WoW Classic: SoD",
                shortName: "wowsod",
                image: "599020379125841954",
                classes: [{ name: "Tank", value: 999, id: "878310168289505301" }, { name: "Melee", value: 999, id: "734439523328720913" }, { name: "Ranged", value: 999, id: "592446395596931072" }, { name: "Healer", value: 999, id: "898011741735235645" }],
                roles: [{ name: "DPS", value: 999, id: "592440132129521664" }]
            },
            {
                id: 41,
                name: "WoW Cataclysm",
                shortName: "wowcata",
                image: "599020379125841954",
                classes: [{ name: "Tank", value: 999, id: "580801859221192714" }, { name: "DK", value: 999, id: "599012538935410701" }, { name: "Hunter", value: 999, id: "579532029880827924" }, { name: "Druid", value: 999, id: "579532029675438081" }, { name: "Warrior", value: 999, id: "579532030153588739" }, { name: "Mage", value: 999, id: "579532030161977355" }, { name: "Rogue", value: 999, id: "579532030086217748" }, { name: "Warlock", value: 999, id: "579532029851336716" }, { name: "Priest", value: 999, id: "579532029901799437" }, { name: "Paladin", value: 999, id: "579532029906124840" }, { name: "Shaman", value: 999, id: "579532030056857600" }],
                roles: [{ name: "Tanks", value: 999, id: "598989638098747403" }, { name: "Melee", value: 999, id: "734439523328720913" }, { name: "Ranged", value: 999, id: "592446395596931072" }, { name: "Healers", value: 999, id: "592438128057253898" }]
            },
            {
                id: 3,
                name: "WoW TBC",
                shortName: "wowtbc",
                image: "599020379125841954",
                classes: [{ name: "Tank", value: 999, id: "580801859221192714" }, { name: "Hunter", value: 999, id: "579532029880827924" }, { name: "Druid", value: 999, id: "579532029675438081" }, { name: "Warrior", value: 999, id: "579532030153588739" }, { name: "Mage", value: 999, id: "579532030161977355" }, { name: "Rogue", value: 999, id: "579532030086217748" }, { name: "Warlock", value: 999, id: "579532029851336716" }, { name: "Priest", value: 999, id: "579532029901799437" }, { name: "Paladin", value: 999, id: "579532029906124840" }, { name: "Shaman", value: 999, id: "579532030056857600" }],
                roles: [{ name: "Tanks", value: 999, id: "598989638098747403" }, { name: "Melee", value: 999, id: "734439523328720913" }, { name: "Ranged", value: 999, id: "592446395596931072" }, { name: "Healers", value: 999, id: "592438128057253898" }]
            },

            {
                id: 4,
                name: "WoW WotLK",
                shortName: "wowwotlk",
                image: "732179749480300546",
                classes: [{ name: "Tank", value: 999, id: "580801859221192714" }, { name: "DK", value: 999, id: "599012538935410701" }, { name: "Hunter", value: 999, id: "579532029880827924" }, { name: "Druid", value: 999, id: "579532029675438081" }, { name: "Warrior", value: 999, id: "579532030153588739" }, { name: "Mage", value: 999, id: "579532030161977355" }, { name: "Rogue", value: 999, id: "579532030086217748" }, { name: "Warlock", value: 999, id: "579532029851336716" }, { name: "Priest", value: 999, id: "579532029901799437" }, { name: "Paladin", value: 999, id: "579532029906124840" }, { name: "Shaman", value: 999, id: "579532030056857600" }],
                roles: [{ name: "Tanks", value: 999, id: "598989638098747403" }, { name: "Melee", value: 999, id: "734439523328720913" }, { name: "Ranged", value: 999, id: "592446395596931072" }, { name: "Healers", value: 999, id: "592438128057253898" }]
            },

            {
                id: 5,
                name: "WoW Retail 1",
                shortName: "wowretail1",
                image: "599020379125841954",
                classes: [{ name: "DK", value: 999, id: "599012538935410701" }, { name: "DH", value: 999, id: "599012539015364633" }, { name: "Monk", value: 999, id: "599012539556167691" }, { name: "Hunter", value: 999, id: "579532029880827924" }, { name: "Druid", value: 999, id: "579532029675438081" }, { name: "Warrior", value: 999, id: "579532030153588739" }, { name: "Mage", value: 999, id: "579532030161977355" }, { name: "Rogue", value: 999, id: "579532030086217748" }, { name: "Warlock", value: 999, id: "579532029851336716" }, { name: "Priest", value: 999, id: "579532029901799437" }, { name: "Paladin", value: 999, id: "579532029906124840" }, { name: "Shaman", value: 999, id: "579532030056857600" }, { name: "Evoker", value: 999, id: "1013161650184003744" }],
                roles: [{ name: "Tanks", value: 999, id: "598989638098747403" }, { name: "Melee", value: 999, id: "734439523328720913" }, { name: "Ranged", value: 999, id: "592446395596931072" }, { name: "Healers", value: 999, id: "592438128057253898" }]
            },

            {
                id: 6,
                name: "WoW Retail 2",
                shortName: "wowretail2",
                image: "599020379125841954",
                roles: [],
                classes: [{ name: "Tank", value: 999, id: "878310168289505301" }, { name: "Melee", value: 999, id: "734439523328720913" }, { name: "Ranged", value: 999, id: "592446395596931072" }, { name: "Healer", value: 999, id: "898011741735235645" }]
            },

            {
                id: 7,
                name: "Default RPG",
                shortName: "defaultrpg",
                image: "598966084091248646",
                roles: [],
                classes: [{ name: "Tank", value: 999, id: "878310168289505301" }, { name: "Melee", value: 999, id: "734439523328720913" }, { name: "Ranged", value: 999, id: "592446395596931072" }, { name: "Healer", value: 999, id: "898011741735235645" }, { name: "Support", value: 999, id: "878310168344035338" }]
            },


            {
                id: 8,
                name: "Basic",
                shortName: "basic",
                image: "596014739398721536",
                classes: [{ name: "Yes", value: 999, id: "596014739398721536" }],
                roles: []
            },

            {
                id: 9,
                name: "Custom Colors",
                shortName: "colors",
                image: "606127767528144917",
                classes: [{ name: "Special", value: 999, id: "606127767528144917" }, { name: "Black", value: 999, id: "606120236479021062" }, { name: "White", value: 999, id: "606120236743262238" }, { name: "Red", value: 999, id: "606120236646662154" }, { name: "Blue", value: 999, id: "606120236948783113" }, { name: "Yellow", value: 999, id: "606120437692497930" }, { name: "Pink", value: 999, id: "606120236613369856" }, { name: "Green", value: 999, id: "606120236584009728" }, { name: "Grey", value: 999, id: "606120236311379975" }],
                roles: []
            },

            {
                id: 10,
                name: "Final Fantasy XIV",
                shortName: "ffxiv",
                image: "600228478297571328",
                classes: [{ name: "Tank", value: 999, id: "823967222778298389" }, { name: "Melee", value: 999, id: "823967222811852830" }, { name: "Ranged", value: 999, id: "823967222677504091" }, { name: "Magical", value: 999, id: "823967222874636298" }, { name: "Healer", value: 999, id: "823967225270501376" }, { name: "Allrounder", value: 999, id: "869859911163605012" }],
                roles: [{ name: "Tank", value: 999, id: "823967222778298389" }, { name: "DPS", value: 999, id: "823965472299024505" }, { name: "Healer", value: 999, id: "823967225270501376" }]
            },

            {
                id: 11,
                name: "The Elder Scrolls Online",
                shortName: "eso",
                image: "683822339431071756",
                roles: [{ name: "Dps", value: 999, id: "592440132129521664" }, ],
                classes: [{ name: "Tank", value: 999, id: "878310168289505301" }, { name: "Stamina", value: 999, id: "932242376779513898" }, { name: "Magicka", value: 999, id: "904494123527069789" }, { name: "Healer", value: 999, id: "898011741735235645" }]
            },

            {
                id: 12,
                name: "Guild Wars 2",
                shortName: "gw2",
                image: "686307981549502508",
                roles: [],
                classes: [{ name: "Tank", value: 999, id: "878310168289505301" }, { name: "Dps", value: 999, id: "592440132129521664" }, { name: "Healer", value: 999, id: "898011741735235645" }, { name: "Bannerslave", value: 999, id: "686325288694579230" }, { name: "Support", value: 999, id: "878310168344035338" }]
            },

            {
                id: 13,
                name: "Star Wars: TOR",
                shortName: "swtor",
                image: "794787500945571851",
                classes: [{ name: "Tank", value: 999, id: "878310168289505301" }, { name: "Melee", value: 999, id: "734439523328720913" }, { name: "Ranged", value: 999, id: "592446395596931072" }, { name: "Healer", value: 999, id: "898011741735235645" }],
                roles: []
            },

            {
                id: 14,
                name: "League of Legends",
                shortName: "lol",
                image: "796548085475311617",
                classes: [{ name: "Top", value: 999, id: "796543277129269261" }, { name: "Jungle", value: 999, id: "796543218732892215" }, { name: "Mid", value: 999, id: "796543218169675787" }, { name: "Bot", value: 999, id: "796543218900271104" }, { name: "Support", value: 999, id: "796543219269369868" }, { name: "Fill", value: 999, id: "796543219105660948" }, { name: "Spectator", value: 999, id: "796546490972766268" }],
                roles: []
            },

            {
                id: 15,
                name: "Lord of the Rings",
                shortName: "lotro",
                image: "796586962785533962",
                classes: [{ name: "Beorning", value: 999, id: "796583829778595890" }, { name: "Brawler", value: 999, id: "898417226540011560" }, { name: "Burglar", value: 999, id: "796583830512205834" }, { name: "Captain", value: 999, id: "796583831019847690" }, { name: "Champion", value: 999, id: "796583830433562641" }, { name: "Guardian2", value: 999, id: "796583831087218758" }, { name: "Hunter1", value: 999, id: "796583830994812968" }, { name: "LoreMaster", value: 999, id: "796583830936485949" }, { name: "Minstrel", value: 999, id: "796583831016177766" }, { name: "RuneKeeper", value: 999, id: "796583831124574277" }, { name: "Warden", value: 999, id: "796583830982754305" }],
                roles: []
            },

            {
                id: 16,
                name: "Destiny 2",
                shortName: "destiny2",
                image: "796891777683554345",
                classes: [{ name: "Titan", value: 999, id: "814712823899881484" }, { name: "Hunter2", value: 999, id: "814712823862001684" }, { name: "Warlock1", value: 999, id: "814712824009588746" }],
                roles: []
            },

            {
                id: 26,
                name: "Swords of Legends",
                shortName: "swordsoflegends",
                image: "869099081862086667",
                classes: [{ name: "Spellsword", value: 999, id: "869096123321704508" }, { name: "Reaper", value: 999, id: "869096122981969970" }, { name: "Bard", value: 999, id: "869096121434267648" }, { name: "Summoner", value: 999, id: "869096123262967848" }, { name: "Berserker", value: 999, id: "869096123078426644" }, { name: "Spearmaster", value: 999, id: "869096123313319936" }, { name: "Foxmage", value: 999, id: "941337769815470152" }, { name: "Warrior", value: 999, id: "941337770033561630" }],
                roles: [{ name: "Tanks", value: 999, id: "598989638098747403" }, { name: "Melee", value: 999, id: "734439523328720913" }, { name: "Ranged", value: 999, id: "592446395596931072" }, { name: "Supports", value: 999, id: "663649500065300484" }]
            },
            {
                id: 27,
                name: "Lost Ark",
                shortName: "lostark",
                image: "877979009038827591",
                classes: [{ name: "Warrior", value: 999, id: "877965853491101740" }, { name: "Martial_Artist", value: 999, id: "877966126158585886" }, { name: "Assassin", value: 999, id: "877966848556163122" }, { name: "Gunner", value: 999, id: "877966848652623922" }, { name: "Mage", value: 999, id: "877966520586752030" }, { name: "Specialist", value: 999, id: "936456800126009375" }],
                roles: [{ name: "DPS", value: 999, id: "592440132129521664" }, { name: "Support", value: 999, id: "878310168344035338" }, ]
            },
            {
                id: 28,
                name: "Dark Age of Camelot",
                shortName: "daoc",
                image: "878305940858892348",
                roles: [],
                classes: [{ name: "Tank", value: 999, id: "878310168289505301" }, { name: "Melee", value: 999, id: "734439523328720913" }, { name: "Ranged", value: 999, id: "592446395596931072" }, { name: "Support", value: 999, id: "878310168344035338" }]
            },
            {
                id: 29,
                name: "New World 1",
                shortName: "nw1",
                image: "869104112048934932",
                classes: [{ name: "Sword", value: 999, id: "878760348071637033" }, { name: "Flail", value: 999, id: "1159958456770515044" }, { name: "Hatchet", value: 999, id: "878761204078764093" }, { name: "Rapier", value: 999, id: "878760348214243348" }, { name: "Spear", value: 999, id: "878760348285566996" }, { name: "War_Hammer", value: 999, id: "878760348419780678" }, { name: "Great_Axe", value: 999, id: "878760347635449858" }, { name: "Bow", value: 999, id: "878762627759751239" }, { name: "Musket", value: 999, id: "878760348071628824" }, { name: "Blunderbuss", value: 999, id: "961578908283711498" }, { name: "Fire_Staff", value: 999, id: "878761203910995968" }, { name: "Life_Staff", value: 999, id: "878760347954196501" }, { name: "Ice_Gauntlet", value: 999, id: "878760348138741830" }, { name: "Void_Gauntlet", value: 999, id: "911091628238864394" }],
                roles: []
            },
            {
                id: 30,
                name: "New World 2",
                shortName: "nw2",
                image: "869104112048934932",
                classes: [{ name: "Tank", value: 999, id: "878310168289505301" }, { name: "Melee", value: 999, id: "734439523328720913" }, { name: "Ranged", value: 999, id: "592446395596931072" }, { name: "Mage", value: 999, id: "898001609735426118" }, { name: "Healer", value: 999, id: "898011741735235645" }, { name: "Artillery", value: 999, id: "898009055031877672" }],
                roles: []
            },
            {
                id: 42,
                name: "Tarisland",
                shortName: "taris",
                image: "1250504567678963722",
                classes: [{ name: "Tank", value: 999, id: "878310168289505301" }, { name: "Dps", value: 999, id: "592440132129521664" }, { name: "Healer", value: 999, id: "898011741735235645" }],
                roles: []
            },
            {
                id: 43,
                name: "Throne & Liberty",
                shortName: "throne&liberty",
                image: "1283209903883812875",
                classes: [{ name: "SwordShield", value: 999, id: "1283217305836454004" }, { name: "Greatsword", value: 999, id: "1283217475290267648" }, { name: "Daggers", value: 999, id: "1283217469049147533" }, { name: "Staff", value: 999, id: "1283217492180733992" }, { name: "Longbow", value: 999, id: "1283217483821744179" }, { name: "Crossbow", value: 999, id: "1283217457913532528" }, { name: "WandTome", value: 999, id: "1283217315466580071" }],
                roles: []
            },
        ],

        textEmotes: [
            ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '-', 'ß', '&', '+', ' '],
            [{ name: '0', id: '603730620970958868' }, { name: '1', id: '587930572094046218' }, { name: '2', id: '587930571800576012' }, { name: '3', id: '587930572282789898' }, { name: '4', id: '587930572094177300' }, { name: '5', id: '587930571603312691' }, { name: '6', id: '587930571863490561' }, { name: '7', id: '587930572077400067' }, { name: '8', id: '587930572115148811' }, { name: '9', id: '587930572345835525' }, { name: 'A', id: '581185331236306993' }, { name: 'B', id: '581205474716811284' }, { name: 'C', id: '581205474599370894' }, { name: 'D', id: '581205474674999296' }, { name: 'E', id: '581215720143061000' }, { name: 'F', id: '581205474708422656' }, { name: 'G', id: '581205475295756309' }, { name: 'H', id: '581205474695708804' }, { name: 'I', id: '581205474716942351' }, { name: 'J', id: '581205474863611933' }, { name: 'K', id: '581205474511159479' }, { name: 'L', id: '581205474704097298' }, { name: 'M', id: '581184451363602452' }, { name: 'N', id: '581185351238811754' }, { name: 'O', id: '581205474670542855' }, { name: 'P', id: '581205474444181545' }, { name: 'Q', id: '581205474901360660' }, { name: 'R', id: '581184453037129748' }, { name: 'S', id: '581184451200024579' }, { name: 'T', id: '581205474846703656' }, { name: 'U', id: '581205474515353601' }, { name: 'V', id: '581205474674868255' }, { name: 'W', id: '581205474851028992' }, { name: 'X', id: '581184450990309387' }, { name: 'Y', id: '581205474846834688' }, { name: 'Z', id: '581205474846965760' }, { name: '-', id: '606261099687313408' }, { name: 'ß', id: '630773221351751741' }, { name: '&', id: '630771645145022466' }, { name: '+', id: '630771760295575555' }, { name: ' ', id: '592384590375550976' }],
            [{ name: '0', id: '675578438857392129' }, { name: '1', id: '675578438651871244' }, { name: '2', id: '675578438693945354' }, { name: '3', id: '675578438895271962' }, { name: '4', id: '675578438840746005' }, { name: '5', id: '675578438618447873' }, { name: '6', id: '675578438781894681' }, { name: '7', id: '675578438995935242' }, { name: '8', id: '675578440581382154' }, { name: '9', id: '675578440640102430' }, { name: 'A', id: '675565660176121856' }, { name: 'B', id: '675565660100755462' }, { name: 'C', id: '675565660230909975' }, { name: 'D', id: '675565659819606019' }, { name: 'E', id: '675565660297756683' }, { name: 'F', id: '675565660138373152' }, { name: 'G', id: '675565660352413696' }, { name: 'H', id: '675565660146892812' }, { name: 'I', id: '675565660407070730' }, { name: 'J', id: '675565660637626369' }, { name: 'K', id: '675565660855730186' }, { name: 'L', id: '675565660578906112' }, { name: 'M', id: '675565660721512448' }, { name: 'N', id: '675565660713254923' }, { name: 'O', id: '675565660792815617' }, { name: 'P', id: '675565660914450457' }, { name: 'Q', id: '675567304872034315' }, { name: 'R', id: '675565660985753636' }, { name: 'S', id: '675565661065445376' }, { name: 'T', id: '675565660738420757' }, { name: 'U', id: '675565662797561896' }, { name: 'V', id: '675565660775907358' }, { name: 'W', id: '675565660805267466' }, { name: 'X', id: '675565661057056768' }, { name: 'Y', id: '675565660859793421' }, { name: 'Z', id: '675565660947873793' }, { name: '-', id: '675565660017000450' }, { name: 'ß', id: '675901572261216257' }, { name: '&', id: '675565659765342229' }, { name: '+', id: '675565659937046532' }, { name: ' ', id: '592384590375550976' }],
            [{ name: '0', id: '675578304731807754' }, { name: '1', id: '675578304593657856' }, { name: '2', id: '675578304518029331' }, { name: '3', id: '675578305008762880' }, { name: '4', id: '675578305101168690' }, { name: '5', id: '675578305004699725' }, { name: '6', id: '675578305025671189' }, { name: '7', id: '675578304912424990' }, { name: '8', id: '675578305000505372' }, { name: '9', id: '675578304786595841' }, { name: 'A', id: '675573195029479424' }, { name: 'B', id: '675573195138662420' }, { name: 'C', id: '675573195100782593' }, { name: 'D', id: '675573194890936341' }, { name: 'E', id: '675573195243388930' }, { name: 'F', id: '675573195465555979' }, { name: 'G', id: '675573195553636352' }, { name: 'H', id: '675573195365154853' }, { name: 'I', id: '675573195008507916' }, { name: 'J', id: '675573195402641409' }, { name: 'K', id: '675573195352440843' }, { name: 'L', id: '675573195704631296' }, { name: 'M', id: '675573195826397195' }, { name: 'N', id: '675573195796906005' }, { name: 'O', id: '675573195885248512' }, { name: 'P', id: '675573195797037057' }, { name: 'Q', id: '675573196124192770' }, { name: 'R', id: '675573195859820555' }, { name: 'S', id: '675573196396691496' }, { name: 'T', id: '675573195436326923' }, { name: 'U', id: '675573195486789643' }, { name: 'V', id: '675573195797168171' }, { name: 'W', id: '675573196128256003' }, { name: 'X', id: '675573195889180686' }, { name: 'Y', id: '675573196191432725' }, { name: 'Z', id: '675573196640092160' }, { name: '-', id: '675573195235000368' }, { name: 'ß', id: '675903782646710273' }, { name: '&', id: '675573195604230175' }, { name: '+', id: '675573195633459228' }, { name: ' ', id: '592384590375550976' }],

        ],

        carousel: [
            "https://cdn.discordapp.com/attachments/837116364220661819/889416672983806013/banner.gif"
        ],

        gameBanners: [{
                name: 'WoW Classic',
                icon: 'https://cdn.discordapp.com/emojis/599020379125841954.png?v=1',
                images: [
                    { url: 'https://raid-helper.dev/uploads/banners/classic/image0.jpg', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/classic/image1.jpg', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/classic/image2.jpg', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/classic/image3.jpg', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/classic/RH-AQ20.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/classic/RH-ZG2.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/classic/RH-AQ20-1.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/classic/RH-AQ40-2.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/classic/RH-AQ40-1.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/classic/RH-ZG3.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/classic/RH-Naxx-1.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/classic/RH-Naxx-2.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/classic/RH-Naxx-3.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/classic/RH-TBC-DarkPortal1-1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/classic/RH-TBC-DarkPortal2-1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/classic/RH-TBC-DarkPortal3-1200x300.png', credit: "_vergil" },
                ]
            },
            {
                name: 'WoW SoD',
                icon: 'https://cdn.discordapp.com/emojis/599020379125841954.png?v=1',
                images: [

                    { url: 'https://raid-helper.dev/uploads/banners/classic/bfd10.jpg', credit: "wyrmburner" },
                    { url: 'https://raid-helper.dev/uploads/banners/classic/bfd10.2.jpg', credit: "wyrmburner" },
                    { url: 'https://raid-helper.dev/uploads/banners/classic/gnomer10.jpg', credit: "wyrmburner" },
                    { url: 'https://raid-helper.dev/uploads/banners/classic/sunkentemple1.jpg', credit: "wyrmburner" },
                    { url: 'https://raid-helper.dev/uploads/banners/classic/sunkentemple2.jpg', credit: "wyrmburner" },
                ]
            },
            {
                name: 'WoW TBC',
                icon: 'https://cdn.discordapp.com/emojis/599020379125841954.png?v=1',
                images: [
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/RH-TBC-Gruul1-1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/RH-TBC-Gruul2-1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/RH-TBC-Karazhan1-1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/RH-TBC-Karazhan2-1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/RH-TBC-Karazhan3-1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/RH-TBC-Magtheridon1-1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/RH-TBC-Magtheridon2-1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/RH-TBC-GruulMaggie_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/1._TBC_KZ.png', credit: "🅼🅸🅽🅽#9347" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/2._TBC_GL.png', credit: "🅼🅸🅽🅽#9347" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/3._TBC_Mag.png', credit: "🅼🅸🅽🅽#9347" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/4._TBC_SSC.png', credit: "🅼🅸🅽🅽#9347" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/5._TBC_TK.png', credit: "🅼🅸🅽🅽#9347" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/6._TBC_HJ.png', credit: "🅼🅸🅽🅽#9347" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/7._TBC_BT.png', credit: "🅼🅸🅽🅽#9347" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/8._TBC_SWP.png', credit: "🅼🅸🅽🅽#9347" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/RH-TBC-SSC1_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/RH-TBC-TheEye1_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/RH-TBC-TheEye3_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/RH-TBC-SSCTK_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/RH-TBC-BT1_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/RH-TBC-BT2_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/RH-TBC-BT3_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/RH-TBC-BT4_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/RH-TBC-BT5_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/RH-TBC-Hyjal1_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/RH-TBC-Hyjal2_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/RH-TBC-ZA1_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/RH-TBC-ZA2_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/RH-TBC-ZA3_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/RH-TBC-MTBT3_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/RH-TBC-MTBT4_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/RH-TBC-MTBT1_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/RH-TBC-MTBT2_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/tbc/RH-TBC-SWP1_1200x300.png', credit: "_vergil" },
                ]
            },
            {
                name: 'WoW WotLK',
                icon: 'https://cdn.discordapp.com/emojis/732179749480300546.png?v=1',
                images: [
                    { url: 'https://raid-helper.dev/uploads/banners/wotlk/RH-Wrath-Naxx1_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/wotlk/RH-Wrath-Naxx2_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/wotlk/RH-Wrath-Dragons_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/wotlk/RH-Wrath-P1Raids_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/wotlk/RH-Wrath-VoA1_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/wotlk/allfour25.jpg', credit: "wyrmburner" },
                    { url: 'https://raid-helper.dev/uploads/banners/wotlk/ULDUAR.jpg', credit: "annondorf" },
                    { url: 'https://raid-helper.dev/uploads/banners/wotlk/003_Ulduar.png', credit: "feanor_87" },
                    { url: 'https://raid-helper.dev/uploads/banners/wotlk/Ulduar_.png', credit: "feanor_87" },
                    { url: 'https://raid-helper.dev/uploads/banners/wotlk/RH-Wrath-Ulduar_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/wotlk/ulduar_2.png', credit: "nx_87" },
                    { url: 'https://raid-helper.dev/uploads/banners/wotlk/ulduar_3.png', credit: "nx_87" },

                    { url: 'https://raid-helper.dev/uploads/banners/wotlk/TOCICC10.jpg', credit: "wyrmburner" },
                    { url: 'https://raid-helper.dev/uploads/banners/wotlk/TOCICC25.jpg', credit: "wyrmburner" },
                    { url: 'https://raid-helper.dev/uploads/banners/wotlk/VOAICC10.jpg', credit: "wyrmburner" },
                    { url: 'https://raid-helper.dev/uploads/banners/wotlk/VOAICC25.jpg', credit: "wyrmburner" },
                    { url: 'https://raid-helper.dev/uploads/banners/wotlk/RSICC10.jpg', credit: "wyrmburner" },
                    { url: 'https://raid-helper.dev/uploads/banners/wotlk/RSICC25.jpg', credit: "wyrmburner" },
                    { url: 'https://raid-helper.dev/uploads/banners/wotlk/ICC10.jpg', credit: "wyrmburner" },
                    { url: 'https://raid-helper.dev/uploads/banners/wotlk/ICC25.jpg', credit: "wyrmburner" },
                    { url: 'https://raid-helper.dev/uploads/banners/wotlk/ICC10.3.jpg', credit: "wyrmburner" },
                    { url: 'https://raid-helper.dev/uploads/banners/wotlk/ICC25.3.jpg', credit: "wyrmburner" },
                ]
            },
            {
                name: 'WoW Cataclysm',
                icon: 'https://cdn.discordapp.com/emojis/599020379125841954.png?v=1',
                images: [
                    { url: 'https://raid-helper.dev/uploads/banners/cata/cataraidsall.jpg', credit: "wyrmburner" },
                    { url: 'https://raid-helper.dev/uploads/banners/cata/cataraidsall10s.jpg', credit: "wyrmburner" },
                    { url: 'https://raid-helper.dev/uploads/banners/cata/cataraidsall25s.jpg', credit: "wyrmburner" },
                ]
            },
            {
                name: 'WoW Retail',
                icon: 'https://cdn.discordapp.com/emojis/599020379125841954.png?v=1',
                images: [
                    { url: 'https://raid-helper.dev/uploads/banners/retail/RH-CN-1.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/retail/RH-CN-2.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/retail/RH-MYTHICS-1.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/retail/RH-Firelands-1.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/retail/RH-Firelands-2.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/retail/RH-CN-5.png', credit: "heyitsmeacey" },
                    { url: 'https://raid-helper.dev/uploads/banners/retail/RH-CN-7.jpg', credit: "heyitsmeacey" },
                    { url: 'https://raid-helper.dev/uploads/banners/retail/RH-Tazavesh1_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/retail/RH-Tazavesh2_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/retail/RH-Tazavesh3_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/retail/RH-SoD1_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/retail/RH-SoD2_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/retail/RH-SoD3_1200x300.png', credit: "_vergil" },
                    { url: 'https://raid-helper.dev/uploads/banners/retail/raid3.png', credit: "heyitsmeacey" },
                    { url: 'https://raid-helper.dev/uploads/banners/retail/raid2.png', credit: "heyitsmeacey" },
                    { url: 'https://raid-helper.dev/uploads/banners/retail/raid1.png', credit: "heyitsmeacey" },
                    { url: 'https://raid-helper.dev/uploads/banners/retail/fatedraids.png', credit: "heyitsmeacey" },
                    { url: 'https://raid-helper.dev/uploads/banners/retail/voti.png', credit: "heyitsmeacey" },
                    { url: 'https://raid-helper.dev/uploads/banners/retail/VOTI_Banner_1.jpg', credit: "heyitsmeacey" },
                    { url: 'https://raid-helper.dev/uploads/banners/retail/VOTI_Banner_2.jpg', credit: "heyitsmeacey" },
                ]
            },
            {
                name: 'Elder Scrolls Online',
                icon: 'https://cdn.discordapp.com/emojis/683822339431071756.png?v=1',
                images: [
                    { url: 'https://raid-helper.dev/uploads/banners/eso/ESOAetherianArchive.png', credit: "AegoliusNyx#2772" },
                    { url: 'https://raid-helper.dev/uploads/banners/eso/ESOAsylumSanctorium.png', credit: "AegoliusNyx#2772" },
                    { url: 'https://raid-helper.dev/uploads/banners/eso/ESOAsylumSanctorium2.png', credit: "AegoliusNyx#2772" },
                    { url: 'https://raid-helper.dev/uploads/banners/eso/ESOCloudrest.png', credit: "AegoliusNyx#2772" },
                    { url: 'https://raid-helper.dev/uploads/banners/eso/ESOHelRaCitadel.png', credit: "AegoliusNyx#2772" },
                    { url: 'https://raid-helper.dev/uploads/banners/eso/ESOKynesAegis.png', credit: "AegoliusNyx#2772" },
                    { url: 'https://raid-helper.dev/uploads/banners/eso/ESOMawOfLorkhaj.png', credit: "AegoliusNyx#2772" },
                    { url: 'https://raid-helper.dev/uploads/banners/eso/ESORockgrove.png', credit: "AegoliusNyx#2772" },
                    { url: 'https://raid-helper.dev/uploads/banners/eso/ESOSanctumOphidia.png', credit: "AegoliusNyx#2772" },
                    { url: 'https://raid-helper.dev/uploads/banners/eso/ESOSunspire2.png', credit: "AegoliusNyx#2772" },
                    { url: 'https://raid-helper.dev/uploads/banners/eso/ESOTheHallsOfFabrication.png', credit: "AegoliusNyx#2772" },
                    { url: 'https://raid-helper.dev/uploads/banners/eso/ESODreadSail.png', credit: "veloxxity" },
                    { url: 'https://raid-helper.dev/uploads/banners/eso/ESODreadSail2.png', credit: "veloxxity" },
                    { url: 'https://raid-helper.dev/uploads/banners/eso/ESOSanitysEdge.png', credit: "veloxxity" },
                    { url: 'https://raid-helper.dev/uploads/banners/eso/ESOLucidentCitadel.png', credit: "veloxxity" },
                    { url: 'https://raid-helper.dev/uploads/banners/eso/ESOLucentCitadel.png', credit: "phnxz" },


                ]
            },
            {
                name: 'Guild Wars 2',
                icon: 'https://cdn.discordapp.com/emojis/686307981549502508.png?v=1',
                images: [
                    { url: 'https://raid-helper.dev/uploads/banners/gw2/achievements.png', credit: "Mirahann#9136" },
                    { url: 'https://raid-helper.dev/uploads/banners/gw2/bounties.png', credit: "Mirahann#9136" },
                    { url: 'https://raid-helper.dev/uploads/banners/gw2/gms.png', credit: "Mirahann#9136" },
                    { url: 'https://raid-helper.dev/uploads/banners/gw2/labyrinth.png', credit: "Mirahann#9136" },
                    { url: 'https://raid-helper.dev/uploads/banners/gw2/fractals.png', credit: "Mirahann#9136" },
                    { url: 'https://raid-helper.dev/uploads/banners/gw2/mapcomplete.png', credit: "Mirahann#9136" },
                    { url: 'https://raid-helper.dev/uploads/banners/gw2/strikes.png', credit: "Mirahann#9136" },
                    { url: 'https://raid-helper.dev/uploads/banners/gw2/other.png', credit: "Mirahann#9136" },
                    { url: 'https://raid-helper.dev/uploads/banners/gw2/dungeons.png', credit: "Mirahann#9136" },
                    { url: 'https://raid-helper.dev/uploads/banners/gw2/pvp.png', credit: "Mirahann#9136" },
                    { url: 'https://raid-helper.dev/uploads/banners/gw2/wvw.png', credit: "Mirahann#9136" },
                    { url: 'https://raid-helper.dev/uploads/banners/gw2/bosses.png', credit: "Mirahann#9136" },
                    { url: 'https://raid-helper.dev/uploads/banners/gw2/dragonbash.png', credit: "Mirahann#9136" },
                    { url: 'https://raid-helper.dev/uploads/banners/gw2/jubilee.png', credit: "Mirahann#9136" },
                    { url: 'https://raid-helper.dev/uploads/banners/gw2/raids.png', credit: "Mirahann#9136" },
                    { url: 'https://raid-helper.dev/uploads/banners/gw2/wintersday.png', credit: "Mirahann#9136" },
                    { url: 'https://raid-helper.dev/uploads/banners/gw2/sab.png', credit: "Mirahann#9136" },
                    { url: 'https://raid-helper.dev/uploads/banners/gw2/wing_1.png', credit: "Mirahann#9136" },
                    { url: 'https://raid-helper.dev/uploads/banners/gw2/wing_2.png', credit: "Mirahann#9136" },
                    { url: 'https://raid-helper.dev/uploads/banners/gw2/wing_3.5.png', credit: "Mirahann#9136" },
                    { url: 'https://raid-helper.dev/uploads/banners/gw2/wing_4.png', credit: "Mirahann#9136" },
                    { url: 'https://raid-helper.dev/uploads/banners/gw2/wing_5.png', credit: "Mirahann#9136" },
                    { url: 'https://raid-helper.dev/uploads/banners/gw2/wing_6.png', credit: "Mirahann#9136" },
                    { url: 'https://raid-helper.dev/uploads/banners/gw2/wing_7.png', credit: "Mirahann#9136" },
                ]
            },
            {
                name: 'Star Wars: The Old Republic',
                icon: 'https://cdn.discordapp.com/emojis/794787500945571851.png?v=1',
                images: [

                    { url: 'https://raid-helper.dev/uploads/banners/swtor/01-EV.png', credit: "unknown" },
                    { url: 'https://raid-helper.dev/uploads/banners/swtor/02-KP.png', credit: "unknown" },
                    { url: 'https://raid-helper.dev/uploads/banners/swtor/03-EC.png', credit: "unknown" },
                    { url: 'https://raid-helper.dev/uploads/banners/swtor/04-TfB.png', credit: "unknown" },
                    { url: 'https://raid-helper.dev/uploads/banners/swtor/06-DF.png', credit: "unknown" },
                    { url: 'https://raid-helper.dev/uploads/banners/swtor/07-DP.png', credit: "unknown" },
                    { url: 'https://raid-helper.dev/uploads/banners/swtor/05-SnV.png', credit: "unknown" },
                    { url: 'https://raid-helper.dev/uploads/banners/swtor/08-Ravagers.png', credit: "unknown" },
                    { url: 'https://raid-helper.dev/uploads/banners/swtor/09-ToS.png', credit: "unknown" },
                    { url: 'https://raid-helper.dev/uploads/banners/swtor/10-Gods.png', credit: "unknown" },
                    { url: 'https://raid-helper.dev/uploads/banners/swtor/11-dxun.png', credit: "unknown" },
                    { url: 'https://raid-helper.dev/uploads/banners/swtor/df.webp', credit: "syseea" },
                    { url: 'https://raid-helper.dev/uploads/banners/swtor/dp.webp', credit: "syseea" },
                    { url: 'https://raid-helper.dev/uploads/banners/swtor/dxun.webp', credit: "syseea" },
                    { url: 'https://raid-helper.dev/uploads/banners/swtor/ec.webp', credit: "syseea" },
                    { url: 'https://raid-helper.dev/uploads/banners/swtor/ev.webp', credit: "syseea" },
                    { url: 'https://raid-helper.dev/uploads/banners/swtor/eyeless.webp', credit: "syseea" },
                    { url: 'https://raid-helper.dev/uploads/banners/swtor/gods.webp', credit: "syseea" },
                    { url: 'https://raid-helper.dev/uploads/banners/swtor/hivequeen.webp', credit: "syseea" },
                    { url: 'https://raid-helper.dev/uploads/banners/swtor/kp.webp', credit: "syseea" },
                    { url: 'https://raid-helper.dev/uploads/banners/swtor/monolith.webp', credit: "syseea" },
                    { url: 'https://raid-helper.dev/uploads/banners/swtor/r4.webp', credit: "syseea" },
                    { url: 'https://raid-helper.dev/uploads/banners/swtor/rav.webp', credit: "syseea" },
                    { url: 'https://raid-helper.dev/uploads/banners/swtor/snv.webp', credit: "syseea" },
                    { url: 'https://raid-helper.dev/uploads/banners/swtor/tfb.webp', credit: "syseea" },
                    { url: 'https://raid-helper.dev/uploads/banners/swtor/toborros.webp', credit: "syseea" },
                    { url: 'https://raid-helper.dev/uploads/banners/swtor/tos.webp', credit: "syseea" },
                    { url: 'https://raid-helper.dev/uploads/banners/swtor/xenoanalyst.webp', credit: "syseea" },
                ]
            },
            {
                name: 'Final Fantasy XIV',
                icon: 'https://cdn.discordapp.com/emojis/600228478297571328.png?v=1',
                images: [
                    { url: 'https://raid-helper.dev/uploads/banners/ffxiv/1._E9S.png', credit: "J0sh#1648" },
                    { url: 'https://raid-helper.dev/uploads/banners/ffxiv/2._e10s.png', credit: "J0sh#1648" },
                    { url: 'https://raid-helper.dev/uploads/banners/ffxiv/3._e11s.png', credit: "J0sh#1648" },
                    { url: 'https://raid-helper.dev/uploads/banners/ffxiv/5._edens_promise.png', credit: "J0sh#1648" },
                    { url: 'https://raid-helper.dev/uploads/banners/ffxiv/SPOILER_P5S.png', credit: "ryklin" },
                    { url: 'https://raid-helper.dev/uploads/banners/ffxiv/SPOILER_P6S.png', credit: "ryklin" },
                    { url: 'https://raid-helper.dev/uploads/banners/ffxiv/SPOILER_P7S.png', credit: "ryklin" },
                    { url: 'https://raid-helper.dev/uploads/banners/ffxiv/SPOILER_P8S.png', credit: "ryklin" },
                ]
            },
            {
                name: 'Destiny 2',
                icon: 'https://cdn.discordapp.com/emojis/796891777683554345.png?v=1',
                images: [
                    { url: 'https://raid-helper.dev/uploads/banners/destiny2/DLR_D2_Nightfall.png', credit: "JonnMcDude#0970" },
                    { url: 'https://raid-helper.dev/uploads/banners/destiny2/DSC-SMALL.png', credit: "JonnMcDude#0970" },
                    { url: 'https://raid-helper.dev/uploads/banners/destiny2/GoS-SMALL.png', credit: "JonnMcDude#0970" },
                    { url: 'https://raid-helper.dev/uploads/banners/destiny2/LW-SMALL.png', credit: "JonnMcDude#0970" },
                    { url: 'https://raid-helper.dev/uploads/banners/destiny2/VOG-SMALL.png', credit: "JonnMcDude#0970" },
                    { url: 'https://raid-helper.dev/uploads/banners/destiny2/DLR_D2_Dungeon.png', credit: "JonnMcDude#0970" },
                    { url: 'https://raid-helper.dev/uploads/banners/destiny2/DLR_D2_Dungeon2.png', credit: "JonnMcDude#0970" },
                ]
            },
            {
                name: 'Lost Ark',
                icon: 'https://cdn.discordapp.com/emojis/877979009038827591.png?v=1',
                images: [
                    { url: 'https://raid-helper.dev/uploads/banners/lostark/Abyss_Raid_Argos.png', credit: "mercuryzx_" },
                    { url: 'https://raid-helper.dev/uploads/banners/lostark/Abyss_Raid_Kaishuter.png', credit: "mercuryzx_" },
                    { url: 'https://raid-helper.dev/uploads/banners/lostark/Abyss_Raid_Mystic.png', credit: "mercuryzx_" },
                    { url: 'https://raid-helper.dev/uploads/banners/lostark/Guardian_Raid.png', credit: "mercuryzx_" },
                    { url: 'https://raid-helper.dev/uploads/banners/lostark/Legion_Raid_Brelshaza.png', credit: "mercuryzx_" },
                    { url: 'https://raid-helper.dev/uploads/banners/lostark/Legion_Raid_Kakul-Saydon.png', credit: "mercuryzx_" },
                    { url: 'https://raid-helper.dev/uploads/banners/lostark/Legion_Raid_Valtan.png', credit: "mercuryzx_" },
                    { url: 'https://raid-helper.dev/uploads/banners/lostark/Legion_Raid_Vykas.png', credit: "mercuryzx_" },
                    { url: 'https://raid-helper.dev/uploads/banners/lostark/Guild_News.png', credit: "mercuryzx_" },
                    { url: 'https://raid-helper.dev/uploads/banners/lostark/Guild_Conference.png', credit: "mercuryzx_" },
                    { url: 'https://raid-helper.dev/uploads/banners/lostark/Guild_Event.png', credit: "mercuryzx_" },
                    { url: 'https://raid-helper.dev/uploads/banners/lostark/PVP_Deathmatch.png', credit: "mercuryzx_" },
                    { url: 'https://raid-helper.dev/uploads/banners/lostark/PVP_Team_Deathmatch.png', credit: "mercuryzx_" },
                    { url: 'https://raid-helper.dev/uploads/banners/lostark/PVP_Team_Elimination.png', credit: "mercuryzx_" },
                    { url: 'https://raid-helper.dev/uploads/banners/lostark/PVP_Tournament.png', credit: "mercuryzx_" },
                    { url: 'https://raid-helper.dev/uploads/banners/lostark/PVP.png', credit: "mercuryzx_" },
                    { url: 'https://raid-helper.dev/uploads/banners/lostark/PVP_Custom_Games.png', credit: "mercuryzx_" },
                ]
            },
        ],

        emoteAlphabet: [
            { 'A': '675573195029479424' },
            { 'B': '675573195138662420' },
            { 'C': '675573195100782593' },
            { 'D': '675573194890936341' },
            { 'E': '675573195243388930' },
            { 'F': '675573195465555979' },
            { 'G': '675573195553636352' },
            { 'H': '675573195365154853' },
            { 'I': '675573195008507916' },
            { 'J': '675573195402641409' },
            { 'K': '675573195352440843' },
            { 'L': '675573195704631296' },
            { 'M': '675573195826397195' },
            { 'N': '675573195796906005' },
            { 'O': '675573195885248512' },
            { 'P': '675573195797037057' },
            { 'Q': '675573196124192770' },
            { 'R': '675573195859820555' },
            { 'S': '675573196396691496' },
            { 'T': '675573195436326923' },
            { 'U': '675573195486789643' },
            { 'V': '675573195797168171' },
            { 'W': '675573196128256003' },
            { 'X': '675573195889180686' },
            { 'Y': '675573196191432725' },
            { 'Z': '675573196640092160' },
            { '-': '675573195235000368' },
            { '+': '675573195633459228' },
            { '&': '675573195604230175' },
            { 'ß': '675903782646710273' },
            { '1': '675578304593657856' },
            { '2': '675578304518029331' },
            { '3': '675578305008762880' },
            { '4': '675578305101168690' },
            { '5': '675578305004699725' },
            { '6': '675578305025671189' },
            { '7': '675578304912424990' },
            { '8': '675578305000505372' },
            { '9': '675578304786595841' },
            { '0': '675578304731807754' },
        ],

        cataBuffArray: [

            [{
                caption: 'Buffs',
                spellList: [
                    { id: "ARMORB", name: 'Armor', specs: ['Enhancement', 'Restoration1', 'Elemental', 'Holy1', 'Protection1', 'Retribution', ], tooltip: [{ spell: 'Devotion Aura', icon: 'spell_holy_devotionaura', color: '#F48CBA' }, { spell: 'Stoneskin Totem', icon: 'spell_nature_stoneskintotem', color: '#0070DD' }] },
                    { id: "STAMINAB", name: 'Stamina', specs: ['Arms', 'Fury', 'Protection', 'Demonology', 'Destruction', 'Affliction', 'Discipline', 'Holy', 'Shadow', 'Smite', 'Beast Mastery', 'Beastmastery', ], tooltip: [{ spell: 'Qiraji Fortitude', icon: 'ability_hunter_pet_silithid', color: '#AAD372' }, { spell: 'Power Word: Fortitude', icon: 'spell_holy_wordfortitude', color: '#FFFFFF' }, { spell: 'Blood Pact', icon: 'spell_shadow_bloodboil', color: '#8788EE' }, { spell: 'Commanding Shout', icon: 'ability_warrior_rallyingcry', color: '#C69B6D' }] },
                    { id: "STATSB", name: 'Stats', specs: ['Holy1', 'Protection1', 'Retribution', 'Beast Mastery', 'Beastmastery', 'Restoration', 'Guardian', 'Feral', 'Balance', ], tooltip: [{ spell: 'Mark of the Wild', icon: 'spell_nature_regeneration', color: '#FF7C0A' }, { spell: 'Embrace of the Shale Spider', icon: 'ability_hunter_pet_spider', color: '#AAD372' }, { spell: 'Blessing of Kings', icon: 'spell_magic_greaterblessingofkings', color: '#F48CBA' }] },
                    { id: "RESB", name: 'Resistances', specs: ['Holy1', 'Protection1', 'Retribution', 'Beast Mastery', 'Beastmastery', 'Restoration', 'Guardian', 'Feral', 'Balance', ], tooltip: [{ spell: 'Mark of the Wild', icon: 'spell_nature_regeneration', color: '#FF7C0A' }, { spell: 'Embrace of the Shale Spider', icon: 'ability_hunter_pet_spider', color: '#AAD372' }, { spell: 'Blessing of Kings', icon: 'spell_magic_greaterblessingofkings', color: '#F48CBA' }] },
                    { id: "SP6B", name: '+6% Spell Power', specs: ['Enhancement', 'Restoration1', 'Elemental', 'Frost', 'Fire', 'Arcane', ], tooltip: [{ spell: 'Arcane Brilliance', icon: 'spell_holy_magicalsentry', color: '#3FC7EB' }, { spell: 'Flametongue Totem', icon: 'spell_nature_guardianward', color: '#0070DD' }] },
                    { id: "MANAB", name: 'Mana', specs: ['Demonology', 'Destruction', 'Affliction', 'Frost', 'Fire', 'Arcane', ], tooltip: [{ spell: 'Arcane Brilliance', icon: 'spell_holy_magicalsentry', color: '#3FC7EB' }, { spell: 'Fel Intelligence', icon: 'spell_shadow_brainwash', color: '#8788EE' }] },
                    { id: "BHB", name: 'Bloodlust / Heroism', specs: ['Enhancement', 'Restoration1', 'Elemental', 'Frost', 'Fire', 'Arcane', 'Beast Mastery', 'Beastmastery', ], tooltip: [{ spell: 'Ancient Hysteria', icon: 'spell_shadow_unholyfrenzy', color: '#AAD372' }, { spell: 'Time Warp', icon: 'ability_mage_timewarp', color: '#3FC7EB' }, { spell: 'Bloodlust', icon: 'spell_nature_bloodlust', color: '#0070DD' }, { spell: 'Heroism', icon: 'ability_shaman_heroism', color: '#0070DD' }] },
                    { id: "SHB", name: 'Spell Haste', specs: ['Enhancement', 'Restoration1', 'Elemental', 'Shadow', 'Balance', ], tooltip: [{ spell: 'Moonkin Form', icon: 'spell_nature_forceofnature', color: 'FF7C0A' }, { spell: 'Shadowform', icon: 'spell_shadow_shadowform', color: '#FFFFFF' }, { spell: 'Wrath of Air Totem', icon: 'spell_nature_slowingtotem', color: '#0070DD' }] },
                    { id: "MRB", name: 'Mana Regen', specs: ['Arms', 'Fury', 'Protection', 'Demonology', 'Destruction', 'Affliction', 'Enhancement', 'Restoration1', 'Elemental', 'Holy1', 'Protection1', 'Retribution', ], tooltip: [{ spell: 'Blessing of Might', icon: 'spell_holy_greaterblessingofkings', color: '#F48CBA' }, { spell: 'Mana Spring Totem', icon: 'spell_nature_manaregentotem', color: '#0070DD' }, { spell: 'Fel Intelligence', icon: 'spell_shadow_brainwash', color: '#8788EE' }] },
                    { id: "SAB", name: 'Strength and Agility', specs: ['Arms', 'Fury', 'Protection', 'Enhancement', 'Restoration1', 'Elemental', 'Survival', 'Marksmanship', 'Marksman', 'Beast Mastery', 'Beastmastery', 'Unholy_Tank', 'Unholy_DPS', 'Blood_Tank', 'Blood_DPS', 'Frost_Tank', 'Frost_DPS', ], tooltip: [{ spell: 'Horn of Winter', icon: 'inv_misc_horn_02', color: '#C41E3A' }, { spell: 'Roar of Courage', icon: 'ability_hunter_catlikereflexes', color: '#AAD372' }, { spell: 'Strength of Earth Totem', icon: 'spell_nature_earthbindtotem', color: '#0070DD' }, { spell: 'Battle Shout', icon: 'ability_warrior_battleshout', color: '#C69B6D' }] },
                    { id: "FIRERB", name: 'Fire Resist', specs: ['Enhancement', 'Restoration1', 'Elemental', 'Holy1', 'Protection1', 'Retribution', ], tooltip: [{ spell: 'Resistance Aura', icon: 'spell_fire_sealoffire', color: '#F48CBA' }, { spell: 'Elemental Resistance Totem', icon: 'spell_fireresistancetotem_01', color: '#0070DD' }, ] },
                    { id: "FROSTB", name: 'Frost Resist', specs: ['Enhancement', 'Restoration1', 'Elemental', 'Holy1', 'Protection1', 'Retribution', ], tooltip: [{ spell: 'Resistance Aura', icon: 'spell_fire_sealoffire', color: '#F48CBA' }, { spell: 'Elemental Resistance Totem', icon: 'spell_fireresistancetotem_01', color: '#0070DD' }, ] },
                    { id: "NATURERB", name: 'Nature Resist', specs: ['Enhancement', 'Restoration1', 'Elemental', 'Survival', 'Marksmanship', 'Marksman', 'Beast Mastery', 'Beastmastery', ], tooltip: [{ spell: 'Aspect of the Wild', icon: 'spell_nature_protectionformnature', color: '#AAD372' }, { spell: 'Elemental Resistance Totem', icon: 'spell_fireresistancetotem_01', color: '#0070DD' }, ] },
                    { id: "MHB", name: 'Melee Haste', specs: ['Enhancement', 'Restoration1', 'Elemental', 'Frost_Tank', 'Frost_DPS', 'Survival'], tooltip: [{ spell: 'Improved Icy Talons', icon: 'spell_deathknight_icytalons', color: '#C41E3A' }, { spell: 'Hunting Party', icon: 'ability_hunter_huntingparty', color: '#AAD372' }, { spell: 'Windfury Totem', icon: 'spell_nature_windfury', color: '#0070DD' }, ] },
                    { id: "CSCB", name: 'Critical Strike Chance', specs: ['Fury', 'Elemental', 'Subtlety', 'Beast Mastery', 'Beastmastery', 'Guardian', 'Feral', ], tooltip: [{ spell: 'Leader of the Pack', icon: 'spell_nature_unyeildingstamina', color: '#FF7C0A' }, { spell: 'Terrifying Roar', icon: 'ability_hunter_pet_devilsaur', color: '#AAD372' }, { spell: 'Honor Among Thieves', icon: 'ability_rogue_honoramongstthieves', color: '#FFF468' }, { spell: 'Elemental Oath', icon: 'spell_shaman_elementaloath', color: '#0070DD' }, { spell: 'Rampage', icon: 'ability_warrior_rampage', color: '#C69B6D' }] },
                    { id: "APB", name: 'Attack Power', specs: ['Enhancement', 'Marksmanship', 'Marksman', 'Blood_Tank', 'Blood_DPS', 'Holy1', 'Protection1', 'Retribution'], tooltip: [{ spell: "Abomination's Might", icon: 'ability_warrior_intensifyrage', color: '#C41E3A' }, { spell: 'Trueshot Aura', icon: 'ability_trueshot', color: '#AAD372' }, { spell: 'Blessing of Might', icon: 'spell_holy_greaterblessingofkings', color: '#F48CBA' }, { spell: 'Unleashed Rage', icon: 'spell_nature_unleashedrage', color: '#0070DD' }] },
                    { id: "SPPB", name: 'Spell Pushback Protection', specs: ['Enhancement', 'Restoration1', 'Elemental', 'Holy1', 'Protection1', 'Retribution', ], tooltip: [{ spell: 'Concentration Aura', icon: 'spell_holy_mindsooth', color: '#F48CBA' }, { spell: 'Totem of Tranquil Mind', icon: 'spell_nature_brilliance', color: '#0070DD' }] },
                    { id: "SRB", name: 'Shadow Resist', specs: ['Discipline', 'Holy', 'Shadow', 'Smite', 'Holy1', 'Protection1', 'Retribution', ], tooltip: [{ spell: 'Resistance Aura', icon: 'spell_fire_sealoffire', color: '#F48CBA' }, { spell: 'Shadow Protection', icon: 'spell_holy_prayerofshadowprotection', color: '#FFFFFF' }] },
                    { id: "DB", name: 'Damage', specs: ['Retribution', 'Arcane', 'Beast Mastery', 'Beastmastery', ], tooltip: [{ spell: 'Ferocious Inspiration', icon: 'ability_hunter_ferociousinspiration', color: '#AAD372' }, { spell: 'Arcane Tactics', icon: 'spell_arcane_arcanetactics', color: '#3FC7EB' }, { spell: 'Communion', icon: 'ability_paladin_judgementofthewise', color: '#F48CBA' }] },
                    { id: "RB", name: 'Replenishment', specs: ['Subtlety', 'Shadow', 'Retribution', 'Frost', 'Restoration', ], tooltip: [{ spell: 'Revitalize', icon: 'ability_druid_empoweredtouch', color: '#FF7C0A' }, { spell: 'Enduring Winter', icon: 'spell_frost_arcticwinds', color: '#3FC7EB' }, { spell: 'Communion', icon: 'ability_paladin_judgementofthewise', color: '#F48CBA' }, { spell: 'Vampiric Touch', icon: 'spell_holy_stoicism', color: '#FFFFFF' }] },
                    { id: "MAB", name: 'Movement Abilities', specs: ['Holy1', 'Protection1', 'Retribution', ], tooltip: [{ spell: 'Crusader Aura', icon: 'spell_holy_crusaderaura', color: '#F48CBA' }] },
                    { id: "SP10B", name: '+10% Spell Power', specs: ['Demonology', 'Elemental', ], tooltip: [{ spell: 'Totemic Wrath', icon: 'spell_fire_totemofwrath', color: '#0070DD' }, { spell: 'Demonic Pact', icon: 'spell_shadow_demonicpact', color: '#8788EE' }] },
                ]
            }, ],

            [{
                caption: 'Debuffs',
                spellList: [
                    { id: "PDDD", name: 'Physical Damage Dealt', specs: ['Arms', 'Fury', 'Protection', 'Demonology', 'Destruction', 'Affliction', 'Protection1', 'Restoration', 'Guardian', 'Feral', 'Balance', 'Blood_Tank', 'Blood_DPS', ], tooltip: [{ spell: 'Scarlet Fever', icon: 'ability_rogue_vendetta', color: '#C41E3A' }, { spell: 'Demoralizing Roar', icon: 'classic_ability_druid_demoralizingroar', color: '#FF7C0A' }, { spell: 'Vindication', icon: 'spell_holy_vindication', color: '#F48CBA' }, { spell: 'Curse of Weakness', icon: 'spell_shadow_curseofmannoroth', color: '#8788EE' }, { spell: 'Demoralizing Shout', icon: 'ability_warrior_warcry', color: '#C69B6D' }] },
                    { id: "ARD", name: 'Armor Reduction', specs: ['Arms', 'Fury', 'Protection', 'Assassination', 'Combat', 'Subtlety', 'Survival', 'Marksmanship', 'Marksman', 'Beast Mastery', 'Beastmastery', 'Restoration', 'Guardian', 'Feral', 'Balance', ], tooltip: [{ spell: 'Faerie Fire', icon: 'spell_nature_faeriefire', color: '#FF7C0A' }, { spell: 'Faerie Fire (Feral)', icon: 'spell_nature_faeriefire_', color: '#FF7C0A' }, { spell: 'Corrosive Spit', icon: 'spell_nature_corrosivebreath', color: '#AAD372' }, { spell: 'Expose Armor', icon: 'ability_warrior_riposte', color: '#FFF468' }, { spell: 'Sunder Armor', icon: 'ability_warrior_sunder', color: '#C69B6D' }] },
                    { id: "MDTD", name: 'Magic Damage Taken', specs: ['Demonology', 'Destruction', 'Affliction', 'Assassination', 'Survival', 'Marksmanship', 'Marksman', 'Beast Mastery', 'Beastmastery', 'Balance', 'Unholy_Tank', 'Unholy_DPS', ], tooltip: [{ spell: 'Ebon Plaguebringer', icon: 'ability_creature_cursed_03', color: '#A330C9' }, { spell: 'Earth and Moon', icon: 'ability_druid_earthandsky', color: '#FF7C0A' }, { spell: 'Fire Breath', icon: 'spell_fire_burnout', color: '#AAD372' }, { spell: 'Master Poisoner', icon: 'ability_creature_poison_06', color: '#FFF468' }, { spell: 'Curse of the Elements', icon: 'spell_shadow_chilltouch', color: '#8788EE' }] },
                    { id: "RMRASD", name: 'Reduced Melee and Ranged Attack Speed', specs: ['Arms', 'Fury', 'Protection', 'Enhancement', 'Restoration1', 'Elemental', 'Protection1', 'Survival', 'Marksmanship', 'Marksman', 'Beast Mastery', 'Beastmastery', 'Guardian', 'Feral', 'Unholy_Tank', 'Unholy_DPS', 'Blood_Tank', 'Blood_DPS', 'Frost_Tank', 'Frost_DPS', ], tooltip: [{ spell: 'Frost Fever', icon: 'spell_deathknight_frostfever', color: '#C41E3A' }, { spell: 'Infected Wounds', icon: 'ability_druid_infectedwound', color: '#FF7C0A' }, { spell: 'Dust Cloud', icon: 'ability_hibernation', color: '#AAD372' }, { spell: 'Judgements of the Just', icon: 'ability_paladin_judgementsofthejust', color: '#F48CBA' }, { spell: 'Earth Shock', icon: 'spell_nature_earthshock', color: '#0070DD' }, { spell: 'Thunderclap', icon: 'ability_thunderclap', color: '#C69B6D' }] },
                    { id: "RHED", name: 'Reduced Healing Effectiveness', specs: ['Fury', 'Demonology', 'Destruction', 'Affliction', 'Shadow', 'Frost', 'Survival', 'Marksmanship', 'Marksman', 'Beast Mastery', 'Beastmastery', ], tooltip: [{ spell: 'Monstrous Bite', icon: 'inv_misc_bone_09', color: '#AAD372' }, { spell: 'Widow Venom', icon: 'ability_hunter_potentvenom', color: '#AAD372' }, { spell: 'Permafrost', icon: 'spell_frost_wisp', color: '#3FC7EB' }, { spell: 'Improved Mind Blast', icon: 'spell_shadow_unholyfrenzy', color: '#FFFFFF' }, { spell: 'Legion Strike', icon: 'inv_axe_09', color: '#8788EE' }, { spell: 'Furious Attacks', icon: 'ability_warrior_furiousresolve', color: '#C69B6D' }] },
                    { id: "SCD", name: '+5% Spell Crit', specs: ['Destruction', 'Demonology', 'Affliction', 'Fire', ], tooltip: [{ spell: 'Critical Mass', icon: 'spell_nature_wispheal', color: '#3FC7EB' }, { spell: 'Shadow and Flame', icon: 'spell_shadow_shadowandflame', color: '#8788EE' }] },
                    { id: "DIBDTD", name: 'Damage Increased Bleed Damage Taken', specs: ['Arms', 'Subtlety', 'Survival', 'Marksmanship', 'Marksman', 'Beast Mastery', 'Beastmastery', 'Restoration', 'Guardian', 'Feral', 'Balance', ], tooltip: [{ spell: 'Mangle', icon: 'ability_druid_mangle2', color: '#FF7C0A' }, { spell: 'Tendon Rip', icon: 'ability_criticalstrike', color: '#AAD372' }, { spell: 'Hemorrhage', icon: 'spell_shadow_lifedrain', color: '#FFF468' }, { spell: 'Blood Frenzy', icon: 'ability_warrior_bloodfrenzy', color: '#C69B6D' }] },
                    { id: "PDTD", name: 'Physical Damage Taken', specs: ['Arms', 'Combat', 'Survival', 'Marksmanship', 'Marksman', 'Beast Mastery', 'Beastmastery', 'Frost_Tank', 'Frost_DPS', ], tooltip: [{ spell: 'Brittle Bones', icon: 'ability_deathknight_brittlebones', color: '#C41E3A' }, { spell: 'Ravage', icon: 'ability_druid_primaltenacity', color: '#AAD372' }, { spell: 'Savage Combat', icon: 'ability_creature_disease_03', color: '#FFF468' }, { spell: 'Blood Frenzy', icon: 'ability_warrior_bloodfrenzy', color: '#C69B6D' }] },
                ]
            }, ],


            [{
                    caption: 'Damage Reduction',
                    spellList: [
                        { id: "DMDR", name: 'Damage Mitigation', specs: ['Discipline', ], tooltip: [{ spell: 'Power Word: Barrier', icon: 'spell_holy_powerwordbarrier', color: '#FFFFFF' }] },
                    ]
                },
                {
                    caption: 'Health Regeneration',
                    spellList: [
                        { id: "HRHR", name: 'Health Regeneration', specs: ['Shadow', ], tooltip: [{ spell: 'Vampiric Embrace', icon: 'spell_shadow_unsummonbuilding', color: '#FFFFFF' }] },
                    ]
                },


                {
                    caption: 'External Cooldowns',
                    spellList: [
                        { id: "CRED", name: 'Combat Resurrection', specs: ['Affliction', 'Demonology', 'Destruction', 'Guardian', 'Balance', 'Feral', 'Restoration', 'Blood_Tank', 'Blood_DPS', 'Unholy_Tank', 'Unholy_DPS', 'Frost_Tank', "Frost_DPS", ], tooltip: [{ spell: 'Soulstone', icon: 'inv_misc_orb_04', color: '#8788EE' }, { spell: 'Rebirth', icon: 'spell_nature_reincarnation', color: '#FF7C0A' }, { spell: 'Raise Ally', icon: 'spell_shadow_deadofnight', color: '#C41E3A' }] },
                        { id: "RTEC", name: 'Reduced Threat', specs: ['Holy1', 'Protection1', 'Retribution', ], tooltip: [{ spell: 'Hand of Salvation', icon: 'spell_holy_sealofsalvation', color: '#F48CBA' }] },
                        { id: "DMEC", name: 'Damage Mitigation', specs: ['Restoration1', 'Holy1', 'Protection1', 'Retribution', ], tooltip: [{ spell: 'Hand of Sacrifice', icon: 'spell_holy_sealofsacrifice', color: '#F48CBA' }, { spell: 'Spirit Link Totem', icon: 'spell_shaman_spiritlink', color: '#0070DD' }] },
                        { id: "SHEC", name: 'Spell Haste', specs: ['Discipline', ], tooltip: [{ spell: 'Power Infusion', icon: 'spell_holy_powerinfusion', color: '#FFFFFF' }] },
                        { id: "MEC", name: 'Mana', specs: ['Restoration1', 'Discipline', 'Holy', 'Shadow', 'Smite', 'Restoration', 'Guardian', 'Feral', 'Balance', ], tooltip: [{ spell: 'Innervate', icon: 'spell_nature_lightning', color: '#FF7C0A' }, { spell: 'Hymn of Hope', icon: 'spell_holy_symbolofhope', color: '#FFFFFF' }, { spell: 'Mana Tide Totem', icon: 'spell_frost_summonwaterelemental', color: '#0070DD' }] },
                        { id: "HEC", name: 'Haste', specs: ['Unholy_Tank', 'Unholy_DPS', ], tooltip: [{ spell: 'Unholy Frenzy', icon: 'spell_shadow_unholyfrenzy', color: '#C41E3A' }] },
                        { id: "TEC", name: 'Threat', specs: ['Assassination', 'Combat', 'Subtlety', 'Marksmanship', 'Marksman', 'Beast Mastery', 'Beastmastery', 'Survival'], tooltip: [{ spell: 'Tricks of the Trade', icon: 'ability_rogue_tricksofthetrade', color: '#FFF468' }, { spell: 'Misdirection', icon: 'ability_hunter_misdirection', color: '#AAD372' }] },
                    ]
                },
            ],
        ],

        wotlkBuffArray: [

            [{
                caption: 'General',
                spellList: [
                    { id: "GOTWG", name: 'Gift of the Wild', specs: ['Balance', 'Feral', 'Restoration', 'Guardian'], tooltip: [{ spell: 'Gift of the Wild', icon: 'spell_nature_giftofthewild', color: '#FF7C0A' }] },
                    { id: "BOKG", name: 'Blessing of Kings', specs: ['Holy1', 'Protection1', 'Retribution'], tooltip: [{ spell: 'Greater Blessing of Kings', icon: 'spell_magic_greaterblessingofkings', color: '#F48CBA' }] },
                    { id: "SAGG", name: 'Strength and Agility', specs: ['Enhancement', 'Restoration1', 'Elemental', 'Frost_Tank', 'Blood_Tank', 'Unholy_Tank', 'Frost_DPS', 'Blood_DPS', 'Unholy_DPS'], tooltip: [{ spell: 'Horn of Winter', icon: 'inv_misc_horn_02', color: '#C41E3A' }, { spell: 'Strength of Earth Totem', icon: 'spell_nature_earthbindtotem', color: '#0070DD' }] },
                    { id: "APSG", name: 'Attack Power: Static', specs: ['Holy1', 'Protection1', 'Retribution', 'Protection', 'Arms', 'Fury'], tooltip: [{ spell: 'Greater Blessing of Might', icon: 'spell_holy_greaterblessingofkings', color: '#F48CBA' }, { spell: 'Battleshout', icon: 'ability_warrior_battleshout', color: '#C69B6D' }] },
                    { id: "AP10G", name: '10% Attack Power', specs: ['Blood_Tank', 'Blood_DPS', 'Marksmanship', 'Marksman', 'Enhancement'], tooltip: [{ spell: 'Unleashed Rage', icon: 'spell_nature_unleashedrage', color: '#0070DD' }, { spell: "Abomination's Might", icon: 'ability_warrior_intensifyrage', color: '#C41E3A' }, { spell: 'Trueshot Aura', icon: 'ability_trueshot', color: '#AAD372' }] },
                    { id: "PC5G", name: '5% Physical Crit', specs: ['Guardian', 'Feral', 'Fury'], tooltip: [{ spell: 'Leader of the Pack', icon: 'spell_nature_unyeildingstamina', color: '#FF7C0A' }, { spell: 'Rampage', icon: 'ability_warrior_rampage', color: '#C69B6D' }] },
                    { id: "MH20G", name: '20% Melee Haste', specs: ['Blood_Tank', 'Blood_DPS', 'Frost_Tank', 'Frost_DPS', 'Enhancement'], tooltip: [{ spell: 'Improved Windfury Totem', icon: 'spell_nature_windfury', color: '#0070DD' }, { spell: 'Improved Icy Talons', icon: 'spell_deathknight_icytalons', color: '#C41E3A' }] },
                    { id: "D3G", name: '3% Damage', specs: ['Arcane', 'Beast Mastery', 'Beastmastery', 'Retribution'], tooltip: [{ spell: 'Sanctified Retribution', icon: 'spell_holy_mindvision', color: '#F48CBA' }, { spell: 'Ferocious Inspiration', icon: 'ability_hunter_ferociousinspiration', color: '#AAD372' }, { spell: 'Arcane Empowerment', icon: 'spell_nature_starfall', color: '#3FC7EB' }] },
                    { id: "H3G", name: '3% Haste', specs: ['Retribution', 'Balance'], tooltip: [{ spell: 'Improved Moonkin Form', icon: 'ability_druid_improvedmoonkinform', color: '#FF7C0A' }, { spell: 'Swift Retribution', icon: 'ability_paladin_swiftretribution', color: '#F48CBA' }] },
                    { id: "HBG", name: 'Heroism / Bloodlust', specs: ['Enhancement', 'Restoration1', 'Elemental'], tooltip: [{ spell: 'Heroism/Bloodlust', icon: 'spell_nature_bloodlust', color: '#0070DD' }] },
                    { id: "IG", name: 'Intellect', specs: ['Affliction', 'Arcane', 'Fire', 'Frost'], tooltip: [{ spell: 'Arcane Intellect', icon: 'spell_holy_magicalsentry', color: '#3FC7EB' }, { spell: 'Fel Intelligence', icon: 'spell_shadow_brainwash', color: '#8788EE' }] },
                    { id: "SPG", name: 'Spell Power', specs: ['Demonology', 'Enhancement', 'Restoration1', 'Elemental'], tooltip: [{ spell: 'Totem of Wrath', icon: 'spell_fire_totemofwrath', color: '#0070DD' }, { spell: 'Flametongue Totem', icon: 'spell_nature_guardianward', color: '#0070DD' }, { spell: 'Demonic pact', icon: 'spell_shadow_demonicpact', color: '#8788EE' }] },
                    { id: "SC5G", name: '5% Spell Crit', specs: ['Elemental', 'Balance'], tooltip: [{ spell: 'Moonkin Aura', icon: 'spell_nature_moonglow', color: '#FF7C0A' }, { spell: 'Elemental Oath', icon: 'spell_shaman_elementaloath', color: '#0070DD' }] },
                    { id: "SH5G", name: '5% Spell Haste', specs: ['Enhancement', 'Restoration1', 'Elemental'], tooltip: [{ spell: '', icon: 'spell_nature_slowingtotem', color: '#0070DD' }] },
                    { id: "SPIG", name: 'Spirit', specs: ['Discipline', 'Holy', 'Shadow', 'Smite', 'Affliction'], tooltip: [{ spell: 'Divine Spirit', icon: 'spell_holy_divinespirit', color: '#FFFFFF' }, { spell: 'Fel Intelligence', icon: 'spell_shadow_brainwash', color: '#8788EE' }] },
                    { id: "STAG", name: 'Stamina', specs: ['Discipline', 'Holy', 'Shadow', 'Smite'], tooltip: [{ spell: 'Power Word: Fortitude', icon: 'spell_holy_wordfortitude', color: '#FFFFFF' }] },
                    { id: "HG", name: 'Health', specs: ['Arms', 'Fury', 'Protection', 'Destruction'], tooltip: [{ spell: 'Commanding Shout', icon: 'ability_warrior_rallyingcry', color: '#C69B6D' }, { spell: 'Blood Pact', icon: 'spell_shadow_bloodboil', color: '#8788EE' }] },
                ]
            }, ],

            [{
                    caption: 'Single-Player Buffs',
                    spellList: [
                        { id: "FMSPB", name: 'Focus Magic', specs: ['Arcane', 'Fire'], tooltip: [{ spell: 'Focus Magic', icon: 'spell_arcane_studentofmagic', color: '#3FC7EB' }] },
                        { id: "UFSPB", name: 'Unholy Frenzy', specs: ['Blood_Tank', 'Blood_DPS', 'Blood'], tooltip: [{ spell: 'Unholy Frenzy', icon: 'spell_deathknight_bladedarmor', color: '#C41E3A' }] },
                        { id: "TOTTSPB", name: 'Tricks of the Trade', specs: ['Assassination', 'Combat', 'Subtlety'], tooltip: [{ spell: 'Tricks of the Trade', icon: 'ability_rogue_tricksofthetrade', color: '#FFF468' }] },
                        { id: "PISPB", name: 'Power Infusion', specs: ['Discipline', 'Smite'], tooltip: [{ spell: 'Power Infusion', icon: 'spell_holy_powerinfusion', color: '#FFFFFF' }] },
                    ]
                },
                {
                    caption: 'Health Return',
                    spellList: [
                        { id: "ILOTPHR", name: 'Imp. Leader of the Pack', specs: ['Guardian', 'Feral'], tooltip: [{ spell: 'Improved Leader of the Pack', icon: 'spell_nature_unyeildingstamina', color: '#FF7C0A' }] },
                        { id: "JOLHR", name: 'Judgement of Light', specs: ['Holy1', 'Protection1', 'Retribution'], tooltip: [{ spell: 'Judgement of Light', icon: 'spell_holy_righteousfury', color: '#F48CBA' }] },
                        { id: "VEHR", name: 'Vampiric Embrace', specs: ['Shadow'], tooltip: [{ spell: 'Vampiric Embrace', icon: 'spell_shadow_unsummonbuilding', color: '#FFFFFF' }] },
                    ]
                },
            ],


            [{
                    caption: 'Offensive Debuffs',
                    spellList: [
                        { id: "BD39OD", name: '30% Bleed Damage', specs: ['Guardian', 'Feral', 'Beast Mastery', 'Beastmastery', 'Arms'], tooltip: [{ spell: 'Mangle', icon: 'ability_druid_mangle2', color: '#FF7C0A' }, { spell: 'Trauma', icon: 'ability_warrior_bloodnova', color: '#C69B6D' }, { spell: 'Stampede', icon: 'spell_shaman_astralshift', color: '#AAD372' }] },
                        { id: "PD4OD", name: '4% Physical Damage', specs: ['Combat', 'Arms'], tooltip: [{ spell: 'Blood Frenzy', icon: 'ability_warrior_bloodfrenzy', color: '#C69B6D' }, { spell: 'Savage Combat', icon: 'ability_creature_disease_03', color: '#FFF468' }] },
                        { id: "CRIT3OD", name: '3% Crit', specs: ['Retribution', 'Elemental', 'Assassination'], tooltip: [{ spell: 'Totem of Wrath', icon: 'spell_fire_totemofwrath', color: '#0070DD' }, { spell: 'Heart of the Crusader', icon: 'spell_holy_holysmite', color: '#F48CBA' }, { spell: 'Master Poisoner', icon: 'ability_creature_poison_06', color: '#FFF468' }] },
                        { id: "SCB5OD", name: '5% Spell Crit Debuff', specs: ['Affliction', 'Demonology', 'Frost', 'Fire'], tooltip: [{ spell: 'Improved Scorch', icon: 'spell_fire_soulburn', color: '#3FC7EB' }, { spell: "Winter's Chill", icon: 'spell_frost_chillingblast', color: '#3FC7EB' }, { spell: 'Improved Shadow Bolt', icon: 'spell_shadow_shadowbolt', color: '#8788EE' }] },
                        { id: "SH3OD", name: '3% Spell Hit', specs: ['Balance', 'Shadow'], tooltip: [{ spell: 'Improved Faerie Fire', icon: 'spell_nature_faeriefire', color: '#FF7C0A' }, { spell: 'Misery', icon: 'spell_shadow_misery', color: '#FFFFFF' }] },
                        { id: "SP13OD", name: '13% Spell Damage', specs: ['Balance', 'Affliction', 'Demonology', 'Destruction', 'Unholy_Tank', 'Unholy_DPS'], tooltip: [{ spell: 'Curse of Elements', icon: 'spell_shadow_chilltouch', color: '#8788EE' }, { spell: 'Earth of Moon', icon: 'ability_druid_earthandsky', color: '#FF7C0A' }, { spell: 'Ebon Plaguebringer', icon: 'ability_creature_cursed_03', color: '#C41E3A' }] },
                    ]
                },


                {
                    caption: 'Reduction Debuffs',
                    spellList: [
                        { id: "ARMOR20RD", name: '20% Armor', specs: ['Arms', 'Fury', 'Protection', 'Assassination', 'Combat', 'Subtlety', 'Beast Mastery', 'Beastmastery'], tooltip: [{ spell: 'Sunder Armor', icon: 'ability_warrior_sunder', color: '#C69B6D' }, { spell: 'Expose Armor', icon: 'ability_warrior_riposte', color: '#FFF468' }, { spell: 'Acid Spit', icon: 'spell_nature_acid_01', color: '#AAD372' }] },
                        { id: "ARMOR5RD", name: '5% Armor', specs: ['Guardian', 'Feral', 'Beast Mastery', 'Beastmastery', 'Marksmanship', 'Marksman', 'Survival', 'Affliction', 'Demonology', 'Destruction'], tooltip: [{ spell: 'Curse of Weakness', icon: 'spell_shadow_curseofmannoroth', color: '#8788EE' }, { spell: 'Fearie Fire', icon: 'spell_nature_faeriefire', color: '#FF7C0A' }, { spell: 'Sting', icon: 'spell_nature_slowpoison', color: '#AAD372' }] },
                        { id: "AS20RD", name: '20% Attack Speed', specs: ['Arms', 'Fury', 'Unholy_Tank', 'Unholy_DPS', 'Frost_Tank', 'Frost_DPS', 'Blood_Tank', 'Blood_DPS', 'Protection', 'Protection1', 'Guardian', 'Feral'], tooltip: [{ spell: 'Improved Thunder Clap', icon: 'ability_thunderclap', color: '#C69B6D' }, { spell: 'Judgements of the Just', icon: 'ability_paladin_judgementsofthejust', color: 'F48CBA' }, { spell: 'Infected Wounds', icon: 'ability_druid_infectedwound', color: '#FF7C0A' }, { spell: 'Improved Frost Fever', icon: 'spell_deathknight_frostfever', color: '#C41E3A' }] },
                        { id: "APRD", name: 'Attack Power', specs: ['Affliction', 'Demonology', 'Destruction', 'Arms', 'Fury', 'Protection', 'Guardian', 'Feral', 'Retribution'], tooltip: [{ spell: 'Demoralizing Shout', icon: 'ability_warrior_warcry', color: '#C69B6D' }, { spell: 'Demoralizing Roar', icon: 'classic_ability_druid_demoralizingroar', color: '#FF7C0A' }, { spell: 'Curse of Weakness', icon: 'spell_shadow_curseofmannoroth', color: '#8788EE' }, { spell: 'Vindication', icon: 'spell_holy_vindication', color: '#F48CBA' }] },
                        { id: "PHRD", name: 'Physical Hit', specs: ['Beast Mastery', 'Beastmastery', 'Marksmanship', 'Marksman', 'Survival', 'Balance'], tooltip: [{ spell: 'Insect Swarm', icon: 'spell_nature_insectswarm', color: '#FF7C0A' }, { spell: 'Scorpid Sting', icon: 'ability_hunter_criticalshot', color: '#AAD372' }] },
                    ]
                },
            ],

            [{
                    caption: 'Damage Reduction',
                    spellList: [
                        { id: "DGDR", name: 'Divine Guardian', specs: ['Protection1'], tooltip: [{ spell: 'Divine Guardian', icon: 'spell_holy_powerwordbarrier', color: '#F48CBA' }] },
                        { id: "BOSDR", name: 'Blessing of Sanctuary', specs: ['Protection1'], tooltip: [{ spell: 'Greater Blessing of Sanctuary', icon: 'spell_holy_greaterblessingofsanctuary', color: '#F48CBA' }] },
                    ]
                },


                {
                    caption: 'Ressource Return',
                    spellList: [
                        { id: "JOWRR", name: 'Judgment of Wisdom', specs: ['Holy1', 'Protection1', 'Retribution'], tooltip: [{ spell: 'Greater Blessing of Wisdom', icon: 'ability_paladin_judgementblue', color: '#F48CBA' }] },
                        { id: "MREPRR", name: 'Mana Replenishment', specs: ['Retribution', 'Shadow', 'Survival', 'Frost', 'Destruction'], tooltip: [{ spell: 'Vampiric Touch', icon: 'spell_holy_stoicism', color: '#FFFFFF' }, { spell: 'Judgement of the Wise', icon: 'ability_paladin_judgementofthewise', color: '#F48CBA' }, { spell: 'Hunting Party', icon: 'ability_hunter_huntingparty', color: '#AAD372' }, { spell: 'Improved Soul Leech', icon: 'ability_warlock_improvedsoulleech', color: '#8788EE' }, { spell: 'Enduring Winte', icon: 'spell_frost_summonwaterelemental_2', color: '#3FC7EB' }] },
                        { id: "MRRESTR", name: 'Mana Restoration', specs: ['Enhancement', 'Restoration1', 'Elemental', 'Holy1', 'Protection1', 'Retribution'], tooltip: [{ spell: 'Greater Blessing of Wisdom', icon: 'spell_holy_greaterblessingofwisdom', color: '#F48CBA' }, { spell: 'Mana Spring Totem', icon: 'spell_nature_manaregentotem', color: '#0070DD' }] },
                        { id: "MTTRR", name: 'Mana Tide Totem', specs: ['Restoration1'], tooltip: [{ spell: 'Mana Tide Totem', icon: 'spell_frost_summonwaterelemental', color: '#0070DD' }] },
                        { id: "HOHRR", name: 'Hymn of Hope', specs: ['Discipline', 'Holy', 'Shadow', 'Smite'], tooltip: [{ spell: 'Hymn of Hope', icon: 'spell_holy_symbolofhope', color: '#FFFFFF' }] },
                        { id: "RAPRR", name: 'Rapture', specs: ['Discipline', 'Smite'], tooltip: [{ spell: 'Rapture', icon: 'spell_holy_rapture', color: '#FFFFFF' }] },
                        { id: "REVRR", name: 'Revitalize', specs: ['Restoration'], tooltip: [{ spell: 'Revitalize', icon: 'ability_druid_replenish', color: '#FF7C0A' }] },
                        { id: "IRR", name: 'Innervate', specs: ['Balance', 'Feral', 'Restoration', 'Guardian'], tooltip: [{ spell: 'Innervate', icon: 'spell_nature_lightning', color: '#FF7C0A' }] },
                    ]
                },
            ],
        ],


        tbcBuffArray: [



            [{
                caption: 'Raid Wide Buffs',
                spellList: [
                    { id: "SSRWD", name: 'Static Stats', specs: ['Guardian', 'Feral', 'Balance', 'Restoration', 'Dreamstate'], tooltip: [{ spell: 'Mark of the Wild', icon: 'spell_nature_regeneration', color: '#FF7C0A' }] },
                    { id: "SRWD", name: 'Stamina', specs: ['Holy', 'Discipline', 'Shadow'], tooltip: [{ spell: 'Power Word: Fortitude', icon: 'spell_holy_wordfortitude', color: '#FFFFFF' }] },
                    { id: "SSPRWD", name: 'Spirit / Spell Damage', specs: ['Discipline'], tooltip: [{ spell: 'Divine Spirit', icon: 'spell_holy_divinespirit', color: '#FFFFFF' }, { spell: 'Improved Divine Spirit', icon: 'spell_holy_divinespirit', color: '#FFFFFF' }] },
                    { id: "INTRWD", name: 'Intellect', specs: ['Frost', 'Fire', 'Arcane'], tooltip: [{ spell: 'Arcane Intellect', icon: 'spell_holy_magicalsentry', color: '#3FC7EB' }] },
                    { id: "STATSRWD", name: 'Stats %', specs: ['Holy1', 'Retribution', 'Protection1'], tooltip: [{ spell: 'Greater Blessing of Kings', icon: 'spell_magic_greaterblessingofkings', color: '#F48CBA' }] },
                    { id: "APRWD", name: 'Attack Power', specs: ['Holy1', 'Retribution', 'Protection1'], tooltip: [{ spell: 'Greater Blessing of Might', icon: 'spell_holy_greaterblessingofkings', color: '#F48CBA' }] },
                    { id: "RTRWD", name: 'Reduced Threat', specs: ['Holy1', 'Retribution', 'Protection1'], tooltip: [{ spell: 'Greater Blessing of Salvation', icon: 'spell_holy_greaterblessingofsalvation', color: '#F48CBA' }] },
                    { id: "MRRWD", name: 'Mana Restoration', specs: ['Holy1', 'Retribution', 'Protection1'], tooltip: [{ spell: 'Greater Blessing of Wisdom', icon: 'spell_holy_greaterblessingofwisdom', color: '#F48CBA' }] },
                ]
            }, ],
            [{
                caption: 'Offensive Debuffs',
                spellList: [
                    { id: "PAPSOD", name: 'Player Attack Power (Survival)', specs: ['Survival'], tooltip: [{ spell: 'Expose Weakness', icon: 'ability_rogue_findweakness', color: '#AAD372' }] },
                    { id: "PAPMOD", name: 'Player Attack Power (Marksmanship)', specs: ['Marksmanship'], tooltip: [{ spell: "Hunter's Mark", icon: 'ability_hunter_snipershot', color: '#AAD372' }, { spell: "Improved Hunter's Mark", icon: 'ability_hunter_snipershot', color: '#AAD372' }] },
                    { id: "PDTOD", name: 'Physical Damage Taken', specs: ['Arms'], tooltip: [{ spell: 'Blood Frenzy', icon: 'ability_warrior_bloodfrenzy', color: '#C69B6D' }] },
                    { id: "SDTLOCKOD", name: 'Spell Damage Taken (Warlock)', specs: ['Destruction', 'Demonology'], tooltip: [{ spell: 'Curse of Elements', icon: 'spell_shadow_chilltouch', color: '#8788EE' }] },
                    { id: "SDTAFFOD", name: 'Spell Damage Taken (Affliction)', specs: ['Affliction'], tooltip: [{ spell: 'Curse of Elements', icon: 'spell_shadow_chilltouch', color: '#8788EE' }, { spell: 'Malediction', icon: 'spell_shadow_curseofachimonde', color: '#8788EE' }] },
                    { id: "SDTWOD", name: 'Shadow Damage Taken (Warlock)', specs: ['Demonology', 'Destruction', 'Affliction'], tooltip: [{ spell: 'Improved Shadow Bolt', icon: 'spell_shadow_shadowbolt', color: '#8788EE' }] },
                    { id: "SDTSOD", name: 'Shadow Damage Taken (Shadow)', specs: ['Shadow'], tooltip: [{ spell: 'Shadow Weaving', icon: 'spell_shadow_blackplague', color: '#FFFFFF' }, { spell: 'Misery', icon: 'spell_shadow_misery', color: '#FFFFFF' }] },
                    { id: "FDTOD", name: 'Fire Damage Taken', specs: ['Fire'], tooltip: [{ spell: 'Improved Scorch', icon: 'spell_fire_soulburn', color: '#3FC7EB' }] },
                    { id: "PCOD", name: 'Player Crit', specs: ['Retribution'], tooltip: [{ spell: 'Improved Seal of the Crusader', icon: 'spell_holy_holysmite', color: '#F48CBA' }] },
                    { id: "PFCOD", name: 'Player Frost Crit', specs: ['Frost'], tooltip: [{ spell: "Winter's Chill", icon: 'spell_frost_chillingblast', color: '#3FC7EB' }] },
                    { id: "PPHOD", name: 'Player Physical Hit', specs: ['Balance', 'Dreamstate'], tooltip: [{ spell: 'Improved Faerie Fire', icon: 'spell_nature_faeriefire', color: '#FF7C0A' }] },
                    { id: "BDOD", name: 'Bleed Damage', specs: ['Guardian', 'Feral'], tooltip: [{ spell: 'Mangle', icon: 'ability_druid_mangle2', color: '#FF7C0A' }] },
                ]
            }, ],


            [{
                    caption: 'Reduction Debuffs',
                    spellList: [
                        { id: "RAPRD", name: 'Reduced Attack Power', specs: ['Guardian', 'Feral', 'Arms', 'Fury', 'Protection'], tooltip: [{ spell: 'Demoralizing Shout', icon: 'ability_warrior_warcry', color: '#C69B6D' }, { spell: 'Demoralizing Roar', icon: 'classic_ability_druid_demoralizingroar', color: '#FF7C0A' }] },
                        { id: "RASRD", name: 'Reduced Attack Speed', specs: ['Arms', 'Fury', 'Protection'], tooltip: [{ spell: 'Thunder Clap', icon: 'spell_nature_thunderclap', color: '#C69B6D' }] },
                        { id: "RAWARRRD", name: 'Reduced Armor (Warrior)', specs: ['Arms', 'Fury', 'Protection'], tooltip: [{ spell: 'Sunder Armor', icon: 'ability_warrior_sunder', color: '#C69B6D' }] },
                        { id: "RAWD", name: 'Reduced Armor (Warlock)', specs: ['Demonology', 'Destruction', 'Affliction'], tooltip: [{ spell: 'Curse of Recklessness', icon: 'spell_shadow_unholystrength', color: '#8788EE' }] },
                        { id: "RADRD", name: 'Reduced Armor (Druid)', specs: ['Guardian', 'Feral', 'Balance', 'Dreamstate', 'Restoration'], tooltip: [{ spell: 'Faerie Fire', icon: 'spell_nature_faeriefire', color: '#FF7C0A' }] },
                        { id: "RARRD", name: 'Reduced Armor (Rogue)', specs: ['Combat', 'Subtlety'], tooltip: [{ spell: 'Expose Armor', icon: 'ability_warrior_riposte', color: '#FFF468' }] },
                        { id: "RAASSRD", name: 'Reduced Armor (Assassination)', specs: ['Assassination'], tooltip: [{ spell: 'Expose Armor', icon: 'ability_warrior_riposte', color: '#FFF468' }, { spell: 'Improved Expose Armor', icon: 'ability_warrior_riposte', color: '#FFF468' }] },
                        { id: "RPHRD", name: 'Reduced Physical Hit', specs: ['Beast Mastery', 'Survival', 'Marksmanship'], tooltip: [{ spell: 'Scorpid Sting', icon: 'ability_hunter_criticalshot', color: '#AAD372' }] },
                        { id: "RHRD", name: 'Reduced Hit', specs: ['Balance', 'Dreamstate'], tooltip: [{ spell: 'Insect Swarm', icon: 'spell_nature_insectswarm', color: '#FF7C0A' }] },
                        { id: "RPDRD", name: 'Reduced Physical Damage', specs: ['Affliction'], tooltip: [{ spell: 'Shadow Embrace', icon: 'spell_shadow_shadowembrace', color: '#FFFFFF' }] },
                    ]
                },


                {
                    caption: 'Utility Debuffs',
                    spellList: [
                        { id: "JUD", name: 'Judgement', specs: ['Holy1', 'Retribution', 'Protection1'], tooltip: [{ spell: 'Seal of Light', icon: 'spell_holy_healingaura', color: '#F48CBA' }, { spell: 'Seal of Wisdom', icon: 'spell_holy_righteousnessaura', color: '#F48CBA' }] },
                    ]
                },
            ],

        ],

    },
    mutations: {
        initialiseStore(state) {

            state.premiumFeatures = {
                recurring: "",
                recurringUnit: "days",
                scheduleDate: "",
                interval2Ordinal: "",
                interval2Weekday: "",
            }

            if (localStorage.getItem("chosenDiscord") != null) {
                state.chosenDiscord = JSON.parse(localStorage.getItem("chosenDiscord"));
            }

            if (localStorage.getItem("discordUser") != null) {
                state.discordUser = JSON.parse(localStorage.getItem("discordUser"));
            }

            if (state.chosenDiscord !== undefined && state.chosenDiscord != null) {
                if (localStorage.getItem("formAnswers-" + state.chosenDiscord.id) != null) {
                    state.formAnswers = JSON.parse(localStorage.getItem("formAnswers-" + state.chosenDiscord.id));
                    if (state.formAnswers.pollAnswers === undefined || state.formAnswers.softresFaction === undefined) {
                        state.formAnswers.pollQuestion = "",
                            state.formAnswers.pollAnswers = [],
                            state.formAnswers.softresFaction = {},
                            state.formAnswers.softresInstance = {},
                            state.formAnswers.softresMaxres = "1",
                            state.formAnswers.softresBehaviour = {},

                            localStorage.setItem(
                                "formAnswers-" + state.chosenDiscord.id,
                                JSON.stringify(state.formAnswers)
                            );
                    }
                } else {
                    state.formAnswers = {
                        template: {},
                        channel: {},
                        title: "",
                        description: "",
                        date: "",
                        time: "",
                        imageUrl: "",
                        color: "#D1D339",
                        mentions: [],
                        optOut: "",
                        announcementChannel: "",
                        announcementTime: "",
                        announcementMessage: "",
                        allowed_roles: [],
                        banned_roles: [],
                        temporaryRoles: "",
                        response: "",
                        pollQuestion: "",
                        pollAnswers: [],
                        softresFaction: {},
                        softresInstance: {},
                        softresMaxres: "1",
                        softresBehaviour: {},
                    }
                }
            }
        },

        setChosenDiscord(state, chosenDiscord) {
            localStorage.setItem("chosenDiscord", JSON.stringify(chosenDiscord));
            state.chosenDiscord = chosenDiscord;
            this.commit("initialiseStore", state);
        },

        setDiscordUser(state, discordUser) {
            localStorage.setItem("discordUser", JSON.stringify(discordUser));
            state.discordUser = discordUser;
        },

        setFormAnswers(state, formAnswers) {
            localStorage.setItem("formAnswers-" + state.chosenDiscord.id, JSON.stringify(formAnswers));
            state.formAnswers = formAnswers;
        },

        setAccessToken: (state, accessToken) => (state.accessToken = accessToken),
        setDiscordGuilds: (state, discordGuilds) => (state.discordGuilds = discordGuilds),
        setAuthenticated: (state, authenticated) => (state.authenticated = authenticated),
        setDiscordChannels: (state, discordChannels) => (state.discordChannels = discordChannels),
        setChosenTemplate: (state, chosenTemplate) => (state.chosenTemplate = chosenTemplate),
    },
    actions: {
        async fetchDiscordInfoNew({ commit, state }) {
            try {
                if (state.chosenDiscord === undefined) {
                    let append = "";
                    let regex = /[\/]/g;
                    let accessToken = localStorage.getItem('accessToken');
                    if (accessToken != undefined) {
                        append = "/" + accessToken.slice(1, -1).replace(regex, '');;
                    }

                    const res = await axios.get(state.url + "/api/auth" + append, { withCredentials: true }).catch(error => {
                        if (error.response.status === 401) {
                            localStorage.removeItem("accessToken");
                            window.location.href = state.url + "/api/auth/discord";
                            return;
                        }
                    });

                    const guild = [];
                    const servers = res.data.guilds;

                    for (let index = 0; index < servers.length; index++) {
                        let discordObject = { id: "", name: "", icon: "", premium: "", hasPerms: false };
                        discordObject.id = servers[index].id;
                        discordObject.name = servers[index].name;
                        discordObject.premium = servers[index].premium;

                        if (servers[index].icon === undefined) {
                            discordObject.icon = "https://cdn.discordapp.com/icons/817542418420596766/f8ec64ca464b50d9f4a80e1256cabd7f.png";
                        } else {
                            discordObject.icon = servers[index].icon;
                        }

                        guild.push(discordObject);
                        guild.sort((a, b) => b.premium - a.premium);
                    }

                    let userObject = { id: "", name: "", icon: "", premium: "" };
                    userObject.id = res.data.discordId;
                    userObject.tag = res.data.discordTag;
                    userObject.avatar = "https://cdn.discordapp.com/avatars/" + res.data.discordId + "/" + res.data.avatar;
                    localStorage.removeItem("accessToken");
                    localStorage.setItem("accessToken", JSON.stringify(res.data.accessToken));
                    commit("setDiscordGuilds", guild);
                    commit("setDiscordUser", userObject);
                    commit("setAuthenticated", true);
                }
            } catch (error) {
                console.log(error)
            }
        },

        async fetchDiscordInfo({ commit, state }) {
            if (state.chosenDiscord === undefined) {
                const res = await axios.get(state.url + "/api/auth", { withCredentials: true })
                const obj = JSON.parse(JSON.stringify({ servers: res.data.guilds }));
                const response = await axios.post(state.url + "/api/mutuals/", obj);
                const guild = [];

                for (let index = 0; index < response.data.servers.length; index++) {
                    let discordObject = { id: "", name: "", icon: "", premium: "", hasPerms: false };
                    discordObject.id = response.data.servers[index].id;
                    discordObject.name = response.data.servers[index].name;
                    discordObject.premium = response.data.servers[index].premium;

                    if (response.data.servers[index].icon === undefined) {
                        discordObject.icon = "https://cdn.discordapp.com/icons/817542418420596766/f8ec64ca464b50d9f4a80e1256cabd7f.png";
                    } else {
                        discordObject.icon = response.data.servers[index].icon;
                    }
                    guild.push(discordObject);
                    guild.sort((a, b) => b.premium - a.premium);
                }

                let userObject = { id: "", name: "", icon: "", premium: "" };
                userObject.id = res.data.discordId;
                userObject.tag = res.data.discordTag;
                userObject.avatar = "https://cdn.discordapp.com/avatars/" + res.data.discordId + "/" + res.data.avatar;
                localStorage.setItem("accessToken", JSON.stringify(res.data.accessToken));
                commit("setDiscordGuilds", guild);
                commit("setDiscordUser", userObject);
                commit("setAuthenticated", true);
            }

        },
    },

    getters: {
        discordGuilds: (state) => state.discordGuilds,
        templates: (state) => state.templates,
        authenticated: (state) => state.authenticated,
        discordUser: (state) => state.discordUser,
        discordChannels: (state) => state.discordChannels,
        chosenDiscord: (state) => state.chosenDiscord,
        chosenDiscordRoles: (state) => state.chosenDiscordRoles,
    },
});