<template>
    <v-container>
      <span class="bg"></span>
    				     <v-col cols="12" class="mt-n7 mb-15 px-3" align="center">
      <v-btn-toggle dark tile>
        <v-btn elevation="0" tile min-width="130px" :ripple="false" color="#202025" style="opacity: 0.8; border: none;" to="/documentation/intro">Intro</v-btn>
        <v-btn elevation="0" tile min-width="130px" :ripple="false" color="red lighten-1" style="opacity: 1; border: none;" to="/documentation/commands">Commands</v-btn>
	  </v-btn-toggle>
          <v-btn-toggle dark tile>
        <v-btn elevation="0" tile min-width="130px" :ripple="false" color="#202025" style="opacity: 0.8; border: none;" to="/documentation/advanced">Advanced</v-btn>
        <v-btn elevation="0" tile min-width="130px" :ripple="false" color="#202025" style="opacity: 0.8; border: none;" to="/documentation/reference">Reference</v-btn>
	  </v-btn-toggle>
	  <v-btn-toggle dark tile>
        <v-btn elevation="0" tile min-width="130px" :ripple="false" color="#202025" style="opacity: 0.8; border: none;" to="/documentation/guides">Guides</v-btn>
        <v-btn elevation="0" tile min-width="130px" :ripple="false" color="#202025" style="opacity: 0.8; border: none;" to="/documentation/api">API</v-btn>
	  </v-btn-toggle>
  
    </v-col>
    <v-autocomplete dark return-object dense style="z-index: 7; min-width: 250px !important; border-radius: 0px;" item-color="white" outlined label="Search..." background-color="secondary" flat :items="commands" class="mb-n6 ml-3 mr-3 mt-n8" item-text="name" hide-no-data hide-selected v-model="searchResult" clearable>
    </v-autocomplete>

    <v-card tile class="ml-3 mr-3" v-if="searchResult" dark background-color="primary" color="#303035" style="border-left: 2px solid darkgrey;">
      <v-card-title class="my-2 pl-6" style="background-color: #1d1d21;">
    
                  <v-row class="">
                    <v-col cols="12" class="text-left mb-n4 mt-n1" style="font-weight: 400;">
                      {{ searchResult.name }}
                    </v-col>

                    <v-col cols="12" class="text-left">
                      <v-row>
                        <span style="color: darkgray; font-size: 0.8em; font-weight: 400;" class="mx-3" v-html="searchResult.shortDescription"></span>
                        </v-row>
                        <v-row class="px-2">
                      <span v-for="permission in searchResult.permissions" :key="permission" class="ml-1 mb-1 mt-n2">
                      <v-tooltip top open-delay="500" color="#0f0f0f">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" label x-small v-if="permission === 'Admin'" color="#008000">
                            {{permission}}
                          </v-chip>
                        </template>
                        <span>Members with the Admin permission can use this command.</span>
                      </v-tooltip>
                    <v-tooltip top open-delay="500" color="#0f0f0f">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" label x-small v-if="permission === 'Manage Server'" color="#806060">
                          {{permission}}
                        </v-chip>
                        </template>
                        <span>Members with the Manage Server permission can use this command.</span>
                      </v-tooltip>
                        
                      <v-tooltip top open-delay="500" color="#0f0f0f">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" label x-small v-if="permission === 'Manager'" color="#277BAB">
                          {{permission}}
                        </v-chip>
                        </template>
                        <span>Members with the manager role can use this command.<br>You can set a manager role with the /set manager command.</span>
                      </v-tooltip>

                      <v-tooltip top open-delay="500" color="#0f0f0f">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" label x-small v-if="permission === 'Assistant'" color="#AB7878">
                          {{permission}}
                        </v-chip>
                        </template>
                        <span>Members with the assistant role can use this command.<br>You can set an assistant role with the /set assistant command.</span>
                      </v-tooltip>

                      </span>
                        </v-row>
                    </v-col>
                  </v-row>
      </v-card-title>
      <v-card-text class="my-3">
       
                  <v-row>
                     <v-expansion-panels flat multiple tile dark class="my-2 px-2" v-if="searchResult.subCommands.length > 1">
              <v-expansion-panel v-for="(n, sub) in searchResult.subCommands" :key="sub" class="my-1">
                  <v-expansion-panel-header style="background-color: #1d1d21;">
                    <v-row>
                    <v-col cols="12" class="mb-n4">
                    {{n.name}}
                    </v-col>
                    <v-col cols="12" class="mb-0">
                    <span style="color:gray" class="" v-html="n.description"></span>
                    </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #373a3e;" class="pt-5">
                    <v-col cols="12" class="mb-n5" v-if="n.options.length>0">
                     <span style='color:#999999; font-weight: 500;'> Options </span> <br/> <span style="color:#99cc00; font-weight: 300;"> <p style="line-height: 35px; font-size: 14px;" v-html="n.options.join('<br>')"></p> </span>
                    </v-col>
                    <v-col cols="12" class="mb-n5" v-if="n.arguments">
                     <span style="color:#99cc00; font-weight: 300;"> <p style="line-height: 30px; font-size: 14px;">
                        <span class='px-2 py-1 field3'>template</span> - Enter the template number for this event. Defaults to the standard template if argument is not provided.<br>
                        <span class='px-2 py-1 field3'>title</span> - Enter the title for this event.<br>
                        <span class='px-2 py-1 field3'>description</span> - Enter the description for this event.<br>
                        <span class='px-2 py-1 field3'>channel</span> - Enter the channel for this event. Defaults to the current channel if argument is not provided.<br>
                        <span class='px-2 py-1 field3'>date</span> - Enter the date for this event. Defaults to one week ahead if argument is not provided.<br>
                        <span class='px-2 py-1 field3'>time</span> - Enter the time for this event. Defaults to one hour ahead if argument is not provided.<br>
                        <span class='px-2 py-1 field3'>image</span> - Enter an image url for this event.<br>
                        <span class='px-2 py-1 field3'>color</span> - Enter a color number or hex value for this event.<br>
                        <span class='px-2 py-1 field3'>advanced</span> - Enter advanced setting arguments to override the servers default advanced settings applied to this event.<br>
                        <span class='px-2 py-1 field3'>mentions</span> - Mention or enter role names to be pinged on event creation.<br>
                        <span class='px-2 py-1 field3'>announcement</span> - Set up an announcement. Enter the channel name, time in minutes before the event and the message separated by comma in that order.<br>
                        <span class='px-2 py-1 field3'>optout</span> - Enter the id of an existing event to copy over the Sign-Ups from.<br>
                        <span class='px-2 py-1 field3'>softres</span> - Enter the softres argument. <v-btn elevation="0" x-small plain :ripple="false" style="margin-top: -1px; margin-left: -7px; padding-left: -23px; font-size: 12px;" color="blue"  @click="openSoftresSyntax = !openSoftresSyntax">Softres Syntax<v-icon v-if="openSoftresSyntax" size="small">mdi-chevron-up</v-icon><v-icon v-else size="small">mdi-chevron-down</v-icon></v-btn>
                                                     <v-simple-table v-if="openSoftresSyntax" dark style="background-color: #18191c; color: darkgrey; border: 1px solid black; margin-top: 10px; margin-bottom: 10px;">
    <template>
      <tbody>
        <tr style="background-color: #28292c; color: white;"><td style="height: 35px !important; color: white;"> Name </td><td style="height: 35px !important;">Values</td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> 1. Instance </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;"><v-btn elevation="0" x-small plain :ripple="false" style="margin-top: -1px; margin-left: -7px; padding-left: -23px; font-size: 12px;" color="blue"  @click="openSoftresInstances = !openSoftresInstances">Softres Instances<v-icon v-if="openSoftresSyntax && openSoftresInstances" size="small">mdi-chevron-up</v-icon><v-icon v-else size="small">mdi-chevron-down</v-icon></v-btn></span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> 2. Faction </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">horde/alliance</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> 3. Limit per member </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">1-10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> 4. Limit per item </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">0-10 (0 = no limit)</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> 5. Discord Protection </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">protected/unprotected</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> 6. Visibility </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">hidden/visible</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> 7. Duplicates </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">yes/no</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> 8. Send Link after Sign-Up </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">yes/no/require</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Example </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">[softres: bwl, horde, 1, 0, protected, visible, yes, yes]</span></td></tr>

          </tbody>
    </template>
  </v-simple-table>
                              <v-simple-table v-if="openSoftresSyntax && openSoftresInstances" dark style="background-color: #18191c; color: darkgrey; border: 1px solid black; margin-top: 10px; margin-bottom: 10px;">
    <template>
      <tbody>
        <tr style="background-color: #28292c; color: white;"><td style="height: 35px !important; color: white;"> INSTANCE (CLASSIC) </td><td style="height: 35px !important;">ID</td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Zul'Gurub </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">zg</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Molten Core </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">mc</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Onyxia </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">onyxia</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Blackwing Lair </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">bwl</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Ahn'Qiraj 20 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">aq20</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Ahn'Qiraj 40 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">aq40</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Naxxramas </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">naxx</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Azuregos </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">azuregos</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Lord Kazzak </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">lordkazzak</span></td></tr>

        <tr style="background-color: #28292c; color: white;"><td style="height: 35px !important; color: white;"> INSTANCE (SOD) </td><td style="height: 35px !important;">ID</td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Blackfathom Deeps </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">bfdsod</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Gnomeregan </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">gnomeregansod</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Sunken Temple </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">sunkentemplesod</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Azuregos </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">azuregossod</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Lord Kazzak </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">lordkazzaksod</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Molten Core </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">mcsod</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Onyxia </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">onyxiasod</span></td></tr>

        <tr style="background-color: #28292c; color: white;"><td style="height: 35px !important; color: white;"> INSTANCE (TBC) </td><td style="height: 35px !important;">ID</td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Karazhan </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">kara</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Gruul's Lair </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">gruul</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Magtheridon's Lair </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">magtheridon</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Serpentshrine Cavern </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">ssc</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Tempest Keep </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">tempestkeep</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Hyjal </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">hyjal</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Black Temple </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">blacktemple</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Zul'Aman </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">za</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Sunwell Plateau </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">sunwellplateau</span></td></tr>

        <tr style="background-color: #28292c; color: white;"><td style="height: 35px !important; color: white;"> INSTANCE (WOTLK) </td><td style="height: 35px !important;">ID</td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Naxxramas 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">wotlknaxx10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Naxxramas 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">wotlknaxx25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Obsidian Sanctum 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">obsidiansanctum10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Obsidian Sanctum 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">obsidiansanctum25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Eye of Eternity 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">eyeofeternity10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Eye of Eternity 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">eyeofeternity25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Ruby Sanctum 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">rubysanctum10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Ruby Sanctum 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">rubysanctum25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> EoE/OS Dragons 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">wyrmrest10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> EoE/OS Dragons 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">wyrmrest25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Ulduar 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">ulduar10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Ulduar 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">ulduar25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Trial of the Crusader 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">toc10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Trial of the Crusader 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">toc25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Onyxia 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">wotlkonyxia10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Onyxia 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">wotlkonyxia25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Icecrown Citadel 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">icc10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Icecrown Citadel 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">icc25</span></td></tr>

        <tr style="background-color: #28292c; color: white;"><td style="height: 35px !important; color: white;"> INSTANCE (CATACLYSM) </td><td style="height: 35px !important;">ID</td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Baradin Hold </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">baradinhold</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Bastion of Twilight </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">bastionoftwilight</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Blackwing Descent </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">blackwingdescent</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Throne of the Four Winds </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">throneoffourwinds</span></td></tr>

  </tbody>
    </template>
  </v-simple-table>

  
                       
                       </p></span>
                    </v-col>
                    <v-col cols="12" class="mb-n5" v-if="n.selection !== ' '">
                     <span style='color:#999999; font-weight: 500;'> Selection </span> <br/> <p v-html="n.selection"></p>
                    </v-col>
                    <v-col cols="12" class="mb-n5" v-if="n.examples.length>0">
                     <span style='color:#999999; font-weight: 500;'> Examples </span> <br/> <span style="color:#99cc00; font-weight: 300;"> <p style="line-height: 35px; font-size: 14px;" v-html="n.examples.join('<br>')"></p> </span>
                    </v-col>
                  </v-expansion-panel-content>
              </v-expansion-panel>
                     </v-expansion-panels>
                           <div v-if="searchResult.subCommands.length < 2">
                    <v-col cols="12" class="mb-n5" v-if="searchResult.subCommands[0].options.length>0">
                     <span style='color:#999999; font-weight: 500;'> Options </span> <br/> <span style="color:#99cc00; font-weight: 300;"> <p style="line-height: 35px; font-size: 14px;" v-html="searchResult.subCommands[0].options.join('<br>')"></p> </span>
                    </v-col>
                    <v-col cols="12" class="mb-n5" v-if="searchResult.subCommands[0].arguments">
                     <span style="color:#99cc00; font-weight: 300;"> <p style="line-height: 30px; font-size: 14px;">
                        <span class='px-2 py-1 field3'>template</span> - Enter the template number for this event. Defaults to the standard template if argument is not provided.<br>
                        <span class='px-2 py-1 field3'>title</span> - Enter the title for this event.<br>
                        <span class='px-2 py-1 field3'>description</span> - Enter the description for this event.<br>
                        <span class='px-2 py-1 field3'>channel</span> - Enter the channel for this event. Defaults to the current channel if argument is not provided.<br>
                        <span class='px-2 py-1 field3'>date</span> - Enter the date for this event. Defaults to one week ahead if argument is not provided.<br>
                        <span class='px-2 py-1 field3'>time</span> - Enter the time for this event. Defaults to one hour ahead if argument is not provided.<br>
                        <span class='px-2 py-1 field3'>image</span> - Enter an image url for this event.<br>
                        <span class='px-2 py-1 field3'>color</span> - Enter a color number or hex value for this event.<br>
                        <span class='px-2 py-1 field3'>advanced</span> - Enter advanced setting arguments to override the servers default advanced settings applied to this event.<br>
                        <span class='px-2 py-1 field3'>mentions</span> - Mention or enter role names to be pinged on event creation.<br>
                        <span class='px-2 py-1 field3'>announcement</span> - Set up an announcement. Enter the channel name, time in minutes before the event and the message separated by comma in that order.<br>
                        <span class='px-2 py-1 field3'>optout</span> - Enter the id of an existing event to copy over the Sign-Ups from.<br>
                        <span class='px-2 py-1 field3'>softres</span> - Enter the softres argument. <v-btn elevation="0" x-small plain :ripple="false" style="margin-top: -1px; margin-left: -7px; padding-left: -23px; font-size: 12px;" color="blue"  @click="openSoftresSyntax = !openSoftresSyntax">Softres Syntax<v-icon v-if="openSoftresSyntax" size="small">mdi-chevron-up</v-icon><v-icon v-else size="small">mdi-chevron-down</v-icon></v-btn>
                                                     <v-simple-table v-if="openSoftresSyntax" dark style="background-color: #18191c; color: darkgrey; border: 1px solid black; margin-top: 10px; margin-bottom: 10px;">
    <template>
      <tbody>
        <tr style="background-color: #28292c; color: white;"><td style="height: 35px !important; color: white;"> Name </td><td style="height: 35px !important;">Values</td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> 1. Instance </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;"><v-btn elevation="0" x-small plain :ripple="false" style="margin-top: -1px; margin-left: -7px; padding-left: -23px; font-size: 12px;" color="blue"  @click="openSoftresInstances = !openSoftresInstances">Softres Instances<v-icon v-if="openSoftresSyntax && openSoftresInstances" size="small">mdi-chevron-up</v-icon><v-icon v-else size="small">mdi-chevron-down</v-icon></v-btn></span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> 2. Faction </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">horde/alliance</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> 3. Limit per member </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">1-10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> 4. Limit per item </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">0-10 (0 = no limit)</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> 5. Discord Protection </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">protected/unprotected</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> 6. Visibility </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">hidden/visible</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> 7. Duplicates </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">yes/no</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> 8. Send Link after Sign-Up </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">yes/no/require</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Example </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">[softres: bwl, horde, 1, 0, protected, visible, yes, yes]</span></td></tr>

          </tbody>
    </template>
  </v-simple-table>
                              <v-simple-table v-if="openSoftresSyntax && openSoftresInstances" dark style="background-color: #18191c; color: darkgrey; border: 1px solid black; margin-top: 10px; margin-bottom: 10px;">
    <template>
      <tbody>
        <tr style="background-color: #28292c; color: white;"><td style="height: 35px !important; color: white;"> INSTANCE (CLASSIC) </td><td style="height: 35px !important;">ID</td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Zul'Gurub </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">zg</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Molten Core </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">mc</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Onyxia </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">onyxia</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Blackwing Lair </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">bwl</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Ahn'Qiraj 20 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">aq20</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Ahn'Qiraj 40 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">aq40</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Naxxramas </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">naxx</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Azuregos </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">azuregos</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Lord Kazzak </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">lordkazzak</span></td></tr>

        <tr style="background-color: #28292c; color: white;"><td style="height: 35px !important; color: white;"> INSTANCE (SOD) </td><td style="height: 35px !important;">ID</td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Blackfathom Deeps </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">bfdsod</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Gnomeregan </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">gnomeregansod</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Sunken Temple </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">sunkentemplesod</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Azuregos </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">azuregossod</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Lord Kazzak </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">lordkazzaksod</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Molten Core </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">mcsod</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Onyxia </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">onyxiasod</span></td></tr>

        <tr style="background-color: #28292c; color: white;"><td style="height: 35px !important; color: white;"> INSTANCE (TBC) </td><td style="height: 35px !important;">ID</td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Karazhan </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">kara</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Gruul's Lair </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">gruul</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Magtheridon's Lair </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">magtheridon</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Serpentshrine Cavern </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">ssc</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Tempest Keep </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">tempestkeep</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Hyjal </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">hyjal</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Black Temple </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">blacktemple</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Zul'Aman </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">za</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Sunwell Plateau </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">sunwellplateau</span></td></tr>

        <tr style="background-color: #28292c; color: white;"><td style="height: 35px !important; color: white;"> INSTANCE (WOTLK) </td><td style="height: 35px !important;">ID</td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Naxxramas 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">wotlknaxx10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Naxxramas 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">wotlknaxx25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Obsidian Sanctum 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">obsidiansanctum10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Obsidian Sanctum 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">obsidiansanctum25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Eye of Eternity 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">eyeofeternity10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Eye of Eternity 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">eyeofeternity25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Ruby Sanctum 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">rubysanctum10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Ruby Sanctum 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">rubysanctum25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> EoE/OS Dragons 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">wyrmrest10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> EoE/OS Dragons 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">wyrmrest25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Ulduar 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">ulduar10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Ulduar 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">ulduar25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Trial of the Crusader 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">toc10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Trial of the Crusader 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">toc25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Onyxia 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">wotlkonyxia10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Onyxia 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">wotlkonyxia25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Icecrown Citadel 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">icc10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Icecrown Citadel 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">icc25</span></td></tr>

        <tr style="background-color: #28292c; color: white;"><td style="height: 35px !important; color: white;"> INSTANCE (CATACLYSM) </td><td style="height: 35px !important;">ID</td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Baradin Hold </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">baradinhold</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Bastion of Twilight </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">bastionoftwilight</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Blackwing Descent </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">blackwingdescent</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Throne of the Four Winds </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">throneoffourwinds</span></td></tr>

  </tbody>
    </template>
  </v-simple-table>

  
                       
                       </p></span>
                    </v-col>
                    <v-col cols="12" class="mb-n5" v-if="searchResult.subCommands[0].selection !== ' '">
                     <span style='color:#999999; font-weight: 500;'> Selection </span> <br/> <p v-html="searchResult.subCommands[0].selection"></p>
                    </v-col>
                    <v-col cols="12" class="mb-n5" v-if="searchResult.subCommands[0].examples.length>0">
                     <span style='color:#999999; font-weight: 500;'> Examples </span> <br/> <span style="color:#99cc00; font-weight: 300;"> <p style="line-height: 35px; font-size: 14px;" v-html="searchResult.subCommands[0].examples.join('<br>')"></p> </span>
                    </v-col>
                           </div>
                  </v-row>
            
      </v-card-text>
    </v-card>

    <v-container v-if="!searchResult">

    <v-tabs v-model="tab" active-class="accent lighten-1" :center-active="isMobile2<=475" color="primary" grow background-color="primary" dark class="border">
      <v-tab v-for="item in categories" :key="item" style="margin-left: 0px !important;">
        {{ item }}
      </v-tab>
    </v-tabs>
    <v-card dark tile elevation="0" style="background-color: transparent !important;">
    <v-tabs-items v-model="tab" style="background-color: transparent !important;">
      <v-tab-item v-for="cat in categories" :key="cat" transition="false" style="background-color: transparent !important;">
            <v-expansion-panels tile hover dark class="mt-2" style="background-color: transparent !important;">
              <v-container v-for="(items, n) in commands" :key="n" class="pa-0">
              <v-expansion-panel class="mb-2" active-class="panel" v-if="cat === items.category">

                <v-expansion-panel-header style="background-color: #1d1d21;">

                  <v-row class="">
                    <v-col cols="12" class="text-left mb-n4" style="font-size: 1.4em;">
                      {{ items.name }}
                    </v-col>

                    <v-col cols="12" class="text-left">
                      <v-row>
                        <span style="color:darkgray" class="mx-3 my-3" v-html="items.shortDescription"></span>
                        </v-row>
                        <v-row class="px-2">
                      <span v-for="permission in items.permissions" :key="permission" class="ml-1 mb-3">
                      <v-tooltip top open-delay="500" color="#0f0f0f">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" label x-small v-if="permission === 'Admin'" color="#008000">
                            {{permission}}
                          </v-chip>
                        </template>
                        <span>Members with the Admin permission can use this command.</span>
                      </v-tooltip>
                    <v-tooltip top open-delay="500" color="#0f0f0f">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" label x-small v-if="permission === 'Manage Server'" color="#806060">
                          {{permission}}
                        </v-chip>
                        </template>
                        <span>Members with the Manage Server permission can use this command.</span>
                      </v-tooltip>
                        
                      <v-tooltip top open-delay="500" color="#0f0f0f">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" label x-small v-if="permission === 'Manager'" color="#277BAB">
                          {{permission}}
                        </v-chip>
                        </template>
                        <span>Members with the manager role can use this command.<br>You can set a manager role with the /set manager command.</span>
                      </v-tooltip>

                      <v-tooltip top open-delay="500" color="#0f0f0f">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" label x-small v-if="permission === 'Assistant'" color="#AB7878">
                          {{permission}}
                        </v-chip>
                        </template>
                        <span>Members with the assistant role can use this command.<br>You can set an assistant role with the /set assistant command.</span>
                      </v-tooltip>

                      </span>
                        </v-row>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mt-0" style="background-color: #303036;">
                  <v-row>
                     <v-expansion-panels flat multiple tile dark class="my-2" v-if="items.subCommands.length > 1">
              <v-expansion-panel v-for="(n, sub) in items.subCommands" :key="sub" class="mt-2 elevation-0" :class="sub === 0 ? 'mt-4' : ''">
                  <v-expansion-panel-header style="background-color: #1d1d21;">
                    <v-row>
                    <v-col cols="12" class="mb-n4">
                    {{n.name}}
                    </v-col>
                    <v-col cols="12" class="mb-0">
                    <span style="color:gray" class="" v-html="n.description"></span>
                    </v-col>

                      <span v-for="permission in n.permissions" :key="permission" class="ml-3 mb-3">
                      <v-tooltip top open-delay="500" color="#0f0f0f">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" label x-small v-if="permission === 'Admin'" color="#008000">
                            {{permission}}
                          </v-chip>
                        </template>
                        <span>Members with the Admin permission can use this command.</span>
                      </v-tooltip>
                    <v-tooltip top open-delay="500" color="#0f0f0f">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" label x-small v-if="permission === 'Manage Server'" color="#806060">
                          {{permission}}
                        </v-chip>
                        </template>
                        <span>Members with the Manage Server permission can use this command.</span>
                      </v-tooltip>
                        
                      <v-tooltip top open-delay="500" color="#0f0f0f">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" label x-small v-if="permission === 'Manager'" color="#277BAB">
                          {{permission}}
                        </v-chip>
                        </template>
                        <span>Members with the manager role can use this command.<br>You can set a manager role with the /set manager command.</span>
                      </v-tooltip>

                      <v-tooltip top open-delay="500" color="#0f0f0f">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" label x-small v-if="permission === 'Assistant'" color="#AB7878">
                          {{permission}}
                        </v-chip>
                        </template>
                        <span>Members with the assistant role can use this command.<br>You can set an assistant role with the /set assistant command.</span>
                      </v-tooltip>

                      </span>

                    </v-row>
                    
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: #38383e;" class="pt-5">
                    <v-col cols="12" class="mb-n5" v-if="n.options.length>0">
                     <span style='color:#999999; font-weight: 500;'> Options </span> <br/> <span style="color:#99cc00; font-weight: 300;"> <p style="line-height: 35px; font-size: 14px;" v-html="n.options.join('<br>')"></p> </span>
                    </v-col>
                    <v-col cols="12" class="mb-n5" v-if="n.arguments">
                     <span style="color:#99cc00; font-weight: 300;"> <p style="line-height: 30px; font-size: 14px;">
                        <span class='px-2 py-1 field3'>template</span> - Enter the template number for this event. Defaults to the standard template if argument is not provided.<br>
                        <span class='px-2 py-1 field3'>title</span> - Enter the title for this event.<br>
                        <span class='px-2 py-1 field3'>description</span> - Enter the description for this event.<br>
                        <span class='px-2 py-1 field3'>channel</span> - Enter the channel for this event. Defaults to the current channel if argument is not provided.<br>
                        <span class='px-2 py-1 field3'>date</span> - Enter the date for this event. Defaults to one week ahead if argument is not provided.<br>
                        <span class='px-2 py-1 field3'>time</span> - Enter the time for this event. Defaults to one hour ahead if argument is not provided.<br>
                        <span class='px-2 py-1 field3'>image</span> - Enter an image url for this event.<br>
                        <span class='px-2 py-1 field3'>color</span> - Enter a color number or hex value for this event.<br>
                        <span class='px-2 py-1 field3'>advanced</span> - Enter advanced setting arguments to override the servers default advanced settings applied to this event.<br>
                        <span class='px-2 py-1 field3'>mentions</span> - Mention or enter role names to be pinged on event creation.<br>
                        <span class='px-2 py-1 field3'>announcement</span> - Set up an announcement. Enter the channel name, time in minutes before the event and the message separated by comma in that order.<br>
                        <span class='px-2 py-1 field3'>optout</span> - Enter the id of an existing event to copy over the Sign-Ups from.<br>
                        <span class='px-2 py-1 field3'>softres</span> - Enter the softres argument. <v-btn elevation="0" x-small plain :ripple="false" style="margin-top: -1px; margin-left: -7px; padding-left: -23px; font-size: 12px;" color="blue"  @click="openSoftresSyntax = !openSoftresSyntax">Softres Syntax<v-icon v-if="openSoftresSyntax" size="small">mdi-chevron-up</v-icon><v-icon v-else size="small">mdi-chevron-down</v-icon></v-btn>
                                                     <v-simple-table v-if="openSoftresSyntax" dark style="background-color: #18191c; color: darkgrey; border: 1px solid black; margin-top: 10px; margin-bottom: 10px;">
    <template>
      <tbody>
        <tr style="background-color: #28292c; color: white;"><td style="height: 35px !important; color: white;"> Name </td><td style="height: 35px !important;">Values</td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> 1. Instance </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;"><v-btn elevation="0" x-small plain :ripple="false" style="margin-top: -1px; margin-left: -7px; padding-left: -23px; font-size: 12px;" color="blue"  @click="openSoftresInstances = !openSoftresInstances">Softres Instances<v-icon v-if="openSoftresSyntax && openSoftresInstances" size="small">mdi-chevron-up</v-icon><v-icon v-else size="small">mdi-chevron-down</v-icon></v-btn></span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> 2. Faction </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">horde/alliance</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> 3. Limit per member </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">1-10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> 4. Limit per item </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">0-10 (0 = no limit)</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> 5. Discord Protection </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">protected/unprotected</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> 6. Visibility </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">hidden/visible</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> 7. Duplicates </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">yes/no</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> 8. Send Link after Sign-Up </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">yes/no/require</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Example </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">[softres: bwl, horde, 1, 0, protected, visible, yes, yes]</span></td></tr>

          </tbody>
    </template>
  </v-simple-table>
                              <v-simple-table v-if="openSoftresSyntax && openSoftresInstances" dark style="background-color: #18191c; color: darkgrey; border: 1px solid black; margin-top: 10px; margin-bottom: 10px;">
    <template>
      <tbody>
        <tr style="background-color: #28292c; color: white;"><td style="height: 35px !important; color: white;"> INSTANCE (CLASSIC) </td><td style="height: 35px !important;">ID</td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Zul'Gurub </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">zg</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Molten Core </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">mc</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Onyxia </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">onyxia</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Blackwing Lair </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">bwl</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Ahn'Qiraj 20 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">aq20</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Ahn'Qiraj 40 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">aq40</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Naxxramas </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">naxx</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Azuregos </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">azuregos</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Lord Kazzak </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">lordkazzak</span></td></tr>

        <tr style="background-color: #28292c; color: white;"><td style="height: 35px !important; color: white;"> INSTANCE (SOD) </td><td style="height: 35px !important;">ID</td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Blackfathom Deeps </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">bfdsod</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Gnomeregan </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">gnomeregansod</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Sunken Temple </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">sunkentemplesod</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Azuregos </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">azuregossod</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Lord Kazzak </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">lordkazzaksod</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Molten Core </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">mcsod</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Onyxia </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">onyxiasod</span></td></tr>


        <tr style="background-color: #28292c; color: white;"><td style="height: 35px !important; color: white;"> INSTANCE (TBC) </td><td style="height: 35px !important;">ID</td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Karazhan </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">kara</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Gruul's Lair </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">gruul</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Magtheridon's Lair </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">magtheridon</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Serpentshrine Cavern </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">ssc</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Tempest Keep </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">tempestkeep</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Hyjal </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">hyjal</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Black Temple </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">blacktemple</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Zul'Aman </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">za</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Sunwell Plateau </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">sunwellplateau</span></td></tr>

        <tr style="background-color: #28292c; color: white;"><td style="height: 35px !important; color: white;"> INSTANCE (WOTLK) </td><td style="height: 35px !important;">ID</td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Naxxramas 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">wotlknaxx10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Naxxramas 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">wotlknaxx25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Obsidian Sanctum 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">obsidiansanctum10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Obsidian Sanctum 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">obsidiansanctum25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Eye of Eternity 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">eyeofeternity10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Eye of Eternity 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">eyeofeternity25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Ruby Sanctum 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">rubysanctum10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Ruby Sanctum 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">rubysanctum25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> EoE/OS Dragons 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">wyrmrest10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> EoE/OS Dragons 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">wyrmrest25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Ulduar 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">ulduar10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Ulduar 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">ulduar25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Trial of the Crusader 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">toc10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Trial of the Crusader 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">toc25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Onyxia 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">wotlkonyxia10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Onyxia 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">wotlkonyxia25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Icecrown Citadel 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">icc10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Icecrown Citadel 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">icc25</span></td></tr>

        <tr style="background-color: #28292c; color: white;"><td style="height: 35px !important; color: white;"> INSTANCE (CATACLYSM) </td><td style="height: 35px !important;">ID</td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Baradin Hold </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">baradinhold</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Bastion of Twilight </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">bastionoftwilight</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Blackwing Descent </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">blackwingdescent</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Throne of the Four Winds </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">throneoffourwinds</span></td></tr>

  </tbody>
    </template>
  </v-simple-table>

  
                       
                       </p></span>
                    </v-col>
                    <v-col cols="12" class="mb-n5" v-if="n.selection !== ' '">
                     <span style='color:#999999; font-weight: 500;'> Selection </span> <br/> <p v-html="n.selection"></p>
                    </v-col>
                    <v-col cols="12" class="mb-n5" v-if="n.examples.length>0">
                     <span style='color:#999999; font-weight: 500;'> Examples </span> <br/> <span style="color:#99cc00; font-weight: 300;"> <p style="line-height: 35px; font-size: 14px;" v-html="n.examples.join('<br>')"></p> </span>
                    </v-col>
                    <v-col cols="12" class="mb-n5" v-if="n.description2 !== ' ' && n.description2 !== undefined">
                     <span style='color:#999999; font-weight: 500; line-height: 40px;'> Description </span> <br/> <p v-html="n.description2"></p>
                    </v-col>
                  </v-expansion-panel-content>
              </v-expansion-panel>
                     </v-expansion-panels>
                           <div v-if="items.subCommands.length < 2">
                    <v-col cols="12" class="mb-n5 mt-5" v-if="items.subCommands[0].options.length>0">
                     <span style='color:#999999; font-weight: 500;'> Options </span> <br/> <span style="color:#99cc00; font-weight: 300;"> <p style="line-height: 35px; font-size: 14px;" v-html="items.subCommands[0].options.join('<br>')"></p> </span>
                    </v-col>
                    <v-col cols="12" class="mb-n5 mt-5" v-if="items.subCommands[0].arguments">
                     <span style='color:#999999; font-weight: 500;'> Arguments </span> <br/> 
                     <span style="color:#99cc00; font-weight: 300;"> <p style="line-height: 30px; font-size: 14px;">
                        <span class='px-2 py-1 field3'>template</span> - Enter the template number for this event. Defaults to the standard template if argument is not provided.<br>
                        <span class='px-2 py-1 field3'>title</span> - Enter the title for this event.<br>
                        <span class='px-2 py-1 field3'>description</span> - Enter the description for this event.<br>
                        <span class='px-2 py-1 field3'>channel</span> - Enter the channel for this event. Defaults to the current channel if argument is not provided.<br>
                        <span class='px-2 py-1 field3'>date</span> - Enter the date for this event. Defaults to one week ahead if argument is not provided.<br>
                        <span class='px-2 py-1 field3'>time</span> - Enter the time for this event. Defaults to one hour ahead if argument is not provided.<br>
                        <span class='px-2 py-1 field3'>image</span> - Enter an image url for this event.<br>
                        <span class='px-2 py-1 field3'>color</span> - Enter a color number or hex value for this event.<br>
                        <span class='px-2 py-1 field3'>advanced</span> - Enter advanced setting arguments to override the servers default advanced settings applied to this event.<br>
                        <span class='px-2 py-1 field3'>mentions</span> - Mention or enter role names to be pinged on event creation.<br>
                        <span class='px-2 py-1 field3'>announcement</span> - Set up an announcement. Enter the channel name, time in minutes before the event and the message separated by comma in that order.<br>
                        <span class='px-2 py-1 field3'>optout</span> - Enter the id of an existing event to copy over the Sign-Ups from.<br>
                        <span class='px-2 py-1 field3'>softres</span> - Enter the softres argument. <v-btn elevation="0" x-small plain :ripple="false" style="margin-top: -1px; margin-left: -7px; padding-left: -23px; font-size: 12px;" color="blue"  @click="openSoftresSyntax = !openSoftresSyntax">Softres Syntax<v-icon v-if="openSoftresSyntax" size="small">mdi-chevron-up</v-icon><v-icon v-else size="small">mdi-chevron-down</v-icon></v-btn>
                                                     <v-simple-table v-if="openSoftresSyntax" dark style="background-color: #18191c; color: darkgrey; border: 1px solid black; margin-top: 10px; margin-bottom: 10px;">
    <template>
      <tbody>
        <tr style="background-color: #28292c; color: white;"><td style="height: 35px !important; color: white;"> Name </td><td style="height: 35px !important;">Values</td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> 1. Instance </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;"><v-btn elevation="0" x-small plain :ripple="false" style="margin-top: -1px; margin-left: -7px; padding-left: -23px; font-size: 12px;" color="blue"  @click="openSoftresInstances = !openSoftresInstances">Softres Instances<v-icon v-if="openSoftresSyntax && openSoftresInstances" size="small">mdi-chevron-up</v-icon><v-icon v-else size="small">mdi-chevron-down</v-icon></v-btn></span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> 2. Faction </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">horde/alliance</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> 3. Limit per member </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">1-10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> 4. Limit per item </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">0-10 (0 = no limit)</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> 5. Discord Protection </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">protected/unprotected</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> 6. Visibility </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">hidden/visible</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> 7. Duplicates </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">yes/no</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> 8. Send Link after Sign-Up </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">yes/no/require</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Example </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">[softres: bwl, horde, 1, 0, protected, visible, yes, yes]</span></td></tr>

          </tbody>
    </template>
  </v-simple-table>
                              <v-simple-table v-if="openSoftresSyntax && openSoftresInstances" dark style="background-color: #18191c; color: darkgrey; border: 1px solid black; margin-top: 10px; margin-bottom: 10px;">
    <template>
      <tbody>
        <tr style="background-color: #28292c; color: white;"><td style="height: 35px !important; color: white;"> INSTANCE (CLASSIC) </td><td style="height: 35px !important;">ID</td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Zul'Gurub </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">zg</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Molten Core </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">mc</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Onyxia </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">onyxia</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Blackwing Lair </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">bwl</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Ahn'Qiraj 20 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">aq20</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Ahn'Qiraj 40 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">aq40</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Naxxramas </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">naxx</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Azuregos </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">azuregos</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Lord Kazzak </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">lordkazzak</span></td></tr>

        <tr style="background-color: #28292c; color: white;"><td style="height: 35px !important; color: white;"> INSTANCE (SOD) </td><td style="height: 35px !important;">ID</td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Blackfathom Deeps </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">bfdsod</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Gnomeregan </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">gnomeregansod</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Sunken Temple </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">sunkentemplesod</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Azuregos </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">azuregossod</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Lord Kazzak </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">lordkazzaksod</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Molten Core </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">mcsod</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Onyxia </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">onyxiasod</span></td></tr>

        <tr style="background-color: #28292c; color: white;"><td style="height: 35px !important; color: white;"> INSTANCE (TBC) </td><td style="height: 35px !important;">ID</td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Karazhan </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">kara</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Gruul's Lair </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">gruul</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Magtheridon's Lair </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">magtheridon</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Serpentshrine Cavern </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">ssc</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Tempest Keep </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">tempestkeep</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Hyjal </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">hyjal</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Black Temple </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">blacktemple</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Zul'Aman </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">za</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Sunwell Plateau </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">sunwellplateau</span></td></tr>

        <tr style="background-color: #28292c; color: white;"><td style="height: 35px !important; color: white;"> INSTANCE (WOTLK) </td><td style="height: 35px !important;">ID</td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Naxxramas 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">wotlknaxx10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Naxxramas 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">wotlknaxx25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Obsidian Sanctum 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">obsidiansanctum10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Obsidian Sanctum 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">obsidiansanctum25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Eye of Eternity 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">eyeofeternity10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Eye of Eternity 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">eyeofeternity25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Ruby Sanctum 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">rubysanctum10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Ruby Sanctum 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">rubysanctum25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> EoE/OS Dragons 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">wyrmrest10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> EoE/OS Dragons 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">wyrmrest25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Ulduar 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">ulduar10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Ulduar 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">ulduar25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Trial of the Crusader 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">toc10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Trial of the Crusader 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">toc25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Onyxia 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">wotlkonyxia10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Onyxia 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">wotlkonyxia25</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Icecrown Citadel 10 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">icc10</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Icecrown Citadel 25 </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">icc25</span></td></tr>

        <tr style="background-color: #28292c; color: white;"><td style="height: 35px !important; color: white;"> INSTANCE (CATACLYSM) </td><td style="height: 35px !important;">ID</td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Baradin Hold </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">baradinhold</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Bastion of Twilight </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">bastionoftwilight</span></td></tr>
        <tr style="background-color: #121315;"><td style="height: 35px !important;"> Blackwing Descent </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">blackwingdescent</span></td></tr>
        <tr style="background-color: #18191c;"><td style="height: 35px !important;"> Throne of the Four Winds </td><td style="height: 35px !important;"><span style="background-color: #ffffff14; padding: 2px; border-radius: 3px;">throneoffourwinds</span></td></tr>

  </tbody>
    </template>
  </v-simple-table>

  
                       
                       </p></span>
                    </v-col>
                    <v-col cols="12" class="mb-n5 mt-5" v-if="items.subCommands[0].selection !== ' '">
                     <span style='color:#999999; font-weight: 500;'> Selection </span> <br/> <p v-html="items.subCommands[0].selection"></p>
                    </v-col>
                    <v-col cols="12" class="mb-n5 mt-5" v-if="items.subCommands[0].examples.length>0">
                     <span style='color:#999999; font-weight: 500;'> Examples </span> <br/> <span style="color:#99cc00; font-weight: 300;"> <p style="line-height: 35px; font-size: 14px;" v-html="items.subCommands[0].examples.join('<br>')"></p> </span>
                    </v-col>
                    <v-col cols="12" class="mb-n5 mt-5" v-if="items.subCommands[0].description !== ' '">
                      <span style='color:#999999; font-weight: 500; line-height: 40px;'> Description </span> <br/> <span style="color:#99cc00; font-weight: 300;"> <p style="line-height: 20px; font-size: 14px;" v-html="items.subCommands[0].description"></p> </span>
                    </v-col>
                           </div>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              </v-container>
            </v-expansion-panels>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
  </v-container>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      tab: null,
      isMobile2: null,
      search: [],
      searchResult: undefined,
      openSoftresInstances: false,
      openSoftresSyntax: false,
      categories: ["General", "Event", "Premium", "Setting"],
      commands: [
            {
            category: "General",
            name: "/welcome",
            shortDescription: "Create a welcome message that will be sent via DM to users joining your server.",
            permissions: ["Admin", "Manage Server"],
            subCommands: [{
            name: "/welcome set",
            description: "Set the welcome message that will be sent to users joining your server.",
            permissions: ["Admin", "Manage Server"],
            options: ["<span class='px-2 py-1 field1'>message:</span><span class='px-2 py-1 field2'>string</span> - Enter the message that will be sent. You can also enter the ID of a Raid-Helper embed."],
            selection: " ",
            examples: ["/welcome set <span class='px-2 py-1 field1'>message:</span><span class='px-2 py-1 field2'>Welcome to our discord server!</span>"],
            },
            {
            name: "/welcome reset",
            description: "Resets the setting",
            permissions: ["Admin", "Manage Server"],
            options: [], 
            selection: " ",
            examples: ["/welcome reset"],
          },
           {
            name: "/welcome show",
            description: "Show the currently set welcome message.",
            permissions: ["Admin", "Manage Server"],
            options: [], 
            selection: " ",
            examples: ["/welcome show"],
          }],
          },
          {
            category: "General",
            name: "/embed",
            shortDescription: "Show your saved embeds or create a new one.",
            permissions: ["Admin", "Manage Server", "Manager", "Assistant"],
            subCommands: [{
            name: "/embed create",
            description: "Create a new embed message. Embeds are otherwise not available to normal users.",
            permissions: ["Admin", "Manage Server", "Manager"],
            options: ["<span class='px-2 py-1 field1'>author:</span><span class='px-2 py-1 field2'>string</span> - Enter text for the author field of the embed.", 
            "<span class='px-2 py-1 field1'>title:</span><span class='px-2 py-1 field2'>string</span> - Enter text for the title field of the embed.", 
            "<span class='px-2 py-1 field1'>description:</span><span class='px-2 py-1 field2'>string</span> - Enter text for the description field of the embed.", 
            "<span class='px-2 py-1 field1'>thumbnail:</span><span class='px-2 py-1 field2'>string</span> - Enter a image url for the thumbnail field of the embed.", 
            "<span class='px-2 py-1 field1'>image:</span><span class='px-2 py-1 field2'>string</span> - Enter a image url for the image field of the embed.", 
            "<span class='px-2 py-1 field1'>footer:</span><span class='px-2 py-1 field2'>string</span> - Enter text for the footer field of the embed.",
            "<span class='px-2 py-1 field1'>fields:</span><span class='px-2 py-1 field2'>arguments</span> - Enter arguments to add fields to the embed. Syntax example: [field title, field text, inline true/false\]"],
            description2: "Create a discord embed message. An embed allows you better formatting options than normal discord messages. Note regarding fields: You can fit 3 inline fields in one row. If you choose to not make it inline, it will be the only field in that row.",
            selection: " ",
            examples: ["/embed create <span class='px-2 py-1 field1'>title:</span><span class='px-2 py-1 field2'>This is the title of my embed.</span> <span class='px-2 py-1 field1'>description:</span><span class='px-2 py-1 field2'>This is the description of my embed.</span> <span class='px-2 py-1 field1'>image:</span><span class='px-2 py-1 field2'>https://imgur.com/image.png</span>",
                       "/embed create <span class='px-2 py-1 field1'>title:</span><span class='px-2 py-1 field2'>This is the title of my embed.</span> <span class='px-2 py-1 field1'>fields:</span><span class='px-2 py-1 field2'>[my field title, my field text, true]</span>"],
            },
            {
            name: "/embed show",
            description: "Show an overview of your saved embeds.",
            permissions: ["Admin", "Manage Server", "Manager", "Assistant"],
            options: [], 
            selection: " ",
            examples: ["/embed show"],
          }],
          },
           {
            category: "General",
            name: "/reactionrole",
            shortDescription: "Create a reaction role message for users to assign roles to themselves.",
            permissions: ["Admin", "Manage Server", "Manager"],
            subCommands: [{
            name: "/reactionrole reactions",
            description: "Roles are given when a member reacts to the message.",
            permissions: ["Admin", "Manage Server", "Manager"],
            options: [
            "<span class='px-2 py-1 field1'>emotes:</span><span class='px-2 py-1 field2'>emotes</span> - Enter the emotes for the reaction roles.",
            "<span class='px-2 py-1 field1'>roles:</span><span class='px-2 py-1 field2'>roles</span> - Tag the roles that will be given. The order relates to the order of the emotes.",
            "<span class='px-2 py-1 field1'>remove_on_unreact:</span><span class='px-2 py-1 field2'>boolean</span> - Removing a reaction will cause the role to be removed too.",
            "<span class='px-2 py-1 field1'>text:</span><span class='px-2 py-1 field2'>string</span> - Enter text that will be displayed on the message.",
            "<span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>string</span> - Enter the id of a message to apply the reaction roles to instead of a new message."],
            selection: " ",
            examples: ["/reactionrole reactions <span class='px-2 py-1 field1'>emotes:</span><span class='px-2 py-1 field2'><img class='mb-n1' height='18px' width='18px' src='https://cdn.discordapp.com/emojis/579532030086217748.png'></img> <img class='mb-n1' height='18px' width='18px' src='https://cdn.discordapp.com/emojis/579532030161977355.png'></img> <img class='mb-n1' height='18px' width='18px' src='https://cdn.discordapp.com/emojis/579532029901799437.png'></img></span> <span class='px-2 py-1 field1'>roles:</span><span class='px-2 py-1 field2'><span style='color: yellow; background-color: #f4f80214; padding: 2px; border-radius: 3px;'>@Rogue</span> <span style='color: lightblue; background-color: #a191f914; padding: 2px; border-radius: 3px;'>@Mage</span> <span style='color: white; background-color: #ffffff14; padding: 2px; border-radius: 3px;'>@Priest</span></span> <span class='px-2 py-1 field1'>text:</span><span class='px-2 py-1 field2'>Click a reaction to receive your role!</span>"],
            },
            {
            name: "/reactionrole buttons",
            description: "Roles are given when a member clicks a button on the message.",
            permissions: ["Admin", "Manage Server", "Manager"],
            options: [
            "<span class='px-2 py-1 field1'>roles:</span><span class='px-2 py-1 field2'>roles</span> - Tag the roles that will be given.",
            "<span class='px-2 py-1 field1'>labels:</span><span class='px-2 py-1 field2'>string</span> - Enter the button labels separated by comma. The order relates to the order of the roles.",
            "<span class='px-2 py-1 field1'>text:</span><span class='px-2 py-1 field2'>string</span> - Enter text that will be displayed on the message.",
            "<span class='px-2 py-1 field1'>remove_unselected:</span><span class='px-2 py-1 field2'>boolean</span> - Members can only have one role from the set, the others will be removed.",
            "<span class='px-2 py-1 field1'>remove_on_reclick:</span><span class='px-2 py-1 field2'>boolean</span> - Clicking a button a second time will remove the role.",
            "<span class='px-2 py-1 field1'>unselect_option:</span><span class='px-2 py-1 field2'>boolean</span> - Add an unselect option for members to remove their roles again."],
            selection: " ",
            examples: ["/reactionrole buttons <span class='px-2 py-1 field1'>roles:</span><span class='px-2 py-1 field2'><span style='color: yellow; background-color: #f4f80214; padding: 2px; border-radius: 3px;'>@Rogue</span> <span style='color: lightblue; background-color: #a191f914; padding: 2px; border-radius: 3px;'>@Mage</span> <span style='color: white; background-color: #ffffff14; padding: 2px; border-radius: 3px;'>@Priest</span></span> <span class='px-2 py-1 field1'>text:</span><span class='px-2 py-1 field2'>Click a button to receive your role!</span> <span class='px-2 py-1 field1'>unselect_option:</span><span class='px-2 py-1 field2'>True</span>"],
            },
            {
            name: "/reactionrole dropdown",
            description: "Roles are given when a member selects from a dropdown menu.",
            permissions: ["Admin", "Manage Server", "Manager"],
            options: [
            "<span class='px-2 py-1 field1'>roles:</span><span class='px-2 py-1 field2'>roles</span> - Tag the roles that will be given.",
            "<span class='px-2 py-1 field1'>labels:</span><span class='px-2 py-1 field2'>string</span> - Enter the button labels separated by comma. The order relates to the order of the roles.",
            "<span class='px-2 py-1 field1'>text:</span><span class='px-2 py-1 field2'>string</span> - Enter text that will be displayed on the message.",
            "<span class='px-2 py-1 field1'>remove_unselected:</span><span class='px-2 py-1 field2'>boolean</span> - Members can only have one role from the set, the others will be removed.",
            "<span class='px-2 py-1 field1'>unselect_option:</span><span class='px-2 py-1 field2'>boolean</span> - Add an unselect option for members to remove their roles again.",
            "<span class='px-2 py-1 field1'>max_limit:</span><span class='px-2 py-1 field2'>number</span> - The maximum amount of roles a member can pick at once.",
            "<span class='px-2 py-1 field1'>min_limit:</span><span class='px-2 py-1 field2'>number</span> - The minimum amount of roles a member has to pick at once."],

            selection: " ",
            examples: ["/reactionrole dropdown <span class='px-2 py-1 field1'>roles:</span><span class='px-2 py-1 field2'><span style='color: yellow; background-color: #f4f80214; padding: 2px; border-radius: 3px;'>@Rogue</span> <span style='color: lightblue; background-color: #a191f914; padding: 2px; border-radius: 3px;'>@Mage</span> <span style='color: white; background-color: #ffffff14; padding: 2px; border-radius: 3px;'>@Priest</span></span> <span class='px-2 py-1 field1'>text:</span><span class='px-2 py-1 field2'>Rogue, Mage, Priest</span> <span class='px-2 py-1 field1'>text:</span><span class='px-2 py-1 field2'>Choose your role!</span> <span class='px-2 py-1 field1'>unselect_option:</span><span class='px-2 py-1 field2'>True</span>"],
            },

            ],
          },
          {
            category: "General",
            name: "/timer",
            shortDescription: "Create a timer in your server.",
            permissions: ["Admin", "Manage Server"],
            subCommands: [{
            name: "/timer create",
            description: "Set the title emote of this template. This is optional.",
            permissions: ["Admin", "Manage Server"],
            options: [ 
            "<span class='px-2 py-1 field1'>type:</span><span class='px-2 py-1 field2'>choice</span> - Select whether you want a channel or message timer.",
            "<span class='px-2 py-1 field1'>date:</span><span class='px-2 py-1 field2'>string</span> - Date for the timer to count down to. Format: dd-MM-yyyy | Example: 20-10-2022",
            "<span class='px-2 py-1 field1'>time:</span><span class='px-2 py-1 field2'>string</span> - Time of day for the timer to count down to. Format: HH:mm or hh:mm a | Example: 20:00",
            "<span class='px-2 py-1 field1'>interval:</span><span class='px-2 py-1 field2'>number</span> - Time in hours the timer will reset to once finished. Enter 0 to not repeat the timer.",
            "<span class='px-2 py-1 field1'>text:</span><span class='px-2 py-1 field2'>string</span> - Text that will be put before the timer in the channel name or as the title in the timer message."],
            selection: " ",
            examples: ["/timer create <span class='px-2 py-1 field1'>type:</span><span class='px-2 py-1 field2'>channel</span> <span class='px-2 py-1 field1'>date:</span><span class='px-2 py-1 field2'>24-12-2022</span> <span class='px-2 py-1 field1'>time:</span><span class='px-2 py-1 field2'>20:15</span> <span class='px-2 py-1 field1'>interval:</span><span class='px-2 py-1 field2'>0</span> <span class='px-2 py-1 field1'>text:</span><span class='px-2 py-1 field2'>Christmas:</span>"],
            description2: " "
            },
           {
            name: "/timer overview",
            description: "Show all the timers in your server.",
            permissions: ["Admin", "Manage Server"],
            options: [],
            selection: " ",
            examples: ["/custom overview"],
            description2: " "},
            ]
          },
                    
          {
            category: "General",
            name: "/item",
            shortDescription: "Search for a TBC or Classic WoW item.",
            permissions: [],
            subCommands: [{

            name: "/item",
            options: [
            "<span class='px-2 py-1 field1'>search:</span><span class='px-2 py-1 field2'>string</span> - Enter a search term or item name.",
            "<span class='px-2 py-1 field1'>version:</span><span class='px-2 py-1 field2'>choice</span> - The database you want to search."],
            selection: " ",
            description: " ",
            examples: ["/item <span class='px-2 py-1 field1'>search:</span><span class='px-2 py-1 field2'>thunderfury</span>"],
            },
            ],
          },
          {
            category: "General",
            name: "/roll",
            shortDescription: "Roll the dice!",
            permissions: [],
            subCommands: [{
            name: "/roll",
            options: [
            "<span class='px-2 py-1 field1'>limit:</span><span class='px-2 py-1 field2'>number</span> - The maximum limit of this roll. 100 is the default.",
            "<span class='px-2 py-1 field1'>amount:</span><span class='px-2 py-1 field2'>number</span> - How often you want to roll the dice (1-25)."],
            selection: " ",
            description: " ",
            examples: ["/roll <span class='px-2 py-1 field1'>limit:</span><span class='px-2 py-1 field2'>6</span>"],
            }],
          },
          {
            category: "General",
            name: "/dkp",
            shortDescription: "Manipulate or show the DKP of members.",
            permissions: ["Admin", "Manage Server", "Manager"],
            subCommands: [
            {
            name: "/dkp show",
            description: "Show the dkp list for your server.",
            options: [],
            selection: " ",
            examples: ["/dkp show"],
            },
            {
            name: "/dkp add",
            description: "Add a flat amount of dkp to the target selection.",
            permissions: ["Admin", "Manage Server", "Manager"],
            options: [
            "<span class='px-2 py-1 field1'>value:</span><span class='px-2 py-1 field2'>number</span> - Enter a value.",
            "<span class='px-2 py-1 field1'>target:</span><span class='px-2 py-1 field2'>string</span> - Tag members/roles or provide an event id.",
            "<span class='px-2 py-1 field1'>description:</span><span class='px-2 py-1 field2'>string</span> - Enter a description."],
            selection: " ",
            examples: ["/dkp add <span class='px-2 py-1 field1'>value:</span><span class='px-2 py-1 field2'>50</span> <span class='px-2 py-1 field1'>target:</span><span class='px-2 py-1 field2'><span style='color: #b32ba9; background-color: #b32ba914; padding: 2px; border-radius: 3px;'>@Raider</span> <span style='color: #739422; background-color: #73942217; padding: 2px; border-radius: 3px;'>@Member</span></span> <span class='px-2 py-1 field1'>description:</span><span class='px-2 py-1 field2'>For the Black Temple run.</span>"],
            },
            {
            name: "/dkp set",
            description: "Set the dkp of the target selection to the specified value.",
            permissions: ["Admin", "Manage Server", "Manager"],
            options: [
            "<span class='px-2 py-1 field1'>value:</span><span class='px-2 py-1 field2'>number</span> - Enter a value.",
            "<span class='px-2 py-1 field1'>target:</span><span class='px-2 py-1 field2'>string</span> - Tag members/roles or provide an event id.",
            "<span class='px-2 py-1 field1'>description:</span><span class='px-2 py-1 field2'>string</span> - Enter a description."],
            selection: " ",
            examples: ["/dkp set <span class='px-2 py-1 field1'>value:</span><span class='px-2 py-1 field2'>0</span> <span class='px-2 py-1 field1'>target:</span><span class='px-2 py-1 field2'><span style='color: #b32ba9; background-color: #b32ba914; padding: 2px; border-radius: 3px;'>@Raider</span> <span style='color: #739422; background-color: #73942217; padding: 2px; border-radius: 3px;'>@Member</span></span> <span class='px-2 py-1 field1'>description:</span><span class='px-2 py-1 field2'>For the Black Temple run.</span>"],
            },
            {
            name: "/dkp split",
            description: "Split a dkp value evenly among the target selection.",
            permissions: ["Admin", "Manage Server", "Manager"],
            options: [
            "<span class='px-2 py-1 field1'>value:</span><span class='px-2 py-1 field2'>number</span> - Enter a value.",
            "<span class='px-2 py-1 field1'>target:</span><span class='px-2 py-1 field2'>string</span> - Tag members/roles or provide an event id.",
            "<span class='px-2 py-1 field1'>description:</span><span class='px-2 py-1 field2'>string</span> - Enter a description."],
            selection: " ",
            examples: ["/dkp split <span class='px-2 py-1 field1'>value:</span><span class='px-2 py-1 field2'>1000</span> <span class='px-2 py-1 field1'>target:</span><span class='px-2 py-1 field2'><span style='color: #b32ba9; background-color: #b32ba914; padding: 2px; border-radius: 3px;'>@Raider</span> <span style='color: #739422; background-color: #73942217; padding: 2px; border-radius: 3px;'>@Member</span></span> <span class='px-2 py-1 field1'>description:</span><span class='px-2 py-1 field2'>For the Black Temple run.</span>"],
            },
            {
            name: "/dkp multiply",
            description: "Multiply the dkp of the target selection with the specified value.",
            permissions: ["Admin", "Manage Server", "Manager"],
            options: [
            "<span class='px-2 py-1 field1'>value:</span><span class='px-2 py-1 field2'>number</span> - Enter a value.",
            "<span class='px-2 py-1 field1'>target:</span><span class='px-2 py-1 field2'>string</span> - Tag members/roles or provide an event id.",
            "<span class='px-2 py-1 field1'>description:</span><span class='px-2 py-1 field2'>string</span> - Enter a description."],
            selection: " ",
            examples: ["/dkp multiply <span class='px-2 py-1 field1'>value:</span><span class='px-2 py-1 field2'>0.8</span> <span class='px-2 py-1 field1'>target:</span><span class='px-2 py-1 field2'><span style='color: #b32ba9; background-color: #b32ba914; padding: 2px; border-radius: 3px;'>@Raider</span> <span style='color: #739422; background-color: #73942217; padding: 2px; border-radius: 3px;'>@Member</span></span> <span class='px-2 py-1 field1'>description:</span><span class='px-2 py-1 field2'>For the Black Temple run.</span>"],
            },
            ],
          },
          {
            category: "Event",
            name: "/create",
            shortDescription: "Start the event creation dialogue.",
            permissions: ["Admin", "Manage Server", "Manager", "Assistant"],
            subCommands: [{
            options: [],
            examples: ["/create"],

            selection: " ",
            description: "Start the event creation dialogue. The bot will send you a DM and ask you to enter relevant event info. You can also create events via the dashboard or the /quickcreate command."}],
          },

          {
            category: "Event",
            name: "/quickcreate",
            shortDescription: "Create an event with a single command.",
            permissions: ["Admin", "Manage Server", "Manager", "Assistant"],
            subCommands: [{
              arguments: true,
              options: [
              "<span class='px-2 py-1 field1'>arguments:</span><span class='px-2 py-1 field2'>string</span> - Enter arguments to set specific attributes.",
            ],
            examples: ["/quickcreate new <span class='px-2 py-1 field1'>arguments:</span><span class='px-2 py-1 field2'>[title: Naxxramas] [channel: <span style='color: white; background-color: #4c4adc45; padding: 2px; border-radius: 3px;'>#officer-chat</span>] [date: 24-12-2022] [time: 22:00]</span>",
            "/quickcreate new <span class='px-2 py-1 field1'>arguments:</span><span class='px-2 py-1 field2'>[title: Meeting] [announcement: meetings, 60, Meet in 60 minutes!]</span> ",
            "/quickcreate new <span class='px-2 py-1 field1'>arguments:</span><span class='px-2 py-1 field2'>[title: Guild Roster] [advanced: &#60; show_info: false &#62; &#60; limit_per_user: 3 &#62;]</span> "],

            selection: " ",
            description: "Create a new event with a single command. Provide arguments to edit settings of the event."}],
          },

          {
            category: "Event",
            name: "/quickcreate_from_profile",
            shortDescription: "Create an event based on a saved profile.",
            permissions: ["Admin", "Manage Server", "Manager", "Assistant"],
            subCommands: [{
              arguments: true,
              options: [
              "<span class='px-2 py-1 field1'>profile:</span><span class='px-2 py-1 field2'>string</span> - Select one of the servers saved profiles.",
              "<span class='px-2 py-1 field1'>date:</span><span class='px-2 py-1 field2'>string</span> - Enter the events date.",
              "<span class='px-2 py-1 field1'>time:</span><span class='px-2 py-1 field2'>string</span> - Enter the events time.",
              "<span class='px-2 py-1 field1'>arguments:</span><span class='px-2 py-1 field2'>string</span> - Enter arguments to manually set specific attributes.",
            ],
            examples: ["/quickcreate_from_profile <span class='px-2 py-1 field1'>profile:</span><span class='px-2 py-1 field2'>mysavedprofile1</span> <span class='px-2 py-1 field1'>date:</span><span class='px-2 py-1 field2'>20-10-2024</span> <span class='px-2 py-1 field1'>time:</span><span class='px-2 py-1 field2'>19:45</span>",
            "/quickcreate_from_profile <span class='px-2 py-1 field1'>profile:</span><span class='px-2 py-1 field2'>mysavedprofile2</span> <span class='px-2 py-1 field1'>date:</span><span class='px-2 py-1 field2'>31-12-2024</span> <span class='px-2 py-1 field1'>time:</span><span class='px-2 py-1 field2'>20:30</span> <span class='px-2 py-1 field1'>arguments:</span><span class='px-2 py-1 field2'>[title: Meeting]</span> "],
 

            selection: " ",
            description: "Create a new event with a single command. The data from the profile will be applied. To create profiles use the /create command and enter all the settings before you select 'Save Profile' at the end."}],
          },

          {
            category: "Event",
            name: "/edit",
            shortDescription: "Edit the properties of a posted or scheduled event.",
            permissions: ["Admin", "Manage Server", "Manager"],
            subCommands: [{
            name: "/edit event",
            options: ["<span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>number</span> - Enter the id of a posted event."],
            permissions: ["Admin", "Manage Server", "Manager"],
            selection: " ",
            examples: ["/edit event", "/edit event <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>896918167807467540</span>"],
            description: "Edit the properties of a posted event.",
            description2:
              "This command can be used to edit a posted event. The id field is an auto-complete field and will suggest you posted events depending on your input. You can enter the id of the posted event or the title, date, time or leaders name to get fitting results. You can also right-click the event itself and navigate to Apps -> Edit as a shortcut for this command.",
                        },
                        {
            name: "/edit schedule",
            options: ["<span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>number</span> - Enter the id of a scheduled event event."],
            permissions: ["Admin", "Manage Server", "Manager"],
            selection: " ",
            examples: ["/edit schedule <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>896918167807467540</span>"],
            description: "Edit the properties of a scheduled event.",
            description2:
            "This command can be used to edit a scheduled event. Editing a scheduled event will affect all events that are yet to be posted from this scheduled event. The id field is an auto-complete field and will suggest you scheduled events depending on your input. You can enter the id of the scheduled event, title or leaders name to get fitting results. You can also use the /schedule command to get an overview of your scheduled events and select which one you want to edit.",

                        }],
          },

          {
            category: "Event",
            name: "/close",
            shortDescription: "Close an event. Sign-Ups will be declined.",
            permissions: ["Admin", "Manage Server", "Manager"],
            subCommands: [{
            options: ["<span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>number</span> - Enter the id of an event.", "<span class='px-2 py-1 field1'>message:</span><span class='px-2 py-1 field2'>string</span> - Enter text that will be sent as a DM to all signed up members."],
            selection: " ",
            examples: ["/close <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>896918167807467540</span>", "/close <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>896918167807467540</span> <span class='px-2 py-1 field1'>message:</span><span class='px-2 py-1 field2'>This event is cancelled due to bad weather!</span>"],
            description:
              "Close an open event. Members won't be able to sign up to the event anymore. You can still add and remove members or edit the event."}],
          },
          {
            category: "Event",
            name: "/open",
            shortDescription: "Open a closed event. Sign-Ups will be accepted again.",
            permissions: ["Admin", "Manage Server", "Manager"],
            subCommands: [{
            options: ["<span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>number</span> - Enter the id of an event."],
            selection: " ",
            examples: ["/open <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>896918167807467540</span>"],
            description:
              "Open a closed event. Members will be able to sign up to the event again."}],
          },
          {
            category: "Event",
            name: "/add",
            shortDescription: "Add a member to an event.",
            permissions: ["Admin", "Manage Server", "Manager"],
            subCommands: [{
            options: ["<span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>number</span> - Enter the id of an event.", "<span class='px-2 py-1 field1'>role:</span><span class='px-2 py-1 field2'>string</span> - Enter role the member will be added as.", "<span class='px-2 py-1 field1'>member:</span><span class='px-2 py-1 field2'>mention</span> - Tag a member to add to the event.", "<span class='px-2 py-1 field1'>position:</span><span class='px-2 py-1 field2'>number</span> - Override the position of the added user.", "<span class='px-2 py-1 field1'>notification:</span><span class='px-2 py-1 field2'>option</span> - Send a notification to the added member.", "<span class='px-2 py-1 field1'>reason:</span><span class='px-2 py-1 field2'>string</span> - Enter a reason that will be sent with the notification.", "<span class='px-2 py-1 field1'>fake:</span><span class='px-2 py-1 field2'>boolean</span> - Create a fake user instead of checking against the servers member list for a match."],
            selection: " ",
            examples:
              ["/add <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>896918167807467540</span> <span class='px-2 py-1 field1'>role:</span><span class='px-2 py-1 field2'>Rogue</span> <span class='px-2 py-1 field1'>member:</span><span class='px-2 py-1 field2'><span style='color: white; background-color: #4c4adc45; padding: 2px; border-radius: 3px; font-weight: 400;'>@Mitmoc</span></span>", "/add <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>896918167807467540</span> <span class='px-2 py-1 field1'>role:</span><span class='px-2 py-1 field2'>Assassination</span> <span class='px-2 py-1 field1'>member:</span><span class='px-2 py-1 field2'><span style='color: white; background-color: #4c4adc45; padding: 2px; border-radius: 3px; font-weight: 400;'>@Mitmoc</span> <span style='color: white; background-color: #4c4adc45; padding: 2px; border-radius: 3px; font-weight: 400;'>@Azzieh</span></span>",
               "/add <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>896918167807467540</span> <span class='px-2 py-1 field1'>role:</span><span class='px-2 py-1 field2'>Arcane</span> <span class='px-2 py-1 field1'>member:</span><span class='px-2 py-1 field2'>Mitmoc</span> <span class='px-2 py-1 field1'>notification:</span><span class='px-2 py-1 field2'>True</span>",
               "/add <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>896918167807467540</span> <span class='px-2 py-1 field1'>role:</span><span class='px-2 py-1 field2'>Survival</span> <span class='px-2 py-1 field1'>member:</span><span class='px-2 py-1 field2'>Faker</span> <span class='px-2 py-1 field1'>fake:</span><span class='px-2 py-1 field2'>True</span>",
               "/add <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>896918167807467540</span> <span class='px-2 py-1 field1'>role:</span><span class='px-2 py-1 field2'>Tank--Dragonhunter</span> <span class='px-2 py-1 field1'>member:</span><span class='px-2 py-1 field2'><span style='color: white; background-color: #4c4adc45; padding: 2px; border-radius: 3px; font-weight: 400;'>@Mitmoc</span></span> <span class='px-2 py-1 field1'>reason:</span><span class='px-2 py-1 field2'>We need more tanks</span>"],
            description:
              "Add a user to an event. For templates where a spec is to be specified, you can use the spec directly instead of the role. The emote name of the spec is to be used. For templates where a spec can belong to more than 1 class, you can combine the class and the spec with --, see last example. You can also just provide the class name and then reply to the bots inquiry about the spec."}],
          },
          {
            category: "Event",
            name: "/remove",
            shortDescription: "Remove a member from the event.",
            permissions: ["Admin", "Manage Server", "Manager"],
            subCommands: [{
            options: ["<span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>number</span> - Enter the id of an event.", "<span class='px-2 py-1 field1'>member:</span><span class='px-2 py-1 field2'><span style='color: white; background-color: #4c4adc45; padding: 2px; border-radius: 3px; font-weight: 400;'>@Mitmoc</span></span> - Tag a member to remove from the event.", "<span class='px-2 py-1 field1'>notification:</span><span class='px-2 py-1 field2'>option</span> - Send a notification to the removed member.", "<span class='px-2 py-1 field1'>reason:</span><span class='px-2 py-1 field2'>string</span> - Enter a reason that will be sent with the notification."],
            arguments: " ",
            selection: " ",
            examples:
              ["/remove <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>896918167807467540</span> <span class='px-2 py-1 field1'>member:</span><span class='px-2 py-1 field2'><span style='color: white; background-color: #4c4adc45; padding: 2px; border-radius: 3px; font-weight: 400;'>@Mitmoc</span></span>", "/remove <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>896918167807467540</span> <span class='px-2 py-1 field1'>member:</span><span class='px-2 py-1 field2'><span style='color: white; background-color: #4c4adc45; padding: 2px; border-radius: 3px; font-weight: 400;'>@Mitmoc</span> <span style='color: white; background-color: #4c4adc45; padding: 2px; border-radius: 3px; font-weight: 400;'>@Azzieh</span></span>",
              "/remove <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>896918167807467540</span> <span class='px-2 py-1 field1'>member:</span><span class='px-2 py-1 field2'>Mitmoc</span> <span class='px-2 py-1 field1'>reason:</span><span class='px-2 py-1 field2'>We are overbooked, sorry!</span>", "/remove <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>896918167807467540</span> <span class='px-2 py-1 field1'>member:</span><span class='px-2 py-1 field2'>Mitmoc, Azzieh</span>",
              "/remove <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>896918167807467540</span> <span class='px-2 py-1 field1'>member:</span><span class='px-2 py-1 field2'>7</span>", "/remove <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>896918167807467540</span> <span class='px-2 py-1 field1'>member:</span><span class='px-2 py-1 field2'>7, 11</span>"],
            description:
              "Removes the member from the specified event. You can use the plain name, mention the member or use the order number. You can chain multiple members by separating them with a comma.",
                                  }],
          },
          {
            category: "Event",
            name: "/move",
            shortDescription: "Move the specified event to the current channel.",
            permissions: ["Admin", "Manage Server", "Manager"],
            subCommands: [{
            options: ["<span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>number</span> - Enter the id of an event."],
            arguments: " ",
            selection: " ",
            examples: ["/move <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>896918167807467540</span>"],
            description: " "}],
          },
          {
            category: "Event",
            name: "/sort",
            shortDescription: "Sort events in this channel chronologically.",
            permissions: ["Admin", "Manage Server", "Manager"],
            subCommands: [{
            options: ["<span class='px-2 py-1 field1'>amount:</span><span class='px-2 py-1 field2'>number</span> - Amount of events to sort. Default and maximum is 25, premium servers can go up to 50.",
                      "<span class='px-2 py-1 field1'>reverse:</span><span class='px-2 py-1 field2'>boolean</span> - Reverse the sort order."],
            arguments: " ",
            selection: " ",
            examples: ["/sort", "/sort <span class='px-2 py-1 field1'>reverse:</span><span class='px-2 py-1 field2'>True</span>"],
            description: " "}],
          },
          {
            category: "Event",
            name: "/move",
            shortDescription: "Move the specified event to the current channel.",
            permissions: ["Admin", "Manage Server", "Manager"],
            subCommands: [{
            options: ["<span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>number</span> - Enter the id of an event."],
            arguments: " ",
            selection: " ",
            examples: ["/move <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>896918167807467540</span>"],
            description: " "}],
          },
          {
            category: "Event",
            name: "/copy",
            shortDescription: "Copy an event or the Sign-Ups of an event.",
            permissions: ["Admin", "Manage Server", "Manager", "Assistant"],
            subCommands: [{
            name: "/copy event",
            arguments: true,
            description: "Copy an event to the current channel.",
            permissions: ["Admin", "Manage Server", "Manager", "Assistant"],
            options: ["<span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>number</span> - Enter the id of an event.", "<span class='px-2 py-1 field1'>arguments:</span><span class='px-2 py-1 field2'>string</span> - Enter creation arguments to apply to the new event."],
            arguments: " ",
            selection: " ",
            examples: ["/copy event <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>896918167807467540</span>", "/copy event <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>896918167807467540</span> <span class='px-2 py-1 field1'>arguments:</span><span class='px-2 py-1 field2'>[date: 20-10-2022] [time: 20:00]</span>", "/copy event <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>896918167807467540</span> <span class='px-2 py-1 field1'>arguments:</span><span class='px-2 py-1 field2'>[advanced: 	&#60;deadline: 24&#62;] [title: Wednesday Raid v2]</span>"],
            description2: "Copying an event will create a new and empty event with the event settings of the original event. Like the /quickcreate command, you can set creation arguments to override specific setting. The date will be incremented by 7 days unless further specified."
            },
            {
            name: "/copy signups",
            description: "Copy Sign-Ups from one event to another.",
            permissions: ["Admin", "Manage Server", "Manager"],
            options: ["<span class='px-2 py-1 field1'>source:</span><span class='px-2 py-1 field2'>string</span> - Enter the id of an event.", "<span class='px-2 py-1 field1'>target:</span><span class='px-2 py-1 field2'>number</span> - Enter the id of an event."],
            arguments: " ",
            selection: " ",
            examples: ["/copy signups <span class='px-2 py-1 field1'>source:</span><span class='px-2 py-1 field2'>896918167807467540</span> <span class='px-2 py-1 field1'>target:</span><span class='px-2 py-1 field2'>897248343435132989</span>"],
          }],
          },

          {
            category: "Event",
            name: "/recover",
            shortDescription: "Recover a deleted event or missing reactions from an existing one.",
            permissions: ["Admin", "Manage Server", "Manager"],
            subCommands: [{
            name: "/recover event",
            options: ["<span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>number</span> - Enter the id of an event."],
            permissions: ["Admin", "Manage Server", "Manager"],
            arguments: " ",
            selection: " ",
            examples: ["/recover event <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>896918167807467540</span>"],
            description:
              "Recover a deleted event.",
                                  },
                                  {
                                                name: "/recover reactions",
            options: ["<span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>number</span> - Enter the id of an event."],
            permissions: ["Admin", "Manage Server", "Manager"],
            arguments: " ",
            selection: " ",
            examples: ["/recover reactions <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>896918167807467540</span>"],
            description:
              "Recover missing reactions of an existing event.",
                                  }],
          },
          {
            category: "Event",
            name: "/delete",
            shortDescription: "Delete events via command.",
            permissions: ["Admin", "Manage Server"],
            subCommands: [{
            options: ["<span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>number</span> - Enter the id of an event.", "<span class='px-2 py-1 field1'>channel:</span><span class='px-2 py-1 field2'>mention</span> - Tag a channel to delete events from.", "<span class='px-2 py-1 field1'>server:</span><span class='px-2 py-1 field2'>boolean</span> - Delete all events from the server.", "<span class='px-2 py-1 field1'>scheduled:</span><span class='px-2 py-1 field2'>boolean</span> - Delete all scheduled and recurring events."],
            arguments: " ",
            selection: " ",
            examples: ["/delete <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>896918167807467540</span>", "/delete <span class='px-2 py-1 field1'>channel:</span><span class='px-2 py-1 field2'><span style='color: white; background-color: #4c4adc45; padding: 2px; border-radius: 3px;'>#events</span></span>"],
            description: "If you want to delete a specific event, just deleting the message is sufficient. Use this command if you want to bulk delete events from a channel or server."}],
          },
          {
            category: "Event",
            name: "/signed",
            shortDescription: "Send a message or list all signed members.",
            permissions: ["Admin", "Manage Server", "Manager"],
            subCommands: [
            {
            name: "/signed list",
            description: "List the Sign-Ups of this event.",
            permissions: ["Admin", "Manage Server", "Manager"],
            options: ["<span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>number</span> - Enter the id of an event.", "<span class='px-2 py-1 field1'>option:</span><span class='px-2 py-1 field2'>choice</span> - Pick an option from the list."],
            arguments: " ",
            selection: " ",
            examples: ["/signed list <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>896918167807467540</span> <span class='px-2 py-1 field1'>option:</span><span class='px-2 py-1 field2'>list by class</span>"],
            description2: "This command will list signed up members. You can also use the shortcut context menu by right-clicking the event > Apps > Signed to list all signed users (sorted by class)."
            },
            {
            name: "/signed message",
            description: "Send a message to members that are signed for this event.",
            permissions: ["Admin", "Manage Server", "Manager"],
            options: ["<span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>number</span> - Enter the id of an event.", "<span class='px-2 py-1 field1'>option:</span><span class='px-2 py-1 field2'>choice</span> - Pick an option from the list.", "<span class='px-2 py-1 field1'>text:</span><span class='px-2 py-1 field2'>string</span> - Enter the text you want to be sent.", "<span class='px-2 py-1 field1'>include:</span><span class='px-2 py-1 field2'>choice</span> - Specify further who exactly should be messaged or pinged."],
            arguments: " ",
            selection: " ",
            examples: ["/signed message <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>896918167807467540</span> <span class='px-2 py-1 field1'>option:</span><span class='px-2 py-1 field2'>direct message</span> <span class='px-2 py-1 field1'>text:</span><span class='px-2 py-1 field2'>Raid started, come online!</span>"],
            description2: "This command will message signed up members."
            },
            ],
          },
          {
            category: "Event",
            name: "/unsigned",
            shortDescription: "Send a message or list all unsigned members.",
            permissions: ["Admin", "Manage Server", "Manager"],
            subCommands: [
            {
            name: "/unsigned list",
            description: "List members that are not signed for this event and have a raider role.",
            permissions: ["Admin", "Manage Server", "Manager"],
            options: ["<span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>number</span> - Enter the id of an event.", "<span class='px-2 py-1 field1'>option:</span><span class='px-2 py-1 field2'>choice</span> - Pick an action from the list.", "<span class='px-2 py-1 field1'>raider_roles:</span><span class='px-2 py-1 field2'>mentions</span> - Override the currently set member pool with alternative raider roles."],
            arguments: " ",
            selection: " ",
            examples: ["/unsigned list <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>896918167807467540</span> <span class='px-2 py-1 field1'>option:</span><span class='px-2 py-1 field2'>list by class</span>", "/unsigned list <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>896918167807467540</span> <span class='px-2 py-1 field1'>option:</span><span class='px-2 py-1 field2'>list by class</span> <span class='px-2 py-1 field1'>raider_roles:</span><span class='px-2 py-1 field2'><span style='color: #b32ba9; background-color: #b32ba914; padding: 2px; border-radius: 3px;'>@Raider</span></span>"],
            description2: "This command will list all unsigned members in the raider pool. You have to build the raider pool via /set raider_roles or have an allowed role set on the event. You can also use the raider_roles override option on this command to build a raider pool."
            },
            {
            name: "/unsigned message",
            description: "Send a message to members that are not signed for this event and have a raider role.",
            permissions: ["Admin", "Manage Server", "Manager"],
            options: ["<span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>number</span> - Enter the id of an event.", "<span class='px-2 py-1 field1'>option:</span><span class='px-2 py-1 field2'>choice</span> - Pick an action from the list.", "<span class='px-2 py-1 field1'>raider_roles:</span><span class='px-2 py-1 field2'>mentions</span> - Override the currently set member pool with alternative raider roles.", "<span class='px-2 py-1 field1'>text:</span><span class='px-2 py-1 field2'>string</span> - Enter text to be sent if you picked a message option."],
            arguments: " ",
            selection: " ",
            examples: ["/unsigned list <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>896918167807467540</span> <span class='px-2 py-1 field1'>option:</span><span class='px-2 py-1 field2'>direct message</span> <span class='px-2 py-1 field1'>text:</span><span class='px-2 py-1 field2'>Don't forget to sign up!</span>", "/unsigned list <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>896918167807467540</span> <span class='px-2 py-1 field1'>option:</span><span class='px-2 py-1 field2'>message to channel and ping</span> <span class='px-2 py-1 field1'>text:</span><span class='px-2 py-1 field2'>Don't forget to sign up!</span>"],
            description2: "This command will message all unsigned members in the raider pool. You have to build the raider pool via /set raider_roles or have an allowed role set on the event. You can also use the raider_roles override option on this command to build a raider pool."
            },
            ],
          },
          {
            category: "Event",
            name: "/attendance",
            shortDescription: "Fetch attendance statistics of your servers events.",
            permissions: ["Admin", "Manage Server", "Manager"],
            subCommands: [{
            options: ["<span class='px-2 py-1 field1'>start:</span><span class='px-2 py-1 field2'>date</span> - Query only the events that started after this date.", 
                      "<span class='px-2 py-1 field1'>end:</span><span class='px-2 py-1 field2'>date</span> - Query only the events that started before this date.",
                      "<span class='px-2 py-1 field1'>tag:</span><span class='px-2 py-1 field2'>string</span> - Query only the events with the provided attendance tag.", 
                      "<span class='px-2 py-1 field1'>channels:</span><span class='px-2 py-1 field2'>mention</span> - Mention channels to limit the query to events from those channes.",
                      "<span class='px-2 py-1 field1'>since_join:</span><span class='px-2 py-1 field2'>boolean</span> - Calculates the percentage of attendance from events after the user joined the server.",
                      "<span class='px-2 py-1 field1'>reset:</span><span class='px-2 py-1 field2'>boolean</span> - Set attendance to false on all existing events.",
                      "<span class='px-2 py-1 field1'>include:</span><span class='px-2 py-1 field2'>choice</span> - Specify further which Sign-Ups to include into the statistic.",
                      "<span class='px-2 py-1 field1'>roles:</span><span class='px-2 py-1 field2'>boolean</span> - Mention channels to limit the query to events from those channes.",
                      "<span class='px-2 py-1 field1'>formatting:</span><span class='px-2 py-1 field2'>boolean</span> - Mention roles to limit the query to members with at least one of those roles."],
            arguments: " ",
            selection: " ",
            examples: ["/attendance", "/attendance <span class='px-2 py-1 field1'>start:</span><span class='px-2 py-1 field2'>24-12-2022</span> <span class='px-2 py-1 field1'>end:</span><span class='px-2 py-1 field2'>31-06-2023</span>", "/attendance <span class='px-2 py-1 field1'>tag:</span><span class='px-2 py-1 field2'>20mans</span>", "/attendance <span class='px-2 py-1 field1'>reset:</span><span class='px-2 py-1 field2'>True</span>"],
            description:
              "Show the attendance stats of your server. Includes only finished events that haven't been deleted and have their attendance advanced setting not set to false. You can reset the stats with the reset option (this will edit all events on your server and set the attendance advanced setting to false). If you have a custom tag set for the attendance in the advanced settings, you can show the attendance for events with that tag by using the tag option.",
                                  }],
          },
          {
            category: "Event",
            name: "/export",
            shortDescription: "Export events to a CSV file.",
            permissions: ["Admin", "Manage Server", "Manager"],
            subCommands: [{
            options: ["<span class='px-2 py-1 field1'>start:</span><span class='px-2 py-1 field2'>date</span> - Enter the start date for this query. Format: dd-MM-yyyy | Example: 20-10-2022", 
                      "<span class='px-2 py-1 field1'>end:</span><span class='px-2 py-1 field2'>date</span> - Enter the end date for this query. Format: dd-MM-yyyy | Example: 20-10-2022", 
                      "<span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>number</span> - Enter the id of an event."],
            arguments: " ",
            selection: " ",
            examples: ["/export", "/export <span class='px-2 py-1 field1'>start:</span><span class='px-2 py-1 field2'>01-01-2022</span> <span class='px-2 py-1 field1'>end:</span><span class='px-2 py-1 field2'>31-12-2022</span>", "/export <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>897341215907926018</span>"],
            description:
              "Export events to a CSV file."}],
          },
          {
            category: "Premium",
            name: "/verify",
            shortDescription: "Verify your premium code on this server.",
            permissions: [],
            subCommands: [{
            options: ["<span class='px-2 py-1 field1'>code:</span><span class='px-2 py-1 field2'>string</span> - Enter the premium code that you received via e-mail."],
            arguments: " ",
            selection: " ",
            examples: ["/verify <span class='px-2 py-1 field1'>code:</span><span class='px-2 py-1 field2'>RH10-jP3CU-mLhV9-37vpw-5mk4r-gMk4EW</span>"],
            description: " ",
                                  }],
          },
          {
            category: "Premium",
            name: "/reminders",
            shortDescription: "Set your personal event reminders.",
            permissions: [],
            subCommands: [{
            options: ["<span class='px-2 py-1 field1'>status:</span><span class='px-2 py-1 field2'>choice</span> - Enable or disable your reminders.", "<span class='px-2 py-1 field1'>time:</span><span class='px-2 py-1 field2'>number</span> - Enter how many minutes before an events starting time you want to receive a reminder."],
            arguments: " ",
            selection: " ",
            examples: ["/reminders <span class='px-2 py-1 field1'>status:</span><span class='px-2 py-1 field2'>Enable</span> <span class='px-2 py-1 field1'>time:</span><span class='px-2 py-1 field2'>30</span>"],
            description:
              "Open the reminder settings. Reminders are individual to each user, they'll be sent at the specified time before an event."}],
          },
          {
            category: "Premium",
            name: "/schedule",
            shortDescription: "Show an overview of scheduled events in your server.",
            permissions: ["Admin", "Manage Server", "Manager"],
            subCommands: [{
            options: [],
            arguments: " ",
            selection: " ",
            examples: ["/schedule"],
            description:
              "This command will show you an overview of your scheduled events and allow you to edit them."}],
          },
          {
            category: "Premium",
            name: "/mirror",
            shortDescription: "Mirror an event.",
            permissions: ["Admin", "Manage Server", "Manager"],
            subCommands: [{
            options: ["<span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>number</span> - Enter the id of an event.", "<span class='px-2 py-1 field1'>mirrorkey:</span><span class='px-2 py-1 field2'>string</span> - Enter the /mirrorkey of the source server if this is a different server."],
            arguments: " ",
            selection: " ",
            examples:
              ["/mirror <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>897347487218696192</span>", "/mirror <span class='px-2 py-1 field1'>id:</span><span class='px-2 py-1 field2'>897347487218696192</span> <span class='px-2 py-1 field1'>mirrorkey:</span><span class='px-2 py-1 field2'>mysecretpw</span>"],
            description:
              "Mirror the provided event, synchronizing all event parameters and Sign-Ups between the source event and all its mirrored events. If used on a different server than the provided event, you have to additionally enter the server password of the source event. The password can be set by an admin with the /mirrorkey command. You can have up to 5 mirrors per event."}],
          },
          {
            category: "Premium",
            name: "/custom",
            shortDescription: "Set emotes to the custom templates.",
            permissions: ["Admin", "Manage Server", "Manager"],
            subCommands: [{
            name: "/custom title",
            description: "Set the title emote of this template. This is optional.",
            permissions: ["Admin", "Manage Server", "Manager"],
            options: ["<span class='px-2 py-1 field1'>template:</span><span class='px-2 py-1 field2'>choice</span> - Pick one of the available custom templates.", "<span class='px-2 py-1 field1'>title:</span><span class='px-2 py-1 field2'>emote</span> - Enter an emote."],
            arguments: " ",
            selection: " ",
            examples: ["/custom title <span class='px-2 py-1 field1'>template:</span><span class='px-2 py-1 field2'>17</span> <span class='px-2 py-1 field1'>title:</span><span class='px-2 py-1 field2'><img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/869104112048934932.png'></img></span>"],
            description2: "The title is set as an emote. The emote name will be shown as the template name and the emote itself as the template icon."
            },
           {
            name: "/custom classes",
            description: "Set the classes of this template. These will be the reactions of the event. This is required.",
            permissions: ["Admin", "Manage Server", "Manager"],
            options: ["<span class='px-2 py-1 field1'>template:</span><span class='px-2 py-1 field2'>choice</span> - Pick one of the available custom templates.", "<span class='px-2 py-1 field1'>classes:</span><span class='px-2 py-1 field2'>emotes</span> - Enter up to 20 emotes to set as classes to this template.", "<span class='px-2 py-1 field1'>option:</span><span class='px-2 py-1 field2'>option</span> - Choose whether to overwrite or append to the existing set."],
            arguments: " ",
            selection: " ",
            examples: ["/custom classes <span class='px-2 py-1 field1'>template:</span><span class='px-2 py-1 field2'>17</span> <span class='px-2 py-1 field1'>classes:</span><span class='px-2 py-1 field2'><img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/579532030086217748.png'></img> <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/579532030161977355.png'></img> <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/579532029901799437.png'></img> <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/579532030153588739.png'></img> <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/579532029851336716.png'></img> <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/579532029880827924.png'></img> <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/579532029675438081.png'></img> <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/579532030056857600.png'></img> <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/579532029906124840.png'></img>"],
            description2: "This is required for the template to be functional. If no classes are set, that means no reactions will be applied to your event."},
           {
            name: "/custom specs",
            description: "Set specs to choose from when signing to a class. This is optional.",
            permissions: ["Admin", "Manage Server", "Manager"],
            options: ["<span class='px-2 py-1 field1'>template:</span><span class='px-2 py-1 field2'>choice</span> - Pick one of the available custom templates.", "<span class='px-2 py-1 field1'>class:</span><span class='px-2 py-1 field2'>emote</span> - Enter the emote of one of the classes set to this template.", "<span class='px-2 py-1 field1'>specs:</span><span class='px-2 py-1 field2'>emotes</span> - Enter up to 30 emotes to set as specs to this class.", "<span class='px-2 py-1 field1'>option:</span><span class='px-2 py-1 field2'>option</span> - Choose whether to overwrite or append to the existing set."],
            arguments: " ",
            selection: " ",
            examples: ["/custom specs <span class='px-2 py-1 field1'>template:</span><span class='px-2 py-1 field2'>17</span> <span class='px-2 py-1 field1'>class:</span><span class='px-2 py-1 field2'><img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/579532030161977355.png'></img></span> <span class='px-2 py-1 field1'>specs:</span><span class='px-2 py-1 field2'><img class='mb-n1' height='18px' width='18px' src='https://cdn.discordapp.com/emojis/637564231545389056.png'></img> <img class='mb-n1' height='18px' width='18px' src='https://cdn.discordapp.com/emojis/637564231239073802.png'></img> <img class='mb-n1' height='18px' width='18px' src='https://cdn.discordapp.com/emojis/637564231469891594.png'></img></span>", "/custom specs <span class='px-2 py-1 field1'>template:</span><span class='px-2 py-1 field2'>17</span> <span class='px-2 py-1 field1'>class:</span><span class='px-2 py-1 field2'><img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/579532029675438081.png'></img></span> <span class='px-2 py-1 field1'>specs:</span><span class='px-2 py-1 field2'><img class='mb-n1' height='18px' width='18px' src='https://cdn.discordapp.com/emojis/637564172007112723.png'></img> <img class='mb-n1' height='18px' width='18px' src='https://cdn.discordapp.com/emojis/637564172061900820.png'></img> <img class='mb-n1' height='18px' width='18px' src='https://cdn.discordapp.com/emojis/637564171994529798.png'></img> <img class='mb-n1' height='18px' width='18px' src='https://cdn.discordapp.com/emojis/637564171696734209.png'></img></span>"],
            description2: "If you have specs set for a class, the user signing up will be sent a DM with a list of the specs to choose from. If no spec is set to a class, this step is skipped and the signup happens immediately."
            },
           {
            name: "/custom role",
            description: "Set a role and which classes/specs to count towards it. This is optional.",
            permissions: ["Admin", "Manage Server", "Manager"],
            options: ["<span class='px-2 py-1 field1'>template:</span><span class='px-2 py-1 field2'>choice</span> - Pick one of the available custom templates.", "<span class='px-2 py-1 field1'>role:</span><span class='px-2 py-1 field2'>emote</span> - Enter an emote to set as a role. This is a counter for how many members signed to it.", "<span class='px-2 py-1 field1'>parts:</span><span class='px-2 py-1 field2'>emotes</span> - Enter emotes of classes or specs that will be counted towards this role.", "<span class='px-2 py-1 field1'>option:</span><span class='px-2 py-1 field2'>option</span> - Choose whether to overwrite or append to the existing set."],
            arguments: " ",
            selection: " ",
            examples: ["/custom role <span class='px-2 py-1 field1'>template:</span><span class='px-2 py-1 field2'>17</span> <span class='px-2 py-1 field1'>role:</span><span class='px-2 py-1 field2'><img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/878310168289505301.png'></img></span> <span class='px-2 py-1 field1'>parts:</span><span class='px-2 py-1 field2'><img class='mb-n1' height='18px' width='18px' src='https://cdn.discordapp.com/emojis/637564171696734209.png'></img> <img class='mb-n1' height='18px' width='18px' src='https://cdn.discordapp.com/emojis/637564444834136065.png'></img> <img class='mb-n1' height='18px' width='18px' src='https://cdn.discordapp.com/emojis/637564297647489034.png'></img></span>", "/custom role <span class='px-2 py-1 field1'>template:</span><span class='px-2 py-1 field2'>17</span> <span class='px-2 py-1 field1'>role:</span><span class='px-2 py-1 field2'><img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/592438128057253898.png'></img></span> <span class='px-2 py-1 field1'>parts:</span><span class='px-2 py-1 field2'><img class='mb-n1' height='18px' width='18px' src='https://cdn.discordapp.com/emojis/606343445803696157.png'></img> <img class='mb-n1' height='18px' width='18px' src='https://cdn.discordapp.com/emojis/637564379847458846.png'></img> <img class='mb-n1' height='18px' width='18px' src='https://cdn.discordapp.com/emojis/606344274472206337.png'></img> <img class='mb-n1' height='18px' width='18px' src='https://cdn.discordapp.com/emojis/637564297622454272.png'></img></span>"],
            description2: "The roles are shown above the signups and below the info row (date and time etc.) on an event. They serve as a counter and don't have any impact on the signup process for the end user, if you don't set a signup limit for the role."
            },
            {
            name: "/custom show",
            description: "Show the current setup of a custom template.",
            permissions: ["Admin", "Manage Server", "Manager"],
            options: ["<span class='px-2 py-1 field1'>template:</span><span class='px-2 py-1 field2'>choice</span> - Pick one of the available custom templates.", "<span class='px-2 py-1 field1'>option:</span><span class='px-2 py-1 field2'>choice</span> - Pick an option."],
            arguments: " ",
            selection: " ",
            examples: ["/custom show <span class='px-2 py-1 field1'>template:</span><span class='px-2 py-1 field2'>17</span> <span class='px-2 py-1 field1'>option:</span><span class='px-2 py-1 field2'>classes&specs</span>", "/custom show <span class='px-2 py-1 field1'>template:</span><span class='px-2 py-1 field2'>18</span> <span class='px-2 py-1 field1'>option:</span><span class='px-2 py-1 field2'>roles</span>"],
            },
            {
            name: "/custom export",
            description: "Export a custom template to be able to share it with others.",
            permissions: ["Admin", "Manage Server", "Manager"],
            options: ["<span class='px-2 py-1 field1'>template:</span><span class='px-2 py-1 field2'>choice</span> - Pick one of the available custom templates.", "<span class='px-2 py-1 field1'>identifier:</span><span class='px-2 py-1 field2'>string</span> - Enter a unique identifier for this template."],
            arguments: " ",
            selection: " ",
            examples: ["/custom export <span class='px-2 py-1 field1'>template:</span><span class='px-2 py-1 field2'>17</span> <span class='px-2 py-1 field1'>identifier:</span><span class='px-2 py-1 field2'>BDO</span>", "/custom export <span class='px-2 py-1 field1'>template:</span><span class='px-2 py-1 field2'>18</span> <span class='px-2 py-1 field1'>identifier:</span><span class='px-2 py-1 field2'>Archeage2</span>"],
            },
            {
            name: "/custom import",
            description: "Import a saved custom template.",
            permissions: ["Admin", "Manage Server", "Manager"],
            options: ["<span class='px-2 py-1 field1'>template:</span><span class='px-2 py-1 field2'>choice</span> - Pick one of the available custom templates.", "<span class='px-2 py-1 field1'>identifier:</span><span class='px-2 py-1 field2'>string</span> - Enter the identifier of a saved template to import it."],
            arguments: " ",
            selection: " ",
            examples: ["/custom import <span class='px-2 py-1 field1'>template:</span><span class='px-2 py-1 field2'>17</span> <span class='px-2 py-1 field1'>identifier:</span><span class='px-2 py-1 field2'>BDO</span>", "/custom import <span class='px-2 py-1 field1'>template:</span><span class='px-2 py-1 field2'>18</span> <span class='px-2 py-1 field1'>identifier:</span><span class='px-2 py-1 field2'>Archeage2</span>"],
            },
            {
            name: "/custom remove",
            description: "Remove one of your own saved templates.",
            permissions: ["Admin", "Manage Server", "Manager"],
            options: ["<span class='px-2 py-1 field1'>identifier:</span><span class='px-2 py-1 field2'>string</span> - Enter the identifier of one of your saved templates to remove it."],
            arguments: " ",
            selection: " ",
            examples: ["/custom remove <span class='px-2 py-1 field1'>identifier:</span><span class='px-2 py-1 field2'>BDO</span>", "/custom remove <span class='px-2 py-1 field1'>identifier:</span><span class='px-2 py-1 field2'>Archeage2</span>"],
            },
            {
            name: "/custom reset",
            description: "Reset a custom template or parts of it.",
            permissions: ["Admin", "Manage Server", "Manager"],
            options: ["<span class='px-2 py-1 field1'>template:</span><span class='px-2 py-1 field2'>choice</span> - Pick one of the available custom templates.", "<span class='px-2 py-1 field1'>option:</span><span class='px-2 py-1 field2'>choice</span> - Pick an option."],
            arguments: " ",
            selection: " ",
            examples: ["/custom reset <span class='px-2 py-1 field1'>template:</span><span class='px-2 py-1 field2'>17</span> <span class='px-2 py-1 field1'>option:</span><span class='px-2 py-1 field2'>all</span>", "/custom reset <span class='px-2 py-1 field1'>template:</span><span class='px-2 py-1 field2'>18</span> <span class='px-2 py-1 field1'>option:</span><span class='px-2 py-1 field2'>title</span>"],
            }],
          },
          {
            category: "Premium",
            name: "/overview",
            shortDescription: "Show an overview of upcoming events.",
            permissions: ["Admin", "Manage Server", "Manager"],
            subCommands: [{
            name: "/overview server",
            options: ["<span class='px-2 py-1 field1'>type:</span><span class='px-2 py-1 field2'>choice</span> - Choose an option."],
            arguments: " ",
            selection: " ",
            examples: ["/overview <span class='px-2 py-1 field1'>type:</span><span class='px-2 py-1 field2'>server</span>"],
            description: "Show an overview of upcoming events in the current server.",
            permissions: ["Admin", "Manage Server", "Manager"],
            description2:
              "Posts an overview of the upcoming events on your server. If you have premium, this display will update automatically each time an event changes. If you do not have premium, this will just make a snapshot without further updating the message if changes happen.",
            },
            {
            name: "/overview personal",
            options: ["<span class='px-2 py-1 field1'>type:</span><span class='px-2 py-1 field2'>choice</span> - Choose an option."],
            arguments: " ",
            selection: " ",
            examples: ["/overview <span class='px-2 py-1 field1'>type:</span><span class='px-2 py-1 field2'>server</span>"],
            description: "Show an overview of upcoming events you are signed for across all your servers.",
            description2:
              "Posts an overview of events you are signed for across all servers. This overview is static and will not update automatically, so you'll have to run the command again whenever you want an up-to-date overview.",
                                  }],
          },

          {

            category: "Setting",
            name: "/settings",
            shortDescription: "Change the current server settings.",
            permissions: ["Admin", "Manage Server"],
            subCommands: [
            
            {
            name: "/settings show",
            description: "Show the current server settings.",
            permissions: [],
            options: [],
            arguments: " ",
            selection: " ",
            examples: ["/settings show"],
            },
                        {
            name: "/settings templates",
            description: "Show the template list of this server.",
            permissions: ["Admin", "Manage Server"],
            options: [],
            arguments: " ",
            selection: " ",
            description2: "You can enable and disable templates. Disabled templates can't be selected when creating events. If only one template is enabled, it will be used by default.",
            examples: ["/settings templates"],
            },
            {
            name: "/settings language set",
            description: "Set the bots language for this server.",
            permissions: ["Admin", "Manage Server"],
            options: ["<span class='px-2 py-1 field1'>language:</span><span class='px-2 py-1 field2'>choice</span> - Select an option."],
            arguments: " ",
            selection: " ",
            examples: ["/settings language set <span class='px-2 py-1 field1'>language:</span><span class='px-2 py-1 field2'>German</span>"],
            },
            {
            name: "/settings timezone set",
            description: "Set the bots timezone for this server.",
            permissions: ["Admin", "Manage Server"],
            options: ["<span class='px-2 py-1 field1'>timezone:</span><span class='px-2 py-1 field2'>choice</span> - Select an option.", "<span class='px-2 py-1 field1'>offset:</span><span class='px-2 py-1 field2'>choice</span> - Select an option."],
            arguments: " ",
            selection: " ",
            description2: "The timezone is an important setting for the bot to be able to accurately parse date and time inputs when you create or edit events.",
            examples: ["/settings timezone set <span class='px-2 py-1 field1'>timezone:</span><span class='px-2 py-1 field2'>Central Europe - Berlin</span>", "/settings timezone set <span class='px-2 py-1 field1'>offset:</span><span class='px-2 py-1 field2'>GMT-8</span>"],
            },
            {
            name: "/settings manager_role set",
            description: "Set the manager role. Managers can create and edit events.",
            permissions: ["Admin", "Manage Server"],
            options: ["<span class='px-2 py-1 field1'>roles:</span><span class='px-2 py-1 field2'>mentions</span> - Mention roles you want to act as managers.", "<span class='px-2 py-1 field1'>whitelist:</span><span class='px-2 py-1 field2'>mentions</span> - Mention channels you want to restrict the manager to. This is optional."],
            arguments: " ",
            selection: " ",
            description2: "The manager role can be set and given to users that you want to be able to use most of the bots functions, like creating and editing all events. To restrict the managers to specific channels, you can enter whitelist channels in the whitelist option.",
            examples: ["/settings manager_role set <span class='px-2 py-1 field1'>roles:</span><span class='px-2 py-1 field2'><span style='color: yellow; background-color: #a191f914; padding: 2px; border-radius: 3px;'>@Officer</span></span> <span class='px-2 py-1 field1'>whitelist:</span><span class='px-2 py-1 field2'><span style='color: white; background-color: #4c4adc45; padding: 2px; border-radius: 3px;'>#events</span></span>"],
            },
            {
            name: "/settings assistant_role set",
            description: "Set the assistant role. Assistants can create and edit their own events.",
            permissions: ["Admin", "Manage Server"],
            options: ["<span class='px-2 py-1 field1'>roles:</span><span class='px-2 py-1 field2'>mentions</span> - Mention roles you want to act as assistants.", "<span class='px-2 py-1 field1'>whitelist:</span><span class='px-2 py-1 field2'>mentions</span> - Mention channels you want to restrict the assistant to. This is optional."],
            arguments: " ",
            selection: " ",
            description2: "The assistant role can be set and given to users that you want to be able to use a limited amount of the bots functions, like creating and only editing their own events. To restrict the assistants to specific channels, you can enter whitelist channels in the whitelist option.",
            examples: ["/settings assistant set <span class='px-2 py-1 field1'>roles:</span><span class='px-2 py-1 field2'><span style='color: lightblue; background-color: #a191f914; padding: 2px; border-radius: 3px;'>@Raider</span></span> <span class='px-2 py-1 field1'>whitelist:</span><span class='px-2 py-1 field2'><span style='color: white; background-color: #4c4adc45; padding: 2px; border-radius: 3px;'>#pug-events</span></span>"],
            },
            {
            name: "/settings raider_roles set",
            description: "Set the raider roles. These roles dictate the pool of players for the unsigned keyword.",
            permissions: ["Admin", "Manage Server"],
            options: ["<span class='px-2 py-1 field1'>roles:</span><span class='px-2 py-1 field2'>mentions</span> - Mention roles to be set as raider roles."],
            arguments: " ",
            selection: " ",
            description2: "The raider roles are used to populate a pool of players that is required for the /unsigned command and unsigned functions. All users with one or multiple raider roles will be in that pool of players.",
            examples: ["/settings raider_roles set <span class='px-2 py-1 field1'>roles:</span><span class='px-2 py-1 field2'><span style='color: yellow; background-color: #a191f914; padding: 2px; border-radius: 3px;'>@Officer</span> <span style='color: lightblue; background-color: #a191f914; padding: 2px; border-radius: 3px;'>@Raider</span> <span style='color: green; background-color: #a191f914; padding: 2px; border-radius: 3px;'>@Member</span></span>"],
            },
            {
            name: "/settings leader_channel set",
            description: "Set the leader channel. This channel will receive notifications about created events.",
            permissions: ["Admin", "Manage Server"],
            options: ["<span class='px-2 py-1 field1'>channel:</span><span class='px-2 py-1 field2'>mention</span> - Select a channel."],
            arguments: " ",
            selection: " ",
            description2: "The leader channel will receive notifications about event creations.",
            examples: ["/settings leader_channel set <span class='px-2 py-1 field1'>channel:</span><span class='px-2 py-1 field2'><span style='color: white; background-color: #4c4adc45; padding: 2px; border-radius: 3px;'>#officer-chat</span></span>"],
            },
            {
            name: "/settings logging_channel set",
            description: "Set the logging channel. This channel will receive event related logging messages.",
            permissions: ["Admin", "Manage Server"],
            options: ["<span class='px-2 py-1 field1'>channel:</span><span class='px-2 py-1 field2'>mention</span> - Select a channel."],
            arguments: " ",
            selection: " ",
            description2: "The logging channel will receive notifications about Sign-Up changes and any kind of edits done to your servers events. This is a premium feature.",
            examples: ["/settings logging_channel set <span class='px-2 py-1 field1'>channel:</span><span class='px-2 py-1 field2'><span style='color: white; background-color: #4c4adc45; padding: 2px; border-radius: 3px;'>#bot-logs</span></span>"],
            },
            {
            name: "/settings notification_channel set",
            description: "Set the notification channel. This channel will receive absence/cancel notifications.",
            permissions: ["Admin", "Manage Server"],
            options: ["<span class='px-2 py-1 field1'>channel:</span><span class='px-2 py-1 field2'>mention</span> - Select a channel."],
            arguments: " ",
            selection: " ",
            description2: "If a notification channel is set and a user signs up with one of the default roles (Bench, Late, Tentative, Absence) or cancels on a raid comp, the bot will inquire about a reason. The user can reply to that and the answer will be sent to the notification channel.",
            examples: ["/settings notification_channel set <span class='px-2 py-1 field1'>channel:</span><span class='px-2 py-1 field2'><span style='color: white; background-color: #4c4adc45; padding: 2px; border-radius: 3px;'>#absences</span></span>"],
            },
            {
            name: "/settings notification_roles set",
            description: "Set the notification roles. These roles will trigger the user to provide a reason.",
            permissions: ["Admin", "Manage Server"],
            options: ["<span class='px-2 py-1 field1'>bench:</span><span class='px-2 py-1 field2'>boolean</span> - Select an option.", "<span class='px-2 py-1 field1'>late:</span><span class='px-2 py-1 field2'>boolean</span> - Select an option.", "<span class='px-2 py-1 field1'>tentative:</span><span class='px-2 py-1 field2'>boolean</span> - Select an option.", "<span class='px-2 py-1 field1'>absence:</span><span class='px-2 py-1 field2'>boolean</span> - Select an option.", "<span class='px-2 py-1 field1'>cancelled:</span><span class='px-2 py-1 field2'>boolean</span> - Select an option.", ],
            arguments: " ",
            selection: " ",
            description2: "If a notification channel is set and a user signs up with one of the default roles, the bot will inquire about a reason. This command allows you to disable this for individual roles.",
            examples: ["/settings notification_roles set <span class='px-2 py-1 field1'>bench:</span><span class='px-2 py-1 field2'>False</span>"],
            },
            {
            name: "/settings notification_settings set",
            description: "Set the notification settings.",
            permissions: ["Admin", "Manage Server"],
            options: ["<span class='px-2 py-1 field1'>leader:</span><span class='px-2 py-1 field2'>boolean</span> - Send the notification via DM to the leader of the event instead.", "<span class='px-2 py-1 field1'>required:</span><span class='px-2 py-1 field2'>boolean</span> - Determine whether it is required to provide a reason to sign up.",],
            arguments: " ",
            selection: " ",
            description2: "If a notification channel is set and a user signs up with one of the default roles, the bot will inquire about a reason. You can set the response to be sent to the leader of the event instead and you can make it a requirement for a successful Sign-Up.",
            examples: ["/settings notification_settings set <span class='px-2 py-1 field1'>leader:</span><span class='px-2 py-1 field2'>True</span> <span class='px-2 py-1 field1'>required:</span><span class='px-2 py-1 field2'>True</span>"],
            },
            {
            name: "/settings dkp_channel set",
            description: "Set the dkp channel. This channel will receive dkp related logging messages.",
            permissions: ["Admin", "Manage Server"],
            options: ["<span class='px-2 py-1 field1'>channel:</span><span class='px-2 py-1 field2'>mention</span> - Select a channel."],
            arguments: " ",
            selection: " ",
            description2: "If a dkp channel is set and dkp is being manipulated, this channel will receive info messages and serve as a log.",
            examples: ["/settings dkp_channel set <span class='px-2 py-1 field1'>channel:</span><span class='px-2 py-1 field2'><span style='color: white; background-color: #4c4adc45; padding: 2px; border-radius: 3px;'>#dkp-log</span></span>"],
            },
            {
            name: "/settings archive_channel set",
            description: "Set the archive channel for your server. Events will be archived to this channel.",
            permissions: ["Admin", "Manage Server"],
            options: ["<span class='px-2 py-1 field1'>channel:</span><span class='px-2 py-1 field2'>mention</span> - Select a channel.", "<span class='px-2 py-1 field1'>time:</span><span class='px-2 py-1 field2'>number</span> - The time in hours that events will be archived after they have ended."],
            arguments: " ",
            selection: " ",
            description2: "If an archive channel is set, finished events will be moved to this channel at the specified time after their conclusion. This only affects events created after the archive channel was set. The archive time is set in hours. This is a premium feature.",
            examples: ["/settings archive_channel set <span class='px-2 py-1 field1'>channel:</span><span class='px-2 py-1 field2'><span style='color: white; background-color: #4c4adc45; padding: 2px; border-radius: 3px;'>#bot-logs</span></span> <span class='px-2 py-1 field1'>time:</span><span class='px-2 py-1 field2'>24</span>"],
            },
            {
            name: "/settings archive_time set",
            description: "Set the archive time for your server. Events will be archived after the specified time past their conclusion.",
            permissions: ["Admin", "Manage Server"],
            options: ["<span class='px-2 py-1 field1'>time:</span><span class='px-2 py-1 field2'>number</span> - The time in hours that events will be archived after they have concluded."],
            arguments: " ",
            selection: " ",
            description2: "If an archive channel is set, finished events will be moved to this channel at the specified time after their conclusion. This only affects events created after the archive channel was set. The archive time is set in hours. This is a premium feature.",
            examples: ["/settings archive_time set <span class='px-2 py-1 field1'>time:</span><span class='px-2 py-1 field2'><span style='color: white; background-color: #4c4adc45; padding: 2px; border-radius: 3px;'>24</span></span> <span class='px-2 py-1 field1'>time:</span><span class='px-2 py-1 field2'>24</span>"],
            },
            {
            name: "/settings remove_reactions set",
            description: "Set the reaction behaviour on successful Sign-Up.",
            permissions: ["Admin", "Manage Server"],
            options: ["<span class='px-2 py-1 field1'>option:</span><span class='px-2 py-1 field2'>choice</span> - Select an option."],
            arguments: " ",
            selection: " ",
            examples: ["/settings remove_reactions set <span class='px-2 py-1 field1'>option:</span><span class='px-2 py-1 field2'>Remove from events and polls</span>"],
            },
            {
            name: "/settings date_format set",
            description: "Set the date format for your server.",
            permissions: ["Admin", "Manage Server"],
            options: ["<span class='px-2 py-1 field1'>display:</span><span class='px-2 py-1 field2'>choice</span> - Select the format in which zoned time will be displayed on events."],
            arguments: " ",
            selection: " ",
            examples: ["/settings date_format set <span class='px-2 py-1 field1'>display:</span><span class='px-2 py-1 field2'>13/October/2021</span>"],
            },
            ],
          },
          {
            category: "Setting",
            name: "/advanced",
            shortDescription: "View and edit this servers default advanced settings.",
            permissions: ["Admin", "Manage Server", "Manager"],
            subCommands: [{
            name: "/advanced show",
            permissions: [],
            options: [],
            arguments: " ",
            selection: " ",
            examples: ["/advanced show"],
            description: "Show the current advanced settings for this server.",
            },
            {
            name: "/advanced set",
            permissions: ["Admin", "Manage Server", "Manager"],
            options: ["<span class='px-2 py-1 field1'>arguments:</span><span class='px-2 py-1 field2'>string</span> - Enter changes to the default advanced settings."],
            arguments: " ",
            selection: " ",
            examples: ["/advanced <span class='px-2 py-1 field1'>arguments:</span><span class='px-2 py-1 field2'>&#60; deadline: 24 &#62; &#60; limit: 25 &#62;</span>"],
            description: "Set the advanced settings for this server",
            description2:
              "Set the default advanced settings for your server. These will be applied to all new events you create. You can still override those settings when you create an event by changing the advanced settings of the event directly. This does not affect already created events or recurring events. <a href='https://raid-helper.dev/documentation/advanced' target='_blank'> <span style='color:#99cc00'>Click here</span></a> for detailed descriptions of the available advanced settings."
            },
            {
            name: "/advanced reset",  
            permissions: ["Admin", "Manage Server", "Manager"],
            options: [],
            arguments: " ",
            selection: " ",
            examples: ["/advanced reset"],
            description:
              "Reset the advanced settings for this server."},
            ],
          
            },
      ],
    };
    
  },
  created(){
    document.title = "Raid-Helper - Commands Documentation"
  },
  mounted() {
   
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods:{
    onResize() {
      this.isMobile2 = window.innerWidth;
  }

  }
    
};
</script>
<style >
.theme--dark.v-expansion-panels .v-expansion-panel{
  transition: 0s !important;
  background-color: red !important;
}

.v-expansion-panels {
  border-radius: 0 !important;
}

.vcard-templates:hover {
  background-color: rgb(51, 51, 58) !important;
}

.accent {
  color: white !important;
}

p {
  color:white;
}

.v-slide-group__next, .v-slide-group__prev{
  min-width: 0px !important; 
  flex: 0 1 0px !important;
}

.v-expansion-panels .v-expansion-panel {
    background-color: #ed1818 !important;
}

.panel {
  opacity: 1 !important;
  border-left: 2px solid darkgrey;
}

.field1 {
  background-color: #181a1c; 
  color: #dcd0d0; 
  border: 2px solid #212327; 
  border-right: 0px; 
  border-top-left-radius: 4px; 
  border-bottom-left-radius: 4px;
}

.field2 {
  background-color: #292b2f; 
  color: #adb1b6; 
  border: 2px solid #212327; 
  border-left: 0px; 
  border-top-right-radius: 4px; 
  border-bottom-right-radius: 4px;
}

.field3 {
  background-color: #232529; 
  color: #adb1b6; 
  border-radius: 3px;
}

.v-expansion-panel:before { box-shadow: none !important; }

</style>