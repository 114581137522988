var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"border-radius":"0px"},attrs:{"color":"#3b3e45","elevation":"0"}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("\n      Form Profiles\n    ")]),_c('v-card-text',{staticClass:"pt-1 px-6"},[_vm._v("\n          You can save your current form into a profile and load it later. The profiles are saved, so that other managers of this server can use them as well.\n    ")]),_c('v-card-actions',{staticClass:"pa-0 pl-3 pr-1 pb-3"},[_c('v-col',{staticClass:"mx-n1"},[_c('v-card',{staticClass:"mt-n3",attrs:{"color":"#2f3136","elevation":"0"}},[_c('v-card-title',{staticClass:"mx-1"},[_vm._v("\n      Save Profile\n    ")]),_c('v-row',[_c('v-col',{},[_c('v-text-field',{staticClass:"px-5",attrs:{"outlined":"","dense":"","placeholder":"Enter a profile name.","background-color":"secondary","flat":""},model:{value:(_vm.profileName),callback:function ($$v) {_vm.profileName=$$v},expression:"profileName"}})],1)],1),_c('v-tooltip',{attrs:{"top":"","disabled":_vm.$store.state.permissionLevel === 'admin' || _vm.$store.state.permissionLevel === 'manager'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-inline-block"},on),[_c('v-btn',{staticClass:"mb-5 mx-5 green",attrs:{"elevation":"0","disabled":_vm.profileNameIsEntered() === false || (_vm.$store.state.permissionLevel !== 'admin' && _vm.$store.state.permissionLevel !== 'manager'),"color":"green"},on:{"click":function($event){return _vm.saveProfile()}}},[_vm._v("Save")])],1)]}}])},[_c('span',[_vm._v("Requires manager role or higher.")])])],1),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mt-5",attrs:{"color":"#2f3136","elevation":"0"}},[_c('v-card-title',{staticClass:"mx-1"},[_vm._v("\n      Load Profile\n    ")]),_c('v-row',[_c('v-col',[_c('v-select',{staticClass:"px-5",attrs:{"disabled":_vm.$store.state.chosenDiscord.premium === false,"dense":"","item-color":"white","outlined":"","items":_vm.loadedProfiles,"placeholder":"Select a profile.","background-color":"secondary","flat":""},on:{"click":function($event){return _vm.getProfiles()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(item.profileName)+"\n              ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(item.profileName)+"\n              ")]}}],null,true),model:{value:(_vm.selectedProfile),callback:function ($$v) {_vm.selectedProfile=$$v},expression:"selectedProfile"}})],1)],1),_c('v-btn',{staticClass:"mb-5 mx-5",attrs:{"elevation":"0","disabled":_vm.selectedProfile === '' || _vm.$store.state.chosenDiscord.premium === false,"color":"green"},on:{"click":function($event){return _vm.applyProfile()}}},[_vm._v("Load")]),_c('v-tooltip',{attrs:{"top":"","disabled":_vm.$store.state.permissionLevel === 'admin' || _vm.$store.state.permissionLevel === 'manager'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-inline-block"},on),[(_vm.selectedProfile !== '')?_c('v-btn',{staticClass:"mb-5",attrs:{"elevation":"0","text":"","color":"red","disabled":(_vm.$store.state.permissionLevel !== 'admin' && _vm.$store.state.permissionLevel !== 'manager') || _vm.$store.state.chosenDiscord.premium === false,"ripple":false},on:{"click":function($event){return _vm.deleteProfile()}}},[_vm._v("Delete")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Requires manager role or higher.")])]),_c('v-fade-transition',[(hover && _vm.$store.state.chosenDiscord.premium === false)?_c('v-overlay',{attrs:{"z-index":0,"absolute":"","opacity":"0.8","color":"primary"}},[_c('v-btn',{attrs:{"elevation":"0","x-large":"","color":"purple"},on:{"click":function($event){_vm.$router.push('/premium/', function () {})}}},[_c('span',[_c('v-icon',{staticClass:"pb-1",attrs:{"color":"yellow"}},[_vm._v("mdi-crown-outline")]),_vm._v("  Get Premium!")],1)])],1):_vm._e()],1)],1)]}}])})],1)],1),_c('v-snackbar',{attrs:{"color":"green","elevation":"0","timeout":"4000"},model:{value:(_vm.snackbar1),callback:function ($$v) {_vm.snackbar1=$$v},expression:"snackbar1"}},[_c('span',{staticStyle:{"font-size":"15px"}},[_vm._v("Profile saved!")])]),_c('v-snackbar',{attrs:{"color":"red","elevation":"0","timeout":"4000"},model:{value:(_vm.snackbar2),callback:function ($$v) {_vm.snackbar2=$$v},expression:"snackbar2"}},[_c('span',{staticStyle:{"font-size":"15px"}},[_vm._v("Profile deleted!")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }