<template>
<v-container class="pa-1">
<v-col cols="12" class="mt-sm-n10 mt-n1 mb-sm-9 mb-4" style="background-color: #1d1d21; border-radius: 3px;">
          <v-row justify="space-between">
            <v-col>
              <div style="height: 20px;">
              
              <v-tooltip transition="v-scale-transition" color="transparent" right nudge-left="30px">
							<template v-slot:activator="{ on, attrs }">
                <v-btn elevation="0" :ripple="false" v-bind="attrs" v-on="on" small left plain class="mt-n1 ml-n3" @click="goBackEvent()" color="#484850"><v-icon size="30">mdi-arrow-left-circle</v-icon></v-btn>
              </template><span style="color: grey;">Go back</span>
						</v-tooltip>
              </div></v-col>
                        <v-spacer></v-spacer>
            <v-col v-if="selectedEvent !== undefined" :style="selectedEvent.permission ? 'cursor: pointer;' : ''" @click="selectedEvent.permission && selectedEvent.displayTitle ? [titleText = selectedEvent.title, overlay11 = true] : ''">
          <div v-if="$vuetify.breakpoint.xs" style="white-space:nowrap; color: white; font-size: 0.8em; text-align: center; height: 20px;">{{(selectedEvent.displayTitle ? truncate(selectedEvent.displayTitle) : 'Web View')}}</div>
          <div v-else-if="$vuetify.breakpoint.sm" class="mt-n1" style="white-space:nowrap; color: white; font-size: 1.3em; text-align: center; height: 20px;">{{(selectedEvent.displayTitle ? truncate1(selectedEvent.displayTitle) : 'Web View')}}</div>
          <div v-else class="mt-n1" style="white-space:nowrap; color: white; font-size: 1.3em; text-align: center; height: 20px;">{{(selectedEvent.displayTitle ? truncate2(selectedEvent.displayTitle) : 'Web View')}}</div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
              <div style="text-align: right; height: 20px;">
          						<v-tooltip transition="v-scale-transition" color="transparent" left nudge-right="30px">
							<template v-slot:activator="{ on, attrs }">
					<v-btn elevation="0" v-bind="attrs" v-on="on" class="mr-n3 mt-n2" :ripple="false" style="cursor: default;" plain>
          <v-avatar class="" width="30" height="30" min-width="30" min-height="30">
            <img :src="getServerIcon() ? getServerIcon() : 'https://cdn.discordapp.com/icons/817542418420596766/f8ec64ca464b50d9f4a80e1256cabd7f.png'" alt="DiscordAvatar" />
          </v-avatar>
          </v-btn>
              </template><span v-if="selectedEvent" style="color: grey;">{{(selectedEvent.servername ? selectedEvent.servername : '')}}</span>
						</v-tooltip>
              </div>
            </v-col>
          </v-row>
                      
        </v-col>

    <v-card class="cardFont pa-0 pt-3 mt-n3 mt-sm-n8" dark color="primary" elevation="2" v-if="isLoaded && selectedEvent.title">
            <v-hover v-slot="{ hover }">
     <div v-if="selectedEvent.permission" :style="hover === true ? 'cursor: pointer; width: 6px; height: 100%; opacity: 1; position: absolute; border-top-right-radius: 0px;  border-top-left-radius: 4px; border-bottom-left-radius: 4px; background-color: rgb(' + selectedEvent.color + ');' : 'opacity: 0.95; width: 6px; height: 100%; position: absolute; border-top-right-radius: 0px; border-top-left-radius: 4px; border-bottom-left-radius: 4px; background-color: rgb(' + selectedEvent.color + ');'" @click="overlay6 = true" class="mt-n3"></div>
     <div v-else :style="'opacity: 0.9; width: 6px; height: 100%; position: absolute; border-top-right-radius: 0px; border-top-left-radius: 4px; border-bottom-left-radius: 4px; background-color: rgb(' + selectedEvent.color + ');'" class="mt-n3"></div>
            </v-hover>

      <v-container>

    <v-row class="justify-center my-0 mx-0 mb-n3 pl-1 mt-n5">

              <v-col class="mx-0">
              <v-row class="mb-3" v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs">
              <v-col class="py-2" align="center">
            <v-row justify="center">

                <v-tooltip transition="v-scale-transition" color="#0f0f0f" top>
                  <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                <span v-bind="attrs" v-on="on" style="color: white; font-size: 1.4em; background-color: #292b2f; border-radius: 6px; white-space:nowrap; opacity: 0.9;" :style="hover && selectedEvent.permission ? 'opacity: 1; cursor: pointer;' : ''" @click="selectedEvent.permission ? [overlay1 = true, deadline = undefined] : ''" class="pa-1 px-2 mt-1">{{ new Date(selectedEvent.unixtime*1000).toLocaleDateString([],{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }}</span>
                </v-hover>    
                </template><span>Starts in: {{days}} days {{hours}} hours {{minutes}} minutes</span></v-tooltip>
                </v-row>
              <v-row justify="center">
               <v-tooltip transition="v-scale-transition" color="#0f0f0f" top>
                  <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <span v-bind="attrs" v-on="on" style="color: white; font-size: 1.4em; background-color: #292b2f; border-radius: 6px; white-space:nowrap; opacity: 0.9;" :style="hover && selectedEvent.permission ? 'opacity: 1; cursor: pointer;' : ''" @click="selectedEvent.permission ? [overlay1 = true, deadline = undefined] : ''"  class="pa-1 px-2 mt-1">{{ new Date(selectedEvent.unixtime*1000).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) }} <span style="font-weight: 400; color: darkgrey;">{{ getEndTime() }}</span></span>
              </v-hover>
                </template><span>Starts in: {{days}} days {{hours}} hours {{minutes}} minutes</span></v-tooltip>                
                </v-row>
              </v-col>

        </v-row>
        <v-row class="pl-3 mt-0 mt-sm-n2">
              <v-col class="pa-0 pt-2">

                <v-row class="pt-1">
                  <v-tooltip transition="v-scale-transition" color="#0f0f0f" top>
                  <template v-slot:activator="{ on, attrs }">
                  <v-hover v-slot="{ hover }">
                    <span v-bind="attrs" v-on="on" style="white-space: nowrap; font-size: 0.8em;" class="mt-2" :style="selectedEvent.permission && hover ? 'opacity: 1; cursor: pointer;' : 'opacity: 0.90;'" @click="selectedEvent.permission ? [overlay = true, leaderText = undefined] : ''">
                    <span style='background-color: #181a1c; color: #dcd0d0; border: 2px solid #212327; border-right: 0px; border-top-left-radius: 4px; border-bottom-left-radius: 4px;' class='pl-1 pr-6 py-1'><v-img src="https://cdn.discordapp.com/emojis/799879945123135508.png" max-height="19" max-width="20" style="display:inline-block; position: absolute;"></v-img>
                    </span>
                    <span style='background-color: #292b2f; color: #adb1b6; border: 2px solid #212327; border-left: 0px; border-top-right-radius: 4px; border-bottom-right-radius: 4px;' class='px-2 py-1 mr-3'>{{ selectedEvent.leadername }}</span>
                    </span>
                  </v-hover>
                  </template>
                  <span>Leader</span>
                  </v-tooltip>
                </v-row>
                
                 <v-row class="pt-1">
                 <v-tooltip transition="v-scale-transition" color="#0f0f0f" top>
                  <template v-slot:activator="{ on, attrs }">
                  <v-hover v-slot="{ hover }">
                    <span v-bind="attrs" v-on="on" style="white-space: nowrap; font-size: 0.8em;" class="mr-n3 mt-2" :style="selectedEvent.permission && hover ? 'opacity: 1; cursor: pointer;' : 'opacity: 0.90;'" @click="selectedEvent.permission ? [overlay8 = true] : ''">
                    <span style='background-color: #181a1c; color: #dcd0d0; border: 2px solid #212327; border-right: 0px; border-top-left-radius: 4px; border-bottom-left-radius: 4px;' class='pl-1 pr-6 py-1'><v-img src="https://cdn.discordapp.com/emojis/799893852814049310.png" max-height="19" max-width="20" style="display:inline-block; position: absolute;"></v-img>
                    </span>
            
                    <span style='background-color: #292b2f; color: #adb1b6; border: 2px solid #212327; border-left: 0px; border-top-right-radius: 4px; border-bottom-right-radius: 4px;' class='px-2 py-1 mr-3'><img :src="'https://cdn.discordapp.com/emojis/' + getChannelIconByType(selectedEvent.channelType) + '.png?v=1'" width="20" height="20" style="margin-bottom: -6px; margin-left: -5px; margin-right: 5px;" />{{ selectedEvent.channelName.replace("#", "") }}</span>
                    </span>
                  </v-hover>
                  </template>
                  <span>Channel</span>
                  </v-tooltip>
                 </v-row>
     <v-row v-if="selectedEvent.interval" class="pt-1">
       <v-hover v-slot="{ hover }">
<div style="font-size: 0.8em; display: flex; align-items: flex-start; justify-content: flex-start;" class="mt-2" :style="selectedEvent.permission && hover ? 'opacity: 1; cursor: pointer;' : 'opacity: 0.90;'" @click="selectedEvent.permission ? overlay7 = true : ''">
  <v-tooltip transition="v-scale-transition" color="#0f0f0f" top>
    <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" style='background-color: #181a1c; color: #dcd0d0; border: 2px solid #212327; border-right: 0px; border-top-left-radius: 4px; border-bottom-left-radius: 4px; cursor: pointer;' class='pl-1 pr-1 pt-1'>
          <v-img src="https://cdn.discordapp.com/emojis/785499194843725864.png" max-height="19" max-width="20" style="display:inline-block; position: relative;"></v-img>
        </span>  
    </template>
    <span>Interval</span>
  </v-tooltip> 

  <v-tooltip transition="v-scale-transition" color="#0f0f0f" top>
    <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" style='background-color: #292b2f; color: #adb1b6; border: 2px solid #212327; border-left: 0px; border-top-right-radius: 4px; border-bottom-right-radius: 4px; cursor: pointer; max-width: 240px;' class='px-2 py-1 mr-3'>{{getIntervalString()}}</span>
    </template>
    <span>{{getIntervalString()}}</span>
  </v-tooltip>
</div>
       </v-hover>


                    
                 </v-row>

                <v-row class="pt-1">
                <v-tooltip transition="v-scale-transition" color="#0f0f0f" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                    <span v-bind="attrs" v-on="on" style="white-space: nowrap; font-size: 0.8em;" class="mr-n3 mt-2" :style="selectedEvent.permission && hover ? 'opacity: 1; cursor: pointer;' : 'opacity: 0.90;'" @click="selectedEvent.permission ? [overlay22 = true] : ''">
                    <span style='background-color: #181a1c; border: 2px solid #212327; border-right: 0px; border-top-left-radius: 4px; border-bottom-left-radius: 4px;' class='pl-1 pr-6 py-1'><v-img src="https://cdn.discordapp.com/emojis/936650002271465533.png" max-height="19" max-width="19" style="display:inline-block; position: absolute;"></v-img>
                    </span>
                    <span style='background-color: #292b2f; color: #adb1b6; border: 2px solid #212327; border-left: 0px; border-top-right-radius: 4px; border-bottom-right-radius: 4px;' class='px-2 py-1 mr-3'><img v-if="selectedEvent.voice_type != undefined" :src="'https://cdn.discordapp.com/emojis/' + getChannelIconByType(selectedEvent.voice_type) + '.png?v=1'" width="20" height="20" style="margin-bottom: -6px; margin-left: -5px; margin-right: 5px;" />{{ getVoiceChannel() }}</span>
                    </span>
                 </v-hover>   
                </template><span>Voice Channel</span></v-tooltip>

                  <v-tooltip transition="v-scale-transition" color="#0f0f0f" top v-if="chosenDiscordRoles && selectedEvent.interval">
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                    <span v-bind="attrs" v-on="on" style="white-space: nowrap; font-size: 0.8em;" class="mt-2" :style="selectedEvent.permission && hover ? 'opacity: 1; cursor: pointer;' : 'opacity: 0.90;'" @click="selectedEvent.permission ? [overlay20 = true] : ''">
                    <span style='background-color: #181a1c; border: 2px solid #212327; border-right: 0px; border-top-left-radius: 4px; border-bottom-left-radius: 4px;' class='pl-1 pr-1 py-1'><v-icon size="20">mdi-at</v-icon>
                    </span>
                    <span style='background-color: #292b2f; color: #adb1b6; border: 2px solid #212327; border-left: 0px; border-top-right-radius: 4px; border-bottom-right-radius: 4px;' class='px-2 py-1 mr-3' v-html="getMentions()"></span>
                    </span>
                 </v-hover>   
                </template><span>Mentions</span></v-tooltip>

                  </v-row>

                <v-row v-if="selectedEvent.posttime" class="pt-1">
                  <v-tooltip transition="v-scale-transition" color="#0f0f0f" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                    <span v-bind="attrs" v-on="on" style="white-space: nowrap; font-size: 0.8em;" class="mt-2" :style="selectedEvent.permission && hover ? 'opacity: 1; cursor: pointer;' : 'opacity: 0.90;'" @click="selectedEvent.permission ? overlay5 = true : ''">
                    <span style='background-color: #181a1c; color: #dcd0d0; border: 2px solid #212327; border-right: 0px; border-top-left-radius: 4px; border-bottom-left-radius: 4px;' class='pl-1 pr-6 py-1'><v-img src="https://cdn.discordapp.com/emojis/799897419759878144.png" max-height="19" max-width="20" style="display:inline-block; position: absolute;"></v-img>
                    </span>
                    <span style='background-color: #292b2f; color: #adb1b6; border: 2px solid #212327; border-left: 0px; border-top-right-radius: 4px; border-bottom-right-radius: 4px;' class='px-2 py-1 mr-3'>{{ new Date(selectedEvent.posttime*1000).toLocaleDateString([],{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }} @ {{ new Date(selectedEvent.posttime*1000).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) }}</span>
                    </span>
                 </v-hover>   
                  </template>
                  <span>Post time</span>
                  </v-tooltip>             
                 </v-row>

                <v-row class="pt-1">
                <v-tooltip v-if="selectedEvent.temprole" transition="v-scale-transition" color="#0f0f0f" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                    <span v-bind="attrs" v-on="on" style="white-space: nowrap; font-size: 0.8em;" class="mt-2 mr-n3" :style="selectedEvent.permission && hover ? 'opacity: 1; cursor: pointer;' : 'opacity: 0.90;'" @click="selectedEvent.permission ? [overlay25 = true] : ''">
                    <span style='background-color: #181a1c; border: 2px solid #212327; border-right: 0px; border-top-left-radius: 4px; border-bottom-left-radius: 4px;' class='pl-1 pr-1 py-1 pb-1'><v-icon class="pb-1" size="19">mdi-shield-account</v-icon>
                    </span>
                    <span style='background-color: #292b2f; color: #adb1b6; border: 2px solid #212327; border-left: 0px; border-top-right-radius: 4px; border-bottom-right-radius: 4px;' class='px-2 py-1 mr-3' v-html="getTemporaryRoles()"></span>
                    </span>
                 </v-hover>   
                </template><span>Temporary Role</span></v-tooltip>
                </v-row>

          


     <v-row class="mt-2">
       <v-hover v-slot="{ hover }">
<div style="font-size: 0.8em; display: flex; align-items: flex-start; justify-content: flex-start;" class="mt-2" :style="selectedEvent.permission && hover ? 'opacity: 1; cursor: pointer;' : 'opacity: 0.90;'" @click="selectedEvent.permission ? overlay21 = true : ''">
  <v-tooltip transition="v-scale-transition" color="#0f0f0f" top>
    <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" style='background-color: #181a1c; color: #dcd0d0; border: 2px solid #212327; border-right: 0px; border-top-left-radius: 4px; border-bottom-left-radius: 4px; cursor: pointer;' :style="selectedEvent.permission && hover ? 'opacity: 1; cursor: pointer;' : 'opacity: 0.90;'" @click="selectedEvent.permission ? [overlay21 = true] : ''" class='pl-1 pr-1 pb-1'>
          <v-icon size="19">mdi-shield-check</v-icon>
        </span>  
        <span v-bind="attrs" v-on="on" style='background-color: #292b2f; color: #adb1b6; border: 2px solid #212327; border-left: 0px; border-top-right-radius: 4px; border-bottom-right-radius: 4px; cursor: pointer; max-width: 240px;' class='px-2 py-3 mr-3' v-html="getAllowedRoles()"></span>
    </template>
    <span>Allowed Roles</span>
  </v-tooltip>
</div>
       </v-hover>
     </v-row>


                       <v-row class="mt-2">
                         <v-hover v-slot="{ hover }">
<div style="font-size: 0.8em; display: flex; align-items: flex-start; justify-content: flex-start;" class="mt-2" :style="selectedEvent.permission && hover ? 'opacity: 1; cursor: pointer;' : 'opacity: 0.90;'" @click="selectedEvent.permission ? overlay26 = true : ''">
  <v-tooltip transition="v-scale-transition" color="#0f0f0f" top>
    <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" style='background-color: #181a1c; color: #dcd0d0; border: 2px solid #212327; border-right: 0px; border-top-left-radius: 4px; border-bottom-left-radius: 4px; cursor: pointer;' :style="selectedEvent.permission && hover ? 'opacity: 1; cursor: pointer;' : 'opacity: 0.90;'" @click="selectedEvent.permission ? [overlay26 = true] : ''" class='pl-1 pr-1 pb-1'>
          <v-icon size="19">mdi-shield-off</v-icon>
        </span>  
        <span v-bind="attrs" v-on="on" style='background-color: #292b2f; color: #adb1b6; border: 2px solid #212327; border-left: 0px; border-top-right-radius: 4px; border-bottom-right-radius: 4px; cursor: pointer; max-width: 240px;' class='px-2 py-3 mr-3' v-html="getBannedRoles()"></span>
    </template>
    <span>Banned Roles</span>
  </v-tooltip>
</div>
                         </v-hover>
     </v-row>

                <v-row class="mt-2 pt-1 pb-md-4">
                  <v-tooltip transition="v-scale-transition" color="#0f0f0f" top v-if="!selectedEvent.interval">
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                    <span v-bind="attrs" v-on="on" style="white-space: nowrap; font-size: 0.8em;" class="mt-2" :style="selectedEvent.permission && hover ? 'opacity: 1; cursor: pointer;' : 'opacity: 0.90;'" @click="selectedEvent.permission ? [overlay4 = true, advancedLimit = undefined] : ''">
                    <span style='background-color: #181a1c; color: #dcd0d0; border: 2px solid #212327; border-right: 0px; border-top-left-radius: 4px; border-bottom-left-radius: 4px;' class='pl-1 pr-6 py-1'><v-img src="https://cdn.discordapp.com/emojis/592460751055224842.png" max-height="19" max-width="20" style="display:inline-block; position: absolute;"></v-img></span>
                    <span style='background-color: #292b2f; color: #adb1b6; border: 2px solid #212327; border-left: 0px; border-top-right-radius: 4px; border-bottom-right-radius: 4px;' class='px-2 py-1 mr-3'><span v-html="getSignupsCount()"></span><span style="font-weight: 700;" v-if="(hover && selectedEvent.permission) || selectedEvent.advanced.limit < 100"> / {{ selectedEvent.advanced.limit }}</span></span>
                    </span>
                </v-hover>
                  </template>
                  <span>Sign-Ups</span>
                  </v-tooltip>    
                  </v-row>

              </v-col>

              <v-col v-if="$vuetify.breakpoint.xl || $vuetify.breakpoint.lg || $vuetify.breakpoint.md" class="pa-4">
              <v-row justify="center">
                <v-tooltip transition="v-scale-transition" color="#0f0f0f" top>
                  <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                <span v-bind="attrs" v-on="on" style="color: white; font-size: 1.4em; background-color: #292b2f; border-radius: 6px; white-space:nowrap; opacity: 0.9;" :style="hover && selectedEvent.permission ? 'opacity: 1; cursor: pointer;' : ''" @click="selectedEvent.permission ? [overlay1 = true, deadline = undefined] : ''" class="pa-1 px-2 mt-1">{{ new Date(selectedEvent.unixtime*1000).toLocaleDateString([],{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }}</span>
                </v-hover>    
                </template><span>Starts in: {{days}} days {{hours}} hours {{minutes}} minutes</span></v-tooltip>
                </v-row>
              <v-row justify="center">
               <v-tooltip transition="v-scale-transition" color="#0f0f0f" top>
                  <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <span v-bind="attrs" v-on="on" style="color: white; font-size: 1.4em; background-color: #292b2f; border-radius: 6px; white-space:nowrap; opacity: 0.9;" :style="hover && selectedEvent.permission ? 'opacity: 1; cursor: pointer;' : ''" @click="selectedEvent.permission ? [overlay1 = true, deadline = undefined] : ''"  class="pa-1 px-2 mt-1">{{ new Date(selectedEvent.unixtime*1000).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) }} <span style="font-weight: 400; color: darkgrey;">{{ getEndTime() }}</span></span>
              </v-hover>
                </template><span>Starts in: {{days}} days {{hours}} hours {{minutes}} minutes</span></v-tooltip>                
                </v-row>
              </v-col>

              <v-col class="px-1 mt-sm-0 mb-5">
                <v-row :justify="($vuetify.breakpoint.xl || $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.sm) ? 'end' : 'end'" v-if="selectedEvent.permission">
                  <v-btn elevation="0" depressed x-small color="purple darken-2" class="pa-2 mr-2 mt-2" @click="overlay14 = true; forceUpdate();">Advanced</v-btn>
                  <v-btn elevation="0" depressed x-small color="red" class="pa-2 mr-3 mt-2" @click="overlay9 = true;"><v-icon small>mdi-trash-can-outline</v-icon></v-btn>
          
                </v-row>			
                  <v-row :justify="($vuetify.breakpoint.xl || $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.sm) ? 'end' : 'end'">
                  <v-btn elevation="0" depressed x-small color="purple darken-2" class="pa-2 mr-2 mt-2" :href="'https://raid-helper.dev/api/v2/events/' + selectedEvent.raidid" target="_blank">JSON</v-btn>
                  <v-btn v-if="!selectedEvent.interval" elevation="0" depressed x-small color="purple darken-2" class="pa-2 mr-3 mt-2" @click="openRaidplan()">Raidplan</v-btn>
                </v-row>
                <v-row :justify="($vuetify.breakpoint.xl || $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.sm) ? 'end' : 'end'" v-if="selectedEvent.permission">
                  <v-btn elevation="0" small text plain :ripple="false" @click="overlay10 = true; forceUpdate();" class="pa-2 mr-0 mt-1"><v-icon size="34" color="grey">mdi-image-area</v-icon></v-btn>	
                  <v-btn v-if="!selectedEvent.posttime" elevation="0" depressed x-small color="purple darken-2" class="pa-2 mr-3 ml-n1 mt-2" @click="addUsers = []; addUsers.push({notify: true}); overlay18 = true;">Add Users</v-btn>
                </v-row>
                <v-row :justify="($vuetify.breakpoint.xl || $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.sm) ? 'end' : 'end'" v-if="selectedEvent.permission">
                  <v-btn elevation="0" depressed x-small color="purple darken-2" class="pa-2 mr-3 ml-n1 mt-1" @click="announcementType = undefined; announcementChannel = selectedEvent.announcement.channel; selectedEvent.announcement.time === 'none' ? announcementTime = '' : announcementTime = selectedEvent.announcement.time; selectedEvent.announcement.message === 'none' ? announcementMessage = '' : announcementMessage = selectedEvent.announcement.message; overlay23 = true;">Announcement</v-btn>
                </v-row>

                <v-row :justify="($vuetify.breakpoint.xl || $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.sm) ? 'end' : 'end'" v-if="selectedEvent.softres">
                  <v-btn v-if="!selectedEvent.posttime" elevation="0" depressed x-small color="purple darken-2" class="pa-2 mr-3 ml-n1 mt-2" :href="'https://softres.it/raid/' + selectedEvent.softres" target="_blank"><v-img src="https://cdn.discordapp.com/emojis/747837526290596002.png" max-height="13" max-width="18" class="py-n2 mx-1"></v-img>Softres.it <v-icon class="mx-1" x-small>mdi-open-in-new</v-icon></v-btn>
                </v-row>
                <v-row :justify="($vuetify.breakpoint.xl || $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.sm) ? 'end' : 'end'" v-if="!selectedEvent.permission">
                  <v-btn elevation="0" depressed x-small color="purple darken-2" class="pa-2 mr-3 mt-2" @click="editPermsOverlay = true;">Edit</v-btn>
                </v-row>
              </v-col>
        </v-row>
              </v-col>
    </v-row>

            <v-row class="mt-10 mt-md-5 mb-n1">
              <v-hover v-slot="{ hover }">
            <v-col>
              <v-row id="description" class="ml-2 mr-n2 px-0" @click="selectedEvent.permission ? [descriptionText = selectedEvent.description, overlay12 = true] : ''" :style="hover && selectedEvent.permission ? 'cursor: pointer;' : 'opacity: 0.95;'">
                <span style="color: darkgrey; font-size: 0.9em; font-weight: 400; min-height: 20px; white-space:pre-wrap;" v-html="getDescriptionWithMarkdown()"></span>
              </v-row>
            </v-col>
          </v-hover>
          </v-row>

    

           <v-row class="mb-2 ml-1" style="justify-content: space-between;" v-if="selectedEvent.roles.length >= 1">
            <v-col cols="12" sm="6" md="3" lg="3" xl="3" class="pr-6" v-for="(roles, b) in selectedEvent.roles" :key="b" align="start">
              <v-row>
                  <v-hover v-slot="{ hover }">
                    <span style="white-space: nowrap; font-size: 0.8em; display: block; background-color: #292b2f; color: #adb1b6; border: 2px solid #212327; border-radius: 4px; width: 100%; padding-bottom: 3px !important;  padding-top: 3px !important; padding: 5px !important;" :style="hover === true ? 'background-color: #2f3136;' : ''" class="mt-1">
                  <v-tooltip transition="v-scale-transition" color="#0f0f0f" top nudge-right="12">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="mr-8"><v-img :src="iconize(roles.emote)" max-height="25" max-width="25" style="display:inline-block; position: absolute; margin-top: -3px !important;"></v-img></span>
                  </template>
                  <span>{{roles.name}}</span>
                  </v-tooltip>
                    <span style="color: white; position: absolute; font-size: 1.1em; font-weight: 300; margin-top: -1px;">{{ roles.name.replaceAll("_", " ") }} <span style="font-weight: 700;">{{ getRoleCount(roles.emote)}} <span style="color: grey;" v-if="roles.amount < 100 || (hover && selectedEvent.permission)"> / {{roles.amount}}</span></span></span>
                  
                  <v-tooltip transition="v-scale-transition" color="#0f0f0f" top >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn elevation="0" v-bind="attrs" v-on="on" v-if="selectedEvent.permission" v-show="hover" :ripple="false" plain @click="editroleName = roles.name; editroleAmount = ''; editroleAmount1 = roles.amount; overlay2 = true" x-small style="float: right;" color="orange" icon class="mr-0 mb-n1"><v-icon>mdi-pencil</v-icon></v-btn>
                  </template><span>Edit</span></v-tooltip>
                    </span>
                  </v-hover>
              </v-row>
            </v-col>
           </v-row>

           <v-row class="ml-2 mr-n2">
             <v-col class="pr-6">
               <v-row>
             <v-divider style="background-color: #292b2f;"></v-divider>
             <v-btn elevation="0" v-if="!showClasses" plain tile x-small color="grey" :ripple="false" class="mr-n2" style="margin-top: -10px !important;" @click="showClasses = !showClasses">Show all classes</v-btn>
             <v-btn elevation="0" v-else plain tile x-small color="grey" :ripple="false" class="mr-n2" style="margin-top: -10px !important;" @click="showClasses = !showClasses">Hide empty classes</v-btn>

               </v-row>
             </v-col>
           </v-row>

          <v-row class="ml-1" id="classes" v-if="!isPoll() && (!isStrawpoll() || selectedEvent.permission)">
            
            <v-col cols="12" sm="6" md="4" lg="3" xl="3" v-for="(classes, b) in getUniqueClasses()" :key="b" class="pr-6 mt-n4">
              <v-row>
                 <v-hover v-slot="{ hover }">
                    <span style="white-space: nowrap; font-size: 0.8em; display: block; background-color: #393C42; color: #adb1b6; border: 2px solid #212327; border-top-right-radius: 4px; border-bottom-right-radius: 4px; width: 100%; padding-bottom: 3px !important;  padding-top: 3px !important;" :style="hover === true ? 'background-color: #40444a;' : ''" class="mt-1">
                <v-tooltip transition="v-scale-transition" color="#0f0f0f" top nudge-right="12">
                  <template v-slot:activator="{ on, attrs }">
                   <span v-bind="attrs" v-on="on" class="mr-8"><v-img v-if="classes.emote.match('^(\\d{17,20})$')" :src="iconize(classes.emote)" max-height="25" max-width="25" style="display:inline-block; position: absolute; margin-top: -3px !important;"></v-img><div v-else max-height="25" max-width="25" style="display:inline-block; position: absolute; margin-top: -3px !important; margin-left: 3px; font-size: 18px;">{{classes.emote}}</div></span>
                  </template><span :style="'color: ' + getClassColor(classes.name, '') + ';'">{{classes.name}}</span></v-tooltip>
                  <span :style="'position: absolute; font-size: 1.1em; font-weight: 700;'"><span :style="'color: ' + getClassColor(classes.name, '') + ';'">{{ classes.name.replaceAll("_", " ") }}</span> {{ getClassCount(classes.emote) }}<span style="color: grey;" v-if="classes.amount < 100 || (hover && selectedEvent.permission)"> / {{classes.amount}}</span></span>
                  
                  <v-tooltip transition="v-scale-transition" color="#0f0f0f" top >
                  <template v-slot:activator="{ on, attrs }">
                  <v-btn elevation="0" v-bind="attrs" v-on="on" v-if="selectedEvent.permission" v-show="hover" :ripple="false" plain @click="editclassName = classes.name; editclassAmount = ''; editclassAmount1 = classes.amount; overlay3 = true" x-small style="float: right;" color="orange" icon class="mr-1 mb-n1"><v-icon>mdi-pencil</v-icon></v-btn>
                  </template><span>Edit</span></v-tooltip>
                    </span>
                       </v-hover>
              </v-row>
              <br />
              <v-col class="fixedColPadding" v-for="(player, d) in getSignupsOfClass(classes.emote)" :key="d">
                <v-row>

                   <v-hover v-slot="{ hover }">
                    <span style="white-space: nowrap; font-size: 0.8em; display: block; background-color: #292b2f; color: #adb1b6; border: 2px solid #212327 !important; border-top-right-radius: 4px; border-bottom-right-radius: 4px; width: 100%; padding-bottom: 3px !important;  padding-top: 3px !important;" :style="hover === true ? 'background-color: #2f3136;' : ''" class="mt-0">
                <v-tooltip transition="v-scale-transition" color="#0f0f0f" top nudge-right="12">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="mr-6"><v-img v-if="player.spec_emote.match('^(\\d{17,20})$')" :src="iconize(player.status === 'queued' ? '816093673108406273' : player.spec_emote)" max-height="25" max-width="25" style="display:inline-block; position: absolute; margin-top: -3px !important;"></v-img><div v-else max-height="25" max-width="25" style="display:inline-block; position: absolute; margin-top: -3px !important; margin-left: 3px; font-size: 18px;">{{player.spec_emote}}</div></span>
                  </template><span v-if="player.status === 'queued'">{{getSpecNameWithout1(player.spec)}} (Queued)</span><span v-else>{{getSpecNameWithout1(player.spec)}}</span></v-tooltip>
                    <v-tooltip transition="v-scale-transition" color="#0f0f0f" top>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="mx-1" style="background-color: #202225; font-size: 1em; position: relative; margin-top: -2px !important; border-radius: 4px; padding: 2px; padding-left: 3px; padding-right: 3px;">{{ getPosition(player.position) }}</span>
                    </template>
                <span>{{new Date(player.signuptime*1000).toLocaleDateString([],{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }} {{ new Date(player.signuptime*1000).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}}</span>
              </v-tooltip>
                    <span :style="'padding-left: 1px !important; color: ' + getClassColor(player.class, player.spec) + ';'"><span :style="player.status === 'primary' ? 'font-weight: 700;' : player.status === 'queued' ? 'text-decoration: line-through;' : 'font-weight: 300;'">{{ truncate(player.name) }}</span></span>
                  
                  <v-tooltip transition="v-scale-transition" color="#0f0f0f" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn elevation="0" v-bind="attrs" v-on="on" v-if="selectedEvent.permission" v-show="hover" :ripple="false" plain @click="currentPlayerEdit = JSON.parse(JSON.stringify(player)); overlay17 = true;" x-small style="float: right;" color="orange lighten-2" icon class="mr-1 mb-n1"><v-icon size="20" small>mdi-pencil</v-icon></v-btn>
                  </template><span>Edit</span></v-tooltip>
                    </span>

                   </v-hover>

                </v-row>

                <br />
              </v-col>
            </v-col>
          </v-row>
          <v-row class="ml-1" id="classes" v-else-if="isStrawpoll()">
            
            <v-col cols="12" v-for="(classes, b) in getPollClasses()" :key="b" class="pr-6">
              <v-row class="ml-n2">
                    <span style="white-space: nowrap; font-size: 0.8em; display: block;" class="mb-1">
                   <span class="mr-8"><v-img :src="iconize(classes.emote)" max-height="25" max-width="25" style="display:inline-block; position: absolute; margin-top: -3px !important;"></v-img></span>
                  <span style="color: white; position: absolute; font-size: 1.1em; font-weight: 700;">
                    {{ classes.answer }} ({{ classes.count }})
                  </span>
                    </span>
              </v-row>
              <br />
              <v-row class="mb-n1 mt-n5" style="margin-left: -22px;">
                <v-col cols="12">
                
                    <span :style="'min-width: 28px; width: ' + Math.min(Math.max(classes.percentage, 0),  100) + '%; display: inline-block; white-space: nowrap; font-size: 0.8em; background-color: #393C42; color: #adb1b6; border: 2px solid #212327; border-top-right-radius: 4px; border-bottom-right-radius: 4px; padding-bottom: 3px !important;  padding-top: 3px !important;'" class="mt-n2">
                   <span style="white-space: nowrap; font-size: 0.8em; float: right; padding-top: 3px; color: white;" class="pr-1">{{ classes.percentage }}%</span>
                   <span class="mr-8 pt-5"></span>
                    </span>
                  
                 
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="ml-1 mb-0" id="classes" justify="center" v-else-if="isPoll()">
              <v-col cols="12" sm="6" md="3" lg="3" xl="3" v-for="(classes, b) in uniqueClasses" :key="b" class="pr-6">
              <v-row class="mt-n4">

                    <span style="white-space: nowrap; font-size: 0.8em; display: block; background-color: #393C42; color: #adb1b6; border: 2px solid #212327; border-top-right-radius: 4px; border-bottom-right-radius: 4px; width: 100%; padding-bottom: 3px !important;  padding-top: 3px !important;" class="mt-1">
                   <span class="mr-8"><v-img :src="iconize(classes.emote)" max-height="25" max-width="25" style="display:inline-block; position: absolute; margin-top: -3px !important;"></v-img></span>
                  <span style="color: white; position: absolute; font-size: 1.0em; font-weight: 700;">
                    {{ classes.name.replaceAll("_", " ") }} ({{ getClassCount(classes.emote) }})
                  </span>
                    </span>

                <v-col class="fixedColPadding" v-for="(player, d) in getSignupsOfClass(classes.emote)" :key="d">
                <v-row v-if="selectedEvent.permission">

                   <v-hover v-slot="{ hover }">
                    <span style="white-space: nowrap; font-size: 0.8em; display: block; background-color: #292b2f; color: #adb1b6; border: 2px solid #212327 !important; border-top-right-radius: 4px; border-bottom-right-radius: 4px; width: 100%; padding-bottom: 3px !important;  padding-top: 3px !important;" :style="hover === true ? 'background-color: #2f3136;' : ''" class="mt-3 mx-3">
                <v-tooltip transition="v-scale-transition" color="#0f0f0f" top nudge-right="12">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="mr-6"><v-img :src="iconize(player.spec_emote)" max-height="25" max-width="25" style="display:inline-block; position: absolute; margin-top: -3px !important;"></v-img></span>
                  </template><span>{{getSpecNameWithout1(player.spec)}}</span></v-tooltip>
                    <v-tooltip transition="v-scale-transition" color="#0f0f0f" top>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="mx-1" style="background-color: #202225; font-size: 1em; position: relative; margin-top: -2px !important; border-radius: 4px; padding: 2px; padding-left: 3px; padding-right: 3px;">{{ getPosition(player.position) }}</span>
                    </template>
                <span>{{new Date(player.signuptime*1000).toLocaleDateString([],{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }} {{ new Date(player.signuptime*1000).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}}</span>
              </v-tooltip>
                    <span :style="'padding-left: 1px !important; color: ' + getClassColor(player.class, player.spec) + ';'"><span :style="player.status === 'primary' ? 'font-weight: 700;' : 'font-weight: 300;'">{{ truncate(player.name) }}</span></span>
                  
                  <v-tooltip transition="v-scale-transition" color="#0f0f0f" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn elevation="0" v-bind="attrs" v-on="on" v-if="selectedEvent.permission" v-show="hover" :ripple="false" plain @click="currentPlayerEdit = JSON.parse(JSON.stringify(player)); overlay17 = true;" x-small style="float: right;" color="orange lighten-2" icon class="mr-1 mb-n1"><v-icon size="20" small>mdi-pencil</v-icon></v-btn>
                  </template><span>Edit</span></v-tooltip>
                    </span>

                   </v-hover>

                </v-row>

                <br />
              </v-col>

              </v-row>
              </v-col>
        
            <v-col align="center" class="pr-6">
              <v-img :src="getPollImage()" max-width="400px"></v-img>
          
            </v-col>
          </v-row>

           <v-row class="ml-2 mr-n2 mt-0 mb-0" v-if="getDefaultClasses() && uniqueStatus.length > 0">
             <v-col class="pr-6">
               <v-row>
             <v-divider style="background-color: #292b2f;" v-if="hasPrimarySignups"></v-divider>
               </v-row>
             </v-col>
           </v-row>

          <v-row  class="ml-1 mt-0 mb-n6" id="classes" v-if="uniqueStatus && uniqueStatus.length > 0">
            
            <v-col cols="12" sm="6" md="4" lg="3" xl="3" v-for="(classes, b) in uniqueStatus" :key="b" class="pr-6 mt-n4" >
              <v-row>
                 <v-hover v-slot="{ hover }">
                    <span style="white-space: nowrap; font-size: 0.8em; display: block; background-color: #393C42; color: #adb1b6; border: 2px solid #212327; border-top-right-radius: 4px; border-bottom-right-radius: 4px; width: 100%; padding-bottom: 3px !important;  padding-top: 3px !important;" :style="hover === true ? 'background-color: #40444a;' : ''" class="mt-1">
                  <v-tooltip transition="v-scale-transition" color="#0f0f0f" top nudge-right="12">
                  <template v-slot:activator="{ on, attrs }">
                   <span v-bind="attrs" v-on="on" class="mr-8"><v-img v-if="classes.emote.match('^(\\d{17,20})$')" :src="iconize(classes.emote)" max-height="25" max-width="25" style="display:inline-block; position: absolute; margin-top: -3px !important;"></v-img><div v-else max-height="25" max-width="25" style="display:inline-block; position: absolute; margin-top: -3px !important; margin-left: 3px; font-size: 18px;">{{classes.emote}}</div></span>
                   </template><span>{{ classes.effective_name ? classes.effective_name.replaceAll("_", " ") : classes.name.replaceAll("_", " ") }}</span></v-tooltip>
                  <span style="color: white; position: absolute; font-size: 1.1em; font-weight: 700;">
                    {{ classes.effective_name ? classes.effective_name.replaceAll("_", " ") : classes.name.replaceAll("_", " ") }} ({{ getClassCount(classes.name) }})
                  </span>
                    </span>
                     </v-hover>
              </v-row>
              <br />
              <v-col class="fixedColPadding" v-for="(player, d) in getSignupsOfClass(classes.name)" :key="d">
                <v-row>
                <v-hover v-slot="{ hover }">
                    <span style="white-space: nowrap; font-size: 0.8em; display: block; background-color: #292b2f; color: #adb1b6; border: 2px solid #212327 !important; border-top-right-radius: 4px; border-bottom-right-radius: 4px; width: 100%; padding-bottom: 3px !important;  padding-top: 3px !important;" :style="hover === true ? 'background-color: #2f3136;' : ''" class="mt-0">
                  <v-tooltip transition="v-scale-transition" color="#0f0f0f" top nudge-right="12">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="mr-6"><v-img v-if="player.spec_emote.match('^(\\d{17,20})$')" :src="iconize(player.spec_emote)" max-height="25" max-width="25" style="display:inline-block; position: absolute; margin-top: -3px !important;"></v-img><div v-else max-height="25" max-width="25" style="display:inline-block; position: absolute; margin-top: -3px !important; margin-left: 3px; font-size: 18px;">{{player.spec_emote}}</div></span>
                    </template><span>{{getSpecNameWithout1(player.spec)}}</span></v-tooltip>
                     <v-tooltip transition="v-scale-transition" color="#0f0f0f" top >
                     <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="mx-1" style="background-color: #202225; font-size: 1em; position: absolute; margin-top: -2px !important; border-radius: 4px; padding: 2px; padding-left: 3px; padding-right: 3px;">{{ getPosition(player.position) }}</span>
                  </template>
                  <span>{{new Date(player.signuptime*1000).toLocaleDateString([],{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }} {{ new Date(player.signuptime*1000).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}}</span>
                  </v-tooltip>
                    <span style="padding-left: 28px !important;"><span :style="player.status === 'primary' ? 'font-weight: 700;' : 'font-weight: 300; color: grey;'">{{ truncate(player.name) }}</span></span>
                  
                  <v-tooltip transition="v-scale-transition" color="#0f0f0f" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn elevation="0" v-bind="attrs" v-on="on" v-if="selectedEvent.permission" v-show="hover" plain @click="currentPlayerEdit = JSON.parse(JSON.stringify(player)); overlay17 = true;" x-small style="float: right;" color="orange" icon class="mr-1 mb-n1"><v-icon small>mdi-pencil</v-icon></v-btn>
                  </template>
                    <span>Edit</span></v-tooltip>
                    </span>
                    </v-hover>
                </v-row>
                <br />
              </v-col>
            </v-col>
          </v-row>
        <v-row class="pl-1" v-if="selectedEvent.advanced.image.includes('http')">
          <v-col>
            <div @click="overlay10 = true;" style="cursor: pointer;">
            <v-img v-if="selectedEvent.permission" :src="selectedEvent.advanced.image" style="cursor: pointer; border-radius: 5px;"></v-img>
            <v-img v-else :src="selectedEvent.advanced.image" style="border-radius: 5px;"></v-img>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-row v-else-if="isLoaded" justify="center">
      <span style="color: grey">This event doesn't exist.</span>
    </v-row>   

    <v-container v-if="isLoaded && selectedEvent.title">
            <v-dialog v-if="selectedEvent.title" transition="slide-y-transition" v-model="overlay" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
               <v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
                    <v-card-text class="mt-2 pa-4" style="font-size: 1.3em; color: white;">Leader</v-card-text>
                    <v-card-text class="mt-n5 pa-4" style="font-size: 0.8em;">Enter a members name.</v-card-text>
                        <v-text-field flat background-color="#4a4e57" solo @keydown.enter="editProperty('leader', leaderText); overlay = false;" hide-details :placeholder="selectedEvent.leadername" v-model="leaderText" class="pa-4 mb-2" full-width single-line>
                        </v-text-field>
                      <div style="background-color: #2f3136; text-align: right;" class="pa-2 mb-0">
                            <v-btn elevation="0" @click="overlay = false;" right plain class="mx-1 my-2" :ripple="false">Cancel</v-btn>
                            <v-btn :disabled="leaderText === undefined || leaderText.length === 0" elevation="0" @click="editProperty('leader', leaderText); overlay = false;" right color="green" class="mx-2 my-2">Save</v-btn>
                      </div>
                  </v-card>
                </v-dialog>

                <v-dialog transition="slide-y-transition" v-model="overlay1" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="700" dark>
                    <v-card color="#3b3e45" style="border: 1px solid #2c2f33;">
                      <VueCtkDateTimePicker :first-day-of-week="1" max-height="7" inline no-keyboard label="Date & Time" style="z-index: 3;" id="datepicker1" dark noHeader no-button v-model="unixtime" format="YYYY-MM-DDTHH:mm"/>

                        <v-container>
                          <v-row style="background-color: #2f3136;">
                              <v-col cols="7" align="start" class="pa-n2">
                          <v-btn elevation="0" @click="overlay1 = false; overlay13 = true;" left style="float: left;" class="mr-2 ma-1" color="#3b3e45" :ripple="false">Deadline</v-btn>      
                          <v-btn elevation="0" @click="overlay19 = true;" left style="float: left;" class="mr-2 ma-1" color="#3b3e45" :ripple="false">Duration</v-btn>      

                            <v-switch style="float: left; margin-top: 7px;" :label="isOpen ? 'Open' : 'Closed'" color="green lighten-1" @change="updateEventStatus()" v-model="isOpen" hide-details class="ml-4"></v-switch>    
                              </v-col>
                              <v-col align="end">
                            <v-btn elevation="0" @click="overlay1 = false;" class="ma-1" color="#3b3e45" :ripple="false">Cancel</v-btn>
                      
                              <v-btn elevation="0" @click="editProperty('unixtime', unixtime); overlay1 = false;" color="green" class="ma-1 ml-2">Save</v-btn>
                              </v-col>
                          </v-row>
                        </v-container>
                    </v-card>
                </v-dialog>

              <v-dialog v-if="selectedEvent.title" transition="slide-y-transition" v-model="overlay2" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
               <v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
                    <v-card-text class="mt-2 pa-4" style="font-size: 1.3em; color: white;">Role limit</v-card-text>
                    <v-card-text class="mt-n5 pa-4" style="font-size: 0.8em;">Enter a maximum role limit.</v-card-text>
                        <v-text-field type="number" flat background-color="#4a4e57" solo @keydown.enter="editroleLimit(); overlay2 = false;" hide-details :placeholder="editroleAmount1 + ''" v-model="editroleAmount" class="pa-4 mb-2" full-width single-line>
                        </v-text-field>
                      <div style="background-color: #2f3136; text-align: right;" class="pa-2 mb-0">
                            <v-btn elevation="0" @click="overlay2 = false;" right plain class="mx-1 my-2" :ripple="false">Cancel</v-btn>
                            <v-btn :disabled="editroleAmount === undefined || editroleAmount.length === 0" elevation="0" @click="editroleLimit(); overlay2 = false;" right color="green" class="mx-2 my-2">Save</v-btn>
                      </div>
                  </v-card>
                </v-dialog>

              <v-dialog v-if="selectedEvent.title" transition="slide-y-transition" v-model="overlay3" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
               <v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
                    <v-card-text class="mt-2 pa-4" style="font-size: 1.3em; color: white;">Class limit</v-card-text>
                    <v-card-text class="mt-n5 pa-4" style="font-size: 0.8em;">Enter a maximum class limit.</v-card-text>
                        <v-text-field type="number" flat background-color="#4a4e57" solo @keydown.enter="editclassLimit(); overlay3 = false;" hide-details :placeholder="editclassAmount1 + ''" v-model="editclassAmount" class="pa-4 mb-2" full-width single-line>
                        </v-text-field>
                      <div style="background-color: #2f3136; text-align: right;" class="pa-2 mb-0">
                            <v-btn elevation="0" @click="overlay3 = false;" right plain class="mx-1 my-2" :ripple="false">Cancel</v-btn>
                            <v-btn :disabled="editclassAmount === undefined || editclassAmount.length === 0" elevation="0" @click="editclassLimit(); overlay3 = false;" right color="green" class="mx-2 my-2">Save</v-btn>
                      </div>
                  </v-card>
                </v-dialog>

                <v-dialog v-if="selectedEvent.title" transition="slide-y-transition" v-model="overlay4" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
                  <v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
                    <v-card-text class="mt-2 pa-4" style="font-size: 1.3em; color: white;">Limit</v-card-text>
                    <v-card-text class="mt-n5 pa-4" style="font-size: 0.8em;">Enter a maximum limit.</v-card-text>
                        <v-text-field type="number" flat background-color="#4a4e57" solo @keydown.enter="editAdvanced('limit', advancedLimit); overlay4 = false;" hide-details :placeholder="selectedEvent.advanced.limit + ''" v-model="advancedLimit" class="pa-4 mb-2" full-width single-line>
                        </v-text-field>
                      <div style="background-color: #2f3136; text-align: right;" class="pa-2 mb-0">
                            <v-btn elevation="0" @click="overlay4 = false;" right plain class="mx-1 my-2" :ripple="false">Cancel</v-btn>
                            <v-btn :disabled="advancedLimit === undefined || advancedLimit.length === 0" elevation="0" @click="editAdvanced('limit', advancedLimit); overlay4 = false;" right color="green" class="mx-2 my-2">Save</v-btn>
                      </div>
                  </v-card>
                </v-dialog>

                <v-dialog transition="slide-y-transition" v-model="overlay5" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
                    <v-card color="#3b3e45" style="border: 1px solid #2c2f33;">
                      <VueCtkDateTimePicker :first-day-of-week="1" max-height="7" inline no-keyboard label="Date & Time" style="z-index: 3;" id="datepicker1" dark noHeader no-button v-model="posttime" format="YYYY-MM-DDTHH:mm"/>
                        <div style="background-color: #2f3136; text-align: right;" class="pa-2">
                            <v-btn elevation="0" @click="overlay5 = false;" right plain class="mr-2 ma-2" :ripple="false">Cancel</v-btn>
                            <v-btn elevation="0" @click="editProperty('posttime', posttime); overlay5 = false;" right color="green" class="ma-2">Save</v-btn>
                        </div>
                    </v-card>
                </v-dialog>

                  <v-dialog transition="slide-y-transition" v-model="overlay6" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
                    <v-card color="#3b3e45" style="border: 1px solid #2c2f33;">
                      <v-color-picker show-swatches swatches-max-height="230" hide-canvas width="100%" :style="'background-color: transparent;'" v-model="color"></v-color-picker>
                        <div style="background-color: #2f3136; text-align: right;" class="pa-2">
                            <v-btn elevation="0" @click="overlay6 = false;" right plain class="mr-2 ma-2" :ripple="false">Cancel</v-btn>
                            <v-btn elevation="0" @click="editAdvanced('color', color); overlay6 = false;" right color="green" class="ma-2">Save</v-btn>
                        </div>
                    </v-card>
                </v-dialog>

                <v-dialog v-if="selectedEvent.title" transition="slide-y-transition" v-model="overlay7" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
                  <v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
                    <v-card-text class="mt-2 pa-4" style="font-size: 1.3em; color: white;">Interval</v-card-text>
                    <v-card-text class="mt-n5 pa-4" style="font-size: 0.8em;">Enter the new interval. This is the time between event postings. If you toggle the dynamic date, the event will happen at the selected dynamic date. The interval will then determine how long before the events date the event will be posted.</v-card-text>
                        
                          <v-select v-if="setDynamicDate" placeholder="Select an ordinal." dense flat item-value="name" item-color="green" solo v-model="interval2Ordinal" :items="ordinals" class="pa-4 mb-n11" background-color="#4a4e57">
                          <template v-slot:selection="{ item }">
                             <span style="font-size: 14px;">{{ item.name }}</span>
                          </template>
                          <template v-slot:item="{ item }"> 
                            <span style="font-size: 14px;">{{ item.name }}</span>
                          </template>
                        </v-select>
                        <v-select v-if="setDynamicDate" placeholder="Select a weekday." dense flat item-value="name" item-color="green" solo v-model="interval2Weekday" :items="weekdays" class="pa-4 mt-n6 mb-n11" background-color="#4a4e57">
                          <template v-slot:selection="{ item }">
                             <span style="font-size: 14px;">{{ item.name }}</span>
                          </template>
                          <template v-slot:item="{ item }"> 
                            <span style="font-size: 14px;">{{ item.name }}</span>
                          </template>
                        </v-select>

      <v-col>
  <v-row>
    <v-text-field dense flat background-color="#4a4e57" solo hide-details :placeholder="'Enter the interval as a number. (Current: ' + selectedEvent.interval + ')'" v-model="interval" class="pl-4 pr-2 mt-4 custom-textfield" full-width single-line style="width: 75%;">
    </v-text-field>

    <v-select placeholder="Select a weekday." dense flat item-value="name" item-text="name" item-color="green" solo v-model="intervalUnit" :items="[{name: 'minutes'}, {name: 'hours'}, {name: 'days'}, {name: 'months'}, ]" class="pl-1 pr-4 mt-4" background-color="#4a4e57" style="width: 25%;">
      <template v-slot:selection="{ item }">
        <span style="font-size: 14px;">{{ item.name }}</span>
      </template>
      <template v-slot:item="{ item }"> 
        <span style="font-size: 14px;">{{ item.name }}</span>
      </template>
    </v-select>
  </v-row>
</v-col>

                          <v-container>
                          <v-row style="background-color: #2f3136;">
                              <v-col cols="4" align="start" class="pa-n2">
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-btn elevation="0" x-large plain :ripple="false" v-on="on" class="mx-2 ml-n5 pt-2" @click="setDynamicDate = !setDynamicDate; interval2Ordinal = ''; interval2Weekday = ''" ><v-icon size="50" :color="setDynamicDate ? 'blue' : 'grey darken-2'" class="mt-n1">mdi-timetable</v-icon></v-btn></template><span>Set Dynamic Date</span></v-tooltip>                              
                        </v-col>
                              <v-col cols="8" align="end">
                            <v-btn elevation="0" @click="overlay7 = false;" right plain class="mx-1 my-2" :ripple="false">Cancel</v-btn>
                            <v-btn :disabled="interval === undefined || interval.length === 0 || (setDynamicDate && (interval2Ordinal == '' || interval2Weekday == ''))" elevation="0" @click="(setDynamicDate && interval2Ordinal != '' && interval2Weekday != '') ? editProperty('interval2', interval2Ordinal.toLowerCase() + '-' + interval2Weekday.toLowerCase()) : editProperty('interval', interval + ' ' + intervalUnit); overlay7 = false;" right color="green" class="mx-2 my-2">Save</v-btn>
                    
                              </v-col>
                          </v-row>
                        </v-container>
                  </v-card>
                </v-dialog>

                <v-dialog v-if="selectedEvent.title" transition="slide-y-transition" v-model="overlay8" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
               <v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
                    <v-card-text class="mt-2 pa-4" style="font-size: 1.3em; color: white;">Channel</v-card-text>
                    <v-card-text class="mt-n5 pa-4" style="font-size: 0.8em;">Select the channel you want to move the event to.</v-card-text>
                        <v-select flat item-text="name" item-value="id" item-color="green" solo v-model="channel" :items="$store.state.discordChannels" class="pa-4 mb-n6" background-color="#4a4e57">
                          <template v-slot:selection="{ item }">
                            <v-avatar tile width="20" height="20">
                              <img :src="'https://cdn.discordapp.com/emojis/' + getChannelIconByType(item.type) + '.png?v=1'" width="20" height="20" class="ml-n7" />
                            </v-avatar>
                             <span class="ml-n5" style="font-size: 14px;">{{ item.name }}</span>
                          </template>
                          <template v-slot:item="{ item }"> 
                            <v-avatar tile width="20" height="20">
                              <img :src="'https://cdn.discordapp.com/emojis/' + getChannelIconByType(item.type) + '.png?v=1'" width="20" height="20" class="ml-n7" />
                            </v-avatar>
                            <span class="ml-n5" style="font-size: 14px;">{{ item.name }}</span>
                          </template>
                        </v-select>
                      <div style="background-color: #2f3136; text-align: right;" class="pa-2 mb-0">
                              <v-btn elevation="0" @click="overlay8 = false;" right plain class="mx-1 my-2" :ripple="false">Cancel</v-btn>
                              <v-btn :disabled="channel === undefined || channel === false" elevation="0" @click="editProperty('channel', channel); overlay8 = false;" right color="green" class="mx-2 my-2">Save</v-btn>
                         
                      </div>
                  </v-card>
                </v-dialog>

                <v-dialog v-if="selectedEvent.title" transition="slide-y-transition" v-model="overlay9" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
                  <v-card color="#3b3e45" style="border: 1px solid #2c2f33;">
                    <v-card-text class="mt-2 pa-4" style="font-size: 1.3em; color: white;">Delete event</v-card-text>
                    <v-card-text class="mt-n5 pa-4 mb-3" style="font-size: 0.8em;">Are you sure you want to delete this event?</v-card-text>
                    <div style="background-color: #2f3136; text-align: right;" class="pa-2">
                       <v-btn elevation="0" @click="overlay9 = false;" right plain class="mr-2 ma-2" :ripple="false">Cancel</v-btn>
                       <v-btn elevation="0" @click="deleteEvent(); overlay9 = false;" right color="#ed4245" class="ma-2">Delete</v-btn>
                       </div>
                  </v-card>
                </v-dialog>

                <v-dialog v-if="selectedEvent.title" transition="slide-y-transition" v-model="overlay10" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
               <v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
                    <v-card-text class="mt-2 pa-4" style="font-size: 1.3em; color: white;">Image</v-card-text>
                    <v-card-text class="mt-n5 pa-4 mb-3" style="font-size: 0.8em;">Enter, upload or select an image.</v-card-text>
                     <Images :key="key" @imageUrl="setImage" v-bind:url="selectedEvent.advanced.image" id="ImageSelection" class="my-10" /> 
                       <div style="background-color: #2f3136; text-align: right;" class="pa-2">
                       <v-btn elevation="0" @click="overlay10 = false;" right plain class="mr-n2 ma-2" :ripple="false">Cancel</v-btn>
                        <v-btn elevation="0" @click="imageUrl = 'none'; editAdvanced('image', imageUrl); overlay10 = false;" :ripple="false" right color="orange" text plain class="ma-2">Remove</v-btn>
                       <v-btn elevation="0" @click="editAdvanced('image', imageUrl); overlay10 = false;" right color="green" class="ma-2">Save</v-btn>
                       </div>
                  </v-card>
                </v-dialog>

                <v-dialog v-if="selectedEvent.title" transition="slide-y-transition" v-model="overlay11" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
                  <v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
                    <v-card-text class="mt-2 pa-4" style="font-size: 1.3em; color: white;">Title</v-card-text>
                    <v-card-text class="mt-n5 pa-4" style="font-size: 0.8em;">Enter a new title.</v-card-text>
                        <v-text-field flat background-color="#4a4e57" solo @keydown.enter="editProperty('title', titleText); overlay11 = false;" hide-details placeholder="Enter a number" v-model="titleText" class="pa-4 mb-2" full-width single-line>
                        </v-text-field>
                      <div style="background-color: #2f3136; text-align: right;" class="pa-2 mb-0">
                            <v-btn elevation="0" @click="overlay11 = false;" right plain class="mx-1 my-2" :ripple="false">Cancel</v-btn>
                            <v-btn :disabled="titleText === undefined || titleText.length === 0" elevation="0" @click="editProperty('title', titleText); overlay11 = false;" right color="green" class="mx-2 my-2">Save</v-btn>
                      </div>
                  </v-card>
                </v-dialog>

                <v-dialog v-if="selectedEvent.title" transition="slide-y-transition" v-model="overlay12" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
                  <v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
                    <v-card-text class="mt-2 pa-4" style="font-size: 1.3em; color: white;">Description</v-card-text>
                    <v-card-text class="mt-n5 pa-4" style="font-size: 0.8em;">Enter a new description.</v-card-text>
                        <v-textarea  flat background-color="#4a4e57" solo hide-details v-model="descriptionText" class="pa-4 mb-2" full-width>
                        </v-textarea>
                      <div style="background-color: #2f3136; text-align: right;" class="pa-2 mb-0">
                            <v-btn elevation="0" @click="overlay12 = false;" right plain class="mx-1 my-2" :ripple="false">Cancel</v-btn>
                            <v-btn :disabled="descriptionText === undefined || descriptionText.length === 0" elevation="0" @click="editProperty('description', descriptionText); overlay12 = false;" right color="green" class="mx-2 my-2">Save</v-btn>
                      </div>
                  </v-card>
                </v-dialog>

                <v-dialog v-if="selectedEvent.title" transition="slide-y-transition" v-model="overlay13" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
                  <v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
                    <v-card-text class="mt-2 pa-4" style="font-size: 1.3em; color: white;">Deadline</v-card-text>
                    <v-card-text class="mt-n5 pa-4" style="font-size: 0.8em;">Enter the new deadline in hours. You can also append d for days or m for minutes.</v-card-text>
                        <v-text-field flat background-color="#4a4e57" solo @keydown.enter="editAdvanced('deadline', deadline.replaceAll(' ', '').replace('days', 'd').replace('day', 'd').replace('hours', 'h').replace('hour', 'h').replace('minutes', 'm').replace('minute', 'm')); overlay13 = false;" hide-details :placeholder="selectedEvent.advanced.deadline + ''" v-model="deadline" class="pa-4 mb-2" full-width single-line>
                        </v-text-field>
                      <div style="background-color: #2f3136; text-align: right;" class="pa-2 mb-0">
                            <v-btn elevation="0" @click="overlay13 = false;" right plain class="mx-1 my-2" :ripple="false">Cancel</v-btn>
                            <v-btn :disabled="deadline === undefined || deadline.length === 0" elevation="0" @click="editAdvanced('deadline', deadline.replaceAll(' ', '').replace('days', 'd').replace('day', 'd').replace('hours', 'h').replace('hour', 'h').replace('minutes', 'm').replace('minute', 'm')); overlay13 = false;" right color="green" class="mx-2 my-2">Save</v-btn>
                      </div>
                  </v-card>
                </v-dialog>

              <v-dialog v-if="selectedEvent.title" transition="slide-y-transition" v-model="overlay14" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
               <v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
                    <v-card-text class="mt-2 pa-4 px-5" style="font-size: 1.3em; color: white;">Advanced Settings</v-card-text>
                    <v-card-text class="mt-n5 pa-5" style="font-size: 0.8em;">Be aware that most visual settings only apply to the event displayed in discord.</v-card-text>
                     <Advanced :key="key" @advancedSettings="setAdvanced" v-bind:adv="selectedEvent.advanced" v-bind:isRec="selectedEvent.interval ? 'full' : 'edit'" id="AdvancedSelection" class="mt-n1 pa-3 pb-6 mr-5" /> 
                        <v-container>
                          <v-row style="background-color: #2f3136;">
                              <v-col align="start" class="pa-n2">
                          <v-btn elevation="0" @click="overlay15 = true;" left style="float: left;" class="mr-2 ma-1" color="#3b3e45" :ripple="false">Documentation</v-btn>      
                              </v-col>
                              <v-col align="end">
                                <v-btn elevation="0" @click="overlay14 = false;" right plain class="mr-2 ma-1" :ripple="false">Cancel</v-btn>
                       <v-btn elevation="0" @click="updateAdvanced(); overlay14 = false;" right color="green" class="ma-1">Save</v-btn>
                              </v-col>
                          </v-row>
                        </v-container>
                  </v-card>
                </v-dialog>

              <v-dialog v-if="selectedEvent.title" transition="slide-y-transition" v-model="overlay15" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="1000" dark>
               <v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
                    <v-card-text class="mt-2 pa-4 px-5" style="font-size: 1.3em; color: white; text-align: center;">Advanced Settings</v-card-text>
                     <AdvancedDocs id="AdvancedDocs" class="my-10" /> 
                  </v-card>
                </v-dialog>

                   <v-dialog v-if="selectedEvent.title && currentPlayerEdit" transition="slide-y-transition" v-model="overlay17" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
               <v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
                    <v-card-text class="mt-2 pa-4" style="font-size: 1.3em; color: white;">Edit Sign-Up</v-card-text>
                        <v-row class="ma-0 mt-n4">
                          <v-col cols="12">
                            <div class="ml-1 mt-5 mb-0">Name</div>
                            <v-text-field flat dense background-color="#4a4e57" solo hide-details :placeholder="currentPlayerEdit.name + ''" v-model="currentPlayerEdit.name" class="pa-1 mb-n5" full-width single-line>
                            </v-text-field>
                          </v-col>

                        <v-col cols="12" sm="6" class="pa-0">
                          <div class="ml-4 mt-5 mb-n3">Class</div>
                        <v-select flat dense item-text="name" item-value="id" item-color="white" solo v-model="currentPlayerEdit.class" @change="currentPlayerEdit.spec = (getSpecsOfClass(currentPlayerEdit.class).length === 0 ? currentPlayerEdit.spec : getSpecsOfClass(currentPlayerEdit.class)[0].name)" :items="selectedEvent.classes"  class="pa-4 mb-n6" background-color="#4a4e57">
                          <template v-slot:selection="{ item }">
                            <v-avatar tile width="20" height="20">
                              <img v-if="item.emote.match('^(\\d{17,20})$')" :src="'https://cdn.discordapp.com/emojis/' + item.emote + '.png?v=1'" width="20" height="20" class="" />
                              <div v-else>{{item.emote}}</div>
                            </v-avatar>
                            {{ item.effective_name ? getSpecNameWithout1(item.effective_name) : getSpecNameWithout1(item.name) }}
                          </template>
                          <template v-slot:item="{ item }">
                            <v-avatar tile width="20" height="20">
                              <img v-if="item.emote.match('^(\\d{17,20})$')" :src="'https://cdn.discordapp.com/emojis/' + item.emote + '.png?v=1'" width="20" height="20" class="" />
                              <div v-else>{{item.emote}}</div>
                            </v-avatar>
                            {{ item.effective_name ? getSpecNameWithout1(item.effective_name) : getSpecNameWithout1(item.name) }}
                          </template>
                        </v-select>
                        </v-col>
                          <v-col cols="12" sm="6" class="pa-0">
                          <div class="ml-4 mt-sm-5 mb-n3">Spec</div>
                        <v-select flat :disabled="getSpecsOfClass(currentPlayerEdit.class).length === 0" solo dense item-text="name" item-value="id" item-color="white" v-model="currentPlayerEdit.spec" :items="getSpecsOfClass(currentPlayerEdit.class)" label="Select a spec" class="pa-4 mb-n6" background-color="#4a4e57">
                          <template v-slot:selection="{ item }">
                            <v-avatar tile width="20" height="20">
                              <img v-if="item.emote.match('^(\\d{17,20})$')" :src="'https://cdn.discordapp.com/emojis/' + item.emote + '.png?v=1'" width="20" height="20" class="" />
                              <div v-else>{{item.emote}}</div>
                            </v-avatar>
                            {{ item.effective_name ? getSpecNameWithout1(item.effective_name) : getSpecNameWithout1(item.name) }}
                          </template>
                          <template v-slot:item="{ item }">
                            <v-avatar tile width="20" height="20">
                              <img v-if="item.emote.match('^(\\d{17,20})$')" :src="'https://cdn.discordapp.com/emojis/' + item.emote + '.png?v=1'" width="20" height="20" class="" />
                              <div v-else>{{item.emote}}</div>
                            </v-avatar>
                            {{ item.effective_name ? getSpecNameWithout1(item.effective_name) : getSpecNameWithout1(item.name) }}
                          </template>
                        </v-select>
                        </v-col>
                                                  <v-col v-if="notify" cols="12">
                            <div class="ml-1 mt-n4">Reason</div>
                            <v-text-field flat dense background-color="#4a4e57" solo hide-details :placeholder="notify ? 'Enter a reason that will be sent with the notification (Optional)' : 'Notification disabled'" v-model="currentPlayerEdit.reason" class="pa-1" full-width single-line>
                            </v-text-field>
                          </v-col>
                        </v-row>

                      <v-container>
                          <v-row style="background-color: #2f3136;">
                              <v-col cols="7" align="start" class="pa-n2">
                                <v-btn elevation="0" @click="currentPlayerEdit.notify = notify; removeSignup(currentPlayerEdit); overlay17 = false;" left color="red" style="font-weight: 700; float: left;" class="mx-1 my-2" :ripple="false">Remove</v-btn>
                                <v-switch style="float: left; margin-top: 9px;" :label="'notify'" color="green lighten-1" v-model="notify" hide-details class="ml-4"></v-switch>    
                              </v-col>
                              <v-col align="end">
                                <v-btn elevation="0" @click="overlay17 = false;" right plain class="mx-1 my-2" :ripple="false">Cancel</v-btn>
                                <v-btn elevation="0" @click="currentPlayerEdit.notify = notify; editSignup(currentPlayerEdit); overlay17 = false;" right color="green" class="mx-2 my-2">Save</v-btn>
                              </v-col>
                          </v-row>
                        </v-container>

                  </v-card>
                </v-dialog>

                <v-dialog v-if="selectedEvent.title" transition="slide-y-transition" v-model="overlay18" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
               <v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
                    <v-card-text class="my-2 pa-4" style="font-size: 1.3em; color: white;">Add Users</v-card-text>
                    <v-card-text class="px-4 mt-n4" style="font-size: 0.8em;">Enter the name or ID of a member in your server. If you want to create a fake Sign-Up, wrap the name in { } brackets.</v-card-text>

                    <v-divider class="ma-4 mt-0"></v-divider>
                    <v-card v-for="(user, u) in addUsers" :key="u" class="pb-5" style="background-color: transparent;" elevation="0">
                        <v-row class="ma-0 mt-n4">
                          <v-col cols="8">
                            <v-text-field flat dense background-color="#4a4e57" solo hide-details placeholder="Enter a name" v-model="user.name" class="pa-1 mb-n5" full-width single-line>
                            </v-text-field>
                          </v-col>
                            <v-col cols="4">
                            <v-switch style="float: left; margin-top: 7px;" label="notify" color="green lighten-1" v-model="user.notify" hide-details class="ml-4"></v-switch>    

                            </v-col>
                        <v-col cols="12" sm="6" class="pa-0">
                        <v-select dense item-text="name" item-value="id" item-color="white" solo placeholder="Select a class" flat v-model="user.class" @change="user.spec = (getSpecsOfClass(user.class).length === 0 ? user.spec : getSpecsOfClass(user.class)[0].name)" :items="selectedEvent.classes" class="pa-4 pr-2 pt-3 mb-n6" background-color="#4a4e57">
                          <template v-slot:selection="{ item }">
                            <v-avatar tile width="20" height="20">
                              <img :src="'https://cdn.discordapp.com/emojis/' + item.emote + '.png?v=1'" width="20" height="20" class="" />
                            </v-avatar>
                            {{ item.name }}
                          </template>
                          <template v-slot:item="{ item }">
                            <v-avatar tile width="20" height="20">
                              <img :src="'https://cdn.discordapp.com/emojis/' + item.emote + '.png?v=1'" width="20" height="20" class="" />
                            </v-avatar>
                            {{ item.name }}
                          </template>
                        </v-select>
                        </v-col>
                          <v-col cols="12" sm="6" class="pa-0">
                        <v-select :disabled="getSpecsOfClass(user.class).length === 0" solo dense item-text="name" item-value="id" item-color="white" placeholder="Select a spec" flat v-model="user.spec" :items="getSpecsOfClass(user.class)" label="Select a spec" class="pa-4 pl-2 pt-3 mb-n6" background-color="#4a4e57">
                          <template v-slot:selection="{ item }">
                            <v-avatar tile width="20" height="20">
                              <img :src="'https://cdn.discordapp.com/emojis/' + item.emote + '.png?v=1'" width="20" height="20" class="" />
                            </v-avatar>
                            {{ getSpecNameWithout1(item.name) }}
                          </template>
                          <template v-slot:item="{ item }">
                            <v-avatar tile width="20" height="20">
                              <img :src="'https://cdn.discordapp.com/emojis/' + item.emote + '.png?v=1'" width="20" height="20" class="" />
                            </v-avatar>
                            {{ getSpecNameWithout1(item.name) }}
                          </template>
                        </v-select>
                        </v-col>
                          <v-col cols="12" v-if="user.notify">

                            <v-text-field flat dense background-color="#4a4e57" solo hide-details placeholder="Enter a reason that will be sent with the notification (Optional)" v-model="user.reason" class="pa-1 mt-n4" full-width single-line>
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-divider class="mx-4"></v-divider>
                    </v-card>
                      <v-row class="ma-0">
                        <v-col align="center" class="mb-12 mt-5">
                                <v-btn elevation="0" small @click="addUsers.push({notify: true}); scroll();" color="green" fab style="font-weight: 700;" :ripple="false"><v-icon>mdi-plus</v-icon></v-btn>
                        </v-col>
                      </v-row>

                      <v-container>
                          <v-row style="background-color: #2f3136;">
                              <v-col cols="12" align="end" id="shitter">
                                <v-btn elevation="0" @click="overlay18 = false;" right plain class="mx-1 my-2" :ripple="false">Cancel</v-btn>
                                <v-btn elevation="0" @click="addSignups(); overlay18 = false;" right color="green" class="mx-2 my-2">Confirm</v-btn>
                              </v-col>
                          </v-row>
                        </v-container>
                  </v-card>
                </v-dialog>

                <v-dialog v-if="selectedEvent.title" transition="slide-y-transition" v-model="overlay19" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
                  <v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
                    <v-card-text class="mt-2 pa-4" style="font-size: 1.3em; color: white;">Duration</v-card-text>
                    <v-card-text class="mt-n5 pa-4" style="font-size: 0.8em;">Enter the duration of the event in minutes.</v-card-text>
                        <v-text-field flat background-color="#4a4e57" type="number" solo @keydown.enter="editAdvanced('duration', duration); overlay19 = false; overlay1 = false; key++" hide-details :placeholder="selectedEvent.advanced.duration + ''" v-model="duration" class="pa-4 mb-2" full-width single-line>
                        </v-text-field>
                      <div style="background-color: #2f3136; text-align: right;" class="pa-2 mb-0">
                            <v-btn elevation="0" @click="editAdvanced('duration', '0'); overlay19 = false; overlay1 = false; key++" plain right color="red" class="mx-2 my-2">Reset</v-btn>
                            <v-btn :disabled="duration === undefined || duration.length === 0" elevation="0" @click="editAdvanced('duration', duration); overlay19 = false; overlay1 = false; key++" right color="green" class="mx-2 my-2">Ok</v-btn>
                      </div>
                  </v-card>
                </v-dialog>

                <v-dialog v-if="selectedEvent.title" transition="slide-y-transition" v-model="overlay20" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
                  <v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
                    <v-card-text class="mt-2 pa-4" style="font-size: 1.3em; color: white;">Mentions</v-card-text>
                    <v-card-text class="mt-n5 pa-4" style="font-size: 0.8em;">Select roles to be mentioned on event creation.</v-card-text>
                    <v-col cols="10">
                    <v-select class="ma-1" hide-details item-value="id" item-text="name" solo background-color="#313338" flat label="Select roles." multiple :items="chosenDiscordRoles" v-model="mentions">
                      <template v-slot:selection="{ attrs, item, selected }">
                        <v-chip v-bind="attrs" :input-value="selected" small close close-icon="mdi-close-circle" :text-color="'white'" style="font-size: 0.7em; border-radius: 6px;" @click:close="mentions.splice(mentions.indexOf(item.id), 1);mentions = [...mentions]" ><v-icon small class="mr-1 ml-n1" :color="'rgb(' + item.color + ')'">mdi-circle</v-icon>
                        <strong>{{ item.name }}</strong>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-icon v-if="mentions.includes(item.id)" :color="'rgb(' + item.color + ')'" class="mr-5">mdi-checkbox-marked</v-icon>
                        <v-icon v-else class="mr-5">mdi-checkbox-blank-outline</v-icon>
                        <span :style="'color: rgb(' + item.color + ');'">{{ item.name }}</span>
                      </template>
                    </v-select>
                    </v-col>
                      <div style="background-color: #2f3136; text-align: right;" class="pa-2 mb-0">
                            <v-btn elevation="0" @click="overlay20 = false;" plain right color="grey" class="mx-2 my-2">Cancel</v-btn>
                            <v-btn elevation="0" @click="editAdvanced('mentions', mentions); overlay20 = false;" right color="green" class="mx-2 my-2">Save</v-btn>
                      </div>
                  </v-card>
                </v-dialog>

                <v-dialog v-if="selectedEvent.title" transition="slide-y-transition" v-model="overlay21" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
                  <v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
                    <v-card-text class="mt-2 pa-4" style="font-size: 1.3em; color: white;">Allowed Roles</v-card-text>
                    <v-card-text class="mt-n5 pa-4" style="font-size: 0.8em;">Select roles that are allowed to sign up. Leave empty to allow any role.</v-card-text>
                    <v-col cols="12">
                    <v-select class="ma-1 mb-3" hide-details item-value="id" item-text="name" solo background-color="#4a4e57" flat label="Select roles." multiple :items="chosenDiscordRoles" v-model="allowed_roles">
                      <template v-slot:selection="{ attrs, item, selected }">
                        <v-chip v-bind="attrs" :input-value="selected" small close close-icon="mdi-close-circle" :text-color="'white'" style="font-size: 0.7em; border-radius: 6px;" @click:close="allowed_roles.splice(allowed_roles.indexOf(item.id), 1);allowed_roles = [...allowed_roles]" ><v-icon small class="mr-1 ml-n1" :color="'rgb(' + item.color + ')'">mdi-circle</v-icon>
                        <strong>{{ item.name }}</strong>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-icon v-if="allowed_roles.includes(item.id)" :color="'rgb(' + item.color + ')'" class="mr-5">mdi-checkbox-marked</v-icon>
                        <v-icon v-else class="mr-5">mdi-checkbox-blank-outline</v-icon>
                        <span :style="'color: rgb(' + item.color + ');'">{{ item.name }}</span>
                      </template>
                    </v-select>
                    </v-col>
                        <v-container>
                          <v-row style="background-color: #2f3136;">
                              <v-col cols="4" align="start" class="pa-n2">
                                <v-btn elevation="0" @click="editAdvanced('allowed_roles', 'all'); overlay21 = false; allowed_roles = ''" left color="red" style="font-weight: 700; float: left;" class="mx-1 my-2" :ripple="false">Remove</v-btn>
                              </v-col>
                              <v-col cols="8" align="end">
                            <v-btn elevation="0" @click="overlay21 = false;" plain right color="grey" class="mx-2 my-2">Cancel</v-btn>
                            <v-btn elevation="0" @click="editAdvanced('allowed_roles', allowed_roles); overlay21 = false;" right color="green" class="mx-2 my-2">Save</v-btn>
                              </v-col>
                          </v-row>
                        </v-container>
                  </v-card>
                </v-dialog>

                <v-dialog v-if="selectedEvent.title" transition="slide-y-transition" v-model="overlay26" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
                  <v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
                    <v-card-text class="mt-2 pa-4" style="font-size: 1.3em; color: white;">Banned Roles</v-card-text>
                    <v-card-text class="mt-n5 pa-4" style="font-size: 0.8em;">Select roles that are banned from signing up. Leave empty to not ban any role.</v-card-text>
                    <v-col cols="12">
                    <v-select class="ma-1 mb-3" hide-details item-value="id" item-text="name" solo background-color="#4a4e57" flat label="Select roles." multiple :items="chosenDiscordRoles" v-model="banned_roles">
                      <template v-slot:selection="{ attrs, item, selected }">
                        <v-chip v-bind="attrs" :input-value="selected" small close close-icon="mdi-close-circle" :text-color="'white'" style="font-size: 0.7em; border-radius: 6px;" @click:close="banned_roles.splice(banned_roles.indexOf(item.id), 1);banned_roles = [...banned_roles]" ><v-icon small class="mr-1 ml-n1" :color="'rgb(' + item.color + ')'">mdi-circle</v-icon>
                        <strong>{{ item.name }}</strong>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-icon v-if="banned_roles.includes(item.id)" :color="'rgb(' + item.color + ')'" class="mr-5">mdi-checkbox-marked</v-icon>
                        <v-icon v-else class="mr-5">mdi-checkbox-blank-outline</v-icon>
                        <span :style="'color: rgb(' + item.color + ');'">{{ item.name }}</span>
                      </template>
                    </v-select>
                    </v-col>
                        <v-container>
                          <v-row style="background-color: #2f3136;">
                              <v-col cols="4" align="start" class="pa-n2">
                                <v-btn elevation="0" @click="editAdvanced('banned_roles', 'none'); overlay26 = false; banned_roles = ''" left color="red" style="font-weight: 700; float: left;" class="mx-1 my-2" :ripple="false">Remove</v-btn>
                              </v-col>
                              <v-col cols="8" align="end">
                            <v-btn elevation="0" @click="overlay26 = false;" plain right color="grey" class="mx-2 my-2">Cancel</v-btn>
                            <v-btn elevation="0" @click="editAdvanced('banned_roles', banned_roles); overlay26 = false;" right color="green" class="mx-2 my-2">Save</v-btn>
                              </v-col>
                          </v-row>
                        </v-container>
                  </v-card>
                </v-dialog>

                <v-dialog v-if="selectedEvent.title" transition="slide-y-transition" v-model="overlay25" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
                  <v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
                    <v-card-text class="mt-2 pa-4" style="font-size: 1.3em; color: white;">Temporary Role</v-card-text>
                    <v-card-text class="mt-n5 pa-4" style="font-size: 0.8em;">Enter a temporary role.</v-card-text>
                    <v-col cols="12">
                        <v-text-field flat background-color="#4a4e57" solo @keydown.enter="editAdvanced('temp_role', temporary_role); overlay25 = false;" hide-details :placeholder="selectedEvent.temprole + ''" v-model="temporary_role" class="pa-1 mb-2" full-width single-line>
                        </v-text-field>
                    </v-col>

                        <v-container>
                          <v-row style="background-color: #2f3136;">
                              <v-col cols="4" align="start" class="pa-n2">
                                <v-btn elevation="0" @click="editAdvanced('temp_role', 'false'); overlay25 = false; temporary_role = ''" left color="red" style="font-weight: 700; float: left;" class="mx-1 my-2" :ripple="false">Remove</v-btn>
                              </v-col>
                              <v-col cols="8" align="end">
                            <v-btn elevation="0" @click="overlay25 = false;" plain right color="grey" class="mx-2 my-2">Cancel</v-btn>
                            <v-btn elevation="0" @click="editAdvanced('temp_role', temporary_role); overlay25 = false;" right color="green" class="mx-2 my-2">Save</v-btn>
                              </v-col>
                          </v-row>
                        </v-container>
                  </v-card>
                </v-dialog>

                <v-dialog v-if="selectedEvent.title" transition="slide-y-transition" v-model="overlay22" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
                  <v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
                    <v-card-text class="my-2 pa-4" style="font-size: 1.3em; color: white;">Voice Channel</v-card-text>
                    <v-card-text class="px-4 mt-n4" style="font-size: 0.8em;">Select a Voice Channel for this event.</v-card-text>

                    <v-card class="pb-3 pt-2" style="background-color: transparent;" elevation="0">
                        <v-row class="ma-0 mt-n2">
                        <v-col class="ml-1 mr-2">
                    <v-select hide-details item-text="name" item-value="id" style="z-index: 7" item-color="green" solo v-model="voice_channel" :items="$store.state.discordVoiceChannels" label="Select a channel." background-color="#4a4e57" flat>
                             <template v-slot:selection="{ item }">
                            <v-avatar tile width="20" height="20">
                              <img :src="'https://cdn.discordapp.com/emojis/' + getChannelIconByType(item.type) + '.png?v=1'" width="20" height="20" class="ml-n7" />
                            </v-avatar>
                             <span class="ml-n5" style="font-size: 14px;">{{ item.name }}</span>
                          </template>
                          <template v-slot:item="{ item }"> 
                            <v-avatar tile width="20" height="20">
                              <img :src="'https://cdn.discordapp.com/emojis/' + getChannelIconByType(item.type) + '.png?v=1'" width="20" height="20" class="ml-n7" />
                            </v-avatar>
                            <span class="ml-n5" style="font-size: 14px;">{{ item.name }}</span>
                          </template>
                    </v-select>                     
                    </v-col>
                        </v-row>
                    </v-card>

                      <v-container>
                          <v-row style="background-color: #2f3136;">
                              <v-col cols="4" align="start" class="pa-n2">
                                <v-btn elevation="0" @click="editAdvanced('voice_channel', 'none'); overlay22 = false;" left color="red" style="font-weight: 700; float: left;" class="mx-1 my-2" :ripple="false">Remove</v-btn>
                              </v-col>
                              <v-col cols="8" align="end">
                            <v-btn elevation="0" @click="overlay22 = false;" plain right color="grey" class="mx-2 my-2">Cancel</v-btn>
                            <v-btn elevation="0" @click="editAdvanced('voice_channel', voice_channel); overlay22 = false;" right color="green" class="mx-2 my-2">Save</v-btn>
                              </v-col>
                          </v-row>
                        </v-container>
                  </v-card>
                </v-dialog>

                <v-dialog v-if="selectedEvent.title" transition="slide-y-transition" v-model="overlay23" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
               <v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
                    <v-card-text class="my-2 pa-4" style="font-size: 1.3em; color: white;">Announcement</v-card-text>
                    <v-card-text v-if="announcementType == undefined" class="px-4 mt-n4" style="font-size: 0.8em;">You can edit the events scheduled announcement or send an announcement immediately.</v-card-text>
                    <v-card-text v-else-if="announcementType == 'schedule'" class="px-4 mt-n4" style="font-size: 0.8em;">Edit the announcement set for this event. You can only have one announcement per event.<br>The time is entered in minutes before the events start time.</v-card-text>
                    <v-card-text v-else-if="announcementType == 'immediately'" class="px-4 mt-n4" style="font-size: 0.8em;">Send an immediate announcement.</v-card-text>

                  <v-col v-if="announcementType == undefined">
                    <v-row justify="center" class="pb-5">
                      <v-btn v-if="announcementType == undefined" elevation="0" @click="announcementType = 'schedule'" right color="purple" class="mx-2 my-2">Schedule</v-btn>
                      <v-btn v-if="announcementType == undefined" elevation="0" @click="announcementType = 'immediately'" right color="purple" class="mx-2 my-2">Immediately</v-btn>
                    </v-row>
                  </v-col>

                    <v-card class="pb-5" style="background-color: transparent;" elevation="0" v-if="announcementType == 'schedule'">
                        <v-row class="ma-0 mt-n4">
                        <v-col cols="6">
                    <v-select height="40" hide-details item-text="name" item-value="id" solo background-color="#4a4e57" flat dense placeholder="Select a channel." item-color="green" v-model="announcementChannel" :items="getChannels()" class="py-1 pl-1 mb-n5">
                      <template v-slot:selection="{ item }">
                            <v-avatar tile width="20" height="20">
                              <img :src="'https://cdn.discordapp.com/emojis/' + getChannelIconByType(item.type) + '.png?v=1'" width="20" height="20" class="ml-n7" />
                            </v-avatar>
                             <span class="ml-n5" style="font-size: 14px;">{{ item.name }}</span>
                          </template>
                          <template v-slot:item="{ item }"> 
                            <v-avatar tile width="20" height="20">
                              <img :src="'https://cdn.discordapp.com/emojis/' + getChannelIconByType(item.type) + '.png?v=1'" width="20" height="20" class="ml-n7" />
                            </v-avatar>
                            <span class="ml-n5" style="font-size: 14px;">{{ item.name }}</span>
                          </template>
                    </v-select>
                          </v-col>
                        <v-col cols="6">
                            <v-text-field flat dense background-color="#4a4e57" type="number" solo hide-details placeholder="Enter a time in minutes." v-model="announcementTime" class="pr-1 py-1 mb-n5" full-width>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-textarea rows="4" flat dense background-color="#4a4e57" solo hide-details placeholder="Enter a message." v-model="announcementMessage" class="pa-1 mb-n5" full-width>
                            </v-textarea>
                          </v-col>
                
                        </v-row>
                        <v-divider class="mx-4"></v-divider>
                    </v-card>

                    <v-card class="pb-5" style="background-color: transparent;" elevation="0" v-if="announcementType == 'immediately'">
                        <v-row class="ma-0 mt-n4">
                        <v-col cols="12">
                    <v-select height="40" hide-details item-text="name" item-value="id" solo background-color="#4a4e57" flat dense placeholder="Select a channel." item-color="green" v-model="announcementChannel2" :items="getChannels()" class="py-1 px-1 mb-n5">
                      <template v-slot:selection="{ item }">
                            <v-avatar tile width="20" height="20">
                              <img :src="'https://cdn.discordapp.com/emojis/' + getChannelIconByType(item.type) + '.png?v=1'" width="20" height="20" class="ml-n7" />
                            </v-avatar>
                             <span class="ml-n5" style="font-size: 14px;">{{ item.name }}</span>
                          </template>
                          <template v-slot:item="{ item }"> 
                            <v-avatar tile width="20" height="20">
                              <img :src="'https://cdn.discordapp.com/emojis/' + getChannelIconByType(item.type) + '.png?v=1'" width="20" height="20" class="ml-n7" />
                            </v-avatar>
                            <span class="ml-n5" style="font-size: 14px;">{{ item.name }}</span>
                          </template>

                    </v-select>
                          </v-col>
                          <v-col cols="12">
                            <v-textarea rows="4" flat dense background-color="#4a4e57" solo hide-details placeholder="Enter a message." v-model="announcementMessage2" class="pa-1 mb-n5" full-width>
                            </v-textarea>
                          </v-col>
                
                        </v-row>
                        <v-divider class="mx-4"></v-divider>
                    </v-card>

                      <v-container>
                          <v-row style="background-color: #2f3136;">
                              <v-col cols="4" align="start" class="pa-n2">
                                <v-btn elevation="0" v-if="announcementType == 'schedule'" @click="removeAnnouncement(); overlay23 = false;" left color="red" style="font-weight: 700; float: left;" class="mx-1 my-2" :ripple="false">Remove</v-btn>
                              </v-col>
                              <v-col cols="8" align="end">
                                <v-btn elevation="0" @click="overlay23 = false;" right plain class="mx-1 my-2" :ripple="false">Cancel</v-btn>
                                <v-btn elevation="0" v-if="announcementType == 'schedule'" @click="editAnnouncement(); overlay23 = false;" right color="green" class="mx-2 my-2">Save</v-btn>
                                <v-btn elevation="0" v-else-if="announcementType == 'immediately'" @click="sendAnnouncement(); overlay23 = false;" right color="green" class="mx-2 my-2">Send</v-btn>
                              </v-col>
                          </v-row>
                        </v-container>
                  </v-card>
                </v-dialog>

		            <v-dialog transition="slide-y-transition" v-model="editPermsOverlay" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
		            	<v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
		            		<v-card-text class="mt-2 pa-4" style="font-size: 1.3em; color: white">Edit Permissions</v-card-text>
		            		<v-card-text class="mt-n3 pt-4 pa-2 px-4" style="font-size: 0.9em">This raidplan can be edited by members that:</v-card-text>
		            		<v-card-text>· have the admin or manage server permission<br>· have a manager role<br>· have an assistant role and are the leader of the event.</v-card-text>
		            		<v-card-text v-if="!$store.state.authenticated" class="mt-10" style="text-align: center; font-size: 1.2em;">You also need to be logged in. Click below to login!</v-card-text>
		            		<div v-if="!$store.state.authenticated" class="mb-10" style="text-align: center;"><v-btn elevation="0" color="purple" @click="login()">LOGIN</v-btn></div>
		            		<div style="background-color: #2f3136; text-align: right" class="pa-2 mb-0">
		            			<v-btn elevation="0" @click="editPermsOverlay = false;" right plain class="mx-1 my-2" :ripple="false" >Close</v-btn>
		            		</div>
		            	</v-card>
		            </v-dialog>


    </v-container>

      		<v-snackbar elevation="0" :color="editresultText === 'Event updated!' || editresultText === 'Event moved!' ? 'green' : 'red'" v-model="snackbar" timeout="2500" ><span style="font-size:15px;">{{ editresultText }}</span>
          </v-snackbar>

          <v-snackbar elevation="0" color="red" v-model="ratelimitSnackbar" timeout="4500" ><span style="font-size:15px;">{{ ratelimitText }}</span>
          </v-snackbar>

          <v-snackbar elevation="0" :color="googleCalResultText === 'Event inserted into Google Calendar!' ? 'green' : 'red'" v-model="snackbarGooglecal" timeout="2500" ><span style="font-size:15px;">{{ googleCalResultText }}</span>
          </v-snackbar>

  </v-container>
</template>
<script>
import axios from "axios";
import Store from "../../store/index";
import Images from "../events/ImageSelection";
import Advanced from "../events/AdvancedSelection";
import AdvancedDocs from "../Advanced";
import Timer from "../events/Timer";
import DOMPurify from 'dompurify';

export default {
    components: {
    Images,
    Advanced,
    AdvancedDocs,
    Timer
  },

  data() {
    return {
      snackbarGooglecal: false,
      addUsers: [],
      jusTesting: undefined,
      isLoaded: false,
      timeout: undefined,
      selectedEvent: undefined,
      uniqueClasses: undefined,
      uniqueStatus: undefined,
      descriptionText: undefined,
      titleText: undefined,
      leaderText: undefined,
      advanced: undefined,
      editroleName: undefined,
      editroleAmount: undefined,
      editroleAmount1: undefined,
      editclassName: undefined,
      editclassAmount: undefined,
      editclassAmount1: undefined,
      advancedLimit: undefined,
      deadline: undefined,
      duration: undefined,
      hasPrimarySignups: false,
      isOpen: true,
      key: 0,
      imageUrl: undefined,
      snackbar: false,
      showClasses: false,
      channel: false,
      mentions: [],
      allowed_roles: [],
      banned_roles: [],
      temporary_role: undefined,
      voice_channel: undefined,
      voice_type: "voice",
      overlay: false,
      overlay1: false,
      overlay2: false,
      overlay3: false,
      overlay4: false,
      overlay5: false,
      overlay6: false,
      overlay7: false,
      overlay8: false,
      overlay9: false,
      overlay10: false,
      overlay11: false,
      overlay12: false,
      overlay13: false,
      overlay14: false,
      overlay15: false,
      overlay16: false,
      overlay17: false,
      overlay18: false,
      overlay19: false,
      overlay20: false,
      overlay21: false,
      overlay22: false,
      overlay23: false,
      overlay24: false,
      overlay25: false,
      overlay26: false,
      currentPlayerEdit: undefined,
			editPermsOverlay: false,
      color: undefined,
      interval: undefined,
      ratelimitSnackbar: false,
      ratelimitText: undefined,
      editresultText: undefined,
      googleCalResultText: undefined,
      unixtime: undefined,
      posttime: undefined,
      countDownInterval: undefined,
      timer: undefined,
      timerCount: undefined,
      days: undefined,
      hours: undefined,
      minutes: undefined,
      setDynamicDate: false,
      interval2Ordinal: "",
      interval2Weekday: "",
      intervalUnit: "days",
      colorstyle: "width: 4px; height: 100%; position: absolute; border-top-left-radius: 5px; border-bottom-left-radius: 5px; z-index: 20; background-color: red;",
      isMobile: false,
      chosenDiscordRoles: [],
      announcementChannel: undefined,
      announcementTime: undefined,
      announcementMessage: undefined,
      announcementChannel2: undefined,
      announcementMessage2: undefined,
      announcementType: undefined,
      notify: true,


      ordinals: [
        {name: "First"},
        {name: "Second"},
        {name: "Third"},
        {name: "Fourth"},
        {name: "Last"},
      ],

      weekdays: [
        {name: "Monday"},
        {name: "Tuesday"},
        {name: "Wednesday"},
        {name: "Thursday"},
        {name: "Friday"},
        {name: "Saturday"},
        {name: "Sunday"},
      ],

    };
  },

  	sockets: {
    	connect() {
    	  this.isConnected = true;
    	},
	
    	disconnect() {
    	  this.isConnected = false;
    	},

		liveUpdateEvent(last_updated) {
      if (this.last_updated < last_updated && !this.hasOpenOverlay()) {
        this.last_updated = last_updated;
        this.getEvent();
      }
		}
  },
        
  methods: {

    promtGoogleCal(){
      let req = {
        discordId: this.$store.state.discordUser.id,
        event: this.selectedEvent,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }; 

      axios.post(Store.state.url + "/checkGoogleCalEntry/", req)
      .then(response => {
        if (response.data.authUrl) {
          window.location.href = response.data.authUrl
        } else {
          this.googleCalResultText = response.data.result
          this.snackbarGooglecal = true;
        }
      }).catch(error => {
        console.log(error)}
        );
    },

    getSpecNameWithout1(specName) {
      if (this.selectedEvent.template == 17 || this.selectedEvent.template == 18 || this.selectedEvent.template == 19 || this.selectedEvent.template == 20 || this.selectedEvent.template == 21 || this.selectedEvent.template == 22 || this.selectedEvent.template == 31 || this.selectedEvent.template == 32 || this.selectedEvent.template == 33 || this.selectedEvent.template == 34 || this.selectedEvent.template == 35 || this.selectedEvent.template == 36) {
        return specName.replace("_", "");
      } else {
        return specName.replace("1", " ").replace("_", "");
      }
    },

    getChannelIconByType(type) {
      if (type === "text") {
        return "1025776950096171148";
      } else if (type === "forum") {
        return "1025776945146904629";
      } else if (type === "thread") {
        return "1025779885127516170";
      } else if (type === "post") {
        return "1025779860838301810";
      } else if (type === "news") {
        return "1025776946698784861";
      } else if (type === "stage") {
        return "1025776948288426068";
      } else if (type === "dm") {
        return "1026094422015877171";
      } else {
        return "1025776951627100210";
      }
    },

    goBackEvent(){
      if (this.selectedEvent.permission && this.$store.state.chosenDiscord && this.selectedEvent.serverid === this.$store.state.chosenDiscord.id) {
        if (localStorage.getItem("calendar") == "true") {
          localStorage.removeItem("calendar")
          this.$router.push('/calendar/' + this.selectedEvent.serverid).catch(() => {})
        } else {
          if (this.selectedEvent.interval ) {
            this.$router.push('/schedule/').catch(() => {})
          } else {
            if (this.selectedEvent.unixtime*1000 > new Date().getTime() ) {
              this.$router.push('/upcoming/').catch(() => {}) 
            } else {
              this.$router.push('/closed/').catch(() => {})
            }
          }
        }

      } else {
        this.$router.push('/dashboard/').catch(() => {})
      }

    },

    getChannels() {
      let channelNameList = [];
      channelNameList.push({id: "unsigned", name: "DM - unsigned members", position: -1, type: "dm" });
      channelNameList.push({id: "signed", name: "DM - signed members", position: -2, type: "dm" });
      channelNameList.push({id: "signedrostered", name: "DM - rostered members", position: -3, type: "dm" });
      channelNameList.push({id: "signedrosteredconfirmed", name: "DM - confirmed rostered members", position: -4, type: "dm" });
      channelNameList.push({id: "signedrosteredunresponded", name: "DM - unresponded rostered members", position: -5, type: "dm" });

      channelNameList.push({id: "unsignedping", name: "Ping - unsigned members", position: -6, type: "dm" });
      channelNameList.push({id: "signedping", name: "Ping - signed members", position: -7, type: "dm" });
      channelNameList.push({id: "signedrosteredping", name: "Ping - rostered members", position: -8, type: "dm" });
      channelNameList.push({id: "signedrosteredpingconfirmed", name: "Ping - confirmed rostered members", position: -9, type: "dm" });
      channelNameList.push({id: "signedrosteredpingunresponded", name: "Ping - unresponded rostered members", position: -10, type: "dm" });

      try {
      for (let index = 0; index < this.$store.state.discordChannels.length; index++) {
        let signedChannel = {
          id: this.$store.state.discordChannels[index].id,
          name: this.$store.state.discordChannels[index].name,
          position: this.$store.state.discordChannels[index].position,
          type: this.$store.state.discordChannels[index].type,
        };
        if (this.$store.state.discordChannels[index].type !== "forum") {
          channelNameList.push(signedChannel);
        }
      }
      } catch { }

      return channelNameList;
    },

    getIntervalString() {
      try {
      let intervalText;
      let interval = this.selectedEvent.interval
      let interval2 = this.selectedEvent.interval2
      let intervalNumber = interval.replace(/[a-zA-Z]/g, "").trim();

      if (interval.includes("minutes")) {
        if (!isNaN(intervalNumber) && parseFloat(intervalNumber) > 1) {
          intervalText = intervalNumber + " minutes";
        } else {
           intervalText = intervalNumber + " minute";
        }
      } else if (interval.includes("hours")) {
        if (!isNaN(intervalNumber) && parseFloat(intervalNumber) > 1) {
          intervalText = intervalNumber + " hours";
        } else {
           intervalText = intervalNumber + " hour";
        }
      } else if (interval.includes("days")) {
        if (!isNaN(intervalNumber) && parseFloat(intervalNumber) > 1) {
          intervalText = intervalNumber + " days";
        } else {
           intervalText = intervalNumber + " day";
        }
      } else if (interval.includes("months")) {
        if (!isNaN(intervalNumber) && parseFloat(intervalNumber) > 1) {
          intervalText = intervalNumber + " months";
        } else {
           intervalText = intervalNumber + " month";
        }
      } else if (interval.includes("h") || interval.includes("H")) {
        if (!isNaN(intervalNumber) && parseFloat(intervalNumber) > 1) {
          intervalText = intervalNumber + " hours";
        } else {
           intervalText = intervalNumber + " hour";
        }
      } else if (interval.includes("m") || interval.includes("M")) {
        if (!isNaN(intervalNumber) && parseFloat(intervalNumber) > 1) {
          intervalText = intervalNumber + " minutes";
        } else {
           intervalText = intervalNumber + " minute";
        }
      } else {
        if (!isNaN(intervalNumber) && parseFloat(intervalNumber) > 1) {
          intervalText = intervalNumber + " days";
        } else {
           intervalText = intervalNumber + " day";
        }
      }

      if (interval2 != undefined && interval2 !== "0") {
        let [ordinal, weekday] = interval2.split("-");
        if (ordinal && weekday) {
          let capitalizedOrdinal = ordinal.charAt(0).toUpperCase() + ordinal.slice(1);
          let capitalizedWeekday = weekday.charAt(0).toUpperCase() + weekday.slice(1);
          intervalText = capitalizedOrdinal + " " + capitalizedWeekday + " of every month, posted " + intervalText + " in advance.";
        }
      }
      return intervalText;
      } catch (error) {
        return "-";
      }
    },

    login() {
			localStorage.setItem('lastPage', this.$router.currentRoute.path)
			window.location.href = this.$store.state.url + "/api/auth/discord";
        return;
    },

    getSpecsOfClass(className) {
      for (let k = 0; k < this.selectedEvent.classes.length; k++) {
        if (this.selectedEvent.classes[k].name === className) {
          return this.selectedEvent.classes[k].specs;
        }
      }
      return [];
    },

    getServerIcon() {
      try {
        if (this.selectedEvent.servericon === undefined) {
          return false;
        } else {
          return this.selectedEvent.servericon
        }
      } catch (error) {
        return false;
      }
    },

    getMentions() {
      let mentionString = "";
      for (let i = 0; i < this.chosenDiscordRoles.length; i++) {
        if (this.selectedEvent.advanced.mentions.includes(this.chosenDiscordRoles[i].name) || this.selectedEvent.advanced.mentions.includes(this.chosenDiscordRoles[i].id)) {
          mentionString += "<span style='color: rgb(" + this.chosenDiscordRoles[i].color + ");'>@" + this.chosenDiscordRoles[i].name + "</span> ";
        }
      }
      return mentionString;
    },

    getAllowedRoles() {
      let allowedString = "";
      for (let i = 0; i < this.chosenDiscordRoles.length; i++) {
        let splitAllowedRoles = this.selectedEvent.advanced.allowed_roles.split(",");
        for (let k = 0; k < splitAllowedRoles.length; k++) {
          if (splitAllowedRoles[k].trim() == this.chosenDiscordRoles[i].name || splitAllowedRoles[k].trim() == this.chosenDiscordRoles[i].id) {
            allowedString += "<span style='color: rgb(" + this.chosenDiscordRoles[i].color + ");'>@" + this.chosenDiscordRoles[i].name + "</span> ";
          }
        }
      }
      return allowedString;
    },

    getBannedRoles() {
      let bannedString = "";
      for (let i = 0; i < this.chosenDiscordRoles.length; i++) {
        let splitBannedRoles = this.selectedEvent.advanced.banned_roles.split(",");
        for (let k = 0; k < splitBannedRoles.length; k++) {
          if (splitBannedRoles[k].trim() == this.chosenDiscordRoles[i].name || splitBannedRoles[k].trim() == this.chosenDiscordRoles[i].id) {
            bannedString += "<span style='color: rgb(" + this.chosenDiscordRoles[i].color + ");'>@" + this.chosenDiscordRoles[i].name + "</span> ";
          }
        }
      }
      return bannedString;
    },

    getTemporaryRoles() {
      let temporaryRoleString = "";
      for (let i = 0; i < this.chosenDiscordRoles.length; i++) {

        if (this.selectedEvent.temprole == this.chosenDiscordRoles[i].name || this.selectedEvent.temprole == this.chosenDiscordRoles[i].id) {
          temporaryRoleString += "<span style='color: rgb(" + this.chosenDiscordRoles[i].color + ");'>@" + this.chosenDiscordRoles[i].name + "</span> ";
          break;
        }
      }
      if(temporaryRoleString.length == 0 && this.selectedEvent.temprole != 'false') {
        temporaryRoleString = "@" + this.selectedEvent.temprole;
      }
      return temporaryRoleString;
    },

    getVoiceChannel() {
      for (let i = 0; i < Store.state.discordVoiceChannels.length; i++) {
        if (this.selectedEvent.advanced.voice_channel.includes(Store.state.discordVoiceChannels[i].name) || this.selectedEvent.advanced.voice_channel.includes(Store.state.discordVoiceChannels[i].id)) {
          return Store.state.discordVoiceChannels[i].name;
        }
      }
      return "";
    },

    getEndTime() {
      if (this.selectedEvent.advanced.duration === '' || this.selectedEvent.advanced.duration === 0 || this.selectedEvent.advanced.duration === "0" || this.selectedEvent.advanced.duration === undefined) {
        return ""
      } else {
        if (this.selectedEvent.advanced.duration < 1440) {
          return " - " + new Date(this.selectedEvent.unixtime*1000 + 1000*60*parseInt(this.selectedEvent.advanced.duration)).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
        } else {
          return " - " + new Date(this.selectedEvent.unixtime*1000 + 1000*60*parseInt(this.selectedEvent.advanced.duration)).toLocaleDateString([],{ month: 'long', day: 'numeric', hour: '2-digit', minute:'2-digit' })
        }
      }
    },

    getClassColor(className, specName) {
      if (this.isWoW()) {
			if (className === 'Death Knight' || className === 'DK' || ['Blood', 'Unholy', 'Frost1'].includes(specName)) {
				return '#C41E3A';
			} else if (className === 'Demon Hunter' || className === 'DH' || ['Havoc', 'Vengeance'].includes(specName)) {
				return '#A330C9';
			} else if (className === 'Druid' || ['Guardian', 'Feral', 'Balance'].includes(specName)) {
				return '#FF7C0A';
			} else if (className === 'Hunter' || ['Beastmastery', 'Survival', 'Marksmanship'].includes(specName)) {
				return '#AAD372';
			} else if (className === 'Mage' || ['Frost', 'Fire', 'Arcane'].includes(specName)) {
				return '#3FC7EB';
			} else if (className === 'Monk' || ['Brewmaster', 'Windwalker', 'Mistweaver'].includes(specName)) {
				return '#00FF98';
			} else if (className === 'Paladin' || ['Holy1', 'Retribution', 'Protection1'].includes(specName)) {
				return '#F48CBA';
			} else if (className === 'Priest' || ['Holy', 'Discipline', 'Shadow', 'Smite'].includes(specName)) {
				return '#FFFFFF';
			} else if (className === 'Rogue' || ['Assassination', 'Combat', 'Subtlety'].includes(specName)) {
				return '#FFF468';
			} else if (className === 'Shaman' || ['Enhancement', 'Elemental'].includes(specName)) {
				return '#0070DD';
			} else if (className === 'Warlock' || ['Demonology', 'Destruction', 'Affliction'].includes(specName)) {
				return '#8788EE';
			} else if (className === 'Warrior' || ['Arms', 'Fury', 'Protection'].includes(specName)) {
				return '#C69B6D';
			}
      }
			return 'white';
		},

    updateCountdown() {
      if (this.selectedEvent) {
        if ((this.selectedEvent.unixtime*1000 - new Date().getTime()) < 0) {
          this.days = 0;
          this.hours = 0;
          this.minutes = 0;
        } else {
          let delta = Math.abs(this.selectedEvent.unixtime*1000 - new Date().getTime()) / 1000;
          this.days = Math.floor(delta / 86400);
          delta -= this.days * 86400;
          this.hours = Math.floor(delta / 3600) % 24;
          delta -= this.hours * 3600;
          this.minutes = Math.floor(delta / 60) % 60;
          delta -= this.minutes * 60;
        }
      }
    },

    isWoW() {
      if (this.selectedEvent.template === "1" || this.selectedEvent.template === "2" || this.selectedEvent.template === "3"  || this.selectedEvent.template === "4"  || this.selectedEvent.template === "5" || this.selectedEvent.template === "31" || this.selectedEvent.template === "40" || this.selectedEvent.template === "41") {
          return true;
      } else {
          return false;
      }
    },

    getDescriptionWithMarkdown() {
      let desc = this.selectedEvent.description;
      desc = desc.replace(/\[([\s\S]+?)\]\(((https|http)?:\/\/[\s\S]+?)\)/g, "<a style='text-decoration: none; color: #009ddb; font-weight: 400;' href='$2' target='_blank'>$1</a>");
      desc = desc.replace(/\*\*(\**[\s\S]*?\**)\*\*/g, "<span style='font-weight:600;'>$1</span>");
      desc = desc.replace(/\_\_(\**[\s\S]*?\**)\_\_/g, "<u>$1</u>");
      desc = desc.replace(/\*(\**[\s\S]*?\**)\*/g, "<i>$1</i>");
      desc = desc.replace(/\~\~(\**[\s\S]*?\**)\~\~/g, "<strike>$1</strike>");
      return DOMPurify.sanitize(desc);
    },

    hasOpenOverlay() {
      return this.overlay1 || this.overlay2 || this.overlay3 || this.overlay4 || this.overlay5 || this.overlay6 || this.overlay7 || this.overlay8 || this.overlay9 || this.overlay10 || this.overlay11 || this.overlay12 || this.overlay13 || this.overlay14 || this.overlay15 || this.overlay16
    },

    forceUpdate() {
      this.key++;
    },

    setImage(variable) {
      this.imageUrl = variable;
    },

    setAdvanced(variable) {
      this.advanced = variable;
    },

    async updateAdvanced() {
      let advanced = this.advanced;
      let req = {
        serverid: this.selectedEvent.serverid,
        accessToken: localStorage.getItem('accessToken').slice(1,-1),
        eventid: this.selectedEvent.raidid,
        edit: {
            advanced
          }
      }; 

      axios.post(Store.state.url + "/api/edit/", req)
      .then(response => {
        this.selectedEvent = response.data;
        this.editresultText = response.data.editresult;
        this.parseEvent();
        this.snackbar = true;
      }).catch(error => {
          if (error.response.data.status === 'failed') {
            this.ratelimitText = error.response.data.reason;
            this.ratelimitSnackbar = true;
          }
      });
    },

    updateEventStatus() {
      let status;

      if (this.isOpen) {
        status = "open";
      } else {
        status = "close";
      }

      let req = {
        serverid: this.selectedEvent.serverid,
        accessToken: localStorage.getItem('accessToken').slice(1,-1),
        eventid: this.selectedEvent.raidid,
        edit: {
          status: status
          }
      };
     
      axios.post(Store.state.url + "/api/edit/", req)      
      .then(response => {
        this.selectedEvent = response.data;
        this.editresultText = response.data.editresult;
        this.parseEvent();
        this.snackbar = true;
      }).catch(error => {
          if (error.response.data.status=== 'failed') {
            this.ratelimitText = error.response.data.reason;
            this.ratelimitSnackbar = true;
          }
      });
    },

    getSignupsOfClass(key) {
      let signupsOfClass = [];
      let sorted = [];
      if (!this.selectedEvent.interval) {
        for (let i = 0; i < this.selectedEvent.signups.length; i++) {
          if (this.selectedEvent.signups[i].class_emote === key || this.selectedEvent.signups[i].class === key) {
            signupsOfClass.push(this.selectedEvent.signups[i]);
          }
        }
      }
      let specs = new Set();
      for (let i = 0; i < signupsOfClass.length; i++) {
          specs.add(signupsOfClass[i].spec);
      }

      signupsOfClass.sort(function (a, b) {
        return a.signuptime - b.signuptime;
      });

      let preSortedSpecs = [];
      specs.forEach(key => {
        let count = 0;
        let positionsCombined = 0;
        for (let i = 0; i < signupsOfClass.length; i++) {
          if (signupsOfClass[i].spec == key) {
            count++;
            positionsCombined += signupsOfClass[i].position;
          }
        }
        let average = positionsCombined / count;
        preSortedSpecs.push({spec: key, position: average});
      })

      preSortedSpecs.sort(function (a, b) {
        return a.position - b.position;
      });

      preSortedSpecs.forEach(key => {
        for (let i = 0; i < signupsOfClass.length; i++) {
          if (signupsOfClass[i].spec == key.spec) {
            sorted.push(signupsOfClass[i]);
          }
        }
      })
      return sorted;
    },

    getSignupsOfRole(key) {
      let signupsOfRole = [];
      for (let i = 0; i < this.selectedEvent.signups.length; i++) {
        if (this.selectedEvent.signups[i].role_emote === key) {
          signupsOfRole.push(this.selectedEvent.signups[i]);
        }
      }
      return signupsOfRole;
    },

    getPollImage() {
      try {
      let percentage = this.getSignupsOfClass("596014739398721536").length / this.selectedEvent.signups.length
            if (percentage >= .99) {
                return "https://i.imgur.com/OjaK62f.png";
            } else if (percentage >= 0.97) {
                return "https://i.imgur.com/PLWtEmy.png";
            } else if (percentage >= 0.93) {
                return "https://i.imgur.com/ExfvxQB.png";
            } else if (percentage >= 0.88) {
                return "https://i.imgur.com/Vc1F2aH.png";
            } else if (percentage >= 0.83) {
                return "https://i.imgur.com/nojuGFF.png";
            } else if (percentage >= 0.78) {
                return "https://i.imgur.com/xpggalV.png";
            } else if (percentage >= 0.73) {
                return "https://i.imgur.com/yEnsvMt.png";
            } else if (percentage >= 0.68) {
                return "https://i.imgur.com/pGgqfIU.png";
            } else if (percentage >= 0.63) {
                return "https://i.imgur.com/4Q5uHRY.png";
            } else if (percentage >= 0.58) {
                return "https://i.imgur.com/uVISjyT.png";
            } else if (percentage >= 0.53) {
                return "https://i.imgur.com/z9zSh4l.png";
            } else if (percentage >= 0.48) {
                return "https://i.imgur.com/DPbBzDs.png";
            } else if (percentage >= 0.43) {
                return "https://i.imgur.com/gVtQkkw.png";
            } else if (percentage >= 0.38) {
                return "https://i.imgur.com/E8LFVNV.png";
            } else if (percentage >= 0.33) {
                return "https://i.imgur.com/t7y8ESb.png";
            } else if (percentage >= 0.28) {
                return "https://i.imgur.com/Wf2OFXx.png";
            } else if (percentage >= 0.23) {
                return "https://i.imgur.com/XcTAdHm.png";
            } else if (percentage >= 0.18) {
                return "https://i.imgur.com/wsWt0l8.png";
            } else if (percentage >= 0.13) {
                return "https://i.imgur.com/H5pkvCW.png";
            } else if (percentage >= 0.08) {
                return "https://i.imgur.com/8hbsqfS.png";
            } else if (percentage >= 0.03) {
                return "https://i.imgur.com/qSALLTU.png";
            } else if (percentage >= 0) {
                return "https://i.imgur.com/fEfT3Hv.png";
            }
      } catch (error) { }
    },

    openRaidplan(){
      this.$store.state.raidplan.raidId = this.selectedEvent.raidid
      this.$router.push({ path: '/raidplan/' + this.selectedEvent.raidid  })
    },

scroll() {
document.getElementById("shitter").scrollIntoView(false);


},

    isPoll() {
      if (this.selectedEvent.template === "23") {
        return true;
      } else {
        return false;
      }
    },
    isStrawpoll() {
      if (this.selectedEvent.template === "24" || this.selectedEvent.template === "25") {
        return true;
      } else {
        return false;
      }
    },
    getPollAnswer(number) {
        if (this.selectedEvent.description.includes("=")) {
          return this.selectedEvent.description.split("=")[1].split(",")[number];
        }
    },
    getPollClasses() {
      let classes = [];
      if (this.selectedEvent.description.includes("=")) {
          let classNames = this.selectedEvent.description.split("=")[1].split(",");
          for (let i = 0; i < classNames.length; i++) {
            this.selectedEvent.classes[i].answer = classNames[i];
            this.selectedEvent.classes[i].count = this.getSignupsOfClass(this.selectedEvent.classes[i].emote).length;
            if (this.getSignupsOfClass(this.selectedEvent.classes[i].emote).length > 0) {
            this.selectedEvent.classes[i].percentage = Math.round(this.getSignupsOfClass(this.selectedEvent.classes[i].emote).length / this.selectedEvent.signups.length *100);
            } else {
            this.selectedEvent.classes[i].percentage = 0;
            }
            classes.push(this.selectedEvent.classes[i]);
          }
        }
        if (this.selectedEvent.template === "24") {
          classes.sort((a, b) => (a.count < b.count) ? 1 : -1);
        }
        return classes;
    }, 

    getDescription() {
      if (this.selectedEvent.template === "23" || this.selectedEvent.template === "24" || this.selectedEvent.template === "25") {
        if (this.selectedEvent.description.includes("=")) {
        return this.selectedEvent.description.split("=")[0]
        }
      }
      return this.selectedEvent.description;
    },

    async getEvent() {
      if (this.$store.state.authenticated && Store.state.discordUser !== undefined) {
        let req = {
        accessToken: localStorage.getItem('accessToken').slice(1,-1),
      };
        axios.post(this.$store.state.url + "/api/event/" + this.$route.params.raidId, req)
        .then(response => {
          this.selectedEvent = response.data;
          if (this.selectedEvent.status === 'failed') {
            this.isLoaded = true;
          } else {
            this.updateCountdown();
            this.parseEvent();
            if (this.selectedEvent.permission) {
              this.loadServerData();
            }
          }
        }).catch(error => {
          if (error.response.data.status=== 'failed') {
            this.ratelimitText = error.response.data.reason;
            this.ratelimitSnackbar = true;
            return;
          } else if (error.response.status === 401) {
              this.$store.commit("setAuthenticated", false);
              axios.get(this.$store.state.url + "/api/discord/logout", {withCredentials: true})
                .then((res) => {
                  this.$store.commit("setChosenDiscord", null);
                  this.$store.commit("setDiscordUser", null);
                })
                .catch((error) => {
                  console.log(error);
                })
                .finally(() => {
			            this.$store.state.discordUser = undefined;
			            this.$store.state.discordChannels = undefined;
                  localStorage.removeItem("discordUser");
                  localStorage.removeItem("chosenDiscord");
                  localStorage.removeItem("accessToken");
                  location.reload();
                });
            }
        });

      } else {
        axios.get(this.$store.state.url + "/api/event/" + this.$route.params.raidId)
        .then(response => {
          this.selectedEvent = response.data;
          this.updateCountdown();
          this.parseEvent();
        }).catch(error => {
          if (error.response.data.status=== 'failed') {
            this.ratelimitText = error.response.data.reason;
            this.ratelimitSnackbar = true;
          }
        });
      }
    },

    parseEvent() {
        this.$store.state.selectedEvent = this.selectedEvent;
        let classes = [];
        let classes2 = [];
        this.hasPrimarySignups = false;

        for (let i = 0; i < this.selectedEvent.classes.length; i++) {
            if (this.selectedEvent.classes[i].type === "primary") {
              classes.push(this.selectedEvent.classes[i]);
            } else {
              if (this.selectedEvent.signups) {
              for (let k = 0; k < this.selectedEvent.signups.length; k++) {
                if (this.selectedEvent.signups[k].class === this.selectedEvent.classes[i].name) {
                  classes2.push(this.selectedEvent.classes[i]);
                  break;
                }
              }
                }
            }
        }

        this.isOpen = this.selectedEvent.closingtime > Math.round((new Date().getTime() / 1000));
        this.uniqueClasses = classes;
        this.uniqueStatus = classes2;
        this.descriptionText = this.selectedEvent.description;
        this.titleText = this.selectedEvent.displayTitle != undefined ? this.selectedEvent.displayTitle : this.selectedEvent.title;

        document.title = "Web View - " + this.titleText

        this.voice_channel = this.selectedEvent.advanced.voice_channel;
        this.voice_type = "voice";
        let rgb = 'rgb(' + this.selectedEvent.color + ')';
        this.color = '#' + rgb.slice(4,-1).split(',').map(x => (+x).toString(16).padStart(2,0)).join('')
        if (this.selectedEvent.interval) {
          this.selectedEvent.raidid = this.selectedEvent.channelid;
        }

        if (this.selectedEvent.interval2 && this.selectedEvent.interval2 != undefined && this.selectedEvent.interval2 != "0") {

        let [ordinal, weekday] = this.selectedEvent.interval2.split("-");
        if (ordinal && weekday) {
          let capitalizedOrdinal = ordinal.charAt(0).toUpperCase() + ordinal.slice(1);
          let capitalizedWeekday = weekday.charAt(0).toUpperCase() + weekday.slice(1);
          this.interval2Ordinal = capitalizedOrdinal;
          this.interval2Weekday = capitalizedWeekday;
          this.setDynamicDate = true;
        }
      }

        let date = new Date(this.selectedEvent.unixtime*1000);
        this.unixtime = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + "T" + date.getHours() + ":" + date.getMinutes();

        let pdate = new Date(this.selectedEvent.posttime*1000);
        this.posttime = pdate.getFullYear() + "-" + (pdate.getMonth() + 1) + "-" + pdate.getDate() + "T" + pdate.getHours() + ":" + pdate.getMinutes();

        if (this.selectedEvent.template === "23") {
        this.uniqueClasses = classes.reverse();
        }
        this.isLoaded = true;
    },

    getUniqueClasses() {
      let list = [];
      if (this.showClasses) {
        for (let i = 0; i < this.selectedEvent.classes.length; i++) {
               if (this.selectedEvent.classes[i].type === "primary") {
             list.push(this.selectedEvent.classes[i]);
          }
        }
      } else if (this.selectedEvent.signups) {
        for (let i = 0; i < this.selectedEvent.classes.length; i++) {
          for (let k = 0; k < this.selectedEvent.signups.length; k++) {
             if (this.selectedEvent.signups[k].class_emote === this.selectedEvent.classes[i].emote) {
               if (this.selectedEvent.classes[i].type === "primary") {
                  list.push(this.selectedEvent.classes[i]);
                  break;
                }
             }
          }
        }
      }
        return list;
    },

    getDefaultClasses() {
      let list = [];
      if (this.selectedEvent.signups) {
        for (let i = 0; i < this.selectedEvent.classes.length; i++) {
          for (let k = 0; k < this.selectedEvent.signups.length; k++) {
             if (this.selectedEvent.signups[k].class_emote === this.selectedEvent.classes[i].emote || this.showClasses) {
               if (this.selectedEvent.classes[i].type === "default") {
                  list.push(this.selectedEvent.classes[i]);
                  break;
                }
             }
          }
        }
      }
        return list;
    },
    

    getClassCount(key) {
      let counter = 0;
      if (this.selectedEvent.signups) {
        for (let i = 0; i < this.selectedEvent.signups.length; i++) {
            if (this.selectedEvent.signups[i].status === "primary" && (this.selectedEvent.signups[i].class_emote === key || this.selectedEvent.signups[i].class === key)) {
              counter += 1;
            }
        }
      }
      return counter;
    },

    getRoleCount(emote) {
      let counter = 0;
      if (this.selectedEvent.signups) {
      for (let i = 0; i < this.selectedEvent.signups.length; i++) {
        if (this.selectedEvent.signups[i].class !== "Tentative" && this.selectedEvent.signups[i].class !== "Absence" && this.selectedEvent.signups[i].class !== "Bench" && this.selectedEvent.signups[i].class !== "Late") {
          if (this.selectedEvent.signups[i].status === "primary" && this.selectedEvent.signups[i].role_emote === emote) {
            counter += 1;
          }
        }
      }
      }
      return counter;
    },

    getLimitString(amount) {
      if (amount < 100) {
        return " / " + amount;
      } else {
        return "";
      }
    },

    getTitleAsEmotes(input, font) {
      if (input === "unnamed") {
        this.titleEmote = false;
      }
      let textEmotes = [];
      for (let i = 0; i < input.length; i++) {
        if (this.$store.state.textEmotes[0].includes(input[i].toUpperCase())) {
          textEmotes.push(
            this.$store.state.textEmotes[parseInt(font)].find(
              (x) => x.name === input[i].toUpperCase()
            ).id
          );
        } else {
          this.titleEmote = false;
        }
      }
      this.titleEmote = true;
      return textEmotes;
    },

    getSignupsCount() {
      let counter = 0;
      let extraCounter = 0;

      if (this.selectedEvent.signups) {
        for (let i = 0; i < this.selectedEvent.signups.length; i++) {
          if (this.selectedEvent.signups[i].status === "primary") {
            if (this.selectedEvent.signups[i].class !== "Tentative" && this.selectedEvent.signups[i].class !== "Absence" && this.selectedEvent.signups[i].class !== "Bench" && this.selectedEvent.signups[i].class !== "Late" && this.selectedEvent.signups[i].class !== "Declined") {
              counter += 1;
              this.hasPrimarySignups = true;
            }
            if (this.selectedEvent.signups[i].class === "Late" || this.selectedEvent.signups[i].class === "Tentative") {
              extraCounter += 1;
            }
          }
        }
      }

      counter = "<span style='color: white;'><b>" + counter + "</b></span>";
      if (extraCounter > 0) {
        counter = counter + " (+" + extraCounter + ")";
      }

      return counter;
    },
    getPosition(pos) {
      if (pos < 10) {
        pos = "0" + pos;
      }
      
      return pos;
    },

    displayClick(type, input){
      this.$store.state.selectedEventObject.information.selectedType = type
      this.$store.state.selectedEventObject.information.selectedInput = input
    },

    iconize(input) {
      if (input === "-") {
        return " ";
      } else if (input === '612373441051361353') {
        return "https://cdn.discordapp.com/emojis/" + this.selectedEvent.advanced.bench_emote + ".png?v=1";
      } else if (input === '612373443551297689') {
        return "https://cdn.discordapp.com/emojis/" + this.selectedEvent.advanced.late_emote + ".png?v=1";
      } else if (input === '676284492754976788') {
        return "https://cdn.discordapp.com/emojis/" + this.selectedEvent.advanced.tentative_emote + ".png?v=1";
      } else if (input === '612343589070045200') {
        return "https://cdn.discordapp.com/emojis/" + this.selectedEvent.advanced.absence_emote + ".png?v=1";
      } else if(input.match("^(\\d{17,20})$")) {
        return "https://cdn.discordapp.com/emojis/" + input + ".png?v=1";
      } else {
        return input;
      }
    },

    async removeSignup(signup) {
      let user = [{
        userid: signup.userid,
        timestamp: signup.timestamp,
        notify: signup.notify,
        reason: signup.reason,
        status: "remove"
      }]
      let req = {
        serverid: this.selectedEvent.serverid,
        accessToken: localStorage.getItem('accessToken').slice(1,-1),
        eventid: this.selectedEvent.raidid,
        edit: {
          signups: user
          }
      };
     
      axios.post(Store.state.url + "/api/edit/", req)      
      .then(response => {
        this.selectedEvent = response.data;
        this.editresultText = response.data.editresult;
        this.parseEvent();
        this.snackbar = true;
      }).catch(error => {
          if (error.response.data.status=== 'failed') {
            this.ratelimitText = error.response.data.reason;
            this.ratelimitSnackbar = true;
          }
      });
    },

    async editSignup(signup) {
      if ((this.selectedEvent.template === "2" || this.selectedEvent.template === "3" || this.selectedEvent.template === "4") && (signup.spec === "Protection" || signup.spec === "Protection1" || signup.spec === "Guardian" || signup.spec === "Frost_Tank" || signup.spec === "Unholy_Tank" || signup.spec === "Blood_Tank") && (signup.class !== "Absence" && signup.class !== "Bench" && signup.class !== "Late" && signup.class !== "Tentative")) {
        signup.class = "Tank";
      }

      if (this.getSpecsOfClass(signup.class).length === 0 && signup.class != 'Tentative' && signup.class != 'Bench' && signup.class != 'Late') {
          signup.spec = signup.class;
      }
      let user = [{
        userid: signup.userid,
        timestamp: signup.timestamp,
        class: signup.class,
        spec: signup.spec,
        name: signup.name,
        notify: signup.notify,
        reason: signup.reason,
      }]
      let req = {
        serverid: this.selectedEvent.serverid,
        accessToken: localStorage.getItem('accessToken').slice(1,-1),
        eventid: this.selectedEvent.raidid,
        edit: {
          signups: user
          }
      };
     
      axios.post(Store.state.url + "/api/edit/", req)      
      .then(response => {
        this.selectedEvent = response.data;
        this.editresultText = response.data.editresult;
        this.parseEvent();
        this.snackbar = true;
      }).catch(error => {
          if (error.response.data.status=== 'failed') {
            this.ratelimitText = error.response.data.reason;
            this.ratelimitSnackbar = true;
          }
      });
    },

    async addSignups() {
      for (let i = 0; i < this.addUsers.length; i++) {
              if ((this.selectedEvent.template === "2" || this.selectedEvent.template === "3" || this.selectedEvent.template === "4") && (this.addUsers[i].spec === "Protection" || this.addUsers[i].spec === "Protection1" || this.addUsers[i].spec === "Guardian" || this.addUsers[i].spec === "Frost_Tank" || this.addUsers[i].spec === "Unholy_Tank" || this.addUsers[i].spec === "Blood_Tank") && (this.addUsers[i].class !== "Absence" && this.addUsers[i].class !== "Bench" && this.addUsers[i].class !== "Late" && this.addUsers[i].class !== "Tentative")) {
        this.addUsers[i].class = "Tank";
      }
      }
      let req = {
        serverid: this.selectedEvent.serverid,
        accessToken: localStorage.getItem('accessToken').slice(1,-1),
        eventid: this.selectedEvent.raidid,
        edit: {
          addusers: this.addUsers
          }
      };
     
      axios.post(Store.state.url + "/api/edit/", req)      
      .then(response => {
        this.selectedEvent = response.data;
        this.editresultText = response.data.editresult;
        this.parseEvent();
        this.snackbar = true;
      }).catch(error => {
          if (error.response.data.status=== 'failed') {
            this.ratelimitText = error.response.data.reason;
            this.ratelimitSnackbar = true;
          }
      });
    },

    async editProperty(name, value) {
      var edit = {};
      if (name === 'unixtime' || name === 'posttime') {
        value = new Date(value).getTime() / 1000
      }

      if (name == "interval2") {
        edit["interval"] = this.interval + " " + this.intervalUnit;
      }

      edit[name] = value;
      let req = {
        serverid: this.selectedEvent.serverid,
        accessToken: localStorage.getItem('accessToken').slice(1,-1),
        eventid: this.selectedEvent.raidid,
        edit: edit
      }
      axios.post(Store.state.url + "/api/edit/", req)      
      .then(response => {
        this.selectedEvent = response.data;
        this.editresultText = response.data.editresult;
        this.parseEvent();
        this.snackbar = true;
      }).catch(error => {
          if (error.response.data.status=== 'failed') {
            this.ratelimitText = error.response.data.reason;
            this.ratelimitSnackbar = true;
          }
      });
    },

    async removeAnnouncement() {
      var announcement = {
        delete: true,
      }
      var edit = {
        announcement: announcement
      };

      let req = {
        serverid: this.selectedEvent.serverid,
        accessToken: localStorage.getItem('accessToken').slice(1,-1),
        eventid: this.selectedEvent.raidid,
        edit: edit
      }
      axios.post(Store.state.url + "/api/edit/", req)      
      .then(response => {
        this.selectedEvent = response.data;
        this.editresultText = response.data.editresult;
        this.parseEvent();
        this.snackbar = true;
      }).catch(error => {
          if (error.response.data.status=== 'failed') {
            this.ratelimitText = error.response.data.reason;
            this.ratelimitSnackbar = true;
          }
      });
    },

    async editAnnouncement() {
      var announcement = {
        channel: this.announcementChannel,
        time: this.announcementTime,
        message: this.announcementMessage
      }
      var edit = {
        announcement: announcement
      };

      let req = {
        serverid: this.selectedEvent.serverid,
        accessToken: localStorage.getItem('accessToken').slice(1,-1),
        eventid: this.selectedEvent.raidid,
        edit: edit
      }
      axios.post(Store.state.url + "/api/edit/", req)      
      .then(response => {
        this.selectedEvent = response.data;
        this.editresultText = response.data.editresult;
        this.parseEvent();
        this.snackbar = true;
      }).catch(error => {
          if (error.response.data.status=== 'failed') {
            this.ratelimitText = error.response.data.reason;
            this.ratelimitSnackbar = true;
          }
      });
    },

    async sendAnnouncement() {
      var announcement = {
        channel: this.announcementChannel2,
        time: "0",
        message: this.announcementMessage2,
        immediately: "true",
      }
      var edit = {
        announcement: announcement
      };

      let req = {
        serverid: this.selectedEvent.serverid,
        accessToken: localStorage.getItem('accessToken').slice(1,-1),
        eventid: this.selectedEvent.raidid,
        edit: edit
      }
      axios.post(Store.state.url + "/api/edit/", req)      
      .then(response => {
        this.selectedEvent = response.data;
        this.editresultText = response.data.editresult;
        this.parseEvent();
        this.snackbar = true;
      }).catch(error => {
          if (error.response.data.status=== 'failed') {
            this.ratelimitText = error.response.data.reason;
            this.ratelimitSnackbar = true;
          }
      });
    },
    
    async editAdvanced(name, value) {
      if (name === 'allowed_roles' || name === 'banned_roles') {
        let allowedString = "none";
        if (value.length > 0) {
          allowedString = "";
          for (let i = 0; i < value.length; i++) {
            allowedString += value[i] + ",";
          }
          value = allowedString;
        }
      }

      if (name === 'mentions') {
        let mentionString = "none";
        if (value.length > 0) {
          mentionString = "";
          for (let i = 0; i < value.length; i++) {
            mentionString += value[i] + ",";
          }
          value = mentionString;
        }
      }

      var advanced = {};
      advanced[name] = value;
      var edit = {
        advanced: advanced
      };
      let req = {
        serverid: this.selectedEvent.serverid,
        accessToken: localStorage.getItem('accessToken').slice(1,-1),
        eventid: this.selectedEvent.raidid,
        edit: edit
      }
      axios.post(Store.state.url + "/api/edit/", req)
      .then(response => {
        this.selectedEvent = response.data;
        this.editresultText = response.data.editresult;
        this.parseEvent();
        this.snackbar = true;
      }).catch(error => {
          if (error.response.data.status=== 'failed') {
            this.ratelimitText = error.response.data.reason;
            this.ratelimitSnackbar = true;
          }
      });
    },

    async editroleLimit() {
      let role = [{
        name: this.editroleName,
        amount: this.editroleAmount
      }]
      let req = {
        serverid: this.selectedEvent.serverid,
        accessToken: localStorage.getItem('accessToken').slice(1,-1),
        eventid: this.selectedEvent.raidid,
        edit: {
          roles: role
          }
      };
     
      axios.post(Store.state.url + "/api/edit/", req)      
      .then(response => {
        this.selectedEvent = response.data;
        this.editresultText = response.data.editresult;
        this.parseEvent();
        this.snackbar = true;
      }).catch(error => {
          if (error.response.data.status=== 'failed') {
            this.ratelimitText = error.response.data.reason;
            this.ratelimitSnackbar = true;
          }
      });
    },

    async editclassLimit() {
      let obj = [{
        name: this.editclassName,
        amount: this.editclassAmount
      }]
      let req = {
        serverid: this.selectedEvent.serverid,
        accessToken: localStorage.getItem('accessToken').slice(1,-1),
        eventid: this.selectedEvent.raidid,
        edit: {
          classes: obj
          }
      };
     
      axios.post(Store.state.url + "/api/edit/", req)      
      .then(response => {
        this.selectedEvent = response.data;
        this.editresultText = response.data.editresult;
        this.parseEvent();
        this.snackbar = true;
      }).catch(error => {
          if (error.response.data.status=== 'failed') {
            this.ratelimitText = error.response.data.reason;
            this.ratelimitSnackbar = true;
          }
      });
    },



    async deleteEvent() {
      let req = {
        serverid: this.selectedEvent.serverid,
        accessToken: localStorage.getItem('accessToken').slice(1,-1),
        eventid: this.selectedEvent.raidid,
        edit: {
          delete: true
          }
      };
     
      axios.post(Store.state.url + "/api/edit/", req)
      .then(() => {
      this.$router.push('/dashboard', () => {});
      }).catch(error => {
          if (error.response.data.status=== 'failed') {
            this.ratelimitText = error.response.data.reason;
            this.ratelimitSnackbar = true;
          }
      });
    },

    truncate(input) {
      if (input.length > 19) {
        return input.substring(0, 20) + "..";
      }
      return input;
    },

    truncate1(input) {
      if (input.length > 39) {
        return input.substring(0, 40) + "..";
      }
      return input;
    },

    truncate2(input) {
      if (input.length > 69) {
        return input.substring(0, 70) + "..";
      }
      return input;
    },

    async loadServerData() {
        this.req = {
          serverid: this.selectedEvent.serverid,
        accessToken: localStorage.getItem('accessToken').slice(1,-1),
        };

        let channelNameList = [];
        let voiceChannelNameList = [];
        let rolesList = [];
        this.mentions = [];
        this.allowed_roles = [];
        axios.post(Store.state.url + "/api/server/", this.req)
          .then(response => {
            Store.state.permissionLevel = response.data.permission;
        
            for (let index = 0; index < response.data.channels.length; index++) {
              channelNameList.push(response.data.channels[index]);
            }
            for (let index = 0; index < response.data.voicechannels.length; index++) {
              voiceChannelNameList.push(response.data.voicechannels[index]);
            }
            for (let index = 0; index < response.data.roles.length; index++) {
              rolesList.push(response.data.roles[index]);
              if (this.selectedEvent.advanced.mentions.includes(response.data.roles[index].name) || this.selectedEvent.advanced.mentions.includes(response.data.roles[index].id)) {
                this.mentions.push(response.data.roles[index].id)
              }
              let splitAllowedRoles = this.selectedEvent.advanced.allowed_roles.split(",");
              for (let k = 0; k < splitAllowedRoles.length; k++) {
                if (splitAllowedRoles[k] == response.data.roles[index].name || splitAllowedRoles[k] == response.data.roles[index].id) {
                  this.allowed_roles.push(response.data.roles[index].id)
                }
              }
            }

            Store.state.discordVoiceChannels = voiceChannelNameList;
            Store.state.discordChannels = channelNameList;
            Store.state.chosenDiscordRoles = rolesList;

            try {
              for (let i = 0; i < this.$store.state.discordChannels.length; i++) {
                if (this.$store.state.discordChannels[i].id === this.selectedEvent.channelid) {
                  this.channel = this.$store.state.discordChannels[i];
                }
              }
            } catch { }

            this.chosenDiscordRoles = JSON.parse(JSON.stringify(Store.state.chosenDiscordRoles));
            this.chosenDiscordRoles.push({color: "255,255,255", id: "@here", name: "@here", position: -1})
            this.chosenDiscordRoles.push({color: "255,255,255", id: "@everyone", name: "@everyone", position: -2})
          }).catch(error => {
            if (error.response.data.status=== 'failed') {
            this.ratelimitText = error.response.data.reason;
            this.ratelimitSnackbar = true;
          }
      });

      
    },
  },

  mounted() {
    this.getEvent();
    window.addEventListener('resize', this.onResize, { passive: true })
    this.last_updated = new Date().getTime();
    this.$socket.emit('liveUpdateEventRegister', this.$route.params.raidId);
    this.timer = this.unixtime;
    this.countDownInterval = setInterval(() => this.updateCountdown(), 5000);
  },

  created() {
      this.$root.$refs.SelectedEvent = this;
      document.title = "Web View"
  },

  beforeDestroy () {
      if (typeof window === 'undefined') { 
        return;
      }
      clearInterval(this.countDownInterval);
      window.removeEventListener('resize', this.onResize, { passive: true })
  },

};
</script>
<style>
.fixedColPadding {
  padding: 0px;
}

.v-text-field .v-input__control .v-input__slot {
  min-height: 40px !important;
  display: flex !important;
  align-items: center !important;
}

.noMarginP {
  margin-bottom: 0px !important;
}

.noMarginP2 {
  margin-bottom: 4px !important;
}

.noMarginIcon {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  margin-right: 0px !important;
}

.cardFont {
  font-family: Whitney, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

li {
  display: inline-block;
}

  /* width */
  ::-webkit-scrollbar {
    width: 3px;

  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #202225;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #4b5158;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
</style>