<template>
    <v-container>
      <span class="bg"></span>
    		<v-col cols="12" class="mt-n7 mb-15 px-3" align="center" v-if="this.$route.name === 'Advanced'">
      <v-btn-toggle dark tile>
        <v-btn elevation="0" tile min-width="130px" :ripple="false" color="#202025" style="opacity: 0.8; border: none;" to="/documentation/intro">Intro</v-btn>
        <v-btn elevation="0" tile min-width="130px" :ripple="false" color="#202025" style="opacity: 0.8; border: none;" to="/documentation/commands">Commands</v-btn>
	  </v-btn-toggle>
          <v-btn-toggle dark tile>
        <v-btn elevation="0" tile min-width="130px" :ripple="false" color="red lighten-1" style="opacity: 1; border: none;" to="/documentation/advanced">Advanced</v-btn>
        <v-btn elevation="0" tile min-width="130px" :ripple="false" color="#202025" style="opacity: 0.8; border: none;" to="/documentation/reference">Reference</v-btn>
	  </v-btn-toggle>
	  <v-btn-toggle dark tile>
        <v-btn elevation="0" tile min-width="130px" :ripple="false" color="#202025" style="opacity: 0.8; border: none;" to="/documentation/guides">Guides</v-btn>
        <v-btn elevation="0" tile min-width="130px" :ripple="false" color="#202025" style="opacity: 0.8; border: none;" to="/documentation/api">API</v-btn>
	  </v-btn-toggle>

    </v-col>
    <v-autocomplete  dark return-object dense style="z-index: 7; min-width: 250px !important; border-radius: 0px;" item-color="white" outlined label="Search..." background-color="secondary" flat :items="search.concat(commands)" class="mb-n9 ml-3 mr-3 mt-n8" item-text="name" hide-no-data hide-selected v-model="searchResult" clearable>
    </v-autocomplete>

    <v-card tile class="ml-3 mr-3 px-3" v-if="searchResult" dark background-color="primary" color="#494d52">
      <v-card-title class="my-4">
        <v-row class="">
                    <v-col cols="8" class="text-left my-n1" style="font-weight: 400;">
                      {{ searchResult.name }}
                    </v-col>
                 
                    <v-col cols="8" class="text-left my-n3">
                      <span style="color:gray; font-size: 0.75em; font-weight: normal" v-html="searchResult.shortDescription"></span>
                    </v-col>
                   
                  </v-row>
      </v-card-title>
      <v-card-text class="my-5">
        <v-row>
                    <v-col cols="12" class="" v-if="searchResult.syntax !== ' '">
                     <span style='color:#999999'> Syntax </span> <br/> <p v-html="searchResult.syntax"></p>
                    </v-col>
                    <v-col cols="12" class="" v-if="searchResult.keywords !== ' '">
                     <span style='color:#999999'> Keywords </span> <br/> <p v-html="searchResult.keywords"></p>
                    </v-col>
                    <v-col cols="12" class="" v-if="searchResult.selection !== ' '">
                     <span style='color:#999999'> Selection </span> <br/> <p v-html="searchResult.selection"></p>
                    </v-col>
                    <v-col cols="12" class="" v-if="searchResult.examples !== ' '">
                     <span style='color:#999999'> Example </span> <br/> <p v-html="searchResult.examples"></p>
                    </v-col>
                    <v-col cols="12" class="" v-if="searchResult.description !== ' '">
                     <span style='color:#999999'> Description </span> <br/> <p v-html="searchResult.description"></p>
                    </v-col>
                  </v-row>

      </v-card-text>
    </v-card>

    <v-container v-if="!searchResult">

    <v-card dark tile elevation="0" color="transparent">

            <v-expansion-panels flat tile dark style="background-color: transparent !important;" class="mt-1">
          
              <v-expansion-panel class="mt-2" active-class="panel" v-for="(items, n) in commands" :key="n">
                <v-expansion-panel-header style="background-color: #1d1d21;">

                  <v-row class="">
                    <v-col cols="8" class="text-left" style="font-size: 1.4em;">
                      {{ items.name }}
                    </v-col>
                 
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content eager style="background-color: #38383e;">
                  <v-row>
                    <v-col cols="12" class="mt-5" v-if="items.syntax !== ' '">
                     <span style='color:#999999'> Syntax </span> <br/> <p v-html="items.syntax"></p>
                    </v-col>
                    <v-col cols="12" class="" v-if="items.keywords !== ' '">
                     <span style='color:#999999'> Keywords </span> <br/> <p v-html="items.keywords"></p>
                    </v-col>
                    <v-col cols="12" class="" v-if="items.selection !== ' '">
                     <span style='color:#999999'> Selection </span> <br/> <p v-html="items.selection"></p>
                    </v-col>
                    <v-col cols="12" class="" v-if="items.examples !== ' '">
                     <span style='color:#999999'> Example </span> <br/> <p v-html="items.examples"></p>
                    </v-col>
                    <v-col cols="12" class="" v-if="items.description !== ' '">
                     <span style='color:#999999'> Description </span> <br/> <p v-html="items.description"></p>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
  </v-card>
  </v-container>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      tab: null,
      isMobile2: null,
      search: [],
      searchResult: undefined,
      commands: [

          {
            name: "duration",
            syntax: "< duration: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>number</span> >",
            keywords: " ",
            selection: " ",
            examples: "< duration: 120 >",
            description:
              "The duration of the event in minutes.",
          },
          {
            name: "deadline",
            syntax: "< deadline: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>number</span> >",
            keywords: " ",
            selection: " ",
            examples: "< deadline: 24 >",
            description:
              "Events generally close when they reach the event time. You might want to close the event earlier, to prevent last minute changes. The deadline determines how much earlier the event will close. The number is entered in hours. You can also enter a negative number, to close the event later than its designated time.",
          },
          {
            name: "limit",
            syntax: "< limit: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>number</span> >",
            keywords: " ",
            selection: " ",
            examples: "< limit: 5 >",
            description:
              "The limit determines the maximum amount of Sign-Ups allowed on this event. Sign-Ups past the limit will be benched or denied, depending on the <span style='font-weight: 300; color: yellow; opacity: 0.8;'>bench_overflow</span> setting. The limit can also only be visual if the <span style='font-weight: 300; color: yellow; opacity: 0.8;'>lock_at_limit</span> setting is set to false.",
          },
          {
            name: "lock_at_limit",
            syntax: "< lock_at_limit: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< lock_at_limit: true ><br>< lock_at_limit: false >",
            description:
              "This setting determines whether an event should be locked for further signups, once the <span style='font-weight: 300; color: yellow; opacity: 0.8;'>limit</span> is reached.",
          },
          {
            name: "limit_per_user",
            syntax: "< limit_per_user: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>number</span> >",
            keywords: " ",
            selection: " ",
            examples: "< limit_per_user: 1 ><br>< limit_per_user: 3 >",
            description:
              "This setting determines the maximum amount of Sign-Ups a user can have on the event at the same time. If you are running a poll for example and want your users to be able to vote multiple times, then you would increase this number accordingly.",
          },
          {
            name: "lower_limit",
            syntax: "< lower_limit: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>number</span> >",
            keywords: " ",
            selection: " ",
            examples: "< lower_limit: 0 ><br>< lower_limit: 10 >",
            description:
              "The lower limit specifies the minimum required signups for an event. If this limit is not met by the signup closure (adjustable via the deadline setting), the system will automatically cancel any pending announcements, delete the Discord event (if created), and display a message on the event page: '⚠️ This event did not reach the minimum sign-up requirement.' Additionally, a direct message will be sent to all participants, informing them of the event cancellation due to insufficient signups.",
          },
          {
            name: "allow_duplicate",
            syntax: "< allow_duplicate: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< allow_duplicate: false ><br>< allow_duplicate: true >",
            description:
              "If <span style='font-weight: 300; color: yellow; opacity: 0.8;'>limit_per_user</span> is set to greater than 1, then this setting will determine whether the member can sign up multiple times to the same class.",
          },
          {
            name: "horizontal_mode",
            syntax: "< horizontal_mode: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< horizontal_mode: false ><br>< horizontal_mode: true >",
            description:
              "Sign-up content on events is generally displayed in fields that are inline, which means they show next to each other in 3 columns. This has the effect that member names have to be trimmed to keep the columns intact. It can also make events very long vertically if all Sign-Ups are of the same class. Set <span style='font-weight: 300; color: yellow; opacity: 0.8;'>horizontal_mode</span> to true to sort fields below each other and Sign-Ups horizontally.",
          },
          {
            name: "bench_overflow",
            syntax: "< bench_overflow: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< bench_overflow: false ><br>< bench_overflow: true >",
            description:
              "Sign-Ups that would go over a limit or are otherwise not allowed will be benched if this setting is set to true. Set this to false, if you want the Sign-Up to be denied entirely.",
          },
          {
            name: "queue_bench",
            syntax: "< queue_bench: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< queue_bench: false ><br>< queue_bench: true >",
            description:
              "The default behaviour of the bot is to bench members that sign up above a limit. This setting enables an alternative where members don't get benched but queued instead. A queued Sign-Up is highlighted with a crossed out name and an hourglass icon. If a spot opens up, the first queued Sign-Up will be unqueued and the member notified.",
          },
          {
            name: "vacuum",
            syntax: "< vacuum: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< vacuum: false ><br>< vacuum: true >",
            description:
              "This setting will cause the bot to do a minor cleanup in the channel where the event is posted. The last 20 messages will be checked and all non-event and non-pinned messages will be deleted. <a href='/premium/' style='text-decoration: none;'><span style='color:skyblue;'>This is a premium feature.</span></a>",
          },
          {
            name: "force_reminders",
            syntax: "< force_reminders: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean / number</span> >",
            keywords: " ",
            selection: " ",
            examples: "< force_reminders: false ><br>< force_reminders: 30 >",
            description:
              "Reminders are set by members individually via the /reminders command. With this setting you have the power to override the members personal setting and send out a reminder either way. Enter a number to set the amount of minutes before the event that the reminders will be sent out. Reminders are sent via DM and just notify the receiving user that the event is happening in the specified amount of time. This is a premium feature.",
          },
          {
            name: "pin_message",
            syntax: "< pin_message: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< pin_message: false ><br>< pin_message: true >",
            description:
              "This setting determines whether the event will be pinned on creation.",
          },
          {
            name: "deletion",
            syntax: "< deletion: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean / number</span> >",
            keywords: " ",
            selection: " ",
            examples: "< deletion: false ><br>< deletion: true ><br>< deletion: 24 >",
            description:
              "This setting determines whether the event should be automatically deleted after it reaches the events ending time. Setting it to true will delete the event 24 hours after it ends. You can also enter a number to have it be deleted an amount in hours after the events ending time. Entering 12 for example will cause the event to be deleted 12 hours after the events ending time.",
          },
          {
            name: "mention_mode",
            syntax: "< mention_mode: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< mention_mode: false ><br>< mention_mode: true >",
            description:
              "If this setting is enabled, Sign-Ups will be displayed as mentions instead of plain text. This is not recommended because names can be too long for a column and mentions are not always guaranteed to show the resolved member name. This depends on whether the mentioned member is cached in your client, which can be a problem on mobile more often than on desktop. If the member is not cached in your client, you will see the members ID displayed instead.",
          },
          {
            name: "preserve_order",
            syntax: "< preserve_order: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>keyword</span> >",
            keywords: "<span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>always</span> - the order number will be preserved regardless of which roles the member switches between.<br><span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>normal</span> - the order number will be preserved unless the member switches from a default role (bench / late / tentative / absence).<br><span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>never</span> - the order number will be reset each time the member switches between roles.",
            selection: " ",
            examples: "< preserve_order: always ><br>< preserve_order: normal ><br>< preserve_order: never >",
            description:
              "This setting determines the behaviour of the order number when a member changes his Sign-Up.",
          },
          {
            name: "apply_unregister",
            syntax: "< apply_unregister: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< apply_unregister: false ><br>< apply_unregister: true >",
            description:
              "Members can't sign off an event entirely by default. They can change their Sign-Up or set themselves as absent. If this setting is set to true, the bot will give an option to unregister entirely from an event or poll.",
          },
          {
            name: "apply_specreset",
            syntax: "< apply_specreset: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< apply_specreset: false ><br>< apply_specreset: true >",
            description:
              "Some templates require the member to specify a spec in DMs after they clicked their class reaction. This choice is saved by the bot so that the member doesn't have to clarify the choice repeatedly. To reset the spec choice a member can simply sign up to the same class twice, which will trigger the bot to ask for the spec again. The member can also click the spec reset reaction on the event to fully clear all his/her saved specs. You can set this setting to false if you don't want to have the spec reset reaction to be applied to the event.",
          },
          {
            name: "spec_saving",
            syntax: "< spec_saving: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< spec_saving: false ><br>< spec_saving: true >",
            description:
              "Some templates require the member to specify a spec in DMs after they clicked their class reaction. This choice is saved by the bot so that the member doesn't have to clarify the choice repeatedly. You can turn this behaviour off by setting it to false. The members will then be asked for their spec on every Sign-Up.",
          },
          {
            name: "font_style",
            syntax: "< font_style: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>number</span> >",
            keywords: " ",
            selection: " ",
            examples: "< font_style: 3 ><br>< font_style: 0 >",
            description:
              "The font style determines the emotes used to display the title. There are 3 emote sets available (1-3). You can display the title in plain text by setting this to 0. The title can only be displayed in emote letters if its no longer than 19 characters.",
          },
          {
            name: "alt_names",
            syntax: "< alt_names: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< alt_names: false ><br>< alt_names: true >",
            description:
              "You might want your members to be able to sign up to an event with a different name than their discord name. If this is set to true, the bot will DM members on Sign-Up and offer them to enter an alternative name for this Sign-Up. The user can enter a name or ignore the message.",
          },
          {
            name: "defaults_pre_req",
            syntax: "< defaults_pre_req: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< defaults_pre_req: true ><br>< defaults_pre_req: false >",
            description:
              "When signing up to a default role like bench / late / tentative, the bot requires you to have been signed up normally to the event first in order to display the class/spec with which you are on bench / late / tentative. If this is set to false, the bot will not require that and put the member on the default role regardless.",
          },
          {
            name: "show_on_overview",
            syntax: "< show_on_overview: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< defaults_pre_req: true ><br>< defaults_pre_req: false >",
            description:
              "Determines whether this event will be shown on the overview embed produced by the /overview server command.",
          },
          {
            name: "attendance",
            syntax: "< attendance: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean / tag</span> >",
            keywords: " ",
            selection: " ",
            examples: "< attendance: true ><br>< attendance: false ><br>< attendance: team1 >",
            description:
              "Raid-Helper keeps track of attendance statistics in your server. If this setting is not set to false, the event will be counted towards attendance. You can query the statistic with the <span style='font-weight: 300; color: yellow; opacity: 0.8;'>/attendance</span> command. You can also use a custom tag instead of a boolean value, so that you can query the attendance statistics of events with that custom tag specifically.",
          },
          {
            name: "show_title",
            syntax: "< show_title: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< show_title: true ><br>< show_title: false >",
            description:
              "Determines whether the event title will be shown.",
          },
          {
            name: "show_info",
            syntax: "< show_info: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< show_info: true ><br>< show_info: false >",
            description:
              "Determines whether the event info will be shown. The info contains the date, time & Sign-Up count.",
          },
          {
            name: "show_leader",
            syntax: "< show_leader: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< show_leader: true ><br>< show_leader: false >",
            description:
              "Determines whether the event leader will be shown.",
          },
          {
            name: "show_counter",
            syntax: "< show_counter: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< show_counter: true ><br>< show_counter: false >",
            description:
              "Determines whether the Sign-Ups counter will be shown.",
          },
          {
            name: "show_roles",
            syntax: "< show_roles: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< show_roles: true ><br>< show_roles: false >",
            description:
              "Determines whether the role counters will be shown. The role counters are below the info and above the Sign-Up content. Not all templates have role counters.",
          },
          {
            name: "show_content",
            syntax: "< show_content: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< show_content: true ><br>< show_content: false >",
            description:
              "Determines whether the Sign-Up content will be shown. The content contains all the Sign-Ups of an event.",
          },
          {
            name: "show_classes",
            syntax: "< show_classes: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< show_classes: true ><br>< show_classes: false >",
            description:
              "Determines whether the class headers are always shown or only if at least one member has signed up to that class.",
          },
          {
            name: "show_emotes",
            syntax: "< show_emotes: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< show_emotes: true ><br>< show_emotes: false >",
            description:
              "Determines whether the emotes in front of Sign-Ups will be shown.",
          },
          {
            name: "show_numbering",
            syntax: "< show_numbering: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< show_numbering: true ><br>< show_numbering: false >",
            description:
              "Determines whether the order numbers in front of Sign-Ups will be shown.",
          },
          {
            name: "show_allowed",
            syntax: "< show_allowed: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< show_allowed: true ><br>< show_allowed: false >",
            description:
              "Determines whether the allowed roles will be shown on the event if they are set.",
          },
          {
            name: "show_footer",
            syntax: "< show_footer: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< show_footer: true ><br>< show_footer: false >",
            description:
              "Determines whether the footer text will be shown. The footer text contains info about the event closure time if it deviates from the event time, aswell as the amount of Sign-Ups allowed per member and more.",
          },
          {
            name: "date_variant",
            syntax: "< date_variant: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>keyword</span> >",
            keywords: "local, zoned, both",
            selection: " ",
            examples: "< date_variant: local ><br>< date_variant: both >",
            description:
              "Determines whether the date and time will be shown in a localized timestamp, a static time with timezone or both.",
          },
          {
            name: "info_variant",
            syntax: "< info_variant: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>keyword</span> >",
            keywords: "short, long",
            selection: " ",
            examples: "< info_variant: short ><br>< info_variant: long >",
            description:
              "Determines the info fields formatting. Short will be horizontally aligned and long will be vertically aligned with more space to display the date including the day of week.",
          },
          {
            name: "show_countdown",
            syntax: "< show_countdown: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< show_countdown: true ><br>< show_countdown: false >",
            description:
              "Determines whether a countdown to the event time will be shown.",
          },
          {
            name: "bold_all",
            syntax: "< bold_all: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< bold_all: false ><br>< bold_all: true >",
            description:
              "The default behaviour is to display all Sign-Ups in bold letters and take it into account for all relevant limits. If you set bold_all to false and <span style='font-weight: 300; color: yellow; opacity: 0.8;'>limit_per_user</span> is greater than 1, subsequent Sign-Ups of the same member will be considered 'secondary'. They will not be displayed in bold letters and not count towards limits.",
          },
          {
            name: "bench_emote",
            syntax: "< bench_emote: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>emote / keyword</span> >",
            keywords: "<span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>remove</span> - remove the currently set emote. No reaction will be applied.<br><span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>default</span> - reset this emote back to the default emote.",
            selection: " ",
            examples: "< bench_emote: remove ><br>< bench_emote: :fire: >",
            description:
              "You can remove this reaction or replace it with an emote of your choice with this setting. The behaviour and position on the event will be unchanged, this is a visual change.",
          },
          {
            name: "late_emote",
            syntax: "< late_emote: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>emote / keyword</span> >",
            keywords: "<span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>remove</span> - remove the currently set emote. No reaction will be applied.<br><span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>default</span> - reset this emote back to the default emote.",
            selection: " ",
            examples: "< late_emote: remove ><br>< late_emote: :fire: >",
            description:
              "You can remove this reaction or replace it with an emote of your choice with this setting. The behaviour and position on the event will be unchanged, this is a visual change.",
          },
          {
            name: "tentative_emote",
            syntax: "< tentative_emote: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>emote / keyword</span> >",
            keywords: "<span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>remove</span> - remove the currently set emote. No reaction will be applied.<br><span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>default</span> - reset this emote back to the default emote.",
            selection: " ",
            examples: "< tentative_emote: remove ><br>< tentative_emote: :fire: >",
            description:
              "You can remove this reaction or replace it with an emote of your choice with this setting. The behaviour and position on the event will be unchanged, this is a visual change.",
          },
          {
            name: "absence_emote",
            syntax: "< absence_emote: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>emote / keyword</span> >",
            keywords: "<span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>remove</span> - remove the currently set emote. No reaction will be applied.<br><span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>default</span> - reset this emote back to the default emote.",
            selection: " ",
            examples: "< absence_emote: remove ><br>< absence_emote: :fire: >",
            description:
              "You can remove this reaction or replace it with an emote of your choice with this setting. The behaviour and position on the event will be unchanged, this is a visual change.",
          },
          {
            name: "leader_emote",
            syntax: "< leader_emote: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>emote / keyword</span> >",
            keywords: "<span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>default</span> - reset this emote back to the default emote.",
            selection: " ",
            examples: "< leader_emote: default ><br>< leader_emote: :fire: ><br>< leader_emote: 936425888046780446 >",
            description:
              "You can replace this emote with one of your choice by entering a different emote or an emote id. This is only a visual change.",
          },
          {
            name: "signups1_emote",
            syntax: "< signups1_emote: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>emote / keyword</span> >",
            keywords: "<span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>default</span> - reset this emote back to the default emote.",
            selection: " ",
            examples: "< signups1_emote: default ><br>< signups1_emote: :fire: ><br>< signups1_emote: 936425888046780446 >",
            description:
              "You can replace this emote with one of your choice by entering a different emote or an emote id. This is only a visual change.",
          },
          {
            name: "signups2_emote",
            syntax: "< signups2_emote: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>emote / keyword</span> >",
            keywords: "<span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>default</span> - reset this emote back to the default emote.",
            selection: " ",
            examples: "< signups2_emote: default ><br>< signups2_emote: :fire: ><br>< signups2_emote: 936425888046780446 >",
            description:
              "You can replace this emote with one of your choice by entering a different emote or an emote id. This is only a visual change.",
          },
          {
            name: "date1_emote",
            syntax: "< date1_emote: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>emote / keyword</span> >",
            keywords: "<span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>default</span> - reset this emote back to the default emote.",
            selection: " ",
            examples: "< date1_emote: default ><br>< date1_emote: :fire: ><br>< date1_emote: 936425888046780446 >",
            description:
              "You can replace this emote with one of your choice by entering a different emote or an emote id. This is only a visual change.",
          },
          {
            name: "date2_emote",
            syntax: "< date2_emote: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>emote / keyword</span> >",
            keywords: "<span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>default</span> - reset this emote back to the default emote.",
            selection: " ",
            examples: "< date2_emote: default ><br>< date2_emote: :fire: ><br>< date2_emote: 936425888046780446 >",
            description:
              "You can replace this emote with one of your choice by entering a different emote or an emote id. This is only a visual change.",
          },
          {
            name: "time1_emote",
            syntax: "< time1_emote: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>emote / keyword</span> >",
            keywords: "<span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>default</span> - reset this emote back to the default emote.",
            selection: " ",
            examples: "< time1_emote: default ><br>< time1_emote: :fire: ><br>< time1_emote: 936425888046780446 >",
            description:
              "You can replace this emote with one of your choice by entering a different emote or an emote id. This is only a visual change.",
          },
          {
            name: "time2_emote",
            syntax: "< time2_emote: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>emote / keyword</span> >",
            keywords: "<span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>default</span> - reset this emote back to the default emote.",
            selection: " ",
            examples: "< time2_emote: default ><br>< time2_emote: :fire: ><br>< time2_emote: 936425888046780446 >",
            description:
              "You can replace this emote with one of your choice by entering a different emote or an emote id. This is only a visual change.",
          },
          {
            name: "countdown1_emote",
            syntax: "< countdown1_emote: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>emote / keyword</span> >",
            keywords: "<span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>default</span> - reset this emote back to the default emote.",
            selection: " ",
            examples: "< countdown1_emote: default ><br>< countdown1_emote: :fire: ><br>< countdown1_emote: 936425888046780446 >",
            description:
              "You can replace this emote with one of your choice by entering a different emote or an emote id. This is only a visual change.",
          },
          {
            name: "countdown2_emote",
            syntax: "< countdown2_emote: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>emote / keyword</span> >",
            keywords: "<span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>default</span> - reset this emote back to the default emote.",
            selection: " ",
            examples: "< countdown2_emote: default ><br>< countdown2_emote: :fire: ><br>< countdown2_emote: 936425888046780446 >",
            description:
              "You can replace this emote with one of your choice by entering a different emote or an emote id. This is only a visual change.",
          },
          {
            name: "event_type",
            syntax: "< event_type: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>keyword</span> >",
            keywords: "<span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>reaction</span> - members will be clicking reactions to sign up.<br><span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>interaction</span> - members will be interacting with buttons and selection menus to sign up.",
            selection: " ",
            examples: "< event_type: reaction ><br>< event_type: interaction >",
            description:
              "Determines the response type of an event. This setting is a placeholder for future updates.",
          },
          {
            name: "reminder",
            syntax: "< reminder: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean / number</span> >",
            keywords: " ",
            selection: " ",
            examples: "< reminder: false ><br>< reminder: 30 >",
            description:
              "Sends a reminder message into the events channel that will also ping all attendees. Enter a number to specify how many minutes before the event the reminder is to be sent.",
          },
          {
            name: "create_discordevent",
            syntax: "< create_discordevent: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< create_discordevent: false ><br>< create_discordevent: true >",
            description:
              "Creates a discord event on top of the Raid-Helper event. This is a Premium feature. This can help your server with visibility for events as it gives a clean overview of current events in the events tab in discord.",
          },
          {
            name: "only_discordevent",
            syntax: "< only_discordevent: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< only_discordevent: false ><br>< only_discordevent: true >",
            description:
              "Makes the bot create the Discord event only. No Raid-Helper event will be created. This allows you to make recurring and scheduled Discord events through the bot.",
          },
          {
            name: "create_thread",
            syntax: "< create_thread: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean / title</span> >",
            keywords: " ",
            selection: " ",
            examples: "< create_thread: false ><br>< create_thread: true ><br>< create_thread: Discussion Thread >",
            description:
              "Creates a thread on the event if set to anything but 'false'. The thread will be titled after the event if set to 'true', you can also enter any text to name the thread accordingly.",
          },
          {
            name: "delete_thread",
            syntax: "< delete_thread: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< delete_thread: false ><br>< delete_thread: true >",
            description:
              "Determines whether the attached thread or post of the event will be deleted or just archived after the event has been deleted/moved.",
          },
          {
            name: "voice_channel",
            syntax: "< voice_channel: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>channel name</span> >",
            keywords: " ",
            selection: " ",
            examples: "< voice_channel: none ><br>< temp_voicechannel: RaidVoice >",
            description:
              "Displays the entered voice channel on the event. This makes it easier for attendees to join the correct voice channel for the event.",
          },
          {
            name: "temp_voicechannel",
            syntax: "< temp_voicechannel: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>channel name</span> >",
            keywords: " ",
            selection: " ",
            examples: "< temp_voicechannel: false ><br>< voice_channel: RaidVoice >",
            description:
              "Creates a temporary voicechannel for the duration of this event. Will be deleted 24 hours after the event or upon event deletion.",
          },
          {
            name: "color",
            syntax: "< color: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>number / hex color</span> >",
            keywords: " ",
            selection: " ",
            examples: "< color: 23 ><br>< color: #800080 >",
            description:
              "The color of the event embed. You can enter the number of one of the preset colors (1-30) or a hex color value.",
          },
          {
            name: "response",
            syntax: "< response: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>text / embed id</span> >",
            keywords: " ",
            selection: " ",
            examples: "< response: Please come prepared with full consumables! ><br>< response: 3 >",
            description:
              "Sends members the specified text or embed via DM after successfull Sign-Up. <a href='/premium/' style='text-decoration: none;'><span style='color:skyblue;'>This is a premium feature.</span></a>",
          },
          {
            name: "temp_role",
            syntax: "< temp_role: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean / keyword / role name</span> >",
            keywords: "<span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>id</span> - Set the temporary role to equal the event ID.<br><span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>name</span> - Set the temporary role to equal the event name.<br><span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>namedate</span> - Set the temporary role to equal the event name + date.",
            selection: " ",
            examples: "< temp_role: false ><br>< temp_role: namedate ><br>< temp_role: id ><br>< temp_role: Officer >",
            description:
              "The temporary role will be given to members that are signed up to the event. You can pick one of the predefined keywords or enter the name of an existing role. You can also enter any name and if the role doesn't exist, the bot will create it. The role will be deleted 24 hours after the event or when the event gets deleted / archived. If the bot didn't create the role, then the role will not be deleted. It will be removed from the affected members instead. <a href='/premium/' style='text-decoration: none;'><span style='color:skyblue;'>This is a premium feature.</span></a>",
          },
          {
            name: "disable_archiving",
            syntax: "< disable_archiving: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< disable_archiving: true ><br>< disable_archiving: false >",
            description:
              "Set this to true if you have archiving enabled on your server but want to exclude an event from being archived.",
          },
          {
            name: "disable_reason",
            syntax: "< disable_reason: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< disable_reason: true ><br>< disable_reason: false >",
            description:
              "Set this option to true if you've configured a notification channel on your server but wish to exempt an event from prompting for a reason when members sign up with Bench/Late/Tentative/Absence.",
          },
          {
            name: "allowed_roles",
            syntax: "< allowed_roles: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>role names</span> >",
            keywords: " ",
            selection: " ",
            examples: "< allowed_roles: Raider ><br>< allowed_roles: Raider, Member ><br>< allowed_roles: all >",
            description:
              "Use this setting if you only want specific roles to be allowed to sign up to the event. Separate multiple roles with a comma. Sign-Ups without an allowed role will be benched, unless bench_overflow is set to false in which case the Sign-Up will just be denied.",
          },
          {
            name: "banned_roles",
            syntax: "< banned_roles: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>role names</span> >",
            keywords: " ",
            selection: " ",
            examples: "< banned_roles: Raider ><br>< banned_roles: Raider, Member ><br>< banned_roles: all >",
            description:
              "Use this setting if you want to prevent specific roles from signing up to the event. Separate multiple roles with a comma.",
          },
          {
            name: "opt_out",
            syntax: "< opt_out: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>event ID</span> >",
            keywords: " ",
            selection: " ",
            examples: "< opt_out: none ><br>< opt_out: 891384876903829574 >",
            description:
              "If a valid event ID is entered, the Sign-Ups from that event will be copied to this event on creation. The source event has to be of the same template as the created event.",
          },
          {
            name: "mentions",
            syntax: "< mentions: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>role names</span> >",
            keywords: " ",
            selection: " ",
            examples: "< mentions: Raider ><br>< mentions: Raider, Member ><br>< mentions: all >",
            description:
              "Mentions the provided roles on event creation. Separate multiple roles with a comma.",
          },
          {
            name: "image",
            syntax: "< image: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>url</span> >",
            keywords: " ",
            selection: " ",
            examples: "< image: none ><br>< image: https://imgur.com/image.png >",
            description:
              "Set an image to be displayed at the bottom of the event. The positon can not be altered, because it's dicated by discord.",
          },
          {
            name: "thumbnail",
            syntax: "< thumbnail: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>url</span> >",
            keywords: " ",
            selection: " ",
            examples: "< thumbnail: none ><br>< thumbnail: https://imgur.com/image.png >",
            description:
              "Set an image to be displayed in the top right of the event as a thumbnail. The positon can not be altered, because it's dicated by discord. It's not recommended to use a thumbnail, because it pushes all content below it to the left.",
          },
          {
            name: "use_nicknames",
            syntax: "< use_nicknames: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>boolean</span> >",
            keywords: " ",
            selection: " ",
            examples: "< use_nicknames: true ><br>< use_nicknames: false >",
            description:
              "Defines whether the members server nickname will be used for the Sign-Up or the users global name.",
          },
          {
            name: "forum_tags",
            syntax: "< forum_tags: <span style='background-color: #2e2e33; font-weight: 300; color: white; opacity: 0.8; border: 1px solid grey;' class='px-1'>tag names</span> >",
            keywords: " ",
            selection: " ",
            examples: "< forum_tags: Event ><br>< forum_tags: Event, Wednesday >",
            description:
              "Defines the tags that will be added to the forum post of this event. Only applies if the event is posted to a forum channel.",
          },
      ],
    };
    
  },
  created(){
    document.title = "Raid-Helper - Advanced Documentation"
  },
  mounted() {
   
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods:{
    onResize() {
      this.isMobile2 = window.innerWidth;
  }
  }
    
};
</script>
<style >
.theme--dark.v-expansion-panels .v-expansion-panel{
  background-color: #404448 !important;
}
.v-expansion-panels {
  border-radius: 0 !important;
}

.vcard-templates:hover {
  background-color: rgb(51, 51, 58) !important;
}

.accent {
  color: white !important;
}

.theme--light.v-tabs-items {
  background-color: #2e2e33 !important;
}

p {
  color:white;
}

.v-slide-group__next, .v-slide-group__prev{
  min-width: 0px !important; 
  flex: 0 1 0px !important;
}

</style>