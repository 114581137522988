<template>
    <v-container>
      <span class="bg"></span>
    				     <v-col cols="12" class="mt-n7 mb-15 px-3" align="center">
      <v-btn-toggle dark tile>
        <v-btn elevation="0" tile min-width="130px" :ripple="false" color="#202025" style="opacity: 0.8; border: none;" to="/documentation/intro">Intro</v-btn>
        <v-btn elevation="0" tile min-width="130px" :ripple="false" color="#202025" style="opacity: 0.8; border: none;" to="/documentation/commands">Commands</v-btn>
	  </v-btn-toggle>
          <v-btn-toggle dark tile>
        <v-btn elevation="0" tile min-width="130px" :ripple="false" color="#202025" style="opacity: 0.8; border: none;" to="/documentation/advanced">Advanced</v-btn>
        <v-btn elevation="0" tile min-width="130px" :ripple="false" color="#202025" style="opacity: 0.8; border: none;" to="/documentation/reference">Reference</v-btn>
	  </v-btn-toggle>
    <v-btn-toggle dark tile>
        <v-btn elevation="0" tile min-width="130px" :ripple="false" color="red lighten-1" style="opacity: 1; border: none;" to="/documentation/guides">Guides</v-btn>
        <v-btn elevation="0" tile min-width="130px" :ripple="false" color="#202025" style="opacity: 0.8; border: none;" to="/documentation/api">API</v-btn>
	  </v-btn-toggle>
	  
	  
    </v-col>
  <v-card height="100%" color="primary" class="mt-n8 mx-3">
    <v-card-text style="color: white;">

  <v-expansion-panels mandatory class="mt-n1">
    <v-expansion-panel class="mt-2">
      <v-expansion-panel-header hide-actions color="#18191c" style="color: white; border-radius: 0px; font-weight: 700;" class="pa-4">
          <v-avatar tile max-width="25" max-height="25">
        <v-img max-width="25" height="25" :src="'https://cdn.discordapp.com/emojis/795081328588226580.png'"></v-img>
          </v-avatar>Custom Templates
      </v-expansion-panel-header>
      <v-expansion-panel-content color="accent">
      <v-row>
        <v-col cols="12" class="px-0 mt-5">
          Custom templates allow you to use emotes of your choice for Raid-Helper events. You can have up to 12 custom templates. Before you create an event with a custom template, you have to go through the setup which is done with the <span style="white-space: nowrap; color: lime;">/custom</span> commands. If you create a custom template event before doing the setup, the event will only have the default emotes applied to it (Late, Bench, Tentative, Absence).
          <br><br><b>Heads up:</b> You can only use emotes that are uploaded to a server where the bot is a member. 
          <h1 class="mb-3 mt-10">1. Structure</h1>
          <div style="border-left: 4px solid #2e2e33;" class="pl-2">
            <v-img max-width="600" class="my-3" src="@/assets/customexample.png"></v-img>
            <h2 class="mb-3 mt-5">Classes</h2>
            Classes make up the header of a field that contains the Sign-Ups of that class. The classes are applied as reactions to the event and can be set up with the <span style="white-space: nowrap; color: lime;">/custom classes</span> command. <u>This is the only required part to setup a basic custom template.</u>
            <h2 class="mb-3 mt-5">Specs</h2>
            Specs are choice options that the user will be presented with when signing up to a class. If no specs are set for a class, the Sign-Up will happen immediately and the emote of the class will be shown in front of the users name on the event. If specs are set with the <span style="white-space: nowrap; color: lime;">/custom specs</span> command, the user will be asked to choose a spec which will then be shown in front of the users name on the event. Keep in mind, while this is an extra step, the bot will only ask to choose the spec once per class and remember the choice for future use.
            <h2 class="mb-3 mt-5">Roles</h2>
            Roles are basically just a counter above the Sign-Ups. Depending on which classes or specs you define as part of a role, the role will show the amount of Sign-Ups within those classes and/or specs. The setup is done with the <span style="white-space: nowrap; color: lime;">/custom role</span> command.
          </div>
          <h1 class="mb-3 mt-10">2. Setup</h1>
          <div style="border-left: 4px solid #2e2e33;" class="pl-2">
            <div>We'll go through the full setup of a custom template together. Keep in mind, you don't <b>need</b> to do all of that. The only required part is the setup of the <span style="white-space: nowrap; color: lime;">/custom classes!</span></div>
        
            <h2 class="mb-3 mt-5">Classes</h2>
            <div>Let's say we want to have an event with the following reactions: <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/579532030153588739.png'> <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/579532030086217748.png'> <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/579532029880827924.png'> <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/579532030161977355.png'> <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/579532029851336716.png'> <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/579532029901799437.png'><br>The command should look like this:</div>
            <div class="mt-2 pa-4" style="color: lime; background-color: #121315; border-radius: 5px;">/custom classes <span class='px-2 py-1 field1'>template:</span><span class='px-2 py-1 field2'>17</span> <span class='px-2 py-1 field1'>classes:</span><span class='px-2 py-1 field2'><img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/579532030153588739.png'> <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/579532030086217748.png'> <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/579532029880827924.png'> <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/579532030161977355.png'> <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/579532029851336716.png'> <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/579532029901799437.png'></span></div>
            <v-row>
            <v-col cols="auto">
            <v-img max-width="300" class="mt-3" src="@/assets/replyToCustom.png"></v-img>
            </v-col>
            <v-col>
              <div class="pt-3">The bot will reply with an ephemeral message (can only be seen by you) showing that the classes have been set successfully. The buttons serve as a way to remove specific classes from the template if you wish to do that.</div>
              <div class="pt-3">You could already create an event now with the template number 17 and if all you want is a basic structure like that, you are good to go!</div>
              <div class="pt-3"><u>Limitations</u>: You can apply a maximum of 20 classes to the template, because of the discord limitation of 20 reactions per message. Keep in mind if you do apply the maximum amount, the bot will be unable to apply the default emotes <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/612373443551297689.png'> <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/612373441051361353.png'> <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/676284492754976788.png'> <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/612343589070045200.png'> .</div>

              </v-col>
            </v-row>
                    <h2 class="mb-3 mt-10">Specs</h2>
            <div>Now we might have more signup options than we can fit on an event as reactions, so Raid-Helper features specs! If a class has specs assigned to it, the bot will DM the user that signs up to that class and present a list of available specs to choose from.<br>This is how a command should look like for adding specs to the <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/579532030086217748.png'> class:</div>
            <div class="mt-2 pa-4" style="color: lime; background-color: #121315; border-radius: 5px;">/custom specs <span class='px-2 py-1 field1'>template:</span><span class='px-2 py-1 field2'>17</span> <span class='px-2 py-1 field1'>class:</span><span class='px-2 py-1 field2'><img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/579532030086217748.png'></span> <span class='px-2 py-1 field1'>specs:</span><span class='px-2 py-1 field2'><img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/637564351707873324.png'> <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/637564352333086720.png'> <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/637564352169508892.png'></span></div>
            <v-row>
            <v-col cols="auto">
            <v-img max-width="300" class="mt-3" src="@/assets/replyToCustom1.png"></v-img>
            </v-col>
            <v-col>
              <div class="pt-3">If we do that for all of our classes, eventually the structure would look like this.</div>
              <div class="pt-3">As previously mentioned, once you sign up and pick a spec for a class, the bot will remember your choice and not ask again on the next signup. If you do want to change your spec for a specific class, the easiest way is to just double sign for that class. If you click/add the reaction for the class you are already signed up for, the saved spec resets and the bot sends you a DM to choose again.</div>
              </v-col>
            </v-row>

            <h2 class="mb-3 mt-10">Roles</h2>
            <div>Roles are counters that can help you to get the sum of Sign-Ups of specific specs/classes. If you want to show the amount of dps attending your event for example, roles will do just that! Here is how the command should look like:</div>
            <div class="mt-2 pa-4" style="color: lime; background-color: #121315; border-radius: 5px;">/custom role <span class='px-2 py-1 field1'>template:</span><span class='px-2 py-1 field2'>17</span> <span class='px-2 py-1 field1'>role:</span><span class='px-2 py-1 field2'><img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/592440132129521664.png'></span> <span class='px-2 py-1 field1'>parts:</span><span class='px-2 py-1 field2'><img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/637564445215948810.png'> <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/637564445031399474.png'> <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/579532030086217748.png'> <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/579532029880827924.png'> <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/579532030161977355.png'> <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/579532029851336716.png'> <img class='mb-n1' height='18px' width='20px' src='https://cdn.discordapp.com/emojis/597921879159734284.png'></span></div>
            <v-row>
            <v-col cols="auto">
            <v-img max-width="300" class="mt-3" src="@/assets/replyToCustom2.png"></v-img>
            </v-col>
            <v-col>
              <div class="pt-3">We can set up to 6 roles. We can also mix classes and specs as part of the role. Don't double dip though (class + any of its specs to the same role) as it will count twice that way.</div>
              <div class="pt-3">Roles are a visual help, they don't change the signup process in any way.</div>
              </v-col>
            </v-row>

            <h2 class="mb-3 mt-10">Result</h2>
            <div>We can now create an event with our finished template and test it out!</div>
            <div class="mt-2 pa-4" style="color: lime; background-color: #121315; border-radius: 5px;">/quickcreate <span class='px-2 py-1 field1'>arguments:</span><span class='px-2 py-1 field2'>[template: 17]</span></div>
            <v-row>
            <v-col cols="auto">
            <v-img max-width="400" class="mt-3" src="@/assets/replyToCustom3.png"></v-img>
            </v-col>
            <v-col>
              <div class="pt-3">There are a few more subcommands to the <span style="white-space: nowrap; color: lime;">/custom</span> command. <span style="white-space: nowrap; color: lime;">/custom title</span> lets you set an emote that will be shown on the template list instead of the custom template default emote. The name of the emote will be shown as the templates title on the list. You can view your current setup with <span style="white-space: nowrap; color: lime;">/custom show</span> and reset it partially or fully with <span style="white-space: nowrap; color: lime;">/custom reset</span>.
              </div>
              </v-col>
            </v-row>
            </div>
            <h1 class="mb-3 mt-10">3. Emotes</h1>
            <div style="border-left: 4px solid #2e2e33;" class="pl-2">
            <div>There are a few things you should know about emotes and how to use them. First of all, the bot can only use emotes that are standard discord emojis or custom emotes that are uploaded to a server where the bot is a member in. The same rule applies to you too. If you have discord nitro you can use any emotes from the servers you are in. If you don't have nitro, you can only use the emotes from the server you are typing in.
              So be aware where the emotes are from that you are trying to use for your custom template. The easiest way is to just upload the emotes you want to use to the server you want to use them in. <a style="color: #5a51c5;" href="https://support.discord.com/hc/en-us/articles/360036479811-Custom-Emojis">Click here</a> for more information about uploading emotes to discord. </div>
            <div class="mt-4">Not everyone has discord nitro and you might come across the situation of needing a lot of emotes for your template but insufficient space in your current server to upload them all. Non-boosted discord servers are limited to 50 uploaded emotes, so a slightly complex template would take a lot of your available emote slots away.
              Raid-Helper supports input of emote IDs instead of the actual emotes:
            </div>
          <div class="mt-2 pa-4" style="color: lime; background-color: #121315; border-radius: 5px;">/custom classes <span class='px-2 py-1 field1'>template:</span><span class='px-2 py-1 field2'>17</span> <span class='px-2 py-1 field1'>classes:</span><span class='px-2 py-1 field2'>579532030153588739 579532030086217748</span></div>


            <h2 class="mb-3 mt-10">Emote IDs</h2>
            <div>By using the emote ID instead of using the emote directly, you are not required to have Discord Nitro if you want to use an emote from a different server. You just need to make sure the bot is a member of the emotes original server. There is multiple ways of acquiring an emotes ID. The easiest is to rightclick an emote in discord and clicking 'Copy Link', then pasting that link somewhere and extracting the 18 digit ID from it.
              Another way is to type out the emote into your text field and then backtracking to put a \ directly in front of it. If you then post that message, you will see the raw emote string from which you can extract the 18 digit emote ID.
            </div>
            <div class="mt-2 pa-4" style="background-color: #121315; border-radius: 5px;">Emote link: <span style="color: lime;">https://cdn.discordapp.com/emojis/579532030086217748.png</span><br>Raw emote string: 	<span style="color: lime;">&#60;:Rogue:579532030086217748&#62;</span><br>Emote ID: <span style="color: lime;">579532030086217748</span></div>
            <div class="mt-2">You can use this method and create a new server for the sole purpose of uploading emotes to it and using them in a custom template on your main server. Don't forget to invite Raid-Helper to your emote server!</div>
       
            <h2 class="mb-3 mt-10">Raid-Helper Emotes</h2>
            <div>If you are interested in using any of the emotes that Raid-Helper is using natively, <a style="color: #5a51c5;" href="https://raid-helper.dev/documentation/reference#emotes">click here</a> for the full collection!</div>
            </div>

                        <h1 class="mb-3 mt-10">4. Export/Import</h1>
            <div style="border-left: 4px solid #2e2e33;" class="pl-2">
            <div>You can export and import custom templates to share them with other servers. To save one of your current custom templates and be able to share them, you can use the /custom export command as shown below.</div>
          
          <div class="mt-2 pa-4" style="color: lime; background-color: #121315; border-radius: 5px;">/custom export <span class='px-2 py-1 field1'>template:</span><span class='px-2 py-1 field2'>17</span> <span class='px-2 py-1 field1'>identifier:</span><span class='px-2 py-1 field2'>myTemplate1</span></div>
          <div class="mt-4">To then import the template later or on another server, you can use the /custom import command as shown below. The identifier will be auto-completed which enables you to also search for all kinds of saved templates across all servers. There is currently no way to preview a template other than importing it and creating a test event.
          </div>
          <div class="mt-2 pa-4" style="color: lime; background-color: #121315; border-radius: 5px;">/custom import <span class='px-2 py-1 field1'>template:</span><span class='px-2 py-1 field2'>17</span> <span class='px-2 py-1 field1'>identifier:</span><span class='px-2 py-1 field2'>myTemplate1</span></div>

          </div>
        </v-col>

      </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
  </v-card-text>
  </v-card>


        		<v-snackbar elevation="0" color="green" v-model="snackbar" timeout="1000" ><span style="font-size:15px;">Text copied!</span></v-snackbar>
</v-container>
</template>
<script>


export default {
  data() {
    return {
      snackbar: false,
    }
  },
  methods: {
  
  },
  created(){
    document.title = "Raid-Helper - Guides"
  },

  
};
</script>
<style>

</style>
