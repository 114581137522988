<template>
	<v-container justify="center">
		<v-layout row fluid>
			<v-flex xs0 sm0 md0 lg0 xl1> </v-flex>
			<v-flex xs12 sm12 md12 lg12 xl10 class="my-12" :class="`rounded-lg`">
                <PremiumPage />
			</v-flex>
			<v-flex xs0 sm0 md0 lg0 xl1> </v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import PremiumPage from '../components/Premium.vue'

export default {
    name: 'premium',
    components: {PremiumPage,},
    
}
</script>
<style>
    
</style>