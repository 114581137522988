<template >

    <v-card color="#3b3e45" elevation="0" style="border-radius: 0px;">
      <v-card-title class="text-h5">
        Form Profiles
      </v-card-title>
      <v-card-text class="pt-1 px-6">
            You can save your current form into a profile and load it later. The profiles are saved, so that other managers of this server can use them as well.
      </v-card-text>
      <v-card-actions class="pa-0 pl-3 pr-1 pb-3">
        <v-col class="mx-n1">
          <v-card color="#2f3136" class="mt-n3" elevation="0">
                  <v-card-title class="mx-1">
        Save Profile
      </v-card-title>
          <v-row>
            <v-col class="">
              <v-text-field class="px-5"
                v-model="profileName"
                outlined
                dense
                placeholder="Enter a profile name."
                background-color="secondary"
                flat
              >
              </v-text-field>
            </v-col>
         
          </v-row>
                <v-tooltip top :disabled="$store.state.permissionLevel === 'admin' || $store.state.permissionLevel === 'manager'">
                <template v-slot:activator="{ on }">
                <div v-on="on" class="d-inline-block">
              <v-btn elevation="0" class="mb-5 mx-5 green" :disabled="profileNameIsEntered() === false || ($store.state.permissionLevel !== 'admin' && $store.state.permissionLevel !== 'manager')" color="green" @click="saveProfile()">Save</v-btn>
                </div>
                </template>
                <span>Requires manager role or higher.</span>
                </v-tooltip>
          </v-card>


           <v-hover>
    <template v-slot:default="{ hover }">

          <v-card color="#2f3136" class="mt-5" elevation="0">
                   
                  <v-card-title class="mx-1">
        Load Profile
      </v-card-title>
                      <v-row>
            <v-col >
              <v-select :disabled="$store.state.chosenDiscord.premium === false" class="px-5" dense item-color="white" outlined v-model="selectedProfile" :items="loadedProfiles" placeholder="Select a profile." background-color="secondary" flat @click="getProfiles()">
                <template v-slot:selection="{ item }">
                  {{ item.profileName }}
                </template>
                <template v-slot:item="{ item }">
                  {{ item.profileName }}
                </template>
              </v-select>
            </v-col>
       
          </v-row>
                <v-btn elevation="0" class="mb-5 mx-5" :disabled="selectedProfile === '' || $store.state.chosenDiscord.premium === false" @click="applyProfile()" color="green" >Load</v-btn>

                <v-tooltip top :disabled="$store.state.permissionLevel === 'admin' || $store.state.permissionLevel === 'manager'">
                <template v-slot:activator="{ on }">
                <div v-on="on" class="d-inline-block">
                <v-btn elevation="0" class="mb-5" text color="red" :disabled="($store.state.permissionLevel !== 'admin' && $store.state.permissionLevel !== 'manager') || $store.state.chosenDiscord.premium === false" v-if="selectedProfile !== ''" @click="deleteProfile()" :ripple="false">Delete</v-btn>
                </div>
                </template>
                <span>Requires manager role or higher.</span>
                </v-tooltip>

            <v-fade-transition>
          <v-overlay :z-index="0" v-if="hover && $store.state.chosenDiscord.premium === false" absolute opacity="0.8" color="primary">
            <v-btn elevation="0" x-large color="purple" @click="$router.push('/premium/', () => {})"> <span><v-icon color="yellow" class="pb-1">mdi-crown-outline</v-icon>  Get Premium!</span></v-btn>
          </v-overlay>
        </v-fade-transition>
      </v-card>
    </template>
  </v-hover>

        </v-col>
      </v-card-actions>
            <v-snackbar color="green" elevation="0" v-model="snackbar1" timeout="4000" ><span style="font-size:15px;">Profile saved!</span></v-snackbar>
            <v-snackbar color="red" elevation="0" v-model="snackbar2" timeout="4000" ><span style="font-size:15px;">Profile deleted!</span></v-snackbar>
    </v-card>

</template>
<script>
import axios from "axios";

export default {
      props: {
  	    closeOverlay: {
            type: Boolean,
  	    },
    },
  data() {
    return {
      snackbar1: false,
      snackbar2: false,
      dialog: false,
      profileName: "",
      selectedProfile: "",
      savedProfiles: [],
      loadedProfiles: [],
      profileSnapshot: {
        duration: "",
        voice_channel: "",
        server: "",
        profileName: "",
        template: "",
        channel: "",
        title: "",
        description: "",
        time: "",
        clock: "",
        limit: "",
        imageUrl: "",
        color: "",
        mentions: "",
        optOut: "",
        announcementChannel: {},
        announcementTime: "",
        announcementMessage: "",
        softresFaction: {},
        softresInstance: {},
        softresMaxres: "1",
        softresBehaviour: {},
        allowed_roles: "",
        banned_roles: "",
        temporaryRoles: "",
        response: "",
        pollQuestion: "",
        pollAnswers: [],
        date: "",
        clock: "",
        deadline: "",
        limit: "",
        lock_at_limit: "",
        limit_per_user: "",
        lower_limit: "",
        horizontal_mode: "",
        bench_overflow: "",
        queue_bench: "",
        vacuum: "",
        force_reminders: "",
        create_thread: "",
        forum_tags: "",
        delete_thread: "",
        use_nicknames: "",
        create_discordevent: "",
        only_discordevent: "",
        pin_message: "",
        deletion: "",
        mention_mode: "",
        preserve_order: "",
        event_type: "",
        reminder: "",
        apply_unregister: "",
        apply_specreset: "",
        show_on_overview: "",
        mention_leader: "",
        spec_saving: "",
        font_style: "",
        alt_names: "",
        attendance: "",
        show_title: "",
        show_info: "",
        show_leader: "",
        show_counter: "",
        show_roles: "",
        show_content: "",
        show_classes: "",
        show_emotes: "",
        show_numbering: "",
        show_allowed: "",
        show_banned: "",
        show_footer: "",
        defaults_pre_req: "",
        disable_archiving: "",
        bold_all: "",
        info_variant: "",
        date_variant: "",
        localtime_as_link: "",
        show_countdown: "",
        allow_duplicate: "",
        bench_emote: "",
        late_emote: "",
        tentative_emote: "", 
        absence_emote: "",
        leader_emote: "",
        signups1_emote: "",
        signups2_emote: "",
        date1_emote: "",
        date2_emote: "",
        time1_emote: "",
        time2_emote: "",
        countdown1_emote: "",
        countdown2_emote: "",
        thumbnail: "",
      },
    };
  },

  watch: {
    dialog: function (newValue) {
      if (newValue === false) {
        this.selectedProfile = "";
        this.profileName = "";
      }
    },
  },
  methods: {

    isMobile() {
      return window.innerWidth < 800;
    },

    profileNameIsEntered() {
      if (this.profileName.length > 0) {
        return true;
      } else {
        return false;
      }
    },

    applyProfile(){
      if (this.$store.state.chosenDiscord.premium === true) {
        this.$emit('closeOverlay', false);      
        this.$store.state.premiumFeatures.recurring = ""
        this.$store.state.premiumFeatures.scheduleDate = ""
        this.$store.state.formAnswers.date = ""
        try { this.$store.state.formAnswers.voice_channel = this.selectedProfile.voice_channel } catch (error) { }
        try { this.$store.state.advancedSettings.duration = this.selectedProfile.duration } catch (error) { }
        try { this.$store.state.formAnswers.template = this.selectedProfile.template } catch (error) { }
              if (this.$store.state.formAnswers.template && this.$store.state.formAnswers.template.name == "WoW Classic") {
                this.$store.state.formAnswers.template = this.$store.state.templates[4];
              }
        try { this.$store.state.formAnswers.channel = this.selectedProfile.channel } catch (error) { }
        try { this.$store.state.formAnswers.title = this.selectedProfile.title } catch (error) { }
        try { this.$store.state.formAnswers.description = this.selectedProfile.description } catch (error) { }
        try { this.$store.state.formAnswers.limit = this.selectedProfile.limit } catch (error) { }
        try { this.$store.state.formAnswers.imageUrl = this.selectedProfile.imageUrl } catch (error) { }
        try { this.$store.state.formAnswers.thumbnail = this.selectedProfile.thumbnail } catch (error) { }
        try { this.$store.state.formAnswers.color = this.selectedProfile.color } catch (error) { }
        try { this.$store.state.formAnswers.mentions = this.selectedProfile.mentions } catch (error) { }
        try { this.$store.state.formAnswers.optOut = this.selectedProfile.optOut } catch (error) { }
        try { this.$store.state.formAnswers.allowed_roles = this.selectedProfile.allowed_roles } catch (error) { }
        try { this.$store.state.formAnswers.banned_roles = this.selectedProfile.banned_roles } catch (error) { }
        try { this.$store.state.formAnswers.temporaryRoles = this.selectedProfile.temp_role } catch (error) { }
        try { if (this.selectedProfile.temp_role != undefined) { this.$store.state.formAnswers.temporaryRoles = this.selectedProfile.temp_role } } catch (error) { }
        try { this.$store.state.formAnswers.announcementChannel = this.selectedProfile.announcementChannel } catch (error) { }
        try { this.$store.state.formAnswers.announcementTime = this.selectedProfile.announcementTime } catch (error) { }
        try { this.$store.state.formAnswers.announcementMessage = this.selectedProfile.announcementMessage } catch (error) { }
        try { this.$store.state.advancedSettings.response = this.selectedProfile.response } catch (error) { }
        try { this.$store.state.formAnswers.pollQuestion = this.selectedProfile.pollQuestion } catch (error) { }
        try { this.$store.state.formAnswers.pollAnswers = this.selectedProfile.pollAnswers } catch (error) { }
        try { this.$store.state.advancedSettings.deadline = this.selectedProfile.deadline } catch (error) { }
        try { this.$store.state.advancedSettings.limit = this.selectedProfile.limit } catch (error) { }
        try { this.$store.state.formAnswers.announcementChannel = this.selectedProfile.announcementChannel; } catch (error) { }
        try { this.$store.state.formAnswers.announcementTime = this.selectedProfile.announcementTime; } catch (error) { }
        try { this.$store.state.formAnswers.announcementMessage = this.selectedProfile.announcementMessage; } catch (error) { }
        try { this.$store.state.formAnswers.softresFaction = this.selectedProfile.softresFaction; } catch (error) { }
        try {
          const instance = this.selectedProfile.softresInstance.trim();
          if (instance.includes(' ')) {
            this.$store.state.formAnswers.softresInstance = instance.split(' ');
          } else {
            this.$store.state.formAnswers.softresInstance = instance;
          }
        } catch (error) { }

        try { this.$store.state.formAnswers.softresMaxres = this.selectedProfile.softresMaxres; } catch (error) { }
        try { 
          this.$store.state.formAnswers.softresBehaviour = this.selectedProfile.softresBehaviour; 
          if (this.$store.state.formAnswers.softresBehaviour == "truedm") {
            this.$store.state.formAnswers.softresBehaviour
          }
        } catch (error) { }
        try { this.$store.state.advancedSettings.lock_at_limit = JSON.parse(this.selectedProfile.lock_at_limit) } catch (error) { }
        try { this.$store.state.advancedSettings.limit_per_user = this.selectedProfile.limit_per_user } catch (error) { }
        try { this.$store.state.advancedSettings.lower_limit = this.selectedProfile.lower_limit } catch (error) { }
        try { this.$store.state.advancedSettings.horizontal_mode = JSON.parse(this.selectedProfile.horizontal_mode) } catch (error) { }
        try { this.$store.state.advancedSettings.bench_overflow = JSON.parse(this.selectedProfile.bench_overflow) } catch (error) { }
        try { this.$store.state.advancedSettings.queue_bench = JSON.parse(this.selectedProfile.queue_bench) } catch (error) { }
        try { this.$store.state.advancedSettings.vacuum = JSON.parse(this.selectedProfile.vacuum) } catch (error) { }
        try { this.$store.state.advancedSettings.force_reminders = this.selectedProfile.force_reminders } catch (error) { }
        try { this.$store.state.advancedSettings.create_thread = this.selectedProfile.create_thread } catch (error) { }
        try { this.$store.state.advancedSettings.forum_tags = this.selectedProfile.forum_tags } catch (error) { }
        try { this.$store.state.advancedSettings.delete_thread = JSON.parse(this.selectedProfile.delete_thread) } catch (error) { }
        try { if (this.selectedProfile.use_nicknames == undefined) { this.selectedProfile.use_nicknames = true; } } catch (error) { }
        try { this.$store.state.advancedSettings.use_nicknames = JSON.parse(this.selectedProfile.use_nicknames) } catch (error) { }
        try { this.$store.state.advancedSettings.create_discordevent = JSON.parse(this.selectedProfile.create_discordevent) } catch (error) { }
        try { this.$store.state.advancedSettings.only_discordevent = JSON.parse(this.selectedProfile.only_discordevent) } catch (error) { }

        try { this.$store.state.advancedSettings.pin_message = JSON.parse(this.selectedProfile.pin_message) } catch (error) { }
        try { this.$store.state.advancedSettings.deletion = JSON.parse(this.selectedProfile.deletion) } catch (error) { }
        try { this.$store.state.advancedSettings.mention_mode = JSON.parse(this.selectedProfile.mention_mode) } catch (error) { }
        try { this.$store.state.advancedSettings.preserve_order = this.selectedProfile.preserve_order } catch (error) { }
        try { this.$store.state.advancedSettings.event_type = this.selectedProfile.event_type } catch (error) { }
        try { this.$store.state.advancedSettings.reminder = JSON.parse(this.selectedProfile.reminder) } catch (error) { }
        try { this.$store.state.advancedSettings.apply_unregister = JSON.parse(this.selectedProfile.apply_unregister) } catch (error) { }
        try { this.$store.state.advancedSettings.apply_specreset = JSON.parse(this.selectedProfile.apply_specreset) } catch (error) { }
        try { if (this.selectedProfile.show_on_overview == undefined) { this.selectedProfile.show_on_overview = true; } } catch (error) { }
        try { this.$store.state.advancedSettings.show_on_overview = JSON.parse(this.selectedProfile.show_on_overview) } catch (error) { }
        try { if (this.selectedProfile.mention_leader == undefined) { this.selectedProfile.mention_leader = false; } } catch (error) { }
        try { this.$store.state.advancedSettings.mention_leader = JSON.parse(this.selectedProfile.mention_leader) } catch (error) { }
        try { this.$store.state.advancedSettings.spec_saving = JSON.parse(this.selectedProfile.spec_saving) } catch (error) { }
        try { this.$store.state.advancedSettings.font_style = this.selectedProfile.font_style } catch (error) { }
        try { this.$store.state.advancedSettings.alt_names = JSON.parse(this.selectedProfile.alt_names) } catch (error) { }
        try { this.$store.state.advancedSettings.attendance = this.selectedProfile.attendance } catch (error) { }
        try { this.$store.state.advancedSettings.show_title = JSON.parse(this.selectedProfile.show_title) } catch (error) { }
        try { this.$store.state.advancedSettings.show_info = JSON.parse(this.selectedProfile.show_info) } catch (error) { }
        try { if (this.selectedProfile.show_leader == undefined) { this.selectedProfile.show_leader = true; } } catch (error) { }
        try { this.$store.state.advancedSettings.show_leader = JSON.parse(this.selectedProfile.show_leader) } catch (error) { }
        try { if (this.selectedProfile.show_counter == undefined) { this.selectedProfile.show_counter = true; } } catch (error) { }
        try { this.$store.state.advancedSettings.show_counter = JSON.parse(this.selectedProfile.show_counter) } catch (error) { }
        try { this.$store.state.advancedSettings.show_roles = JSON.parse(this.selectedProfile.show_roles) } catch (error) { }
        try { this.$store.state.advancedSettings.show_content = JSON.parse(this.selectedProfile.show_content) } catch (error) { }
        try { this.$store.state.advancedSettings.show_classes = JSON.parse(this.selectedProfile.show_classes) } catch (error) { }
        try { this.$store.state.advancedSettings.show_emotes = JSON.parse(this.selectedProfile.show_emotes) } catch (error) { }
        try { this.$store.state.advancedSettings.show_numbering = JSON.parse(this.selectedProfile.show_numbering) } catch (error) { }
        try { this.$store.state.advancedSettings.show_allowed = JSON.parse(this.selectedProfile.show_allowed) } catch (error) { }
        try { this.$store.state.advancedSettings.show_banned = JSON.parse(this.selectedProfile.show_banned) } catch (error) { }
        try { this.$store.state.advancedSettings.show_footer = JSON.parse(this.selectedProfile.show_footer) } catch (error) { }
        try { this.$store.state.advancedSettings.defaults_pre_req = JSON.parse(this.selectedProfile.defaults_pre_req) } catch (error) { }
        try { this.$store.state.advancedSettings.disable_archiving = JSON.parse(this.selectedProfile.disable_archiving) } catch (error) { }
        try { this.$store.state.advancedSettings.bold_all = JSON.parse(this.selectedProfile.bold_all) } catch (error) { }
        try { if (this.selectedProfile.info_variant == undefined) { this.selectedProfile.info_variant = "short"; } } catch (error) { }
        try { this.$store.state.advancedSettings.info_variant = this.selectedProfile.info_variant } catch (error) { }
        try { this.$store.state.advancedSettings.date_variant = this.selectedProfile.date_variant } catch (error) { }
        try { this.$store.state.advancedSettings.show_countdown = JSON.parse(this.selectedProfile.show_countdown) } catch (error) { }
        try { this.$store.state.advancedSettings.allow_duplicate = JSON.parse(this.selectedProfile.allow_duplicate) } catch (error) { }
        try { this.$store.state.advancedSettings.bench_emote = this.selectedProfile.bench_emote } catch (error) { }
        try { this.$store.state.advancedSettings.late_emote = this.selectedProfile.late_emote } catch (error) { }
        try { this.$store.state.advancedSettings.tentative_emote = this.selectedProfile.tentative_emote } catch (error) { }
        try { this.$store.state.advancedSettings.absence_emote = this.selectedProfile.absence_emote } catch (error) { }
        try { this.$store.state.advancedSettings.leader_emote = this.selectedProfile.leader_emote } catch (error) { }
        try { this.$store.state.advancedSettings.signups1_emote = this.selectedProfile.signups1_emote } catch (error) { }
        try { this.$store.state.advancedSettings.signups2_emote = this.selectedProfile.signups2_emote } catch (error) { }
        try { this.$store.state.advancedSettings.date1_emote = this.selectedProfile.date1_emote } catch (error) { }
        try { this.$store.state.advancedSettings.date2_emote = this.selectedProfile.date2_emote } catch (error) { }
        try { this.$store.state.advancedSettings.time1_emote = this.selectedProfile.time1_emote } catch (error) { }
        try { this.$store.state.advancedSettings.time2_emote = this.selectedProfile.time2_emote } catch (error) { }
        try { this.$store.state.advancedSettings.countdown1_emote = this.selectedProfile.countdown1_emote } catch (error) { }
        try { this.$store.state.advancedSettings.countdown2_emote = this.selectedProfile.countdown2_emote } catch (error) { }

  
        const fullTemplateFound = this.$store.state.templates.find(template => template.shortName === this.selectedProfile.template.shortName);
    
        try {
        this.selectedProfile.template.classes.forEach(cls1 => { 
          fullTemplateFound.classes.find(cls2 => {
            if (cls2.name === cls1.name) {
              cls1.id = cls2.id;
            }
          }) 
        })
      } catch (error) { }

      try {
        this.selectedProfile.template.roles.forEach(cls1 => { 
          fullTemplateFound.roles.find(cls2 => {
            if (cls2.name === cls1.name) {
              cls1.id = cls2.id;
            }
          }) 
        })
      } catch (error) { }

        this.$root.$refs.Form.snackbar2 = true;
        this.dialog = false;
        this.profileName = "";
        this.selectedProfile = "";
      }
    },

    deleteProfile() {
      if (this.$store.state.chosenDiscord.premium === true) {
        let server = this.$store.state.chosenDiscord.id;
        let profileName = this.selectedProfile.profileName;
        let accessToken = localStorage.getItem('accessToken').slice(1,-1);
        axios
         .post(this.$store.state.url + "/api/profiles/delete/", { server, profileName, accessToken })
          .then((res) => {})
          .catch((error) => {
            console.log("Something went wrong!");
          })
          .finally(() => {
            this.snackbar2 = true;
            this.selectedProfile = ''
          });
      }
    },
    saveProfile() {
        this.profileSnapshot.duration = this.$store.state.advancedSettings.duration;
        this.profileSnapshot.voice_channel = this.$store.state.formAnswers.voice_channel;
        this.profileSnapshot.server = this.$store.state.chosenDiscord.id;
        this.profileSnapshot.template = this.$store.state.formAnswers.template;
        this.profileSnapshot.channel = this.$store.state.formAnswers.channel;
        this.profileSnapshot.title = this.$store.state.formAnswers.title;
        this.profileSnapshot.description = this.$store.state.formAnswers.description;
        this.profileSnapshot.limit = this.$store.state.formAnswers.limit;
        this.profileSnapshot.imageUrl = this.$store.state.formAnswers.imageUrl;
        this.profileSnapshot.color = this.$store.state.formAnswers.color;
        this.profileSnapshot.mentions = this.$store.state.formAnswers.mentions;
        this.profileSnapshot.optOut = this.$store.state.formAnswers.optOut;
        this.profileSnapshot.announcementChannel = this.$store.state.formAnswers.announcementChannel;
        this.profileSnapshot.announcementTime = this.$store.state.formAnswers.announcementTime;
        this.profileSnapshot.announcementMessage = this.$store.state.formAnswers.announcementMessage;
        this.profileSnapshot.softresFaction = this.$store.state.formAnswers.softresFaction;
        this.profileSnapshot.softresInstance = this.$store.state.formAnswers.softresInstance;
        this.profileSnapshot.softresMaxres = this.$store.state.formAnswers.softresMaxres;
        this.profileSnapshot.softresBehaviour = this.$store.state.formAnswers.softresBehaviour;
        this.profileSnapshot.allowed_roles = this.$store.state.formAnswers.allowed_roles;
        this.profileSnapshot.banned_roles = this.$store.state.formAnswers.banned_roles;
        this.profileSnapshot.temporaryRoles = this.$store.state.formAnswers.temporaryRoles;
        this.profileSnapshot.response = this.$store.state.advancedSettings.response;
        if (this.$store.state.formAnswers.template.id === 23 ||
          this.$store.state.formAnswers.template.id === 24 || 
          this.$store.state.formAnswers.template.id === 25 ||
          this.$store.state.formAnswers.template.shortName === "unsortedstrawpoll" ||
          this.$store.state.formAnswers.template.shortName === "sortedstrawpoll" ||
          this.$store.state.formAnswers.template.shortName === "basepoll")
        {
            this.profileSnapshot.pollQuestion = this.$store.state.formAnswers.pollQuestion;
            this.profileSnapshot.pollAnswers = this.$store.state.formAnswers.pollAnswers;
        }
        this.profileSnapshot.deadline = this.$store.state.advancedSettings.deadline;
        this.profileSnapshot.limit = this.$store.state.advancedSettings.limit;
        this.profileSnapshot.lock_at_limit = this.$store.state.advancedSettings.lock_at_limit;
        this.profileSnapshot.limit_per_user = this.$store.state.advancedSettings.limit_per_user;
        this.profileSnapshot.lower_limit = this.$store.state.advancedSettings.lower_limit;
        this.profileSnapshot.horizontal_mode = this.$store.state.advancedSettings.horizontal_mode;
        this.profileSnapshot.bench_overflow = this.$store.state.advancedSettings.bench_overflow;
        this.profileSnapshot.queue_bench = this.$store.state.advancedSettings.queue_bench;
        this.profileSnapshot.vacuum = this.$store.state.advancedSettings.vacuum;
        this.profileSnapshot.force_reminders = this.$store.state.advancedSettings.force_reminders;
        this.profileSnapshot.create_thread = this.$store.state.advancedSettings.create_thread;
        this.profileSnapshot.forum_tags = this.$store.state.advancedSettings.forum_tags;
        this.profileSnapshot.delete_thread = this.$store.state.advancedSettings.delete_thread;
        this.profileSnapshot.use_nicknames = this.$store.state.advancedSettings.use_nicknames;
        this.profileSnapshot.create_discordevent = this.$store.state.advancedSettings.create_discordevent;
        this.profileSnapshot.only_discordevent = this.$store.state.advancedSettings.only_discordevent;
        this.profileSnapshot.pin_message = this.$store.state.advancedSettings.pin_message;
        this.profileSnapshot.deletion = this.$store.state.advancedSettings.deletion;
        this.profileSnapshot.mention_mode = this.$store.state.advancedSettings.mention_mode;
        this.profileSnapshot.preserve_order = this.$store.state.advancedSettings.preserve_order;
        this.profileSnapshot.event_type = this.$store.state.advancedSettings.event_type;
        this.profileSnapshot.reminder = this.$store.state.advancedSettings.reminder;
        this.profileSnapshot.apply_unregister = this.$store.state.advancedSettings.apply_unregister;
        this.profileSnapshot.apply_specreset = this.$store.state.advancedSettings.apply_specreset;
        this.profileSnapshot.show_on_overview = this.$store.state.advancedSettings.show_on_overview;
        this.profileSnapshot.mention_leader = this.$store.state.advancedSettings.mention_leader;
        this.profileSnapshot.spec_saving = this.$store.state.advancedSettings.spec_saving;
        this.profileSnapshot.font_style = this.$store.state.advancedSettings.font_style;
        this.profileSnapshot.alt_names = this.$store.state.advancedSettings.alt_names;
        this.profileSnapshot.attendance = this.$store.state.advancedSettings.attendance;
        this.profileSnapshot.show_title = this.$store.state.advancedSettings.show_title;
        this.profileSnapshot.show_info = this.$store.state.advancedSettings.show_info;
        this.profileSnapshot.show_leader = this.$store.state.advancedSettings.show_leader;
        this.profileSnapshot.show_counter = this.$store.state.advancedSettings.show_counter;
        this.profileSnapshot.show_roles = this.$store.state.advancedSettings.show_roles;
        this.profileSnapshot.show_content = this.$store.state.advancedSettings.show_content;
        this.profileSnapshot.show_classes = this.$store.state.advancedSettings.show_classes;
        this.profileSnapshot.show_emotes = this.$store.state.advancedSettings.show_emotes;
        this.profileSnapshot.show_numbering = this.$store.state.advancedSettings.show_numbering;
        this.profileSnapshot.show_allowed = this.$store.state.advancedSettings.show_allowed;
        this.profileSnapshot.show_banned = this.$store.state.advancedSettings.show_banned;
        this.profileSnapshot.show_footer = this.$store.state.advancedSettings.show_footer;
        this.profileSnapshot.defaults_pre_req = this.$store.state.advancedSettings.defaults_pre_req;
        this.profileSnapshot.disable_archiving = this.$store.state.advancedSettings.disable_archiving;
        this.profileSnapshot.bold_all = this.$store.state.advancedSettings.bold_all;
        this.profileSnapshot.info_variant = this.$store.state.advancedSettings.info_variant;
        this.profileSnapshot.date_variant = this.$store.state.advancedSettings.date_variant;
        this.profileSnapshot.localtime_as_link = this.$store.state.advancedSettings.localtime_as_link;
        this.profileSnapshot.show_countdown = this.$store.state.advancedSettings.show_countdown; 
        this.profileSnapshot.allow_duplicate = this.$store.state.advancedSettings.allow_duplicate; 
        this.profileSnapshot.bench_emote = this.$store.state.advancedSettings.bench_emote
        this.profileSnapshot.late_emote = this.$store.state.advancedSettings.late_emote
        this.profileSnapshot.tentative_emote = this.$store.state.advancedSettings.tentative_emote
        this.profileSnapshot.absence_emote = this.$store.state.advancedSettings.absence_emote
        this.profileSnapshot.leader_emote = this.$store.state.advancedSettings.leader_emote
        this.profileSnapshot.signups1_emote = this.$store.state.advancedSettings.signups1_emote
        this.profileSnapshot.signups2_emote = this.$store.state.advancedSettings.signups2_emote
        this.profileSnapshot.date1_emote = this.$store.state.advancedSettings.date1_emote
        this.profileSnapshot.date2_emote = this.$store.state.advancedSettings.date2_emote
        this.profileSnapshot.time1_emote = this.$store.state.advancedSettings.time1_emote
        this.profileSnapshot.time2_emote = this.$store.state.advancedSettings.time2_emote
        this.profileSnapshot.countdown1_emote = this.$store.state.advancedSettings.countdown1_emote
        this.profileSnapshot.countdown2_emote = this.$store.state.advancedSettings.countdown2_emote
        this.profileSnapshot.thumbnail = this.$store.state.advancedSettings.thumbnail
        this.profileSnapshot.profileName = this.profileName;
        this.profileSnapshot.accessToken = localStorage.getItem('accessToken').slice(1,-1);
        if (this.profileName !== undefined && this.profileName.length > 0) {
          axios.post(this.$store.state.url + "/api/profiles/create/", this.profileSnapshot)
            .then((res) => {})
            .catch((error) => {
              console.log("Something went wrong!");
            })
            .finally(() => {
              this.profileName = "";
              this.snackbar1 = true;
            });
        } else {

        }
    },
    getProfiles() {
      let server = this.$store.state.chosenDiscord.id;
      let accessToken = localStorage.getItem('accessToken').slice(1,-1);
      axios
        .post(this.$store.state.url + "/api/profiles/get/", { server, accessToken })
        .then((res) => {
          for (let index = 0; index < res.data.length; index++) {
            if (res.data[index].identifier) {
              res.data[index].profileName = res.data[index].identifier;
            }
          }
      
          this.loadedProfiles = res.data.sort(function(a, b) {
            return a.profileName.localeCompare(b.profileName);
          });
        }).catch((error) => {
          console.log(error)
          console.log("Something went wrong!");
        }).finally(() => {
      
          for (let index = 0; index < this.loadedProfiles.length; index++) {
            let matchingRoles = [];
                this.$store.state.chosenDiscordRoles.forEach(role => {
                  if (this.loadedProfiles[index].mentions.includes(role.id) || this.loadedProfiles[index].mentions.includes(role.name)) {
                    matchingRoles.push(role.id);
                  }
                });
            
            this.loadedProfiles[index].mentions = matchingRoles;
            this.savedProfiles.push(this.loadedProfiles[index]);
          }
        });
    },

    deleteAllInput() {
      this.$store.state.formAnswers.template = "";
      this.$store.state.formAnswers.voice_channel = "";
      this.$store.state.formAnswers.channel = "";
      this.$store.state.formAnswers.title = "";
      this.$store.state.formAnswers.description = "";
      this.$store.state.formAnswers.date = "";
      this.$store.state.formAnswers.imageUrl = "";
      this.$store.state.formAnswers.color = "#71368A";
      this.$store.state.formAnswers.mentions = "";
      this.$store.state.formAnswers.optOut = "";
      this.$store.state.formAnswers.announcementChannel = "";
      this.$store.state.formAnswers.announcementTime = "";
      this.$store.state.formAnswers.announcementMessage = "";
      this.$store.state.formAnswers.allowed_roles = "";
      this.$store.state.formAnswers.banned_roles = "";
      this.$store.state.formAnswers.temporaryRoles = "";
      this.$store.state.formAnswers.response = "";
      this.$store.state.formAnswers.pollQuestion = "";
      this.$store.state.formAnswers.pollAnswers = [];
      this.$store.state.premiumFeatures.scheduleDate = "";
      this.$store.state.premiumFeatures.recurring = "";
      this.$store.state.formAnswers.softresFaction = "";
      this.$store.state.formAnswers.softresInstance = "";
      this.$store.state.formAnswers.softresMaxres = "";
      this.$store.state.formAnswers.softresBehaviour = "";
      this.$store.state.advancedSettings.duration = this.$store.state.defaultAdvancedSettings.duration;
      this.$store.state.advancedSettings.deadline = this.$store.state.defaultAdvancedSettings.deadline;
      this.$store.state.advancedSettings.limit = this.$store.state.defaultAdvancedSettings.limit;
      this.$store.state.advancedSettings.lock_at_limit = this.$store.state.defaultAdvancedSettings.lock_at_limit;
      this.$store.state.advancedSettings.limit_per_user = this.$store.state.defaultAdvancedSettings.limit_per_user;
      this.$store.state.advancedSettings.lower_limit = this.$store.state.defaultAdvancedSettings.lower_limit;
      this.$store.state.advancedSettings.horizontal_mode = this.$store.state.defaultAdvancedSettings.horizontal_mode;
      this.$store.state.advancedSettings.bench_overflow = this.$store.state.defaultAdvancedSettings.bench_overflow;
      this.$store.state.advancedSettings.queue_bench = this.$store.state.defaultAdvancedSettings.queue_bench;
      this.$store.state.advancedSettings.vacuum = this.$store.state.defaultAdvancedSettings.vacuum;
      this.$store.state.advancedSettings.force_reminders = this.$store.state.defaultAdvancedSettings.force_reminders;
      this.$store.state.advancedSettings.create_thread = this.$store.state.defaultAdvancedSettings.create_thread;
      this.$store.state.advancedSettings.forum_tags = this.$store.state.defaultAdvancedSettings.forum_tags;
      this.$store.state.advancedSettings.delete_thread = this.$store.state.defaultAdvancedSettings.delete_thread;
      this.$store.state.advancedSettings.use_nicknames = this.$store.state.defaultAdvancedSettings.use_nicknames;
      this.$store.state.advancedSettings.create_discordevent = this.$store.state.defaultAdvancedSettings.create_discordevent;
      this.$store.state.advancedSettings.only_discordevent = this.$store.state.defaultAdvancedSettings.only_discordevent;
      this.$store.state.advancedSettings.pin_message = this.$store.state.defaultAdvancedSettings.pin_message;
      this.$store.state.advancedSettings.deletion = this.$store.state.defaultAdvancedSettings.deletion;
      this.$store.state.advancedSettings.response = this.$store.state.defaultAdvancedSettings.response;
      this.$store.state.advancedSettings.mention_mode = this.$store.state.defaultAdvancedSettings.mention_mode;
      this.$store.state.advancedSettings.preserve_order = this.$store.state.defaultAdvancedSettings.preserve_order;
      this.$store.state.advancedSettings.event_type = this.$store.state.defaultAdvancedSettings.event_type;
      this.$store.state.advancedSettings.reminder = this.$store.state.defaultAdvancedSettings.reminder;
      this.$store.state.advancedSettings.apply_unregister = this.$store.state.defaultAdvancedSettings.apply_unregister;
      this.$store.state.advancedSettings.apply_specreset = this.$store.state.defaultAdvancedSettings.apply_specreset;
      this.$store.state.advancedSettings.show_on_overview = this.$store.state.defaultAdvancedSettings.show_on_overview;
      this.$store.state.advancedSettings.mention_leader = this.$store.state.defaultAdvancedSettings.mention_leader;
      this.$store.state.advancedSettings.spec_saving = this.$store.state.defaultAdvancedSettings.spec_saving;
      this.$store.state.advancedSettings.font_style = this.$store.state.defaultAdvancedSettings.font_style;
      this.$store.state.advancedSettings.alt_names = this.$store.state.defaultAdvancedSettings.alt_names;
      this.$store.state.advancedSettings.attendance = this.$store.state.defaultAdvancedSettings.attendance;
      this.$store.state.advancedSettings.show_title = this.$store.state.defaultAdvancedSettings.show_title;
      this.$store.state.advancedSettings.show_info = this.$store.state.defaultAdvancedSettings.show_info;
      this.$store.state.advancedSettings.show_leader = this.$store.state.defaultAdvancedSettings.show_leader;
      this.$store.state.advancedSettings.show_counter = this.$store.state.defaultAdvancedSettings.show_counter;
      this.$store.state.advancedSettings.show_roles = this.$store.state.defaultAdvancedSettings.show_roles;
      this.$store.state.advancedSettings.show_content = this.$store.state.defaultAdvancedSettings.show_content;
      this.$store.state.advancedSettings.show_classes = this.$store.state.defaultAdvancedSettings.show_classes;
      this.$store.state.advancedSettings.show_emotes = this.$store.state.defaultAdvancedSettings.show_emotes;
      this.$store.state.advancedSettings.show_numbering = this.$store.state.defaultAdvancedSettings.show_numbering;
      this.$store.state.advancedSettings.show_allowed = this.$store.state.defaultAdvancedSettings.show_allowed;
      this.$store.state.advancedSettings.show_banned = this.$store.state.defaultAdvancedSettings.show_banned;
      this.$store.state.advancedSettings.show_footer = this.$store.state.defaultAdvancedSettings.show_footer;
      this.$store.state.advancedSettings.defaults_pre_req = this.$store.state.defaultAdvancedSettings.defaults_pre_req;
      this.$store.state.advancedSettings.disable_archiving = this.$store.state.defaultAdvancedSettings.disable_archiving;
      this.$store.state.advancedSettings.bold_all = this.$store.state.defaultAdvancedSettings.bold_all;
      this.$store.state.advancedSettings.info_variant = this.$store.state.defaultAdvancedSettings.info_variant;
      this.$store.state.advancedSettings.date_variant = this.$store.state.defaultAdvancedSettings.date_variant;
      this.$store.state.advancedSettings.show_countdown = this.$store.state.defaultAdvancedSettings.show_countdown;
      this.$store.state.advancedSettings.localtime_as_link = this.$store.state.defaultAdvancedSettings.localtime_as_link;
      this.$store.state.advancedSettings.allow_duplicate = this.$store.state.defaultAdvancedSettings.allow_duplicate;
      this.$store.state.advancedSettings.bench_emote = this.$store.state.defaultAdvancedSettings.bench_emote
      this.$store.state.advancedSettings.late_emote = this.$store.state.defaultAdvancedSettings.late_emote
      this.$store.state.advancedSettings.tentative_emote = this.$store.state.defaultAdvancedSettings.tentative_emote
      this.$store.state.advancedSettings.absence_emote = this.$store.state.defaultAdvancedSettings.absence_emote
      this.$store.state.advancedSettings.leader_emote = this.$store.state.defaultAdvancedSettings.leader_emote
      this.$store.state.advancedSettings.signups1_emote = this.$store.state.defaultAdvancedSettings.signups1_emote
      this.$store.state.advancedSettings.signups2_emote = this.$store.state.defaultAdvancedSettings.signups2_emote
      this.$store.state.advancedSettings.date1_emote = this.$store.state.defaultAdvancedSettings.date1_emote
      this.$store.state.advancedSettings.date2_emote = this.$store.state.defaultAdvancedSettings.date2_emote
      this.$store.state.advancedSettings.time1_emote = this.$store.state.defaultAdvancedSettings.time1_emote
      this.$store.state.advancedSettings.time2_emote = this.$store.state.defaultAdvancedSettings.time2_emote
      this.$store.state.advancedSettings.countdown1_emote = this.$store.state.defaultAdvancedSettings.countdown1_emote
      this.$store.state.advancedSettings.countdown2_emote = this.$store.state.defaultAdvancedSettings.countdown2_emote
      this.$store.state.advancedSettings.thumbnail = this.$store.state.defaultAdvancedSettings.thumbnail
    },

    cancelBtn() {
      this.dialog = false;
    },
  },
};
</script>
<style >
#form-profile.v-btn--absolute.v-btn--bottom,
#form-profile.v-btn--fixed.v-btn--bottom {
  right: 1px;
  margin-right: 350px;
}

#profile-card-title {
  background-color: rgb(67 67 78);
}



.settingsbuttons {
  opacity: 0.7;

}

.settingsbuttons:hover {
  opacity: 1;
}
</style>