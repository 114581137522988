var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mx-0 ml-n1 mb-n3 mt-3"},[_c('v-col',{staticClass:"mb-n7 mb-sm-0",attrs:{"cols":"6","sm":"4"}},[_c('div',{staticClass:"mb-3 mt-n3",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("deadline")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-n3",attrs:{"background-color":"#313338","solo":"","flat":"","dense":"","label":"deadline"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.deadline),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "deadline", $$v)},expression:"advancedSettings.deadline"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("The deadline is the closing time of the event in hours before the events time.")])]),_c('div',{staticClass:"mb-3 mt-n5",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("duration")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-n3",attrs:{"background-color":"#313338","solo":"","flat":"","dense":"","label":"duration"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.duration),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "duration", $$v)},expression:"advancedSettings.duration"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("The duration of the event in minutes.")])]),_c('div',{staticClass:"mb-3 mt-n5",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("font_style")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-select',{staticClass:"my-n3",attrs:{"type":"number","items":_vm.items,"item-value":"name","background-color":"#313338","solo":"","dense":"","flat":"","label":"font_style","item-color":"green"},on:{"change":function($event){return _vm.updateAdvanced()}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [(item.name != 'none')?_c('img',{staticClass:"mx-1",attrs:{"width":"20px","src":item.image}}):_c('div',[_vm._v(_vm._s(item.name))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"mx-1 mt-1",attrs:{"width":"20px","src":item.image}})]}}],null,true),model:{value:(_vm.advancedSettings.font_style),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "font_style", $$v)},expression:"advancedSettings.font_style"}})],1)]}}])},[_c('span',[_vm._v("The event titles font style.")])]),_c('div',{staticClass:"mb-3 mt-n5",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("preserve_order")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-select',{staticClass:"my-n3",attrs:{"type":"number","items":['normal', 'always', 'never'],"item-value":"name","background-color":"#313338","solo":"","dense":"","flat":"","label":"preserve_order","item-color":"green"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.preserve_order),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "preserve_order", $$v)},expression:"advancedSettings.preserve_order"}})],1)]}}])},[_c('span',[_vm._v("whether the order number will be retained on Sign-Up change.")])]),_c('div',{staticClass:"mb-3 mt-n5",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("event_type")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-select',{staticClass:"my-n3",attrs:{"type":"number","items":['interaction', 'reaction'],"item-value":"name","background-color":"#313338","solo":"","dense":"","flat":"","label":"event_type","item-color":"green"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.event_type),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "event_type", $$v)},expression:"advancedSettings.event_type"}})],1)]}}])},[_c('span',[_vm._v("whether the event will use reactions or interactions.")])]),_c('div',{staticClass:"mb-3 mt-n5",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("forum_tags")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-n3",attrs:{"background-color":"#313338","solo":"","flat":"","dense":"","label":"forum_tags"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.forum_tags),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "forum_tags", $$v)},expression:"advancedSettings.forum_tags"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("Set the tags to apply to this post.")])]),_c('div',{staticClass:"mb-3 mt-n5",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("signups1_emote")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-n3",attrs:{"background-color":"#313338","solo":"","flat":"","dense":"","label":"signups1_emote"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.signups1_emote),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "signups1_emote", $$v)},expression:"advancedSettings.signups1_emote"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("The current emote used for this icon. To change the emote you can paste the ID, raw string or link of the emote here.")])]),_c('div',{staticClass:"mb-3 mt-n5",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("signups2_emote")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-n3",attrs:{"background-color":"#313338","solo":"","flat":"","dense":"","label":"signups2_emote"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.signups2_emote),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "signups2_emote", $$v)},expression:"advancedSettings.signups2_emote"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("The current emote used for this icon. To change the emote you can paste the ID, raw string or link of the emote here.")])]),_c('div',{staticClass:"mb-3 mt-n5",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("date1_emote")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-n3",attrs:{"background-color":"#313338","solo":"","flat":"","dense":"","label":"date1_emote"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.date1_emote),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "date1_emote", $$v)},expression:"advancedSettings.date1_emote"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("The current emote used for this icon. To change the emote you can paste the ID, raw string or link of the emote here.")])]),_c('div',{staticClass:"mb-3 mt-n5",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("date2_emote")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-n3",attrs:{"background-color":"#313338","solo":"","flat":"","dense":"","label":"date2_emote"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.date2_emote),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "date2_emote", $$v)},expression:"advancedSettings.date2_emote"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("The current emote used for this icon. To change the emote you can paste the ID, raw string or link of the emote here.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"mb-n7 mt-n4",attrs:{"color":"green","label":"lock_at_limit"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.lock_at_limit),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "lock_at_limit", $$v)},expression:"advancedSettings.lock_at_limit"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("lock_at_limit")])])]}}])},[_c('span',[_vm._v("whether the set limit is a hard limit or only visual.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"my-n7",attrs:{"color":"green","label":"horizontal_mode"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.horizontal_mode),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "horizontal_mode", $$v)},expression:"advancedSettings.horizontal_mode"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("horizontal_mode")])])]}}])},[_c('span',[_vm._v("Display the content horizontally instead of vertically.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"my-n7",attrs:{"color":"green","label":"bench_overflow"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.bench_overflow),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "bench_overflow", $$v)},expression:"advancedSettings.bench_overflow"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("bench_overflow")])])]}}])},[_c('span',[_vm._v("Sign-Ups above a limit will be pushed to the bench.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"my-n7",attrs:{"color":"green","label":"queue_bench"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.queue_bench),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "queue_bench", $$v)},expression:"advancedSettings.queue_bench"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("queue_bench")])])]}}])},[_c('span',[_vm._v("Sign-Ups above a limit will be queued instead of fully benched.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"my-n7",attrs:{"color":"green","label":"mention_mode"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.mention_mode),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "mention_mode", $$v)},expression:"advancedSettings.mention_mode"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("mention_mode")])])]}}])},[_c('span',[_vm._v("Displays mentions instead of just the members names.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"my-n7",attrs:{"color":"green","label":"spec_saving"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.spec_saving),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "spec_saving", $$v)},expression:"advancedSettings.spec_saving"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("spec_saving")])])]}}])},[_c('span',[_vm._v("The bot will remember the spec and apply it automatically next time.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"my-n7",attrs:{"color":"green","label":"alt_names"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.alt_names),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "alt_names", $$v)},expression:"advancedSettings.alt_names"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("alt_names")])])]}}])},[_c('span',[_vm._v("Sign-Ups will be given the option to enter an alternative name.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"my-n7",attrs:{"color":"green","label":"apply_unregister"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.apply_unregister),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "apply_unregister", $$v)},expression:"advancedSettings.apply_unregister"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("apply_unregister")])])]}}])},[_c('span',[_vm._v("Apply a reaction that will allow users to remove themselves from the event.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"my-n7",attrs:{"color":"green","label":"apply_specreset"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.apply_specreset),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "apply_specreset", $$v)},expression:"advancedSettings.apply_specreset"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("apply_specreset")])])]}}])},[_c('span',[_vm._v("Apply a reaction that will allow users to reset all their saved specs for this template.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"my-n7",attrs:{"color":"green","label":"show_on_overview"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.show_on_overview),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "show_on_overview", $$v)},expression:"advancedSettings.show_on_overview"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("show_on_overview")])])]}}])},[_c('span',[_vm._v("whether this event will be displayed on the /overview.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"my-n7",attrs:{"color":"green","label":"create_discordevent"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.create_discordevent),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "create_discordevent", $$v)},expression:"advancedSettings.create_discordevent"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("create_discordevent")])])]}}])},[_c('span',[_vm._v("Create a discord event on top of the Raid-Helper event. Premium feature.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"my-n7",attrs:{"color":"green","label":"create_discordevent"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.only_discordevent),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "only_discordevent", $$v)},expression:"advancedSettings.only_discordevent"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("only_discordevent")])])]}}])},[_c('span',[_vm._v("Create only a discord event without the Raid-Helper event. Premium feature.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"mb-n7 mt-n4",attrs:{"color":"green","label":"delete_thread"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.delete_thread),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "delete_thread", $$v)},expression:"advancedSettings.delete_thread"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("delete_thread")])])]}}])},[_c('span',[_vm._v("Determines whether the attached thread or post of the event will be deleted or just archived.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"mb-n7 mt-n4",attrs:{"color":"green","label":"use_nicknames"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.use_nicknames),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "use_nicknames", $$v)},expression:"advancedSettings.use_nicknames"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("use_nicknames")])])]}}])},[_c('span',[_vm._v("Determines whether the server nicknames are used for the Sign-Up or the global names.")])])],1),_c('v-col',{staticClass:"mb-n7 mb-sm-0",attrs:{"cols":"6","sm":"4"}},[_c('div',{staticClass:"mb-3 mt-n3",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("limit")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-n3",attrs:{"type":"number","background-color":"#313338","solo":"","dense":"","flat":"","label":"limit"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.limit),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "limit", $$v)},expression:"advancedSettings.limit"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("The displayed overall Sign-Up limit for this event.")])]),_c('div',{staticClass:"mb-3 mt-n5",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("lower_limit")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-n3",attrs:{"type":"number","background-color":"#313338","solo":"","dense":"","flat":"","label":"lower_limit"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.lower_limit),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "lower_limit", $$v)},expression:"advancedSettings.lower_limit"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("The minimum amount of Sign-Ups required for this event to happen.")])]),_c('div',{staticClass:"mb-3 mt-n5",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("limit_per_user")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-n3",attrs:{"type":"number","background-color":"#313338","solo":"","dense":"","flat":"","label":"limit_per_user"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.limit_per_user),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "limit_per_user", $$v)},expression:"advancedSettings.limit_per_user"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("The maximum amount of Sign-Ups per user for this event.")])]),_c('div',{staticClass:"mb-3 mt-n5",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("force_reminders")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-n3",attrs:{"background-color":"#313338","solo":"","flat":"","dense":"","label":"force_reminders"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.force_reminders),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "force_reminders", $$v)},expression:"advancedSettings.force_reminders"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("Causes a reminder to be sent to users, regardless of their setting.")])]),_c('div',{staticClass:"mb-3 mt-n5",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("create_thread")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-n3",attrs:{"background-color":"#313338","solo":"","flat":"","dense":"","label":"create_thread"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.create_thread),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "create_thread", $$v)},expression:"advancedSettings.create_thread"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("Creates a thread with the event.")])]),_c('div',{staticClass:"mb-3 mt-n5",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("reminder")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-n3",attrs:{"background-color":"#313338","solo":"","flat":"","dense":"","label":"reminder"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.reminder),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "reminder", $$v)},expression:"advancedSettings.reminder"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("Sends a reminder into event channel. Enter the time before the event in minutes.")])]),_c('div',{staticClass:"mb-3 mt-n5",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("info_variant")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-select',{staticClass:"my-n3",attrs:{"items":['short', 'long'],"item-value":"name","background-color":"#313338","solo":"","dense":"","flat":"","label":"info_variant","item-color":"green"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.info_variant),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "info_variant", $$v)},expression:"advancedSettings.info_variant"}})],1)]}}])},[_c('span',[_vm._v("Determines the formatting of the info field.")])]),_c('div',{staticClass:"mb-3 mt-n5",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("time1_emote")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-n3",attrs:{"background-color":"#313338","solo":"","flat":"","dense":"","label":"time1_emote"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.time1_emote),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "time1_emote", $$v)},expression:"advancedSettings.time1_emote"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("The current emote used for this icon. To change the emote you can paste the ID, raw string or link of the emote here.")])]),_c('div',{staticClass:"mb-3 mt-n5",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("time2_emote")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-n3",attrs:{"background-color":"#313338","solo":"","flat":"","dense":"","label":"time2_emote"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.time2_emote),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "time2_emote", $$v)},expression:"advancedSettings.time2_emote"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("The current emote used for this icon. To change the emote you can paste the ID, raw string or link of the emote here.")])]),_c('div',{staticClass:"mb-3 mt-n5",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("countdown1_emote")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-n3",attrs:{"background-color":"#313338","solo":"","flat":"","dense":"","label":"countdown1_emote"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.countdown1_emote),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "countdown1_emote", $$v)},expression:"advancedSettings.countdown1_emote"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("The current emote used for this icon. To change the emote you can paste the ID, raw string or link of the emote here.")])]),_c('div',{staticClass:"mb-3 mt-n5",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("countdown2_emote")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-n3",attrs:{"background-color":"#313338","solo":"","flat":"","dense":"","label":"countdown2_emote"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.countdown2_emote),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "countdown2_emote", $$v)},expression:"advancedSettings.countdown2_emote"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("The current emote used for this icon. To change the emote you can paste the ID, raw string or link of the emote here.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"mb-n7 mt-n4",attrs:{"color":"green","label":"defaults_pre_req"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.defaults_pre_req),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "defaults_pre_req", $$v)},expression:"advancedSettings.defaults_pre_req"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("defaults_pre_req")])])]}}])},[_c('span',[_vm._v("whether signing up with Late, Bench or Tentative requires the user to first sign up normally.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"my-n7",attrs:{"color":"green","label":"disable_archiving"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.disable_archiving),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "disable_archiving", $$v)},expression:"advancedSettings.disable_archiving"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("disable_archiving")])])]}}])},[_c('span',[_vm._v("Exclude this specific event from being archived.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"my-n7",attrs:{"color":"green","label":"allow_duplicate"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.allow_duplicate),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "allow_duplicate", $$v)},expression:"advancedSettings.allow_duplicate"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("allow_duplicate")])])]}}])},[_c('span',[_vm._v("Allow users multiple Sign-Ups to the same class.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"my-n7",attrs:{"color":"green","label":"bold_all"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.bold_all),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "bold_all", $$v)},expression:"advancedSettings.bold_all"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("bold_all")])])]}}])},[_c('span',[_vm._v("whether a users subsequent Sign-Ups will be primary (counts towards limits).")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"my-n7",attrs:{"color":"green","label":"show_title"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.show_title),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "show_title", $$v)},expression:"advancedSettings.show_title"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("show_title")])])]}}])},[_c('span',[_vm._v("Shows the title of the event.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"my-n7",attrs:{"color":"green","label":"show_info"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.show_info),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "show_info", $$v)},expression:"advancedSettings.show_info"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("show_info")])])]}}])},[_c('span',[_vm._v("Shows the event info. Contains date, time and Sign-Up count.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"my-n7",attrs:{"color":"green","label":"show_leader"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.show_leader),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "show_leader", $$v)},expression:"advancedSettings.show_leader"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("show_leader")])])]}}])},[_c('span',[_vm._v("Shows the event leader on the event.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"my-n7",attrs:{"color":"green","label":"mention_leader"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.mention_leader),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "mention_leader", $$v)},expression:"advancedSettings.mention_leader"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("mention_leader")])])]}}])},[_c('span',[_vm._v("whether the leaders name will be mentioned.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"my-n7 mt-n4",attrs:{"color":"green","label":"show_counter"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.show_counter),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "show_counter", $$v)},expression:"advancedSettings.show_counter"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("show_counter")])])]}}])},[_c('span',[_vm._v("Shows Sign-Ups counter on the event.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"my-n7",attrs:{"color":"green","label":"show_roles"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.show_roles),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "show_roles", $$v)},expression:"advancedSettings.show_roles"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("show_roles")])])]}}])},[_c('span',[_vm._v("Shows the role counters above the content.")])])],1),_c('v-col',{staticClass:"mt-5 mt-sm-0 mb-n2 mb-sm-0",attrs:{"cols":"6","sm":"4"}},[_c('div',{staticClass:"mb-3 mt-n3",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("deletion")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-n3",attrs:{"background-color":"#313338","solo":"","flat":"","dense":"","label":"deletion"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.deletion),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "deletion", $$v)},expression:"advancedSettings.deletion"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("Enter the deletion time in hours after the event.")])]),_c('div',{staticClass:"mb-3 mt-n5",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("attendance")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-n3",attrs:{"background-color":"#313338","solo":"","flat":"","dense":"","label":"attendance"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.attendance),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "attendance", $$v)},expression:"advancedSettings.attendance"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("Determines whether this event counts towards attendance.")])]),_c('div',{staticClass:"mb-3 mt-n5",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("date_variant")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-select',{staticClass:"my-n3",attrs:{"items":['local', 'zoned', 'both'],"item-value":"name","background-color":"#313338","solo":"","dense":"","flat":"","label":"date_variant","item-color":"green"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.date_variant),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "date_variant", $$v)},expression:"advancedSettings.date_variant"}})],1)]}}])},[_c('span',[_vm._v("Determines the display of the date and time.")])]),_c('div',{staticClass:"mb-3 mt-n5",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("opt_out")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-n3",attrs:{"background-color":"#313338","solo":"","flat":"","dense":"","label":"opt_out"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.opt_out),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "opt_out", $$v)},expression:"advancedSettings.opt_out"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("Enter the ID of an existing event to copy the Sign-Ups from on event creation.")])]),_c('div',{staticClass:"mb-3 mt-n5",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("response")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-n3",attrs:{"background-color":"#313338","solo":"","flat":"","dense":"","label":"response"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.response),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "response", $$v)},expression:"advancedSettings.response"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("The response text will be sent to members upon signing up for the event.")])]),_c('div',{staticClass:"mb-3 mt-n5",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("thumbnail")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-n3",attrs:{"background-color":"#313338","solo":"","flat":"","dense":"","label":"thumbnail"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.thumbnail),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "thumbnail", $$v)},expression:"advancedSettings.thumbnail"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("The event message will display the thumbnail in the top right. Not recommended due to formatting.")])]),_c('div',{staticClass:"mb-3 mt-n5",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("leader_emote")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-n3",attrs:{"background-color":"#313338","solo":"","flat":"","dense":"","label":"leader_emote"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.leader_emote),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "leader_emote", $$v)},expression:"advancedSettings.leader_emote"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("The current emote used for this icon. To change the emote you can paste the ID, raw string or link of the emote here.")])]),_c('div',{staticClass:"mb-3 mt-n5",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("late_emote")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-n3",attrs:{"background-color":"#313338","solo":"","flat":"","dense":"","label":"late_emote"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.late_emote),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "late_emote", $$v)},expression:"advancedSettings.late_emote"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("The current emote used for this role. You can disable it by setting it to 'remove'. To change the emote you can paste the ID, raw string or link of the emote here.")])]),_c('div',{staticClass:"mb-3 mt-n5",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("bench_emote")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-n3",attrs:{"background-color":"#313338","solo":"","flat":"","dense":"","label":"bench_emote"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.bench_emote),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "bench_emote", $$v)},expression:"advancedSettings.bench_emote"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("The current emote used for this role. You can disable it by setting it to 'remove'. To change the emote you can paste the ID, raw string or link of the emote here.")])]),_c('div',{staticClass:"mb-3 mt-n5",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("tentative_emote")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-n3",attrs:{"background-color":"#313338","solo":"","flat":"","dense":"","label":"tentative_emote"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.tentative_emote),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "tentative_emote", $$v)},expression:"advancedSettings.tentative_emote"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("The current emote used for this role. You can disable it by setting it to 'remove'. To change the emote you can paste the ID, raw string or link of the emote here.")])]),_c('div',{staticClass:"mb-3 mt-n5",staticStyle:{"font-size":".7em","color":"#e3e3e3","position":"relative"}},[_vm._v("absence_emote")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-n3",attrs:{"background-color":"#313338","solo":"","flat":"","dense":"","label":"absence_emote"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.absence_emote),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "absence_emote", $$v)},expression:"advancedSettings.absence_emote"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("The current emote used for this role. You can disable it by setting it to 'remove'. To change the emote you can paste the ID, raw string or link of the emote here.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"mb-n7 mt-n4",attrs:{"color":"green","label":"attendance"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.show_content),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "show_content", $$v)},expression:"advancedSettings.show_content"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("show_content")])])]}}])},[_c('span',[_vm._v("Shows the content. Contains the Sign-Ups.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"my-n7",attrs:{"color":"green","label":"show_classes"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.show_classes),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "show_classes", $$v)},expression:"advancedSettings.show_classes"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("show_classes")])])]}}])},[_c('span',[_vm._v("Show class fields even if they contain no Sign-Ups.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"my-n7",attrs:{"color":"green","label":"show_emotes"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.show_emotes),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "show_emotes", $$v)},expression:"advancedSettings.show_emotes"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("show_emotes")])])]}}])},[_c('span',[_vm._v("Shows the emotes in front of the Sign-Ups.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"my-n7",attrs:{"color":"green","label":"show_numbering"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.show_numbering),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "show_numbering", $$v)},expression:"advancedSettings.show_numbering"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("show_numbering")])])]}}])},[_c('span',[_vm._v("Shows the order numbers in front of Sign-Ups.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"my-n7",attrs:{"color":"green","label":"show_allowed"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.show_allowed),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "show_allowed", $$v)},expression:"advancedSettings.show_allowed"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("show_allowed")])])]}}])},[_c('span',[_vm._v("Shows the allowed roles on the event if any are set.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"my-n7",attrs:{"color":"green","label":"show_banned"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.show_banned),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "show_banned", $$v)},expression:"advancedSettings.show_banned"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("show_banned")])])]}}])},[_c('span',[_vm._v("Shows the banned roles on the event if any are set.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"my-n7",attrs:{"color":"green","label":"show_footer"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.show_footer),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "show_footer", $$v)},expression:"advancedSettings.show_footer"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("show_footer")])])]}}])},[_c('span',[_vm._v("Shows the event footer. Contains deadline info and more.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"my-n7",attrs:{"color":"green","label":"show_countdown"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.show_countdown),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "show_countdown", $$v)},expression:"advancedSettings.show_countdown"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("show_countdown")])])]}}])},[_c('span',[_vm._v("Shows a countdown to the event start time.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"my-n7",attrs:{"color":"green","label":"vacuum"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.vacuum),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "vacuum", $$v)},expression:"advancedSettings.vacuum"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("vacuum")])])]}}])},[_c('span',[_vm._v("Clears up to 20 non-event and non-pinned messages from the channel.")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"600","transition":"v-scale-transition","color":"#0f0f0f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"my-n7",attrs:{"color":"green","label":"pin_message"},on:{"change":function($event){return _vm.updateAdvanced()}},model:{value:(_vm.advancedSettings.pin_message),callback:function ($$v) {_vm.$set(_vm.advancedSettings, "pin_message", $$v)},expression:"advancedSettings.pin_message"}},on),[_c('span',_vm._g({staticStyle:{"font-size":"0.75em"},attrs:{"slot":"label"},slot:"label"},on),[_vm._v("pin_message")])])]}}])},[_c('span',[_vm._v("Pins the event on creation.")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }