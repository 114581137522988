<template>
<v-container class="pa-1">

              <DashNavi v-bind:title="'Scheduled Events'" v-bind:route="'/dashboard'" id="DashNavi"/>

          <v-row v-if="apiCalled && !$store.state.chosenDiscord.hasPerms" class="justify-center mt-n5 mt-sm-n10">
    <v-col cols="12">

    <v-alert v-if="!$store.state.chosenDiscord.hasPerms" prominent type="error">
      <v-row align="center">
        <v-col class="grow"
          >You don't have permission to view events in this server.</v-col
        >
        <v-col class="shrink">
          
          <v-btn elevation="0" to="/dashboard">Go back</v-btn>
        </v-col>
      </v-row>
    </v-alert>
    </v-col>
        </v-row>

    <v-row v-if="!loadingEvents && eventsEven" class="justify-center mt-sm-n11 mt-n6">
      <v-col cols="12">
        <v-card id="profile" :ripple="false" dark color="primary" class="mb-1" elevation="0" v-for="(item, i) in displayPage(eventsEven)" :key="i" @click="selectToEdit(item.channelId)" :style="'border-left: 5px solid rgb(' + item.color + ') !important;'">
          <v-card-actions>
         
              <v-col cols="3" sm="2" md="2" lg="2" xl="1" class="d-flex justify-center ml-n1">
                <v-img class="my-0" :src="'https://cdn.discordapp.com/emojis/' + item.emote + '.png'" max-height="30" max-width="30"></v-img>
              </v-col>
              <v-col>
                <v-row id="title">
          <v-col>
            <v-row class="mb-n3 ml-n2">
              <span class="pt-0" style="font-size: 1.5em;">
                {{ (item.displayTitle != undefined ? item.displayTitle : item.title) }}
              </span>
            </v-row>
          </v-col>
        </v-row>
              <v-container>
                  <v-row class="pt-2 ml-n6">

                    <span style="white-space: nowrap; font-size: 0.8em;" class="mt-3">
                    <span style='background-color: #181a1c; color: #dcd0d0; border: 2px solid #212327; border-right: 0px; border-top-left-radius: 4px; border-bottom-left-radius: 4px;' class='pl-1 pr-6 py-1'><v-img src="https://cdn.discordapp.com/emojis/799879945123135508.png" max-height="19" max-width="20" style="display:inline-block; position: absolute;"></v-img></span>
                    <span style='background-color: #292b2f; color: #adb1b6; border: 2px solid #212327; border-left: 0px; border-top-right-radius: 4px; border-bottom-right-radius: 4px;' class='px-2 py-1 mr-3'>{{ item.leader }}</span>
                    </span>

                    <span style="white-space: nowrap; font-size: 0.8em;" class="mt-3">
                    <span style='background-color: #181a1c; color: #dcd0d0; border: 2px solid #212327; border-right: 0px; border-top-left-radius: 4px; border-bottom-left-radius: 4px;' class='pl-1 pr-6 py-1'><v-img src="https://cdn.discordapp.com/emojis/592462264670617641.png" max-height="19" max-width="20" style="display:inline-block; position: absolute;"></v-img></span>
                    <span style='background-color: #292b2f; color: #adb1b6; border: 2px solid #212327; border-left: 0px; border-top-right-radius: 4px; border-bottom-right-radius: 4px;' class='px-2 py-1 mr-3'>{{ new Date(item.unixtime*1000).toLocaleDateString([],{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }}</span>
                    </span>

                    <span style="white-space: nowrap; font-size: 0.8em;" class="mt-3">
                    <span style='background-color: #181a1c; color: #dcd0d0; border: 2px solid #212327; border-right: 0px; border-top-left-radius: 4px; border-bottom-left-radius: 4px;' class='pl-1 pr-6 py-1'><v-img src="https://cdn.discordapp.com/emojis/592463749865275452.png" max-height="19" max-width="20" style="display:inline-block; position: absolute;"></v-img></span>
                    <span style='background-color: #292b2f; color: #adb1b6; border: 2px solid #212327; border-left: 0px; border-top-right-radius: 4px; border-bottom-right-radius: 4px;' class='px-2 py-1 mr-3'>{{ new Date(item.unixtime*1000).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) }}</span>
                    </span>

                    <span style="white-space: nowrap; font-size: 0.8em;" class="mt-3">
                    <span style='background-color: #181a1c; color: #dcd0d0; border: 2px solid #212327; border-right: 0px; border-top-left-radius: 4px; border-bottom-left-radius: 4px;' class='pl-1 pr-6 py-1'><v-img src="https://cdn.discordapp.com/emojis/799893852814049310.png" max-height="19" max-width="20" style="display:inline-block; position: absolute;"></v-img></span>
                    <span style='background-color: #292b2f; color: #adb1b6; border: 2px solid #212327; border-left: 0px; border-top-right-radius: 4px; border-bottom-right-radius: 4px;' class='px-2 py-1 mr-3'><img :src="'https://cdn.discordapp.com/emojis/' + getChannelIconByType(item.channelType) + '.png?v=1'" width="20" height="20" style="margin-bottom: -6px; margin-left: -5px; margin-right: 5px;" />{{ checkChannelDeleted(item.channelName) }}</span>
                    </span>

                  </v-row>
                  <v-row class="pt-1 ml-n6">

                    <span style="white-space: nowrap; font-size: 0.8em;" class="mt-2">
                    <span style='background-color: #181a1c; color: #dcd0d0; border: 2px solid #212327; border-right: 0px; border-top-left-radius: 4px; border-bottom-left-radius: 4px;' class='pl-1 pr-6 py-1'><v-img src="https://cdn.discordapp.com/emojis/753683174009339974.png" max-height="19" max-width="20" style="display:inline-block; position: absolute;"></v-img></span>
                    <span style='background-color: #292b2f; color: #adb1b6; border: 2px solid #212327; border-left: 0px; border-top-right-radius: 4px; border-bottom-right-radius: 4px;' class='px-2 py-1 mr-3'>{{ getIntervalString(item.interval, item.interval2) }}</span>
                    </span>

                  </v-row>
              </v-container>
              <v-row class="mt-3 ml-n2">
             <span style="font-weight: 300; color: grey; font-size: 0.8em;">This event will be posted on <span style="font-weight: 500;">{{ new Date(item.timestamp*1000).toLocaleDateString([],{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }} @ {{ new Date(item.timestamp*1000).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) }}.</span></span>
              </v-row>
              </v-col>
           
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="!loadingEvents && eventsEven.length === 0 && $store.state.chosenDiscord.hasPerms" class="justify-center" style="color: grey;">no events found</v-row>
          <v-snackbar elevation="0" color="red" v-model="ratelimitSnackbar" timeout="4500" ><span style="font-size:15px;">{{ ratelimitText }}</span>
          </v-snackbar>
    <v-overlay opacity="0" :value="loadingEvents && $store.state.chosenDiscord.hasPerms">
      <v-progress-circular
        indeterminate
        color="white"
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <div v-if="Math.ceil(eventsEven.length/10)>1 && !loadingEvents && $store.state.chosenDiscord.hasPerms" class="text-center mt-7" >
          <v-pagination
            dark
            active="white"
            v-model="page"
            :length="Math.ceil(eventsEven.length/10)"
            :total-visible="7"
          ></v-pagination>
        </div>
  </v-container>
</template>
<script>
import axios from "axios";
import Store from "../../store/index";
import DashNavi from "../../components/DashNavi";

export default {
  components: {
    DashNavi
  },
  data() {
    return {
      eventsEven: [],
      page: 1,
      loadingEvents: true,
      apiCalled: false,
      ratelimitSnackbar: false,
      ratelimitText: "",
      color: undefined,
    };
  },
  methods: {
    displayPage(events){
      let pageStart = this.page *10
      return events.slice(pageStart -10, pageStart)
    },  

        async getEvents() {
      if (!Store.state.chosenDiscord) {
        this.$router.push("/dashboard");
      } else {
        this.req = {
          serverid: Store.state.chosenDiscord.id,
          accessToken: localStorage.getItem('accessToken').slice(1,-1),
        };

        axios.post(Store.state.url + "/api/server/",this.req)
        .then(response => {

          Store.state.permissionLevel = response.data.permission;
          if (response.data.reason === "missing permission") {
            Store.state.chosenDiscord.hasPerms = false
            this.loadingEvents = false;
            this.apiCalled = true;
            return;
          } else {
            Store.state.chosenDiscord.hasPerms = true
            this.apiCalled = true;
            axios.post(Store.state.url + "/api/events/", this.req)
            .then(response => {
              const events = response.data.schedule;
              events.sort(function (a, b) {
                return b.unixtime - a.unixtime;
              });

              events.reverse();
              this.eventsEven = [];
              for (let index = 0; index < events.length; index++) {
                this.eventsEven.push(events[index]);
              }

              Store.state.scheduledCache = this.eventsEven;
              this.loadingEvents = false;

            }).catch(error => {
              if (error.response.status === 429) {
                this.ratelimitSnackbar = true;
                this.ratelimitText = error.response.data.reason;
                this.loadingEvents = false;
              }
            });
          } 
          }).catch(error => {
              if (error.response.status === 429) {
                this.ratelimitSnackbar = true;
                this.ratelimitText = error.response.data.reason;
                this.loadingEvents = false;
              } else if (error.response.status === 401) {
                window.location.href = this.$store.state.url + "/api/auth/discord";
            }
          }
        );
      }
    },

    getTitleAsEmotes(input, font=3) {
      if (input === 'unnamed') {
        this.titleEmote = false
      }
      let textEmotes = [];
      for (let i = 0; i < input.length; i++) {
        
        if (this.$store.state.textEmotes[0].includes(input[i].toUpperCase())) {
          
          textEmotes.push(
            this.$store.state.textEmotes[parseInt(font)].find(
              (x) => x.name === input[i].toUpperCase()
            ).id
          );
        } else {
          this.titleEmote = false
        }
      }
      this.titleEmote = true
      return textEmotes;
    },
    iconize(input) {
      if (input === "-") {
        return " ";
      } else {
        return "https://cdn.discordapp.com/emojis/" + input + ".png?v=1";
      }
    },

        getChannelIconByType(type) {
      if (type === "text") {
        return "1025776950096171148";
      } else if (type === "forum") {
        return "1025776945146904629";
      } else if (type === "thread") {
        return "1025779885127516170";
      } else if (type === "post") {
        return "1025779860838301810";
      } else if (type === "news") {
        return "1025776946698784861";
      } else if (type === "stage") {
        return "1025776948288426068";
      } else if (type === "dm") {
        return "1026094422015877171";
      } else {
        return "1025776951627100210";
      }
    },

    getIntervalString(interval, interval2) {
      let intervalText;

      if (interval.includes("minutes")) {
        let intervalNumber = interval.replaceAll("minutes", "").trim();
        if (!isNaN(intervalNumber) && parseFloat(intervalNumber) > 1) {
          intervalText = intervalNumber + " minutes";
        } else {
           intervalText = intervalNumber + " minute";
        }
      } else if (interval.includes("hours")) {
        let intervalNumber = interval.replaceAll("hours", "").trim();
        if (!isNaN(intervalNumber) && parseFloat(intervalNumber) > 1) {
            intervalText = intervalNumber + " hours";
        } else {
            intervalText = intervalNumber + " hour";
        }
      } else if (interval.includes("days")) {
        let intervalNumber = interval.replaceAll("days", "").trim();
        if (!isNaN(intervalNumber) && parseFloat(intervalNumber) > 1) {
          intervalText = intervalNumber + " days";
        } else {
           intervalText = intervalNumber + " day";
        }
      } else if (interval.includes("months")) {
        let intervalNumber = interval.replaceAll("months", "").trim();
        if (!isNaN(intervalNumber) && parseFloat(intervalNumber) > 1) {
          intervalText = intervalNumber + " months";
        } else {
          intervalText = intervalNumber + " month";
        }
      } else if (interval.includes("h") || interval.includes("H")) {
        intervalText = interval.replaceAll("h", "").replaceAll("H", "") + " hours";
      } else if (interval.includes("m") || interval.includes("M")) {
        intervalText = interval.replaceAll("m", "").replaceAll("M", "") + " minutes";
      } else {
        let intervalNumber = interval.replaceAll("D", "").replaceAll("d", "").trim();
        if (!isNaN(intervalNumber) && parseFloat(intervalNumber) > 1) {
          intervalText = intervalNumber + " days";
        } else {
           intervalText = intervalNumber + " day";
        }
      }

      if (interval2 != undefined && interval2 !== "0") {
        let [ordinal, weekday] = interval2.split("-");
        if (ordinal && weekday) {
          let capitalizedOrdinal = ordinal.charAt(0).toUpperCase() + ordinal.slice(1);
          let capitalizedWeekday = weekday.charAt(0).toUpperCase() + weekday.slice(1);
          intervalText = capitalizedOrdinal + " " + capitalizedWeekday + " of every month, posted " + intervalText + " in advance.";
        }
      }
      return intervalText;
    },

    async selectToEdit(raidId) {
      localStorage.setItem("selectedEventId", raidId);
      this.$store.state.selectedEventId = raidId;
      this.$router.push(
        "/event/" + this.$store.state.selectedEventId,
        () => {}
      );
    },

    truncate(input) {
      if (input.length > 15 && this.$vuetify.breakpoint.xs) {
        return input.substring(0, 15) + "..";
      }
      return input;
    },

    checkDateColor(input, item, i) {
      let now = new Date();
      let eventDate = new Date(item.date.split("-").reverse().join("-"));

      if (now - eventDate > 0) {
        return "#ff0000";
      }
      if (input && input !== "none") {
        return input;
      }
    },
    checkChannelDeleted(input) {
      if (input === "") {
        return "deleted channel";
      } else {
        return input;
      }
    },
  },
  created(){
    document.title = "Dashboard - Scheduled Events" 
  },
  mounted() {
    this.$store.state.selectedEvent = undefined
    this.$store.state.selectedEventId = undefined

    if (this.$store.state.scheduledCache !== undefined) {
      this.eventsEven = this.$store.state.scheduledCache;
      this.loadingEvents = false;
    }

    this.getEvents();
  },
};
</script>
<style >
.fixedPadding {
  padding-bottom: 6px !important;
  padding-top: 6px !important;
  padding-right: 6px !important;
  padding-left: 6px !important;
}

#profile {
  border: 1px solid transparent !important;
}

#profile:hover {
  background-color: #25252b !important;
}
</style>