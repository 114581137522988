<template>

<v-container fill-height fluid>
<span class="bg"></span>
<v-row>
    				     <v-col cols="12" class="mt-n4 mb-5 px-3" align="center">
      <v-btn-toggle dark tile>
        <v-btn elevation="0" tile min-width="130px" :ripple="false" color="red lighten-1" style="opacity: 1; border: none;" to="/documentation/intro">Intro</v-btn>
        <v-btn elevation="0" tile min-width="130px" :ripple="false" color="#202025" style="opacity: 0.8; border: none;" to="/documentation/commands">Commands</v-btn>
	  </v-btn-toggle>
          <v-btn-toggle dark tile>
        <v-btn elevation="0" tile min-width="130px" :ripple="false" color="#202025" style="opacity: 0.8; border: none;" to="/documentation/advanced">Advanced</v-btn>
        <v-btn elevation="0" tile min-width="130px" :ripple="false" color="#202025" style="opacity: 0.8; border: none;" to="/documentation/reference">Reference</v-btn>
	  </v-btn-toggle>
	  <v-btn-toggle dark tile>
        <v-btn elevation="0" tile min-width="130px" :ripple="false" color="#202025" style="opacity: 0.8; border: none;" to="/documentation/guides">Guides</v-btn>
        <v-btn elevation="0" tile min-width="130px" :ripple="false" color="#202025" style="opacity: 0.8; border: none;" to="/documentation/api">API</v-btn>
	  </v-btn-toggle>
    
    </v-col>
    <v-col class="mt-n6">
      <v-card elevation="10" dark width="100%" class="my-5">
        <v-card-title style="background-color: #1d1d21;" class="text-h6">
          Invite the bot
        </v-card-title>
        <v-card-text style="background-color: #38383e; color: white;" class="py-5 pb-2">
          <p class="text1"><v-btn elevation="0" x-small class="purple white--text px-2 mx-1" @click="goToBotInvite()">Click here</v-btn> to invite the bot to your server.</p>

        </v-card-text>
      </v-card>
      <v-card elevation="10" dark class="my-5">
        <v-card-title style="background-color: #1d1d21;" class="text-h6">
            <h4 id="permissions">Permissions<v-btn elevation="0" :ripple="false" x-small text plain @click="copyText('https://raid-helper.dev/documentation/intro#permissions')"><v-icon small color="grey" class="mb-1">mdi-link-variant</v-icon></v-btn></h4>

        </v-card-title>
        <v-card-text style="background-color: #38383e; color: white;" class="pt-7">
          <p class="text1">The bot will allow any member on your server with <span style="font-size:17px; color:#81c784;">ADMIN</span> or <span style="font-size:17px; color:#81c784;">MANAGE SERVER</span> permissions to freely use all commands and features. 
            To allow some members without those permissions access to the bot, you have the following options:</p>

    <v-list-item>
      <v-list-item-content>
          <p class="text1 pa-3" style="background-color: #303035; border-left: 3px solid purple;">Define a discord role to act as manager with the following command: <span style="font-size:17px; color:yellow;">/settings manager_role set</span><br>Members with that role will be able to <u>create and edit events</u> on your server.</p>

      </v-list-item-content>
    </v-list-item>
        <v-list-item>
      <v-list-item-content>
          <p class="text1 pa-3" style="background-color: #303035; border-left: 3px solid purple;">Define a discord role to act as assistant with the following command: <span style="font-size:17px; color:yellow;">/settings assistant_role set</span><br>Members with that role will be able to <u>create events</u> and <u>edit their own events</u> on your server.</p>
      </v-list-item-content>
    </v-list-item>

         
   <v-col cols="auto" align="center">
      <v-dialog transition="dialog-top-transition" max-width="600" overlay-opacity="0.8" >
        <template v-slot:activator="{ on, attrs }">
          <v-btn elevation="0" color="primary" v-bind="attrs" v-on="on"
          >Watch gif</v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card color="#1d1d21" @click="dialog.value = false">
 
            <v-img src="@/assets/intro1.gif"></v-img>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
    <br>
 
      <p class="text1"><v-btn elevation="0" x-small class="purple white--text px-2" to="/documentation/commands">Click here</v-btn> to visit the documentation if you want to learn about the permission requirements for each command. </p>
    </v-card-text>
      </v-card>

      <v-card dark elevation="10" class="my-5">
        <v-card-title style="background-color: #1d1d21;" class="text-h6">
            <h4 id="importantsettings">Important Settings<v-btn elevation="0" :ripple="false" x-small text plain @click="copyText('https://raid-helper.dev/documentation/intro#importantsettings')"><v-icon small color="grey" class="mb-1">mdi-link-variant</v-icon></v-btn></h4>

        </v-card-title>
        <v-card-text style="background-color: #38383e;" class=" py-5">
          <p class="text1">The bot is ready to go now, but you might want to adjust a few settings first. Raid-Helper events close for further Sign-Ups once the event time is reached (+/- a set deadline).
            For the time be accurate, <u>Raid-Helper needs to know the timezone in which you are entering date and time inputs</u> when creating events on your server.</p>
    <v-list-item>
      <v-list-item-content>
          <p class="text1 pa-3" style="background-color: #303035; border-left: 3px solid purple;">Set a timezone for your server with the following command: <span style="font-size:17px; color:yellow;">/settings timezone set</span></p>
      </v-list-item-content>
    </v-list-item>
     <v-col cols="auto" align="center">
      <v-dialog transition="dialog-top-transition" max-width="600" overlay-opacity="0.8" >
        <template v-slot:activator="{ on, attrs }">
          <v-btn elevation="0" color="primary" v-bind="attrs" v-on="on"
          >Watch gif</v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card color="#1d1d21" @click="dialog.value = false">
 
            <v-img src="@/assets/intro3.gif"></v-img>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
        </v-card-text>
      </v-card>

      <v-card dark elevation="10" class="my-5">
        <v-card-title style="background-color: #1d1d21;" class="text-h6">
            <h4 id="creatingevents">Creating Events<v-btn elevation="0" :ripple="false" x-small text plain @click="copyText('https://raid-helper.dev/documentation/intro#creatingevents')"><v-icon small color="grey" class="mb-1">mdi-link-variant</v-icon></v-btn></h4>

        </v-card-title>
        <v-card-text style="background-color: #38383e;" class=" py-5">
          <p class="text1">You are ready to create your first event!</p> 
          
              <v-list-item>
      <v-list-item-content>
          <p class="text1 pa-3" style="background-color: #303035; border-left: 3px solid purple;">Create events with the following command: <span style="font-size:17px; color:yellow;">/create</span><br>The bot will send you a DM, where the event creation will continue.</p>
      </v-list-item-content>
    </v-list-item>
                 <v-col cols="auto" align="center" class="mb-5">
      <v-dialog transition="dialog-top-transition" max-width="600" overlay-opacity="0.8" >
        <template v-slot:activator="{ on, attrs }">
          <v-btn elevation="0" color="primary" v-bind="attrs" v-on="on"
          >Watch gif</v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card color="#1d1d21" @click="dialog.value = false">
 
            <v-img src="@/assets/intro4.gif"></v-img>
          </v-card>
        </template>
      </v-dialog>
    </v-col>

          <p class="text1">You can use the above command to go through the DM dialogue but you can also create events in a single command.
           <v-btn elevation="0" x-small class="purple white--text px-2" to="/documentation/commands">Click here</v-btn> to visit the Raid-Helper command documentation where you can read up on the <span style="font-size:17px; color:yellow;">/quickcreate</span> command.
          </p>

          <p class="text1">Another alternative is the dashboard.
           <v-btn elevation="0" x-small class="purple white--text px-2" to="/dashboard">Click here</v-btn> to visit the Raid-Helper dashboard where you can create events through the Web UI.
          </p><br>
          <p class="text1" style="color: yellow; opacity: 0.8;">You know the basics for Raid-Helper now. Keep reading to learn more!
          </p>
        </v-card-text>
      </v-card>

      <v-card elevation="10" dark class="my-5">
        <v-card-title style="background-color: #1d1d21;" class="text-h6">
            <h4 id="editingevents">Editing Events<v-btn elevation="0" :ripple="false" x-small text plain @click="copyText('https://raid-helper.dev/documentation/intro#editingevents')"><v-icon small color="grey" class="mb-1">mdi-link-variant</v-icon></v-btn></h4>

        </v-card-title>
        <v-card-text style="background-color: #38383e;" class=" py-5">
          <p class="text1">You can edit posted events if you want to change a property.</p>
      <v-list-item-content>
          <p class="text1 pa-3" style="background-color: #303035; border-left: 3px solid purple;">Edit events with the following command: <span style="font-size:17px; color:yellow;">/edit</span><br>The bot will send you a DM with a list of upcoming events to edit.</p>
      </v-list-item-content>
            <v-list-item-content>
          <p class="text1 pa-3" style="background-color: #303035; border-left: 3px solid purple;">You can also edit an event by right-clicking it > Apps > Edit.</p>
      </v-list-item-content>
                       <v-col cols="auto" align="center" class="mb-5">
      <v-dialog transition="dialog-top-transition" max-width="600" overlay-opacity="0.8" >
        <template v-slot:activator="{ on, attrs }">
          <v-btn elevation="0" color="primary" v-bind="attrs" v-on="on"
          >Watch gif</v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card color="#1d1d21" @click="dialog.value = false">
 
            <v-img src="@/assets/intro5.gif"></v-img>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
        </v-card-text>
      </v-card>

      <v-card elevation="10" dark class="my-5">
        <v-card-title style="background-color: #1d1d21;" class="text-h6">
            <h4 id="advancedsettings">Advanced Settings<v-btn elevation="0" :ripple="false" x-small text plain @click="copyText('https://raid-helper.dev/documentation/intro#advancedsettings')"><v-icon small color="grey" class="mb-1">mdi-link-variant</v-icon></v-btn></h4>

        </v-card-title>
        <v-card-text style="background-color: #38383e;" class=" pt-7">
          <p class="text1">An important feature of the bot are the advanced settings. They contain most of the customization you can do around events. The advanced settings have 2 layers:</p>

    <v-list-item>
      <v-list-item-content>
          <p class="text1 pa-3" style="background-color: #303035; border-left: 3px solid purple;">The first layer are the servers advanced settings. They are applied to all events you manually create, so that you don't need to repeatedly change them on event creation. They are viewed and changed with the following command: <span style="font-size:17px; color:yellow;">/advanced set</span></p>
      </v-list-item-content>
    </v-list-item>
                               <v-col cols="auto" align="center" class="mb-5">
      <v-dialog transition="dialog-top-transition" max-width="600" overlay-opacity="0.8" >
        <template v-slot:activator="{ on, attrs }">
          <v-btn elevation="0" color="primary" v-bind="attrs" v-on="on"
          >Watch gif</v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card color="#1d1d21" @click="dialog.value = false">
 
            <v-img src="@/assets/intro7.gif"></v-img>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
        <v-list-item>
      <v-list-item-content>
          <p class="text1 pa-3" style="background-color: #303035; border-left: 3px solid purple;">The second layer are the advanced settings of each event. When you start the event creation, the event will inherit your servers advanced settings. 
            You can then adjust those settings on creation or editing for this specific event, without affecting other events.</p>
      </v-list-item-content>
    </v-list-item>
                           <v-col cols="auto" align="center" class="mb-5">
      <v-dialog transition="dialog-top-transition" max-width="600" overlay-opacity="0.8" >
        <template v-slot:activator="{ on, attrs }">
          <v-btn elevation="0" color="primary" v-bind="attrs" v-on="on"
          >Watch gif</v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card color="#1d1d21" @click="dialog.value = false">
 
            <v-img src="@/assets/intro6.gif"></v-img>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
        </v-card-text>
      </v-card>

      <v-card dark elevation="10" class="my-5">
        <v-card-title style="background-color: #1d1d21;" class="text-h6">
            <h4 id="raiderroles">Raider Roles<v-btn elevation="0" :ripple="false" x-small text plain @click="copyText('https://raid-helper.dev/documentation/intro#raiderroles')"><v-icon small color="grey" class="mb-1">mdi-link-variant</v-icon></v-btn></h4>

        </v-card-title>
        <v-card-text style="background-color: #38383e;" class=" py-5">
          <p class="text1">The raider roles are necessary if you want to query or message all members that did not sign up to an event. 
            The raider roles serve to populate the member pool with all members that have one of the set raider roles. </p>
    <v-list-item>
      <v-list-item-content>
              <p class="text1 pa-3" style="background-color: #303035; border-left: 3px solid purple;">You can define raider roles with the following command: <span style="font-size:17px; color:yellow;">/settings raider_roles set</span>
              <br>You can then use the <span style="font-size:17px; color:yellow;">/unsigned</span> command or query the list via right-click > Apps > Unsigned.</p>
      </v-list-item-content>
    </v-list-item>
                               <v-col cols="auto" align="center" class="mb-5">
      <v-dialog transition="dialog-top-transition" max-width="600" overlay-opacity="0.8" >
        <template v-slot:activator="{ on, attrs }">
          <v-btn elevation="0" color="primary" v-bind="attrs" v-on="on"
          >Watch gif</v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card color="#1d1d21" @click="dialog.value = false">
 
            <v-img src="@/assets/intro8.gif"></v-img>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
        </v-card-text>
      </v-card>

      <v-card dark elevation="10" class="my-5">
        <v-card-title style="background-color: #1d1d21;" class="text-h6">
            <h4 id="scheduledevents">Scheduled Events<v-btn elevation="0" :ripple="false" x-small text plain @click="copyText('https://raid-helper.dev/documentation/intro#scheduledevents')"><v-icon small color="grey" class="mb-1">mdi-link-variant</v-icon></v-btn></h4>
        </v-card-title>
        <v-card-text style="background-color: #38383e;" class=" py-5">
          <p class="text1">If you want the same event to be posted weekly (or any other interval), you can save yourself the trouble of manually creating each event by making a scheduled event which will repeatedly post in the provided interval.
            <br><a href="/premium/" style="text-decoration: none;"><span style="color:skyblue;">This is a premium feature.</span></a></p>
    <v-list-item>
      <v-list-item-content>
              <p class="text1 pa-3" style="background-color: #303035; border-left: 3px solid purple;">Create a normal event but pick <span style="font-size:17px; color:yellow;">Recurring</span>
              from the list of optional settings at the end. The bot will ask you to provide the interval in days in which the event will keep reposting.</p>
      </v-list-item-content>
    </v-list-item>
              <p class="text1">If you defined the interval to be 7 days for example, the bot will post the same event 7 days after the initial creation and add 7 days to the event date. You can change the creation time at will, either by editing the recurrence and changing the post time or by scheduling the event before creation.</p>
                 <v-list-item>
              <v-list-item-content>
              <p class="text1 pa-3" style="background-color: #303035; border-left: 3px solid purple;">You can schedule an event by picking <span style="font-size:17px; color:yellow;">Post Time</span> from the list of options on creation. This will cause the event to be posted at the provided date and time instead of immediately.</p>
      </v-list-item-content>
    </v-list-item>
                                   <v-col cols="auto" align="center" class="mb-5">
      <v-dialog transition="dialog-top-transition" max-width="600" overlay-opacity="0.8" >
        <template v-slot:activator="{ on, attrs }">
          <v-btn elevation="0" color="primary" v-bind="attrs" v-on="on"
          >Watch gif</v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card color="#1d1d21" @click="dialog.value = false">
 
            <v-img src="@/assets/intro9.gif"></v-img>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
                  <p class="text1">Editing a scheduled event is a bit different than editing an already existing event. A scheduled event exists in the background and does not change at all if you just edit an already posted event.</p>
                     <v-list-item>
              <v-list-item-content>
              <p class="text1 pa-3" style="background-color: #303035; border-left: 3px solid purple;">
                To edit a scheduled event and have your changes affect its future events you can use the /edit command and enter <u>scheduled</u> into the id field. The bot will provide you with a list of your scheduled events to choose from.
                Once you selected a scheduled event, the bot will DM you with the editing options.
              </p>
      </v-list-item-content>
    </v-list-item>
                                       <v-col cols="auto" align="center" class="mb-5">
      <v-dialog transition="dialog-top-transition" max-width="600" overlay-opacity="0.8" >
        <template v-slot:default="dialog">
          <v-card color="#1d1d21" @click="dialog.value = false">
            <v-img src="@/assets/intro10.gif"></v-img>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
        </v-card-text>
      </v-card>
    </v-col>
</v-row>
        		<v-snackbar elevation="0" color="green" v-model="snackbar" timeout="1000" ><span style="font-size:15px;">Copied to clipboard!</span></v-snackbar>

</v-container>
</template>
<script>

export default {
      data() {
    return {
                isMobile2: false,
                continue1: false,
                snackbar: false,
    }
  },

    beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },

        methods: {
        goToDiscordRedirect() {
            window.location.href = this.$store.state.url + "/api/auth/discord";
			},
                
        copyText(value) {
      navigator.clipboard.writeText(value);
      this.snackbar = true;
    },
        onResize() {
      this.isMobile2 = window.innerWidth;
    },
        goToBotInvite() {
      window.open(
        "https://discord.com/oauth2/authorize?client_id=579155972115660803&permissions=403995880640&scope=bot%20applications.commands",
        "_blank"
      );
    },

    },

    mounted() {
      var element = this.$refs[this.$route.hash.replace("#", "")];
      if (element !== undefined && element.offsetTop !== undefined) {
      window.scrollTo(0, element.offsetTop);
      }
  },
  created(){
    document.title = "Raid-Helper - Intro"
  },
}
</script>
<style >
.text1 {
  font-size: 17px;
  font-weight: 300;
}

</style>