<template>
	<v-container justify="center">
		<v-layout row fluid>
			<v-flex xs0 sm1 md1 lg1 xl3> </v-flex>
			<v-flex xs12 sm10 md10 lg10 xl6 class="my-12" :class="`rounded-lg`">
				<Introduction />
			</v-flex>
			<v-flex xs0 sm1 md1 lg1 xl3> </v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import Introduction from '../components/Intro.vue'

	export default {
		name: "Intro",
		components: {Introduction},
	};
</script>
