<template>

                <v-row class="mx-0 ml-n1 mb-n3 mt-3">
                   
                    <v-col cols="6" sm="4" class="mb-n7 mb-sm-0">
                      <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n3">deadline</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><v-text-field @change="updateAdvanced()" v-bind="attrs" v-on="on" v-model="advancedSettings.deadline" class="my-n3"  background-color="#313338" solo flat dense label="deadline"></v-text-field></template><span>The deadline is the closing time of the event in hours before the events time.</span></v-tooltip>

                        <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n5">duration</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><v-text-field @change="updateAdvanced()" v-bind="attrs" v-on="on" v-model="advancedSettings.duration" class="my-n3"  background-color="#313338" solo flat dense label="duration"></v-text-field></template><span>The duration of the event in minutes.</span></v-tooltip>
                        
                        <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n5">font_style</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><div v-bind="attrs" v-on="on" ><v-select @change="updateAdvanced()" type="number" :items="items" item-value="name" v-model="advancedSettings.font_style" class="my-n3"  background-color="#313338" solo dense flat label="font_style" item-color="green">
                          <template v-slot:item="{ item }">
                             <img v-if="item.name != 'none'" width="20px" class="mx-1" :src="item.image">
                             <div v-else>{{ item.name }}</div>
                          </template>

                          <template v-slot:selection="{ item }">
                            <img width="20px" class="mx-1 mt-1" :src="item.image"></template>
                          </v-select></div></template><span>The event titles font style.</span>
                        </v-tooltip>

                      <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n5">preserve_order</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><div v-bind="attrs" v-on="on"><v-select @change="updateAdvanced()" type="number" :items="['normal', 'always', 'never']" item-value="name" v-model="advancedSettings.preserve_order" class="my-n3"  background-color="#313338" solo dense flat label="preserve_order" item-color="green">
                          </v-select></div></template><span>whether the order number will be retained on Sign-Up change.</span>
                        </v-tooltip>
                             <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n5">event_type</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><div v-bind="attrs" v-on="on"><v-select @change="updateAdvanced()" type="number" :items="['interaction', 'reaction']" item-value="name" v-model="advancedSettings.event_type" class="my-n3"  background-color="#313338" solo dense flat label="event_type" item-color="green">
                          </v-select></div></template><span>whether the event will use reactions or interactions.</span>
                        </v-tooltip>

                        <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n5">forum_tags</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><v-text-field @change="updateAdvanced()" v-bind="attrs" v-on="on" v-model="advancedSettings.forum_tags" class="my-n3"  background-color="#313338" solo flat dense label="forum_tags"></v-text-field></template><span>Set the tags to apply to this post.</span></v-tooltip>
                               
      
                        <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n5">signups1_emote</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><v-text-field @change="updateAdvanced()" v-bind="attrs" v-on="on" v-model="advancedSettings.signups1_emote" class="my-n3"  background-color="#313338" solo flat dense label="signups1_emote"></v-text-field></template><span>The current emote used for this icon. To change the emote you can paste the ID, raw string or link of the emote here.</span></v-tooltip>
                        <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n5">signups2_emote</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><v-text-field @change="updateAdvanced()" v-bind="attrs" v-on="on" v-model="advancedSettings.signups2_emote" class="my-n3"  background-color="#313338" solo flat dense label="signups2_emote"></v-text-field></template><span>The current emote used for this icon. To change the emote you can paste the ID, raw string or link of the emote here.</span></v-tooltip>
            
                        <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n5">date1_emote</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><v-text-field @change="updateAdvanced()" v-bind="attrs" v-on="on" v-model="advancedSettings.date1_emote" class="my-n3"  background-color="#313338" solo flat dense label="date1_emote"></v-text-field></template><span>The current emote used for this icon. To change the emote you can paste the ID, raw string or link of the emote here.</span></v-tooltip>
                        <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n5">date2_emote</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><v-text-field @change="updateAdvanced()" v-bind="attrs" v-on="on" v-model="advancedSettings.date2_emote" class="my-n3"  background-color="#313338" solo flat dense label="date2_emote"></v-text-field></template><span>The current emote used for this icon. To change the emote you can paste the ID, raw string or link of the emote here.</span></v-tooltip>
                             
    
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.lock_at_limit" class="mb-n7 mt-n4" color="green" label="lock_at_limit"><span slot="label" style="font-size: 0.75em;" v-on="on">lock_at_limit</span></v-checkbox></template><span>whether the set limit is a hard limit or only visual.</span></v-tooltip>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.horizontal_mode" class="my-n7" color="green" label="horizontal_mode"><span slot="label" style="font-size: 0.75em;" v-on="on">horizontal_mode</span></v-checkbox></template><span>Display the content horizontally instead of vertically.</span></v-tooltip>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()"  v-on="on" v-model="advancedSettings.bench_overflow" class="my-n7" color="green" label="bench_overflow"><span slot="label" style="font-size: 0.75em;" v-on="on">bench_overflow</span></v-checkbox></template><span>Sign-Ups above a limit will be pushed to the bench.</span></v-tooltip>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.queue_bench" class="my-n7" color="green" label="queue_bench"><span slot="label" style="font-size: 0.75em;" v-on="on">queue_bench</span></v-checkbox></template><span>Sign-Ups above a limit will be queued instead of fully benched.</span></v-tooltip>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.mention_mode" class="my-n7" color="green" label="mention_mode"><span slot="label" style="font-size: 0.75em;" v-on="on">mention_mode</span></v-checkbox></template><span>Displays mentions instead of just the members names.</span></v-tooltip>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.spec_saving" class="my-n7" color="green" label="spec_saving"><span slot="label" style="font-size: 0.75em;" v-on="on">spec_saving</span></v-checkbox></template><span>The bot will remember the spec and apply it automatically next time.</span></v-tooltip>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.alt_names" class="my-n7" color="green" label="alt_names"><span slot="label" style="font-size: 0.75em;" v-on="on">alt_names</span></v-checkbox></template><span>Sign-Ups will be given the option to enter an alternative name.</span></v-tooltip>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.apply_unregister" class="my-n7" color="green" label="apply_unregister"><span slot="label" style="font-size: 0.75em;" v-on="on">apply_unregister</span></v-checkbox></template><span>Apply a reaction that will allow users to remove themselves from the event.</span></v-tooltip>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.apply_specreset" class="my-n7" color="green" label="apply_specreset"><span slot="label" style="font-size: 0.75em;" v-on="on">apply_specreset</span></v-checkbox></template><span>Apply a reaction that will allow users to reset all their saved specs for this template.</span></v-tooltip>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.show_on_overview" class="my-n7" color="green" label="show_on_overview"><span slot="label" style="font-size: 0.75em;" v-on="on">show_on_overview</span></v-checkbox></template><span>whether this event will be displayed on the /overview.</span></v-tooltip>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.create_discordevent" class="my-n7" color="green" label="create_discordevent"><span slot="label" style="font-size: 0.75em;" v-on="on">create_discordevent</span></v-checkbox></template><span>Create a discord event on top of the Raid-Helper event. Premium feature.</span></v-tooltip>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.only_discordevent" class="my-n7" color="green" label="create_discordevent"><span slot="label" style="font-size: 0.75em;" v-on="on">only_discordevent</span></v-checkbox></template><span>Create only a discord event without the Raid-Helper event. Premium feature.</span></v-tooltip>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.delete_thread" class="mb-n7 mt-n4" color="green" label="delete_thread"><span slot="label" style="font-size: 0.75em;" v-on="on">delete_thread</span></v-checkbox></template><span>Determines whether the attached thread or post of the event will be deleted or just archived.</span></v-tooltip>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.use_nicknames" class="mb-n7 mt-n4" color="green" label="use_nicknames"><span slot="label" style="font-size: 0.75em;" v-on="on">use_nicknames</span></v-checkbox></template><span>Determines whether the server nicknames are used for the Sign-Up or the global names.</span></v-tooltip>


                    </v-col>

                    <v-col cols="6" sm="4" class="mb-n7 mb-sm-0">
                        <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n3">limit</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><v-text-field @change="updateAdvanced()" type="number" v-bind="attrs" v-on="on" v-model="advancedSettings.limit" class="my-n3"  background-color="#313338" solo dense flat label="limit"></v-text-field></template><span>The displayed overall Sign-Up limit for this event.</span></v-tooltip>
                        <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n5">lower_limit</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><v-text-field @change="updateAdvanced()" type="number" v-bind="attrs" v-on="on" v-model="advancedSettings.lower_limit" class="my-n3"  background-color="#313338" solo dense flat label="lower_limit"></v-text-field></template><span>The minimum amount of Sign-Ups required for this event to happen.</span></v-tooltip>
                        <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n5">limit_per_user</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><v-text-field @change="updateAdvanced()" type="number" v-bind="attrs" v-on="on" v-model="advancedSettings.limit_per_user" class="my-n3"  background-color="#313338" solo dense flat label="limit_per_user"></v-text-field></template><span>The maximum amount of Sign-Ups per user for this event.</span></v-tooltip>
                      
                        <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n5">force_reminders</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><v-text-field @change="updateAdvanced()" v-bind="attrs" v-on="on" v-model="advancedSettings.force_reminders" class="my-n3"  background-color="#313338" solo flat dense label="force_reminders"></v-text-field></template><span>Causes a reminder to be sent to users, regardless of their setting.</span></v-tooltip>
                       
                        <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n5">create_thread</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><v-text-field @change="updateAdvanced()" v-bind="attrs" v-on="on" v-model="advancedSettings.create_thread" class="my-n3"  background-color="#313338" solo flat dense label="create_thread"></v-text-field></template><span>Creates a thread with the event.</span></v-tooltip>
                                              
                        <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n5">reminder</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><v-text-field @change="updateAdvanced()" v-bind="attrs" v-on="on" v-model="advancedSettings.reminder" class="my-n3"  background-color="#313338" solo flat dense label="reminder"></v-text-field></template><span>Sends a reminder into event channel. Enter the time before the event in minutes.</span></v-tooltip>
                        
                        <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n5">info_variant</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><div v-bind="attrs" v-on="on"><v-select @change="updateAdvanced()" :items="['short', 'long']" item-value="name" v-model="advancedSettings.info_variant" class="my-n3"  background-color="#313338" solo dense flat label="info_variant" item-color="green">
                          </v-select></div></template><span>Determines the formatting of the info field.</span>
                        </v-tooltip>

                                          <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n5">time1_emote</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><v-text-field @change="updateAdvanced()" v-bind="attrs" v-on="on" v-model="advancedSettings.time1_emote" class="my-n3"  background-color="#313338" solo flat dense label="time1_emote"></v-text-field></template><span>The current emote used for this icon. To change the emote you can paste the ID, raw string or link of the emote here.</span></v-tooltip>
                        <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n5">time2_emote</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><v-text-field @change="updateAdvanced()" v-bind="attrs" v-on="on" v-model="advancedSettings.time2_emote" class="my-n3"  background-color="#313338" solo flat dense label="time2_emote"></v-text-field></template><span>The current emote used for this icon. To change the emote you can paste the ID, raw string or link of the emote here.</span></v-tooltip>
                      
                        <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n5">countdown1_emote</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><v-text-field @change="updateAdvanced()" v-bind="attrs" v-on="on" v-model="advancedSettings.countdown1_emote" class="my-n3"  background-color="#313338" solo flat dense label="countdown1_emote"></v-text-field></template><span>The current emote used for this icon. To change the emote you can paste the ID, raw string or link of the emote here.</span></v-tooltip>
                        <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n5">countdown2_emote</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><v-text-field @change="updateAdvanced()" v-bind="attrs" v-on="on" v-model="advancedSettings.countdown2_emote" class="my-n3"  background-color="#313338" solo flat dense label="countdown2_emote"></v-text-field></template><span>The current emote used for this icon. To change the emote you can paste the ID, raw string or link of the emote here.</span></v-tooltip>
                      
                      
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.defaults_pre_req" class="mb-n7 mt-n4" color="green" label="defaults_pre_req"><span slot="label" style="font-size: 0.75em;" v-on="on">defaults_pre_req</span></v-checkbox></template><span>whether signing up with Late, Bench or Tentative requires the user to first sign up normally.</span></v-tooltip>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.disable_archiving" class="my-n7" color="green" label="disable_archiving"><span slot="label" style="font-size: 0.75em;" v-on="on">disable_archiving</span></v-checkbox></template><span>Exclude this specific event from being archived.</span></v-tooltip>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.allow_duplicate" class="my-n7" color="green" label="allow_duplicate"><span slot="label" style="font-size: 0.75em;" v-on="on">allow_duplicate</span></v-checkbox></template><span>Allow users multiple Sign-Ups to the same class.</span></v-tooltip>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.bold_all" class="my-n7" color="green" label="bold_all"><span slot="label" style="font-size: 0.75em;" v-on="on">bold_all</span></v-checkbox></template><span>whether a users subsequent Sign-Ups will be primary (counts towards limits).</span></v-tooltip>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.show_title" class="my-n7" color="green" label="show_title"><span slot="label" style="font-size: 0.75em;" v-on="on">show_title</span></v-checkbox></template><span>Shows the title of the event.</span></v-tooltip>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.show_info" class="my-n7" color="green" label="show_info"><span slot="label" style="font-size: 0.75em;" v-on="on">show_info</span></v-checkbox></template><span>Shows the event info. Contains date, time and Sign-Up count.</span></v-tooltip>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.show_leader" class="my-n7" color="green" label="show_leader"><span slot="label" style="font-size: 0.75em;" v-on="on">show_leader</span></v-checkbox></template><span>Shows the event leader on the event.</span></v-tooltip>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.mention_leader" class="my-n7" color="green" label="mention_leader"><span slot="label" style="font-size: 0.75em;" v-on="on">mention_leader</span></v-checkbox></template><span>whether the leaders name will be mentioned.</span></v-tooltip>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.show_counter" class="my-n7 mt-n4" color="green" label="show_counter"><span slot="label" style="font-size: 0.75em;" v-on="on">show_counter</span></v-checkbox></template><span>Shows Sign-Ups counter on the event.</span></v-tooltip>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.show_roles" class="my-n7" color="green" label="show_roles"><span slot="label" style="font-size: 0.75em;" v-on="on">show_roles</span></v-checkbox></template><span>Shows the role counters above the content.</span></v-tooltip>

                    </v-col>
                   
                    <v-col cols="6" sm="4" class="mt-5 mt-sm-0 mb-n2 mb-sm-0">
                        <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n3">deletion</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><v-text-field @change="updateAdvanced()" v-bind="attrs" v-on="on" v-model="advancedSettings.deletion" class="my-n3" background-color="#313338" solo flat dense label="deletion"></v-text-field></template><span>Enter the deletion time in hours after the event.</span></v-tooltip>
                        <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n5">attendance</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><v-text-field @change="updateAdvanced()" v-bind="attrs" v-on="on" v-model="advancedSettings.attendance" class="my-n3" background-color="#313338" solo flat dense label="attendance"></v-text-field></template><span>Determines whether this event counts towards attendance.</span></v-tooltip>
                        


                        <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n5">date_variant</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><div v-bind="attrs" v-on="on"><v-select @change="updateAdvanced()" :items="['local', 'zoned', 'both']" item-value="name" v-model="advancedSettings.date_variant" class="my-n3"  background-color="#313338" solo dense flat label="date_variant" item-color="green">
                          </v-select></div></template><span>Determines the display of the date and time.</span>
                        </v-tooltip>

                        <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n5">opt_out</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><v-text-field @change="updateAdvanced()" v-bind="attrs" v-on="on" v-model="advancedSettings.opt_out" class="my-n3" background-color="#313338" solo flat dense label="opt_out"></v-text-field></template><span>Enter the ID of an existing event to copy the Sign-Ups from on event creation.</span></v-tooltip>
                        
                        <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n5">response</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><v-text-field @change="updateAdvanced()" v-bind="attrs" v-on="on" v-model="advancedSettings.response" class="my-n3" background-color="#313338" solo flat dense label="response"></v-text-field></template><span>The response text will be sent to members upon signing up for the event.</span></v-tooltip>
                        
                        <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n5">thumbnail</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><v-text-field @change="updateAdvanced()" v-bind="attrs" v-on="on" v-model="advancedSettings.thumbnail" class="my-n3"  background-color="#313338" solo flat dense label="thumbnail"></v-text-field></template><span>The event message will display the thumbnail in the top right. Not recommended due to formatting.</span></v-tooltip>
                        

                        <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n5">leader_emote</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><v-text-field @change="updateAdvanced()" v-bind="attrs" v-on="on" v-model="advancedSettings.leader_emote" class="my-n3"  background-color="#313338" solo flat dense label="leader_emote"></v-text-field></template><span>The current emote used for this icon. To change the emote you can paste the ID, raw string or link of the emote here.</span></v-tooltip>
                        
                        <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n5">late_emote</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><v-text-field @change="updateAdvanced()" v-bind="attrs" v-on="on" v-model="advancedSettings.late_emote" class="my-n3"  background-color="#313338" solo flat dense label="late_emote"></v-text-field></template><span>The current emote used for this role. You can disable it by setting it to 'remove'. To change the emote you can paste the ID, raw string or link of the emote here.</span></v-tooltip>
                           <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n5">bench_emote</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><v-text-field @change="updateAdvanced()" v-bind="attrs" v-on="on" v-model="advancedSettings.bench_emote" class="my-n3"  background-color="#313338" solo flat dense label="bench_emote"></v-text-field></template><span>The current emote used for this role. You can disable it by setting it to 'remove'. To change the emote you can paste the ID, raw string or link of the emote here.</span></v-tooltip>
                       
                       
                        <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n5">tentative_emote</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><v-text-field @change="updateAdvanced()" v-bind="attrs" v-on="on" v-model="advancedSettings.tentative_emote" class="my-n3"  background-color="#313338" solo flat dense label="tentative_emote"></v-text-field></template><span>The current emote used for this role. You can disable it by setting it to 'remove'. To change the emote you can paste the ID, raw string or link of the emote here.</span></v-tooltip>
                        <div style="font-size: .7em; color: #e3e3e3; position: relative;" class="mb-3 mt-n5">absence_emote</div>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on, attrs }"><v-text-field @change="updateAdvanced()" v-bind="attrs" v-on="on" v-model="advancedSettings.absence_emote" class="my-n3"  background-color="#313338" solo flat dense label="absence_emote"></v-text-field></template><span>The current emote used for this role. You can disable it by setting it to 'remove'. To change the emote you can paste the ID, raw string or link of the emote here.</span></v-tooltip>
                      
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.show_content" class="mb-n7 mt-n4" color="green" label="attendance"><span slot="label" style="font-size: 0.75em;" v-on="on">show_content</span></v-checkbox></template><span>Shows the content. Contains the Sign-Ups.</span></v-tooltip>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.show_classes" class="my-n7" color="green" label="show_classes"><span slot="label" style="font-size: 0.75em;" v-on="on">show_classes</span></v-checkbox></template><span>Show class fields even if they contain no Sign-Ups.</span></v-tooltip>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.show_emotes" class="my-n7" color="green" label="show_emotes"><span slot="label" style="font-size: 0.75em;" v-on="on">show_emotes</span></v-checkbox></template><span>Shows the emotes in front of the Sign-Ups.</span></v-tooltip>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.show_numbering" class="my-n7" color="green" label="show_numbering"><span slot="label" style="font-size: 0.75em;" v-on="on">show_numbering</span></v-checkbox></template><span>Shows the order numbers in front of Sign-Ups.</span></v-tooltip>
                        
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.show_allowed" class="my-n7" color="green" label="show_allowed"><span slot="label" style="font-size: 0.75em;" v-on="on">show_allowed</span></v-checkbox></template><span>Shows the allowed roles on the event if any are set.</span></v-tooltip>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.show_banned" class="my-n7" color="green" label="show_banned"><span slot="label" style="font-size: 0.75em;" v-on="on">show_banned</span></v-checkbox></template><span>Shows the banned roles on the event if any are set.</span></v-tooltip>

                        
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.show_footer" class="my-n7" color="green" label="show_footer"><span slot="label" style="font-size: 0.75em;" v-on="on">show_footer</span></v-checkbox></template><span>Shows the event footer. Contains deadline info and more.</span></v-tooltip>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.show_countdown" class="my-n7" color="green" label="show_countdown"><span slot="label" style="font-size: 0.75em;" v-on="on">show_countdown</span></v-checkbox></template><span>Shows a countdown to the event start time.</span></v-tooltip>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.vacuum" class="my-n7" color="green" label="vacuum"><span slot="label" style="font-size: 0.75em;" v-on="on">vacuum</span></v-checkbox></template><span>Clears up to 20 non-event and non-pinned messages from the channel.</span></v-tooltip>
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-checkbox @change="updateAdvanced()" v-on="on" v-model="advancedSettings.pin_message" class="my-n7" color="green" label="pin_message"><span slot="label" style="font-size: 0.75em;" v-on="on">pin_message</span></v-checkbox></template><span>Pins the event on creation.</span></v-tooltip>
                          </v-col>
                </v-row>
</template>
<script>
export default {
    props: {
  	    adv: {
            type: Object,
  	    },
        isRec: {
            type: String,
  	    },
    },

    data() {
        return {
            advancedSettings: {},
            overlay: false,
            isRecurring: 'full',
                items: [
      { name: 'none', image: ''},
      { name: '1', image: 'https://cdn.discordapp.com/emojis/587930572094046218.png'},
      { name: '2', image: 'https://cdn.discordapp.com/emojis/675578438693945354.png'},
      { name: '3', image: 'https://cdn.discordapp.com/emojis/675578305008762880.png'}],
        }
    },

    mounted() {
        this.advancedSettings = JSON.parse(JSON.stringify(this.adv));  
        this.isRecurring = this.isRec;
        this.updateAdvanced();
    },

    methods: {

      updateAdvanced() {
        this.$emit('advancedSettings', this.advancedSettings);  
      },

    isMobile() {
      return window.innerWidth < 800;
    },
  }
};
</script>
<style>
</style>