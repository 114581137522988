<template>
  <div>

	  <v-card color="transparent" elevation="0" class="text-center pb-10 mt-n5">
			<span style="color: #e1dada;"><h1 style="font-size: 2em;" class="mb-5"><v-icon large color="#e1dada" class="mb-2">mdi-crown-outline</v-icon>Premium</h1>Premium supports the maintenance and development of Raid-Helper. To make it worth your time, we offer a few advanced premium features for your server!</span>
	 <v-divider dark style="color:yellow;" class="my-5"></v-divider>

	  </v-card>
	  
	
		<v-row justify="center">

			  	  <v-col cols="11" sm="6" md="5" lg="3">
	  <v-card elevation="10" class="mx-1 rh3" color="primary lighten-2" height="750" style="border: 2px solid #99aab5 !important; border-radius: 0 !important;">
		    <v-card-title class="justify-center" style="background-color:#99aab5; color:white; font-size: 1.5em; font-weight: bold;">Premium 3</v-card-title>
			<v-card-subtitle class="text-center" style="background-color:#99aab5; color:white;">for 3 months and 1 server</v-card-subtitle>
			<v-card-text class="text-center pt-8 mb-n5" style="color:#99aab5; font-size: 4em; font-weight: bold;">10€</v-card-text>
			<v-card-text class="text-center" style="color:#99aab5;">3,33€ / month</v-card-text>
			<v-card-text class="text-center" style="color:#99aab5;"> 
				<v-list rounded dense color="transparent" class="mx-5 mt-n5">


<v-list-item class="my-n1 mx-n5" @click="dialogue1 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-calendar</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Discord Events</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue2 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-shield-account</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Temporary Role</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue3 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-wrench</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Custom Templates</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue4 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-calendar-refresh</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Recurring Events</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue5 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-mirror</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Mirrored Events</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue6 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-folder-edit</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Event Logging</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue7 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-archive</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Archiving</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue8 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-reply-circle</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Event Response</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue9 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-view-list</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Live Overview</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue10 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-alarm</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Reminders</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue11 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-folder-open-outline</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Form Profiles</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue12 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-sync</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Syncing Comp</v-list-item-content></v-list-item>
				</v-list>
 </v-card-text>

 <v-container class="text-center my-2">
<form method='post' name='paypal_form' action='https://www.paypal.com/cgi-bin/webscr' target='_blank'>
<input type='hidden' name='cmd' value='_xclick' />
<input type='hidden' name='business' value='danko.breljak@googlemail.com' />
<input type='hidden' name='currency_code' value='EUR' />
<input type='hidden' name='no_note' value='1' />
<input v-if="$store.state.authenticated && $store.state.discordUser" type='hidden' name='custom' :value="$store.state.discordUser.id" />
<input type='hidden' name='item_name' value='Raid-Helper Premium 3' />
<input type='hidden' name='amount' value='10.00' />
<input type='hidden' name='item_number' value='1' />
<input type='hidden' name='quantity' value='1' />
<input type='hidden' name='no_shipping' value='1' />
<input type='hidden' name='notify_url' value='https://raid-helper.xyz/api/ipn/'/>
<input type="hidden" name="return" :value="successURL">

<input type='image' src='https://raid-helper.dev/uploads/paypal.png' border='0' style="width:95%; height: 25px;" name='submit'>
</form></v-container>
 <v-card-text class="text-center my-n8">

    <stripe-checkout
      ref="checkoutRef1"
      mode="payment"
      :pk="publishableKey"
      :line-items="rh3"
      :success-url="successURL"
      :cancel-url="cancelURL"
      :clientReferenceId="clientReferenceId"
      @loading="v => loading = v"
    />

    <v-btn elevation="0" color="#938df5" width="98%" height="25px" class="text-lowercase" style="color:white;" tile @click="submit1">stripe</v-btn></v-card-text>
	  </v-card></v-col>

	  
			  	  <v-col cols="11" sm="6" md="5" lg="3">
	  <v-card elevation="10" class="mx-1 rh6" color="primary lighten-2" height="750" style="border: 2px solid #6d8a9c !important; border-radius: 0 !important;">
		    <v-card-title class="justify-center" style="background-color:#6d8a9c; color:white; font-size: 1.5em; font-weight: bold;">Premium 6</v-card-title>
			<v-card-subtitle class="text-center" style="background-color:#6d8a9c; color:white;">for 6 months and 1 server</v-card-subtitle>
			<v-card-text class="text-center pt-10 mb-n5" style="color:#6d8a9c; font-size: 4em; font-weight: bold;">15€</v-card-text>
			<v-card-text class="text-center" style="color:#6d8a9c;">2,50€ / month</v-card-text>
			<v-card-text class="text-center" style="color:#99aab5;"> 
				<v-list dense color="transparent" class="mx-5 mt-n5">

          <v-list-item class="my-n1 mx-n5" @click="dialogue1 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-calendar</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Discord Events</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue2 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-shield-account</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Temporary Role</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue3 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-wrench</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Custom Templates</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue4 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-calendar-refresh</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Recurring Events</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue5 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-mirror</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Mirrored Events</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue6 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-folder-edit</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Event Logging</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue7 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-archive</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Archiving</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue8 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-reply-circle</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Event Response</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue9 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-view-list</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Live Overview</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue10 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-alarm</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Reminders</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue11 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-folder-open-outline</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Form Profiles</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue12 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-sync</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Syncing Comp</v-list-item-content></v-list-item>
				</v-list>
 </v-card-text>
  <v-container class="text-center my-2">
<form method='post' name='paypal_form' action='https://www.paypal.com/cgi-bin/webscr' target='_blank'>
<input type='hidden' name='cmd' value='_xclick' />
<input type='hidden' name='business' value='danko.breljak@googlemail.com' />
<input type='hidden' name='currency_code' value='EUR' />
<input type='hidden' name='no_note' value='1' />
<input v-if="$store.state.authenticated && $store.state.discordUser" type='hidden' name='custom' :value="$store.state.discordUser.id" />
<input type='hidden' name='item_name' value='Raid-Helper Premium 6' />
<input type='hidden' name='amount' value='15.00' />
<input type='hidden' name='item_number' value='2' />
<input type='hidden' name='quantity' value='1' />
<input type='hidden' name='no_shipping' value='1' />
<input type='hidden' name='notify_url' value='https://raid-helper.xyz/api/ipn/'/>
<input type="hidden" name="return" :value="successURL">

<input type='image' src='https://raid-helper.dev/uploads/paypal.png' border='0' style="width:95%; height: 25px;" name='submit'>
</form></v-container>
 <v-card-text class="text-center my-n8">
    <stripe-checkout
      ref="checkoutRef2"
      mode="payment"
      :pk="publishableKey"
      :line-items="rh6"
      :success-url="successURL"
      :cancel-url="cancelURL"
      :clientReferenceId="clientReferenceId"
      @loading="v => loading = v"
    />
    <v-btn elevation="0" color="#938df5" width="98%" height="25px" class="text-lowercase" style="color:white;" tile @click="submit2">stripe</v-btn></v-card-text>
	  </v-card></v-col>

			  	  <v-col cols="11" sm="6" md="5" lg="3">
	  <v-card elevation="10" class="mx-1 rh12" color="primary lighten-2" height="750" style="border: 2px solid #c0c869 !important; border-radius: 0 !important;">
		    <v-card-title class="justify-center" style="background-color:#c0c869; color:white; font-size: 1.5em; font-weight: bold;">Premium 12</v-card-title>
			<v-card-subtitle class="text-center" style="background-color:#c0c869; color:white;">for 12 months and 1 server</v-card-subtitle>
			<v-card-text class="text-center pt-10 mb-n5" style="color:#c0c869; font-size: 4em; font-weight: bold;">25€</v-card-text>
			<v-card-text class="text-center" style="color:#c0c869;">2,08€ / month</v-card-text>
			<v-card-text class="text-center" style="color:#c0c869;"> 
				<v-list dense color="transparent" class="mx-5 mt-n5">


          <v-list-item class="my-n1 mx-n5" @click="dialogue1 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-calendar</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Discord Events</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue2 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-shield-account</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Temporary Role</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue3 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-wrench</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Custom Templates</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue4 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-calendar-refresh</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Recurring Events</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue5 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-mirror</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Mirrored Events</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue6 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-folder-edit</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Event Logging</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue7 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-archive</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Archiving</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue8 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-reply-circle</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Event Response</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue9 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-view-list</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Live Overview</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue10 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-alarm</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Reminders</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue11 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-folder-open-outline</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Form Profiles</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue12 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-sync</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Syncing Comp</v-list-item-content></v-list-item>
				</v-list>
 </v-card-text>
  <v-container class="text-center my-2">
<form method='post' name='paypal_form' action='https://www.paypal.com/cgi-bin/webscr' target='_blank'>
<input type='hidden' name='cmd' value='_xclick' />
<input type='hidden' name='business' value='danko.breljak@googlemail.com' />
<input type='hidden' name='currency_code' value='EUR' />
<input type='hidden' name='no_note' value='1' />
<input v-if="$store.state.authenticated && $store.state.discordUser" type='hidden' name='custom' :value="$store.state.discordUser.id" />
<input type='hidden' name='item_name' value='Raid-Helper Premium 12' />
<input type='hidden' name='amount' value='25.00' />
<input type='hidden' name='item_number' value='3' />
<input type='hidden' name='quantity' value='1' />
<input type='hidden' name='no_shipping' value='1' />
<input type='hidden' name='notify_url' value='https://raid-helper.xyz/api/ipn/'/>
<input type="hidden" name="return" :value="successURL">

<input type='image' src='https://raid-helper.dev/uploads/paypal.png' border='0' style="width:95%; height: 25px;" name='submit'>
</form></v-container>
 <v-card-text class="text-center my-n8">
    <stripe-checkout
      ref="checkoutRef3"
      mode="payment"
      :pk="publishableKey"
      :line-items="rh12"
      :success-url="successURL"
      :cancel-url="cancelURL"
      :clientReferenceId="clientReferenceId"
      @loading="v => loading = v"
    />
    <v-btn elevation="0" color="#938df5" width="98%" height="25px" class="text-lowercase" style="color:white;" tile @click="submit3">stripe</v-btn></v-card-text>
	  </v-card></v-col>

			  	  <v-col cols="11" sm="6" md="5" lg="3">

	  <v-card elevation="10" class="mx-1 rhl" color="primary lighten-2" height="750" style="border: 2px solid #8193ec !important; border-radius: 0 !important;">
		    <v-card-title class="justify-center" style="background-color:#8193ec; color:white; font-size: 1.5em; font-weight: bold;">Lifetime Premium</v-card-title>
			<v-card-subtitle class="text-center" style="background-color:#8193ec; color:white;">lifetime for 1 server</v-card-subtitle>
			<v-card-text class="text-center pt-10 mb-n5" style="color:#8193ec; font-size: 4em; font-weight: bold;">55€</v-card-text>
			<v-card-text class="text-center" style="color:#8193ec;">best value</v-card-text>
			<v-card-text class="text-center" style="color:#8193ec;"> 
				<v-list dense color="transparent" class="mx-5 mt-n5">

          <v-list-item class="my-n1 mx-n5" @click="dialogue1 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-calendar</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Discord Events</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue2 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-shield-account</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Temporary Role</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue3 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-wrench</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Custom Templates</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue4 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-calendar-refresh</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Recurring Events</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue5 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-mirror</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Mirrored Events</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue6 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-folder-edit</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Event Logging</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue7 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-archive</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Archiving</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue8 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-reply-circle</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Event Response</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue9 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-view-list</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Live Overview</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue10 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-alarm</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Reminders</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue11 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-folder-open-outline</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Form Profiles</v-list-item-content></v-list-item>
<v-list-item class="my-n1 mx-n5" @click="dialogue12 = true"><v-list-item-icon class="mx-3"><v-icon color="#bab5b5">mdi-sync</v-icon></v-list-item-icon><v-list-item-content style="color:#bab5b5;">Syncing Comp</v-list-item-content></v-list-item>
				</v-list>
 </v-card-text>
  <v-container class="text-center my-2">
<form method='post' name='paypal_form' action='https://www.paypal.com/cgi-bin/webscr' target='_blank'>
<input type='hidden' name='cmd' value='_xclick' />
<input type='hidden' name='business' value='danko.breljak@googlemail.com' />
<input type='hidden' name='currency_code' value='EUR' />
<input type='hidden' name='no_note' value='1' />
<input v-if="$store.state.authenticated && $store.state.discordUser" type='hidden' name='custom' :value="$store.state.discordUser.id" />
<input type='hidden' name='item_name' value='Raid-Helper Lifetime Premium' />
<input type='hidden' name='amount' value='55.00' />
<input type='hidden' name='item_number' value='4' />
<input type='hidden' name='quantity' value='1' />
<input type='hidden' name='no_shipping' value='1' />
<input type='hidden' name='notify_url' value='https://raid-helper.xyz/api/ipn/'/>
<input type="hidden" name="return" :value="successURL">

<input type='image' src='https://raid-helper.dev/uploads/paypal.png' border='0' style="width:95%; height: 25px;" name='submit'>
</form></v-container>
 <v-card-text class="text-center my-n8">
    <stripe-checkout
      ref="checkoutRef4"
      mode="payment"
      :pk="publishableKey"
      :line-items="rhl"
      :success-url="successURL"
      :cancel-url="cancelURL"
      :clientReferenceId="clientReferenceId"
      @loading="v => loading = v"
    />
    <v-btn elevation="0" color="#938df5" width="98%" height="25px" class="text-lowercase" style="color:white;" tile @click="submit4">stripe</v-btn></v-card-text>
	  </v-card></v-col>
		</v-row>

	  <v-card color="transparent" elevation="0" class="text-center pb-10 mt-n5">
	 	<v-divider dark style="color:yellow;" class="my-15"></v-divider>
		<span style="color: #e1dada;">A code will be sent to your e-mail address after a successfull purchase. The code needs to be applied to your server via the       
      <v-dialog transition="dialog-top-transition" max-width="800" overlay-opacity="0.8" >
        <template v-slot:activator="{ on, attrs }">
          <v-btn elevation="0" x-small class="purple white--text px-2 mx-1" v-bind="attrs" v-on="on">/verify</v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card color="#1d1d21" @click="dialog.value = false">
 
            <v-img src="https://raid-helper.dev/uploads/premium/verify.gif"></v-img>
          </v-card>
        </template>
      </v-dialog> command. This will grant your server Raid-Helper Premium! You can change the server your premium is applied to by using the /verify command on another server. There are no limitations in regards to how often you can swap servers or how many codes you can have in use.<br><br>If you are unhappy with your purchase, please contact us on our support server to resolve any issues you might have or to request a refund.</span>

	  </v-card>
    	 	<v-divider dark style="color:yellow;" class="my-15"></v-divider>
    <v-row justify="center">
      <v-col cols="12" sm="12" xl="12" class="text-center">
      <span style="color: #e1dada;"><h1>Premium Features</h1></span>
      </v-col>

    <v-col cols="11" sm="4" xl="4" id="testing">
    <v-card tile color="#38383e8f" style="" elevation="5" class="text-center py-5 vcard-premium" min-height="260">
      <v-card-title class="justify-center">
        <v-icon size="60" color="#bab5b5">mdi-calendar</v-icon>
      </v-card-title>
      <v-card-subtitle class="my-3" style="color: #e1dada;"><h2><span style="color: red;">NEW! </span>Discord Events</h2></v-card-subtitle>
      <v-card-text style="color: #a09c9c;">Get a neat overview of your upcoming events!</v-card-text>
      <v-col cols="auto" align="center" class="mb-5">
        <v-btn @click="dialogue1 = true" elevation="0" color="primary">Preview</v-btn>
    </v-col>
    </v-card></v-col>

    <v-col cols="11" sm="4" xl="4">
    <v-card tile color="#38383e8f" style="" elevation="5" class="text-center py-5 vcard-premium" min-height="260">
      <v-card-title class="justify-center">
      <v-icon size="60" color="#bab5b5">mdi-shield-account</v-icon>
      </v-card-title>
      <v-card-subtitle class="my-3" style="color: #e1dada;"><h2>Temporary Role</h2></v-card-subtitle>
      <v-card-text style="color: #a09c9c;">A temporary role that will be removed after the event has concluded!</v-card-text>
      <v-col cols="auto" align="center" class="mb-5">
        <v-btn @click="dialogue2 = true" elevation="0" color="primary">Preview</v-btn>
    </v-col>
    </v-card></v-col>

    <v-col cols="11" sm="4" xl="4">
    <v-card tile color="#38383e8f" style="" elevation="5" class="text-center py-5 vcard-premium" min-height="260">
      <v-card-title class="justify-center">
      <v-icon size="60" color="#bab5b5">mdi-wrench</v-icon>
      </v-card-title>
      <v-card-subtitle class="my-3" style="color: #e1dada;"><h2>Custom Templates</h2></v-card-subtitle>
      <v-card-text style="color: #a09c9c;">Create your own templates with emotes of your choice!</v-card-text>
      <v-col cols="auto" align="center" class="mb-5">
        <v-btn @click="dialogue3 = true" elevation="0" color="primary">Preview</v-btn>
    </v-col>
    </v-card></v-col>

    <v-col cols="11" sm="4" xl="4">
        <v-card tile color="#38383e8f" style="" elevation="5" class="text-center py-5 vcard-premium" min-height="260">
           <v-card-title class="justify-center">
      <v-icon  size="60" color="#bab5b5">mdi-calendar-refresh</v-icon>
      </v-card-title>
                <v-card-subtitle class="my-3" style="color: #e1dada;"><h2>Recurring Events</h2></v-card-subtitle>
     <v-card-text style="color: #a09c9c;">Save time and let the bot create your regular events automatically!</v-card-text>
     <v-col cols="auto" align="center" class="mb-5">
      <v-btn @click="dialogue4 = true" elevation="0" color="primary">Preview</v-btn>
    </v-col>
    </v-card></v-col>

        <v-col cols="11" sm="4" xl="4">
        <v-card tile color="#38383e8f" style="" elevation="5" class="text-center py-5 vcard-premium" min-height="260">
            <v-card-title class="justify-center">
      <v-icon  size="60" color="#bab5b5">mdi-mirror</v-icon>
      </v-card-title>
          <v-card-subtitle class="my-3" style="color: #e1dada;"><h2>Mirrored Events</h2></v-card-subtitle>
     <v-card-text style="color: #a09c9c;">Mirror events and have them sync even across servers!</v-card-text>
     <v-col cols="auto" align="center" class="mb-5">
      <v-btn @click="dialogue5 = true" elevation="0" color="primary">Preview</v-btn>
    </v-col>
    </v-card></v-col>

    <v-col cols="11" sm="4" xl="4">
    <v-card tile color="#38383e8f" style="" elevation="5" class="text-center py-5 vcard-premium" min-height="260">
            <v-card-title class="justify-center">
      <v-icon  size="60" color="#bab5b5">mdi-folder-edit</v-icon>
      </v-card-title>
     <v-card-subtitle class="my-3" style="color: #e1dada;"><h2>Event Logging</h2></v-card-subtitle>
          <v-card-text style="color: #a09c9c;">Have the bot log every event interaction into a channel!</v-card-text>
          <v-col cols="auto" align="center" class="mb-5">
            <v-btn @click="dialogue6 = true" elevation="0" color="primary">Preview</v-btn>
    </v-col>
    </v-card></v-col>

    <v-col cols="11" sm="4" xl="4">
        <v-card tile color="#38383e8f" style="" elevation="5" class="text-center py-5 vcard-premium" min-height="260">
            <v-card-title class="justify-center">
      <v-icon  size="60" color="#bab5b5">mdi-archive</v-icon>
      </v-card-title>
     <v-card-subtitle class="my-3" style="color: #e1dada;"><h2>Archiving</h2></v-card-subtitle>
          <v-card-text style="color: #a09c9c;">Automatically archive ended events!</v-card-text>
          <v-col cols="auto" align="center" class="mb-5">
            <v-btn @click="dialogue7 = true" elevation="0" color="primary">Preview</v-btn>
    </v-col>
    </v-card></v-col>

        <v-col cols="11" sm="4" xl="4">
        <v-card tile color="#36363c" style="" elevation="5" class="text-center py-5 vcard-premium" min-height="260">
            <v-card-title class="justify-center">
      <v-icon  size="60" color="#bab5b5">mdi-reply-circle</v-icon>
      </v-card-title>
     <v-card-subtitle class="my-3" style="color: #e1dada;"><h2>Event Response</h2></v-card-subtitle>
          <v-card-text style="color: #a09c9c;">Send users a message when they sign up!</v-card-text>
          <v-col cols="auto" align="center" class="mb-5">
            <v-btn @click="dialogue8 = true" elevation="0" color="primary">Preview</v-btn>
    </v-col>
    </v-card></v-col>

    <v-col cols="11" sm="4" xl="4">
    <v-card tile color="#38383e8f" style="" elevation="5" class="text-center py-5 vcard-premium" min-height="260">
            <v-card-title class="justify-center">
      <v-icon  size="60" color="#bab5b5">mdi-view-list</v-icon>
      </v-card-title>
    <v-card-subtitle class="my-3" style="color: #e1dada;"><h2>Live Overview</h2></v-card-subtitle>
    <v-card-text style="color: #a09c9c;">Display a live updating overview of upcoming events!</v-card-text>
    <v-col cols="auto" align="center" class="mb-5">
    <v-btn @click="dialogue9 = true" elevation="0" color="primary">Preview</v-btn>
    </v-col>
    </v-card></v-col>

    <v-col cols="11" sm="4" xl="4">
        <v-card tile color="#38383e8f" style="" elevation="5" class="text-center py-5 vcard-premium" min-height="260">
            <v-card-title class="justify-center">
      <v-icon  size="60" color="#bab5b5">mdi-alarm</v-icon>
      </v-card-title>
    <v-card-subtitle class="my-3" style="color: #e1dada;"><h2>Reminders</h2></v-card-subtitle>
    <v-card-text style="color: #a09c9c;">Allow members to set their own individual reminders for events!</v-card-text>
    <v-col cols="auto" align="center" class="mb-5">
    <v-btn @click="dialogue10 = true" elevation="0" color="primary">Preview</v-btn>
    </v-col>
    </v-card></v-col>

        <v-col cols="11" sm="4" xl="4">
        <v-card tile color="#38383e8f" style="" elevation="5" class="text-center py-5 vcard-premium" min-height="280">
            <v-card-title class="justify-center">
                  <v-icon  size="60" color="#bab5b5">mdi-folder-open-outline</v-icon>
      </v-card-title>
    <v-card-subtitle class="my-3" style="color: #e1dada;"><h2>Form Profiles</h2></v-card-subtitle>
    <v-card-text style="color: #a09c9c;">Save and load profiles on the web dashboard for faster event creation!</v-card-text>
    <v-col cols="auto" align="center" class="mb-5">
    <v-btn @click="dialogue11 = true" elevation="0" color="primary">Preview</v-btn>
    </v-col>
    </v-card></v-col>
    <v-col cols="11" sm="4" xl="4">
        <v-card tile color="#38383e8f" style="" elevation="5" class="text-center py-5 vcard-premium" min-height="280">
            <v-card-title class="justify-center">
                  <v-icon  size="60" color="#bab5b5">mdi-sync</v-icon>
      </v-card-title>
    <v-card-subtitle class="my-3" style="color: #e1dada;"><h2>Syncing Comp</h2></v-card-subtitle>
    <v-card-text style="color: #a09c9c;">Have your Comp sync and allow users to confirm or decline!</v-card-text>
    <v-col cols="auto" align="center" class="mb-5">
    <v-btn @click="dialogue12 = true" elevation="0" color="primary">Preview</v-btn>
    </v-col>



    <v-dialog v-model="dialogue1" transition="dialog-top-transition" max-width="800" overlay-opacity="0.8" >
        <template>
          <v-card color="#1d1d21" @click="dialogue1 = false">
            <v-img src="https://raid-helper.dev/uploads/premium/discordevents.gif"></v-img>
          </v-card>
        </template>
      </v-dialog>
      <v-dialog v-model="dialogue2" transition="dialog-top-transition" max-width="800" overlay-opacity="0.8" >
        <template>
          <v-card color="#1d1d21" @click="dialogue2 = false">
            <v-img src="https://raid-helper.dev/uploads/premium/temporaryrole.gif"></v-img>
          </v-card>
        </template>
      </v-dialog>
      <v-dialog v-model="dialogue3" transition="dialog-top-transition" max-width="800" overlay-opacity="0.8" >
        <template>
          <v-card color="#1d1d21" @click="dialogue3 = false">
            <v-img src="https://raid-helper.dev/uploads/premium/customtemplates.gif"></v-img>
          </v-card>
        </template>
      </v-dialog>
      <v-dialog v-model="dialogue4" transition="dialog-top-transition" max-width="800" overlay-opacity="0.8" >
        <template>
          <v-card color="#1d1d21" @click="dialogue4 = false">
            <v-img src="https://raid-helper.dev/uploads/premium/recurringevents.gif"></v-img>
          </v-card>
        </template>
      </v-dialog>
      <v-dialog v-model="dialogue5" transition="dialog-top-transition" max-width="800" overlay-opacity="0.8" >
        <template>
          <v-card color="#1d1d21" @click="dialogue5 = false">
            <v-img src="https://raid-helper.dev/uploads/premium/mirroredevents.gif"></v-img>
          </v-card>
        </template>
      </v-dialog>
      <v-dialog v-model="dialogue6" transition="dialog-top-transition" max-width="800" overlay-opacity="0.8" >
        <template>
          <v-card color="#1d1d21" @click="dialogue6 = false">
            <v-img src="https://raid-helper.dev/uploads/premium/loggingchannel.gif"></v-img>
          </v-card>
        </template>
      </v-dialog>
      <v-dialog v-model="dialogue7" transition="dialog-top-transition" max-width="800" overlay-opacity="0.8" >
        <template>
          <v-card color="#1d1d21" @click="dialogue7 = false">
            <v-img src="https://raid-helper.dev/uploads/premium/archivechannel.gif"></v-img>
          </v-card>
        </template>
      </v-dialog>
      <v-dialog v-model="dialogue8" transition="dialog-top-transition" max-width="800" overlay-opacity="0.8" >
        <template>
          <v-card color="#1d1d21" @click="dialogue8 = false">
            <v-img src="https://raid-helper.dev/uploads/premium/responses.gif"></v-img>
          </v-card>
        </template>
      </v-dialog>
      <v-dialog v-model="dialogue9" transition="dialog-top-transition" max-width="800" overlay-opacity="0.8" >
        <template>
          <v-card color="#1d1d21" @click="dialogue9 = false">
            <v-img src="https://raid-helper.dev/uploads/premium/liveoverview.gif"></v-img>
          </v-card>
        </template>
      </v-dialog>
      <v-dialog v-model="dialogue10" transition="dialog-top-transition" max-width="800" overlay-opacity="0.8" >
        <template>
          <v-card color="#1d1d21" @click="dialogue10 = false">
            <v-img src="https://raid-helper.dev/uploads/premium/reminders.gif"></v-img>
          </v-card>
        </template>
      </v-dialog>
      <v-dialog v-model="dialogue11" transition="dialog-top-transition" max-width="800" overlay-opacity="0.8" >
        <template>
          <v-card color="#1d1d21" @click="dialogue11 = false">
            <v-img src="https://raid-helper.dev/uploads/premium/formprofiles.gif"></v-img>
          </v-card>
        </template>
      </v-dialog>
      <v-dialog v-model="dialogue12" transition="dialog-top-transition" max-width="800" overlay-opacity="0.8" >
        <template>
          <v-card color="#1d1d21" @click="dialogue12 = false">
            <v-img src="https://raid-helper.dev/uploads/premium/syncingannounce.gif"></v-img>
          </v-card>
        </template>
      </v-dialog>

    </v-card></v-col>

    </v-row>
  </div>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import { mapActions } from "vuex";
import { customAlphabet } from "nanoid/non-secure";

export default {
  components: {
    StripeCheckout,
    
  },
    data: () => ({
      items: [
        {
          color: 'red lighten-2',
          icon: 'mdi-star',
        },
        {
          color: 'purple darken-1',
          icon: 'mdi-book-variant',
        },
        {
          color: 'green lighten-1',
          icon: 'mdi-airballoon',
        },
        {
          color: 'indigo',
          icon: 'mdi-buffer',
        },
      ],
    }),
  
  data () {
    this.publishableKey = 'pk_live_51I5wNCKyOMfuurohBv9i0gyzkDIfFaib7t9eZp5o8zCHCCn2i8TdyaxCpVCDkINKSduB0zaKXEgd6dVezUtMERCm00b41jCKCN';
    return {
      loading: false,
      dialogue1: false,
      dialogue2: false,
      dialogue3: false,
      dialogue4: false,
      dialogue5: false,
      dialogue6: false,
      dialogue7: false,
      dialogue8: false,
      dialogue9: false,
      dialogue10: false,
      dialogue11: false,
      dialogue12: false,
      dialogue13: false,
      carousel: [
        require("../assets/guildscheduledevent1.png"),
        require("../assets/guildscheduledevent2.png"),
        require("../assets/guildscheduledevent3.png"),
        ],
      rh3: [
        {
          price: 'price_1IOT6UKyOMfuurohpDuH5fRY',
          quantity: 1,
        },

      ],
	    rh6: [
		{
          price: 'price_1JRJEXKyOMfuurohJRmWZOf2',
          quantity: 1,
        },

      ],
	    rh12: [
		{
          price: 'price_1JRJF0KyOMfuuroh1sB9qCXo',
          quantity: 1,
        },

      ],
	    rhl: [
		{
          price: 'price_1JRJHnKyOMfuuroh9zAyA7Z4',
          quantity: 1,
        },
      ],

      successURL: 'https://raid-helper.dev/',
      cancelURL: 'https://raid-helper.dev/premium',
      clientReferenceId: undefined,
    };
  },
  methods: {
    submit1 () {
      this.$refs.checkoutRef1.redirectToCheckout();
    },
	    submit2 () {
      this.$refs.checkoutRef2.redirectToCheckout();
    },
	    submit3 () {
      this.$refs.checkoutRef3.redirectToCheckout();
    },
	    submit4 () {
      this.$refs.checkoutRef4.redirectToCheckout();
    },
    navigateToElement(elementId) {
      var element = document.getElementById(elementId);
      if (element !== undefined && element.offsetTop !== undefined) {
      var top = element.offsetTop;
      var add = this.$vuetify.breakpoint.xs ? 160 : 120;
      window.scrollTo(0, top+add);
    }
  }

  },
  		computed: {
			...mapActions(["fetchDiscordInfo"]),
			
		},

		created() {
      const nanoid = customAlphabet("1234567890abcdef", 20);
			this.clientReferenceId = nanoid();
      this.successURL =  'https://raid-helper.dev/premium/' + this.clientReferenceId
			this.fetchDiscordInfo;
			window.scrollTo({
                top: 0,
                left: 0,
                });

                document.title = "Raid-Helper - Premium"
		},
  

};

</script>

<style>
.rh3 {
    box-shadow: 
				0px 0px 0px 0px #99aab5 !important;
				transition: box-shadow 0.5s ease-in-out;
}
.rh3:hover {
    box-shadow: 
				0px 0px 10px 2px #99aab5 !important;
				 transition: box-shadow 0.3s ease-in-out;
}
.rh6 {
    box-shadow: 
				0px 0px 0px 0px #99aab5 !important;
				transition: box-shadow 0.5s ease-in-out;
}
.rh6:hover {
    box-shadow: 
				0px 0px 20px 4px #99aab5 !important;
				 transition: box-shadow 0.3s ease-in-out;
}
.rh12 {
    box-shadow: 
				0px 0px 0px 0px #c0c869 !important;
				transition: box-shadow 0.5s ease-in-out;
}
.rh12:hover {
    box-shadow: 
				0px 0px 30px 6px #c0c869 !important;
				 transition: box-shadow 0.3s ease-in-out;
}
.rhl {
    box-shadow: 
				0px 0px 0px 0px #8193ec !important;
				transition: box-shadow 0.5s ease-in-out;
}
.rhl:hover {
    box-shadow: 
				0px 0px 40px 8px #8193ec !important;
				 transition: box-shadow 0.3s ease-in-out;
}

.vcard-premium:hover {
  background-color: #3e3e44 !important;
  transform: scale(1.01);
  border: 1px solid black !important;

}
.vcard-premium {
  border: 1px solid #101012 !important;
  min-height: 300px !important;
}
</style>
