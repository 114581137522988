<template>
<v-container class="pa-1">

              <DashNavi v-bind:title="'Create Event'" v-bind:route="'/dashboard'" id="DashNavi"/>

            <v-alert v-if="isLoaded && $store.state.currentGuild && !$store.state.chosenDiscord.hasPerms" prominent type="error" class="mt-n5 mt-sm-n7">
              <v-row align="center">
                <v-col class="grow">You don't have permission to create events in this server.</v-col>
                <v-col class="shrink">
                  <v-btn elevation="0" to="/dashboard">Go back</v-btn>
                </v-col>
              </v-row>
            </v-alert>

            <General id="General" class="mt-n3 mt-sm-8" v-if="$store.state.currentGuild" />

            <v-snackbar color="red" v-model="snackbarInvalidForm" :timeout="4000" >Please fill out the Form correctly!
            </v-snackbar>

            <v-snackbar v-if="createResponseText != undefined" v-model="createResponse" :color="color" :timeout="4000" >
              <div v-for="(text, index) in createResponseText.split('\n')" :key="index">
              {{ text }}
              </div>
            
            </v-snackbar>

            <v-snackbar color="red" v-if="$store.state.formAnswers.date === ''" v-model="snackbarInvalidForm" :timeout="4000" >Enter a valid date!
            </v-snackbar>

			      <v-snackbar color="green" elevation="0" v-model="snackbar2" timeout="4000" ><span style="font-size:15px;">Profile loaded!</span>
            </v-snackbar>

						<v-snackbar color="green" elevation="0" v-model="snackbar3" timeout="4000" ><span style="font-size:15px;">Form reset!</span>
            </v-snackbar>

</v-container>
</template>

<script>
import Form from "../components/Form";
import General from "../components/General";
import DashNavi from "../components/DashNavi";

import { mapActions } from "vuex";
import Store from "../store/index";
import axios from "axios";

export default {
    components: {
    Form,
    General,
    DashNavi
  },
  data() {
    return {
		snackbar2: false,
		snackbar3: false,
      radio: 0,
      active: false,
      absolute: true,
      overlay: false,
      show: false,
      isLoaded: false,
      valid: false,
      date: undefined,
      server: undefined,
      channel: undefined,
      toggle_exclusive: undefined,
      templates: [],
      loadingPostReq: false,
      snackbarInvalidForm: false,
      createResponse: false,
      createResponseText: undefined,
      color: "#4caf4f",
      pollEmotes: ["675573195029479424", "675573195138662420", "675573195100782593", "675573194890936341", "675573195243388930", "675573195465555979", "675573195553636352", "675573195365154853", "675573195008507916", "675573195402641409", "675573195352440843", "675573195704631296", "675573195826397195", "675573195796906005", "675573195885248512", "675573195797037057", "675573196124192770", "675573195859820555"],

      swatches: [
        [
          "#212224FF",
          "#000000FF",
          "#330000FF",
          "#663300FF",
          "#992D22FF",
          "#A84300FF",
          "#1F8B4CFF",
          "#11806AFF",
          "#1ABC9CFF",
          "#BCC0C0FF",
          "#95A5A6FF",
          "#979C9FFF",
          "#7F8C8DFF",
          "#2C3E50FF",
          "#34495EFF",
          "#71368AFF",
          "#0000FFFF",
          "#0000CCFF",
          "#3498DBFF",
          "#33CCFFFF",
          "#9B59B6FF",
          "#2ECC71FF",
          "#E74C3CFF",
          "#BC0057FF",
          "#FD0061FF",
          "#F1C40FFF",
          "#C27C0EFF",
          "#66FF66FF",
          "#E67E22FF",
          "#FFFF00FF",
        ],
      ],

      rules: [(v) => !!v || "Required"],
      numberRule: [
        (v) => {
          if (!v.trim()) return true;
          if (!isNaN(parseFloat(v)) && v >= 0) return true;
          return "Number has to be greater than 0";
        },
      ],
      clocks: ["", "PM", "AM"],
      formAnswer: {
        accessToken: "",
        serverid: "",
        channelid: "",
        leaderid: "",
        title: "",
        description: "",
        date: "",
        time: "",
        settings: "0",
        template: "",
        schedule: "",
        color: "",
        image: "",
        mentions: "",
        allowed_roles: "",
        banned_roles: "",
        opt_out: "",
        announcement: "",
        temp_role: "",
        recurring: "",
        softres: [],
        response: "",
        duration: "",
        voice_channel: "",
        deadline: "",
        limit: "",
        lock_at_limit: "",
        limit_per_user: "",
        lower_limit: "",
        horizontal_mode: "",
        bench_overflow: "",
        queue_bench: "",
        vacuum: "",
        force_reminders: "",
        create_thread: "",
        forum_tags: "",
        delete_thread: "",
        use_nicknames: "",
        create_discordevent: "",
        only_discordevent: "",
        pin_message: "",
        deletion: "",
        mention_mode: "",
        preserve_order: "",
        event_type: "",
        reminder: "",
        apply_unregister: "",
        apply_specreset: "",
        show_on_overview: "",
        mention_leader: "",
        spec_saving: "",
        font_style: "",
        alt_names: "",
        attendance: "",
        show_title: "",
        show_info: "",
        show_leader: "",
        show_counter: "",
        show_roles: "",
        show_content: "",
        show_classes: "",
        show_emotes: "",
        show_numbering: "",
        show_allowed: "",
        show_banned: "",
        show_footer: "",
        info_variant: "",
        date_variant: "",
        show_countdown: "",
        defaults_pre_req: "",
        disable_archiving: "",
        allow_duplicate: "",
        bench_emote: "",
        late_emote: "",
        tentative_emote: "",
        absence_emote: "",
        leader_emote: "",
        signups1_emote: "",
        signups2_emote: "",
        date1_emote: "",
        date2_emote: "",
        time1_emote: "",
        time2_emote: "",
        countdown1_emote: "",
        countdown2_emote: "",
        bold_all: "",
        thumbnail: "",
        rolelimits: "",
		    classlimits: "",
        interval2: "",
      },
    };
  },
  methods: {

        truncate(input) {
      if (input.length > 15 && this.$vuetify.breakpoint.xs) {
        return input.substring(0, 15) + "..";
      }
      return input;
    },

    getSavedTemplate() {},

    onClickOutside() {
      this.overlay = false;
    },

    isMobile() {
      return window.innerWidth < 800;
    },
    addPollAnswer() {
      this.$store.state.formAnswers.pollAnswers.push("")
    },

    disablePastDates(val) {
      return val >= new Date().toISOString().substr(0, 10);
    },
    isWoW() {
        if (this.$store.state.formAnswers.template.id === 1 || this.$store.state.formAnswers.template.id === 2 || this.$store.state.formAnswers.template.id === 3 || this.$store.state.formAnswers.template.id === 4 || this.$store.state.formAnswers.template.id === 31 || this.$store.state.formAnswers.template.id === 17 || this.$store.state.formAnswers.template.id === 18 || this.$store.state.formAnswers.template.id === 19 || this.$store.state.formAnswers.template.id === 20 || this.$store.state.formAnswers.template.id === 21 || this.$store.state.formAnswers.template.id === 22 || this.$store.state.formAnswers.template.id === 40 || this.$store.state.formAnswers.template.id === 41) {
          return true;
      } else {
          return false;
      }
    },
    clear() {
      this.$store.state.formAnswers.template = "";
      this.$store.state.formAnswers.channel = "";
      this.$store.state.formAnswers.title = "";
      this.$store.state.formAnswers.description = "";
      this.$store.state.formAnswers.date = "";
      this.$store.state.formAnswers.clock = "";
      this.$store.state.formAnswers.pollQuestion = "";
      this.$store.state.formAnswers.pollAnswers = [];
    },

    clearPollAnswer(n) {
       this.$store.state.formAnswers.pollAnswers.splice(n, 1);
    },

    isPoll() {
       try {
				return (
          this.$store.state.formAnswers.template.id === 23 ||
          this.$store.state.formAnswers.template.id === 24 || 
          this.$store.state.formAnswers.template.id === 25 ||
          this.$store.state.formAnswers.template.shortName === "unsortedstrawpoll" ||
          this.$store.state.formAnswers.template.shortName === "sortedstrawpoll" ||
          this.$store.state.formAnswers.template.shortName === "basepoll"
				);
       } catch {
        return false;
      }
		},

    isStrawpoll() {
       try {
				return (
          this.$store.state.formAnswers.template.id === 23 ||
          this.$store.state.formAnswers.template.id === 24 || 
          this.$store.state.formAnswers.template.shortName === "unsortedstrawpoll" ||
          this.$store.state.formAnswers.template.shortName === "sortedstrawpoll"
				);
       } catch {
        return false;
      }
		},

    getCurrentTime() {
      const today = new Date();
      const minutes =
        today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes();
      return today.getHours() + ":" + minutes;
    },

    postFormAnswer() {
      this.valid = true;
      this.loadingPostReq = true;
      if (this.valid === true) {

        const config = {
          headers: {
            Authorization: "q2vULs8KpLYhPCJdEhTr",
            withCredentials: true,
          },
        };

        var concatedlistclasslimits = "";
        if (this.$store.state.formAnswers.template.classes !== "") {
          for (
            let index = 0;
            index < this.$store.state.formAnswers.template.classes.length;
            index++
          ) {
            if (
              this.$store.state.formAnswers.template.classes[index].value !==
                undefined &&
              this.$store.state.formAnswers.template.classes[index].value !==
                999
            ) {
              concatedlistclasslimits +=
                this.$store.state.formAnswers.template.classes[index].value +
                ":" +
                this.$store.state.formAnswers.template.classes[index].name +
                ";";
            }
          }
        }
        var concatedlistrolelimits = "";
        if (this.$store.state.formAnswers.template.roles !== "") {
          for (let index = 0; index < this.$store.state.formAnswers.template.roles.length; index++) {
            if (
              this.$store.state.formAnswers.template.roles[index].value !==
                undefined &&
              this.$store.state.formAnswers.template.roles[index].value !== 999
            ) {
              concatedlistrolelimits +=
                this.$store.state.formAnswers.template.roles[index].value +
                ":" +
                this.$store.state.formAnswers.template.roles[index].name +
                ";";
            }
          }
        }
        
        this.formAnswer.accessToken = localStorage.getItem('accessToken').slice(1,-1);
        this.formAnswer.leaderid = Store.state.discordUser.id;
        this.formAnswer.serverid = Store.state.chosenDiscord.id;
        this.formAnswer.channelid = Store.state.formAnswers.channel.id ? Store.state.formAnswers.channel.id : Store.state.formAnswers.channel;
        this.formAnswer.template = Store.state.formAnswers.template.shortName != undefined ? Store.state.formAnswers.template.shortName : Store.state.formAnswers.template.id;
        if (
          Store.state.formAnswers.imageUrl !== "" &&
          Store.state.formAnswers.imageUrl !== null
        ) {
          this.formAnswer.image = Store.state.formAnswers.imageUrl;
        }

        this.formAnswer.date = Store.state.formAnswers.date.substr(0, 10);
        this.formAnswer.time = Store.state.formAnswers.date.substr(11, 20);

        if (Store.state.formAnswers.title !== "") {
          this.formAnswer.title = Store.state.formAnswers.title;
        } else {
          this.formAnswer.title = "";
        }

        this.formAnswer.description = Store.state.formAnswers.description;
        this.formAnswer.color = Store.state.formAnswers.color;

        if (Store.state.formAnswers.description !== "") {
          this.formAnswer.description = Store.state.formAnswers.description;
        } else {
          this.formAnswer.description = "";
        }

        if (this.isStrawpoll()) {
          var descrString = "";
          descrString += Store.state.formAnswers.pollQuestion + "=";
          for (let index = 0; index < Store.state.formAnswers.pollAnswers.length; index++) {
              descrString += Store.state.formAnswers.pollAnswers[index] + ","
          }
          this.formAnswer.description = descrString;
          this.formAnswer.title = "Strawpoll";
        } else if (this.isPoll()) {
          this.formAnswer.description = Store.state.formAnswers.pollQuestion;
          this.formAnswer.title = "Poll";
        }

        if (this.isWoW()) {
          let softresArray = [];
          if (Store.state.formAnswers.softresFaction !== 'Alliance') {
            Store.state.formAnswers.softresFaction = 'Horde'
          }

            if (Store.state.formAnswers.softresFaction !== '' && Store.state.formAnswers.softresInstance !== '' && Store.state.formAnswers.softresMaxres !== '' && Store.state.formAnswers.softresBehaviour !== '') {
              softresArray.push(Store.state.formAnswers.softresInstance);
              softresArray.push(Store.state.formAnswers.softresFaction);
              softresArray.push(Store.state.formAnswers.softresMaxres);
              softresArray.push("0");
              softresArray.push("true");
              softresArray.push("true");
              softresArray.push(Store.state.formAnswers.softresBehaviour);
              this.formAnswer.softres = softresArray;
          }
        }

        var mentionsString = "";
        if (Store.state.formAnswers.mentions !== undefined && Store.state.formAnswers.mentions !== "" && Store.state.formAnswers.mentions.length > 0) {
          let mentionsList = Store.state.formAnswers.mentions;
          for (let index = 0; index < mentionsList.length; index++) {
            mentionsString += mentionsList[index] + ", ";
          }
        } else {
          mentionsString = "none";
        }

        var allowedString = "";
        if (Store.state.formAnswers.allowed_roles !== undefined && Store.state.formAnswers.allowed_roles !== "") {
          let allowedList = Store.state.formAnswers.allowed_roles;
          for (let index = 0; index < allowedList.length; index++) {
            allowedString += allowedList[index] + ", ";
          }
        } else {
          allowedString = "all";
        }

        var bannedString = "";
        if (Store.state.formAnswers.banned_roles !== undefined && Store.state.formAnswers.banned_roles !== "") {
          let bannedList = Store.state.formAnswers.banned_roles;
          for (let index = 0; index < bannedList.length; index++) {
            bannedString += bannedList[index] + ", ";
          }
        } else {
          bannedString = "none";
        }

        if (Store.state.premiumFeatures.scheduleDate !== "") {
          let scheduleString =
            Store.state.premiumFeatures.scheduleDate.substr(0, 10) +
            "T" +
            Store.state.premiumFeatures.scheduleDate.substr(11, 20);
          this.formAnswer.schedule = scheduleString;
        }

        if (Store.state.premiumFeatures.interval2Ordinal !== "" && Store.state.premiumFeatures.interval2Weekday !== "") {
          let dynamicDate = Store.state.premiumFeatures.interval2Ordinal + "-" + Store.state.premiumFeatures.interval2Weekday
          this.formAnswer.interval2 = dynamicDate;
        }

        this.formAnswer.mentions = mentionsString;
        this.formAnswer.allowed_roles = allowedString;
        this.formAnswer.banned_roles = bannedString;
        try {
        if (Store.state.formAnswers.announcementChannel !== null && Store.state.formAnswers.announcementChannel !== '' && Store.state.formAnswers.announcementTime !== null && Store.state.formAnswers.announcementMessage.length > 0) {
          this.formAnswer.announcement = [Store.state.formAnswers.announcementChannel, Store.state.formAnswers.announcementTime, Store.state.formAnswers.announcementMessage];
        }
        } catch (error) {}
        if (Store.state.formAnswers.temporaryRoles !== null && Store.state.formAnswers.temporaryRoles !== undefined) {
          this.formAnswer.temp_role = Store.state.formAnswers.temporaryRoles;
        }
        this.formAnswer.recurring = Store.state.premiumFeatures.recurring + (Store.state.premiumFeatures.recurring.length > 0 ? (" " + Store.state.premiumFeatures.recurringUnit) : "");
        this.formAnswer.response = Store.state.advancedSettings.response;
        this.formAnswer.opt_out = Store.state.advancedSettings.opt_out;
        this.formAnswer.voice_channel = Store.state.formAnswers.voice_channel;
        this.formAnswer.duration = Store.state.advancedSettings.duration;
        this.formAnswer.deadline = Store.state.advancedSettings.deadline;
        this.formAnswer.limit = Store.state.advancedSettings.limit;
        this.formAnswer.lock_at_limit = Store.state.advancedSettings.lock_at_limit;
        this.formAnswer.limit_per_user = Store.state.advancedSettings.limit_per_user;
        this.formAnswer.lower_limit = Store.state.advancedSettings.lower_limit;
        this.formAnswer.horizontal_mode = Store.state.advancedSettings.horizontal_mode;
        this.formAnswer.bench_overflow = Store.state.advancedSettings.bench_overflow;
        this.formAnswer.queue_bench = Store.state.advancedSettings.queue_bench;
        this.formAnswer.allow_duplicate = Store.state.advancedSettings.allow_duplicate;
        this.formAnswer.vacuum = Store.state.advancedSettings.vacuum;
        this.formAnswer.force_reminders = Store.state.advancedSettings.force_reminders;
        this.formAnswer.create_thread = Store.state.advancedSettings.create_thread;
        this.formAnswer.forum_tags = Store.state.advancedSettings.forum_tags;
        this.formAnswer.delete_thread = Store.state.advancedSettings.delete_thread;
        this.formAnswer.use_nicknames = Store.state.advancedSettings.use_nicknames;
        this.formAnswer.create_discordevent = Store.state.advancedSettings.create_discordevent;
        this.formAnswer.only_discordevent = Store.state.advancedSettings.only_discordevent;
        this.formAnswer.pin_message = Store.state.advancedSettings.pin_message;
        this.formAnswer.deletion = Store.state.advancedSettings.deletion;
        this.formAnswer.mention_mode = Store.state.advancedSettings.mention_mode;
        this.formAnswer.preserve_order = Store.state.advancedSettings.preserve_order;
        this.formAnswer.event_type = Store.state.advancedSettings.event_type;
        this.formAnswer.reminder = Store.state.advancedSettings.reminder;
        this.formAnswer.apply_unregister = Store.state.advancedSettings.apply_unregister;
        this.formAnswer.apply_specreset = Store.state.advancedSettings.apply_specreset;
        this.formAnswer.show_on_overview = Store.state.advancedSettings.show_on_overview;
        this.formAnswer.mention_leader = Store.state.advancedSettings.mention_leader;
        this.formAnswer.spec_saving = Store.state.advancedSettings.spec_saving;
        this.formAnswer.font_style = Store.state.advancedSettings.font_style;
        this.formAnswer.alt_names = Store.state.advancedSettings.alt_names;
        this.formAnswer.attendance = Store.state.advancedSettings.attendance;
        this.formAnswer.show_title = Store.state.advancedSettings.show_title;
        this.formAnswer.show_info = Store.state.advancedSettings.show_info;
        this.formAnswer.show_leader = Store.state.advancedSettings.show_leader;
        this.formAnswer.show_counter = Store.state.advancedSettings.show_counter;
        this.formAnswer.show_roles = Store.state.advancedSettings.show_roles;
        this.formAnswer.show_content = Store.state.advancedSettings.show_content;
        this.formAnswer.show_classes = Store.state.advancedSettings.show_classes;
        this.formAnswer.show_emotes = Store.state.advancedSettings.show_emotes;
        this.formAnswer.show_numbering = Store.state.advancedSettings.show_numbering;
        this.formAnswer.show_allowed = Store.state.advancedSettings.show_allowed;
        this.formAnswer.show_banned = Store.state.advancedSettings.show_banned;
        this.formAnswer.show_localtime = Store.state.advancedSettings.show_localtime;
        this.formAnswer.info_variant = Store.state.advancedSettings.info_variant;
        this.formAnswer.date_variant = Store.state.advancedSettings.date_variant;
        this.formAnswer.show_footer = Store.state.advancedSettings.show_footer;
        this.formAnswer.show_countdown = Store.state.advancedSettings.show_countdown;
        this.formAnswer.defaults_pre_req = Store.state.advancedSettings.defaults_pre_req;
        this.formAnswer.disable_archiving = Store.state.advancedSettings.disable_archiving;
        this.formAnswer.bold_all = Store.state.advancedSettings.bold_all;
        this.formAnswer.bench_emote = Store.state.advancedSettings.bench_emote;
        this.formAnswer.late_emote = Store.state.advancedSettings.late_emote;
        this.formAnswer.tentative_emote = Store.state.advancedSettings.tentative_emote;
        this.formAnswer.absence_emote = Store.state.advancedSettings.absence_emote;
        this.formAnswer.leader_emote = Store.state.advancedSettings.leader_emote;
        this.formAnswer.signups1_emote = Store.state.advancedSettings.signups1_emote;
        this.formAnswer.signups2_emote = Store.state.advancedSettings.signups2_emote;
        this.formAnswer.date1_emote = Store.state.advancedSettings.date1_emote;
        this.formAnswer.date2_emote = Store.state.advancedSettings.date2_emote;
        this.formAnswer.time1_emote = Store.state.advancedSettings.time1_emote;
        this.formAnswer.time2_emote = Store.state.advancedSettings.time2_emote;
        this.formAnswer.countdown1_emote = Store.state.advancedSettings.countdown1_emote;
        this.formAnswer.countdown2_emote = Store.state.advancedSettings.time2_emote;
        this.formAnswer.thumbnail = Store.state.advancedSettings.thumbnail;
        this.formAnswer.classlimits = concatedlistclasslimits;
        this.formAnswer.rolelimits = concatedlistrolelimits;

        axios.post(Store.state.url + "/api/create/", this.formAnswer, config)
          .then(response => {
              this.createResponseText = response.data.status;
              this.color = "#4caf4f";
              this.createResponse = true;
              this.$router.push('/event/' + response.data.eventid)
          }).catch(error => {
            this.createResponseText = error.response.data.reason;
            this.color = "red";
            this.createResponse = true;
          }).finally(() => {
            this.loadingPostReq = false;
            this.formAnswer.schedule = "";
            this.formAnswer.softres = [];
			      Store.state.premiumFeatures.recurring = "";
			      Store.state.premiumFeatures.scheduleDate = "";
            Store.state.premiumFeatures.interval2Ordinal = "";
            Store.state.premiumFeatures.interval2Weekday = "";
          });
      } else {
        this.snackbarInvalidForm = true;
        this.loadingPostReq = false;
        this.$vuetify.goTo(0);
      }
    },
    goToDashboard() {
      window.location.href = "/";
    },

    async updateGuild() {
        this.req = {
          withCredentials: true,
          serverid: Store.state.chosenDiscord.id,
          accessToken: localStorage.getItem('accessToken').slice(1,-1),
        };

        let voiceChannelNameList = [];
        let channelNameList = [];
        let rolesList = [];
        axios.post(Store.state.url + "/api/server/",this.req)
        .then(response => {
          this.$store.state.currentGuild = response.data;
          try {
            Store.state.permissionLevel = response.data.permission;
            Store.state.chosenDiscord.premium = response.data.premium;
            this.isLoaded = true;
          if (response.data.reason === "missing permission") {
            Store.state.chosenDiscord.hasPerms = false;

          } else {
            Store.state.chosenDiscord.hasPerms = true;

            for (let index = 0; index < response.data.voicechannels.length; index++) {
              voiceChannelNameList.push(response.data.voicechannels[index]);
            }
            for (let index = 0; index < response.data.channels.length; index++) {
              channelNameList.push(response.data.channels[index]);
            }
            for (let index = 0; index < response.data.roles.length; index++) {
              rolesList.push(response.data.roles[index]);
            }

            Store.state.discordVoiceChannels = voiceChannelNameList;
            Store.state.discordChannels = channelNameList;
            Store.state.chosenDiscordRoles = rolesList;

            let advancedSettings = {};
            advancedSettings = response.data.advanced1;

            if (advancedSettings.font_style != 1 && advancedSettings.font_style != 2 && advancedSettings.font_style != 3) {
              advancedSettings.font_style = 0;
            }

            try {
              Store.state.settings = response.data.settings;
              Store.state.defaultAdvancedSettings.duration = advancedSettings.duration
              Store.state.defaultAdvancedSettings.deadline = advancedSettings.deadline
              Store.state.defaultAdvancedSettings.limit = JSON.parse(advancedSettings.limit)
              Store.state.defaultAdvancedSettings.lock_at_limit = JSON.parse(advancedSettings.lock_at_limit)
              Store.state.defaultAdvancedSettings.limit_per_user = JSON.parse(advancedSettings.limit_per_user)
              Store.state.defaultAdvancedSettings.lower_limit = JSON.parse(advancedSettings.lower_limit)
              Store.state.defaultAdvancedSettings.horizontal_mode = JSON.parse(advancedSettings.horizontal_mode)
              Store.state.defaultAdvancedSettings.bench_overflow = JSON.parse(advancedSettings.bench_overflow)
              Store.state.defaultAdvancedSettings.queue_bench = JSON.parse(advancedSettings.queue_bench)
              Store.state.defaultAdvancedSettings.vacuum = JSON.parse(advancedSettings.vacuum)
              Store.state.defaultAdvancedSettings.force_reminders = JSON.parse(advancedSettings.force_reminders)
              Store.state.defaultAdvancedSettings.create_thread = advancedSettings.create_thread
              Store.state.defaultAdvancedSettings.forum_tags = advancedSettings.forum_tags
              Store.state.defaultAdvancedSettings.delete_thread = JSON.parse(advancedSettings.delete_thread)
              Store.state.defaultAdvancedSettings.use_nicknames = JSON.parse(advancedSettings.use_nicknames)
              Store.state.defaultAdvancedSettings.create_discordevent = JSON.parse(advancedSettings.create_discordevent)
              Store.state.defaultAdvancedSettings.only_discordevent = JSON.parse(advancedSettings.only_discordevent)
              Store.state.defaultAdvancedSettings.pin_message = JSON.parse(advancedSettings.pin_message)
              Store.state.defaultAdvancedSettings.deletion = JSON.parse(advancedSettings.deletion)
              Store.state.defaultAdvancedSettings.mention_mode = JSON.parse(advancedSettings.mention_mode)
              Store.state.defaultAdvancedSettings.preserve_order = advancedSettings.preserve_order
              Store.state.defaultAdvancedSettings.event_type = advancedSettings.event_type
              Store.state.defaultAdvancedSettings.reminder = JSON.parse(advancedSettings.reminder)
              Store.state.defaultAdvancedSettings.apply_unregister = JSON.parse(advancedSettings.apply_unregister)
              Store.state.defaultAdvancedSettings.apply_specreset = JSON.parse(advancedSettings.apply_specreset)
              Store.state.defaultAdvancedSettings.show_on_overview = JSON.parse(advancedSettings.show_on_overview)
              Store.state.defaultAdvancedSettings.mention_leader = JSON.parse(advancedSettings.mention_leader)
              Store.state.defaultAdvancedSettings.spec_saving = JSON.parse(advancedSettings.spec_saving)
              Store.state.defaultAdvancedSettings.font_style = advancedSettings.font_style
              Store.state.defaultAdvancedSettings.alt_names = JSON.parse(advancedSettings.alt_names)
              Store.state.defaultAdvancedSettings.attendance = advancedSettings.attendance
              Store.state.defaultAdvancedSettings.show_title = JSON.parse(advancedSettings.show_title)
              Store.state.defaultAdvancedSettings.show_info = JSON.parse(advancedSettings.show_info)
              Store.state.defaultAdvancedSettings.show_leader = JSON.parse(advancedSettings.show_leader)
              Store.state.defaultAdvancedSettings.show_counter = JSON.parse(advancedSettings.show_counter)
              Store.state.defaultAdvancedSettings.show_roles = JSON.parse(advancedSettings.show_roles)
              Store.state.defaultAdvancedSettings.show_content = JSON.parse(advancedSettings.show_content)
              Store.state.defaultAdvancedSettings.show_classes = JSON.parse(advancedSettings.show_classes)
              Store.state.defaultAdvancedSettings.show_emotes = JSON.parse(advancedSettings.show_emotes)
              Store.state.defaultAdvancedSettings.show_numbering = JSON.parse(advancedSettings.show_numbering)
              Store.state.defaultAdvancedSettings.show_allowed = JSON.parse(advancedSettings.show_allowed)
              Store.state.defaultAdvancedSettings.show_banned = JSON.parse(advancedSettings.show_banned)
              Store.state.defaultAdvancedSettings.show_footer = JSON.parse(advancedSettings.show_footer)
              Store.state.defaultAdvancedSettings.defaults_pre_req = JSON.parse(advancedSettings.defaults_pre_req)
              Store.state.defaultAdvancedSettings.disable_archiving = JSON.parse(advancedSettings.disable_archiving)
              Store.state.defaultAdvancedSettings.bold_all = JSON.parse(advancedSettings.bold_all)
              Store.state.defaultAdvancedSettings.info_variant = advancedSettings.info_variant
              Store.state.defaultAdvancedSettings.date_variant = advancedSettings.date_variant
              Store.state.defaultAdvancedSettings.show_countdown = JSON.parse(advancedSettings.show_countdown)
              Store.state.defaultAdvancedSettings.allow_duplicate = JSON.parse(advancedSettings.allow_duplicate)
              Store.state.defaultAdvancedSettings.response = advancedSettings.response
              Store.state.defaultAdvancedSettings.opt_out = advancedSettings.opt_out
              
              Store.state.defaultAdvancedSettings.bench_emote = advancedSettings.bench_emote
              Store.state.defaultAdvancedSettings.late_emote = advancedSettings.late_emote
              Store.state.defaultAdvancedSettings.tentative_emote = advancedSettings.tentative_emote
              Store.state.defaultAdvancedSettings.absence_emote = advancedSettings.absence_emote
              
              Store.state.defaultAdvancedSettings.leader_emote = advancedSettings.leader_emote
              Store.state.defaultAdvancedSettings.signups1_emote = advancedSettings.signups1_emote
              Store.state.defaultAdvancedSettings.signups2_emote = advancedSettings.signups2_emote
              Store.state.defaultAdvancedSettings.date1_emote = advancedSettings.date1_emote
              Store.state.defaultAdvancedSettings.date2_emote = advancedSettings.date2_emote
              Store.state.defaultAdvancedSettings.time1_emote = advancedSettings.time1_emote
              Store.state.defaultAdvancedSettings.time2_emote = advancedSettings.time2_emote
              Store.state.defaultAdvancedSettings.countdown1_emote = advancedSettings.countdown1_emote
              Store.state.defaultAdvancedSettings.countdown2_emote = advancedSettings.countdown2_emote
              Store.state.defaultAdvancedSettings.thumbnail = advancedSettings.thumbnail
            } catch (error) {
              console.log(error);
            }

            try {
              Store.state.advancedSettings.duration = advancedSettings.duration
		          Store.state.advancedSettings.deadline = advancedSettings.deadline
              Store.state.advancedSettings.limit = JSON.parse(advancedSettings.limit)
              Store.state.advancedSettings.lock_at_limit = JSON.parse(advancedSettings.lock_at_limit)
              Store.state.advancedSettings.limit_per_user = JSON.parse(advancedSettings.limit_per_user)
              Store.state.advancedSettings.lower_limit = JSON.parse(advancedSettings.lower_limit)
              Store.state.advancedSettings.horizontal_mode = JSON.parse(advancedSettings.horizontal_mode)
              Store.state.advancedSettings.bench_overflow = JSON.parse(advancedSettings.bench_overflow)
              Store.state.advancedSettings.queue_bench = JSON.parse(advancedSettings.queue_bench)
              Store.state.advancedSettings.vacuum = JSON.parse(advancedSettings.vacuum)
              Store.state.advancedSettings.force_reminders = JSON.parse(advancedSettings.force_reminders)
              Store.state.advancedSettings.create_thread = advancedSettings.create_thread
              Store.state.advancedSettings.forum_tags = advancedSettings.forum_tags
              Store.state.advancedSettings.delete_thread = JSON.parse(advancedSettings.delete_thread)
              Store.state.advancedSettings.use_nicknames = JSON.parse(advancedSettings.use_nicknames)
              Store.state.advancedSettings.create_discordevent = JSON.parse(advancedSettings.create_discordevent)
              Store.state.advancedSettings.only_discordevent = JSON.parse(advancedSettings.only_discordevent)
              Store.state.advancedSettings.pin_message = JSON.parse(advancedSettings.pin_message)
              Store.state.advancedSettings.deletion = JSON.parse(advancedSettings.deletion)
              Store.state.advancedSettings.mention_mode = JSON.parse(advancedSettings.mention_mode)
              Store.state.advancedSettings.preserve_order = advancedSettings.preserve_order
              Store.state.advancedSettings.event_type = advancedSettings.event_type
              Store.state.advancedSettings.reminder = JSON.parse(advancedSettings.reminder)
              Store.state.advancedSettings.apply_unregister = JSON.parse(advancedSettings.apply_unregister)
              Store.state.advancedSettings.apply_specreset = JSON.parse(advancedSettings.apply_specreset)
              Store.state.advancedSettings.show_on_overview = JSON.parse(advancedSettings.show_on_overview)
              Store.state.advancedSettings.mention_leader = JSON.parse(advancedSettings.mention_leader)
              Store.state.advancedSettings.spec_saving = JSON.parse(advancedSettings.spec_saving)
              Store.state.advancedSettings.font_style = advancedSettings.font_style
              Store.state.advancedSettings.alt_names = JSON.parse(advancedSettings.alt_names)
              Store.state.advancedSettings.attendance = advancedSettings.attendance
              Store.state.advancedSettings.show_title = JSON.parse(advancedSettings.show_title)
              Store.state.advancedSettings.show_info = JSON.parse(advancedSettings.show_info)
              Store.state.advancedSettings.show_leader = JSON.parse(advancedSettings.show_leader)
              Store.state.advancedSettings.show_counter = JSON.parse(advancedSettings.show_counter)
              Store.state.advancedSettings.show_roles = JSON.parse(advancedSettings.show_roles)
              Store.state.advancedSettings.show_content = JSON.parse(advancedSettings.show_content)
              Store.state.advancedSettings.show_classes = JSON.parse(advancedSettings.show_classes)
              Store.state.advancedSettings.show_emotes = JSON.parse(advancedSettings.show_emotes)
              Store.state.advancedSettings.show_numbering = JSON.parse(advancedSettings.show_numbering)
              Store.state.advancedSettings.show_allowed = JSON.parse(advancedSettings.show_allowed)
              Store.state.advancedSettings.show_banned = JSON.parse(advancedSettings.show_banned)
              Store.state.advancedSettings.show_footer = JSON.parse(advancedSettings.show_footer)
              Store.state.advancedSettings.defaults_pre_req = JSON.parse(advancedSettings.defaults_pre_req)
              Store.state.advancedSettings.disable_archiving = JSON.parse(advancedSettings.disable_archiving)
              Store.state.advancedSettings.bold_all = JSON.parse(advancedSettings.bold_all)
              Store.state.advancedSettings.info_variant = advancedSettings.info_variant
              Store.state.advancedSettings.date_variant = advancedSettings.date_variant
              Store.state.advancedSettings.show_countdown = JSON.parse(advancedSettings.show_countdown)
              Store.state.advancedSettings.allow_duplicate = JSON.parse(advancedSettings.allow_duplicate)
              Store.state.advancedSettings.response = advancedSettings.response
              Store.state.advancedSettings.opt_out = advancedSettings.opt_out
              Store.state.advancedSettings.bench_emote = advancedSettings.bench_emote
              Store.state.advancedSettings.late_emote = advancedSettings.late_emote
              Store.state.advancedSettings.tentative_emote = advancedSettings.tentative_emote
              Store.state.advancedSettings.absence_emote = advancedSettings.absence_emote

              Store.state.advancedSettings.leader_emote = advancedSettings.leader_emote
              Store.state.advancedSettings.signups1_emote = advancedSettings.signups1_emote
              Store.state.advancedSettings.signups2_emote = advancedSettings.signups2_emote
              Store.state.advancedSettings.date1_emote = advancedSettings.date1_emote
              Store.state.advancedSettings.date2_emote = advancedSettings.date2_emote
              Store.state.advancedSettings.time1_emote = advancedSettings.time1_emote
              Store.state.advancedSettings.time2_emote = advancedSettings.time2_emote
              Store.state.advancedSettings.countdown1_emote = advancedSettings.countdown1_emote
              Store.state.advancedSettings.countdown2_emote = advancedSettings.countdown2_emote
              Store.state.advancedSettings.thumbnail = advancedSettings.thumbnail
            } catch (error) {
              console.log(error);
            }

            let enabledTemplates = response.data.enabledtemplates ? response.data.enabledtemplates : response.data.removedtemplates

            this.templates = [];
            for (let index = 0; index < this.$store.state.templates.length; index++) {
              if (enabledTemplates.includes(this.$store.state.templates[index].shortName)) {
                this.templates.push(this.$store.state.templates[index]);
              }
            }

            try {
              let customtemplates = [];
              customtemplates = response.data.customtemplates;

              for (let index = 0; index < customtemplates.length; index++) {
                   if (enabledTemplates.includes(customtemplates[index].shortName)) {
              
                for (let i = 0; i < customtemplates[index].classes.length; i++) {
                  if (customtemplates[index].classes[i].value === undefined) {
                    customtemplates[index].classes[i].value = 999;
                  }
                }
                for (let i = 0; i < customtemplates[index].roles.length; i++) {
                  if (customtemplates[index].roles[i].value === undefined) {
                    customtemplates[index].roles[i].value = 999;
                  }
                }
        
                for (let i = 0; i < this.templates.length; i++) {
                    if (this.templates[i].name.includes(customtemplates[index].name)) {
                      var string = " ";
                      customtemplates[index].name = customtemplates[index].name + string.repeat(index)
                    }
                }
                this.templates.push(customtemplates[index]);
                }
              }
            } catch (error) {
              console.log(error);
            }

            Store.state.loadedTemplates = this.templates;
            if (Store.state.loadedTemplates.length === 1) {
              Store.state.formAnswers.template = Store.state.loadedTemplates[0];
            }
        
        }

        this.isLoaded = true;
        } catch (error) { 
          console.log(error)
        }
        }).catch(error => {
          if (error.response.status === 429) {
            this.createResponseText = error.response.data.reason;
            this.color = "red";
            this.createResponse = true;
          } else if (error.response.status === 401) {
            window.location.href = this.$store.state.url + "/api/auth/discord";
          }
        }
        )}
  },

  computed: {
    ...mapActions(["fetchDiscordInfo"]),
  },

  created() {
    this.$root.$refs.Form = this;
    this.formAnswer.time = this.getCurrentTime();
    this.updateGuild();

	window.addEventListener('beforeunload', function(event) {
         localStorage.setItem(
          "formAnswers-" + Store.state.chosenDiscord.id,
          JSON.stringify(Store.state.formAnswers)
        );
      })

  },
  beforeDestroy() {
        localStorage.setItem(
          "formAnswers-" + Store.state.chosenDiscord.id,
          JSON.stringify(Store.state.formAnswers)
        );
  },

  watch: {
    server: function (newValue) {
      this.formAnswer.serverid = Store.state.chosenDiscord.id;
    },

    "$store.state.chosenDiscord.id": function () {
      this.isLoaded = false;
      this.updateGuild();
    },
  },
};
</script>

<style>
.v-text-field--outlined fieldset {
  border: 0px solid rgba(255, 255, 255, 0) !important;
}

.theme--light.v-list {
  background: #313338;
}
.theme--light.v-list-item:hover:before {
  opacity: 0.2;
}

.theme--light.v-list-item--active:before {
  opacity: 0;
}
.theme--light.v-list-item--active:hover:before {
  opacity: 0.2;
}
.theme--light.v-list-item:focus:before {
  opacity: 0;
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: white !important;
}

.v-application .primary--text {
  color: #f7f7fb !important;
  caret-color: #ffffff !important;
}

.field.is-dark .field-input[data-v-5b500588] {
  background-color: #43434e !important;
  border-color: rgb(230, 25, 25) !important;
  color: hsla(0, 0%, 100%, 1) !important;
}
.field.is-dark .field[data-v-5b500588] {
  background-color: #43434e !important;
  border-color: rgb(218, 13, 13) !important;
  color: hsla(0, 0%, 100%, 1) !important;
}
.date-time-picker .flex-fixed {
  background-color: #3b3e45 !important;
  border-left: solid 10px #3b3e45;
}

.time-picker.with-border.is-dark[data-v-5bc85983] {
  border: solid 10px #3b3e45;
}
.header-picker .pl-10[data-v-6d49f11d] {
  background-color: transparent !important;
}
.datepicker-buttons-container.is-dark
  .datepicker-button[data-v-601c6e79]:not(.now),
.datepicker-buttons-container.is-dark[data-v-601c6e79]:not(.now) {
  background-color: #43434e !important;
}
.datepicker-buttons-container
  .datepicker-button.now.right-margin[data-v-601c6e79] {
  background-color: #43434e !important;
}
.datepicker-container .padding-button[data-v-7043ad7f] {
  background-color: transparent !important;
}

.vcard-templates:hover {
  background-color: rgb(51, 51, 58) !important;
  border-top: 1px solid rgb(167, 164, 164) !important;
}

.vcard-templates {
  background-color: accent !important;
  border-top: 1px solid grey !important;
}

.v-slide-group__next {
  margin-left: 20px;
}

.v-slide-group__prev {
  margin-right: 20px;
}



</style>
