<template>
	<v-container justify="center">
		<v-layout row fluid>
			<v-flex xs0 sm0 md0 lg0 xl0> </v-flex>
			<v-flex  xs12 sm12 md12 lg12 xl12 class="my-5 rounded-lg" :class="$vuetify.breakpoint.xs ? 'mt-1' : 'mt-10'">
				<Calendar />
			</v-flex>
			<v-flex xs0 sm0 md0 lg0 xl0> </v-flex>
			
		</v-layout>
	</v-container>
</template>

<script>
import Calendar from '../components/event_selection/Calendar.vue'

export default {
    name: 'Calendarv',
    components: {Calendar,},
  	methods: {
  	},
	data() {
    	return {
      		opacityValue: "0",
		}
	},
	mounted() {
		setTimeout(this.showAd, 600);
	}

}
</script>
<style>
    
</style>