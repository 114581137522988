import Vue from "vue"
import Store from '../store/index'
import VueRouter from "vue-router"
import Create from "../views/Create.vue"
import Settings from "../views/Settings.vue"
import Dashboard from "../views/Dashboard.vue"
import Landingpage from "../views/Landingpage.vue"
import Introduction from "../views/Intro.vue"
import Privacypolicy from "../views/Privacypolicy"
import Termsofservice from "../views/Termsofservice"
import Commands from "../views/Commands"
import Advanced from "../views/Advanced"
import Reference from "../views/Reference"
import Guides from "../views/Guides"
import API from "../views/API"
import Premium from "../views/Premium"
import Thankyou from "../views/Thankyou"
import Upcoming from "../views/Upcoming"
import Schedule from "../views/Schedule"
import Closed from "../views/Closed"
import DashboardEvent from "../views/DashboardEvent"
import Raidplan from "../views/Raidplan"
import Tools from "../views/Tools"
import Calendar from "../views/Calendar"
import Embed from "../views/Embed"

Vue.use(VueRouter);

const routes = [{
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
        beforeEnter: (to, from, next) => {
            Store.dispatch('fetchDiscordInfo')
                .catch((error) => next('/'))
                .finally((res) => {
                    if (localStorage.getItem('discordUser') !== null) {
                        Store.state.authenticated = true
                        if (localStorage.getItem('lastPage') !== null) {
                            next(localStorage.getItem('lastPage'))
                        }
                        next()
                    } else {
                        Store.state.authenticated = false
                        next('/')
                    }
                })
        },
    },

    {
        path: "/",
        name: "Landingpage",
        component: Landingpage,
        beforeEnter: (to, from, next) => {
            localStorage.removeItem('lastPage')
            if (localStorage.getItem('discordUser') !== null) {
                Store.state.authenticated = true
                next()
            } else {
                next()
            }
        },
    },

    {
        path: "/documentation/intro/",
        name: "Intro",
        component: Introduction,
        beforeEnter: (to, from, next) => {
            localStorage.removeItem('lastPage')
            if (localStorage.getItem('discordUser') !== null) {
                Store.state.authenticated = true
                next()
            } else {
                next()
            }
        },
    },
    {
        path: "/documentation/guides/",
        name: "Guides",
        component: Guides,
        beforeEnter: (to, from, next) => {
            localStorage.removeItem('lastPage')
            if (localStorage.getItem('discordUser') !== null) {
                Store.state.authenticated = true
                next()
            } else {
                next()
            }
        },
    },
    {
        path: "/create/",
        name: "Create",
        component: Create,
        beforeEnter: (to, from, next) => {
            localStorage.removeItem('lastPage')
            if (localStorage.getItem('discordUser') !== null || localStorage.getItem('chosenDiscord') !== null) {
                Store.state.authenticated = true
                next()
            } else {
                next('/')
            }

        },
    },

    {
        path: "/embed/",
        name: "Embed",
        component: Embed,
        beforeEnter: (to, from, next) => {
            localStorage.removeItem('lastPage')
            if (localStorage.getItem('discordUser') !== null || localStorage.getItem('chosenDiscord') !== null) {
                Store.state.authenticated = true
                next()
            } else {
                next('/')
            }

        },
    },

    {
        path: "/settings/",
        name: "Settings",
        component: Settings,
        beforeEnter: (to, from, next) => {
            localStorage.removeItem('lastPage')
            if (localStorage.getItem('discordUser') !== null || localStorage.getItem('chosenDiscord') !== null) {
                Store.state.authenticated = true
                next()
            } else {
                next('/')
            }

        },
    },

    {
        path: "/upcoming/",
        name: "upcoming",
        component: Upcoming,
        beforeEnter: (to, from, next) => {
            localStorage.removeItem('lastPage')
            if (localStorage.getItem('discordUser') !== null) {
                Store.state.authenticated = true
                next()
            } else {
                next('/')
            }

        },
    },

    {
        path: "/schedule/",
        name: "schedule",
        component: Schedule,
        beforeEnter: (to, from, next) => {
            localStorage.removeItem('lastPage')
            if (localStorage.getItem('discordUser') !== null) {
                Store.state.authenticated = true
                next()
            } else {
                next('/')
            }

        },
    },

    {
        path: "/closed/",
        name: "closed",
        component: Closed,
        beforeEnter: (to, from, next) => {
            localStorage.removeItem('lastPage')
            if (localStorage.getItem('discordUser') !== null) {
                Store.state.authenticated = true
                next()
            } else {
                next('/')
            }

        },
    },

    {
        path: "/calendar/:serverId",
        name: "Calendar",
        component: Calendar,
        beforeEnter: (to, from, next) => {
            localStorage.removeItem('lastPage')
            if (localStorage.getItem('discordUser') !== null) {
                Store.state.authenticated = true
                next()
            } else {
                localStorage.setItem('lastPage', to.path)
                location.href = Store.state.url + "/api/auth/discord"
            }
        },
    },


    {
        path: "/raidplan/:hash",
        name: "raidplan",
        component: Raidplan,
        beforeEnter: (to, from, next) => {
            localStorage.removeItem('lastPage')
            if (localStorage.getItem('discordUser') !== null) {
                Store.state.authenticated = true
                next()
            } else {
                next()
            }
        },

    },
    {
        path: "/raidplan",
        name: "raidplanNew",
        component: Raidplan,
        beforeEnter: (to, from, next) => {
            localStorage.removeItem('lastPage')
            if (localStorage.getItem('discordUser') !== null) {
                Store.state.authenticated = true
                next()
            } else {
                next()
            }
        },
    },

    {
        path: "/event/:raidId",
        name: "selectedEvent",
        component: DashboardEvent,
        beforeEnter: (to, from, next) => {
            localStorage.removeItem('lastPage')
            if (localStorage.getItem('discordUser') !== null) {
                Store.state.authenticated = true
                next()
            } else {
                next()
            }

        },
    },

    {
        path: "/Tools/",
        name: "Tools",
        component: Tools,
        beforeEnter: (to, from, next) => {
            localStorage.removeItem('lastPage')
            if (localStorage.getItem('discordUser') !== null) {
                Store.state.authenticated = true
                next()
            } else {
                next()
            }

        },
    },
    {
        path: "/documentation/commands/",
        name: "Commands",
        component: Commands,
        beforeEnter: (to, from, next) => {
            localStorage.removeItem('lastPage')
            if (localStorage.getItem('discordUser') !== null) {
                Store.state.authenticated = true
                next()
            } else {
                next()
            }

        },
    },
    {
        path: "/documentation/advanced/",
        name: "Advanced",
        component: Advanced,
        beforeEnter: (to, from, next) => {
            localStorage.removeItem('lastPage')
            if (localStorage.getItem('discordUser') !== null) {
                Store.state.authenticated = true
                next()
            } else {
                next()
            }

        },
    },
    {
        path: "/documentation/api/",
        name: "API",
        component: API,
        beforeEnter: (to, from, next) => {
            localStorage.removeItem('lastPage')
            if (localStorage.getItem('discordUser') !== null) {
                Store.state.authenticated = true
                next()
            } else {
                next()
            }

        },
    },
    {
        path: "/documentation/reference/",
        name: "Reference",
        component: Reference,
        beforeEnter: (to, from, next) => {
            localStorage.removeItem('lastPage')
            if (localStorage.getItem('discordUser') !== null) {
                Store.state.authenticated = true
                next()
            } else {
                next()
            }

        },
    },

    {
        path: "/Termsofservice/",
        name: "Termsofservice",
        component: Termsofservice,
        beforeEnter: (to, from, next) => {
            localStorage.removeItem('lastPage')
            if (localStorage.getItem('discordUser') !== null) {
                Store.state.authenticated = true
                next()
            } else {
                next()
            }

        },
    },
    {
        path: "/Privacypolicy/",
        name: "Privacypolicy",
        component: Privacypolicy,
        beforeEnter: (to, from, next) => {
            localStorage.removeItem('lastPage')
            if (localStorage.getItem('discordUser') !== null) {
                Store.state.authenticated = true
                next()
            } else {
                next()
            }

        },
    },

    {
        path: "/premium/",
        name: "premium",
        component: Premium,
        beforeEnter: (to, from, next) => {
            localStorage.removeItem('lastPage')
            if (localStorage.getItem('discordUser') !== null) {
                Store.state.authenticated = true
                next()
            } else {
                next()
            }

        },
    },
    {
        path: "/premium/:clientReferenceId",
        name: "thankyou",
        component: Thankyou,
        beforeEnter: (to, from, next) => {
            localStorage.removeItem('lastPage')
            if (localStorage.getItem('discordUser') !== null) {
                Store.state.authenticated = true
                next()
            } else {
                next()
            }
        },

    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;