<template>
  <v-container v-if="$store.state.authenticated" class="pa-1">
      <div v-if="$store.state.chosenDiscord === undefined">
        <v-col cols="12" class="mt-sm-n10 mt-n1 mb-sm-4 mb-4" style="background-color: #1d1d21; border-radius: 3px; height: 44px;">
          <v-row justify="space-between">
            <v-col>
          <div class="mt-n1" style="white-space:nowrap; color: white; font-size: 1.3em; text-align: center; height: 20px;">Servers</div>
            </v-col>

          </v-row>
        </v-col>

      <v-row v-for="item in $store.state.discordGuilds" :key="item.id" class="mx-0">
        <v-btn elevation="0" :ripple="false" style="border-radius: 3px; color: white;" class="mb-1 btn" x-large block @click=" chosenDiscord = item; updateGuild();" color="#202025" tile>
          <v-container class="btn-container mt-1" name="premium btn" style="font-weight: 400;" :style="$vuetify.breakpoint.xs ? 'font-size: 0.7em;' : ''">
            <v-avatar width="40" height="40" class="mr-5 mb-1">
              <img :src="item.icon" width="40" height="40" />
            </v-avatar>
            {{ truncate(item.name) }}
          </v-container>
          <v-spacer></v-spacer>
          <v-btn elevation="0" v-if="item.premium" outlined color="green"><v-icon color="green">mdi-crown-outline</v-icon></v-btn>
        </v-btn>
      </v-row>
      </div>

        <v-col v-if="$store.state.chosenDiscord !== undefined" cols="12" class="mt-sm-n10 mt-n1 mb-sm-9 mb-3" style="background-color: #1d1d21; border-radius: 3px;">
          <v-row justify="space-between">
            <v-col>
              <div style="height: 20px;">
              
              <v-tooltip transition="v-scale-transition" color="transparent" right nudge-left="30px">
							<template v-slot:activator="{ on, attrs }">
                <v-btn elevation="0" v-bind="attrs" v-on="on" small left @click="$store.state.chosenDiscord = undefined; deleteGuild(); fetchMutuals();" :ripple="false" plain class="mt-n1 ml-n3" color="#484850"><v-icon size="30">mdi-arrow-left-circle</v-icon></v-btn>
              </template><span style="color: grey;">Go back</span>
						</v-tooltip>
              </div></v-col>
                        <v-spacer></v-spacer>
            <v-col>
          <div class="mt-n1" style="white-space:nowrap; color: white; font-size: 1.3em; text-align: center; height: 20px;">Dashboard</div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
              <div style="text-align: right; height: 20px;">
          						<v-tooltip transition="v-scale-transition" color="transparent" left nudge-right="30px">
							<template v-slot:activator="{ on, attrs }">
					<v-btn elevation="0" v-bind="attrs" v-on="on" class="mr-n3 mt-n2" :ripple="false" style="cursor: default;" plain>
          <v-avatar class="" width="30" height="30" min-width="30" min-height="30">
            <img :src="$store.state.chosenDiscord.icon" alt="DiscordAvatar" />
          </v-avatar>
          </v-btn>
              </template><span style="color: grey;">{{$store.state.chosenDiscord.name}}</span>
						</v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-col>
    <v-row class="mx-0 mt-n2 mt-sm-n8" v-if="$store.state.chosenDiscord !== undefined" justify="space-between">

        

        <v-btn elevation="0" :ripple="false" @click="$router.push('/create/', () => {})" style="border-radius: 3px; color: white;" class="mb-1 btn" x-large block color="#202025" tile>
          <v-container class="btn-container mt-1" name="premium btn" style="font-weight: 400;" :style="$vuetify.breakpoint.xs ? 'font-size: 0.7em;' : ''">
            <v-avatar width="40" height="40" class="mr-5 mb-1"><v-icon width="40" height="40">mdi-calendar-edit</v-icon></v-avatar>
            Create Event
          </v-container>
        </v-btn>
        
        <v-btn elevation="0" :ripple="false" @click="$router.push('/embed/', () => {})" style="border-radius: 3px; color: white;" class="mb-1 btn" x-large block color="#202025" tile>
          <v-container class="btn-container mt-1" name="premium btn" style="font-weight: 400;" :style="$vuetify.breakpoint.xs ? 'font-size: 0.7em;' : ''">
            <v-avatar width="40" height="40" class="mr-5 mb-1"><v-icon width="40" height="40">mdi-calendar-edit</v-icon></v-avatar>
            Create Embed
          </v-container>
        </v-btn>

        <v-btn elevation="0" :ripple="false" @click="goToCalendar()" style="border-radius: 3px; color: white;" class="mb-1 btn" x-large block color="#202025" tile>
          <v-container class="btn-container mt-1" name="premium btn" style="font-weight: 400;" :style="$vuetify.breakpoint.xs ? 'font-size: 0.7em;' : ''">
            <v-avatar width="40" height="40" class="mr-5 mb-1"><v-icon width="40" height="40">mdi-calendar</v-icon></v-avatar>
            Calendar
          </v-container>
        </v-btn>

        <v-btn elevation="0" :ripple="false" @click="$router.push('/upcoming/', () => {})" style="border-radius: 3px; color: white;" class="mb-1 btn" x-large block color="#202025" tile>
          <v-container class="btn-container mt-1" name="premium btn" style="font-weight: 400;" :style="$vuetify.breakpoint.xs ? 'font-size: 0.7em;' : ''">
            <v-avatar width="40" height="40" class="mr-5 mb-1"><v-icon width="40" height="40">mdi-calendar-check</v-icon></v-avatar>
            Current Events
          </v-container>
        </v-btn>

        <v-btn elevation="0" :ripple="false" @click="$router.push('/closed/', () => {})" style="border-radius: 3px; color: white;" class="mb-1 btn" x-large block color="#202025" tile>
          <v-container class="btn-container mt-1" name="premium btn" style="font-weight: 400;" :style="$vuetify.breakpoint.xs ? 'font-size: 0.7em;' : ''">
            <v-avatar width="40" height="40" class="mr-5 mb-1"><v-icon width="40" height="40">mdi-calendar-remove</v-icon></v-avatar>
            Past Events
          </v-container>
        </v-btn>

        <v-btn elevation="0" :ripple="false" @click="$router.push('/schedule/', () => {})" style="border-radius: 3px; color: white;" class="mb-1 btn" x-large block color="#202025" tile>
          <v-container class="btn-container mt-1" name="premium btn" style="font-weight: 400;" :style="$vuetify.breakpoint.xs ? 'font-size: 0.7em;' : ''">
            <v-avatar width="40" height="40" class="mr-5 mb-1"><v-icon width="40" height="40">mdi-calendar-clock</v-icon></v-avatar>
            Scheduled Events
          </v-container>
        </v-btn>

        <v-btn elevation="0" :ripple="false" @click="$router.push('/settings/', () => {})" style="border-radius: 3px; color: white;" class="mb-1 btn" x-large block color="#202025" tile>
          <v-container class="btn-container mt-1" name="premium btn" style="font-weight: 400;" :style="$vuetify.breakpoint.xs ? 'font-size: 0.7em;' : ''">
            <v-avatar width="40" height="40" class="mr-5 mb-1"><v-icon width="40" height="40">mdi-cog</v-icon></v-avatar>
            Settings
          </v-container>
        </v-btn>

    </v-row>

   

  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import DashNavi from "../components/DashNavi";

export default {
      components: {
        DashNavi
      },
  name: "Dashboard",
  data() {
    return {
      guildsLoaded: false,
      snackbarInvalidForm: false,
      snackbarInvalidFormTimeout: 4000,
      chosenDiscord: undefined,
      selectDiscord: false,
    };
  },

  methods: {
    goToCalendar(){
      this.$router.push({ path: '/calendar/' + this.$store.state.chosenDiscord.id  })
    },

    fetchMutuals() {
      this.fetchDiscordInfo;
    },

    inviteBot() {
      window.open(
        "https://discord.com/oauth2/authorize?client_id=579155972115660803&permissions=403995880640&scope=bot%20applications.commands",
        "_blank"
      );
    },

    async updateGuild() {
      this.$store.commit("setChosenDiscord", this.chosenDiscord);
      this.selectDiscord = true;
    },
    truncate(input) {
      if (input.length > 17 && this.$vuetify.breakpoint.xs) {
        return input.substring(0, 17) + "..";
      }
      return input;
    },
    async deleteGuild() {
      localStorage.removeItem("chosenDiscord");
      this.$store.state.upcomingCache = undefined;
      this.$store.state.closedCache = undefined;
      this.$store.state.scheduledCache = undefined;
      this.$store.state.calendarCache = undefined;
    },
  },

  computed: {
			...mapActions(["fetchDiscordInfo"]),
		},

  created() {
    document.title = "Dashboard"
  },

  mounted() {
    
  },

  watch: {},
};
</script>

<style>
.btn-row {
  margin: 6px;
  border-left: solid;
  border-color: rgb(162, 163, 162);
  border-width: 1px;
  border-left-width: 5px;
  background: rgb(203, 210, 215);
  background: linear-gradient(
    90deg,
    rgb(131, 142, 150) 0%,
    rgba(173, 200, 218, 0.423) 0%,
    rgba(202, 211, 217, 0.47102591036414565) 86%
  );
}

.btn-row-premium {
  margin: 6px;
  border: solid;
  border-left: solid;
  border-color: rgb(96, 100, 96);
  border-width: 1px;
  border-left-width: 5px;
  background: rgb(203, 210, 215);
  background: linear-gradient(
    90deg,
    rgb(131, 142, 150) 0%,
    rgba(173, 200, 218, 0.423) 0%,
    rgba(202, 211, 217, 0.47102591036414565) 86%
  );
}

.btn-container {
  text-align: left;
}

.btn-inside {
  text-align: right;
}
.btn {
  text-transform: unset !important;
}

.btn::before {
  color: #3d4046 !important;
}


#dashbordSelect:hover {
  border-color: green !important;
}

.vcard-dashboard:hover {
  background-color: #3e3e44 !important;
  transform: scale(1.01);
  border: 1px solid black !important;

}
.vcard-dashboard {
  border: 1px solid #101012 !important;
  height: 180px !important;
}

</style>
