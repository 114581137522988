<template>
        <v-col cols="12" class="mt-sm-n10 mt-n1 mb-sm-9 mb-4" style="background-color: #1d1d21; border-radius: 3px;">
          <v-row justify="space-between">
            <v-col>
              <div style="height: 20px;">
              
              <v-tooltip transition="v-scale-transition" color="transparent" right nudge-left="30px">
							<template v-slot:activator="{ on, attrs }">
                <v-btn elevation="0" v-bind="attrs" v-on="on" small left @click="$router.push(route)" :ripple="false" plain class="mt-n1 ml-n3" color="#484850"><v-icon size="30">mdi-arrow-left-circle</v-icon></v-btn>
              </template><span style="color: grey;">Go back</span>
						</v-tooltip>
              </div></v-col>
                        <v-spacer></v-spacer>
            <v-col>
          <div class="mt-n1" style="white-space:nowrap; color: white; font-size: 1.3em; text-align: center; height: 20px;">{{title}}</div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
              <div style="text-align: right; height: 20px;">
          						<v-tooltip transition="v-scale-transition" color="transparent" left nudge-right="30px">
                     
							<template v-slot:activator="{ on, attrs }">
					<v-btn elevation="0" v-bind="attrs" v-on="on" class="mr-n3 mt-n2" :ripple="false" style="cursor: default;" plain>
          <v-avatar class="" width="30" height="30" min-width="30" min-height="30">
          
            <img :src="$store.state.chosenDiscord.icon" alt="DiscordAvatar" />
          
          </v-avatar>
          </v-btn>
              </template><span style="color: grey;">{{$store.state.chosenDiscord.name}}</span>
						</v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-col>
</template>
<script>

export default {
      props: {
  	    title: {
            type: String,
  	    },
        route: {
            type: String,
        }
    },
    data() {
        return {

        }
    },
    methods:{

    }

    
}
</script>
<style>
    
</style>