<template>
    <v-card v-if="$store.state.chosenDiscord.hasPerms" dark color="primary" elevation="2" class="cardFont px-1">
      <v-row justify="space-between" no-gutters class="mt-3 mt-sm-n8 pt-sm-3">
            
    
        <v-col cols="12">
          <v-container>
            
            <v-form v-model="valid">
              <v-row>
              <v-col cols="12" md="6" class="pr-1 pl-1 pl-sm-3 pr-sm-3" style="margin-bottom: -26px;" >

              <v-row justify="space-around" class="pa-0 mb-n6 mt-n5">
                <v-col cols="12" class="pa-0" style="z-index: 7; ">
                  

                      
                      <v-col align="center">
    
                        <div style="font-size: .8em; color: #e3e3e3;">Date & Time<span style="color: red;"> *</span></div>
                          <v-hover v-slot="{ hover }"><div style="font-weight: 700; background-color: #313338; display: inline-block; height: 36px; border-radius: 3px; cursor: pointer; padding-top: 6px;" :style="hover && $vuetify.breakpoint.xs ? 'background-color: #373a40; font-size: 0.7em;' : hover ? 'background-color: #373a40;' : $vuetify.breakpoint.xs ? 'font-size: 0.7em;' : ''" @click="overlay1 = true; overlayShadow = false;" class="px-2"><div><v-icon style="">mdi-calendar</v-icon> {{getDate()}} <v-icon class="ml-1 mt-n1">mdi-clock-outline</v-icon> {{ getTime() }} <span style="font-weight: 400;">{{ getEndTime() }}</span></div></div></v-hover><br>
                        <div style="font-size: 0.8em; color: grey; display: inline-block;">{{$store.state.settings.timezone}}</div>
                      </v-col>
                     
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col class="pa-0 mb-n2">

                      <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Template<span style="color: red;"> *</span></div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;"><v-btn elevation="0" @click="overlay = true;" x-small plain :ripple="false" class="mr-n2" ><v-icon small color="green" class="mt-n1">mdi-comment-question</v-icon></v-btn></div>

                        </div>
                        <v-select @click="overlayShadow = false;" hide-details item-text="shortName" return-object dense style="z-index: 7;" item-color="green" solo :rules="rules" v-model="$store.state.formAnswers.template" :items="$store.state.loadedTemplates" label="Select a template for your event." background-color="#313338" flat>
                          <template v-slot:selection="{ item }" >
                            <v-avatar tile width="20" height="20">
                              <img :src="'https://cdn.discordapp.com/emojis/' + item.image + '.png?v=1'" width="20" height="20" class="ml-n7" />
                            </v-avatar>
                            <span class="ml-n5" style="font-size: 14px;">{{ item.name }}</span>
                          </template>
                          <template v-slot:item="{ item }" >
                            <v-avatar tile width="20" height="20">
                              <img :src="'https://cdn.discordapp.com/emojis/' + item.image + '.png?v=1'" width="20" height="20" class="ml-n7" />
                            </v-avatar>
                           <span class="ml-n5" style="font-size: 14px;">{{ item.name }}</span>
                          </template>
                        </v-select>
                      </v-col>

                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col class="pa-0 mb-n1">

                      <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Channel<span style="color: red;"> *</span></div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;"><v-btn elevation="0" x-small plain :ripple="false" class="mr-n2" @click="tooltipTitle = 'Channel'; tooltipText = 'The channel the event will be posted to initially. You can change the channel later by using the /move command.'; tooltipOverlay = true;"><v-icon small color="green" class="mt-n1">mdi-comment-question</v-icon></v-btn></div>
                        </div>
                        <v-select @click="overlayShadow = false;" item-text="id" hide-details dense return-object style="z-index: 7" item-color="green" solo :rules="rules" v-model="$store.state.formAnswers.channel" :items="getEventChannels()" label="Select the channel to post your event to." background-color="#313338" flat>
                          <template v-slot:selection="{ item }" >
                            <v-avatar tile width="20" height="20">
                              <img :src="'https://cdn.discordapp.com/emojis/' + getChannelIconByType(item.type) + '.png?v=1'" width="20" height="20" class="ml-n7" />
                            </v-avatar>
                             <span class="ml-n5" style="font-size: 14px;">{{ item.name }}</span>
                          </template>
                          <template v-slot:item="{ item }" > 
                            <v-avatar tile width="20" height="20">
                              <img :src="'https://cdn.discordapp.com/emojis/' + getChannelIconByType(item.type) + '.png?v=1'" width="20" height="20" class="ml-n7" />
                            </v-avatar>
                            <span class="ml-n5" style="font-size: 14px;">{{ item.name }}</span>
                          </template>
                        </v-select>
                      </v-col>

                </v-col>
              </v-row>

              <v-row v-if="isPoll() === false" class="" justify="space-between">
                <v-col class="pa-0 mb-4 mt-n1">
                      <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Title</div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;">
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n4" @click="$store.state.formAnswers.title = ''" ><v-icon small color="red darken-2" class="mt-n1">mdi-trash-can-outline</v-icon></v-btn>
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n2" @click="tooltipTitle = 'Title'; tooltipText = 'The title can be a maximum of 200 characters long. If you want your title to be displayed in emote letters, make your title shorter than 19 characters.'; tooltipOverlay = true;"><v-icon small color="green" class="mt-n1">mdi-comment-question</v-icon></v-btn></div>

                        </div>
                        <v-text-field style="font-size: 14px;" hide-details spellcheck="false" dense solo v-model="$store.state.formAnswers.title" label="Enter a title." background-color="#313338" flat>
                        </v-text-field>
                      </v-col>
                </v-col>
              </v-row>
              <v-row v-if="isPoll() === false">
                <v-col class="pa-0 mt-n6">
                      <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Description</div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;">
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n4" @click="$store.state.formAnswers.description = ''" ><v-icon small color="red darken-2" class="mt-n1">mdi-trash-can-outline</v-icon></v-btn>
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n2" @click="tooltipTitle = 'Description'; tooltipText = 'The description can be a maximum of 4000 characters long. You can use markdown here including masked links: [text](url) and mentions: {Raider}'; tooltipOverlay = true;"><v-icon small color="green" class="mt-n1">mdi-comment-question</v-icon></v-btn></div>

                        </div>
                        <v-textarea style="font-size: 14px;" flat solo spellcheck="false" v-model="$store.state.formAnswers.description" background-color="#313338" label="Enter a description." rows="6"></v-textarea>
                      </v-col>
                </v-col>
              </v-row>

              <v-row v-if="isPoll() === true" class="" justify="space-between">
                <v-col class="pa-0 mb-4 mt-n1">
                      <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Question</div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;">
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n4" @click="$store.state.formAnswers.pollQuestion = ''" ><v-icon small color="red darken-2" class="mt-n1">mdi-trash-can-outline</v-icon></v-btn>
                        <v-btn elevation="0" x-small plain :ripple="false" class="mr-n2" @click="tooltipTitle = 'Question'; tooltipText = 'The question for your poll.'; tooltipOverlay = true;"><v-icon small color="green" class="mt-n1">mdi-comment-question</v-icon></v-btn></div>

                        </div>
                        <v-text-field style="font-size: 14px;" hide-details spellcheck="false" dense solo v-model="$store.state.formAnswers.pollQuestion" label="Enter a Question." background-color="#313338" flat>
                        </v-text-field>
                      </v-col>

                </v-col>
              </v-row>
          
               <v-row v-if="isStrawpoll() === true" class="mt-n2 mb-6" justify="space-between">
                                      
                <div style="float: left; font-size: .8em; color: #e3e3e3;" class="ml-4 mb-n2">Answers</div>
                                        
                <v-col v-for="n in Math.min($store.state.formAnswers.pollAnswers.length+1, 18)" :key="n" class="pa-0 mb-n8 mt-n1" cols="12">
                      <v-col>
                        <v-text-field style="font-size: 14px;" outlined dense append-icon="mdi-minus" tabindex="1" v-model="$store.state.formAnswers.pollAnswers[n-1]" background-color="#313338" flat rows="1" @click:append="clearPollAnswer(n-1)">
                        </v-text-field>
                      </v-col>

                </v-col>
              </v-row>
              </v-col>

              <v-col cols="12" md="6" class="pl-1 pr-1 pr-sm-3 pb-2 pl-sm-2 mb-4 mb-sm-6">


    <v-row class="mb-2 mt-n2 ml-0 mr-0">
        <v-btn-toggle borderless tile background-color="transparent">
          <v-btn elevation="0" style="border-radius: 4px 4px 0px 0px;" plain small @click="radio = 0" :style="radio === 0 ? 'background-color: #27272b;' : ''" :class="$vuetify.breakpoint.xs ? 'btn px-2 mr-n2' : 'btn px-4'" tile :ripple="false" text><v-icon :color="radio === 0 ? 'white' : 'grey lighten-2'" small>mdi-cog</v-icon></v-btn>
          <v-btn elevation="0" style="border-radius: 4px 4px 0px 0px;" plain small @click="radio = 1" :style="radio === 1 ? 'background-color: #27272b;' : ''" :class="$vuetify.breakpoint.xs ? 'btn px-2 mr-n2' : 'btn px-4'" tile :ripple="false" text><v-icon :color="radio === 1 ? 'orange' : 'grey lighten-2'" small>mdi-bullhorn-variant</v-icon></v-btn>
          <v-btn elevation="0" style="border-radius: 4px 4px 0px 0px;" plain small @click="radio = 2" :style="radio === 2 ? 'background-color: #27272b;' : ''" :class="$vuetify.breakpoint.xs ? 'btn px-2 mr-n2' : 'btn px-4'" tile :ripple="false" text><v-icon :color="radio === 2 ? '#e907ff' : 'grey lighten-2'" size="20" >mdi-crown</v-icon></v-btn>
          <v-btn elevation="0" style="border-radius: 4px 4px 0px 0px;" plain small @click="radio = 3" :style="radio === 3 ? 'background-color: #27272b;' : ''" :class="$vuetify.breakpoint.xs ? 'btn px-2 mr-n2' : 'btn px-4'" tile :ripple="false" text><v-icon :color="radio === 3 ? 'yellow' : 'grey lighten-2'" small>mdi-arrow-collapse-up</v-icon></v-btn>
          <v-btn elevation="0" style="border-radius: 4px 4px 0px 0px;" plain small @click="radio = 4" :style="radio === 4 ? 'background-color: #27272b;' : ''" :class="$vuetify.breakpoint.xs ? 'btn px-2 mr-n2' : 'btn px-4'" tile :ripple="false" text><v-icon :color="radio === 4 ? 'green' : 'grey lighten-2'" small>mdi-tune</v-icon></v-btn>
          <v-btn elevation="0" style="border-radius: 4px 4px 0px 0px;" v-if="isWoW()" plain small @click="radio = 5" :style="radio === 5 ? 'background-color: #27272b;' : ''" :class="$vuetify.breakpoint.xs ? 'btn px-2 mr-n2' : 'btn px-4'" tile :ripple="false" text><v-img :src="radio === 5 ? 'https://cdn.discordapp.com/emojis/747837526290596002.png' : 'https://cdn.discordapp.com/emojis/747766640187408385'" max-height="16" max-width="17"></v-img></v-btn>
        </v-btn-toggle>
    </v-row>

            <div v-if="radio === 0" style="background-color: #27272b; height: 100%; border-radius: 0px 4px 4px 4px;" class="px-3 pb-6">
              <v-row class="px-1 pl-3 pt-2 pb-3" style="font-weight: 700;" justify="space-between">
                <div>General</div><v-tooltip bottom max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-btn elevation="0" :ripple="false" v-on="on" x-small plain @click="$store.state.formAnswers.mentions = ''; $store.state.formAnswers.allowed_roles = ''; $store.state.formAnswers.banned_roles = ''; $store.state.formAnswers.optOut = ''; $store.state.formAnswers.imageUrl = ''; $store.state.formAnswers.color = '#71368A'"><v-icon color="red darken-2" small>mdi-trash-can-outline</v-icon></v-btn></template><span>Reset the input</span></v-tooltip>
              </v-row>
              <v-row justify="space-between" style="padding-top: 11px;" v-if="$store.state.chosenDiscordRoles">
                <v-col class="pa-0 mb-n2">
                  <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Mentions</div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;">
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n4" @click="$store.state.formAnswers.mentions = ''" ><v-icon small color="red darken-2" class="mt-n1">mdi-trash-can-outline</v-icon></v-btn>
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n2" @click="tooltipTitle = 'Mentions'; tooltipText = 'Select roles to be pinged on event creation.'; tooltipOverlay = true;"><v-icon small color="green" class="mt-n1">mdi-comment-question</v-icon></v-btn></div>
                        </div>
                    <v-select hide-details item-value="id" item-text="name" dense solo background-color="#313338" flat label="Select roles." @click="checkRoles()" multiple :items="chosenDiscordRoles" v-model="$store.state.formAnswers.mentions">
                      <template v-slot:selection="{ attrs, item, selected }">
                        <v-chip v-bind="attrs" :input-value="selected" small close close-icon="mdi-close-circle" :text-color="'white'" style="font-size: 0.7em; border-radius: 6px;" @click:close="$store.state.formAnswers.mentions.splice($store.state.formAnswers.mentions.indexOf(item.id), 1);$store.state.formAnswers.mentions = [...$store.state.formAnswers.mentions]" ><v-icon small class="mr-1 ml-n1" :color="'rgb(' + item.color + ')'">mdi-circle</v-icon>
                        <strong>{{ item.name }}</strong>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item }" >
                        <v-icon v-if="$store.state.formAnswers.mentions.includes(item.id)" :color="'rgb(' + item.color + ')'" class="mr-5">mdi-checkbox-marked</v-icon>
                        <v-icon v-else class="mr-5">mdi-checkbox-blank-outline</v-icon>
                        <span :style="'color: rgb(' + item.color + '); font-size: 14px;'">{{ item.name }}</span>
                      </template>
                    </v-select>
                  </v-col>

                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col class="pa-0 mb-n2">
                      <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Voice Channel</div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;">
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n4" @click="$store.state.formAnswers.voice_channel = ''" ><v-icon small color="red darken-2" class="mt-n1">mdi-trash-can-outline</v-icon></v-btn>
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n2" @click="tooltipTitle = 'Voice Channel'; tooltipText = 'Select a voice channel to be displayed on the event. This can help navigate the attendees to the correct voice channel.'; tooltipOverlay = true;"><v-icon small color="green" class="mt-n1">mdi-comment-question</v-icon></v-btn></div>
                        </div>
                     
                    <v-select hide-details dense item-text="name" item-value="id" item-color="yellow" solo v-model="$store.state.formAnswers.voice_channel" :items="$store.state.discordVoiceChannels" label="Select a channel." background-color="#313338" flat>
                                    <template v-slot:selection="{ item }">
                            <v-avatar tile width="20" height="20">
                              <img :src="'https://cdn.discordapp.com/emojis/' + getChannelIconByType(item.type) + '.png?v=1'" width="20" height="20" class="ml-n7" />
                            </v-avatar>
                             <span class="ml-n5" style="font-size: 14px;">{{ item.name }}</span>
                          </template>
                          <template v-slot:item="{ item }"> 
                            <v-avatar tile width="20" height="20">
                              <img :src="'https://cdn.discordapp.com/emojis/' + getChannelIconByType(item.type) + '.png?v=1'" width="20" height="20" class="ml-n7" />
                            </v-avatar>
                            <span class="ml-n5" style="font-size: 14px;">{{ item.name }}</span>
                          </template>
                    </v-select>

                      </v-col>

                </v-col>
              </v-row>

  <v-row justify="space-between">
                <v-col class="pa-0 mb-n2">
                      <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Allowed Roles</div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;">
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n4" @click="$store.state.formAnswers.allowed_roles = ''" ><v-icon small color="red darken-2" class="mt-n1">mdi-trash-can-outline</v-icon></v-btn>
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n2" @click="tooltipTitle = 'Allowed Roles'; tooltipText = 'Select roles to restrict the Sign-Ups to this event. Members will need at least one of the allowed roles to be able to sign up. Leave this empty if you want everyone to be able to sign up.'; tooltipOverlay = true;"><v-icon small color="green" class="mt-n1">mdi-comment-question</v-icon></v-btn></div>
                        </div>
                    <v-select hide-details item-value="id" item-text="name" dense solo background-color="#313338" flat label="Select roles." multiple :items="$store.state.chosenDiscordRoles" v-model="$store.state.formAnswers.allowed_roles">
                      <template v-slot:selection="{ attrs, item, selected }">
                        <v-chip v-bind="attrs" :input-value="selected" small close close-icon="mdi-close-circle" :text-color="'white'" style="font-size: 0.7em; border-radius: 6px;" @click:close="$store.state.formAnswers.allowed_roles.splice($store.state.formAnswers.allowed_roles.indexOf(item.id), 1);$store.state.formAnswers.allowed_roles = [...$store.state.formAnswers.allowed_roles]" ><v-icon small class="mr-1 ml-n1" :color="'rgb(' + item.color + ')'">mdi-circle</v-icon>
                        <strong>{{ item.name }}</strong>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-icon v-if="$store.state.formAnswers.allowed_roles.includes(item.id)" :color="'rgb(' + item.color + ')'" class="mr-5">mdi-checkbox-marked</v-icon>
                        <v-icon v-else class="mr-5">mdi-checkbox-blank-outline</v-icon>
                        <span :style="'color: rgb(' + item.color + '); font-size: 14px;'">{{ item.name }}</span>
                      </template>
                    </v-select>

                      </v-col>

                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col class="pa-0 mb-n2">
                      <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Banned Roles</div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;">
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n4" @click="$store.state.formAnswers.banned_roles = ''" ><v-icon small color="red darken-2" class="mt-n1">mdi-trash-can-outline</v-icon></v-btn>
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n2" @click="tooltipTitle = 'Banned Roles'; tooltipText = 'Select roles to restrict the Sign-Ups to this event. Members with a banned role will not be able to sign up. Leave this empty if you want everyone to be able to sign up.'; tooltipOverlay = true;"><v-icon small color="green" class="mt-n1">mdi-comment-question</v-icon></v-btn></div>
                        </div>
                    <v-select hide-details item-value="id" item-text="name" dense solo background-color="#313338" flat label="Select roles." multiple :items="$store.state.chosenDiscordRoles" v-model="$store.state.formAnswers.banned_roles">
                      <template v-slot:selection="{ attrs, item, selected }">
                        <v-chip v-bind="attrs" :input-value="selected" small close close-icon="mdi-close-circle" :text-color="'white'" style="font-size: 0.7em; border-radius: 6px;" @click:close="$store.state.formAnswers.banned_roles.splice($store.state.formAnswers.banned_roles.indexOf(item.id), 1);$store.state.formAnswers.banned_roles = [...$store.state.formAnswers.banned_roles]" ><v-icon small class="mr-1 ml-n1" :color="'rgb(' + item.color + ')'">mdi-circle</v-icon>
                        <strong>{{ item.name }}</strong>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item }" >
                        <v-icon v-if="$store.state.formAnswers.banned_roles.includes(item.id)" :color="'rgb(' + item.color + ')'" class="mr-5">mdi-checkbox-marked</v-icon>
                        <v-icon v-else class="mr-5">mdi-checkbox-blank-outline</v-icon>
                        <span :style="'color: rgb(' + item.color + '); font-size: 14px;'">{{ item.name }}</span>
                      </template>
                    </v-select>

                      </v-col>

                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col class="pa-0 mb-n7">
                      <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Color</div>
                        </div>
                      <div @click="overlay2 = true;" :style="'margin-top: 2px; cursor: pointer; border-radius: 3px; width: 100%; height: 25px; background-color: ' + $store.state.formAnswers.color + ';'"></div>
                      </v-col>
                </v-col>
              </v-row>

        <v-row v-if="$store.state.formAnswers.imageUrl && $store.state.formAnswers.imageUrl.includes('http')" justify="space-between" class="mt-8 mb-n10">
          <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Image</div>
                        </div>
                        <div @click="overlay3 = true;" style="cursor: pointer; min-height: 20px; background-color: #232326;">
            <v-img :src="$store.state.formAnswers.imageUrl" style="margin-top: 2px; cursor: pointer; border-radius: 5px;"></v-img>
                        </div>  
          </v-col>
        </v-row>
        <v-row v-else class="mt-8 mb-n12">
                     
          <v-col align="center"><v-btn elevation="0" large text plain :ripple="false" @click="overlay3 = true; forceUpdate()"><v-icon large color="grey">mdi-image-area</v-icon></v-btn></v-col>
        </v-row>
            </div>


            <div v-if="radio === 1" style="background-color: #27272b; height: 100%; border-radius: 0px 4px 4px 4px;" class="px-3 pb-0">
              <v-row class="px-1 pl-3 pt-2 pb-3" style="font-weight: 700;" justify="space-between">
                <div>Announcement</div><v-tooltip bottom max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-btn elevation="0" :ripple="false" v-on="on" x-small plain @click="$store.state.formAnswers.announcementChannel = ''; $store.state.formAnswers.announcementTime = ''; $store.state.formAnswers.announcementMessage = '';"><v-icon color="red darken-2" small>mdi-trash-can-outline</v-icon></v-btn></template><span>Reset the input</span></v-tooltip>

              </v-row>
              <v-row justify="space-between"  style="padding-top: 23px;">
                <v-col class="pa-0 mb-n2">
                      <v-row class="mx-0">
                        <v-col cols="6">
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Channel</div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;">
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n4" @click="$store.state.formAnswers.announcementChannel = ''" ><v-icon small color="red darken-2" class="mt-n1">mdi-trash-can-outline</v-icon></v-btn>
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n2" @click="tooltipTitle = 'Announcement channel'; tooltipText = 'Select the channel where this announcement will be sent to. You can also select @Signed to DM the signed up members or @Unsigned to DM all unsigned members. Unsigned members are all members with one of the raider roles that have not signed up to the event yet. You can set the raider roles with /set raider_roles.'; tooltipOverlay = true;"><v-icon small color="green" class="mt-n1">mdi-comment-question</v-icon></v-btn></div>
                        </div>
                    <v-select style="font-size: 14px;" hide-details item-text="name" item-value="id" dense solo background-color="#313338" flat label="Select a channel." item-color="yellow" v-model="$store.state.formAnswers.announcementChannel" :items="getChannels()">
                          <template v-slot:selection="{ item }" >
                            <v-avatar tile width="20" height="20">
                              <img :src="'https://cdn.discordapp.com/emojis/' + getChannelIconByType(item.type) + '.png?v=1'" width="20" height="20" class="ml-n7" />
                            </v-avatar>
                             <span class="ml-n5" style="font-size: 14px;">{{ item.name }}</span>
                          </template>
                          <template v-slot:item="{ item }" > 
                            <v-avatar tile width="20" height="20">
                              <img :src="'https://cdn.discordapp.com/emojis/' + getChannelIconByType(item.type) + '.png?v=1'" width="20" height="20" class="ml-n7" />
                            </v-avatar>
                            <span class="ml-n5" style="font-size: 14px;">{{ item.name }}</span>
                          </template>
                    </v-select>
                    </v-col>
                    <v-col cols="6">
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Time</div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;">
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n4" @click="$store.state.formAnswers.announcementTime = ''" ><v-icon small color="red darken-2" class="mt-n1">mdi-trash-can-outline</v-icon></v-btn>
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n2" @click="tooltipTitle = 'Announcement time'; tooltipText = 'The announcement is sent x minutes before the event time, where x is the time in minutes you provided.'; tooltipOverlay = true;"><v-icon small color="green" class="mt-n1">mdi-comment-question</v-icon></v-btn></div>
                        </div>

                    <v-text-field style="font-size: 14px;" hide-details type="number" dense solo v-model="$store.state.formAnswers.announcementTime" label="Enter a number." background-color="#313338" flat>
                        </v-text-field>
                    </v-col>
                      </v-row>
                </v-col>
              </v-row>

              <v-row justify="space-between" class="pt-3">
                <v-col class="pa-0 mb-n1">
                      <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Message</div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;">
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n4" @click="$store.state.formAnswers.announcementMessage = ''" ><v-icon small color="red darken-2" class="mt-n1">mdi-trash-can-outline</v-icon></v-btn>
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n2" @click="tooltipTitle = 'Announcement message'; tooltipText = 'Enter the message that will be sent with the announcement. To mention and ping a role put the role name inside curly brackets.'; tooltipOverlay = true;"><v-icon small color="green" class="mt-n1">mdi-comment-question</v-icon></v-btn></div>
                        </div>
                        <v-textarea style="font-size: 14px;" hide-details flat solo spellcheck="false" v-model="$store.state.formAnswers.announcementMessage" background-color="#313338" label="Enter a message." rows="2"></v-textarea>
                      </v-col>
                </v-col>
              </v-row>

                            <v-row justify="space-between" class="pt-1">
                <v-col class="pa-0 mb-n1">
                      <v-col>
                        <div style="overflow: hidden; text-align: center;">
                        <div style="display: inline-block; font-size: .8em; color: #e3e3e3;"><span v-html="getAnnouncementTime()"></span></div>
                        </div>
                      </v-col>
                </v-col>
              </v-row>

               <v-row class="px-1 pl-3 pt-2 pb-3" style="font-weight: 700;" justify="space-between">
                <div>Reminder</div>

              </v-row>
              <v-row justify="space-between"  style="padding-top: 23px;">
                <v-col class="pa-0 mb-n2">
                      <v-row class="mx-0">
                    <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Time</div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;">
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n4" @click="$store.state.advancedSettings.reminder = ''" ><v-icon small color="red darken-2" class="mt-n1">mdi-trash-can-outline</v-icon></v-btn>
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n2" @click="tooltipTitle = 'Reminder'; tooltipText = 'The reminder is sent the specified amount of minutes before the event time into the events channel and will ping all attendees.'; tooltipOverlay = true;"><v-icon small color="green" class="mt-n1">mdi-comment-question</v-icon></v-btn></div>
                        </div>

                    <v-text-field style="font-size: 14px;" hide-details dense solo v-model="$store.state.advancedSettings.reminder" label="Enter a number." background-color="#313338" flat>
                        </v-text-field>
                    </v-col>
                      </v-row>
                </v-col>
              </v-row>

                            <v-row justify="space-between" class="pt-3">
                <v-col class="pa-0 mb-n1">
                      <v-col>
                        <div style="overflow: hidden; text-align: center;">
                        <div style="display: inline-block; font-size: .8em; color: #e3e3e3;"><span v-html="getReminderTime()"></span></div>
                        </div>
                      </v-col>
                </v-col>
              </v-row>
            </div>

            <div v-else-if="radio === 2 && $store.state.chosenDiscord.premium === true" style="background-color: #27272b; height: 100%; border-radius: 0px 4px 4px 4px;" class="px-3 pb-0">
              <v-row class="px-1 pl-3 pt-2 pb-3" style="font-weight: 700;" justify="space-between">
                <div>Premium</div><v-tooltip bottom max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-btn elevation="0" :ripple="false" v-on="on" x-small plain @click="$store.state.formAnswers.temporaryRoles = ''; $store.state.premiumFeatures.recurring = ''; $store.state.premiumFeatures.scheduleDate = ''; $store.state.formAnswers.response = ''; $store.state.premiumFeatures.interval2Ordinal = ''; $store.state.premiumFeatures.interval2Weekday = ''; setDynamicDate = false;"><v-icon color="red darken-2" small>mdi-trash-can-outline</v-icon></v-btn></template><span>Reset the input</span></v-tooltip>

              </v-row>
              <v-row justify="space-between" style="padding-top: 11px;">
                <v-col class="pa-0 mb-n2">

                      <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Temporary Role</div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;">
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n4" @click="$store.state.formAnswers.temporaryRoles = ''" ><v-icon small color="red darken-2" class="mt-n1">mdi-trash-can-outline</v-icon></v-btn>
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n2" @click="tooltipTitle = 'Temporary Role'; tooltipText = 'The temporary role will be given to every attending member on Sign-Up. You can enter an existing role or one that does\'t exist. If the role doesn\'t exist then the bot will create it and also delete it 24 hours after the event ended. Accepted dynamic input: \'id\' for naming the role after the event ID, \'name\' for naming the role after the event name, \'date\' for naming the role after the event date, \'namedate\' for naming the role after the event name and date.'; tooltipOverlay = true;"><v-icon small color="green" class="mt-n1">mdi-comment-question</v-icon></v-btn></div>
                        </div>
                        <v-text-field style="font-size: 14px;" clearable hide-details spellcheck="false" dense solo v-model="$store.state.formAnswers.temporaryRoles" label="Enter a role name." background-color="#313338" flat>
                        </v-text-field>

                      </v-col>
                </v-col>
              </v-row>

              <v-row justify="space-between" v-if="$store.state.permissionLevel !== 'assistant'">
                <v-col class="pa-0 mb-n2">
                      <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Recurring</div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;">
                        <v-tooltip top max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-btn elevation="0" x-small plain :ripple="false" v-on="on" class="mx-2 mr-n4" @click="setDynamicDate = !setDynamicDate; $store.state.premiumFeatures.interval2Ordinal = ''; $store.state.premiumFeatures.interval2Weekday = ''" ><v-icon small :color="setDynamicDate ? 'blue' : 'grey darken-2'" class="mt-n1">mdi-timetable</v-icon></v-btn></template><span>Set Dynamic Date</span></v-tooltip>
                        
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n4" @click="$store.state.premiumFeatures.recurring = ''; $store.state.premiumFeatures.interval2Ordinal = ''; $store.state.premiumFeatures.interval2Weekday = ''; setDynamicDate = false;" ><v-icon small color="red darken-2" class="mt-n1">mdi-trash-can-outline</v-icon></v-btn>
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n2" @click="tooltipTitle = 'Recurring'; tooltipText = 'A recurring event will be reposted in intervals. Each time the event is posted the new event date is also incremented by the interval. By default, the first event is posted immediately. You can schedule the posting of the event below to align the post time to your liking.'; tooltipOverlay = true;"><v-icon small color="green" class="mt-n1">mdi-comment-question</v-icon></v-btn></div>
                        </div>
                    <v-select v-if="setDynamicDate" class="mb-3" hide-details dense solo background-color="#313338" flat label="Select an ordinal." item-value="name" item-color="yellow" v-model="$store.state.premiumFeatures.interval2Ordinal" :items="ordinals">
                        <template v-slot:selection="{ item }">
                            <span style="font-size: 14px;">{{ item.name }}</span>
                          </template>
                          <template v-slot:item="{ item }">
                           <span style="font-size: 14px;">{{ item.name }}</span>
                          </template>
                    </v-select>
                    <v-select v-if="setDynamicDate" class="mb-3" hide-details dense solo background-color="#313338" flat label="Select a weekday." item-value="name" item-color="yellow" v-model="$store.state.premiumFeatures.interval2Weekday" :items="weekdays">
                        <template v-slot:selection="{ item }">
                            <span style="font-size: 14px;">{{ item.name }}</span>
                          </template>
                          <template v-slot:item="{ item }">
                           <span style="font-size: 14px;">{{ item.name }}</span>
                          </template>
                    </v-select>
    

      <v-col>
  <v-row>
                    <v-text-field type="number" style="font-size: 14px; width: 70%;" clearable hide-details dense solo v-model="$store.state.premiumFeatures.recurring" label="Enter the interval as a number." background-color="#313338" flat class="pr-3">
                        </v-text-field>

    <v-select placeholder="Select a weekday." dense flat item-value="name" item-text="name" item-color="green" solo v-model="$store.state.premiumFeatures.recurringUnit" :items="[{name: 'minutes'}, {name: 'hours'}, {name: 'days'}, {name: 'months'}, ]" class="pl-0 mt-0" background-color="#313338" style="width: 30%;">
      <template v-slot:selection="{ item }">
        <span style="font-size: 14px;">{{ item.name }}</span>
      </template>
      <template v-slot:item="{ item }"> 
        <span style="font-size: 14px;">{{ item.name }}</span>
      </template>
    </v-select>
  </v-row>
</v-col>
                      </v-col>
                </v-col>
              </v-row>

              <v-row justify="space-between" v-if="$store.state.permissionLevel !== 'assistant'">
                <v-col class="pa-0 mb-n4">
                           
                        
                      <v-col align="center">
                        <div style=" font-size: .8em; color: #e3e3e3;">Schedule</div>
                       <v-hover v-slot="{ hover }">
                        <div style="background-color: #313338; display: inline-block; height: 36px; border-radius: 3px; cursor: pointer; padding-top: 6px;" :style="hover && $vuetify.breakpoint.xs ? 'background-color: #373a40; font-size: 0.7em;' : hover ? 'background-color: #373a40;' : $vuetify.breakpoint.xs ? 'font-size: 0.7em;' : ''" @click="overlay4 = true" class="px-2"><div><v-icon style="padding-bottom: 2px;">mdi-calendar</v-icon> {{getScheduleDate()}} <v-icon class="ml-1 mt-n1">mdi-clock-outline</v-icon> {{ getScheduleTime() }}</div></div>
                          </v-hover><br>
                        <div style="font-size: 0.8em; color: grey; display: inline-block;">{{$store.state.settings.timezone}}</div>
                      </v-col> 
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col class="pa-0 mb-n1">
                      <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Response</div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;">
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n4" @click="$store.state.advancedSettings.response = ''" ><v-icon small color="red darken-2" class="mt-n1">mdi-trash-can-outline</v-icon></v-btn>
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n2" @click="tooltipTitle = 'Response'; tooltipText = 'The response will be sent as a DM to members on Sign-Up. You can use this to convey additional information.'; tooltipOverlay = true;"><v-icon small color="green" class="mt-n1">mdi-comment-question</v-icon></v-btn></div>
                        </div>
                        <v-textarea style="font-size: 14px;" hide-details flat solo spellcheck="false" v-model="$store.state.advancedSettings.response" background-color="#313338" label="Enter a message." rows="4"></v-textarea>
                      </v-col>
                </v-col>
              </v-row>
            </div>
            <div v-else-if="radio === 2 && $store.state.chosenDiscord.premium === false" style="background-color: #27272b; height: 100%; border-radius: 0px 4px 4px 4px;" class="px-3 pb-3">
              <v-row class="px-3 pt-4" style="font-weight: 700;" justify="start">Premium</v-row>


              <v-row justify="space-between" class="pt-3">
                <v-col class="pa-0">
                      <v-col>
                        <div style="font-size: 0.85em; color: grey;">Do you want access to advanced features like temporary roles, recurring and scheduled events, Sign-Up responses and more ? Check out Raid-Helper Premium and support the projects continuous maintenance and development!</div>
                      </v-col>
                </v-col>
              </v-row>
              <v-row class="my-10" justify="center"><v-btn elevation="0" x-large color="purple" @click="$router.push('/premium/', () => {})"> <span><v-icon color="yellow" class="pb-1"> mdi-crown-outline</v-icon>  Get Premium!</span></v-btn>
              </v-row>
            </div>
            

            <div v-else-if="radio === 3" style="background-color: #27272b; height: 100%; border-radius: 0px 4px 4px 4px;" class="px-3 pb-0">
              <v-row class="px-1 pl-3 pt-2 pb-3" style="font-weight: 700;" justify="space-between">
                <div>Limits</div><v-tooltip bottom max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-btn elevation="0" :ripple="false" v-on="on" x-small plain @click="clearLimits()"><v-icon color="red darken-2" small>mdi-trash-can-outline</v-icon></v-btn></template><span>Reset the input</span></v-tooltip>

              </v-row>
              <v-row class="pt-2" v-if="$store.state.formAnswers.template.roles">
                <v-col cols="6" sm="4" class="mb-n6" v-for="n in $store.state.formAnswers.template.roles.length" :key="n">
                  <div v-for="ro in $store.state.formAnswers.template.roles.slice(n - 1, n)" :key="ro.name">
                    <span style="font-size: 0.7em;"><img class="my-n1" width="15" height="15" :src="'https://cdn.discordapp.com/emojis/' + ro.id + '.png?v=1'" > {{ro.name}}</span>
                      <v-text-field style="font-size: 14px;" hide-details type="number" dense solo v-model="ro.value" :min="0" :max="999" background-color="#313338" flat></v-text-field>
                  </div>
                </v-col>
              </v-row>
               <v-row class="mb-2" v-if="$store.state.formAnswers.template.classes">
                <v-col cols="6" sm="4" class="mb-n6" v-for="k in $store.state.formAnswers.template.classes.length" :key="k">
                  <div v-for="cl in $store.state.formAnswers.template.classes.slice(k - 1, k)" :key="cl.name">
                    <span style="font-size: 0.7em;"><img class="my-n1" width="15" height="15" :src="'https://cdn.discordapp.com/emojis/' + cl.id + '.png?v=1'" > {{cl.name}}</span>
                      <v-text-field style="font-size: 14px;" hide-details type="number" dense solo v-model="cl.value" :min="0" :max="999" background-color="#313338" flat></v-text-field>
                  </div>
                </v-col>
              </v-row>

              <v-row justify="space-between" style="padding-top: 11px;" v-if="!$store.state.formAnswers.template.roles && !$store.state.formAnswers.template.classes">
                <v-col class="pa-0 mb-n2">

                      <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Template<span style="color: red;"> *</span></div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;"><v-btn elevation="0" @click="overlay = true;" x-small plain :ripple="false" class="mr-n2" ><v-icon small color="green" class="mt-n1">mdi-comment-question</v-icon></v-btn></div>

                        </div>
                        <v-select hide-details item-text="name" return-object dense style="z-index: 7;" item-color="yellow" solo :rules="rules" v-model="$store.state.formAnswers.template" :items="$store.state.loadedTemplates" label="Select a template for your event." background-color="#313338" flat>
                          <template v-slot:selection="{ item }" >
                            <v-avatar tile width="20" height="20">
                              <img :src="'https://cdn.discordapp.com/emojis/' + item.image + '.png?v=1'" width="20" height="20" class="ml-n7" />
                            </v-avatar>
                            <span class="ml-n5">{{ item.name }}</span>
                          </template>
                          <template v-slot:item="{ item }" >
                            <v-avatar tile width="20" height="20">
                              <img :src="'https://cdn.discordapp.com/emojis/' + item.image + '.png?v=1'" width="20" height="20" class="ml-n7" />
                            </v-avatar>
                           <span class="ml-n5">{{ item.name }}</span>
                          </template>
                        </v-select>
                      </v-col>

                </v-col>
              </v-row>
            </div>

             <div v-if="radio === 5 && isWoW()" style="background-color: #27272b; height: 100%; border-radius: 0px 4px 4px 4px;" class="px-3 pb-0">
              <v-row class="px-1 pl-3 pt-2 pb-3" style="font-weight: 700;" justify="space-between">
                <div>Softres.it</div><v-tooltip bottom max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-btn elevation="0" :ripple="false" v-on="on" x-small plain @click="clearSoftres()"><v-icon color="red darken-2" small>mdi-trash-can-outline</v-icon></v-btn></template><span>Reset the input</span></v-tooltip>

              </v-row>
  

              <v-row justify="space-between" style="padding-top: 11px;">
                <v-col class="pa-0 mb-n2">
                      <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Instance</div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;">
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n4" @click="$store.state.formAnswers.softresInstance = ''" ><v-icon small color="red darken-2" class="mt-n1">mdi-trash-can-outline</v-icon></v-btn>
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n2" @click="tooltipTitle = 'Instance'; tooltipText = 'The instance choice determines the items that will be available to soft-reserve.'; tooltipOverlay = true;"><v-icon small color="green" class="mt-n1">mdi-comment-question</v-icon></v-btn></div>
                        </div>


                    <v-select hide-details item-value="abbr" item-text="name" dense solo background-color="#313338" flat label="Select instances." multiple :items="instances" v-model="$store.state.formAnswers.softresInstance">
                      <template v-slot:selection="{ attrs, item, selected }">
                        <v-chip v-bind="attrs" :input-value="selected" small close close-icon="mdi-close-circle" :text-color="'white'" style="font-size: 0.7em; border-radius: 6px;" @click:close="$store.state.formAnswers.softresInstance.splice($store.state.formAnswers.softresInstance.indexOf(item.abbr), 1);$store.state.formAnswers.softresInstance = [...$store.state.formAnswers.softresInstance]" ><v-icon small class="mr-1 ml-n1">mdi-circle</v-icon>
                        <strong>{{ item.name }}</strong>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item }" >
                        <v-icon v-if="$store.state.formAnswers.softresInstance.includes(item.abbr)" :color="'yellow'" class="mr-5">mdi-checkbox-marked</v-icon>
                        <v-icon v-else class="mr-5">mdi-checkbox-blank-outline</v-icon>
                        <span :style="'font-size: 14px; color: white'">{{ item.name }}</span>
                      </template>
                    </v-select>


                      </v-col>
                </v-col>
              </v-row>              
              
              <v-row justify="space-between">
                <v-col class="pa-0 mb-n2">
                      <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Faction</div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;">
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n4" @click="$store.state.formAnswers.softresFaction = 'Horde'" ><v-icon small color="red darken-2" class="mt-n1">mdi-trash-can-outline</v-icon></v-btn>
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n2" @click="tooltipTitle = 'Faction'; tooltipText = 'The faction choice determines the items that are available to reserve, as there are faction restricted items in classic wow.'; tooltipOverlay = true;"><v-icon small color="green" class="mt-n1">mdi-comment-question</v-icon></v-btn></div>
                        </div>
                    <v-select hide-details dense solo background-color="#313338" flat label="Select an instance." item-value="name" item-color="green" v-model="$store.state.formAnswers.softresFaction" :items="factions">
                        <template v-slot:selection="{ item }" >
                        <v-avatar tile width="20" height="20">
                              <img :src="item.icon" width="20" height="20" class="ml-n7" />
                            </v-avatar>
                            <span class="ml-n5" style="font-size: 14px;">{{ item.name }}</span>
                          </template>
                          <template v-slot:item="{ item }" >
                            <v-avatar tile width="20" height="20">
                              <img :src="item.icon" width="20" height="20" class="ml-n7" />
                            </v-avatar>
                           <span class="ml-n5" style="font-size: 14px;">{{ item.name }}</span>
                          </template>
                    </v-select>
                      </v-col>
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col class="pa-0 mb-n2">
                      <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Reserves per user</div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;">
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n4" @click="$store.state.formAnswers.softresMaxres = ''" ><v-icon small color="red darken-2" class="mt-n1">mdi-trash-can-outline</v-icon></v-btn>  
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n2" @click="tooltipTitle = 'Reserves per user'; tooltipText = 'The maximum amount of reserves per user.'; tooltipOverlay = true;"><v-icon small color="green" class="mt-n1">mdi-comment-question</v-icon></v-btn></div>
                        </div>
                    <v-select style="font-size: 14px;" hide-details dense solo background-color="#313338" flat label="Select an amount." item-value="name" item-color="green" v-model="$store.state.formAnswers.softresMaxres" :items="['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']">
                    </v-select>
                      </v-col>
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col class="pa-0 mb-n1">
                      <v-col>
                        <div style="overflow: hidden;">
                        <div style="float: left; font-size: .8em; color: #e3e3e3;">Behaviour on Sign-Up</div>
                        <div style="float: right; font-size: .8em; color: #e3e3e3; cursor: pointer;">
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n4" @click="$store.state.formAnswers.softresBehaviour = ''" ><v-icon small color="red darken-2" class="mt-n1">mdi-trash-can-outline</v-icon></v-btn>  
                        <v-btn elevation="0" x-small plain :ripple="false" class="mx-2 mr-n2" @click="tooltipTitle = 'Behaviour on Sign-Up'; tooltipText = 'The action that will be triggered when a user signs up to the event.'; tooltipOverlay = true;"><v-icon small color="green" class="mt-n1">mdi-comment-question</v-icon></v-btn></div>
                        </div>
                    <v-select hide-details dense solo background-color="#313338" flat label="Select an option." item-value="abbr" item-color="green" v-model="$store.state.formAnswers.softresBehaviour" :items="sendmessageOption">
                        <template v-slot:selection="{ item }" >
                        <v-avatar tile width="20" height="20">
                              <img :src="item.icon" width="20" height="20" class="ml-n7" />
                            </v-avatar>
                            <span class="ml-n5" style="font-size: 14px;">{{ item.name }}</span>
                          </template>
                          <template v-slot:item="{ item }" >
                            <v-avatar tile width="20" height="20">
                              <img :src="item.icon" width="20" height="20" class="ml-n7" />
                            </v-avatar>
                           <span class="ml-n5" style="font-size: 14px;">{{ item.name }}</span>
                          </template>
                    </v-select>
                      </v-col>
                </v-col>
              </v-row>

            </div>


            <div v-else-if="radio === 4" style="background-color: #27272b; height: 100%; border-radius: 0px 4px 4px 4px;" class="px-3 pb-3">
              <v-row class="px-1 pl-3 pt-2 pb-3" style="font-weight: 700;" justify="space-between">
                <div>Advanced Settings</div><v-tooltip bottom max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-btn elevation="0" :ripple="false" v-on="on" x-small plain @click="clearAdvanced()"><v-icon color="red darken-2" small>mdi-trash-can-outline</v-icon></v-btn></template><span>Reset the input</span></v-tooltip>

              </v-row>
                <v-row style="padding-top: 16px;" class="pl-1 mb-0">
                     <Advanced :key="key" @advancedSettings="setAdvanced" v-bind:adv="$store.state.advancedSettings" v-bind:isRec="'creation'" id="AdvancedSelection" /> 
                </v-row>
            </div>
          </v-col>
        </v-row>
                <v-overlay :z-index="2" v-if="overlayShadow === true" opacity="0.8" color="primary"></v-overlay>
        <v-row class="mt-0">
                <v-col class="mb-0 pl-2 pl-sm-4">
                  <v-tooltip right max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-btn elevation="0" v-on="on" left x-small fab color="red" @click="deleteAllInput()" class="mr-2"><v-icon>mdi-trash-can-outline</v-icon></v-btn></template><span>Reset all input</span></v-tooltip>
                  <v-tooltip right max-width="600" transition="v-scale-transition" color="#0f0f0f"><template v-slot:activator="{ on }"><v-btn elevation="0" v-on="on" left x-small fab color="blue" @click="overlayOptions = true;"><v-icon >mdi-folder-open-outline</v-icon></v-btn></template><span>Form Profiles</span></v-tooltip>
    <v-btn elevation="0" class="mt-sm-n1 mx-n2 mx-sm-0" :style="(this.$store.state.chosenDiscord.hasPerms && this.$store.state.formAnswers.channel !== '' && this.$store.state.formAnswers.date !== '' && this.$store.state.formAnswers.template !== '' && !disableCreation) ? 'z-index: 10; box-shadow: 0px 0px 25px green; background-color: #4caf4f;' : 'z-index: 10; background-color: grey;'" id="custom-disabled" absolute right :disabled="disableCreation" @click="postFormAnswer(); disableButtonTemporarily();"> 
      <v-progress-circular size="20" v-if="disableCreation" indeterminate color="white" class="mr-4"></v-progress-circular>Create Event
    </v-btn>
                </v-col>
        </v-row>
      </v-form>


                <v-dialog transition="slide-y-transition" v-model="overlay1" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
                    <v-card color="#3b3e45" style="border: 1px solid #2c2f33;">
                      <v-card-title>Date & Time</v-card-title>
                      <VueCtkDateTimePicker :first-day-of-week="1" max-height="7" inline no-keyboard label="Date & Time" style="z-index: 3;" id="datepicker1" dark noHeader no-button v-model="$store.state.formAnswers.date" format="YYYY-MM-DD HH:mm"/>

                        <v-container>
                          <v-row style="background-color: #2f3136;">
                              <v-col align="start" class="pa-n2">
                          <v-btn elevation="0" @click="overlay5 = true;" left style="float: left;" class="mr-2 ma-1" color="#3b3e45" :ripple="false">Deadline</v-btn>      
                          <v-btn elevation="0" @click="overlay6 = true;" left style="float: left;" class="mr-2 ma-1" color="#3b3e45" :ripple="false">Duration</v-btn>      
    
                        </v-col>
                              <v-col align="end">
                            <v-btn elevation="0" @click="overlay1 = false;" class="mr-2 ma-1" :ripple="false" color="green">OK</v-btn>
                   
                              </v-col>
                          </v-row>
                        </v-container>
                    </v-card>
                </v-dialog>

                <v-dialog transition="slide-y-transition" v-model="overlay2" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
                    <v-card color="#3b3e45" style="border: 1px solid #2c2f33;">
                      <v-card-title>Color</v-card-title>
                      <v-color-picker class="pa-2" show-swatches swatches-max-height="230" hide-canvas width="100%" :style="'background-color: transparent;'" v-model="$store.state.formAnswers.color"></v-color-picker>
                        <div style="background-color: #2f3136; text-align: right;" class="pa-2">
                            <v-btn elevation="0" @click="overlay2 = false;" right color="green" class="ma-2">OK</v-btn>
                        </div>
                    </v-card>
                </v-dialog>

                <v-dialog transition="slide-y-transition" v-model="overlay3" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
               <v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
                    <v-card-title class="px-5">Image</v-card-title>
                    <v-card-text class="px-5 mb-3" style="font-size: 0.8em;">Enter, upload or select an image.</v-card-text>
                     <Images :key="key" @imageUrl="setImage" v-bind:url="$store.state.formAnswers.imageUrl" id="ImageSelection" class="my-5" /> 
                       <div style="background-color: #2f3136; text-align: right;" class="pa-2">
                        <v-btn elevation="0" @click="$store.state.formAnswers.imageUrl = 'none'; overlay3 = false;" :ripple="false" right color="orange" text plain class="ma-2">Remove</v-btn>
                       <v-btn elevation="0" @click="overlay3 = false;" right color="green" class="ma-2">Ok</v-btn>
                       </div>
                  </v-card>
                </v-dialog>

                <v-dialog transition="slide-y-transition" v-model="overlay4" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
                    <v-card color="#3b3e45" style="border: 1px solid #2c2f33;">
                      <v-card-title>Schedule</v-card-title>
                      <v-card-text>If you don't want your event to be posted immediately, you can schedule it here for later.</v-card-text>
                      <VueCtkDateTimePicker :first-day-of-week="1" max-height="7" inline no-keyboard label="Date & Time" style="z-index: 3;" id="datepicker1" dark noHeader no-button v-model="$store.state.premiumFeatures.scheduleDate" format="YYYY-MM-DD HH:mm"/>

                        <v-container>
                          <v-row style="background-color: #2f3136;">
                              <v-col align="end">
                        <v-btn elevation="0" @click="$store.state.premiumFeatures.scheduleDate = ''; overlay4 = false;" :ripple="false" right color="orange" text plain class="ma-2">Delete</v-btn>
                       <v-btn elevation="0" @click="overlay4 = false;" right color="green" class="ma-2">Ok</v-btn>
                   
                              </v-col>
                          </v-row>
                        </v-container>
                    </v-card>
                </v-dialog>

          <v-dialog transition="slide-y-transition" v-model="overlay" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="800" dark>
            <v-card color="#3b3e45" style="border: 1px solid #2c2f33;">
              <v-card-title>Template</v-card-title>
              <v-card-text class="mt-3">
                Here you can see a list of the available templates and what they look like:
                <v-row class="my-5" v-if="$store.state.loadedTemplates">
                  <v-col cols="12" sm="6" md="4" lg="3" v-for="n in $store.state.loadedTemplates.length" :key="n">
                    <v-card color="#27272d" elevation="5" class="vcard-templates" @click=" overlay = false; $store.state.formAnswers.template = $store.state.loadedTemplates[n - 1];">
                      <v-avatar class="py-6" width="20" height="20">
                        <img class="mb-1 ml-n2" :src="'https://cdn.discordapp.com/emojis/' +  $store.state.loadedTemplates[n - 1].image + '.png?v=1'" width="20" height="20"/>
                      </v-avatar>
                      <span style="font-size: 0.8em !important;" class="ml-n2">{{ $store.state.loadedTemplates[n - 1].name }}</span>
                      <v-img v-if="$store.state.loadedTemplates[n - 1].shortName && !$store.state.loadedTemplates[n - 1].shortName.startsWith('ct')" :src=" require('@/assets/template' + $store.state.loadedTemplates[n - 1].id + '.png')"></v-img>
                    </v-card>
                  </v-col> 
                  </v-row>
                  <v-spacer class="py-5"></v-spacer>

              </v-card-text>
                        <v-container>
                          <v-row style="background-color: #2f3136;">
                              <v-col align="end">
                            <v-btn elevation="0" @click="overlay = false;" plain class="mr-2 ma-1" :ripple="false">Close</v-btn>
                              </v-col>
                          </v-row>
                        </v-container>
            </v-card>
          </v-dialog>

          <v-dialog transition="slide-y-transition" v-model="tooltipOverlay" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="400" dark>

            <v-card color="#3b3e45" style="border: 1px solid #2c2f33;">
                        <v-card-title>{{tooltipTitle}}</v-card-title>
              <v-card-text class="px-3 pb-2">
                  <v-col>
                   {{tooltipText}}
                  </v-col> 
              </v-card-text>
                        <v-container>
                          <v-row style="background-color: #2f3136;">
                              <v-col align="end">
                            <v-btn elevation="0" @click="tooltipOverlay = false;" plain class="mr-2 ma-1 mt-n2 mb-n2" :ripple="false">Close</v-btn>
                              </v-col>
                          </v-row>
                        </v-container>
              </v-card>
          </v-dialog>

          <v-dialog transition="slide-y-transition" v-model="overlay5" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
                  <v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
                    <v-card-text class="mt-2 pa-4" style="font-size: 1.3em; color: white;">Deadline</v-card-text>
                    <v-card-text class="mt-n5 pa-4" style="font-size: 0.8em;">Enter the new deadline in hours. You can also append d for days or m for minutes.</v-card-text>
                        <v-text-field flat background-color="#4a4e57" solo @keydown.enter="overlay5 = false; key++" hide-details v-model="$store.state.advancedSettings.deadline" class="pa-4 mb-2" full-width single-line>
                        </v-text-field>
                      <div style="background-color: #2f3136; text-align: right;" class="pa-2 mb-0">
                            <v-btn elevation="0" @click="overlay5 = false; key++" right color="green" class="mx-2 my-2">OK</v-btn>
                      </div>
                  </v-card>
                </v-dialog>

          <v-dialog transition="slide-y-transition" v-model="overlay6" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
                  <v-card color="#3b3e45" style="border: 1px solid #2c2f33; overflow-y: hidden">
                    <v-card-text class="mt-2 pa-4" style="font-size: 1.3em; color: white;">Duration</v-card-text>
                    <v-card-text class="mt-n5 pa-4" style="font-size: 0.8em;">Enter the duration of the event in minutes.</v-card-text>
                        <v-text-field flat background-color="#4a4e57" type="number" solo @keydown.enter="overlay6 = false; key++" hide-details v-model="$store.state.advancedSettings.duration" class="pa-4 mb-2" full-width single-line>
                        </v-text-field>
                      <div style="background-color: #2f3136; text-align: right;" class="pa-2 mb-0">
                            <v-btn elevation="0" @click="$store.state.advancedSettings.duration = '0'; overlay6 = false; key++" plain right color="red" class="mx-2 my-2">Reset</v-btn>
                            <v-btn elevation="0" @click="overlay6 = false; key++" right color="green" class="mx-2 my-2">Ok</v-btn>
                      </div>
                  </v-card>
                </v-dialog>

                <v-dialog transition="slide-y-transition" v-model="overlayOptions" overlay-color="black" overlay-opacity="0.6" content-class="elevation-0" max-width="600" dark>
                  <Options id="Options" @closeOverlay="closeOverlayNow" />
                        <v-container>
                          <v-row style="background-color: #2f3136;">
                              <v-col align="end">
                            <v-btn elevation="0" @click="overlayOptions = false;" class="mr-2 ma-1" :ripple="false" color="green">OK</v-btn>
                              </v-col>
                          </v-row>
                        </v-container>
                </v-dialog>

              <v-snackbar class="my-2" color="red" v-model="snackbarInvalidForm" :timeout="snackbarInvalidFormTimeout" ><v-icon class="mx-3">mdi-alert</v-icon> Fill out the required fields!</v-snackbar>


          </v-container>
        </v-col>
      </v-row>

  </v-card>
</template>
<script>

import Form from "../components/Form";
import Images from "../components/events/ImageSelection";
import Advanced from "../components/events/AdvancedSelection";
import Options from "../components/Options";

export default {
  components: {
    Form,
    Images,
    Advanced,
    Options
  },
  data() {
    return {
      unixtime: undefined,
      chosenDiscordRoles: undefined,
		  snackbar2: false,
		  snackbar3: false,
      active: false,
      absolute: true,
      overlayShadow: false,
      overlayOptions: false,
      overlay: false,
      overlay1: false,
      overlay2: false,
      overlay3: false,
      overlay4: false,
      overlay5: false,
      overlay6: false,
      tooltipOverlay: false,
      tooltipTitle: '',
      tooltipText: '',
      show: false,
      radio: 0,
      key: 0,
      isLoaded: false,
      valid: false,
      date: undefined,
      server: undefined,
      channel: undefined,
      toggle_exclusive: undefined,
      templates: [],
      loadingPostReq: false,
      createResponse: false,
      createResponseText: undefined,
      color: "#4caf4f",
      disableCreation: false,
      setDynamicDate: false,
      snackbarInvalidForm: false,
      snackbarInvalidFormTimeout: 3000,
      pollEmotes: ["675573195029479424", "675573195138662420", "675573195100782593", "675573194890936341", "675573195243388930", "675573195465555979", "675573195553636352", "675573195365154853", "675573195008507916", "675573195402641409", "675573195352440843", "675573195704631296", "675573195826397195", "675573195796906005", "675573195885248512", "675573195797037057", "675573196124192770", "675573195859820555"],
      factions: [
        {name: "Horde", icon: "https://cdn.discordapp.com/emojis/594591073293303822.png?v=1"},
        {name: "Alliance", icon: "https://cdn.discordapp.com/emojis/594591075918675990.png?v=1"}
      ],

      sendmessageOption: [
        {name: "Nothing", icon: "https://cdn.discordapp.com/emojis/596014739411173386.png?v=1", abbr: "true"},
        {name: "Send Softres.it link in DM", icon: "https://cdn.discordapp.com/emojis/596014739398721536.png?v=1", abbr: "trueDM"},
        {name: "Send link and require reserve", icon: "https://cdn.discordapp.com/emojis/767260613386567730.png?v=1", abbr: "trueReq"}
      ],

      ordinals: [
        {name: "First"},
        {name: "Second"},
        {name: "Third"},
        {name: "Fourth"},
        {name: "Last"},
      ],

      weekdays: [
        {name: "Monday"},
        {name: "Tuesday"},
        {name: "Wednesday"},
        {name: "Thursday"},
        {name: "Friday"},
        {name: "Saturday"},
        {name: "Sunday"},
      ],

      instances: [
        {name: "Zul'Gurub (Classic)", abbr: "zg"},
        {name: "Molten Core (Classic)", abbr: "mc"},
        {name: "Onyxia's Lair (Classic)", abbr: "onyxia"},
        {name: "Blackwing Lair (Classic)", abbr: "bwl"},
        {name: "Ahn'Qiraj 20 (Classic)", abbr: "aq20"},
        {name: "Ahn'Qiraj 40 (Classic)", abbr: "aq40"},
        {name: "Naxxramas (Classic)", abbr: "naxxramas"},
        {name: "Azuregos (Classic)", abbr: "azuregos"},
        {name: "Lord Kazzak (Classic)", abbr: "lordkazzak"},
        {name: "Blackfathom Deeps (SoD)", abbr: "bfdsod"},
        {name: "Gnomeregan (SoD)", abbr: "gnomeregansod"},
        {name: "Sunken Temple (SoD)", abbr: "sunkentemplesod"},
        {name: "Azuregos (SoD)", abbr: "azuregossod"},
        {name: "Lord Kazzak (SoD)", abbr: "lordkazzaksod"},
        {name: "Molten Core (SoD)", abbr: "mcsod"},
        {name: "Onyxia (SoD)", abbr: "onyxiasod"},
        {name: "Karazhan (TBC)", abbr: "kara"},
        {name: "Gruul (TBC)", abbr: "gruul"},
        {name: "Magtheridon (TBC)", abbr: "magtheridon"},
        {name: "Serpentshrine Cavern (TBC)", abbr: "ssc"},
        {name: "Tempest Keep (TBC)", abbr: "tempestkeep"},     
        {name: "Hyjal (TBC)", abbr: "hyjal"},
        {name: "Black Temple (TBC)", abbr: "blacktemple"},
        {name: "Zul'Aman (TBC)", abbr: "za"},
        {name: "Sunwell Plateau (TBC)", abbr: "sunwellplateau"},
        {name: "Naxxramas 10 (WotLK)", abbr: "wotlknaxx10p2"},
        {name: "Naxxramas 25 (WotLK)", abbr: "wotlknaxx25"},
        {name: "Obsidian Sanctum 10 (WotLK)", abbr: "obsidiansanctum10p2"},
        {name: "Obsidian Santum 25 (WotLK)", abbr: "obsidiansanctum25"},
        {name: "Eye of Eternity 10 (WotLK)", abbr: "eyeofeternity10p2"},
        {name: "Eye of Eternity 25 (WotLK)", abbr: "eyeofeternity25"},
        {name: "EoE/OS Dragons 10 (WotLK)", abbr: "wyrmrest10p2"},
        {name: "EoE/OS Dragons 25 (WotLK)", abbr: "wyrmrest25"},
        {name: "Ulduar 10 (WotLK)", abbr: "ulduar10p3"},
        {name: "Ulduar 25 (WotLK)", abbr: "ulduar25"},

        {name: "Trial of the Crusader 10 (WotLK)", abbr: "toc10"},
        {name: "Trial of the Crusader 25 (WotLK)", abbr: "toc25"},
        {name: "Onyxia's Lair 10 (WotLK)", abbr: "wotlkonyxia10"},
        {name: "Onyxia's Lair 25 (WotLK)", abbr: "wotlkonyxia25"},
        {name: "Icecrown Citadel 10 (WotLK)", abbr: "icc10"},
        {name: "Icecrown Citadel 25 (WotLK)", abbr: "icc25"},
        {name: "Ruby Sanctum 10 (WotLK)", abbr: "rubysanctum10"},
        {name: "Ruby Sanctum 25 (WotLK)", abbr: "rubysanctum25"},

        {name: "Baradin Hold (Cata)", abbr: "baradinhold"},
        {name: "Blackwing Descent (Cata)", abbr: "blackwingdescent"},
        {name: "Bastion of Twilight (Cata)", abbr: "bastionoftwilight"},
        {name: "Throne of the Four Winds (Cata)", abbr: "throneoffourwinds"}
      ],
      swatches: [
        [
          "#212224FF",
          "#000000FF",
          "#330000FF",
          "#663300FF",
          "#992D22FF",
          "#A84300FF",
          "#1F8B4CFF",
          "#11806AFF",
          "#1ABC9CFF",
          "#BCC0C0FF",
          "#95A5A6FF",
          "#979C9FFF",
          "#7F8C8DFF",
          "#2C3E50FF",
          "#34495EFF",
          "#71368AFF",
          "#0000FFFF",
          "#0000CCFF",
          "#3498DBFF",
          "#33CCFFFF",
          "#9B59B6FF",
          "#2ECC71FF",
          "#E74C3CFF",
          "#BC0057FF",
          "#FD0061FF",
          "#F1C40FFF",
          "#C27C0EFF",
          "#66FF66FF",
          "#E67E22FF",
          "#FFFF00FF",
        ],
      ],

      rules: [(v) => !!v || "Required"],
      numberRule: [
        (v) => {
          if (!v.trim()) return true;
          if (!isNaN(parseFloat(v)) && v >= 0) return true;
          return "Number has to be greater than 0";
        },
      ],
      clocks: ["", "PM", "AM"],
    };
  },

  methods: {

    checkRoles() {
      this.chosenDiscordRoles = JSON.parse(JSON.stringify(this.$store.state.chosenDiscordRoles));
      if (this.$store.state.formAnswers.channel && this.$store.state.formAnswers.channel.mention_permission) {
        this.chosenDiscordRoles.push({color: "255,255,255", id: "@here", name: "@here", position: -1})
        this.chosenDiscordRoles.push({color: "255,255,255", id: "@everyone", name: "@everyone", position: -2})
      }
    },

      remove (item, array) {
        array.splice(array.indexOf(item), 1)
        array = [...array]
      },

    getAnnouncementTime() {
      if (this.$store.state.formAnswers.announcementTime === '' || this.$store.state.formAnswers.announcementTime === null || this.$store.state.formAnswers.announcementChannel === ''  || this.$store.state.formAnswers.announcementChannel === null || this.$store.state.formAnswers.announcementMessage === '') {
          return ""
      }
      let date = new Date(this.$store.state.formAnswers.date)
      date = new Date(this.$store.state.formAnswers.date).setMinutes(date.getMinutes() - parseInt(this.$store.state.formAnswers.announcementTime));
      return "Announcement will be sent on:<br><b><u>" + new Date(date).toLocaleDateString([],{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) + " @ " + new Date(date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) + "</u></b> " + this.$store.state.settings.timezone;

    },

    getReminderTime() {
      if (this.$store.state.advancedSettings.reminder === 'false' || this.$store.state.advancedSettings.reminder === '') {
          return ""
      }
      let reminder = parseInt(this.$store.state.advancedSettings.reminder);
      if (!reminder) {
        return ""
      }

      let date = new Date(this.$store.state.formAnswers.date)
      date = new Date(this.$store.state.formAnswers.date).setMinutes(date.getMinutes() - reminder);
      return "Reminder will be sent on:<br><b><u>" + new Date(date).toLocaleDateString([],{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) + " @ " + new Date(date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) + "</u></b> " + this.$store.state.settings.timezone;

    },

    disableButtonTemporarily() {
      if (this.requirementsMet()) {
        this.disableCreation = true;
        setTimeout(() => this.disableCreation = false, 3000);
      }
    },

    requirementsMet() {
      let channelExists = false;
      if (this.$store.state.formAnswers.channel) {
        for (let i = 0; i < this.$store.state.discordChannels.length; i++) {
          if (this.$store.state.discordChannels[i].id === this.$store.state.formAnswers.channel.id || this.$store.state.discordChannels[i].id === this.$store.state.formAnswers.channel) {
            channelExists = true;
          }
        }
      }
      return (
        this.$store.state.chosenDiscord.hasPerms &&
        this.$store.state.formAnswers.channel !== "" && this.$store.state.formAnswers.channel !== undefined &&
        this.$store.state.formAnswers.date !== "" && this.$store.state.formAnswers.date !== undefined &&
        this.$store.state.formAnswers.template !== "" && this.$store.state.formAnswers.template !== undefined &&
        channelExists
      );
    },

    postFormAnswer() {
      this.$vuetify.goTo("#Form", {
        duration: 250,
        offset: 0,
        easing: "easeInOutQuad",
      });
      if (this.requirementsMet()) {
        this.$root.$refs.Form.postFormAnswer();
      } else {
        this.overlayShadow = true;
        this.snackbarInvalidForm = true;
      }
    },

    isWoW() {
      try {
        if (this.$store.state.formAnswers.template.shortName.includes("wow") || this.$store.state.formAnswers.template.shortName.includes("ct") || this.$store.state.formAnswers.template.id === 1 || this.$store.state.formAnswers.template.id === 2 || this.$store.state.formAnswers.template.id === 3 || this.$store.state.formAnswers.template.id === 4 || this.$store.state.formAnswers.template.id === 31 || this.$store.state.formAnswers.template.id === 17 || this.$store.state.formAnswers.template.id === 18 || this.$store.state.formAnswers.template.id === 19 || this.$store.state.formAnswers.template.id === 20 || this.$store.state.formAnswers.template.id === 21 || this.$store.state.formAnswers.template.id === 22 || this.$store.state.formAnswers.template.id === 40 || this.$store.state.formAnswers.template.id === 41) {
          return true;
        } else {
          return false;
        }
        
      } catch (error) {
        return false;
      }
    },

    clearLimits() {
      for (let i = 0; i < this.$store.state.formAnswers.template.classes.length; i++) {
        this.$store.state.formAnswers.template.classes[i].value = 999;
      }
      for (let i = 0; i < this.$store.state.formAnswers.template.roles.length; i++) {
        this.$store.state.formAnswers.template.roles[i].value = 999;
      }
    },

    clearAdvanced() {
          this.$store.state.advancedSettings.deadline = this.$store.state.defaultAdvancedSettings.deadline;
          this.$store.state.advancedSettings.limit = this.$store.state.defaultAdvancedSettings.limit;
          this.$store.state.advancedSettings.lock_at_limit = this.$store.state.defaultAdvancedSettings.lock_at_limit;
          this.$store.state.advancedSettings.limit_per_user = this.$store.state.defaultAdvancedSettings.limit_per_user;
          this.$store.state.advancedSettings.lower_limit = this.$store.state.defaultAdvancedSettings.lower_limit;
          this.$store.state.advancedSettings.horizontal_mode = this.$store.state.defaultAdvancedSettings.horizontal_mode;
          this.$store.state.advancedSettings.bench_overflow = this.$store.state.defaultAdvancedSettings.bench_overflow;
          this.$store.state.advancedSettings.queue_bench = this.$store.state.defaultAdvancedSettings.queue_bench;
          this.$store.state.advancedSettings.vacuum = this.$store.state.defaultAdvancedSettings.vacuum;
          this.$store.state.advancedSettings.force_reminders = this.$store.state.defaultAdvancedSettings.force_reminders;
          this.$store.state.advancedSettings.create_thread = this.$store.state.defaultAdvancedSettings.create_thread;
          this.$store.state.advancedSettings.forum_tags = this.$store.state.defaultAdvancedSettings.forum_tags;
          this.$store.state.advancedSettings.delete_thread = this.$store.state.defaultAdvancedSettings.delete_thread;
          this.$store.state.advancedSettings.use_nicknames = this.$store.state.defaultAdvancedSettings.use_nicknames;
          this.$store.state.advancedSettings.create_discordevent = this.$store.state.defaultAdvancedSettings.create_discordevent;
          this.$store.state.advancedSettings.only_discordevent = this.$store.state.defaultAdvancedSettings.only_discordevent;
          this.$store.state.advancedSettings.pin_message = this.$store.state.defaultAdvancedSettings.pin_message;
          this.$store.state.advancedSettings.deletion = this.$store.state.defaultAdvancedSettings.deletion;
          this.$store.state.advancedSettings.mention_mode = this.$store.state.defaultAdvancedSettings.mention_mode;
          this.$store.state.advancedSettings.preserve_order = this.$store.state.defaultAdvancedSettings.preserve_order;
          this.$store.state.advancedSettings.event_type = this.$store.state.defaultAdvancedSettings.event_type;
          this.$store.state.advancedSettings.reminder = this.$store.state.defaultAdvancedSettings.reminder;
          this.$store.state.advancedSettings.apply_unregister = this.$store.state.defaultAdvancedSettings.apply_unregister;
          this.$store.state.advancedSettings.apply_specreset = this.$store.state.defaultAdvancedSettings.apply_specreset;
          this.$store.state.advancedSettings.show_on_overview = this.$store.state.defaultAdvancedSettings.show_on_overview;
          this.$store.state.advancedSettings.mention_leader = this.$store.state.defaultAdvancedSettings.mention_leader;
          this.$store.state.advancedSettings.spec_saving = this.$store.state.defaultAdvancedSettings.spec_saving;
          this.$store.state.advancedSettings.font_style = this.$store.state.defaultAdvancedSettings.font_style;
          this.$store.state.advancedSettings.alt_names = this.$store.state.defaultAdvancedSettings.alt_names;
          this.$store.state.advancedSettings.attendance = this.$store.state.defaultAdvancedSettings.attendance;
          this.$store.state.advancedSettings.show_title = this.$store.state.defaultAdvancedSettings.show_title;
          this.$store.state.advancedSettings.show_info = this.$store.state.defaultAdvancedSettings.show_info;
          this.$store.state.advancedSettings.show_leader = this.$store.state.defaultAdvancedSettings.show_leader;
          this.$store.state.advancedSettings.show_counter = this.$store.state.defaultAdvancedSettings.show_counter;
          this.$store.state.advancedSettings.show_roles = this.$store.state.defaultAdvancedSettings.show_roles;
          this.$store.state.advancedSettings.show_content = this.$store.state.defaultAdvancedSettings.show_content;
          this.$store.state.advancedSettings.show_classes = this.$store.state.defaultAdvancedSettings.show_classes;
          this.$store.state.advancedSettings.show_emotes = this.$store.state.defaultAdvancedSettings.show_emotes;
          this.$store.state.advancedSettings.show_numbering = this.$store.state.defaultAdvancedSettings.show_numbering;
          this.$store.state.advancedSettings.show_allowed = this.$store.state.defaultAdvancedSettings.show_allowed;
          this.$store.state.advancedSettings.show_banned = this.$store.state.defaultAdvancedSettings.show_banned;
          this.$store.state.advancedSettings.show_footer = this.$store.state.defaultAdvancedSettings.show_footer;
          this.$store.state.advancedSettings.defaults_pre_req = this.$store.state.defaultAdvancedSettings.defaults_pre_req;
          this.$store.state.advancedSettings.disable_archiving = this.$store.state.defaultAdvancedSettings.disable_archiving;
          this.$store.state.advancedSettings.bold_all = this.$store.state.defaultAdvancedSettings.bold_all;
          this.$store.state.advancedSettings.info_variant = this.$store.state.defaultAdvancedSettings.info_variant;
          this.$store.state.advancedSettings.date_variant = this.$store.state.defaultAdvancedSettings.date_variant;
          this.$store.state.advancedSettings.show_countdown = this.$store.state.defaultAdvancedSettings.show_countdown;
          this.$store.state.advancedSettings.localtime_as_link = this.$store.state.defaultAdvancedSettings.localtime_as_link;
          this.$store.state.advancedSettings.allow_duplicate = this.$store.state.defaultAdvancedSettings.allow_duplicate;
          this.$store.state.advancedSettings.response = this.$store.state.defaultAdvancedSettings.response;
          this.$store.state.advancedSettings.opt_out = this.$store.state.defaultAdvancedSettings.opt_out;
          this.$store.state.advancedSettings.bench_emote = this.$store.state.defaultAdvancedSettings.bench_emote;
          this.$store.state.advancedSettings.late_emote = this.$store.state.defaultAdvancedSettings.late_emote;
          this.$store.state.advancedSettings.tentative_emote = this.$store.state.defaultAdvancedSettings.tentative_emote;
          this.$store.state.advancedSettings.absence_emote = this.$store.state.defaultAdvancedSettings.absence_emote;
          
          this.$store.state.advancedSettings.leader_emote = this.$store.state.defaultAdvancedSettings.leader_emote;
          this.$store.state.advancedSettings.signups1_emote = this.$store.state.defaultAdvancedSettings.signups1_emote;
          this.$store.state.advancedSettings.signups2_emote = this.$store.state.defaultAdvancedSettings.signups2_emote;
          this.$store.state.advancedSettings.date1_emote = this.$store.state.defaultAdvancedSettings.date1_emote;
          this.$store.state.advancedSettings.date2_emote = this.$store.state.defaultAdvancedSettings.date2_emote;
          this.$store.state.advancedSettings.time1_emote = this.$store.state.defaultAdvancedSettings.time1_emote;
          this.$store.state.advancedSettings.time2_emote = this.$store.state.defaultAdvancedSettings.time2_emote;
          this.$store.state.advancedSettings.countdown1_emote = this.$store.state.defaultAdvancedSettings.countdown1_emote;
          this.$store.state.advancedSettings.countdown2_emote = this.$store.state.defaultAdvancedSettings.countdown2_emote;
          this.$store.state.advancedSettings.thumbnail = this.$store.state.defaultAdvancedSettings.thumbnail;
    },

    clearPollAnswer(n) {
       this.$store.state.formAnswers.pollAnswers.splice(n, 1);
    },

    clearSoftres() {
      this.$store.state.formAnswers.softresFaction = "";
      this.$store.state.formAnswers.softresInstance = "";
      this.$store.state.formAnswers.softresMaxres = "";
      this.$store.state.formAnswers.softresBehaviour = "";
    },

    forceUpdate() {
      this.key++;
    },

    setImage(variable) {
      this.$store.state.formAnswers.imageUrl = variable;
    },

    closeOverlayNow(variable) {
      this.overlayOptions = variable;
    },

    setAdvanced(variable) {
      this.$store.state.advancedSettings = variable;
    },

    getDate() {
      if (this.$store.state.formAnswers.date === undefined || this.$store.state.formAnswers.date === '') {
      return " _____ "
      } else {
      return new Date(this.$store.state.formAnswers.date.replace(/-/g, "/")).toLocaleDateString([],{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
      }
    },

    getTime() {
      if (this.$store.state.formAnswers.date === undefined || this.$store.state.formAnswers.date === '') {
      return " _____ "
      } else {
      return new Date(this.$store.state.formAnswers.date.replace(/-/g, "/")).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
      }
    },

    getEndTime() {
      if (this.$store.state.formAnswers.date === undefined || this.$store.state.formAnswers.date === '' || this.$store.state.advancedSettings.duration === '' || this.$store.state.advancedSettings.duration === 0 || this.$store.state.advancedSettings.duration === "0" || this.$store.state.advancedSettings.duration === undefined) {
        return ""
      } else {
        if (this.$store.state.advancedSettings.duration < 1440) {
          return " - " + new Date(new Date(this.$store.state.formAnswers.date.replace(/-/g, "/")).getTime() + 1000*60*parseInt(this.$store.state.advancedSettings.duration)).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
        } else {
          return " - " + new Date(new Date(this.$store.state.formAnswers.date.replace(/-/g, "/")).getTime() + 1000*60*parseInt(this.$store.state.advancedSettings.duration)).toLocaleDateString([],{ month: 'long', day: 'numeric', hour: '2-digit', minute:'2-digit' })
        }
      }
    },

    getScheduleDate() {
      if (this.$store.state.premiumFeatures.scheduleDate === undefined || this.$store.state.premiumFeatures.scheduleDate === '') {
      return " _____ "
      } else {
      return new Date(this.$store.state.premiumFeatures.scheduleDate.replace(/-/g, "/")).toLocaleDateString([],{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
      }
    },

    getScheduleTime() {
      if (this.$store.state.premiumFeatures.scheduleDate === undefined || this.$store.state.premiumFeatures.scheduleDate === '') {
      return " _____ "
      } else {
      return new Date(this.$store.state.premiumFeatures.scheduleDate.replace(/-/g, "/")).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
      }
    },

    isMobile() {
      return window.innerWidth < 800;
    },
    clear() {
      this.$store.state.formAnswers.template = '';
      this.$store.state.formAnswers.title = '';
      this.$store.state.formAnswers.description = '';
      this.$store.state.formAnswers.channel = '';
      this.$store.state.formAnswers.date = '';
    },

        isPoll() {
       try {
				return (
          this.$store.state.formAnswers.template.id === 23 ||
          this.$store.state.formAnswers.template.id === 24 || 
          this.$store.state.formAnswers.template.id === 25 ||
          this.$store.state.formAnswers.template.shortName === "unsortedstrawpoll" ||
          this.$store.state.formAnswers.template.shortName === "sortedstrawpoll" ||
          this.$store.state.formAnswers.template.shortName === "basepoll"
				);
       } catch {
        return false;
      }
		},

        isStrawpoll() {
       try {
				return (
          this.$store.state.formAnswers.template.id === 24 || 
          this.$store.state.formAnswers.template.id === 25 ||
          this.$store.state.formAnswers.template.shortName === "unsortedstrawpoll" ||
          this.$store.state.formAnswers.template.shortName === "sortedstrawpoll"
				);
       } catch {
        return false;
      }
		},

    getChannelIconByType(type) {
      if (type === "text") {
        return "1025776950096171148";
      } else if (type === "forum") {
        return "1025776945146904629";
      } else if (type === "thread") {
        return "1025779885127516170";
      } else if (type === "post") {
        return "1025779860838301810";
      } else if (type === "news") {
        return "1025776946698784861";
      } else if (type === "stage") {
        return "1025776948288426068";
      } else if (type === "dm") {
        return "1026094422015877171";
      } else {
        return "1025776951627100210";
      }
    },

    getEventChannels() {
      return this.$store.state.discordChannels;
    },

    getChannels() {
      let channelNameList = [];
      channelNameList.push({id: "unsigned", name: "DM - unsigned members", position: -1, type: "dm" });
      channelNameList.push({id: "signed", name: "DM - signed members", position: -2, type: "dm" });
      channelNameList.push({id: "signedrostered", name: "DM - rostered members", position: -3, type: "dm" });
      channelNameList.push({id: "signedrosteredconfirmed", name: "DM - confirmed rostered members", position: -4, type: "dm" });
      channelNameList.push({id: "signedrosteredunresponded", name: "DM - unresponded rostered members", position: -5, type: "dm" });

      channelNameList.push({id: "unsignedping", name: "Ping - unsigned members", position: -6, type: "dm" });
      channelNameList.push({id: "signedping", name: "Ping - signed members", position: -7, type: "dm" });
      channelNameList.push({id: "signedrosteredping", name: "Ping - rostered members", position: -8, type: "dm" });
      channelNameList.push({id: "signedrosteredpingconfirmed", name: "Ping - confirmed rostered members", position: -9, type: "dm" });
      channelNameList.push({id: "signedrosteredpingunresponded", name: "Ping - unresponded rostered members", position: -10, type: "dm" });

      try {
      for (let index = 0; index < this.$store.state.discordChannels.length; index++) {
        let signedChannel = {
          id: this.$store.state.discordChannels[index].id,
          name: this.$store.state.discordChannels[index].name,
          position: this.$store.state.discordChannels[index].position,
          type: this.$store.state.discordChannels[index].type,
        };
        if (this.$store.state.discordChannels[index].type !== "forum") {
          channelNameList.push(signedChannel);
        }
      }
      } catch { }

      return channelNameList;
    },

    deleteAllInput() {
      this.$store.state.formAnswers.voice_channel = this.$store.state.defaultAdvancedSettings.voice_channel;
      this.$store.state.advancedSettings.duration = this.$store.state.defaultAdvancedSettings.duration;
      this.$store.state.formAnswers.template = "";
      this.$store.state.formAnswers.channel = "";
      this.$store.state.formAnswers.title = "";
      this.$store.state.formAnswers.description = "";
      this.$store.state.formAnswers.date = "";
      this.$store.state.formAnswers.imageUrl = "";
      this.$store.state.formAnswers.color = "#71368A";
      this.$store.state.formAnswers.mentions = "";
      this.$store.state.formAnswers.optOut = "";
      this.$store.state.formAnswers.announcementChannel = "";
      this.$store.state.formAnswers.announcementTime = "";
      this.$store.state.formAnswers.announcementMessage = "";
      this.$store.state.formAnswers.allowed_roles = "";
      this.$store.state.formAnswers.banned_roles = "";
      this.$store.state.formAnswers.temporaryRoles = "";
      this.$store.state.formAnswers.response = "";
      this.$store.state.formAnswers.pollQuestion = "";
      this.$store.state.formAnswers.pollAnswers = [];
      this.$store.state.premiumFeatures.scheduleDate = "";
      this.$store.state.premiumFeatures.recurring = "";
      this.$store.state.formAnswers.softresFaction = "";
      this.$store.state.formAnswers.softresInstance = "";
      this.$store.state.formAnswers.softresMaxres = "";
      this.$store.state.formAnswers.softresBehaviour = "";
      this.$store.state.premiumFeatures.interval2Ordinal = "";
      this.$store.state.premiumFeatures.interval2Weekday = "";
      this.setDynamicDate = false;
      this.$store.state.advancedSettings.deadline = this.$store.state.defaultAdvancedSettings.deadline;
      this.$store.state.advancedSettings.limit = this.$store.state.defaultAdvancedSettings.limit;
      this.$store.state.advancedSettings.lock_at_limit = this.$store.state.defaultAdvancedSettings.lock_at_limit;
      this.$store.state.advancedSettings.limit_per_user = this.$store.state.defaultAdvancedSettings.limit_per_user;
      this.$store.state.advancedSettings.lower_limit = this.$store.state.defaultAdvancedSettings.lower_limit;
      this.$store.state.advancedSettings.horizontal_mode = this.$store.state.defaultAdvancedSettings.horizontal_mode;
      this.$store.state.advancedSettings.bench_overflow = this.$store.state.defaultAdvancedSettings.bench_overflow;
      this.$store.state.advancedSettings.queue_bench = this.$store.state.defaultAdvancedSettings.queue_bench;
      this.$store.state.advancedSettings.vacuum = this.$store.state.defaultAdvancedSettings.vacuum;
      this.$store.state.advancedSettings.force_reminders = this.$store.state.defaultAdvancedSettings.force_reminders;
      this.$store.state.advancedSettings.create_thread = this.$store.state.defaultAdvancedSettings.create_thread;
      this.$store.state.advancedSettings.forum_tags = this.$store.state.defaultAdvancedSettings.forum_tags;
      this.$store.state.advancedSettings.delete_thread = this.$store.state.defaultAdvancedSettings.delete_thread;
      this.$store.state.advancedSettings.use_nicknames = this.$store.state.defaultAdvancedSettings.use_nicknames;
      this.$store.state.advancedSettings.create_discordevent = this.$store.state.defaultAdvancedSettings.create_discordevent;
      this.$store.state.advancedSettings.only_discordevent = this.$store.state.defaultAdvancedSettings.only_discordevent;
      this.$store.state.advancedSettings.pin_message = this.$store.state.defaultAdvancedSettings.pin_message;
      this.$store.state.advancedSettings.deletion = this.$store.state.defaultAdvancedSettings.deletion;
      this.$store.state.advancedSettings.mention_mode = this.$store.state.defaultAdvancedSettings.mention_mode;
      this.$store.state.advancedSettings.preserve_order = this.$store.state.defaultAdvancedSettings.preserve_order;
      this.$store.state.advancedSettings.event_type = this.$store.state.defaultAdvancedSettings.event_type;
      this.$store.state.advancedSettings.reminder = this.$store.state.defaultAdvancedSettings.reminder;
      this.$store.state.advancedSettings.apply_unregister = this.$store.state.defaultAdvancedSettings.apply_unregister;
      this.$store.state.advancedSettings.apply_specreset = this.$store.state.defaultAdvancedSettings.apply_specreset;
      this.$store.state.advancedSettings.show_on_overview = this.$store.state.defaultAdvancedSettings.show_on_overview;
      this.$store.state.advancedSettings.mention_leader = this.$store.state.defaultAdvancedSettings.mention_leader;
      this.$store.state.advancedSettings.spec_saving = this.$store.state.defaultAdvancedSettings.spec_saving;
      this.$store.state.advancedSettings.font_style = this.$store.state.defaultAdvancedSettings.font_style;
      this.$store.state.advancedSettings.alt_names = this.$store.state.defaultAdvancedSettings.alt_names;
      this.$store.state.advancedSettings.attendance = this.$store.state.defaultAdvancedSettings.attendance;
      this.$store.state.advancedSettings.show_title = this.$store.state.defaultAdvancedSettings.show_title;
      this.$store.state.advancedSettings.show_info = this.$store.state.defaultAdvancedSettings.show_info;
      this.$store.state.advancedSettings.show_leader = this.$store.state.defaultAdvancedSettings.show_leader;
      this.$store.state.advancedSettings.show_counter = this.$store.state.defaultAdvancedSettings.show_counter;
      this.$store.state.advancedSettings.show_roles = this.$store.state.defaultAdvancedSettings.show_roles;
      this.$store.state.advancedSettings.show_content = this.$store.state.defaultAdvancedSettings.show_content;
      this.$store.state.advancedSettings.show_classes = this.$store.state.defaultAdvancedSettings.show_classes;
      this.$store.state.advancedSettings.show_emotes = this.$store.state.defaultAdvancedSettings.show_emotes;
      this.$store.state.advancedSettings.show_numbering = this.$store.state.defaultAdvancedSettings.show_numbering;
      this.$store.state.advancedSettings.show_allowed = this.$store.state.defaultAdvancedSettings.show_allowed;
      this.$store.state.advancedSettings.show_banned = this.$store.state.defaultAdvancedSettings.show_banned;
      this.$store.state.advancedSettings.show_footer = this.$store.state.defaultAdvancedSettings.show_footer;
      this.$store.state.advancedSettings.defaults_pre_req = this.$store.state.defaultAdvancedSettings.defaults_pre_req;
      this.$store.state.advancedSettings.disable_archiving = this.$store.state.defaultAdvancedSettings.disable_archiving;
      this.$store.state.advancedSettings.bold_all = this.$store.state.defaultAdvancedSettings.bold_all;
      this.$store.state.advancedSettings.info_variant = this.$store.state.defaultAdvancedSettings.info_variant;
      this.$store.state.advancedSettings.date_variant = this.$store.state.defaultAdvancedSettings.date_variant;
      this.$store.state.advancedSettings.show_countdown = this.$store.state.defaultAdvancedSettings.show_countdown;
      this.$store.state.advancedSettings.localtime_as_link = this.$store.state.defaultAdvancedSettings.localtime_as_link;
      this.$store.state.advancedSettings.allow_duplicate = this.$store.state.defaultAdvancedSettings.allow_duplicate;
      this.$store.state.advancedSettings.response = this.$store.state.defaultAdvancedSettings.response;
      this.$store.state.advancedSettings.opt_out = this.$store.state.defaultAdvancedSettings.opt_out;
      this.$store.state.advancedSettings.bench_emote = this.$store.state.defaultAdvancedSettings.bench_emote;
      this.$store.state.advancedSettings.late_emote = this.$store.state.defaultAdvancedSettings.late_emote;
      this.$store.state.advancedSettings.tentative_emote = this.$store.state.defaultAdvancedSettings.tentative_emote;
      this.$store.state.advancedSettings.absence_emote = this.$store.state.defaultAdvancedSettings.absence_emote;

      this.$store.state.advancedSettings.leader_emote = this.$store.state.defaultAdvancedSettings.leader_emote;
      this.$store.state.advancedSettings.signups1_emote = this.$store.state.defaultAdvancedSettings.signups1_emote;
      this.$store.state.advancedSettings.signups2_emote = this.$store.state.defaultAdvancedSettings.signups2_emote;
      this.$store.state.advancedSettings.date1_emote = this.$store.state.defaultAdvancedSettings.date1_emote;
      this.$store.state.advancedSettings.date2_emote = this.$store.state.defaultAdvancedSettings.date2_emote;
      this.$store.state.advancedSettings.time1_emote = this.$store.state.defaultAdvancedSettings.time1_emote;
      this.$store.state.advancedSettings.time2_emote = this.$store.state.defaultAdvancedSettings.time2_emote;
      this.$store.state.advancedSettings.countdown1_emote = this.$store.state.defaultAdvancedSettings.countdown1_emote;
      this.$store.state.advancedSettings.countdown2_emote = this.$store.state.defaultAdvancedSettings.countdown2_emote;
      this.$store.state.advancedSettings.thumbnail = this.$store.state.defaultAdvancedSettings.thumbnail;
    },
  },

  created() {
    this.checkRoles();
    document.title = "Dashboard - Create Event"
  }
};
</script>
<style>
.v-input__slot .v-label{
color: grey!important
}

.v-input {
  min-height: 1px !important;
}

.tooltip-arrow-top {
  opacity: 0.7; 
  position: absolute; 
  height: 10px; 
  width: 10px; 
  margin-left: 40%; 
  margin-top: -15px; 
  border-left: 10px solid transparent; 
  border-right: 10px solid transparent; 
  border-bottom: 10px solid rgb(5, 5, 5);
}

.v-tooltip__content {
  color: rgb(224, 204, 204); /* Set text color */
  background-color: rgba(0, 0, 0, 0.911) !important;
  border: 1px solid rgb(102, 89, 89) !important; /* Add a border */
  border-radius: 1px; /* Add some rounded corners */
  padding: 6px 14px; /* Add padding */
  font-size: 12px; /* Set font size */
}

</style>
